import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CountryDto, CountryService } from '@mysvg/api/toll-box';

import { RepositoryStoreService } from './repository-store.service';

@Injectable({ providedIn: 'root' })
export class VehicleCountriesStoreService extends RepositoryStoreService<CountryDto[]> {
	constructor(private countryService: CountryService) {
		super();
	}

	fetchData(): Observable<CountryDto[]> {
		return this.countryService.getAllCountries({ boxRelevant: false, onlyRealCountries: true });
	}
}
