/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddressDto } from '../models/address-dto';
import { createAddress } from '../fn/address-management-controller/create-address';
import { CreateAddress$Params } from '../fn/address-management-controller/create-address';
import { createAddressWithUdf } from '../fn/address-management-controller/create-address-with-udf';
import { CreateAddressWithUdf$Params } from '../fn/address-management-controller/create-address-with-udf';
import { deleteAddress } from '../fn/address-management-controller/delete-address';
import { DeleteAddress$Params } from '../fn/address-management-controller/delete-address';
import { getAddress } from '../fn/address-management-controller/get-address';
import { GetAddress$Params } from '../fn/address-management-controller/get-address';
import { getCustomerAddresses } from '../fn/address-management-controller/get-customer-addresses';
import { GetCustomerAddresses$Params } from '../fn/address-management-controller/get-customer-addresses';
import { updateAddress } from '../fn/address-management-controller/update-address';
import { UpdateAddress$Params } from '../fn/address-management-controller/update-address';

@Injectable({ providedIn: 'root' })
export class AddressManagementControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateAddress()` */
  static readonly UpdateAddressPath = '/customers/{portalUid}/addresses/{addressId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAddress$Response(params: UpdateAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressDto>> {
    return updateAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAddress(params: UpdateAddress$Params, context?: HttpContext): Observable<AddressDto> {
    return this.updateAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressDto>): AddressDto => r.body)
    );
  }

  /** Path part for operation `deleteAddress()` */
  static readonly DeleteAddressPath = '/customers/{portalUid}/addresses/{addressId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAddress$Response(params: DeleteAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAddress(params: DeleteAddress$Params, context?: HttpContext): Observable<void> {
    return this.deleteAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCustomerAddresses()` */
  static readonly GetCustomerAddressesPath = '/customers/{portalUid}/addresses';

  /**
   * selecting all addresses of customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAddresses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAddresses$Response(params: GetCustomerAddresses$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AddressDto>>> {
    return getCustomerAddresses(this.http, this.rootUrl, params, context);
  }

  /**
   * selecting all addresses of customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerAddresses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAddresses(params: GetCustomerAddresses$Params, context?: HttpContext): Observable<Array<AddressDto>> {
    return this.getCustomerAddresses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AddressDto>>): Array<AddressDto> => r.body)
    );
  }

  /** Path part for operation `createAddress()` */
  static readonly CreateAddressPath = '/customers/{portalUid}/addresses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAddress$Response(params: CreateAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressDto>> {
    return createAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAddress(params: CreateAddress$Params, context?: HttpContext): Observable<AddressDto> {
    return this.createAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressDto>): AddressDto => r.body)
    );
  }

  /** Path part for operation `createAddressWithUdf()` */
  static readonly CreateAddressWithUdfPath = '/customers/{portalUid}/addresses/with-udf';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAddressWithUdf()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAddressWithUdf$Response(params: CreateAddressWithUdf$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressDto>> {
    return createAddressWithUdf(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAddressWithUdf$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAddressWithUdf(params: CreateAddressWithUdf$Params, context?: HttpContext): Observable<AddressDto> {
    return this.createAddressWithUdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressDto>): AddressDto => r.body)
    );
  }

  /** Path part for operation `getAddress()` */
  static readonly GetAddressPath = '/customers/{portalUid}/addresses/{id}';

  /**
   * receiving a customers address.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddress$Response(params: GetAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressDto>> {
    return getAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * receiving a customers address.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddress(params: GetAddress$Params, context?: HttpContext): Observable<AddressDto> {
    return this.getAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressDto>): AddressDto => r.body)
    );
  }

}
