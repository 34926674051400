/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CountryDto } from '../../models/country-dto';

export interface GetAllCountries$Params {
  boxRelevant?: boolean;
  onlyRealCountries?: boolean;
}

export function getAllCountries(http: HttpClient, rootUrl: string, params?: GetAllCountries$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CountryDto>>> {
  const rb = new RequestBuilder(rootUrl, getAllCountries.PATH, 'get');
  if (params) {
    rb.query('boxRelevant', params.boxRelevant, {});
    rb.query('onlyRealCountries', params.onlyRealCountries, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<CountryDto>>;
    })
  );
}

getAllCountries.PATH = '/public/countries/relevant';
