import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from '@nsalaun/ng-logger';

/**
 * Page to show when some chunk was not found after an update of the App
 */
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-new-release-chunk-error-page.component',
	styleUrls: ['./new-release-chunk-error-page.component.scss'],
	templateUrl: './new-release-chunk-error-page.component.html',
})
export class NewReleaseChunkErrorPageComponent {
	constructor(private router: Router, private logger: Logger) {}

	/**
	 * Navigate to default dashboard page with reload in between
	 */
	reload() {
		window.location.href = '/';
	}

	/**
	 * Route guard ensures that user is logged in or redirects to keycloak
	 */
	navigateToDefault(): void {
		this.router.navigateByUrl('').catch((error) => this.logger.error('Navigation failed', error));
	}
}
