import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, first, map, tap } from 'rxjs/operators';

import { UrlParamsService, WindowTabManagmentService } from '@mysvg/utils';
import { ErrorHandlingService, ErrorHandlingType } from '@svg-frontends/error';
import { StorageService } from '@svg-frontends/storage';

import { AuthenticationService } from '../../authentication/services/authentication.service';
import { OauthWrapperService } from '../../authentication/services/oauth-wrapper.service';
import { Context } from '../models/context.model';
import { DefaultUserTokenClaims } from '../models/user-token-claims.model';

const CONTEXT_SESSION_STORAGE_KEY = 'app_context';

export const CONTEXT_KEY = 'context'; // TODO: Make this overridable

@Injectable({ providedIn: 'root' })
export class ContextService<T extends Context, U extends DefaultUserTokenClaims> {
	// undefined is initial state of behavior subject and is always ignored
	private context$ = new BehaviorSubject<T | undefined>(undefined);

	constructor(
		private authenticationService: AuthenticationService<T>,
		private errorHandlingService: ErrorHandlingService,
		private oauthWrapperService: OauthWrapperService<U>,
		private storageService: StorageService,
		private urlParamsService: UrlParamsService,
		private windowTabManagmentService: WindowTabManagmentService,
	) {}

	init(): void {
		this.saveContextIdFromUrl();

		/**
		 * `init` can login based on hash fragments or existing token - then start custom flow
		 * if no token available or login based on hash fragment did not work, then set context to notLoggedIn
		 */
		this.oauthWrapperService.isDoneLoading$
			.pipe(map((initSucceeded: boolean) => initSucceeded && this.oauthWrapperService.hasValidAccessToken()))
			.subscribe((isValid: boolean) => {
				if (isValid) {
					this.initCustomLoginFlow();
				} else {
					this.context$.next(this.authenticationService.notLoggedIn());
				}
			});

		this.oauthWrapperService.init();
	}

	/**
	 * [NOTE] true if matches at least one of the given activities
	 */
	hasActivity(activity: string | string[]): Observable<boolean> {
		const activities = activity instanceof Array ? activity : [activity];

		return this.getFirstContext().pipe(
			map((context: T) => activities.map((a: string) => context.activities.indexOf(a) !== -1)),
			map((matches: boolean[]) => matches.some((match: boolean) => match)),
			catchError(() => of(false)),
		);
	}

	/**
	 * [NOTE] true if matches all of the given activities
	 */
	hasAllActivities(activities: string[]): Observable<boolean> {
		return this.getFirstContext().pipe(
			map((context: T) => activities.map((a: string) => context.activities.indexOf(a) !== -1)),
			map((matches: boolean[]) => matches.every((match: boolean) => match)),
			catchError(() => of(false)),
		);
	}

	/**
	 * [NOTE] InitialAuthenticationGuard sets contextId from url to storage before keycloak redirect would be remove them (an all other) params
	 * 				from redirect_uri
	 *
	 * [NOTE] this is only needed for staff users
	 */
	getContextIdFromStorage(): string | null {
		if (this.storageService.has(CONTEXT_SESSION_STORAGE_KEY)) {
			return this.storageService.getDecoded(CONTEXT_SESSION_STORAGE_KEY);
		} else {
			return null;
		}
	}

	/**
	 * Context after login (value is never undefined or null)
	 */
	getContext(): Observable<T> {
		return this.context$.asObservable().pipe(filter((context: T) => !!context));
	}

	/**
	 * Context after login (value is never undefined or null)
	 */
	getFirstContext(): Observable<T> {
		return this.context$.asObservable().pipe(
			filter((context: T) => !!context),
			first(),
		);
	}

	/**
	 * [NOTE] RavenContext is cleared by listeners on context
	 * [NOTE] Clears session to clear customer group in session storage
	 */
	logout(noRedirect: boolean = false): void {
		this.oauthWrapperService.logout(noRedirect);
		this.storageService.clearSession();
		this.context$.next(this.authenticationService.notLoggedIn());
	}

	/**
	 * some edge cases need to access the token directly
	 * e.g. svg uploader | restricted documents
	 *
	 * @deprecated
	 */
	getAccessToken(): string | null {
		return this.oauthWrapperService.getAccessToken();
	}

	initCustomLoginFlow(): void {
		this.authenticationService
			.postLoginFlow(this.getContextIdFromStorage())
			.pipe(tap(() => this.oauthWrapperService.clearHashAfterLogin()))
			.subscribe({
				next: (context: T) => this.context$.next(context),
				error: (error: Error | HttpErrorResponse) => this.logoutAndRedirectToErrorPage(error),
			});
	}

	/**
	 * Opens new tab (or window for PWA) within staff context
	 */
	openStaffContextTab(): void {
		const newTab = this.windowTabManagmentService.createNewTab('/');

		// clear session of new tab/window and navigate to wanted staff page
		newTab.onload = () => {
			newTab.sessionStorage.clear();
			newTab.window.location.href = '/staff';
		};
		newTab.focus();
	}

	private logoutAndRedirectToErrorPage(error: Error | HttpErrorResponse): void {
		this.logout(true);

		if (!!error) {
			this.errorHandlingService.setNextErrorBy(error, ErrorHandlingType.ROUTE_TO_ERROR_PAGE);
		}
	}

	/**
	 * [NOTE] Set context from url to storage before keycloak redirect would remove them (and all other) params from redirect_uri
	 */
	private saveContextIdFromUrl(): void {
		const contextId: string | null = this.urlParamsService.getBy(CONTEXT_KEY);

		if (contextId !== null) {
			this.storageService.setEncoded(CONTEXT_SESSION_STORAGE_KEY, contextId);
		}
	}
}
