<div [ngClass]="{ 'clr-form-control-disabled': disableControl }" class="clr-form-control mac-form-control">
	<label *ngIf="label" [for]="controlName" class="clr-control-label mac-control-label">
		<svg-frontends-string-or-template-ref [value]="label"></svg-frontends-string-or-template-ref>
		<svg-frontends-required-marker *ngIf="forceRequired || (formControl | isFormControlRequired) === true"></svg-frontends-required-marker>
	</label>
	<div
		[ngClass]="{
			'mac-control-container': label,
			'mac-control-container--fullwidth': fullWidth,
			'clr-error': formControl?.invalid && formControl?.touched
		}"
		class="clr-control-container"
	>
		<div [ngClass]="{ 'svg-frontends-control-wrapper-disable': disableControl }">
			<div class="clr-input-wrapper mac-input-wrapper" [ngClass]="{ 'XCblock-display': forceBlockDisplay }">
				<ng-content></ng-content>
			</div>

			<div class="clr-subtext-wrapper mac-subtext-wrapper">
				<span *ngIf="helperText && !(formControl?.invalid && formControl?.touched)" class="clr-subtext">
					<svg-frontends-string-or-template-ref [value]="helperText"></svg-frontends-string-or-template-ref>
				</span>
				<span *ngIf="formControl?.invalid && formControl?.touched" class="clr-subtext">
					<cds-icon class="clr-validate-icon" shape="exclamation-circle"></cds-icon>
					{{ formControl?.errors | firstControlError | controlErrorMessage }}
				</span>
			</div>
		</div>
	</div>
</div>
