/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AsiReportDto } from '../models/asi-report-dto';
import { getAllSvg } from '../fn/report-controller/get-all-svg';
import { GetAllSvg$Params } from '../fn/report-controller/get-all-svg';
import { getPopularAsiReports } from '../fn/report-controller/get-popular-asi-reports';
import { GetPopularAsiReports$Params } from '../fn/report-controller/get-popular-asi-reports';
import { getPopularSvgReports } from '../fn/report-controller/get-popular-svg-reports';
import { GetPopularSvgReports$Params } from '../fn/report-controller/get-popular-svg-reports';
import { PageSvgReportDto } from '../models/page-svg-report-dto';
import { SvgReportDto } from '../models/svg-report-dto';

@Injectable({ providedIn: 'root' })
export class ReportControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllSvg()` */
  static readonly GetAllSvgPath = '/report/svg';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSvg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSvg$Response(params?: GetAllSvg$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSvgReportDto>> {
    return getAllSvg(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSvg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSvg(params?: GetAllSvg$Params, context?: HttpContext): Observable<PageSvgReportDto> {
    return this.getAllSvg$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSvgReportDto>): PageSvgReportDto => r.body)
    );
  }

  /** Path part for operation `getPopularSvgReports()` */
  static readonly GetPopularSvgReportsPath = '/report/svg/popular';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPopularSvgReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPopularSvgReports$Response(params?: GetPopularSvgReports$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SvgReportDto>>> {
    return getPopularSvgReports(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPopularSvgReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPopularSvgReports(params?: GetPopularSvgReports$Params, context?: HttpContext): Observable<Array<SvgReportDto>> {
    return this.getPopularSvgReports$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SvgReportDto>>): Array<SvgReportDto> => r.body)
    );
  }

  /** Path part for operation `getPopularAsiReports()` */
  static readonly GetPopularAsiReportsPath = '/report/asi/popular';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPopularAsiReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPopularAsiReports$Response(params?: GetPopularAsiReports$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AsiReportDto>>> {
    return getPopularAsiReports(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPopularAsiReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPopularAsiReports(params?: GetPopularAsiReports$Params, context?: HttpContext): Observable<Array<AsiReportDto>> {
    return this.getPopularAsiReports$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AsiReportDto>>): Array<AsiReportDto> => r.body)
    );
  }

}
