import { CustomerInfo, SvgInfo, UserContext } from '@mysvg/api/auth';
import { ErrorMessageKey } from '@svg-frontends/error';

import { User } from '../../authentication/models/user';
import { ContextState } from '../enums/context-state.enum';
import { Context } from './context.model';
import { KeycloakTokenClaims } from '../../context/models/user-token-claims.model';

export class SvgContext implements Context {
	constructor(
		public activities: string[],
		public customerInfo: CustomerInfo,
		public state: ContextState,
		public svgInfo: SvgInfo,
		public user: User,
		public rootSvgInfo: SvgInfo,
	) {}

	/**
	 * throws an error, if there is no svgContext, which is ContextState, on token
	 * application is not working, if contextState is missing
	 */
	static from(userTokenClaims: KeycloakTokenClaims, userContext: UserContext, activities: string[]): SvgContext {
		const customer: CustomerInfo = userContext.customerInfo;
		const state: ContextState = ContextState[userContext.svgContextName];
		const svgInfo: SvgInfo = userContext.svgInfo;
		const rootSvgInfo: SvgInfo = userContext.rootSvg;
		const user: User = new User(userTokenClaims);

		if (!state) {
			throw new Error('[TECHNICAL] SvgContext says, svgContext is missing on Token.');
		} else if (!activities || activities.length === 0) {
			throw new Error(ErrorMessageKey.AUTH_NO_ACTIVITIES);
		} else {
			return new SvgContext(activities, customer, state, svgInfo, user, rootSvgInfo);
		}
	}

	static notLoggedIn(): SvgContext {
		return new SvgContext(null, null, ContextState.NOT_LOGGED_IN, null, null, null);
	}

	isCustomerState(): boolean {
		return this.state === ContextState.CUSTOMER;
	}

	isStaffState(): boolean {
		return this.state === ContextState.STAFF;
	}

	isStaffAtCustomerState(): boolean {
		return this.state === ContextState.STAFF_CUSTOMER;
	}

	isStaffOrStaffAtCustomerState(): boolean {
		return this.state === ContextState.STAFF || this.state === ContextState.STAFF_CUSTOMER;
	}

	isCustomerOrStaffAtCustomerState(): boolean {
		return this.state === ContextState.CUSTOMER || this.state === ContextState.STAFF_CUSTOMER;
	}

	isNotLoggedInState(): boolean {
		return this.state === ContextState.NOT_LOGGED_IN;
	}

	/**
	 * root user of customer always has customerNumber as username
	 */
	isRootUser(): boolean {
		const customerNumber = this.customerInfo.customerNumber;
		const userName = this.user.preferred_username;
		return customerNumber === userName;
	}
}
