/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseSettlement } from '../../models/response-settlement';

export interface GetSettlementDates$Params {
  customers: string;
  providers?: Array<string>;
}

export function getSettlementDates(http: HttpClient, rootUrl: string, params: GetSettlementDates$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSettlement>> {
  const rb = new RequestBuilder(rootUrl, getSettlementDates.PATH, 'get');
  if (params) {
    rb.query('customers', params.customers, {});
    rb.query('providers', params.providers, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseSettlement>;
    })
  );
}

getSettlementDates.PATH = '/settlementdates';
