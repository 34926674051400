import { DiscoveryDocument } from '../models/discovery-document.model';

export const OAUTH_EVENTS = {
	TOKEN_RECEIVED: 'token_received',
	SILENT_REFRESH: {
		REFRESHED: 'silently_refreshed',
		ERROR: 'silent_refresh_error',
		TIMEOUT: 'silent_refresh_timeout',
	},
};

export const ACCESS_TOKEN_STORAGE_KEY = 'access_token';

/**
 * this is the client side config for oauth-lib, discovery document (server-side) does more configuration
 */
export const DEFAULT_DISCOVERY_DOCUMENT: DiscoveryDocument = {
	clientId: '', // This will be set by inject token `MAC_DISCOVERY_DOCUMENT`
	issuer: '', // This will be set by inject token `MAC_DISCOVERY_DOCUMENT`
	requireHttps: false, // ssl / https enforcement is done by backend, this lib should not check anything
	responseType: 'code', // Authorization Code flow
	logoutUrl: window.location.origin,
	postLogoutRedirectUri: window.location.origin,
	redirectUri: window.location.origin,
	silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

	// TODO - remove this when context is no longer url parameter append with `?` (open customer in new tab)
	clearHashAfterLogin: false,
};
