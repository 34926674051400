/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getIsUp } from '../fn/my-svg-controller/get-is-up';
import { GetIsUp$Params } from '../fn/my-svg-controller/get-is-up';
import { getVersion } from '../fn/my-svg-controller/get-version';
import { GetVersion$Params } from '../fn/my-svg-controller/get-version';

@Injectable({ providedIn: 'root' })
export class MySvgControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getVersion()` */
  static readonly GetVersionPath = '/version';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersion$Response(params?: GetVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersion(params?: GetVersion$Params, context?: HttpContext): Observable<string> {
    return this.getVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getIsUp()` */
  static readonly GetIsUpPath = '/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIsUp()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIsUp$Response(params?: GetIsUp$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getIsUp(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIsUp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIsUp(params?: GetIsUp$Params, context?: HttpContext): Observable<string> {
    return this.getIsUp$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
