import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

import {
	ActionReasonsControllerService,
	ActionsControllerService,
	Customer,
	CustomerLock,
	LockCancellationReasonBo,
	LockReasonBo,
	PiaControllerService,
	ResponseLockCancellationReasonBo,
	ResponseLockReasonBo,
	SvgContact,
} from '@mysvg/api/pia';

import { InfoDialogData } from '../components/info-dialog/info-dialog.component';
import { SpecifyReasonDialogData, SpecifyReasonResult } from '../components/specify-reason-dialog/specify-reason-dialog.component';
import { DialogLevel } from '../enums/dialog-level.enum';
import { DialogSize } from '../enums/dialog-size.enum';
import { StandardDialogService } from './standard-dialog.service';

@Injectable({ providedIn: 'root' })
export class CustomerService {
	constructor(
		private actionReasonsControllerService: ActionReasonsControllerService,
		private actionsControllerService: ActionsControllerService,
		private piaControllerService: PiaControllerService,
		private standardDialogService: StandardDialogService,
	) {}

	lockCustomer(portalUid: string): Observable<Customer> {
		const data: SpecifyReasonDialogData<LockReasonBo> = {
			bindLabel: 'reason',
			cancelBtnTxt: 'Abbrechen',
			confirmBtnTxt: 'Sperren',
			data$: this.actionReasonsControllerService.getLockReasons().pipe(map((response: ResponseLockReasonBo) => response.data)),
			dateLabel: 'Ausführungsdatum der Sperre',
			infoText: 'Warum möchten sie den Kunden sperren?',
			isAdditionalCommentMandatory: false,
			isDateMandatory: false,
			reasonLabel: 'Grund',
			showAdditionalComment: true,
			showDate: true,
			title: 'Ihre Begründung',
		};

		return this.standardDialogService.showSpecifyReasonDialog(data).pipe(
			filter((result: SpecifyReasonResult<LockReasonBo> | null) => result !== null),
			map((result: SpecifyReasonResult<LockReasonBo>) => {
				const date = result.date || dayjs().add(1, 'hour');
				const lockReason: CustomerLock = {
					additionalComment: result.additionalComment,
					reason: result.reason.reason,
					// The customer lock store procedure runs everyday at 15:00. So picking a specific timestamp does not make sense.
					toLockAt: dayjs(date).set('hour', 15).toISOString(),
				};

				return lockReason;
			}),
			mergeMap((lockReason: CustomerLock) => this.actionsControllerService.lockCustomer({ body: lockReason, id: portalUid })),
			mergeMap(() => this.loadCustomer(portalUid)),
			tap(() => this.showConfirmation('Der Kunde wurde erfolgreich für eine Sperrung vorgemerkt.')),
		);
	}

	unlockCustomer(portalUid: string): Observable<Customer> {
		const data: SpecifyReasonDialogData<LockCancellationReasonBo> = {
			bindLabel: 'reason',
			cancelBtnTxt: 'Abbrechen',
			confirmBtnTxt: 'Entsperren',
			data$: this.actionReasonsControllerService
				.getLockCancellationReasons()
				.pipe(map((response: ResponseLockCancellationReasonBo) => response.data)),
			reasonLabel: 'Grund',
			size: DialogSize.MEDIUM,
			title: 'Warum möchten sie den Kunden entsperren?',
		};

		return this.standardDialogService.showSpecifyReasonDialog(data).pipe(
			filter((result: SpecifyReasonResult<LockCancellationReasonBo> | null) => result !== null),
			mergeMap((result: SpecifyReasonResult<LockCancellationReasonBo>) =>
				this.actionsControllerService.removeCustomerLock({ id: portalUid, reason: result.reason.reason }),
			),
			mergeMap(() => this.loadCustomer(portalUid)),
			tap(() => this.showConfirmation('Der Kunde wurde erfolgreich entsperrt.')),
		);
	}

	getContactMail(customerId: string, key?: string): Observable<string> {
		return this.piaControllerService.getSvgContact({ id: customerId }).pipe(map((contact: SvgContact) => this.mapToEmail(contact, key)));
	}

	getContact(customerId: string): Observable<SvgContact> {
		return this.piaControllerService.getSvgContact({ id: customerId });
	}

	public mapToEmail(contact: SvgContact, key: string = null): string {
		switch (key) {
			case 'dkv':
				return contact.emailDkv;
			case 'esso':
				return contact.emailEsso;
			case 'euroshell':
				return contact.emailShell;
			case 'total':
				return contact.emailTotal;
			case 'e-vignette':
				return contact.emailEVingnette;
			default:
				return contact.emailDefault;
		}
	}

	private loadCustomer(portalUid: string): Observable<Customer> {
		return this.piaControllerService.getCustomerByPortalId({ id: portalUid });
	}

	private showConfirmation(text: string): void {
		const data: InfoDialogData = { title: 'Erfolgreich', body: text, submitButton: 'Ok', level: DialogLevel.SUCCESS };
		this.standardDialogService.showInfoDialog(data);
	}
}
