import { Injectable } from '@angular/core';
import { RiskControllerService, RiskTypeDto } from '@mysvg/api/financial-guarantees';
import { RepositoryStoreService } from '../repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RisksTypesStoreService extends RepositoryStoreService<RiskTypeDto[]> {
	constructor(private riskControllerService: RiskControllerService) {
		super();
	}

	fetchData(): Observable<RiskTypeDto[]> {
		return this.riskControllerService.getRiskTypes();
	}
}
