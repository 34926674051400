/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllFibus } from '../fn/fibu-controller/get-all-fibus';
import { GetAllFibus$Params } from '../fn/fibu-controller/get-all-fibus';
import { ResponseFibuDto } from '../models/response-fibu-dto';

@Injectable({ providedIn: 'root' })
export class FibuControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllFibus()` */
  static readonly GetAllFibusPath = '/fibu';

  /**
   * List all available FiBus
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllFibus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFibus$Response(params?: GetAllFibus$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseFibuDto>> {
    return getAllFibus(this.http, this.rootUrl, params, context);
  }

  /**
   * List all available FiBus
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllFibus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFibus(params?: GetAllFibus$Params, context?: HttpContext): Observable<ResponseFibuDto> {
    return this.getAllFibus$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseFibuDto>): ResponseFibuDto => r.body)
    );
  }

}
