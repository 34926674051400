export enum FileTypeEnum {
	CSV = 'CSV',
	EXCEL = 'EXCEL',
	GIF = 'GIF',
	JPG = 'JPG',
	JSON = 'JSON',
	MP4 = 'MP4',
	MSG = 'MSG',
	PDF = 'PDF',
	PNG = 'PNG',
	POWERPOINT = 'POWERPOINT',
	SVG = 'SVG',
	WORD = 'WORD',
}
