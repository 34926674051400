import { Injectable } from '@angular/core';
import { CountryControllerService, CountryDto } from '@mysvg/api/contact-data';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CountriesStoreService extends RepositoryStoreService<CountryDto[]> {
	constructor(private countryControllerService: CountryControllerService) {
		super();
	}

	fetchData(): Observable<CountryDto[]> {
		return this.countryControllerService.getAllCountries();
	}
}
