/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addNewDocument } from '../fn/customers-documents-controller/add-new-document';
import { AddNewDocument$Params } from '../fn/customers-documents-controller/add-new-document';
import { deleteCustomerDocumentByDownloadHash } from '../fn/customers-documents-controller/delete-customer-document-by-download-hash';
import { DeleteCustomerDocumentByDownloadHash$Params } from '../fn/customers-documents-controller/delete-customer-document-by-download-hash';
import { getCrefoDocumentsOfType } from '../fn/customers-documents-controller/get-crefo-documents-of-type';
import { GetCrefoDocumentsOfType$Params } from '../fn/customers-documents-controller/get-crefo-documents-of-type';
import { getCustomerDocumentByDownloadHash } from '../fn/customers-documents-controller/get-customer-document-by-download-hash';
import { GetCustomerDocumentByDownloadHash$Params } from '../fn/customers-documents-controller/get-customer-document-by-download-hash';
import { getDocumentByDownloadHash } from '../fn/customers-documents-controller/get-document-by-download-hash';
import { GetDocumentByDownloadHash$Params } from '../fn/customers-documents-controller/get-document-by-download-hash';
import { getDocumentsOfCustomer } from '../fn/customers-documents-controller/get-documents-of-customer';
import { GetDocumentsOfCustomer$Params } from '../fn/customers-documents-controller/get-documents-of-customer';
import { getPublicDocumentsOfType } from '../fn/customers-documents-controller/get-public-documents-of-type';
import { GetPublicDocumentsOfType$Params } from '../fn/customers-documents-controller/get-public-documents-of-type';
import { getRvDocumentsOfType } from '../fn/customers-documents-controller/get-rv-documents-of-type';
import { GetRvDocumentsOfType$Params } from '../fn/customers-documents-controller/get-rv-documents-of-type';
import { ResponseSvgDocument } from '../models/response-svg-document';
import { SvgDocument } from '../models/svg-document';

@Injectable({ providedIn: 'root' })
export class CustomersDocumentsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDocumentsOfCustomer()` */
  static readonly GetDocumentsOfCustomerPath = '/customers/{id}/documents';

  /**
   * get all documents of the customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentsOfCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsOfCustomer$Response(params: GetDocumentsOfCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSvgDocument>> {
    return getDocumentsOfCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * get all documents of the customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentsOfCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsOfCustomer(params: GetDocumentsOfCustomer$Params, context?: HttpContext): Observable<ResponseSvgDocument> {
    return this.getDocumentsOfCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSvgDocument>): ResponseSvgDocument => r.body)
    );
  }

  /** Path part for operation `addNewDocument()` */
  static readonly AddNewDocumentPath = '/customers/{id}/documents';

  /**
   * uploading a document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewDocument$Response(params: AddNewDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgDocument>> {
    return addNewDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * uploading a document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addNewDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewDocument(params: AddNewDocument$Params, context?: HttpContext): Observable<SvgDocument> {
    return this.addNewDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<SvgDocument>): SvgDocument => r.body)
    );
  }

  /** Path part for operation `getDocumentByDownloadHash()` */
  static readonly GetDocumentByDownloadHashPath = '/documents/{downloadHash}';

  /**
   * get document by hash.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentByDownloadHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentByDownloadHash$Response(params: GetDocumentByDownloadHash$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgDocument>> {
    return getDocumentByDownloadHash(this.http, this.rootUrl, params, context);
  }

  /**
   * get document by hash.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentByDownloadHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentByDownloadHash(params: GetDocumentByDownloadHash$Params, context?: HttpContext): Observable<SvgDocument> {
    return this.getDocumentByDownloadHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<SvgDocument>): SvgDocument => r.body)
    );
  }

  /** Path part for operation `getCustomerDocumentByDownloadHash()` */
  static readonly GetCustomerDocumentByDownloadHashPath = '/customers/{id}/documents/{downloadHash}';

  /**
   * delete customers document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerDocumentByDownloadHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDocumentByDownloadHash$Response(params: GetCustomerDocumentByDownloadHash$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSvgDocument>> {
    return getCustomerDocumentByDownloadHash(this.http, this.rootUrl, params, context);
  }

  /**
   * delete customers document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerDocumentByDownloadHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDocumentByDownloadHash(params: GetCustomerDocumentByDownloadHash$Params, context?: HttpContext): Observable<ResponseSvgDocument> {
    return this.getCustomerDocumentByDownloadHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSvgDocument>): ResponseSvgDocument => r.body)
    );
  }

  /** Path part for operation `deleteCustomerDocumentByDownloadHash()` */
  static readonly DeleteCustomerDocumentByDownloadHashPath = '/customers/{id}/documents/{downloadHash}';

  /**
   * delete customers document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerDocumentByDownloadHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerDocumentByDownloadHash$Response(params: DeleteCustomerDocumentByDownloadHash$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCustomerDocumentByDownloadHash(this.http, this.rootUrl, params, context);
  }

  /**
   * delete customers document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerDocumentByDownloadHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerDocumentByDownloadHash(params: DeleteCustomerDocumentByDownloadHash$Params, context?: HttpContext): Observable<void> {
    return this.deleteCustomerDocumentByDownloadHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getPublicDocumentsOfType()` */
  static readonly GetPublicDocumentsOfTypePath = '/customer/documents/{documentType}';

  /**
   * return public documents for customer of given type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicDocumentsOfType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicDocumentsOfType$Response(params: GetPublicDocumentsOfType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SvgDocument>>> {
    return getPublicDocumentsOfType(this.http, this.rootUrl, params, context);
  }

  /**
   * return public documents for customer of given type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicDocumentsOfType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicDocumentsOfType(params: GetPublicDocumentsOfType$Params, context?: HttpContext): Observable<Array<SvgDocument>> {
    return this.getPublicDocumentsOfType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SvgDocument>>): Array<SvgDocument> => r.body)
    );
  }

  /** Path part for operation `getRvDocumentsOfType()` */
  static readonly GetRvDocumentsOfTypePath = '/customer/documents/rv/{documentType}';

  /**
   * return rv documents for customer of given type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRvDocumentsOfType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRvDocumentsOfType$Response(params: GetRvDocumentsOfType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SvgDocument>>> {
    return getRvDocumentsOfType(this.http, this.rootUrl, params, context);
  }

  /**
   * return rv documents for customer of given type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRvDocumentsOfType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRvDocumentsOfType(params: GetRvDocumentsOfType$Params, context?: HttpContext): Observable<Array<SvgDocument>> {
    return this.getRvDocumentsOfType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SvgDocument>>): Array<SvgDocument> => r.body)
    );
  }

  /** Path part for operation `getCrefoDocumentsOfType()` */
  static readonly GetCrefoDocumentsOfTypePath = '/customer/documents/crefo/{documentType}';

  /**
   * return crefo documents for customer of given type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCrefoDocumentsOfType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCrefoDocumentsOfType$Response(params: GetCrefoDocumentsOfType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SvgDocument>>> {
    return getCrefoDocumentsOfType(this.http, this.rootUrl, params, context);
  }

  /**
   * return crefo documents for customer of given type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCrefoDocumentsOfType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCrefoDocumentsOfType(params: GetCrefoDocumentsOfType$Params, context?: HttpContext): Observable<Array<SvgDocument>> {
    return this.getCrefoDocumentsOfType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SvgDocument>>): Array<SvgDocument> => r.body)
    );
  }

}
