/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { TradeAuthorityOfficeControllerService } from './services/trade-authority-office-controller.service';
import { OccupationalInsuranceAssociationCustomerInfoControllerService } from './services/occupational-insurance-association-customer-info-controller.service';
import { CustomerOverviewControllerService } from './services/customer-overview-controller.service';
import { EconomicBranchControllerService } from './services/economic-branch-controller.service';
import { CustomerSecurityExpertControllerService } from './services/customer-security-expert-controller.service';
import { MinimalCustomerControllerService } from './services/minimal-customer-controller.service';
import { CustomerTypeControllerService } from './services/customer-type-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    TradeAuthorityOfficeControllerService,
    OccupationalInsuranceAssociationCustomerInfoControllerService,
    CustomerOverviewControllerService,
    EconomicBranchControllerService,
    CustomerSecurityExpertControllerService,
    MinimalCustomerControllerService,
    CustomerTypeControllerService,
    ApiConfiguration
  ],
})
export class CustomersCoreModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<CustomersCoreModule> {
    return {
      ngModule: CustomersCoreModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: CustomersCoreModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('CustomersCoreModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
