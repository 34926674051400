import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
	ActionDialogComponent,
	ActionDialogData,
	ActionDialogResult,
	ActionDialogState,
} from '../components/action-dialog/action-dialog.component';
import { InfoDialogComponent, InfoDialogData } from '../components/info-dialog/info-dialog.component';
import {
	SpecifyReasonDialogComponent,
	SpecifyReasonDialogData,
	SpecifyReasonResult,
} from '../components/specify-reason-dialog/specify-reason-dialog.component';
import { DialogLevel } from '../enums/dialog-level.enum';
import { DialogSize } from '../enums/dialog-size.enum';

export interface ConfirmDialogData {
	body: SafeHtml;
	submitButton: string;
	title: string;

	cancelButton?: string;
	level?: DialogLevel;
	size?: DialogSize;
}

@Injectable()
export class StandardDialogService {
	constructor(private matDialog: MatDialog) {}

	showActionDialog<T>(data: ActionDialogData<T>): Observable<ActionDialogResult<T>> {
		return this.matDialog
			.open<ActionDialogComponent<T>, ActionDialogData<T>, ActionDialogResult<T>>(ActionDialogComponent, { data })
			.afterClosed();
	}

	showInfoDialog(data: InfoDialogData): void {
		this.matDialog.open<InfoDialogComponent, InfoDialogData>(InfoDialogComponent, { data });
	}

	showConfirmationDialog(confirmDialogData: ConfirmDialogData): Observable<boolean> {
		const data: ActionDialogData<void> = { ...confirmDialogData, action$: null };
		return this.matDialog
			.open<ActionDialogComponent<void>, ActionDialogData<void>, ActionDialogResult<void>>(ActionDialogComponent, { data })
			.afterClosed()
			.pipe(map((result: ActionDialogResult<void>) => result.state === ActionDialogState.SUCCESS));
	}

	showSpecifyReasonDialog<T>(data: SpecifyReasonDialogData<T>): Observable<SpecifyReasonResult<T>> {
		return this.matDialog
			.open<SpecifyReasonDialogComponent<T>, SpecifyReasonDialogData<T>, SpecifyReasonResult<T>>(SpecifyReasonDialogComponent, {
				data,
			})
			.afterClosed();
	}
}
