import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FuelTypeDto, PublicVehiclesControllerService } from '@mysvg/api/vehicle';

import { RepositoryStoreService } from './repository-store.service';

@Injectable({ providedIn: 'root' })
export class FuelTypesStoreService extends RepositoryStoreService<FuelTypeDto[]> {
	constructor(private publicVehiclesControllerService: PublicVehiclesControllerService) {
		super();
	}

	fetchData(): Observable<FuelTypeDto[]> {
		return this.publicVehiclesControllerService.getAvailableFuelTypes();
	}
}
