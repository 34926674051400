/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseSafetyRiskDto } from '../../models/response-safety-risk-dto';

export interface GetRiskList$Params {
  rvNumber?: string;
  rvInsured?: string;
  fuelcardMaxMonth?: string;
  fuelcardRiskMax?: string;
  fuelcardRiskAverage?: string;
  tollMaxMonth?: string;
  tollRiskMax?: string;
  tollRiskAverage?: string;
  overallMaxMonth?: string;
  overallRiskMax?: string;
  overallRiskAverage?: string;
  remainingGuaranteesSum?: string;
  activeGuaranteesSum?: string;
  customerName?: string;
  customerNumber?: string;
  crefoNumber?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getRiskList(http: HttpClient, rootUrl: string, params?: GetRiskList$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSafetyRiskDto>> {
  const rb = new RequestBuilder(rootUrl, getRiskList.PATH, 'get');
  if (params) {
    rb.query('rvNumber', params.rvNumber, {});
    rb.query('rvInsured', params.rvInsured, {});
    rb.query('fuelcardMaxMonth', params.fuelcardMaxMonth, {});
    rb.query('fuelcardRiskMax', params.fuelcardRiskMax, {});
    rb.query('fuelcardRiskAverage', params.fuelcardRiskAverage, {});
    rb.query('tollMaxMonth', params.tollMaxMonth, {});
    rb.query('tollRiskMax', params.tollRiskMax, {});
    rb.query('tollRiskAverage', params.tollRiskAverage, {});
    rb.query('overallMaxMonth', params.overallMaxMonth, {});
    rb.query('overallRiskMax', params.overallRiskMax, {});
    rb.query('overallRiskAverage', params.overallRiskAverage, {});
    rb.query('remainingGuaranteesSum', params.remainingGuaranteesSum, {});
    rb.query('activeGuaranteesSum', params.activeGuaranteesSum, {});
    rb.query('customerName', params.customerName, {});
    rb.query('customerNumber', params.customerNumber, {});
    rb.query('crefoNumber', params.crefoNumber, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseSafetyRiskDto>;
    })
  );
}

getRiskList.PATH = '/safetyRisks';
