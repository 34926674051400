<form [formGroup]="formGroup" [svgFrontendsDisableForm]="disabled" clrForm>
	<ng-content></ng-content>
</form>

<!-- only show error stuff if error message is found (not only if formGroup is invalid because of nested errors) -->
<ng-container *svgFrontendsLet="formGroup.errors | firstControlError | controlErrorMessage as errorMessage">
	<div *ngIf="formGroup.invalid && formGroup.touched && errorMessage" class="clr-subtext-wrapper mac-subtext-wrapper clr-error">
		<cds-icon class="clr-validate-icon" shape="exclamation-circle"></cds-icon>
		<span class="clr-subtext">{{ errorMessage }}</span>
	</div>
</ng-container>
