import { Injectable } from '@angular/core';
import { OAuthEvent } from 'angular-oauth2-oidc';
import { merge, Observable, Subject } from 'rxjs';
import { filter, mergeMap, skip, take, tap } from 'rxjs/operators';

import { NetworkConnectionService } from '@mysvg/utils';

import { Context } from '../../context/models/context.model';
import { DefaultUserTokenClaims } from '../../context/models/user-token-claims.model';
import { ContextService } from '../../context/services/context.service';
import { OAUTH_EVENTS } from '../configs/config';
import { BrowserPageVisibilityApiService } from '../services/browser-page-visibility-api.service';
import { OauthWrapperService } from './oauth-wrapper.service';

const SILENT_REFRESH_IFRAME_NAME = 'angular-oauth-oidc-silent-refresh-iframe';

// The AuthRefreshService tries to refresh authentication after inactive tab comes back to the foreground, since subscriptions do not run if
// the browser tab is inactive (behaviour is browser dependant). If the tab is active the silent refresh will be done automatically by
// `oauth-oidc` for us. The `triggerRefresh` method can also be called in error interceptors after receiving a 401 status code.
@Injectable({ providedIn: 'root' })
export class AuthRefreshService<T extends Context, U extends DefaultUserTokenClaims> {
	private refreshTrigger$ = new Subject<void>();
	private isOnlineButNotLoggedInTrigger$: Observable<boolean>;

	constructor(
		private contextService: ContextService<T, U>,
		private networkConnectionService: NetworkConnectionService,
		private oauthWrapperService: OauthWrapperService<U>,
		private pageVisibilityService: BrowserPageVisibilityApiService,
	) {}

	init(): void {
		this.isOnlineButNotLoggedInTrigger$ = this.getOnlineButNoTokenTrigger();
		this.initGeneralRefreshLogic();

		// do manual refresh
		merge(this.isOnlineButNotLoggedInTrigger$, this.refreshTrigger$)
			.pipe(mergeMap(() => this.oauthWrapperService.manualSilentRefresh()))
			.subscribe();

		this.pageVisibilityService.getReEnterState().subscribe(() => this.checkTokenOnReEnter());
	}

	triggerRefresh(): void {
		this.refreshTrigger$.next();
	}

	/**
	 * setup silent refresh and listen to refresh events
	 */
	private initGeneralRefreshLogic(): void {
		this.afterUserLoggedIn()
			.pipe(
				take(1),
				tap(() => this.oauthWrapperService.setupAutomaticSilentRefresh()),
				mergeMap(() => this.oauthWrapperService.getSilentRefreshEvents()),
			)
			.subscribe({
				next: (event: OAuthEvent) => this.handleRefreshEvents(event.type),
			});
	}

	private afterUserLoggedIn(): Observable<T> {
		return this.contextService.getFirstContext().pipe(filter((context: T) => !context.isNotLoggedInState()));
	}

	private handleRefreshEvents(eventName: string): void {
		switch (eventName) {
			case OAUTH_EVENTS.SILENT_REFRESH.REFRESHED: {
				this.removeSilentRefreshIframe();
				this.contextService.initCustomLoginFlow();
				break;
			}
			case OAUTH_EVENTS.SILENT_REFRESH.TIMEOUT: {
				this.handleRefreshTimeout();
				break;
			}
			case OAUTH_EVENTS.SILENT_REFRESH.ERROR:
			default:
				return this.contextService.logout();
		}
	}

	/**
	 * after a refresh timout (which should always be because of user was offline)
	 * wait for user to be back online then manually refresh
	 */
	private handleRefreshTimeout(): void {
		if (this.networkConnectionService.isOffline()) {
			this.triggerRefresh();
		} else {
			this.contextService.logout();
		}
	}

	/**
	 * TODO - this should not be necessary
	 */
	private removeSilentRefreshIframe(): void {
		const iframe$ = document.getElementById(SILENT_REFRESH_IFRAME_NAME);
		if (!!iframe$) {
			document.body.removeChild(iframe$);
		}
	}

	/**
	 * create observable that fires, when online (not initially) triggered and no valid token is found
	 */
	private getOnlineButNoTokenTrigger(): Observable<boolean> {
		return this.networkConnectionService.isOnline$().pipe(
			skip(1), // initial value should be skipped
			filter((isOnline: boolean) => isOnline && !this.oauthWrapperService.hasValidAccessToken()),
		);
	}

	/**
	 * [NOTE] inactive tab / browser does not refresh token via javascript
	 * 				using this tab would result in 401 responses (sentry logging) and redirect to login page
	 * 				`checkTokenOnReEnter` avoids 401 responses by manually refreshing the token
	 */
	private checkTokenOnReEnter(): void {
		if (!this.oauthWrapperService.hasValidAccessToken()) {
			this.triggerRefresh();
		}
	}
}
