/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { VignetteControllerService } from './services/vignette-controller.service';
import { MySvgControllerService } from './services/my-svg-controller.service';
import { TransactionControllerService } from './services/transaction-controller.service';
import { PriceListControllerService } from './services/price-list-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    VignetteControllerService,
    MySvgControllerService,
    TransactionControllerService,
    PriceListControllerService,
    ApiConfiguration
  ],
})
export class VignetteModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<VignetteModule> {
    return {
      ngModule: VignetteModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: VignetteModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('VignetteModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
