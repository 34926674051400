/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getMinimalCustomers } from '../fn/minimal-customer-controller/get-minimal-customers';
import { GetMinimalCustomers$Params } from '../fn/minimal-customer-controller/get-minimal-customers';
import { ResponseMinimalCustomer } from '../models/response-minimal-customer';

@Injectable({ providedIn: 'root' })
export class MinimalCustomerControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMinimalCustomers()` */
  static readonly GetMinimalCustomersPath = '/customers/minimal';

  /**
   * selecting all customers (minimal representation)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMinimalCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMinimalCustomers$Response(params?: GetMinimalCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseMinimalCustomer>> {
    return getMinimalCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * selecting all customers (minimal representation)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMinimalCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMinimalCustomers(params?: GetMinimalCustomers$Params, context?: HttpContext): Observable<ResponseMinimalCustomer> {
    return this.getMinimalCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseMinimalCustomer>): ResponseMinimalCustomer => r.body)
    );
  }

}
