/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createTag } from '../fn/tag-controller/create-tag';
import { CreateTag$Params } from '../fn/tag-controller/create-tag';
import { deleteTag } from '../fn/tag-controller/delete-tag';
import { DeleteTag$Params } from '../fn/tag-controller/delete-tag';
import { getAllTags } from '../fn/tag-controller/get-all-tags';
import { GetAllTags$Params } from '../fn/tag-controller/get-all-tags';
import { ResponseTagDto } from '../models/response-tag-dto';
import { searchTags } from '../fn/tag-controller/search-tags';
import { SearchTags$Params } from '../fn/tag-controller/search-tags';
import { TagDto } from '../models/tag-dto';
import { updateTag } from '../fn/tag-controller/update-tag';
import { UpdateTag$Params } from '../fn/tag-controller/update-tag';


/**
 * Manages customer tags types
 */
@Injectable({ providedIn: 'root' })
export class TagControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateTag()` */
  static readonly UpdateTagPath = '/tags/{id}';

  /**
   * Update a tag
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTag$Response(params: UpdateTag$Params, context?: HttpContext): Observable<StrictHttpResponse<TagDto>> {
    return updateTag(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a tag
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTag(params: UpdateTag$Params, context?: HttpContext): Observable<TagDto> {
    return this.updateTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagDto>): TagDto => r.body)
    );
  }

  /** Path part for operation `deleteTag()` */
  static readonly DeleteTagPath = '/tags/{id}';

  /**
   * delete a tag
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTag$Response(params: DeleteTag$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTag(this.http, this.rootUrl, params, context);
  }

  /**
   * delete a tag
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTag(params: DeleteTag$Params, context?: HttpContext): Observable<void> {
    return this.deleteTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAllTags()` */
  static readonly GetAllTagsPath = '/tags';

  /**
   * Get all available tags (tags on customers)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTags$Response(params?: GetAllTags$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TagDto>>> {
    return getAllTags(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all available tags (tags on customers)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTags(params?: GetAllTags$Params, context?: HttpContext): Observable<Array<TagDto>> {
    return this.getAllTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TagDto>>): Array<TagDto> => r.body)
    );
  }

  /** Path part for operation `createTag()` */
  static readonly CreateTagPath = '/tags';

  /**
   * Create a new tag
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTag$Response(params: CreateTag$Params, context?: HttpContext): Observable<StrictHttpResponse<TagDto>> {
    return createTag(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new tag
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTag(params: CreateTag$Params, context?: HttpContext): Observable<TagDto> {
    return this.createTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagDto>): TagDto => r.body)
    );
  }

  /** Path part for operation `searchTags()` */
  static readonly SearchTagsPath = '/tags/search';

  /**
   * Get tags by query and paginated
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchTags$Response(params?: SearchTags$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseTagDto>> {
    return searchTags(this.http, this.rootUrl, params, context);
  }

  /**
   * Get tags by query and paginated
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchTags(params?: SearchTags$Params, context?: HttpContext): Observable<ResponseTagDto> {
    return this.searchTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseTagDto>): ResponseTagDto => r.body)
    );
  }

}
