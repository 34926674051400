import { Pipe, PipeTransform } from '@angular/core';
import { ACCESS_TOKEN_STORAGE_KEY, UrlParamsService } from '@mysvg/utils';

import { SvgContextService } from '../../services/svg-context.service';

@Pipe({ name: 'accessTokenToUrl' })
export class AccessTokenToUrlPipe implements PipeTransform {
	constructor(private svgContextService: SvgContextService, private urlParamsService: UrlParamsService) {}

	transform(url: string): string {
		const accessToken: string | null = this.svgContextService.getAccessToken();

		if (accessToken) {
			return this.urlParamsService.append(url, ACCESS_TOKEN_STORAGE_KEY, accessToken, false);
		} else {
			return url;
		}
	}
}
