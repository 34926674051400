import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { ErrorHandlingType } from '../enums/error-handling-type.enum';
import { ErrorHandlingService } from './error-handling.service';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
	constructor(private injector: Injector, private ngZone: NgZone, private router: Router) {}

	handleError(error: Error): void {
		const isChunkFailedMessage = /Loading chunk [\d]+ failed/.test(error.message);

		if (isChunkFailedMessage) {
			/*
				Handle chunk loading failed issue after release on clients not supporting Service Worker/pwa (private browsing, Google App, mobile safari 🤢 etc.) or when users do not click update.
				Application failed anyways so we don't need to care about saving user state/data and should only happen on navigations triggering lazy load anyways which means no sensitive data in memory.
				Solution from https://stackoverflow.com/a/59073053 as fallback.
			*/
			this.router.navigate(['update-error']);
		} else {
			// see this thread https://github.com/angular/components/issues/7550#issuecomment-345298536
			// we need to re-enter the ngZone here because errorHandler was planned to not modify ui but only log errors etc.
			this.ngZone.run(() => this.sendErrorToErrorHandlingService(error));
		}
	}

	sendErrorToErrorHandlingService(error: Error) {
		const errorHandlingService: ErrorHandlingService | undefined = this.injector.get<ErrorHandlingService>(ErrorHandlingService);

		// This ensures that we do not throw a second error, if no ErrorHandlingService can not be provided
		if (errorHandlingService !== undefined) {
			errorHandlingService.setNextErrorBy(error, ErrorHandlingType.SHOW_ERROR_DIALOG, true);
		}
	}
}
