import { Injectable } from '@angular/core';
import { Customer, PiaControllerService } from '@mysvg/api/pia';
import { SvgContextService } from '@svg-frontends/session';
import { RepositoryStoreService } from '../repository-store.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CustomerStoreService extends RepositoryStoreService<Customer> {
	constructor(private piaControllerService: PiaControllerService, private svgContextService: SvgContextService) {
		super();
	}

	fetchData(): Observable<Customer> {
		return this.svgContextService
			.getFirstPortalUid()
			.pipe(mergeMap((portalUid: string) => this.piaControllerService.getCustomerByPortalId({ id: portalUid })));
	}
}
