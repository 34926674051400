import { Injectable } from '@angular/core';
import { SafetyControllerService, SafetyDto } from '@mysvg/api/financial-guarantees';
import { SvgContextService } from '@svg-frontends/session';
import { RepositoryStoreService } from '../repository-store.service';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SafetyStoreService extends RepositoryStoreService<SafetyDto[]> {
	constructor(private safetyControllerService: SafetyControllerService, private svgContextService: SvgContextService) {
		super();
	}

	fetchData(): Observable<SafetyDto[]> {
		return this.svgContextService
			.getFirstPortalUid()
			.pipe(mergeMap((portalUid: string) => this.safetyControllerService.getSafetiesForCustomer({ portalUid })));
	}

	findSafetyById(safetyId: string): Observable<SafetyDto | null> {
		return this.getFirst().pipe(
			map((safeties: SafetyDto[]) => {
				const match: SafetyDto | undefined = safeties.find((safety: SafetyDto) => safety.id.toString() === safetyId);
				return match ? match : null;
			}),
		);
	}
}
