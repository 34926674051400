/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { RejectionDto } from '../models/rejection-dto';
import { rejectSuggestion } from '../fn/suggestion-controller/reject-suggestion';
import { RejectSuggestion$Params } from '../fn/suggestion-controller/reject-suggestion';

@Injectable({ providedIn: 'root' })
export class SuggestionControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `rejectSuggestion()` */
  static readonly RejectSuggestionPath = '/suggestions/reject';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectSuggestion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rejectSuggestion$Response(params: RejectSuggestion$Params, context?: HttpContext): Observable<StrictHttpResponse<RejectionDto>> {
    return rejectSuggestion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rejectSuggestion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rejectSuggestion(params: RejectSuggestion$Params, context?: HttpContext): Observable<RejectionDto> {
    return this.rejectSuggestion$Response(params, context).pipe(
      map((r: StrictHttpResponse<RejectionDto>): RejectionDto => r.body)
    );
  }

}
