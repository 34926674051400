import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Pipe({ name: 'isFormControlRequired' })
export class IsFormControlRequiredPipe implements PipeTransform {
	transform(control: FormControl): boolean {
		if (control && control.validator) {
			// This is a hack // TODO @MAX what is the hack?
			const validator = control.validator({} as AbstractControl);

			if (validator && validator['required']) {
				return true;
			}
		}

		return false;
	}
}
