/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getMinimalEmployee } from '../fn/minimal-employee-controller/get-minimal-employee';
import { GetMinimalEmployee$Params } from '../fn/minimal-employee-controller/get-minimal-employee';
import { getMinimalEmployees } from '../fn/minimal-employee-controller/get-minimal-employees';
import { GetMinimalEmployees$Params } from '../fn/minimal-employee-controller/get-minimal-employees';
import { MinimalEmployee } from '../models/minimal-employee';
import { ResponseMinimalEmployee } from '../models/response-minimal-employee';

@Injectable({ providedIn: 'root' })
export class MinimalEmployeeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMinimalEmployee()` */
  static readonly GetMinimalEmployeePath = '/employees/minimal';

  /**
   * selecting a single employee (minimal representation)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMinimalEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMinimalEmployee$Response(params: GetMinimalEmployee$Params, context?: HttpContext): Observable<StrictHttpResponse<MinimalEmployee>> {
    return getMinimalEmployee(this.http, this.rootUrl, params, context);
  }

  /**
   * selecting a single employee (minimal representation)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMinimalEmployee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMinimalEmployee(params: GetMinimalEmployee$Params, context?: HttpContext): Observable<MinimalEmployee> {
    return this.getMinimalEmployee$Response(params, context).pipe(
      map((r: StrictHttpResponse<MinimalEmployee>): MinimalEmployee => r.body)
    );
  }

  /** Path part for operation `getMinimalEmployees()` */
  static readonly GetMinimalEmployeesPath = '/employees/minimal/list';

  /**
   * selecting all employees (minimal representation)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMinimalEmployees()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMinimalEmployees$Response(params?: GetMinimalEmployees$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseMinimalEmployee>> {
    return getMinimalEmployees(this.http, this.rootUrl, params, context);
  }

  /**
   * selecting all employees (minimal representation)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMinimalEmployees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMinimalEmployees(params?: GetMinimalEmployees$Params, context?: HttpContext): Observable<ResponseMinimalEmployee> {
    return this.getMinimalEmployees$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseMinimalEmployee>): ResponseMinimalEmployee => r.body)
    );
  }

}
