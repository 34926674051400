import { MinimalEmployee } from '@mysvg/api/auth';

import { User } from '../models/user';

export class AuthenticationUtilsService {
	static getUserName = (user: User | MinimalEmployee): string => {
		if (user instanceof User) {
			return user.name;
		} else {
			return user.loginName;
		}
	};
}
