/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createSafety } from '../fn/safety-controller/create-safety';
import { CreateSafety$Params } from '../fn/safety-controller/create-safety';
import { getActiveSafetiesForCustomer } from '../fn/safety-controller/get-active-safeties-for-customer';
import { GetActiveSafetiesForCustomer$Params } from '../fn/safety-controller/get-active-safeties-for-customer';
import { getAllStatus } from '../fn/safety-controller/get-all-status';
import { GetAllStatus$Params } from '../fn/safety-controller/get-all-status';
import { getArchivedSafetiesForCustomer } from '../fn/safety-controller/get-archived-safeties-for-customer';
import { GetArchivedSafetiesForCustomer$Params } from '../fn/safety-controller/get-archived-safeties-for-customer';
import { getHistory } from '../fn/safety-controller/get-history';
import { GetHistory$Params } from '../fn/safety-controller/get-history';
import { getSafetiesForCustomer } from '../fn/safety-controller/get-safeties-for-customer';
import { GetSafetiesForCustomer$Params } from '../fn/safety-controller/get-safeties-for-customer';
import { getSafetyById } from '../fn/safety-controller/get-safety-by-id';
import { GetSafetyById$Params } from '../fn/safety-controller/get-safety-by-id';
import { getSafetyTypes } from '../fn/safety-controller/get-safety-types';
import { GetSafetyTypes$Params } from '../fn/safety-controller/get-safety-types';
import { getSubTypes } from '../fn/safety-controller/get-sub-types';
import { GetSubTypes$Params } from '../fn/safety-controller/get-sub-types';
import { SafetyDto } from '../models/safety-dto';
import { SafetyHistoryDto } from '../models/safety-history-dto';
import { SafetyStatusDto } from '../models/safety-status-dto';
import { SafetySubTypeDto } from '../models/safety-sub-type-dto';
import { SafetyTypeDto } from '../models/safety-type-dto';
import { terminateSafety } from '../fn/safety-controller/terminate-safety';
import { TerminateSafety$Params } from '../fn/safety-controller/terminate-safety';
import { updateSafety } from '../fn/safety-controller/update-safety';
import { UpdateSafety$Params } from '../fn/safety-controller/update-safety';

@Injectable({ providedIn: 'root' })
export class SafetyControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSafetyById()` */
  static readonly GetSafetyByIdPath = '/safeties/{id}';

  /**
   * Get single safety instance.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSafetyById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSafetyById$Response(params: GetSafetyById$Params, context?: HttpContext): Observable<StrictHttpResponse<SafetyDto>> {
    return getSafetyById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get single safety instance.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSafetyById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSafetyById(params: GetSafetyById$Params, context?: HttpContext): Observable<SafetyDto> {
    return this.getSafetyById$Response(params, context).pipe(
      map((r: StrictHttpResponse<SafetyDto>): SafetyDto => r.body)
    );
  }

  /** Path part for operation `updateSafety()` */
  static readonly UpdateSafetyPath = '/safeties/{id}';

  /**
   * Safety update (The only parameters to update are amount and status)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSafety()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSafety$Response(params: UpdateSafety$Params, context?: HttpContext): Observable<StrictHttpResponse<SafetyDto>> {
    return updateSafety(this.http, this.rootUrl, params, context);
  }

  /**
   * Safety update (The only parameters to update are amount and status)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSafety$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSafety(params: UpdateSafety$Params, context?: HttpContext): Observable<SafetyDto> {
    return this.updateSafety$Response(params, context).pipe(
      map((r: StrictHttpResponse<SafetyDto>): SafetyDto => r.body)
    );
  }

  /** Path part for operation `getSafetiesForCustomer()` */
  static readonly GetSafetiesForCustomerPath = '/safeties';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSafetiesForCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSafetiesForCustomer$Response(params: GetSafetiesForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SafetyDto>>> {
    return getSafetiesForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSafetiesForCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSafetiesForCustomer(params: GetSafetiesForCustomer$Params, context?: HttpContext): Observable<Array<SafetyDto>> {
    return this.getSafetiesForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SafetyDto>>): Array<SafetyDto> => r.body)
    );
  }

  /** Path part for operation `createSafety()` */
  static readonly CreateSafetyPath = '/safeties';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSafety()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSafety$Response(params: CreateSafety$Params, context?: HttpContext): Observable<StrictHttpResponse<SafetyDto>> {
    return createSafety(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSafety$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSafety(params: CreateSafety$Params, context?: HttpContext): Observable<SafetyDto> {
    return this.createSafety$Response(params, context).pipe(
      map((r: StrictHttpResponse<SafetyDto>): SafetyDto => r.body)
    );
  }

  /** Path part for operation `terminateSafety()` */
  static readonly TerminateSafetyPath = '/safeties/{id}/terminate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `terminateSafety()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateSafety$Response(params: TerminateSafety$Params, context?: HttpContext): Observable<StrictHttpResponse<SafetyDto>> {
    return terminateSafety(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `terminateSafety$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateSafety(params: TerminateSafety$Params, context?: HttpContext): Observable<SafetyDto> {
    return this.terminateSafety$Response(params, context).pipe(
      map((r: StrictHttpResponse<SafetyDto>): SafetyDto => r.body)
    );
  }

  /** Path part for operation `getHistory()` */
  static readonly GetHistoryPath = '/safeties/{id}/history';

  /**
   * Get safety history (values and status changes) for id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHistory$Response(params: GetHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<SafetyHistoryDto>> {
    return getHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * Get safety history (values and status changes) for id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHistory(params: GetHistory$Params, context?: HttpContext): Observable<SafetyHistoryDto> {
    return this.getHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<SafetyHistoryDto>): SafetyHistoryDto => r.body)
    );
  }

  /** Path part for operation `getSafetyTypes()` */
  static readonly GetSafetyTypesPath = '/safeties/safety-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSafetyTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSafetyTypes$Response(params?: GetSafetyTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SafetyTypeDto>>> {
    return getSafetyTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSafetyTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSafetyTypes(params?: GetSafetyTypes$Params, context?: HttpContext): Observable<Array<SafetyTypeDto>> {
    return this.getSafetyTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SafetyTypeDto>>): Array<SafetyTypeDto> => r.body)
    );
  }

  /** Path part for operation `getSubTypes()` */
  static readonly GetSubTypesPath = '/safeties/safety-sub-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubTypes$Response(params?: GetSubTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SafetySubTypeDto>>> {
    return getSubTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSubTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubTypes(params?: GetSubTypes$Params, context?: HttpContext): Observable<Array<SafetySubTypeDto>> {
    return this.getSubTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SafetySubTypeDto>>): Array<SafetySubTypeDto> => r.body)
    );
  }

  /** Path part for operation `getAllStatus()` */
  static readonly GetAllStatusPath = '/safeties/safety-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStatus$Response(params?: GetAllStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SafetyStatusDto>>> {
    return getAllStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStatus(params?: GetAllStatus$Params, context?: HttpContext): Observable<Array<SafetyStatusDto>> {
    return this.getAllStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SafetyStatusDto>>): Array<SafetyStatusDto> => r.body)
    );
  }

  /** Path part for operation `getArchivedSafetiesForCustomer()` */
  static readonly GetArchivedSafetiesForCustomerPath = '/safeties/archived-safeties';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArchivedSafetiesForCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArchivedSafetiesForCustomer$Response(params: GetArchivedSafetiesForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SafetyDto>>> {
    return getArchivedSafetiesForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArchivedSafetiesForCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArchivedSafetiesForCustomer(params: GetArchivedSafetiesForCustomer$Params, context?: HttpContext): Observable<Array<SafetyDto>> {
    return this.getArchivedSafetiesForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SafetyDto>>): Array<SafetyDto> => r.body)
    );
  }

  /** Path part for operation `getActiveSafetiesForCustomer()` */
  static readonly GetActiveSafetiesForCustomerPath = '/safeties/active-safeties';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveSafetiesForCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveSafetiesForCustomer$Response(params: GetActiveSafetiesForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SafetyDto>>> {
    return getActiveSafetiesForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveSafetiesForCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveSafetiesForCustomer(params: GetActiveSafetiesForCustomer$Params, context?: HttpContext): Observable<Array<SafetyDto>> {
    return this.getActiveSafetiesForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SafetyDto>>): Array<SafetyDto> => r.body)
    );
  }

}
