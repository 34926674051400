import { LabeledEnumModel } from '../models/enum/labeledEnum.model';

export const SafetyUrl: LabeledEnumModel = {
	vertrag: { label: '00' },
	berichte: { label: '01' },
	gfb: { label: '02' },
	schul: { label: '03' },
	schriftv: { label: '04' },
	barzt: { label: '05' },
	betranw: { label: '06' },
	infoschr: { label: '07' },
	pruef: { label: '08' },
	unfaelle: { label: '09' },
	rgen: { label: '10' },
};
