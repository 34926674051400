import { Pipe, PipeTransform } from '@angular/core';

import { AgesEmissionClass } from '../enums/ages-emission-class.enum';
import { AxleEnum } from '../enums/axle.enum';
import { AxleClass } from '../enums/axleclass.enum';
import { ColumnEnum } from '../enums/column.enum';
import { Country } from '../enums/country.enum';
import { CustomerLockStatus } from '../enums/custer-lock-status.enum';
import { EuroEmissionClass } from '../enums/euro-emission-class.enum';
import { InvoiceCategoriesEnum } from '../enums/invoice-categories.enum';
import { MsgCategoriesEnum } from '../enums/messages/msg-category.enum';
import { MsgPriorities } from '../enums/messages/msg-priority.enum';
import { MsgStates } from '../enums/messages/msg-state.enum';
import { MsgSubcategoriesEnum } from '../enums/messages/msg-subcategory.enum';
import { ProcessStateEnum } from '../enums/processes/process-state.enum';
import { ProvidersEnum } from '../enums/provider.enum';
import { QuantityUnitEnum } from '../enums/quantityunit.enum';
import { RegistrationStatus } from '../enums/registrationStatus.enum';
import { TollBoxStatus } from '../enums/toll-box-status.enum';
import { VehicleFinancingTypeClass } from '../enums/vehicleFinancingType.enum';
import { VehicleFuelType } from '../enums/vehicleFuelType.enum';
import { VehicleType } from '../enums/vehicleType.enum';

@Pipe({ name: 'enum' })
export class EnumPipe implements PipeTransform {
	enums: any = {
		agesEmissionClass: AgesEmissionClass,
		axle: AxleEnum,
		axleClass: AxleClass,
		category: MsgCategoriesEnum,
		column: ColumnEnum,
		country: Country,
		customerLockState: CustomerLockStatus,
		documentType: DocumentType,
		euroEmissionClass: EuroEmissionClass,
		invoiceCategory: InvoiceCategoriesEnum,
		priority: MsgPriorities,
		processState: ProcessStateEnum,
		provider: ProvidersEnum,
		quantityunit: QuantityUnitEnum,
		registrationStatus: RegistrationStatus,
		state: MsgStates,
		subcategory: MsgSubcategoriesEnum,
		tollBoxStatus: TollBoxStatus,
		vehicleFinancingType: VehicleFinancingTypeClass,
		vehicleFuelType: VehicleFuelType,
		vehicleType: VehicleType,
	};

	transform(input: string | number, enumName: string, property: string = null): string | any {
		// no input or no enum found return empty string
		if (!input || Object.keys(this.enums).indexOf(enumName) === -1) {
			return '';
		} else if (property !== null) {
			const e = this.enums[enumName][input];
			return e ? e[property] : '';
		} else {
			return this.enums[enumName][input];
		}
	}
}
