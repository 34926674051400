/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createTcPaymentNumber } from '../fn/tc-payment-controller/create-tc-payment-number';
import { CreateTcPaymentNumber$Params } from '../fn/tc-payment-controller/create-tc-payment-number';
import { getCustomersInTcProcess } from '../fn/tc-payment-controller/get-customers-in-tc-process';
import { GetCustomersInTcProcess$Params } from '../fn/tc-payment-controller/get-customers-in-tc-process';
import { getCustomersWithoutTcPaymentNumber } from '../fn/tc-payment-controller/get-customers-without-tc-payment-number';
import { GetCustomersWithoutTcPaymentNumber$Params } from '../fn/tc-payment-controller/get-customers-without-tc-payment-number';
import { getTcPaymentNumber } from '../fn/tc-payment-controller/get-tc-payment-number';
import { GetTcPaymentNumber$Params } from '../fn/tc-payment-controller/get-tc-payment-number';
import { TcPaymentCustomerDto } from '../models/tc-payment-customer-dto';
import { TcPaymentNumberDto } from '../models/tc-payment-number-dto';

@Injectable({ providedIn: 'root' })
export class TcPaymentControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTcPaymentNumber()` */
  static readonly GetTcPaymentNumberPath = '/tc-payment-number/{portalUid}';

  /**
   * Provides the tc payment number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTcPaymentNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTcPaymentNumber$Response(params: GetTcPaymentNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<TcPaymentNumberDto>> {
    return getTcPaymentNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * Provides the tc payment number
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTcPaymentNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTcPaymentNumber(params: GetTcPaymentNumber$Params, context?: HttpContext): Observable<TcPaymentNumberDto> {
    return this.getTcPaymentNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcPaymentNumberDto>): TcPaymentNumberDto => r.body)
    );
  }

  /** Path part for operation `createTcPaymentNumber()` */
  static readonly CreateTcPaymentNumberPath = '/tc-payment-number/{portalUid}';

  /**
   * Creates the tc payment number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTcPaymentNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTcPaymentNumber$Response(params: CreateTcPaymentNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<TcPaymentNumberDto>> {
    return createTcPaymentNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates the tc payment number
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTcPaymentNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTcPaymentNumber(params: CreateTcPaymentNumber$Params, context?: HttpContext): Observable<TcPaymentNumberDto> {
    return this.createTcPaymentNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcPaymentNumberDto>): TcPaymentNumberDto => r.body)
    );
  }

  /** Path part for operation `getCustomersWithoutTcPaymentNumber()` */
  static readonly GetCustomersWithoutTcPaymentNumberPath = '/tc-payment-number/customers';

  /**
   * Provides the customers which can be registered with tc payment number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomersWithoutTcPaymentNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersWithoutTcPaymentNumber$Response(params?: GetCustomersWithoutTcPaymentNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TcPaymentCustomerDto>>> {
    return getCustomersWithoutTcPaymentNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * Provides the customers which can be registered with tc payment number
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomersWithoutTcPaymentNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersWithoutTcPaymentNumber(params?: GetCustomersWithoutTcPaymentNumber$Params, context?: HttpContext): Observable<Array<TcPaymentCustomerDto>> {
    return this.getCustomersWithoutTcPaymentNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TcPaymentCustomerDto>>): Array<TcPaymentCustomerDto> => r.body)
    );
  }

  /** Path part for operation `getCustomersInTcProcess()` */
  static readonly GetCustomersInTcProcessPath = '/tc-payment-number/customers-in-process';

  /**
   * Provides the customers which can be registered with tc payment number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomersInTcProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersInTcProcess$Response(params?: GetCustomersInTcProcess$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TcPaymentCustomerDto>>> {
    return getCustomersInTcProcess(this.http, this.rootUrl, params, context);
  }

  /**
   * Provides the customers which can be registered with tc payment number
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomersInTcProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersInTcProcess(params?: GetCustomersInTcProcess$Params, context?: HttpContext): Observable<Array<TcPaymentCustomerDto>> {
    return this.getCustomersInTcProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TcPaymentCustomerDto>>): Array<TcPaymentCustomerDto> => r.body)
    );
  }

}
