import { NgModule } from '@angular/core';
import { ControlErrorFactoryService } from '@svg-frontends/error';

@NgModule({
	providers: [
		{ provide: ControlErrorFactoryService, useClass: ControlErrorFactoryService },
		// TODO maybe add provider stuff for errorMessageFactory or SvgErrorMessageFactory
	],
})
export class ErrorMessageModule {}
