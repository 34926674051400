/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseBodyTcRegistrationDto } from '../../models/response-body-tc-registration-dto';

export interface GetAllTcRegistrations$Params {

/**
 * limit for pagination
 */
  limit?: number;

/**
 * offset for pagination
 */
  offset?: number;
  sortOrder?: 'ASC' | 'DESC';
  sortBy?: string;

/**
 * UUID to filter for a customer
 */
  customerId?: string;

/**
 * UUID to filter for a SVG
 */
  svgId?: string;

/**
 * unique customer number
 */
  customerNumber?: string;

/**
 * customer name / company name
 */
  customerName?: string;

/**
 * search string for TC Payment Number, will find matches that start with the search string
 */
  tcPaymentNumber?: string;

/**
 * search string for TC Customer ID, will find matches that start with the search string
 */
  tcCustomerId?: string;

/**
 * show only data with TC Billing Cycle Group Number equal to or greater than this value
 */
  tcBillingCycleGroupNumberMin?: number;

/**
 * show only data with TC Billing Cycle Group Number equal to or less than this value
 */
  tcBillingCycleGroupNumberMax?: number;

/**
 * Filter TCRegistrations by their lock state
 */
  lock?: 'unspecified' | 'false' | 'true';

/**
 * The reason why the customer is locked
 */
  lockCause?: string;

/**
 * Filter TCRegistrations by state
 */
  state?: (Array<'Created' | 'Active' | 'Terminated'>) | null;

/**
 * show only data which was created starting with the given date (inclusive)
 */
  createdAtMin?: string;

/**
 * show only data which was created ending at the given date (inclusive)
 */
  createdAtMax?: string;
}

export function getAllTcRegistrations(http: HttpClient, rootUrl: string, params?: GetAllTcRegistrations$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBodyTcRegistrationDto>> {
  const rb = new RequestBuilder(rootUrl, getAllTcRegistrations.PATH, 'get');
  if (params) {
    rb.query('limit', params.limit, {"explode":false});
    rb.query('offset', params.offset, {"explode":false});
    rb.query('sortOrder', params.sortOrder, {"explode":false});
    rb.query('sortBy', params.sortBy, {"explode":false});
    rb.query('customerId', params.customerId, {"explode":false});
    rb.query('svgId', params.svgId, {"explode":false});
    rb.query('customerNumber', params.customerNumber, {"explode":false});
    rb.query('customerName', params.customerName, {"explode":false});
    rb.query('tcPaymentNumber', params.tcPaymentNumber, {"explode":false});
    rb.query('tcCustomerId', params.tcCustomerId, {"explode":false});
    rb.query('tcBillingCycleGroupNumberMin', params.tcBillingCycleGroupNumberMin, {"explode":false});
    rb.query('tcBillingCycleGroupNumberMax', params.tcBillingCycleGroupNumberMax, {"explode":false});
    rb.query('lock', params.lock, {"explode":false});
    rb.query('lockCause', params.lockCause, {"explode":false});
    rb.query('state', params.state, {"explode":false});
    rb.query('createdAtMin', params.createdAtMin, {"explode":false});
    rb.query('createdAtMax', params.createdAtMax, {"explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseBodyTcRegistrationDto>;
    })
  );
}

getAllTcRegistrations.PATH = '/registrations';
