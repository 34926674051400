import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppendToOptions, MacAppendTo } from '@mysvg/utils';

import { AbstractControlComponent } from '../../classes/AbstractControlComponent';
import { FormStatus } from '../../enums/form-status.enum';
import { FormGroupService } from '../../services/form-group.service';

@UntilDestroy()
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-select-form-control',
	styleUrls: ['./select-form-control.component.scss'],
	templateUrl: './select-form-control.component.html',
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class SelectFormControlComponent extends AbstractControlComponent implements OnInit {
	FORM_STATUS = FormStatus;

	formControl: FormControl;
	inputTouched = false;

	@Input() appendTo: MacAppendTo = AppendToOptions.BODY;
	@Input() bindLabel?: string;
	@Input() bindValue?: string;
	@Input() controlName: string;
	@Input() disableControl: boolean = undefined;
	@Input() groupBy?: string | ((item: any) => string);
	@Input() helperText?: string | TemplateRef<any>;
	@Input() label?: string | TemplateRef<any>;
	@Input() labelTemplate?: TemplateRef<any>;
	@Input() loadingTextTemplate?: TemplateRef<any>;
	@Input() multiple = false;
	@Input() notFoundTemplate?: TemplateRef<any>;
	@Input() notFoundText?: string;
	@Input() optionGroupTemplate?: TemplateRef<any>;
	@Input() optionTemplate?: TemplateRef<any>;
	@Input() placeholder?: string;
	@Input() possibleValues: any[];
	@Input() searchFn?: any;
	@Input() selectableGroup?: boolean;
	@Input() selectableGroupAsModel?: boolean;
	@Input() selectSingleOption = true;
	@Input() sortPossibleValues = true;

	@Output() changeSelection: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private formGroupDirective: FormGroupDirective,
		private macFormGroupService: FormGroupService,
	) {
		super();
	}

	ngOnInit(): void {
		this.formControl = this.formGroupDirective.control.get(this.controlName) as FormControl;

		this.macFormGroupService.submit$.pipe(untilDestroyed(this)).subscribe(() => {
			FormGroupService.touchControl(this.formControl);
			this.changeDetectorRef.markForCheck();
		});

		if (this.selectSingleOption && this.possibleValues?.length === 1) {
			if (this.bindValue) {
				this.formControl.setValue(this.possibleValues[0][this.bindValue]);
			} else {
				this.formControl.setValue(this.possibleValues[0]);
			}
		}
	}

	onBlur(): void {
		if (!this.inputTouched) {
			this.inputTouched = true;
		}

		FormGroupService.touchControl(this.formControl);
	}
}
