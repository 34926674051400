import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { CONTROL_ERROR_KEYS } from '../enums/control-error-keys.enum';
import { SvgErrorMessageFactoryService } from './error-message-factory/svg-error-message-factory.service';

export abstract class BaseControlErrorFactoryService {
	abstract mapToErrorMessage(key: CONTROL_ERROR_KEYS, value?: any): string;
}

@Injectable()
export class ControlErrorFactoryService extends BaseControlErrorFactoryService {
	constructor(@Inject(LOCALE_ID) private locale: string, private errorMessageFactoryService: SvgErrorMessageFactoryService) {
		super();
	}

	mapToErrorMessage(key: CONTROL_ERROR_KEYS, value?: any): string {
		switch (key) {
			// formerly ControlErrorFactoryService
			case CONTROL_ERROR_KEYS.OWL_DATE_MIN:
				return `Das eingegebene Datum liegt zu weit in der Vergangenheit`;
			case CONTROL_ERROR_KEYS.OWL_DATE_MAX:
				return `Das eingegebene Datum liegt zu weit in der Zukunft`;
			case CONTROL_ERROR_KEYS.CSTM_DATE_MUST_BEFORE:
				// hard coded locale to de
				return `Das Datum muss vor dem Datum ${formatDate(value, 'dd.MM.yyyy', this.locale)} liegen.`;
			case CONTROL_ERROR_KEYS.CSTM_VAT_NUMBER_PATTERN:
				return `Die USt-IdNr muss aus zwei Großbuchstaben und neun Zahlen bestehen.`;
			// formerly ControlErrorFactoryService
			case CONTROL_ERROR_KEYS.DATE:
				return 'Die Eingabe ist kein validas Datum';
			case CONTROL_ERROR_KEYS.END_DATE:
				return 'Das eingebene Datum darf nicht vor dem Startdatum sein';
			case CONTROL_ERROR_KEYS.IS_MAIL:
				return `Die Eingabe ist keine valide E-Mail-Adresse: ${value}`;
			case CONTROL_ERROR_KEYS.MAIL_CONTAINS_WHITESPACES:
				return `Die eingegebene E-Mail-Adresse enthält Leerzeichen: ${value}`;
			case CONTROL_ERROR_KEYS.IS_NUMBER:
				return 'Das eingegebene Zahlen-Format ist nicht korrekt';
			case CONTROL_ERROR_KEYS.MAX_DECIMAL_PLACES:
				return 'Zu viele Nachkommastellen';
			case CONTROL_ERROR_KEYS.PATTERN_ALPHA_NUMERIC:
				return 'Die Eingabe muss alphanumerisch sein';
			case CONTROL_ERROR_KEYS.MIN_DECIMAL_PLACES:
				return 'Zu wenige Nachkommastellen';
			case CONTROL_ERROR_KEYS.VALUE_BIGGER_OR_EQUALS:
				return 'Der eingebene Wert ist zu klein';
			case CONTROL_ERROR_KEYS.VALUE_EQUALS:
				return 'Die Passwörter stimmen nicht überein';
			case CONTROL_ERROR_KEYS.VALUE_SMALLER_OR_EQUALS:
				return 'Der eingebene Wert ist zu groß';
			case CONTROL_ERROR_KEYS.MAXIMUM_FILE_SIZE_EXCEEDED:
				return 'Die Datei ist zu groß.';
			case CONTROL_ERROR_KEYS.NOT_ALLOWED_FILE_TYPES:
				return 'Der Dateityp ist hier nicht erlaubt.';

			// formerly ControlErrorFactoryService
			case CONTROL_ERROR_KEYS.EMAIL:
				return `Die Eingabe ist keine valide E-Mail-Adresse: ${value}`;
			case CONTROL_ERROR_KEYS.MAX:
				return `Der eingegebene Wert (${value.actual}) ist zu groß und darf (${value.max}) nicht überschreiten.`;
			case CONTROL_ERROR_KEYS.MAX_LENGTH:
				return `Die Eingabe ist zu lang (${value.actualLength}/${value.requiredLength})`;
			case CONTROL_ERROR_KEYS.MIN:
				return `Der eingegebene Wert (${value.actual}) ist zu klein und darf (${value.min}) nicht unterschreiten.`;
			case CONTROL_ERROR_KEYS.MIN_LENGTH:
				return `Die Eingabe ist zu kurz (${value.actualLength}/${value.requiredLength})`;
			case CONTROL_ERROR_KEYS.PATTERN:
				return 'Die Eingabe entspricht nicht dem vorgegeben Muster';
			case CONTROL_ERROR_KEYS.REQUIRED:
				return 'Dies ist ein Pflichtfeld';

			default:
				return this.errorMessageFactoryService.getMessageForKey(key) || 'Diese Eingabe ist nicht korrekt';
		}
	}
}
