/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createVehicle } from '../fn/vehicles-controller/create-vehicle';
import { CreateVehicle$Params } from '../fn/vehicles-controller/create-vehicle';
import { deleteVehicle } from '../fn/vehicles-controller/delete-vehicle';
import { DeleteVehicle$Params } from '../fn/vehicles-controller/delete-vehicle';
import { getFilterOptions } from '../fn/vehicles-controller/get-filter-options';
import { GetFilterOptions$Params } from '../fn/vehicles-controller/get-filter-options';
import { getMinimalVehicles } from '../fn/vehicles-controller/get-minimal-vehicles';
import { GetMinimalVehicles$Params } from '../fn/vehicles-controller/get-minimal-vehicles';
import { getRequiredFields } from '../fn/vehicles-controller/get-required-fields';
import { GetRequiredFields$Params } from '../fn/vehicles-controller/get-required-fields';
import { getTotalCount } from '../fn/vehicles-controller/get-total-count';
import { GetTotalCount$Params } from '../fn/vehicles-controller/get-total-count';
import { getVehicleById } from '../fn/vehicles-controller/get-vehicle-by-id';
import { GetVehicleById$Params } from '../fn/vehicles-controller/get-vehicle-by-id';
import { getVehicleFields } from '../fn/vehicles-controller/get-vehicle-fields';
import { GetVehicleFields$Params } from '../fn/vehicles-controller/get-vehicle-fields';
import { getVehicles } from '../fn/vehicles-controller/get-vehicles';
import { GetVehicles$Params } from '../fn/vehicles-controller/get-vehicles';
import { getVehiclesByLicensePlate } from '../fn/vehicles-controller/get-vehicles-by-license-plate';
import { GetVehiclesByLicensePlate$Params } from '../fn/vehicles-controller/get-vehicles-by-license-plate';
import { getVehicleTypes } from '../fn/vehicles-controller/get-vehicle-types';
import { GetVehicleTypes$Params } from '../fn/vehicles-controller/get-vehicle-types';
import { getVignetteVehicles } from '../fn/vehicles-controller/get-vignette-vehicles';
import { GetVignetteVehicles$Params } from '../fn/vehicles-controller/get-vignette-vehicles';
import { isBoxValid } from '../fn/vehicles-controller/is-box-valid';
import { IsBoxValid$Params } from '../fn/vehicles-controller/is-box-valid';
import { patchVehicle } from '../fn/vehicles-controller/patch-vehicle';
import { PatchVehicle$Params } from '../fn/vehicles-controller/patch-vehicle';
import { ResponseMinimalVehicle } from '../models/response-minimal-vehicle';
import { ResponseVehicle } from '../models/response-vehicle';
import { ResponseVignetteVehicle } from '../models/response-vignette-vehicle';
import { updateVehicle } from '../fn/vehicles-controller/update-vehicle';
import { UpdateVehicle$Params } from '../fn/vehicles-controller/update-vehicle';
import { Vehicle } from '../models/vehicle';
import { VehicleField } from '../models/vehicle-field';

@Injectable({ providedIn: 'root' })
export class VehiclesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getVehicleById()` */
  static readonly GetVehicleByIdPath = '/vehicles/{id}';

  /**
   * get the vehicle.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleById$Response(params: GetVehicleById$Params, context?: HttpContext): Observable<StrictHttpResponse<Vehicle>> {
    return getVehicleById(this.http, this.rootUrl, params, context);
  }

  /**
   * get the vehicle.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicleById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleById(params: GetVehicleById$Params, context?: HttpContext): Observable<Vehicle> {
    return this.getVehicleById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Vehicle>): Vehicle => r.body)
    );
  }

  /** Path part for operation `updateVehicle()` */
  static readonly UpdateVehiclePath = '/vehicles/{id}';

  /**
   * updating the vehicle.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVehicle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVehicle$Response(params: UpdateVehicle$Params, context?: HttpContext): Observable<StrictHttpResponse<Vehicle>> {
    return updateVehicle(this.http, this.rootUrl, params, context);
  }

  /**
   * updating the vehicle.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateVehicle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVehicle(params: UpdateVehicle$Params, context?: HttpContext): Observable<Vehicle> {
    return this.updateVehicle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Vehicle>): Vehicle => r.body)
    );
  }

  /** Path part for operation `deleteVehicle()` */
  static readonly DeleteVehiclePath = '/vehicles/{id}';

  /**
   * deleting the vehicle
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteVehicle()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVehicle$Response(params: DeleteVehicle$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteVehicle(this.http, this.rootUrl, params, context);
  }

  /**
   * deleting the vehicle
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteVehicle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVehicle(params: DeleteVehicle$Params, context?: HttpContext): Observable<void> {
    return this.deleteVehicle$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchVehicle()` */
  static readonly PatchVehiclePath = '/vehicles/{id}';

  /**
   * patches the vehicle
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVehicle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchVehicle$Response(params: PatchVehicle$Params, context?: HttpContext): Observable<StrictHttpResponse<Vehicle>> {
    return patchVehicle(this.http, this.rootUrl, params, context);
  }

  /**
   * patches the vehicle
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchVehicle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchVehicle(params: PatchVehicle$Params, context?: HttpContext): Observable<Vehicle> {
    return this.patchVehicle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Vehicle>): Vehicle => r.body)
    );
  }

  /** Path part for operation `getVehicles()` */
  static readonly GetVehiclesPath = '/vehicles';

  /**
   * Get a list of all vehicles.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicles$Response(params: GetVehicles$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseVehicle>> {
    return getVehicles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of all vehicles.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicles(params: GetVehicles$Params, context?: HttpContext): Observable<ResponseVehicle> {
    return this.getVehicles$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseVehicle>): ResponseVehicle => r.body)
    );
  }

  /** Path part for operation `createVehicle()` */
  static readonly CreateVehiclePath = '/vehicles';

  /**
   * creating a new vehicle.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createVehicle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVehicle$Response(params: CreateVehicle$Params, context?: HttpContext): Observable<StrictHttpResponse<Vehicle>> {
    return createVehicle(this.http, this.rootUrl, params, context);
  }

  /**
   * creating a new vehicle.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createVehicle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVehicle(params: CreateVehicle$Params, context?: HttpContext): Observable<Vehicle> {
    return this.createVehicle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Vehicle>): Vehicle => r.body)
    );
  }

  /** Path part for operation `isBoxValid()` */
  static readonly IsBoxValidPath = '/vehicles/validate';

  /**
   * validates the given vehicle for all boxe types or the (optional) given box type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isBoxValid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  isBoxValid$Response(params: IsBoxValid$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isBoxValid(this.http, this.rootUrl, params, context);
  }

  /**
   * validates the given vehicle for all boxe types or the (optional) given box type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isBoxValid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  isBoxValid(params: IsBoxValid$Params, context?: HttpContext): Observable<boolean> {
    return this.isBoxValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getVignetteVehicles()` */
  static readonly GetVignetteVehiclesPath = '/vignetteVehicles';

  /**
   * Get a list vehicles used for vignette booking.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVignetteVehicles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVignetteVehicles$Response(params: GetVignetteVehicles$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseVignetteVehicle>> {
    return getVignetteVehicles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list vehicles used for vignette booking.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVignetteVehicles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVignetteVehicles(params: GetVignetteVehicles$Params, context?: HttpContext): Observable<ResponseVignetteVehicle> {
    return this.getVignetteVehicles$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseVignetteVehicle>): ResponseVignetteVehicle => r.body)
    );
  }

  /** Path part for operation `getVehicleFields()` */
  static readonly GetVehicleFieldsPath = '/vehicles/vehicleFields';

  /**
   * getting all available vehicle fields.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleFields()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleFields$Response(params?: GetVehicleFields$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VehicleField>>> {
    return getVehicleFields(this.http, this.rootUrl, params, context);
  }

  /**
   * getting all available vehicle fields.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicleFields$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleFields(params?: GetVehicleFields$Params, context?: HttpContext): Observable<Array<VehicleField>> {
    return this.getVehicleFields$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VehicleField>>): Array<VehicleField> => r.body)
    );
  }

  /** Path part for operation `getVehicleTypes()` */
  static readonly GetVehicleTypesPath = '/vehicles/types';

  /**
   * get all vehicle types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleTypes$Response(params?: GetVehicleTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getVehicleTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * get all vehicle types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicleTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleTypes(params?: GetVehicleTypes$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getVehicleTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getTotalCount()` */
  static readonly GetTotalCountPath = '/vehicles/totalCount';

  /**
   * get the total count of vehicles for a customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTotalCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTotalCount$Response(params: GetTotalCount$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getTotalCount(this.http, this.rootUrl, params, context);
  }

  /**
   * get the total count of vehicles for a customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTotalCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTotalCount(params: GetTotalCount$Params, context?: HttpContext): Observable<number> {
    return this.getTotalCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getRequiredFields()` */
  static readonly GetRequiredFieldsPath = '/vehicles/requiredFields';

  /**
   * getting the fields required to create or update a vehicle in relation to a list of box types.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequiredFields()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequiredFields$Response(params: GetRequiredFields$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VehicleField>>> {
    return getRequiredFields(this.http, this.rootUrl, params, context);
  }

  /**
   * getting the fields required to create or update a vehicle in relation to a list of box types.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRequiredFields$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequiredFields(params: GetRequiredFields$Params, context?: HttpContext): Observable<Array<VehicleField>> {
    return this.getRequiredFields$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VehicleField>>): Array<VehicleField> => r.body)
    );
  }

  /** Path part for operation `getMinimalVehicles()` */
  static readonly GetMinimalVehiclesPath = '/vehicles/minimal';

  /**
   * Get a list vehicles.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMinimalVehicles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMinimalVehicles$Response(params: GetMinimalVehicles$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseMinimalVehicle>> {
    return getMinimalVehicles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list vehicles.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMinimalVehicles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMinimalVehicles(params: GetMinimalVehicles$Params, context?: HttpContext): Observable<ResponseMinimalVehicle> {
    return this.getMinimalVehicles$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseMinimalVehicle>): ResponseMinimalVehicle => r.body)
    );
  }

  /** Path part for operation `getVehiclesByLicensePlate()` */
  static readonly GetVehiclesByLicensePlatePath = '/vehicles/byLicensePlate/{licensePlate}';

  /**
   * get vehicles by licensplate number and customer id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehiclesByLicensePlate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehiclesByLicensePlate$Response(params: GetVehiclesByLicensePlate$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Vehicle>>> {
    return getVehiclesByLicensePlate(this.http, this.rootUrl, params, context);
  }

  /**
   * get vehicles by licensplate number and customer id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehiclesByLicensePlate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehiclesByLicensePlate(params: GetVehiclesByLicensePlate$Params, context?: HttpContext): Observable<Array<Vehicle>> {
    return this.getVehiclesByLicensePlate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Vehicle>>): Array<Vehicle> => r.body)
    );
  }

  /** Path part for operation `getFilterOptions()` */
  static readonly GetFilterOptionsPath = '/filter/vehicle/{name}';

  /**
   * get filter options for any column a vehicle
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterOptions$Response(params: GetFilterOptions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getFilterOptions(this.http, this.rootUrl, params, context);
  }

  /**
   * get filter options for any column a vehicle
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFilterOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterOptions(params: GetFilterOptions$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getFilterOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
