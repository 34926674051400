/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getDailySales } from '../fn/tc-daily-sales/get-daily-sales';
import { GetDailySales$Params } from '../fn/tc-daily-sales/get-daily-sales';
import { insertDailySale } from '../fn/tc-daily-sales/insert-daily-sale';
import { InsertDailySale$Params } from '../fn/tc-daily-sales/insert-daily-sale';
import { patchDailySalesInvoicing } from '../fn/tc-daily-sales/patch-daily-sales-invoicing';
import { PatchDailySalesInvoicing$Params } from '../fn/tc-daily-sales/patch-daily-sales-invoicing';
import { TcDailySaleDto } from '../models/tc-daily-sale-dto';
import { TcDailySalesPaginatedBody } from '../models/tc-daily-sales-paginated-body';

@Injectable({ providedIn: 'root' })
export class TcDailySalesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDailySales()` */
  static readonly GetDailySalesPath = '/svgs/{svgId}/customers/{customerId}/daily-sales';

  /**
   * Get daily sales (paginated).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDailySales()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDailySales$Response(params: GetDailySales$Params, context?: HttpContext): Observable<StrictHttpResponse<TcDailySalesPaginatedBody>> {
    return getDailySales(this.http, this.rootUrl, params, context);
  }

  /**
   * Get daily sales (paginated).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDailySales$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDailySales(params: GetDailySales$Params, context?: HttpContext): Observable<TcDailySalesPaginatedBody> {
    return this.getDailySales$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcDailySalesPaginatedBody>): TcDailySalesPaginatedBody => r.body)
    );
  }

  /** Path part for operation `insertDailySale()` */
  static readonly InsertDailySalePath = '/svgs/{svgId}/customers/{customerId}/daily-sales';

  /**
   * Create a daily sale.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertDailySale()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insertDailySale$Response(params: InsertDailySale$Params, context?: HttpContext): Observable<StrictHttpResponse<TcDailySaleDto>> {
    return insertDailySale(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a daily sale.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insertDailySale$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insertDailySale(params: InsertDailySale$Params, context?: HttpContext): Observable<TcDailySaleDto> {
    return this.insertDailySale$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcDailySaleDto>): TcDailySaleDto => r.body)
    );
  }

  /** Path part for operation `patchDailySalesInvoicing()` */
  static readonly PatchDailySalesInvoicingPath = '/svgs/{svgId}/customers/{customerId}/daily-sales/{id}';

  /**
   * Assign invoice data to a daily sale.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchDailySalesInvoicing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchDailySalesInvoicing$Response(params: PatchDailySalesInvoicing$Params, context?: HttpContext): Observable<StrictHttpResponse<TcDailySaleDto>> {
    return patchDailySalesInvoicing(this.http, this.rootUrl, params, context);
  }

  /**
   * Assign invoice data to a daily sale.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchDailySalesInvoicing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchDailySalesInvoicing(params: PatchDailySalesInvoicing$Params, context?: HttpContext): Observable<TcDailySaleDto> {
    return this.patchDailySalesInvoicing$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcDailySaleDto>): TcDailySaleDto => r.body)
    );
  }

}
