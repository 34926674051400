import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Logger } from '@nsalaun/ng-logger';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Context } from '../../context/models/context.model';
import { ContextService } from '../../context/services/context.service';

import { DefaultUserTokenClaims } from '../../context/models/user-token-claims.model';

@Injectable({ providedIn: 'root' })
export class IsNotLoggedInGuard<T extends Context, U extends DefaultUserTokenClaims> implements CanActivate {
	constructor(private contextService: ContextService<T, U>, private router: Router, private logger: Logger) {}

	canActivate(): Observable<boolean> {
		return this.contextService.getFirstContext().pipe(
			map((context: T | null) => context.isNotLoggedInState()),
			tap((canActivate: boolean) => {
				if (!canActivate) {
					this.router.navigateByUrl('/').catch((error) => {
						this.logger.error('Navigation failed', error);
					});
				}
			}),
		);
	}
}
