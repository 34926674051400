/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createDebitingDetails } from '../fn/debiting-details-controller/create-debiting-details';
import { CreateDebitingDetails$Params } from '../fn/debiting-details-controller/create-debiting-details';
import { DebitingDetailsDto } from '../models/debiting-details-dto';
import { deleteDebitingDetails } from '../fn/debiting-details-controller/delete-debiting-details';
import { DeleteDebitingDetails$Params } from '../fn/debiting-details-controller/delete-debiting-details';
import { getDebitingDetailsForCustomer } from '../fn/debiting-details-controller/get-debiting-details-for-customer';
import { GetDebitingDetailsForCustomer$Params } from '../fn/debiting-details-controller/get-debiting-details-for-customer';
import { ResponseDebitingDetailsDto } from '../models/response-debiting-details-dto';
import { updateDebitingDetails } from '../fn/debiting-details-controller/update-debiting-details';
import { UpdateDebitingDetails$Params } from '../fn/debiting-details-controller/update-debiting-details';

@Injectable({ providedIn: 'root' })
export class DebitingDetailsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateDebitingDetails()` */
  static readonly UpdateDebitingDetailsPath = '/debitingdetails/{id}';

  /**
   * Update debiting details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDebitingDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDebitingDetails$Response(params: UpdateDebitingDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<DebitingDetailsDto>> {
    return updateDebitingDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Update debiting details
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDebitingDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDebitingDetails(params: UpdateDebitingDetails$Params, context?: HttpContext): Observable<DebitingDetailsDto> {
    return this.updateDebitingDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<DebitingDetailsDto>): DebitingDetailsDto => r.body)
    );
  }

  /** Path part for operation `deleteDebitingDetails()` */
  static readonly DeleteDebitingDetailsPath = '/debitingdetails/{id}';

  /**
   * Delete debiting details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDebitingDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDebitingDetails$Response(params: DeleteDebitingDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<DebitingDetailsDto>> {
    return deleteDebitingDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete debiting details
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDebitingDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDebitingDetails(params: DeleteDebitingDetails$Params, context?: HttpContext): Observable<DebitingDetailsDto> {
    return this.deleteDebitingDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<DebitingDetailsDto>): DebitingDetailsDto => r.body)
    );
  }

  /** Path part for operation `getDebitingDetailsForCustomer()` */
  static readonly GetDebitingDetailsForCustomerPath = '/debitingdetails';

  /**
   * Get a list of debiting details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDebitingDetailsForCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDebitingDetailsForCustomer$Response(params: GetDebitingDetailsForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDebitingDetailsDto>> {
    return getDebitingDetailsForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of debiting details
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDebitingDetailsForCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDebitingDetailsForCustomer(params: GetDebitingDetailsForCustomer$Params, context?: HttpContext): Observable<ResponseDebitingDetailsDto> {
    return this.getDebitingDetailsForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseDebitingDetailsDto>): ResponseDebitingDetailsDto => r.body)
    );
  }

  /** Path part for operation `createDebitingDetails()` */
  static readonly CreateDebitingDetailsPath = '/debitingdetails';

  /**
   * Create debiting details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDebitingDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDebitingDetails$Response(params: CreateDebitingDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<DebitingDetailsDto>> {
    return createDebitingDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Create debiting details
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDebitingDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDebitingDetails(params: CreateDebitingDetails$Params, context?: HttpContext): Observable<DebitingDetailsDto> {
    return this.createDebitingDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<DebitingDetailsDto>): DebitingDetailsDto => r.body)
    );
  }

}
