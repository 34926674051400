import { Injectable } from '@angular/core';
import { SafetyControllerService, SafetyDto } from '@mysvg/api/financial-guarantees';
import { SvgContextService } from '@svg-frontends/session';
import { RepositoryStoreService } from '../repository-store.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ActiveSafetyStoreService extends RepositoryStoreService<SafetyDto[]> {
	constructor(private safetyControllerService: SafetyControllerService, private svgContextService: SvgContextService) {
		super();
	}
	fetchData(): Observable<SafetyDto[]> {
		return this.svgContextService
			.getFirstPortalUid()
			.pipe(mergeMap((portalUid: string) => this.safetyControllerService.getActiveSafetiesForCustomer({ portalUid })));
	}
}
