import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreakpointService } from '../services/breakpoint.service';

@UntilDestroy()
@Directive({ selector: '[svgFrontendsForBreakpoints]' })
export class BreakpointsDirective implements OnInit {
	@Input() svgFrontendsForBreakpoints: string | string[] = [];

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private breakpointService: BreakpointService,
		private changeDetectorRef: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.breakpointService
			.matches(this.svgFrontendsForBreakpoints)
			.pipe(untilDestroyed(this))
			.subscribe((isMatch: boolean) => this.setVisibility(isMatch));
	}

	/**
	 * show if actual breakpoint matches one of the set breakpoints
	 */
	private setVisibility(isMatch: boolean): void {
		if (isMatch) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}

		this.changeDetectorRef.markForCheck();
	}
}
