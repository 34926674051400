import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, of } from 'rxjs';

import { FormGroupComponent } from '@mysvg/utils';

import { DialogLevel } from '../enums/dialog-level.enum';
import { ConfirmDialogData, StandardDialogService } from '../services/standard-dialog.service';

@Injectable({ providedIn: 'root' })
export class CanLeaveFormGuard implements CanDeactivate<FormGroupComponent> {
	constructor(private standardDialogService: StandardDialogService) {}

	canDeactivate(component: FormGroupComponent): Observable<boolean> {
		if (component && component.formGroup && component.formGroup.dirty) {
			const data: ConfirmDialogData = {
				cancelButton: 'Abbrechen',
				submitButton: 'Ok',
				body: 'Möchten Sie die Änderungen wirklich verwerfen?',
				level: DialogLevel.DANGER,
				title: 'Änderungen verwerfen?',
			};

			return this.standardDialogService.showConfirmationDialog(data);
		} else {
			return of(true);
		}
	}
}
