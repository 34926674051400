import { Injectable } from '@angular/core';
import { DocumentTypeDto, PublicControllerService } from '@mysvg/api/pia';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CustomerDocumentTypesStoreService extends RepositoryStoreService<DocumentTypeDto[]> {
	constructor(private publicControllerService: PublicControllerService) {
		super();
	}

	fetchData(): Observable<DocumentTypeDto[]> {
		return this.publicControllerService.getDocumentTypes();
	}

	findDocumentTypeByShortDescription(shortDescription: string): Observable<DocumentTypeDto | null> {
		return this.getFirst().pipe(
			map((customerDocumentTypes: DocumentTypeDto[]) => {
				const match: DocumentTypeDto | undefined = customerDocumentTypes.find(
					(customerDocumentType: DocumentTypeDto) => customerDocumentType.shortDescription === shortDescription,
				);
				return match ? match : null;
			}),
		);
	}
}
