import { BillableServiceDto } from '@myasi/api/billable-service/core';
import { RiskAssessmentDto } from '@myasi/api/customers/riskassessment';

import { isDefined } from './general.utils';

export const compareRiskAssessmentDtoByProcessStatus = (a: RiskAssessmentDto, b: RiskAssessmentDto): number => {
	if (a && b) {
		if (a.riskAssessmentStatus < b.riskAssessmentStatus) {
			return -1;
		} else if (a.riskAssessmentStatus > b.riskAssessmentStatus) {
			return 1;
		} else {
			return 0;
		}
	} else {
		return 0;
	}
};

export const stringToNumberArray = (param: string | null | undefined): number[] =>
	(param || '')
		.split(',')
		.map((num: string) => parseInt(num, 10))
		.filter((num: number) => !isNaN(num));

export const parseFloatFromString = (float: string | any | null | undefined): number => {
	if (isDefined(float)) {
		return parseFloat(float.toString().replace(',', '.'));
	} else {
		return NaN;
	}
};

export const toNumberString = (float: number | null): string | undefined => {
	if (isDefined(float)) {
		return float.toString().replace('.', ',');
	} else {
		return undefined;
	}
};

export const stringifyBillableService = (billableService: BillableServiceDto): string => {
	if (billableService.billableServiceCategory && billableService.billableServiceType) {
		return `${billableService.billableServiceCategory.label}, ${billableService.billableServiceType.label} (${billableService.billableServiceType.serviceTypeNumber})`;
	} else {
		return `${billableService.billableServiceType.label} (${billableService.billableServiceType.serviceTypeNumber})`;
	}
};
