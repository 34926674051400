/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getFilterOptions } from '../fn/toll-service-controller/get-filter-options';
import { GetFilterOptions$Params } from '../fn/toll-service-controller/get-filter-options';
import { getServiceEndpoints } from '../fn/toll-service-controller/get-service-endpoints';
import { GetServiceEndpoints$Params } from '../fn/toll-service-controller/get-service-endpoints';
import { getSettlementDates } from '../fn/toll-service-controller/get-settlement-dates';
import { GetSettlementDates$Params } from '../fn/toll-service-controller/get-settlement-dates';
import { getTransactions } from '../fn/toll-service-controller/get-transactions';
import { GetTransactions$Params } from '../fn/toll-service-controller/get-transactions';
import { getTurnoverFigures } from '../fn/toll-service-controller/get-turnover-figures';
import { GetTurnoverFigures$Params } from '../fn/toll-service-controller/get-turnover-figures';
import { ResponseSettlement } from '../models/response-settlement';
import { ResponseTollTransaction } from '../models/response-toll-transaction';
import { ResponseTurnoverFigures } from '../models/response-turnover-figures';

@Injectable({ providedIn: 'root' })
export class TollServiceControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTransactions()` */
  static readonly GetTransactionsPath = '/transactions';

  /**
   * View a list of transactions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactions$Response(params: GetTransactions$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseTollTransaction>> {
    return getTransactions(this.http, this.rootUrl, params, context);
  }

  /**
   * View a list of transactions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactions(params: GetTransactions$Params, context?: HttpContext): Observable<ResponseTollTransaction> {
    return this.getTransactions$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseTollTransaction>): ResponseTollTransaction => r.body)
    );
  }

  /** Path part for operation `getTurnoverFigures()` */
  static readonly GetTurnoverFiguresPath = '/toll/turnoverFigures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTurnoverFigures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTurnoverFigures$Response(params: GetTurnoverFigures$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseTurnoverFigures>> {
    return getTurnoverFigures(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTurnoverFigures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTurnoverFigures(params: GetTurnoverFigures$Params, context?: HttpContext): Observable<ResponseTurnoverFigures> {
    return this.getTurnoverFigures$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseTurnoverFigures>): ResponseTurnoverFigures => r.body)
    );
  }

  /** Path part for operation `getSettlementDates()` */
  static readonly GetSettlementDatesPath = '/settlementdates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettlementDates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettlementDates$Response(params: GetSettlementDates$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSettlement>> {
    return getSettlementDates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSettlementDates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettlementDates(params: GetSettlementDates$Params, context?: HttpContext): Observable<ResponseSettlement> {
    return this.getSettlementDates$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSettlement>): ResponseSettlement => r.body)
    );
  }

  /** Path part for operation `getFilterOptions()` */
  static readonly GetFilterOptionsPath = '/filter/{model}/{column}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterOptions$Response(params: GetFilterOptions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getFilterOptions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFilterOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterOptions(params: GetFilterOptions$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getFilterOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getServiceEndpoints()` */
  static readonly GetServiceEndpointsPath = '/endpoints';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getServiceEndpoints()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceEndpoints$Response(params?: GetServiceEndpoints$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getServiceEndpoints(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getServiceEndpoints$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceEndpoints(params?: GetServiceEndpoints$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getServiceEndpoints$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
