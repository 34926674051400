import { Directive, Input, OnChanges } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({ selector: '[svgFrontendsDisableControl]' })
export class DisableControlDirective implements OnChanges {
	@Input() svgFrontendsDisableControl: boolean | undefined;

	constructor(private ngControl: NgControl) {}

	/**
	 * [NOTE] svgFrontendsDisableControl condition is not enough to decide whether the control should be disabled or enabled.
	 * 				the third option `do nothing` is needed too.
	 */
	ngOnChanges(): void {
		const abstractControl: AbstractControl = this.ngControl.control;

		if (this.svgFrontendsDisableControl !== undefined) {
			if (this.svgFrontendsDisableControl) {
				abstractControl.disable({ emitEvent: false });
			} else if (!this.svgFrontendsDisableControl) {
				abstractControl.enable({ emitEvent: false });
			}
		}
	}
}
