/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createCustomerMemo } from '../fn/customer-memo-controller/create-customer-memo';
import { CreateCustomerMemo$Params } from '../fn/customer-memo-controller/create-customer-memo';
import { CustomerMemo } from '../models/customer-memo';
import { deleteCustomerMemo } from '../fn/customer-memo-controller/delete-customer-memo';
import { DeleteCustomerMemo$Params } from '../fn/customer-memo-controller/delete-customer-memo';
import { getCustomerMemosFor } from '../fn/customer-memo-controller/get-customer-memos-for';
import { GetCustomerMemosFor$Params } from '../fn/customer-memo-controller/get-customer-memos-for';
import { ResponseCustomerMemo } from '../models/response-customer-memo';
import { updateCustomerMemo } from '../fn/customer-memo-controller/update-customer-memo';
import { UpdateCustomerMemo$Params } from '../fn/customer-memo-controller/update-customer-memo';

@Injectable({ providedIn: 'root' })
export class CustomerMemoControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateCustomerMemo()` */
  static readonly UpdateCustomerMemoPath = '/customermemo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerMemo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerMemo$Response(params: UpdateCustomerMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerMemo>> {
    return updateCustomerMemo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCustomerMemo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerMemo(params: UpdateCustomerMemo$Params, context?: HttpContext): Observable<CustomerMemo> {
    return this.updateCustomerMemo$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerMemo>): CustomerMemo => r.body)
    );
  }

  /** Path part for operation `createCustomerMemo()` */
  static readonly CreateCustomerMemoPath = '/customermemo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomerMemo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomerMemo$Response(params: CreateCustomerMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerMemo>> {
    return createCustomerMemo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCustomerMemo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomerMemo(params: CreateCustomerMemo$Params, context?: HttpContext): Observable<CustomerMemo> {
    return this.createCustomerMemo$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerMemo>): CustomerMemo => r.body)
    );
  }

  /** Path part for operation `getCustomerMemosFor()` */
  static readonly GetCustomerMemosForPath = '/customermemo/{customerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerMemosFor()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerMemosFor$Response(params: GetCustomerMemosFor$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseCustomerMemo>> {
    return getCustomerMemosFor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerMemosFor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerMemosFor(params: GetCustomerMemosFor$Params, context?: HttpContext): Observable<ResponseCustomerMemo> {
    return this.getCustomerMemosFor$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseCustomerMemo>): ResponseCustomerMemo => r.body)
    );
  }

  /** Path part for operation `deleteCustomerMemo()` */
  static readonly DeleteCustomerMemoPath = '/customermemo/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerMemo()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerMemo$Response(params: DeleteCustomerMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCustomerMemo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerMemo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerMemo(params: DeleteCustomerMemo$Params, context?: HttpContext): Observable<void> {
    return this.deleteCustomerMemo$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
