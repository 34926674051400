/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { boxTypeDetails } from '../fn/box-type/box-type-details';
import { BoxTypeDetails$Params } from '../fn/box-type/box-type-details';
import { BoxTypeView } from '../models/box-type-view';
import { getBoxTypes } from '../fn/box-type/get-box-types';
import { GetBoxTypes$Params } from '../fn/box-type/get-box-types';


/**
 * Box type operations
 */
@Injectable({ providedIn: 'root' })
export class BoxTypeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getBoxTypes()` */
  static readonly GetBoxTypesPath = '/box-types';

  /**
   * Returns list with box types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBoxTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoxTypes$Response(params?: GetBoxTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BoxTypeView>>> {
    return getBoxTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns list with box types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBoxTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoxTypes(params?: GetBoxTypes$Params, context?: HttpContext): Observable<Array<BoxTypeView>> {
    return this.getBoxTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BoxTypeView>>): Array<BoxTypeView> => r.body)
    );
  }

  /** Path part for operation `boxTypeDetails()` */
  static readonly BoxTypeDetailsPath = '/box-types/{id}';

  /**
   * Returns box type details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `boxTypeDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  boxTypeDetails$Response(params: BoxTypeDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<BoxTypeView>> {
    return boxTypeDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns box type details
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `boxTypeDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  boxTypeDetails(params: BoxTypeDetails$Params, context?: HttpContext): Observable<BoxTypeView> {
    return this.boxTypeDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<BoxTypeView>): BoxTypeView => r.body)
    );
  }

}
