/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getSvgs } from '../fn/svg-management-controller/get-svgs';
import { GetSvgs$Params } from '../fn/svg-management-controller/get-svgs';
import { ResponseSvgPermission } from '../models/response-svg-permission';

@Injectable({ providedIn: 'root' })
export class SvgManagementControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSvgs()` */
  static readonly GetSvgsPath = '/svgs';

  /**
   * getting all SVGs. Returning all available SVGs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSvgs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgs$Response(params?: GetSvgs$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSvgPermission>> {
    return getSvgs(this.http, this.rootUrl, params, context);
  }

  /**
   * getting all SVGs. Returning all available SVGs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSvgs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgs(params?: GetSvgs$Params, context?: HttpContext): Observable<ResponseSvgPermission> {
    return this.getSvgs$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSvgPermission>): ResponseSvgPermission => r.body)
    );
  }

}
