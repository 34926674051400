/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { MailAddressControllerService } from './services/mail-address-controller.service';
import { ContactPersonManagementControllerService } from './services/contact-person-management-controller.service';
import { AddressManagementControllerService } from './services/address-management-controller.service';
import { CommunicationControllerService } from './services/communication-controller.service';
import { MySvgControllerService } from './services/my-svg-controller.service';
import { RoleControllerService } from './services/role-controller.service';
import { SalutationControllerService } from './services/salutation-controller.service';
import { CountryControllerService } from './services/country-controller.service';
import { MailAddressTypeControllerService } from './services/mail-address-type-controller.service';
import { LanguageControllerService } from './services/language-controller.service';
import { CommunicationTypeControllerService } from './services/communication-type-controller.service';
import { AddressTypeControllerService } from './services/address-type-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    MailAddressControllerService,
    ContactPersonManagementControllerService,
    AddressManagementControllerService,
    CommunicationControllerService,
    MySvgControllerService,
    RoleControllerService,
    SalutationControllerService,
    CountryControllerService,
    MailAddressTypeControllerService,
    LanguageControllerService,
    CommunicationTypeControllerService,
    AddressTypeControllerService,
    ApiConfiguration
  ],
})
export class ContactDataModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ContactDataModule> {
    return {
      ngModule: ContactDataModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ContactDataModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ContactDataModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
