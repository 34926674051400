/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { RegistrationControllerService } from './services/registration-controller.service';
import { PiaControllerService } from './services/pia-controller.service';
import { CustomerMemoControllerService } from './services/customer-memo-controller.service';
import { PublicControllerService } from './services/public-controller.service';
import { ActionsControllerService } from './services/actions-controller.service';
import { CustomersDocumentsControllerService } from './services/customers-documents-controller.service';
import { ResurrectionControllerService } from './services/resurrection-controller.service';
import { MySvgControllerService } from './services/my-svg-controller.service';
import { SvgContactControllerService } from './services/svg-contact-controller.service';
import { CustomersCardsBoxesControllerService } from './services/customers-cards-boxes-controller.service';
import { MinimalCustomerControllerService } from './services/minimal-customer-controller.service';
import { ActionReasonsControllerService } from './services/action-reasons-controller.service';
import { CustomerDocumentDirectoryControllerService } from './services/customer-document-directory-controller.service';
import { AuditControllerService } from './services/audit-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    RegistrationControllerService,
    PiaControllerService,
    CustomerMemoControllerService,
    PublicControllerService,
    ActionsControllerService,
    CustomersDocumentsControllerService,
    ResurrectionControllerService,
    MySvgControllerService,
    SvgContactControllerService,
    CustomersCardsBoxesControllerService,
    MinimalCustomerControllerService,
    ActionReasonsControllerService,
    CustomerDocumentDirectoryControllerService,
    AuditControllerService,
    ApiConfiguration
  ],
})
export class PiaModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<PiaModule> {
    return {
      ngModule: PiaModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: PiaModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('PiaModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
