import { Injectable } from '@angular/core';
import { ProcessControllerService, ProcessTypeDto } from '@mysvg/api/bpm';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProcessTypesStoreService extends RepositoryStoreService<ProcessTypeDto[]> {
	constructor(private processControllerService: ProcessControllerService) {
		super();
	}

	fetchData(): Observable<ProcessTypeDto[]> {
		return this.processControllerService.getProcessTypes();
	}
}
