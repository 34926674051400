/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteValidation } from '../fn/dhl-validations-controller/delete-validation';
import { DeleteValidation$Params } from '../fn/dhl-validations-controller/delete-validation';
import { DhlValidationDto } from '../models/dhl-validation-dto';
import { getValidation } from '../fn/dhl-validations-controller/get-validation';
import { GetValidation$Params } from '../fn/dhl-validations-controller/get-validation';
import { upsertValidation } from '../fn/dhl-validations-controller/upsert-validation';
import { UpsertValidation$Params } from '../fn/dhl-validations-controller/upsert-validation';

@Injectable({ providedIn: 'root' })
export class DhlValidationsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `upsertValidation()` */
  static readonly UpsertValidationPath = '/dhlvalidations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertValidation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upsertValidation$Response(params: UpsertValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<DhlValidationDto>> {
    return upsertValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertValidation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upsertValidation(params: UpsertValidation$Params, context?: HttpContext): Observable<DhlValidationDto> {
    return this.upsertValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<DhlValidationDto>): DhlValidationDto => r.body)
    );
  }

  /** Path part for operation `getValidation()` */
  static readonly GetValidationPath = '/dhlvalidations/{addressId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getValidation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValidation$Response(params: GetValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<DhlValidationDto>> {
    return getValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getValidation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValidation(params: GetValidation$Params, context?: HttpContext): Observable<DhlValidationDto> {
    return this.getValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<DhlValidationDto>): DhlValidationDto => r.body)
    );
  }

  /** Path part for operation `deleteValidation()` */
  static readonly DeleteValidationPath = '/dhlvalidations/{validationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteValidation()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteValidation$Response(params: DeleteValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteValidation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteValidation(params: DeleteValidation$Params, context?: HttpContext): Observable<void> {
    return this.deleteValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
