/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CrefoOutstandingOrderDto } from '../models/crefo-outstanding-order-dto';
import { getOutStandingOrders } from '../fn/crefo-order-controller/get-out-standing-orders';
import { GetOutStandingOrders$Params } from '../fn/crefo-order-controller/get-out-standing-orders';
import { sendCommercialOrder } from '../fn/crefo-order-controller/send-commercial-order';
import { SendCommercialOrder$Params } from '../fn/crefo-order-controller/send-commercial-order';
import { sendCommercialOrderWithoutCrefoNumber } from '../fn/crefo-order-controller/send-commercial-order-without-crefo-number';
import { SendCommercialOrderWithoutCrefoNumber$Params } from '../fn/crefo-order-controller/send-commercial-order-without-crefo-number';
import { sendCommercialPrivateOrder } from '../fn/crefo-order-controller/send-commercial-private-order';
import { SendCommercialPrivateOrder$Params } from '../fn/crefo-order-controller/send-commercial-private-order';
import { sendCommercialPrivateOrderWithoutCrefoNumber } from '../fn/crefo-order-controller/send-commercial-private-order-without-crefo-number';
import { SendCommercialPrivateOrderWithoutCrefoNumber$Params } from '../fn/crefo-order-controller/send-commercial-private-order-without-crefo-number';

@Injectable({ providedIn: 'root' })
export class CrefoOrderControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendCommercialOrder()` */
  static readonly SendCommercialOrderPath = '/crefo/order/commercial';

  /**
   * sends commercial order and returns order reference
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCommercialOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendCommercialOrder$Response(params: SendCommercialOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return sendCommercialOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * sends commercial order and returns order reference
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendCommercialOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendCommercialOrder(params: SendCommercialOrder$Params, context?: HttpContext): Observable<number> {
    return this.sendCommercialOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `sendCommercialOrderWithoutCrefoNumber()` */
  static readonly SendCommercialOrderWithoutCrefoNumberPath = '/crefo/order/commercial/without-crefo';

  /**
   * sends commercial order without crefo number and returns order reference
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCommercialOrderWithoutCrefoNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendCommercialOrderWithoutCrefoNumber$Response(params: SendCommercialOrderWithoutCrefoNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return sendCommercialOrderWithoutCrefoNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * sends commercial order without crefo number and returns order reference
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendCommercialOrderWithoutCrefoNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendCommercialOrderWithoutCrefoNumber(params: SendCommercialOrderWithoutCrefoNumber$Params, context?: HttpContext): Observable<number> {
    return this.sendCommercialOrderWithoutCrefoNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `sendCommercialPrivateOrder()` */
  static readonly SendCommercialPrivateOrderPath = '/crefo/order/commercial/private';

  /**
   * sends commercial order for private person and returns order reference
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCommercialPrivateOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendCommercialPrivateOrder$Response(params: SendCommercialPrivateOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return sendCommercialPrivateOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * sends commercial order for private person and returns order reference
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendCommercialPrivateOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendCommercialPrivateOrder(params: SendCommercialPrivateOrder$Params, context?: HttpContext): Observable<number> {
    return this.sendCommercialPrivateOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `sendCommercialPrivateOrderWithoutCrefoNumber()` */
  static readonly SendCommercialPrivateOrderWithoutCrefoNumberPath = '/crefo/order/commercial/private/without-crefo';

  /**
   * sends commercial order without crefo number for private person and returns order reference
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCommercialPrivateOrderWithoutCrefoNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendCommercialPrivateOrderWithoutCrefoNumber$Response(params: SendCommercialPrivateOrderWithoutCrefoNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return sendCommercialPrivateOrderWithoutCrefoNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * sends commercial order without crefo number for private person and returns order reference
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendCommercialPrivateOrderWithoutCrefoNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendCommercialPrivateOrderWithoutCrefoNumber(params: SendCommercialPrivateOrderWithoutCrefoNumber$Params, context?: HttpContext): Observable<number> {
    return this.sendCommercialPrivateOrderWithoutCrefoNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getOutStandingOrders()` */
  static readonly GetOutStandingOrdersPath = '/crefo/order/outstanding';

  /**
   * get outstanding orders
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOutStandingOrders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOutStandingOrders$Response(params: GetOutStandingOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CrefoOutstandingOrderDto>>> {
    return getOutStandingOrders(this.http, this.rootUrl, params, context);
  }

  /**
   * get outstanding orders
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOutStandingOrders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOutStandingOrders(params: GetOutStandingOrders$Params, context?: HttpContext): Observable<Array<CrefoOutstandingOrderDto>> {
    return this.getOutStandingOrders$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CrefoOutstandingOrderDto>>): Array<CrefoOutstandingOrderDto> => r.body)
    );
  }

}
