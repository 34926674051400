import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { isDefined } from '../../utils/general.utils';

@Injectable({ providedIn: 'root' })
export class NumberFormatService {
	constructor(@Inject(LOCALE_ID) private localeId: string) {}

	toLocaleString(value: number, decimalPlaces = 2): string {
		if (typeof value === 'string') {
			return value as string;
		}

		if (value === null || value === undefined || typeof value !== 'number') {
			return null;
		}

		const checkedValue = typeof value === 'number' ? value : 0;
		const checkedDecimalPlaces = isDefined(decimalPlaces) ? Math.max(0, decimalPlaces) : 2;

		switch (this.localeId) {
			case 'de':
				return checkedValue.toLocaleString('de-DE', {
					useGrouping: false,
					minimumFractionDigits: checkedDecimalPlaces,
					maximumFractionDigits: checkedDecimalPlaces,
				});
			case 'en':
				return checkedValue.toLocaleString('en-US', {
					useGrouping: false,
					minimumFractionDigits: checkedDecimalPlaces,
					maximumFractionDigits: checkedDecimalPlaces,
				});
			default:
				throw new Error('Specified locale is not supported.');
		}
	}

	fromLocaleString(value: string): number {
		if (typeof value === 'number') {
			return value as number;
		}

		if (value === null || value === undefined || typeof value !== 'string') {
			return null;
		}

		switch (this.localeId) {
			case 'de':
				return parseFloat(value.replace(',', '.'));
			case 'en':
				return parseFloat(value);
			default:
				throw new Error('Specified locale is not supported.');
		}
	}
}
