import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DialogSize } from '../../enums/dialog-size.enum';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-dialog-container',
	styleUrls: ['./dialog-container.component.scss'],
	templateUrl: './dialog-container.component.html',
})
export class DialogContainerComponent {
	DIALOG_SIZE = DialogSize;

	@Input() closable = true;
	@Input() size: DialogSize = DialogSize.MEDIUM;
	@Input() title: string;

	@Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
}
