import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo } from 'rxjs/operators';

import { UtilsService } from '@mysvg/utils';

import { SvgErrorMessageFactoryService } from '@svg-frontends/error';

@Injectable({ providedIn: 'root' })
export class FormErrorService {
	constructor(private errorMessageFactoryService: SvgErrorMessageFactoryService) {}

	getCurrentFieldFormError(formGroup: FormGroup, enableReset = false): Observable<string | null> {
		let observables: Observable<string>[] = [];

		for (const key of Object.keys(formGroup.controls)) {
			const control: AbstractControl | null = formGroup.get(key);

			if (control !== null) {
				const observable: Observable<string> = control.statusChanges.pipe(
					filter(() => control.invalid && control.touched),
					map(() => this.checkControl(control)),
					filter((error: string | undefined) => error !== undefined),
				);
				observables = observables.concat(observable);
			}
		}

		if (enableReset) {
			const resetErrors: Observable<string> = this.createErrorResetObservable(formGroup);
			observables = observables.concat(resetErrors);
		}

		return merge<string, string>(...observables).pipe(distinctUntilChanged());
	}

	getFormErrors(formGroup: FormGroup): Observable<string[]> {
		return merge(
			this.getChildControls(formGroup).map((abstractControl: AbstractControl) =>
				merge(abstractControl.statusChanges, abstractControl.valueChanges),
			),
		).pipe(map(() => this.getAllFormErrors(formGroup)));
	}

	getChildControls(abstractControl: AbstractControl): AbstractControl[] {
		if (abstractControl instanceof FormGroup) {
			const formGroup: FormGroup = abstractControl as FormGroup;
			return UtilsService.flattenArray(
				Object.values(formGroup.controls).map((control: AbstractControl) => this.getChildControls(control)),
			).concat(abstractControl);
		} else if (abstractControl instanceof FormArray) {
			const formArray: FormArray = abstractControl as FormArray;
			return UtilsService.flattenArray(formArray.controls.map((control: AbstractControl) => this.getChildControls(control))).concat(
				abstractControl,
			);
		} else {
			return [abstractControl];
		}
	}

	getAllFormErrors(formGroup: FormGroup): string[] {
		return this.getChildControls(formGroup)
			.filter((abstractControl: AbstractControl) => abstractControl !== null)
			.map((abstractControl: AbstractControl) => this.checkControl(abstractControl))
			.filter((error: string | undefined) => error);
	}

	private checkControl(abstractControl: AbstractControl): string | undefined {
		const firstInvalidControl: AbstractControl | undefined = this.getChildControls(abstractControl).find(
			(control: AbstractControl) => control.invalid,
		);

		return firstInvalidControl !== undefined ? this.createFormError(firstInvalidControl) : undefined;
	}

	private createFormError(control: AbstractControl): string {
		const errors: string[] = Object.keys(control.errors);
		const firstError: string = errors && errors.length > 0 ? errors[0] : 'default';
		const message: string = this.errorMessageFactoryService.getMessageForKey(firstError);

		return message;
	}

	private createErrorResetObservable(formGroup: FormGroup): Observable<string> {
		return formGroup.statusChanges.pipe(
			filter(() => !!formGroup.valid),
			mapTo(null),
		);
	}
}
