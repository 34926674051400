/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { archiveCustomerLogos } from '../fn/customer-logo-controller/archive-customer-logos';
import { ArchiveCustomerLogos$Params } from '../fn/customer-logo-controller/archive-customer-logos';
import { DocumentMetadataDto } from '../models/document-metadata-dto';
import { getCustomerLogo } from '../fn/customer-logo-controller/get-customer-logo';
import { GetCustomerLogo$Params } from '../fn/customer-logo-controller/get-customer-logo';
import { setCustomerLogo } from '../fn/customer-logo-controller/set-customer-logo';
import { SetCustomerLogo$Params } from '../fn/customer-logo-controller/set-customer-logo';

@Injectable({ providedIn: 'root' })
export class CustomerLogoControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCustomerLogo()` */
  static readonly GetCustomerLogoPath = '/documents/customer-logo/{customerId}';

  /**
   * Get customer logo.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerLogo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLogo$Response(params: GetCustomerLogo$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getCustomerLogo(this.http, this.rootUrl, params, context);
  }

  /**
   * Get customer logo.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerLogo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLogo(params: GetCustomerLogo$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getCustomerLogo$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `setCustomerLogo()` */
  static readonly SetCustomerLogoPath = '/documents/customer-logo/{customerId}';

  /**
   * Upload and set the customer logo. Returns the document metadata of the resulting document entry.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCustomerLogo()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  setCustomerLogo$Response(params: SetCustomerLogo$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentMetadataDto>> {
    return setCustomerLogo(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload and set the customer logo. Returns the document metadata of the resulting document entry.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setCustomerLogo$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  setCustomerLogo(params: SetCustomerLogo$Params, context?: HttpContext): Observable<DocumentMetadataDto> {
    return this.setCustomerLogo$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentMetadataDto>): DocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `archiveCustomerLogos()` */
  static readonly ArchiveCustomerLogosPath = '/documents/customer-logo/{customerId}';

  /**
   * Archive all customer logos.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `archiveCustomerLogos()` instead.
   *
   * This method doesn't expect any request body.
   */
  archiveCustomerLogos$Response(params: ArchiveCustomerLogos$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return archiveCustomerLogos(this.http, this.rootUrl, params, context);
  }

  /**
   * Archive all customer logos.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `archiveCustomerLogos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  archiveCustomerLogos(params: ArchiveCustomerLogos$Params, context?: HttpContext): Observable<void> {
    return this.archiveCustomerLogos$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
