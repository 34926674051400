import { Pipe, PipeTransform } from '@angular/core';

import { DocumentService } from '../services/document.service';

@Pipe({ name: 'discountProgramDownloadLinkFromHashPipe' })
export class DiscountProgramDownloadLinkFromHashPipe implements PipeTransform {
	constructor(private documentService: DocumentService) {}

	transform(downloadHash: string): string {
		return this.documentService.getDiscountProgramDocumentUrlViaDocumentHash(downloadHash);
	}
}
