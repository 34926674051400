/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteRegistration } from '../fn/registration-controller/delete-registration';
import { DeleteRegistration$Params } from '../fn/registration-controller/delete-registration';
import { getRegistrations } from '../fn/registration-controller/get-registrations';
import { GetRegistrations$Params } from '../fn/registration-controller/get-registrations';
import { insertRegistration } from '../fn/registration-controller/insert-registration';
import { InsertRegistration$Params } from '../fn/registration-controller/insert-registration';
import { Registration } from '../models/registration';
import { ResponseRegistration } from '../models/response-registration';
import { updateRegistration } from '../fn/registration-controller/update-registration';
import { UpdateRegistration$Params } from '../fn/registration-controller/update-registration';

@Injectable({ providedIn: 'root' })
export class RegistrationControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateRegistration()` */
  static readonly UpdateRegistrationPath = '/registrations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRegistration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRegistration$Response(params: UpdateRegistration$Params, context?: HttpContext): Observable<StrictHttpResponse<Registration>> {
    return updateRegistration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRegistration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRegistration(params: UpdateRegistration$Params, context?: HttpContext): Observable<Registration> {
    return this.updateRegistration$Response(params, context).pipe(
      map((r: StrictHttpResponse<Registration>): Registration => r.body)
    );
  }

  /** Path part for operation `deleteRegistration()` */
  static readonly DeleteRegistrationPath = '/registrations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRegistration()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRegistration$Response(params: DeleteRegistration$Params, context?: HttpContext): Observable<StrictHttpResponse<Registration>> {
    return deleteRegistration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRegistration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRegistration(params: DeleteRegistration$Params, context?: HttpContext): Observable<Registration> {
    return this.deleteRegistration$Response(params, context).pipe(
      map((r: StrictHttpResponse<Registration>): Registration => r.body)
    );
  }

  /** Path part for operation `getRegistrations()` */
  static readonly GetRegistrationsPath = '/registrations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegistrations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegistrations$Response(params?: GetRegistrations$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseRegistration>> {
    return getRegistrations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRegistrations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegistrations(params?: GetRegistrations$Params, context?: HttpContext): Observable<ResponseRegistration> {
    return this.getRegistrations$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseRegistration>): ResponseRegistration => r.body)
    );
  }

  /** Path part for operation `insertRegistration()` */
  static readonly InsertRegistrationPath = '/registrations';

  /**
   * register an existing customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertRegistration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insertRegistration$Response(params: InsertRegistration$Params, context?: HttpContext): Observable<StrictHttpResponse<Registration>> {
    return insertRegistration(this.http, this.rootUrl, params, context);
  }

  /**
   * register an existing customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insertRegistration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insertRegistration(params: InsertRegistration$Params, context?: HttpContext): Observable<Registration> {
    return this.insertRegistration$Response(params, context).pipe(
      map((r: StrictHttpResponse<Registration>): Registration => r.body)
    );
  }

}
