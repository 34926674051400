import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from '@nsalaun/ng-logger';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

// keep order (no alphabetical order)
const XS_MAX_LIMIT = 599;
const XL_MIN_LIMIT = 1920;
const S_MIN_LIMIT = XS_MAX_LIMIT + 1;
const S_MAX_LIMIT = 959;
const M_MIN_LIMIT = S_MAX_LIMIT + 1;
const M_MAX_LIMIT = 1279;
const L_MIN_LIMIT = M_MAX_LIMIT + 1;
const L_MAX_LIMIT = XL_MIN_LIMIT - 1;

export const BREAKPOINTS = {
	L: '(min-width: ' + L_MIN_LIMIT + 'px) and (max-width: ' + L_MAX_LIMIT + 'px)',
	M: '(min-width: ' + M_MIN_LIMIT + 'px) and (max-width: ' + M_MAX_LIMIT + 'px)',
	S: '(min-width: ' + S_MIN_LIMIT + 'px) and (max-width: ' + S_MAX_LIMIT + 'px)',
	XL: '(min-width: ' + XL_MIN_LIMIT + 'px)',
	XS: '(max-width: ' + XS_MAX_LIMIT + 'px)',
};

@Injectable({ providedIn: 'root' })
export class BreakpointService {
	constructor(private breakpointObserver: BreakpointObserver, private router: Router, private logger: Logger) {}

	/**
	 * will emit event on change of all queries / breakpoints
	 */
	matches(breakpoints: string | string[]): Observable<boolean> {
		return this.breakpointObserver.observe(breakpoints).pipe(
			map((breakpointState: BreakpointState) => breakpointState.matches),
			distinctUntilChanged(),
		);
	}

	/**
	 * routes to `routeTo` on specified breakpoints
	 * [NOTE]	`routeFrom` is optional
	 * 				`routeFrom` not set: routes no matter what current url is,
	 * 				`routeFrom` set: routes only if current url matches routeFrom
	 */
	routeOnBreakpointChange(breakpoints: string | string[], routeTo: string, routeFrom?: string): Observable<boolean> {
		return this.matches(breakpoints).pipe(tap((matches: boolean) => this.routeOnMatchingBreakpoint(matches, routeTo, routeFrom)));
	}

	private routeOnMatchingBreakpoint(matches: boolean, routeTo: string, routeFrom?: string): void {
		const matchesRouting = routeFrom ? this.router.url === routeFrom : true;

		if (matches && matchesRouting) {
			this.router.navigateByUrl(routeTo).catch((error) => {
				this.logger.error('Navigation failed', error);
			});
		}
	}

	/**
	 * Returns whether the client is a mobile-type client with reduced screen real estate
	 */
	isMobileDevice() {
		return this.matches([BREAKPOINTS.XS, BREAKPOINTS.S]);
	}
}
