/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseTollTransaction } from '../../models/response-toll-transaction';

export interface GetGroupedTransactions$Params {

/**
 * the customers portal uids
 */
  customers: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
  id?: string;
  provider?: string;
  licensePlate?: string;
  country?: string;
  servicePointName?: string;
  servicePointMake?: string;
  mileage?: string;
  serviceCardNumber?: string;
  transactionDateTime?: string;
  settlementDate?: string;
  material?: string;
  totalNetAmount?: string;
  totalGrossAmount?: string;
  vatRate?: string;
  invoiceNumber?: string;
  vatAmount?: string;
}

export function getGroupedTransactions(http: HttpClient, rootUrl: string, params: GetGroupedTransactions$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseTollTransaction>> {
  const rb = new RequestBuilder(rootUrl, getGroupedTransactions.PATH, 'get');
  if (params) {
    rb.query('customers', params.customers, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
    rb.query('id', params.id, {});
    rb.query('provider', params.provider, {});
    rb.query('licensePlate', params.licensePlate, {});
    rb.query('country', params.country, {});
    rb.query('servicePointName', params.servicePointName, {});
    rb.query('servicePointMake', params.servicePointMake, {});
    rb.query('mileage', params.mileage, {});
    rb.query('serviceCardNumber', params.serviceCardNumber, {});
    rb.query('transactionDateTime', params.transactionDateTime, {});
    rb.query('settlementDate', params.settlementDate, {});
    rb.query('material', params.material, {});
    rb.query('totalNetAmount', params.totalNetAmount, {});
    rb.query('totalGrossAmount', params.totalGrossAmount, {});
    rb.query('vatRate', params.vatRate, {});
    rb.query('invoiceNumber', params.invoiceNumber, {});
    rb.query('vatAmount', params.vatAmount, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseTollTransaction>;
    })
  );
}

getGroupedTransactions.PATH = '/transactions/grouped';
