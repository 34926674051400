import { Injectable } from '@angular/core';
import { IncidentControllerService, IncidentMetaDto } from '@mysvg/api/financial-guarantees';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IncidentMetaCountsStoreService extends RepositoryStoreService<IncidentMetaDto> {
	constructor(private incidentControllerService: IncidentControllerService) {
		super();
	}

	fetchData(): Observable<IncidentMetaDto> {
		return this.incidentControllerService.getIncidentMeta();
	}
}
