import { Pipe, PipeTransform } from '@angular/core';

const states = {
	active: { label: 'Aktiv', conditions: [1, '1', true] },
	inactive: { label: 'Inaktiv', conditions: [0, '0', false] },
};

@Pipe({ name: 'state' })
export class StatePipe implements PipeTransform {
	transform(value: any): any {
		if (this.isActive(value)) {
			return states.active.label;
		} else if (this.isInActive(value)) {
			return states.inactive.label;
		} else {
			return value;
		}
	}

	isActive(value: any): boolean {
		return states.active.conditions.some((cond) => cond === value);
	}

	isInActive(value: any): boolean {
		return states.inactive.conditions.some((cond) => cond === value);
	}
}
