import { cloneDeep } from 'lodash';

import { CustomerInfo } from '@mysvg/api/auth';
import { TaskDto } from '@mysvg/api/bpm';
import { BusinessSector, Customer, CustomerAlias, PiaCustomerFieldsProjection } from '@mysvg/api/pia';

import { Vignette } from '@mysvg/api/vignette';
import { AliasKey } from '../../enums/alias-key.enum';
import { TaskSortOrderEnum } from '../../enums/processes/task-sort-order.enum';
import { FirmDataComponentModel } from '../../models/firm-data-component.model';
import { TaskSortConfigModel } from '../../models/processes/task-sort-config.model';

const DATE_REGEX = /([0-9]{4}-[0-9]{2}-[0-9]{2})/;

export class UtilsService {
	/**
	 *
	 * FORMER UTILS
	 *
	 */

	static flattenArray = (arr: any[]): any[] =>
		arr.reduce((flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? UtilsService.flattenArray(toFlatten) : toFlatten), []);

	/**
	 * find 'SVG_KUNDENSTAMM' alias in customer
	 * technical customer id in 'Maut' database
	 * via alias system a customer can hold more ids than portal id
	 */
	static getAliasFrom = (customer: CustomerInfo): number | null => {
		const alias: string[] | null = customer.aliases[AliasKey.SVG_KUNDENSTAMM] || null;
		return alias ? parseInt(alias[0], 10) : null;
	};

	static isUnsetOrEmpty = (array: any[]): boolean => !array || (!!array && array.length === 0);

	/**
	 * Returns an array of numbers from min to max: [min, min+1, min+2, ..., max-2, max-1, max]
	 */
	static createRangeArray = (min = 0, max = 100): number[] => Array.from(Array(max + 1).keys()).filter((num: number) => num >= min);

	/**
	 * compares two objects, by given properties
	 * default order: DESC (DESC: B - A / ASC: A - B)
	 * for stable sorting / comparing, use at least one property, which cant be equals in both objects, like 'id'
	 */
	static simpleCompare = (obj1: any, obj2: any, properties: string[] = ['id']): number => {
		for (const prop of properties) {
			const result = +obj2[prop] - +obj1[prop];
			if (result !== 0 && !isNaN(result)) {
				return result;
			}
		}
		return 0;
	};

	static compare = (obj1: TaskDto, obj2: TaskDto, sortingConfigs: TaskSortConfigModel[]): number => {
		for (const sortingConfig of sortingConfigs) {
			const result: number = UtilsService.getComparisonIndicator(obj1, obj2, sortingConfig);
			if (result !== 0 && !isNaN(result)) {
				return result;
			}
		}
		return 0;
	};

	static computeNewPrio = (prio: number, summand: number, maximumLimit: number = 3, minimumLimit: number = 0): number => {
		let newPriority: number = prio + summand; // add summand
		newPriority = newPriority > maximumLimit ? maximumLimit : newPriority; // check if over limit
		newPriority = newPriority < minimumLimit ? minimumLimit : newPriority; // check if under limit
		return newPriority;
	};

	static convertExtendedCustomer = (customer: Customer): FirmDataComponentModel => ({
		...customer,
		// non optional fields must be set explicitly
		companyName: customer.companyName,
		// converted fields
		city: customer.address.city,
		companyRegisterId: customer.commercialRegisterNumber,
		email: customer.defaultMail,
		street: customer.address.street,
		zip: customer.address.zip,
	});

	static convertPiaCustomer = (customer: PiaCustomerFieldsProjection): FirmDataComponentModel => ({
		city: customer.TOWN1,
		companyName: customer.COMPNAME,
		companyRegisterId: customer.REGISTRATIONNUMBER,
		email: customer.MAILFIELDSTR1,
		street: customer.STREET1,
		vatId: customer.TurnoverTaxId,
		zip: customer.ZIP1,
	});

	/**
	 * [1] no providers set, then get all available providers of customers business sectors for given category
	 * [1.1] ignored sectors like asi, which do not have providers
	 * [1.2] filter all sectors of given category (like fuelcard, which would result in all fuelcard sectors of customer like ESSO, DKV etc.)
	 * [2] return providers without editing / hard coded providers (e.g. via tabs selected)
	 */
	static createProviderParameter = (businessSectors: BusinessSector[], providers: string, category: string = null): string => {
		if (!providers && !!businessSectors) {
			return businessSectors
				.filter((sector: BusinessSector) => !!sector.provider && !!sector.sectorType)
				.filter((sector: BusinessSector) => !category || sector.sectorType.toLocaleLowerCase() === category.toLocaleLowerCase())
				.map((sector: BusinessSector) => sector.provider)
				.join(',');
		} else {
			return providers;
		}
	};

	static getFirstOf = (array: any[]): any | null => (!!array && array.length > 0 ? array[0] : null);

	/**
	 * returns a number representation of a date, to be compared by sorting function
	 * date.parse does not throw exception, but value is NaN.
	 */
	static toComparableDate = (value: string): number => {
		const timestamp: number = Date.parse(value);
		return !isNaN(timestamp) ? timestamp : 0;
	};

	static getString = (val: string): string => (val ? val : '');

	/**
	 *
	 * WIZARD UTILS
	 *
	 */

	static getKundenstammId(customer: Customer): number {
		const kdstamm: CustomerAlias = customer.aliases.find((alias: CustomerAlias) => alias.intendedPurpose === 'SVG_KUNDENSTAMM');

		if (kdstamm) {
			return parseInt(kdstamm.alias, 10);
		} else {
			return 0;
		}
	}

	static clone = <T>(object: T): T => cloneDeep(object);

	/**
	 * [NOTE] creates a vignette string, to be used for loggin (like google analytics)
	 * [TODO] can be refactored or removed after improving models (refactoring EVignette)
	 */
	static stringifyVignette(vignette: Vignette): string {
		let vignetteString = vignette.vignettenumber ? `'${vignette.vignettenumber}' => ` : '';
		vignetteString += vignette.bookingError ? `'${vignette.bookingError}' => ` : '';
		vignetteString += vignette.licensePlateNumber ? ` '${vignette.licensePlateNumber}'` : '';
		vignetteString += vignette.validFrom || vignette.validTo ? ` is valid from '${vignette.validFrom}' to '${vignette.validTo}'` : '';
		vignetteString += vignette.axleClassId || vignette.emissionClassId ? ` ('${vignette.axleClassId}' '${vignette.emissionClassId}')` : '';
		vignetteString += vignette.bookingRequestUuid || vignette.price ? ` '${vignette.bookingRequestUuid}', '${vignette.price}' €` : '';
		vignetteString += vignette.resubmission ? `, is resubmission` : '';
		return vignetteString;
	}

	/**
	 * calculate the difference between two objects properties, using TaskSortConfigModel
	 * used by UtilsService.compare function
	 */
	private static getComparisonIndicator = (obj1: any, obj2: any, sortingConfig: TaskSortConfigModel): number => {
		const property: string = sortingConfig.property;
		const obj1Prop: number = sortingConfig.toComparable ? sortingConfig.toComparable(obj1[property]) : +obj1[property];
		const obj2Prop: number = sortingConfig.toComparable ? sortingConfig.toComparable(obj2[property]) : +obj2[property];
		return sortingConfig.order === TaskSortOrderEnum.ASC ? obj1Prop - obj2Prop : obj2Prop - obj1Prop;
	};

	public static is3dData = (data: any[]): boolean => {
		if (data.length > 0) {
			const firstEntry = data[0];
			return Object.keys(firstEntry).some((key) => firstEntry[key] instanceof Array);
		}

		return false;
	};

	public static isDate = (key: string): boolean => DATE_REGEX.test(key);

	/**
	 *
	 * Generic utils for e.g. storage.service
	 *
	 */

	public static stringify(value: any): string {
		return value && typeof value === 'object' ? JSON.stringify(value) : value;
	}

	public static encode(param: string): string {
		param = btoa(param);
		return param;
	}

	public static decode(param: string): string {
		param = atob(param);
		return param;
	}
}
