/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BoxDiscountView } from '../../models/box-discount-view';

export interface PostDiscountsForBox$Params {

/**
 * Box id
 */
  id: number;

/**
 * Country id
 */
  countryId: number;
      body: Array<BoxDiscountView>
}

export function postDiscountsForBox(http: HttpClient, rootUrl: string, params: PostDiscountsForBox$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BoxDiscountView>>> {
  const rb = new RequestBuilder(rootUrl, postDiscountsForBox.PATH, 'post');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('countryId', params.countryId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<BoxDiscountView>>;
    })
  );
}

postDiscountsForBox.PATH = '/boxes/{id}/discounts';
