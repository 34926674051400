import { Pipe, PipeTransform } from '@angular/core';

import { DocumentService } from '../services/document.service';

@Pipe({ name: 'privateEntityDownloadLinkFromHash' })
export class PrivateEntityDownloadLinkFromHashPipe implements PipeTransform {
	constructor(private documentService: DocumentService) {}

	transform(downloadHash: string, entityType: 'vehicles' | 'customers' | 'svgs', id: string): string {
		return this.documentService.getPrivateEntityDocumentUrlFromHash(entityType, id, downloadHash);
	}
}
