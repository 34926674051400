/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BlockConfigView } from '../models/block-config-view';
import { BoxBoDocument } from '../models/box-bo-document';
import { BoxBoView } from '../models/box-bo-view';
import { BoxStatusActionDto } from '../models/box-status-action-dto';
import { BoxVehicleView } from '../models/box-vehicle-view';
import { getConnectableVehicles } from '../fn/box/get-connectable-vehicles';
import { GetConnectableVehicles$Params } from '../fn/box/get-connectable-vehicles';
import { getConnectedBoxesOfVehicle } from '../fn/box/get-connected-boxes-of-vehicle';
import { GetConnectedBoxesOfVehicle$Params } from '../fn/box/get-connected-boxes-of-vehicle';
import { getFaqDocumentForTollVehicleTypes } from '../fn/box/get-faq-document-for-toll-vehicle-types';
import { GetFaqDocumentForTollVehicleTypes$Params } from '../fn/box/get-faq-document-for-toll-vehicle-types';
import { getLockConfig } from '../fn/box/get-lock-config';
import { GetLockConfig$Params } from '../fn/box/get-lock-config';
import { getOne } from '../fn/box/get-one';
import { GetOne$Params } from '../fn/box/get-one';
import { getPossibleBoxStatusActions } from '../fn/box/get-possible-box-status-actions';
import { GetPossibleBoxStatusActions$Params } from '../fn/box/get-possible-box-status-actions';
import { getReturnAdressForBoxType } from '../fn/box/get-return-adress-for-box-type';
import { GetReturnAdressForBoxType$Params } from '../fn/box/get-return-adress-for-box-type';
import { ReturnAddressView } from '../models/return-address-view';
import { updateBox } from '../fn/box/update-box';
import { UpdateBox$Params } from '../fn/box/update-box';
import { updateBoxCountries } from '../fn/box/update-box-countries';
import { UpdateBoxCountries$Params } from '../fn/box/update-box-countries';
import { updateBoxVehicleConnections } from '../fn/box/update-box-vehicle-connections';
import { UpdateBoxVehicleConnections$Params } from '../fn/box/update-box-vehicle-connections';


/**
 * Boxes operations
 */
@Injectable({ providedIn: 'root' })
export class BoxService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOne()` */
  static readonly GetOnePath = '/boxes/{id}';

  /**
   * Returns data for one box
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOne$Response(params: GetOne$Params, context?: HttpContext): Observable<StrictHttpResponse<BoxBoView>> {
    return getOne(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns data for one box
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOne(params: GetOne$Params, context?: HttpContext): Observable<BoxBoView> {
    return this.getOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<BoxBoView>): BoxBoView => r.body)
    );
  }

  /** Path part for operation `updateBox()` */
  static readonly UpdateBoxPath = '/boxes/{id}';

  /**
   * Updates a box
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBox()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBox$Response(params: UpdateBox$Params, context?: HttpContext): Observable<StrictHttpResponse<BoxBoView>> {
    return updateBox(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates a box
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBox$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBox(params: UpdateBox$Params, context?: HttpContext): Observable<BoxBoView> {
    return this.updateBox$Response(params, context).pipe(
      map((r: StrictHttpResponse<BoxBoView>): BoxBoView => r.body)
    );
  }

  /** Path part for operation `updateBoxCountries()` */
  static readonly UpdateBoxCountriesPath = '/boxes/{id}/countries';

  /**
   * Updates country assignment of a box
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBoxCountries()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBoxCountries$Response(params: UpdateBoxCountries$Params, context?: HttpContext): Observable<StrictHttpResponse<BoxBoView>> {
    return updateBoxCountries(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates country assignment of a box
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBoxCountries$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBoxCountries(params: UpdateBoxCountries$Params, context?: HttpContext): Observable<BoxBoView> {
    return this.updateBoxCountries$Response(params, context).pipe(
      map((r: StrictHttpResponse<BoxBoView>): BoxBoView => r.body)
    );
  }

  /** Path part for operation `updateBoxVehicleConnections()` */
  static readonly UpdateBoxVehicleConnectionsPath = '/boxes/vehicle-connections';

  /**
   * Updates vehicles assignment of a box
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBoxVehicleConnections()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBoxVehicleConnections$Response(params: UpdateBoxVehicleConnections$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BoxBoView>>> {
    return updateBoxVehicleConnections(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates vehicles assignment of a box
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBoxVehicleConnections$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBoxVehicleConnections(params: UpdateBoxVehicleConnections$Params, context?: HttpContext): Observable<Array<BoxBoView>> {
    return this.updateBoxVehicleConnections$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BoxBoView>>): Array<BoxBoView> => r.body)
    );
  }

  /** Path part for operation `getConnectedBoxesOfVehicle()` */
  static readonly GetConnectedBoxesOfVehiclePath = '/vehicles/{vehicleId}/boxes';

  /**
   * Returns all connected boxes for one vehicles.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConnectedBoxesOfVehicle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConnectedBoxesOfVehicle$Response(params: GetConnectedBoxesOfVehicle$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BoxBoView>>> {
    return getConnectedBoxesOfVehicle(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all connected boxes for one vehicles.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConnectedBoxesOfVehicle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConnectedBoxesOfVehicle(params: GetConnectedBoxesOfVehicle$Params, context?: HttpContext): Observable<Array<BoxBoView>> {
    return this.getConnectedBoxesOfVehicle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BoxBoView>>): Array<BoxBoView> => r.body)
    );
  }

  /** Path part for operation `getReturnAdressForBoxType()` */
  static readonly GetReturnAdressForBoxTypePath = '/boxes/{id}/returnaddress';

  /**
   * Reads the return address for given boxTypeId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReturnAdressForBoxType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReturnAdressForBoxType$Response(params: GetReturnAdressForBoxType$Params, context?: HttpContext): Observable<StrictHttpResponse<ReturnAddressView>> {
    return getReturnAdressForBoxType(this.http, this.rootUrl, params, context);
  }

  /**
   * Reads the return address for given boxTypeId
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReturnAdressForBoxType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReturnAdressForBoxType(params: GetReturnAdressForBoxType$Params, context?: HttpContext): Observable<ReturnAddressView> {
    return this.getReturnAdressForBoxType$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReturnAddressView>): ReturnAddressView => r.body)
    );
  }

  /** Path part for operation `getPossibleBoxStatusActions()` */
  static readonly GetPossibleBoxStatusActionsPath = '/boxes/{id}/actions';

  /**
   * Returns a list of all available box status actions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPossibleBoxStatusActions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPossibleBoxStatusActions$Response(params: GetPossibleBoxStatusActions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BoxStatusActionDto>>> {
    return getPossibleBoxStatusActions(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a list of all available box status actions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPossibleBoxStatusActions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPossibleBoxStatusActions(params: GetPossibleBoxStatusActions$Params, context?: HttpContext): Observable<Array<BoxStatusActionDto>> {
    return this.getPossibleBoxStatusActions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BoxStatusActionDto>>): Array<BoxStatusActionDto> => r.body)
    );
  }

  /** Path part for operation `getConnectableVehicles()` */
  static readonly GetConnectableVehiclesPath = '/boxes/{boxId}/connectable-vehicles';

  /**
   * Returns all connectable vehicles.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConnectableVehicles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConnectableVehicles$Response(params: GetConnectableVehicles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BoxVehicleView>>> {
    return getConnectableVehicles(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all connectable vehicles.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConnectableVehicles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConnectableVehicles(params: GetConnectableVehicles$Params, context?: HttpContext): Observable<Array<BoxVehicleView>> {
    return this.getConnectableVehicles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BoxVehicleView>>): Array<BoxVehicleView> => r.body)
    );
  }

  /** Path part for operation `getLockConfig()` */
  static readonly GetLockConfigPath = '/boxes/{boxId}/block-config';

  /**
   * Returns box lock config.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLockConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLockConfig$Response(params: GetLockConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<BlockConfigView>> {
    return getLockConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns box lock config.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLockConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLockConfig(params: GetLockConfig$Params, context?: HttpContext): Observable<BlockConfigView> {
    return this.getLockConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<BlockConfigView>): BlockConfigView => r.body)
    );
  }

  /** Path part for operation `getFaqDocumentForTollVehicleTypes()` */
  static readonly GetFaqDocumentForTollVehicleTypesPath = '/boxes/documents/faqs/toll-vehicle-types/{svgId}';

  /**
   * Get toll vehicle types Document (F.A.Q)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFaqDocumentForTollVehicleTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqDocumentForTollVehicleTypes$Response(params: GetFaqDocumentForTollVehicleTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<BoxBoDocument>> {
    return getFaqDocumentForTollVehicleTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get toll vehicle types Document (F.A.Q)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFaqDocumentForTollVehicleTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqDocumentForTollVehicleTypes(params: GetFaqDocumentForTollVehicleTypes$Params, context?: HttpContext): Observable<BoxBoDocument> {
    return this.getFaqDocumentForTollVehicleTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<BoxBoDocument>): BoxBoDocument => r.body)
    );
  }

}
