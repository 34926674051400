import { Directive, HostListener } from '@angular/core';

import { TableViewOptionsService } from '../services/table-view-options.service';

@Directive({ selector: '[svgFrontendsPageSize]' })
export class PageSizeDirective {
	constructor(private tableViewOptionsService: TableViewOptionsService) {}

	@HostListener('click', ['$event']) onClick(event: any): void {
		if (!!event.target.value) {
			const pageSize: number = event.target.value.split(': ')[1];
			this.tableViewOptionsService.setPageSize(pageSize);
		}
	}
}
