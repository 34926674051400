import { Pipe, PipeTransform } from '@angular/core';

import { DocumentService } from '../services/document.service';

@Pipe({ name: 'tempDownloadLinkFromHash' })
export class TempDownloadLinkFromHashPipe implements PipeTransform {
	constructor(private documentService: DocumentService) {}

	transform(downloadHash: string): string {
		return this.documentService.getTempDocumentUrlFromHash(downloadHash);
	}
}
