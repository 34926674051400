/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CentStream } from '../models/cent-stream';
import { downloadLink } from '../fn/download-controller/download-link';
import { DownloadLink$Params } from '../fn/download-controller/download-link';

@Injectable({ providedIn: 'root' })
export class DownloadControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `downloadLink()` */
  static readonly DownloadLinkPath = '/report/{provider}/{id}/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadLink$Response(params: DownloadLink$Params, context?: HttpContext): Observable<StrictHttpResponse<CentStream>> {
    return downloadLink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadLink(params: DownloadLink$Params, context?: HttpContext): Observable<CentStream> {
    return this.downloadLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<CentStream>): CentStream => r.body)
    );
  }

}
