// TODO - refactor to reflect status codes - for better translation from code to http status enum

export enum HttpStatus {
	CONFLICT,
	ERROR,
	IDLE,
	OK,
	PENDING,
	SUCCESS,
}
