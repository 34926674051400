import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { DialogLevel } from '../../enums/dialog-level.enum';
import { DialogSize } from '../../enums/dialog-size.enum';

export interface ActionDialogData<T> {
	action$: Observable<T>;
	body: SafeHtml;
	submitButton: string;
	title: string;

	cancelButton?: string;
	level?: DialogLevel;
	size?: DialogSize;
}

export enum ActionDialogState {
	CANCEL,
	SUCCESS,
	ERROR,
}

export interface ActionDialogResult<T> {
	state: ActionDialogState;

	error?: Error;
	value?: T;
}

@UntilDestroy()
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-action-dialog',
	styleUrls: ['./action-dialog.component.scss'],
	templateUrl: './action-dialog.component.html',
})
export class ActionDialogComponent<T> {
	DIALOG_LEVEL = DialogLevel;
	DIALOG_SIZE = DialogSize;

	loading = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public inputArgs: ActionDialogData<T>,
		private matDialogRef: MatDialogRef<ActionDialogComponent<T>, ActionDialogResult<T>>,
	) {}

	submitDialog(): void {
		this.loading = true;

		if (!this.inputArgs.action$) {
			this.matDialogRef.close({ state: ActionDialogState.SUCCESS });
		} else {
			this.inputArgs.action$.pipe(untilDestroyed(this)).subscribe({
				next: (value: T) => this.matDialogRef.close({ state: ActionDialogState.SUCCESS, value }),
				error: (error: Error) => this.matDialogRef.close({ state: ActionDialogState.ERROR, error }),
			});
		}
	}

	closeDialog(): void {
		this.matDialogRef.close({ state: ActionDialogState.CANCEL });
	}
}
