/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SafetyDocumentDto } from '../../models/safety-document-dto';

export interface GetSafetyDocuments$Params {
  portalUid: string;
  productType: 'PRTY-0' | 'PRTY-2' | 'PRTY-3' | 'PRTY-4' | 'PRTY-5' | 'PRTY-1101' | 'RV-K' | 'RV-B';
}

export function getSafetyDocuments(http: HttpClient, rootUrl: string, params: GetSafetyDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SafetyDocumentDto>>> {
  const rb = new RequestBuilder(rootUrl, getSafetyDocuments.PATH, 'get');
  if (params) {
    rb.query('portalUid', params.portalUid, {});
    rb.path('productType', params.productType, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<SafetyDocumentDto>>;
    })
  );
}

getSafetyDocuments.PATH = '/safeties/documents/{productType}';
