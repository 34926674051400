/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseFuelcardTransaction } from '../../models/response-fuelcard-transaction';

export interface GetTransactions$Params {

/**
 * the customer portal uid
 */
  customers: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';

/**
 * a list of providers
 */
  providers?: Array<string>;

/**
 * the settlement flag. if true the service will return settled transactions otherwise unsettled
 */
  settled: boolean;
  id?: string;
  material?: string;
  materialDescription?: string;
  materialGroup?: string;
  licensePlate?: string;
  licensePlateNation?: string;
  servicePoint?: string;
  servicePointName?: string;
  serviceCardNumber?: string;
  invoiceDate?: string;
  transactionDate?: string;
  transactionTime?: string;
  invoiceNumber?: string;
  mileage?: string;
  quantity?: string;
  quantityUnit?: string;
  totalGrossAmount?: string;
  totalNetAmount?: string;
  provider?: string;
  costCenter?: string;
  subCostCenter?: string;
  vatRate?: string;
  vatAmount?: string;
  discountGrossAmount?: string;
  discountPerUnitGrossAmount?: string;
  discountPercentage?: string;
  surchargeGrossAmount?: string;
  surchargePercentage?: string;
  unitPrice?: string;
  grossAmountLW?: string;
  currencyISO?: string;
  countryIsoCode?: string;
}

export function getTransactions(http: HttpClient, rootUrl: string, params: GetTransactions$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseFuelcardTransaction>> {
  const rb = new RequestBuilder(rootUrl, getTransactions.PATH, 'get');
  if (params) {
    rb.query('customers', params.customers, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
    rb.query('providers', params.providers, {});
    rb.query('settled', params.settled, {});
    rb.query('id', params.id, {});
    rb.query('material', params.material, {});
    rb.query('materialDescription', params.materialDescription, {});
    rb.query('materialGroup', params.materialGroup, {});
    rb.query('licensePlate', params.licensePlate, {});
    rb.query('licensePlateNation', params.licensePlateNation, {});
    rb.query('servicePoint', params.servicePoint, {});
    rb.query('servicePointName', params.servicePointName, {});
    rb.query('serviceCardNumber', params.serviceCardNumber, {});
    rb.query('invoiceDate', params.invoiceDate, {});
    rb.query('transactionDate', params.transactionDate, {});
    rb.query('transactionTime', params.transactionTime, {});
    rb.query('invoiceNumber', params.invoiceNumber, {});
    rb.query('mileage', params.mileage, {});
    rb.query('quantity', params.quantity, {});
    rb.query('quantityUnit', params.quantityUnit, {});
    rb.query('totalGrossAmount', params.totalGrossAmount, {});
    rb.query('totalNetAmount', params.totalNetAmount, {});
    rb.query('provider', params.provider, {});
    rb.query('costCenter', params.costCenter, {});
    rb.query('subCostCenter', params.subCostCenter, {});
    rb.query('vatRate', params.vatRate, {});
    rb.query('vatAmount', params.vatAmount, {});
    rb.query('discountGrossAmount', params.discountGrossAmount, {});
    rb.query('discountPerUnitGrossAmount', params.discountPerUnitGrossAmount, {});
    rb.query('discountPercentage', params.discountPercentage, {});
    rb.query('surchargeGrossAmount', params.surchargeGrossAmount, {});
    rb.query('surchargePercentage', params.surchargePercentage, {});
    rb.query('unitPrice', params.unitPrice, {});
    rb.query('grossAmountLW', params.grossAmountLW, {});
    rb.query('currencyISO', params.currencyISO, {});
    rb.query('countryIsoCode', params.countryIsoCode, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseFuelcardTransaction>;
    })
  );
}

getTransactions.PATH = '/transactions';
