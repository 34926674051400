import { Injectable } from '@angular/core';
import { CrefoOrderControllerService, CrefoOutstandingOrderDto } from '@mysvg/api/financial-guarantees';
import { SvgContextService } from '@svg-frontends/session';
import { RepositoryStoreService } from '../repository-store.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CrefoOutstandingOrdersStoreService extends RepositoryStoreService<CrefoOutstandingOrderDto[]> {
	constructor(private crefoOrderControllerService: CrefoOrderControllerService, private svgContextService: SvgContextService) {
		super();
	}

	fetchData(): Observable<CrefoOutstandingOrderDto[]> {
		return this.svgContextService
			.getFirstPortalUid()
			.pipe(mergeMap((portalUid: string) => this.crefoOrderControllerService.getOutStandingOrders({ portalUid })));
	}
}
