import { Injectable } from '@angular/core';
import { MailAddressControllerService, MailAddressDto } from '@mysvg/api/contact-data';
import { SvgContextService } from '@svg-frontends/session';
import { RepositoryStoreService } from '../repository-store.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MailAddressStoreService extends RepositoryStoreService<MailAddressDto[]> {
	constructor(private mailAddressControllerService: MailAddressControllerService, private svgContextService: SvgContextService) {
		super();
	}

	fetchData(): Observable<MailAddressDto[]> {
		return this.svgContextService
			.getFirstCustomerBaseId()
			.pipe(
				mergeMap((customerBaseId: number) =>
					this.mailAddressControllerService.getMailAddressesByEntity({ entityType: 'Customer', entityId: customerBaseId }),
				),
			);
	}
}
