import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { BusinessSectorPermission } from '@mysvg/api/auth';

import { FilterParams, Resp, UtilsService } from '@mysvg/utils';
import { ErrorHandlingService } from '@svg-frontends/error';
import { ContextState, SvgContext, SvgContextService } from '@svg-frontends/session';
import { BusinessSectorsStoreService } from '@svg-frontends/store';

@Injectable({ providedIn: 'root' })
export class SVGTableViewRepositoryService {
	constructor(
		private businessSectorsStoreService: BusinessSectorsStoreService,
		private errorHandlingService: ErrorHandlingService,
		private httpClient: HttpClient,
		private svgContextService: SvgContextService,
	) {}

	static addCustomersParam(params: HttpParams, svgContext: SvgContext): HttpParams {
		if (!(svgContext.state === ContextState.CUSTOMER || svgContext.state === ContextState.STAFF_CUSTOMER)) {
			return params;
		} else {
			return params.set('customers', svgContext.customerInfo.id);
		}
	}

	private static addSortParams(params: HttpParams, sortOrder?: 'ASC' | 'DESC', sortProperty?: string): HttpParams {
		if (sortProperty) {
			return params.set('sortBy', sortProperty).set('sortOrder', sortOrder);
		} else {
			return params;
		}
	}

	private static addFilterParams<T>(params: HttpParams, filters?: FilterParams<T>): HttpParams {
		if (filters) {
			Object.entries(filters).forEach(
				([key, value]: [string, any]) =>
					(params = params.set(SVGTableViewRepositoryService.fixParamKey(key), SVGTableViewRepositoryService.fixParamValue(value))),
			);
		}

		return params;
	}

	private static fixParamKey(paramKey: string): string {
		// use property of filter as key, except it is `provider`, then change to `providers`
		return paramKey === 'provider' ? 'providers' : paramKey;
	}

	private static fixParamValue(paramValue: any): string {
		return paramValue ? SVGTableViewRepositoryService.stringify(paramValue).replace('+', '%2B') : paramValue;
	}

	private static stringify(value: any): string {
		if (Array.isArray(value)) {
			return value.join(',');
		} else {
			return value ? value.toString() : value;
		}
	}

	loadData<T>(configuration: {
		category?: string;
		filters?: FilterParams<T>;
		pageNumber: number;
		pageSize: number;
		customParams?: HttpParams;
		sortOrder?: 'ASC' | 'DESC';
		sortProperty?: string;
		url: string;
	}): Observable<Resp<T>> {
		return this.createHttpParams({
			category: configuration.category,
			customParams: configuration.customParams,
			filters: configuration.filters,
			limit: configuration.pageSize,
			offset: configuration.pageSize * (configuration.pageNumber - 1),
			sortOrder: configuration.sortOrder,
			sortProperty: configuration.sortProperty,
		}).pipe(
			mergeMap((params: HttpParams) => this.httpClient.get<Resp<T>>(configuration.url, { params })),
			catchError((error: HttpErrorResponse) => {
				this.errorHandlingService.setNextErrorBy(error);
				return of({});
			}),
		);
	}

	private createHttpParams<T>(configuration: {
		category?: string;
		customParams?: HttpParams;
		filters?: FilterParams<T>;
		limit: number;
		offset: number;
		sortOrder?: 'ASC' | 'DESC';
		sortProperty?: string;
	}): Observable<HttpParams> {
		let params = (configuration.customParams ? configuration.customParams : new HttpParams())
			.set('limit', configuration.limit.toString())
			.set('offset', configuration.offset.toString());

		params = SVGTableViewRepositoryService.addSortParams(params, configuration.sortOrder, configuration.sortProperty);
		params = SVGTableViewRepositoryService.addFilterParams(params, configuration.filters);

		return this.svgContextService.getFirstSvgContext().pipe(
			map((svgContext: SvgContext) => SVGTableViewRepositoryService.addCustomersParam(params, svgContext)),
			mergeMap((p: HttpParams) => this.enrichParamsByBusinessSectors(p, configuration.category)),
		);
	}

	private enrichParamsByBusinessSectors(params: HttpParams, category?: string): Observable<HttpParams> {
		if (category) {
			return this.businessSectorsStoreService.getFirst().pipe(
				map((businessSectors: BusinessSectorPermission[]) =>
					UtilsService.createProviderParameter(businessSectors, params.get('providers'), category),
				),
				map((providerParam: string) => params.set('providers', providerParam)),
			);
		} else {
			return of(params);
		}
	}
}
