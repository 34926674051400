import { Injectable } from '@angular/core';

import { StorageService } from '@svg-frontends/storage';

@Injectable({ providedIn: 'root' })
export class LocalTableViewOptionsService {
	constructor(private storageService: StorageService) {}

	save(key: string, value: any): void {
		this.storageService.set(key, value, true);
	}

	getOptions(key: string): any | null {
		if (this.storageService.has(key)) {
			return this.storageService.get(key);
		} else {
			return null;
		}
	}
}
