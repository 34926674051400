import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Context } from '../../context/models/context.model';

@Injectable({ providedIn: 'root' })
export abstract class AuthenticationService<T extends Context> {
	abstract postLoginFlow(contextId: string | null): Observable<T>;

	abstract notLoggedIn(): T;
}
