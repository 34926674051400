import { LabeledEnumModel } from '../models/enum/labeledEnum.model';

export const TollBoxStatus: LabeledEnumModel = {
	// TODO to backend + matching
	aktiv: { label: 'aktiv' },
	angefordert: { label: 'angefordert' },
	bestellbereit: { label: 'bestellbereit' },
	bestellt: { label: 'bestellt' },
	abgelaufen: { label: 'abgelaufen' },
	gesperrt: { label: 'gesperrt' },
	zu_sperren: { label: 'zu sperren' },
};
