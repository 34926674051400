/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BoxOverviewViewResponse } from '../../models/box-overview-view-response';

export interface GetBoxes$Params {
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';

/**
 * Box Type
 */
  boxType?: Array<number>;

/**
 * Provider filter
 */
  tollProvider?: string;

/**
 * Box Status
 */
  boxStatus?: Array<number>;

/**
 * Box number filter
 */
  boxNumber?: string;

/**
 * License plate filter
 */
  licensePlate?: string;

/**
 * Countries filter, by country id
 */
  countries?: string;

/**
 * Created date filter
 */
  createdDate?: string;

/**
 * Expiration date filter
 */
  expirationDate?: string;

/**
 * Blocked date filter
 */
  blockedDate?: string;

/**
 * Blocked reason filter
 */
  blockReason?: string;

/**
 * the svg number
 */
  svgNumber?: string;

/**
 * the customer number
 */
  customerNumber?: string;

/**
 * the customer number
 */
  customerName?: string;

/**
 * the telematic product
 */
  telematicProduct?: string;

/**
 * the telematic status of the box
 */
  telematicStatus?: Array<'in_pruefung' | 'eingeschraenkt' | 'einbaufehler' | 'aktiv'>;

/**
 * filtering boxes with pending status (both box status and telematic status)
 */
  pendingOnly?: boolean;

/**
 * filering by activation state of the boxes
 */
  active?: boolean;
}

export function getBoxes(http: HttpClient, rootUrl: string, params?: GetBoxes$Params, context?: HttpContext): Observable<StrictHttpResponse<BoxOverviewViewResponse>> {
  const rb = new RequestBuilder(rootUrl, getBoxes.PATH, 'get');
  if (params) {
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
    rb.query('boxType', params.boxType, {});
    rb.query('tollProvider', params.tollProvider, {});
    rb.query('boxStatus', params.boxStatus, {});
    rb.query('boxNumber', params.boxNumber, {});
    rb.query('licensePlate', params.licensePlate, {});
    rb.query('countries', params.countries, {});
    rb.query('createdDate', params.createdDate, {});
    rb.query('expirationDate', params.expirationDate, {});
    rb.query('blockedDate', params.blockedDate, {});
    rb.query('blockReason', params.blockReason, {});
    rb.query('svgNumber', params.svgNumber, {});
    rb.query('customerNumber', params.customerNumber, {});
    rb.query('customerName', params.customerName, {});
    rb.query('telematicProduct', params.telematicProduct, {});
    rb.query('telematicStatus', params.telematicStatus, {});
    rb.query('pendingOnly', params.pendingOnly, {});
    rb.query('active', params.active, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<BoxOverviewViewResponse>;
    })
  );
}

getBoxes.PATH = '/staff/boxes/overview';
