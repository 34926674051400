/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { moveRVtoNominate } from '../fn/incident-controller/move-r-vto-nominate';
import { MoveRVtoNominate$Params } from '../fn/incident-controller/move-r-vto-nominate';
import { SimpleProcessDto } from '../models/simple-process-dto';

@Injectable({ providedIn: 'root' })
export class IncidentControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `moveRVtoNominate()` */
  static readonly MoveRVtoNominatePath = '/incidents/nominate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveRVtoNominate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  moveRVtoNominate$Response(params: MoveRVtoNominate$Params, context?: HttpContext): Observable<StrictHttpResponse<SimpleProcessDto>> {
    return moveRVtoNominate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveRVtoNominate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  moveRVtoNominate(params: MoveRVtoNominate$Params, context?: HttpContext): Observable<SimpleProcessDto> {
    return this.moveRVtoNominate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SimpleProcessDto>): SimpleProcessDto => r.body)
    );
  }

}
