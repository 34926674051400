import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { UtilsService } from '@mysvg/utils';

import { SvgContext } from '../models/svg-context.model';
import { KeycloakTokenClaims } from '../models/user-token-claims.model';
import { ContextService } from './context.service';

@Injectable({ providedIn: 'root' })
export class SvgContextService {
	constructor(private contextService: ContextService<SvgContext, KeycloakTokenClaims>) {}

	hasActivity(activity: string | string[]): Observable<boolean> {
		return this.contextService.hasActivity(activity);
	}

	/**
	 * @deprecated
	 */
	getAccessToken(): string | null {
		return this.contextService.getAccessToken();
	}

	getSvgContext(): Observable<SvgContext> {
		return this.contextService.getContext();
	}

	getFirstSvgContext(): Observable<SvgContext> {
		return this.contextService.getFirstContext().pipe(first());
	}

	getPortalUid(): Observable<string> {
		return this.getSvgContext().pipe(
			filter((svgContext: SvgContext) => !!svgContext.customerInfo),
			map((svgContext: SvgContext) => svgContext.customerInfo.id),
		);
	}

	getFirstPortalUid(): Observable<string> {
		return this.getFirstSvgContext().pipe(
			filter((svgContext: SvgContext) => !!svgContext.customerInfo),
			map((svgContext: SvgContext) => svgContext.customerInfo.id),
		);
	}

	getFirstCustomerBaseId(): Observable<number> {
		return this.getFirstSvgContext().pipe(
			filter((svgContext: SvgContext) => !!svgContext.customerInfo),
			map((svgContext: SvgContext) => UtilsService.getAliasFrom(svgContext.customerInfo)),
		);
	}

	getCustomerBaseId(): Observable<number> {
		return this.getSvgContext().pipe(
			filter((svgContext: SvgContext) => !!svgContext.customerInfo),
			map((svgContext: SvgContext) => UtilsService.getAliasFrom(svgContext.customerInfo)),
		);
	}
}
