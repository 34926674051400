/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DocumentLinkDto } from '../models/document-link-dto';
import { DocumentMetadataDto } from '../models/document-metadata-dto';
import { getCheckListDocuments } from '../fn/documents-controller/get-check-list-documents';
import { GetCheckListDocuments$Params } from '../fn/documents-controller/get-check-list-documents';
import { getContractDocuments } from '../fn/documents-controller/get-contract-documents';
import { GetContractDocuments$Params } from '../fn/documents-controller/get-contract-documents';
import { getDocument } from '../fn/documents-controller/get-document';
import { GetDocument$Params } from '../fn/documents-controller/get-document';
import { getDocumentLink } from '../fn/documents-controller/get-document-link';
import { GetDocumentLink$Params } from '../fn/documents-controller/get-document-link';
import { getDocuments } from '../fn/documents-controller/get-documents';
import { GetDocuments$Params } from '../fn/documents-controller/get-documents';
import { ResponseDocumentMetadataDto } from '../models/response-document-metadata-dto';
import { updateDocument } from '../fn/documents-controller/update-document';
import { UpdateDocument$Params } from '../fn/documents-controller/update-document';
import { uploadDocument } from '../fn/documents-controller/upload-document';
import { UploadDocument$Params } from '../fn/documents-controller/upload-document';


/**
 * Provides operations to manage documents including templates and document generation.
 */
@Injectable({ providedIn: 'root' })
export class DocumentsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDocuments()` */
  static readonly GetDocumentsPath = '/documents';

  /**
   * Get documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments$Response(params?: GetDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocumentMetadataDto>> {
    return getDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * Get documents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments(params?: GetDocuments$Params, context?: HttpContext): Observable<ResponseDocumentMetadataDto> {
    return this.getDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseDocumentMetadataDto>): ResponseDocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `updateDocument()` */
  static readonly UpdateDocumentPath = '/documents';

  /**
   * Update a document, this will create a new version which metadata is returned in the response.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDocument()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateDocument$Response(params: UpdateDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentMetadataDto>> {
    return updateDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a document, this will create a new version which metadata is returned in the response.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDocument$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateDocument(params: UpdateDocument$Params, context?: HttpContext): Observable<DocumentMetadataDto> {
    return this.updateDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentMetadataDto>): DocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `uploadDocument()` */
  static readonly UploadDocumentPath = '/documents';

  /**
   * Upload a document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadDocument()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadDocument$Response(params?: UploadDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentMetadataDto>> {
    return uploadDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload a document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadDocument$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadDocument(params?: UploadDocument$Params, context?: HttpContext): Observable<DocumentMetadataDto> {
    return this.uploadDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentMetadataDto>): DocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `getDocument()` */
  static readonly GetDocumentPath = '/documents/{id}';

  /**
   * Get document by document id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocument$Response(params: GetDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentMetadataDto>> {
    return getDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Get document by document id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocument(params: GetDocument$Params, context?: HttpContext): Observable<DocumentMetadataDto> {
    return this.getDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentMetadataDto>): DocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `getDocumentLink()` */
  static readonly GetDocumentLinkPath = '/documents/{id}/link';

  /**
   * Get public document link by document id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentLink$Response(params: GetDocumentLink$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
    return getDocumentLink(this.http, this.rootUrl, params, context);
  }

  /**
   * Get public document link by document id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentLink(params: GetDocumentLink$Params, context?: HttpContext): Observable<DocumentLinkDto> {
    return this.getDocumentLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentLinkDto>): DocumentLinkDto => r.body)
    );
  }

  /** Path part for operation `getContractDocuments()` */
  static readonly GetContractDocumentsPath = '/documents/contracts/{customerId}';

  /**
   * Get all contract documents by customer id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContractDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractDocuments$Response(params: GetContractDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocumentMetadataDto>> {
    return getContractDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all contract documents by customer id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContractDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractDocuments(params: GetContractDocuments$Params, context?: HttpContext): Observable<ResponseDocumentMetadataDto> {
    return this.getContractDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseDocumentMetadataDto>): ResponseDocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `getCheckListDocuments()` */
  static readonly GetCheckListDocumentsPath = '/documents/checklists/{customerId}';

  /**
   * Get all basis checklist documents by customer id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCheckListDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckListDocuments$Response(params: GetCheckListDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocumentMetadataDto>> {
    return getCheckListDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all basis checklist documents by customer id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCheckListDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckListDocuments(params: GetCheckListDocuments$Params, context?: HttpContext): Observable<ResponseDocumentMetadataDto> {
    return this.getCheckListDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseDocumentMetadataDto>): ResponseDocumentMetadataDto => r.body)
    );
  }

}
