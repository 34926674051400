import { faFileCsv, faFileExcel, faFilePdf, faFilePowerpoint, faFileVideo, faFileWord } from '@fortawesome/free-solid-svg-icons';

import { FileTypeInfo } from './file-type-info.model';
import { FileTypeEnum } from './file-type.enum';

export const FILE_TYPES: FileTypeInfo[] = [
	{
		extensions: ['csv'],
		icon: faFileCsv,
		isImagePreviewAvailable: false,
		key: FileTypeEnum.CSV,
		types: ['text/csv'],
	},
	{
		extensions: ['json'],
		isImagePreviewAvailable: false,
		key: FileTypeEnum.JSON,
		types: ['application/json'],
	},
	{
		extensions: ['pdf'],
		icon: faFilePdf,
		isImagePreviewAvailable: false,
		key: FileTypeEnum.PDF,
		types: ['application/pdf'],
	},
	{
		extensions: ['jpg', 'jpeg'],
		isImagePreviewAvailable: true,
		key: FileTypeEnum.JPG,
		types: ['image/jpeg'],
	},
	{
		extensions: ['png'],
		isImagePreviewAvailable: true,
		key: FileTypeEnum.PNG,
		types: ['image/png'],
	},
	{
		extensions: ['gif'],
		isImagePreviewAvailable: true,
		key: FileTypeEnum.GIF,
		types: ['image/gif'],
	},
	{
		extensions: ['svg'],
		isImagePreviewAvailable: true,
		key: FileTypeEnum.SVG,
		types: ['image/svg+xml'],
	},
	{
		extensions: ['mpg4', 'mpeg4'],
		icon: faFileVideo,
		isImagePreviewAvailable: false,
		key: FileTypeEnum.MP4,
		types: ['video/mp4'],
	},
	{
		extensions: ['docx', 'doc'],
		icon: faFileWord,
		isImagePreviewAvailable: false,
		key: FileTypeEnum.WORD,
		types: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
	},
	{
		extensions: ['xlsx', 'xls'],
		icon: faFileExcel,
		isImagePreviewAvailable: false,
		key: FileTypeEnum.EXCEL,
		types: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
	},
	{
		extensions: ['pptx', 'ppt'],
		icon: faFilePowerpoint,
		isImagePreviewAvailable: false,
		key: FileTypeEnum.POWERPOINT,
		types: ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
	},
	{
		extensions: ['msg'],
		isImagePreviewAvailable: false,
		key: FileTypeEnum.MSG,
		types: ['application/vnd.ms-outlook'],
	},
];
