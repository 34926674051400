<div
	[ngClass]="{
		'mac-custom-dialog--sm': size === DIALOG_SIZE.SMALL,
		'mac-custom-dialog--lg': size === DIALOG_SIZE.LARGE,
		'mac-custom-dialog--xl': size === DIALOG_SIZE.EXTRA_LARGE
	}"
	aria-hidden="true"
	class="mac-custom-dialog"
	role="dialog"
>
	<div class="modal-content">
		<div class="modal-header">
			<button (click)="closeDialog.emit()" *ngIf="closable" aria-label="Close" class="close" type="button">
				<cds-icon aria-hidden="true" shape="window-close"></cds-icon>
			</button>
			<h3 class="modal-title">{{ title }}</h3>
		</div>

		<ng-content></ng-content>
	</div>
</div>
