/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ExternalDocumentSubTypeDirectory } from '../../models/external-document-sub-type-directory';

export interface GetDocumentSubTypeDirectoryByCustomerAndType$Params {
  typeId: number;
  puid: string;
}

export function getDocumentSubTypeDirectoryByCustomerAndType(http: HttpClient, rootUrl: string, params: GetDocumentSubTypeDirectoryByCustomerAndType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExternalDocumentSubTypeDirectory>>> {
  const rb = new RequestBuilder(rootUrl, getDocumentSubTypeDirectoryByCustomerAndType.PATH, 'get');
  if (params) {
    rb.path('typeId', params.typeId, {});
    rb.path('puid', params.puid, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ExternalDocumentSubTypeDirectory>>;
    })
  );
}

getDocumentSubTypeDirectoryByCustomerAndType.PATH = '/external-document/type-directories/{typeId}/sub-type-directories/customers/{puid}';
