/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface ResetActualConditionsToStandardConditions$Params {
  runtimeGroupId: number;
  expirationDate: string;
  respectAdvanceInformationDuty?: boolean;
  portalUid: string;
}

export function resetActualConditionsToStandardConditions(http: HttpClient, rootUrl: string, params: ResetActualConditionsToStandardConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, resetActualConditionsToStandardConditions.PATH, 'post');
  if (params) {
    rb.path('runtimeGroupId', params.runtimeGroupId, {});
    rb.query('expirationDate', params.expirationDate, {});
    rb.query('respectAdvanceInformationDuty', params.respectAdvanceInformationDuty, {});
    rb.query('portalUid', params.portalUid, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

resetActualConditionsToStandardConditions.PATH = '/toll-conditions/condition-set/{runtimeGroupId}/terminate';
