import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';

@UntilDestroy()
@Injectable()
export class FormGroupService {
	submit$: Observable<void>;

	private submitSubject: Subject<void> = new Subject<void>();

	constructor() {
		this.submit$ = this.submitSubject.asObservable().pipe(untilDestroyed(this));
	}

	static touchFormGroup(formGroup: FormGroup): void {
		Object.keys(formGroup.controls).forEach((controlName: string) => {
			const control: AbstractControl | null = formGroup.get(controlName);

			if (control instanceof FormControl || control instanceof FormGroup) {
				this.touchControl(control);
			}
		});
	}

	static touchControl(abstractControl: AbstractControl): void {
		abstractControl.markAsTouched();
		abstractControl.markAsDirty();
		abstractControl.updateValueAndValidity();
	}

	submitForm(): void {
		this.submitSubject.next();
	}
}
