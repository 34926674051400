/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllBookings } from '../fn/mailbox-directory-controller/get-all-bookings';
import { GetAllBookings$Params } from '../fn/mailbox-directory-controller/get-all-bookings';

@Injectable({ providedIn: 'root' })
export class MailboxDirectoryControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllBookings()` */
  static readonly GetAllBookingsPath = '/mailboxdirectory/process';

  /**
   * Trigger for mailbox reference processing
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBookings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBookings$Response(params?: GetAllBookings$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getAllBookings(this.http, this.rootUrl, params, context);
  }

  /**
   * Trigger for mailbox reference processing
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllBookings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBookings(params?: GetAllBookings$Params, context?: HttpContext): Observable<void> {
    return this.getAllBookings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
