/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BankDetailsType } from '../models/bank-details-type';
import { getAllBankDetailsTypes } from '../fn/bank-details-type-controller/get-all-bank-details-types';
import { GetAllBankDetailsTypes$Params } from '../fn/bank-details-type-controller/get-all-bank-details-types';
import { getAllowedTypes } from '../fn/bank-details-type-controller/get-allowed-types';
import { GetAllowedTypes$Params } from '../fn/bank-details-type-controller/get-allowed-types';
import { ResponseBankDetailsType } from '../models/response-bank-details-type';

@Injectable({ providedIn: 'root' })
export class BankDetailsTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllBankDetailsTypes()` */
  static readonly GetAllBankDetailsTypesPath = '/bankdetails/types';

  /**
   * Get a list of all bank details types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBankDetailsTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBankDetailsTypes$Response(params?: GetAllBankDetailsTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBankDetailsType>> {
    return getAllBankDetailsTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of all bank details types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllBankDetailsTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBankDetailsTypes(params?: GetAllBankDetailsTypes$Params, context?: HttpContext): Observable<ResponseBankDetailsType> {
    return this.getAllBankDetailsTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseBankDetailsType>): ResponseBankDetailsType => r.body)
    );
  }

  /** Path part for operation `getAllowedTypes()` */
  static readonly GetAllowedTypesPath = '/bankdetails/types/allowed';

  /**
   * Get a list of all allowed bank details types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllowedTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllowedTypes$Response(params: GetAllowedTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BankDetailsType>>> {
    return getAllowedTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of all allowed bank details types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllowedTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllowedTypes(params: GetAllowedTypes$Params, context?: HttpContext): Observable<Array<BankDetailsType>> {
    return this.getAllowedTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BankDetailsType>>): Array<BankDetailsType> => r.body)
    );
  }

}
