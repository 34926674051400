import { Injectable } from '@angular/core';
import { MandateStatusControllerService, MandateStatusDto, ResponseMandateStatusDto } from '@mysvg/api/account-master-data';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MandateStatusesStoreService extends RepositoryStoreService<MandateStatusDto[]> {
	constructor(private mandateStatusControllerService: MandateStatusControllerService) {
		super();
	}

	fetchData(): Observable<MandateStatusDto[]> {
		return this.mandateStatusControllerService.getAllMandateStatus().pipe(map((response: ResponseMandateStatusDto) => response.data));
	}
}
