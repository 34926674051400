<clr-alert *ngIf="isNewVersionReady$ | async" [clrAlertAppLevel]="true" [clrAlertClosable]="false" [clrAlertType]="ALERT_LEVEL">
	<clr-alert-item>
		<span class="alert-text mac-alert-text">
			<span>Update: Es gibt eine neue Version. Bitte laden Sie die Seite neu.</span>
		</span>
		<div class="alert-actions">
			<button (click)="reload()" class="btn alert-action">Neu Laden</button>
		</div>
	</clr-alert-item>
</clr-alert>
