/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { updateVehicle } from '../fn/vehicle-controller/update-vehicle';
import { UpdateVehicle$Params } from '../fn/vehicle-controller/update-vehicle';
import { VehicleResponse } from '../models/vehicle-response';

@Injectable({ providedIn: 'root' })
export class VehicleControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateVehicle()` */
  static readonly UpdateVehiclePath = '/vehicles/{id}';

  /**
   * updating the vehicle.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVehicle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVehicle$Response(params: UpdateVehicle$Params, context?: HttpContext): Observable<StrictHttpResponse<VehicleResponse>> {
    return updateVehicle(this.http, this.rootUrl, params, context);
  }

  /**
   * updating the vehicle.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateVehicle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVehicle(params: UpdateVehicle$Params, context?: HttpContext): Observable<VehicleResponse> {
    return this.updateVehicle$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleResponse>): VehicleResponse => r.body)
    );
  }

}
