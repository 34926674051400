import { InjectionToken } from '@angular/core';
import { AsiApiBaseUrls, MysvgBaseUrls } from '@mysvg/utils';
import { ApplicationNamesEnum } from './application-names.enum';
import { StagesEnum } from './stages.enum';

export const APPLICATION_NAME = new InjectionToken<ApplicationNamesEnum>('name of the current application');

export const APP_VERSION_TOKEN: InjectionToken<string> = new InjectionToken<string>(
	'version (major.minor.path-buildnumber) of frontend build',
);
export const STAGE_TOKEN: InjectionToken<StagesEnum> = new InjectionToken<StagesEnum>(
	'stage (testing, staging, production) configuration for frontend',
);
export const API_BASE_URLS_TOKEN: InjectionToken<MysvgBaseUrls | AsiApiBaseUrls> = new InjectionToken<MysvgBaseUrls | AsiApiBaseUrls>(
	'Object with keys for services and values for urls e.g. `{ addresses: `https://mysvg.de/addresses/1.0`, ... }`',
);
