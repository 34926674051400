<svg-frontends-dialog-container
	(closeDialog)="closeDialog()"
	[closable]="!loading"
	[size]="inputArgs.size || DIALOG_SIZE.LARGE"
	[title]="inputArgs.title"
>
	<svg-frontends-dialog-body-container>
		<div [innerHTML]="inputArgs.body"></div>
	</svg-frontends-dialog-body-container>

	<svg-frontends-dialog-footer-container>
		<button (click)="closeDialog()" [disabled]="loading" class="btn btn-primary btn-outline" type="button">
			{{ inputArgs.cancelButton || 'Schließen' }}
		</button>
		<button
			(click)="submitDialog()"
			[clrLoading]="loading"
			[ngClass]="{
				'btn-primary': inputArgs.level === DIALOG_LEVEL.INFO || !(inputArgs.level | isDefined),
				'btn-success': inputArgs.level === DIALOG_LEVEL.SUCCESS,
				'btn-warning': inputArgs.level === DIALOG_LEVEL.WARNING,
				'btn-danger': inputArgs.level === DIALOG_LEVEL.DANGER
			}"
			class="btn btn-primary"
			type="button"
		>
			{{ inputArgs.submitButton }}
		</button>
	</svg-frontends-dialog-footer-container>
</svg-frontends-dialog-container>
