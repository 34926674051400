/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addNewDocument } from '../fn/svg-document-controller/add-new-document';
import { AddNewDocument$Params } from '../fn/svg-document-controller/add-new-document';
import { addNewSecuredDocument } from '../fn/svg-document-controller/add-new-secured-document';
import { AddNewSecuredDocument$Params } from '../fn/svg-document-controller/add-new-secured-document';
import { deleteSecuredSvgDocumentByDownloadHash } from '../fn/svg-document-controller/delete-secured-svg-document-by-download-hash';
import { DeleteSecuredSvgDocumentByDownloadHash$Params } from '../fn/svg-document-controller/delete-secured-svg-document-by-download-hash';
import { deleteSvgDocumentByDownloadHash } from '../fn/svg-document-controller/delete-svg-document-by-download-hash';
import { DeleteSvgDocumentByDownloadHash$Params } from '../fn/svg-document-controller/delete-svg-document-by-download-hash';
import { Document } from '../models/document';
import { DocumentTypeDto } from '../models/document-type-dto';
import { getDocumentByDownloadHash } from '../fn/svg-document-controller/get-document-by-download-hash';
import { GetDocumentByDownloadHash$Params } from '../fn/svg-document-controller/get-document-by-download-hash';
import { getDocumentsOfSvgs } from '../fn/svg-document-controller/get-documents-of-svgs';
import { GetDocumentsOfSvgs$Params } from '../fn/svg-document-controller/get-documents-of-svgs';
import { getDocumentTypes } from '../fn/svg-document-controller/get-document-types';
import { GetDocumentTypes$Params } from '../fn/svg-document-controller/get-document-types';
import { getSvgDocumentByDownloadHash } from '../fn/svg-document-controller/get-svg-document-by-download-hash';
import { GetSvgDocumentByDownloadHash$Params } from '../fn/svg-document-controller/get-svg-document-by-download-hash';
import { ResponseSvgDocument } from '../models/response-svg-document';
import { SvgDocument } from '../models/svg-document';
import { updateDocument } from '../fn/svg-document-controller/update-document';
import { UpdateDocument$Params } from '../fn/svg-document-controller/update-document';
import { updateSecuredDocument } from '../fn/svg-document-controller/update-secured-document';
import { UpdateSecuredDocument$Params } from '../fn/svg-document-controller/update-secured-document';

@Injectable({ providedIn: 'root' })
export class SvgDocumentControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateSecuredDocument()` */
  static readonly UpdateSecuredDocumentPath = '/secured-documents/{downloadHash}';

  /**
   * update the secured document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSecuredDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSecuredDocument$Response(params: UpdateSecuredDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgDocument>> {
    return updateSecuredDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * update the secured document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSecuredDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSecuredDocument(params: UpdateSecuredDocument$Params, context?: HttpContext): Observable<SvgDocument> {
    return this.updateSecuredDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<SvgDocument>): SvgDocument => r.body)
    );
  }

  /** Path part for operation `getDocumentByDownloadHash()` */
  static readonly GetDocumentByDownloadHashPath = '/documents/{downloadHash}';

  /**
   * get svg document by download hash
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentByDownloadHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentByDownloadHash$Response(params: GetDocumentByDownloadHash$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgDocument>> {
    return getDocumentByDownloadHash(this.http, this.rootUrl, params, context);
  }

  /**
   * get svg document by download hash
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentByDownloadHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentByDownloadHash(params: GetDocumentByDownloadHash$Params, context?: HttpContext): Observable<SvgDocument> {
    return this.getDocumentByDownloadHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<SvgDocument>): SvgDocument => r.body)
    );
  }

  /** Path part for operation `updateDocument()` */
  static readonly UpdateDocumentPath = '/documents/{downloadHash}';

  /**
   * update the document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDocument$Response(params: UpdateDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgDocument>> {
    return updateDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * update the document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDocument(params: UpdateDocument$Params, context?: HttpContext): Observable<SvgDocument> {
    return this.updateDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<SvgDocument>): SvgDocument => r.body)
    );
  }

  /** Path part for operation `addNewSecuredDocument()` */
  static readonly AddNewSecuredDocumentPath = '/svgs/{id}/secured-documents';

  /**
   * uploading a secured document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewSecuredDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewSecuredDocument$Response(params: AddNewSecuredDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<Document>> {
    return addNewSecuredDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * uploading a secured document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addNewSecuredDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewSecuredDocument(params: AddNewSecuredDocument$Params, context?: HttpContext): Observable<Document> {
    return this.addNewSecuredDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<Document>): Document => r.body)
    );
  }

  /** Path part for operation `getDocumentsOfSvgs()` */
  static readonly GetDocumentsOfSvgsPath = '/svgs/{id}/documents';

  /**
   * get all documents of the svg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentsOfSvgs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsOfSvgs$Response(params: GetDocumentsOfSvgs$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSvgDocument>> {
    return getDocumentsOfSvgs(this.http, this.rootUrl, params, context);
  }

  /**
   * get all documents of the svg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentsOfSvgs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsOfSvgs(params: GetDocumentsOfSvgs$Params, context?: HttpContext): Observable<ResponseSvgDocument> {
    return this.getDocumentsOfSvgs$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSvgDocument>): ResponseSvgDocument => r.body)
    );
  }

  /** Path part for operation `addNewDocument()` */
  static readonly AddNewDocumentPath = '/svgs/{id}/documents';

  /**
   * uploading a document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewDocument$Response(params: AddNewDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<Document>> {
    return addNewDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * uploading a document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addNewDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewDocument(params: AddNewDocument$Params, context?: HttpContext): Observable<Document> {
    return this.addNewDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<Document>): Document => r.body)
    );
  }

  /** Path part for operation `getSvgDocumentByDownloadHash()` */
  static readonly GetSvgDocumentByDownloadHashPath = '/svgs/{id}/documents/{downloadHash}';

  /**
   * get svg document by svg number and download hash
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSvgDocumentByDownloadHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgDocumentByDownloadHash$Response(params: GetSvgDocumentByDownloadHash$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgDocument>> {
    return getSvgDocumentByDownloadHash(this.http, this.rootUrl, params, context);
  }

  /**
   * get svg document by svg number and download hash
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSvgDocumentByDownloadHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgDocumentByDownloadHash(params: GetSvgDocumentByDownloadHash$Params, context?: HttpContext): Observable<SvgDocument> {
    return this.getSvgDocumentByDownloadHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<SvgDocument>): SvgDocument => r.body)
    );
  }

  /** Path part for operation `deleteSvgDocumentByDownloadHash()` */
  static readonly DeleteSvgDocumentByDownloadHashPath = '/svgs/{id}/documents/{downloadHash}';

  /**
   * delete svg document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSvgDocumentByDownloadHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSvgDocumentByDownloadHash$Response(params: DeleteSvgDocumentByDownloadHash$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteSvgDocumentByDownloadHash(this.http, this.rootUrl, params, context);
  }

  /**
   * delete svg document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSvgDocumentByDownloadHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSvgDocumentByDownloadHash(params: DeleteSvgDocumentByDownloadHash$Params, context?: HttpContext): Observable<void> {
    return this.deleteSvgDocumentByDownloadHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getDocumentTypes()` */
  static readonly GetDocumentTypesPath = '/documents/types';

  /**
   * get a list of all document types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes$Response(params?: GetDocumentTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentTypeDto>>> {
    return getDocumentTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * get a list of all document types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes(params?: GetDocumentTypes$Params, context?: HttpContext): Observable<Array<DocumentTypeDto>> {
    return this.getDocumentTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentTypeDto>>): Array<DocumentTypeDto> => r.body)
    );
  }

  /** Path part for operation `deleteSecuredSvgDocumentByDownloadHash()` */
  static readonly DeleteSecuredSvgDocumentByDownloadHashPath = '/svgs/{id}/secured-documents/{downloadHash}';

  /**
   * delete secured svg document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSecuredSvgDocumentByDownloadHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSecuredSvgDocumentByDownloadHash$Response(params: DeleteSecuredSvgDocumentByDownloadHash$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteSecuredSvgDocumentByDownloadHash(this.http, this.rootUrl, params, context);
  }

  /**
   * delete secured svg document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSecuredSvgDocumentByDownloadHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSecuredSvgDocumentByDownloadHash(params: DeleteSecuredSvgDocumentByDownloadHash$Params, context?: HttpContext): Observable<void> {
    return this.deleteSecuredSvgDocumentByDownloadHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
