import { Injectable } from '@angular/core';
import { isDefined } from '@mysvg/utils';

import { FileTypeInfo } from '../file-type-info.model';
import { FileTypeEnum } from '../file-type.enum';
import { FILE_TYPES } from '../file-types.config';

@Injectable({ providedIn: 'root' })
export class FileTypeInfoService {
	/**
	 * static function is needed vor validators
	 * TODO - make validators injectable, then this function can be converted / omitted
	 */
	static getFileTypesFor(fileTypes: FileTypeEnum[]): string[] {
		if (fileTypes.length === 0) {
			return [];
		} else {
			/* Angular Compiler won't accept returning a lambda statement in static function */
			const result: string[] = fileTypes
				.map((macFileTypeEnum: FileTypeEnum) => FILE_TYPES.find((macFileTypeInfo: FileTypeInfo) => macFileTypeInfo.key === macFileTypeEnum))
				.map((macFileTypeInfo: FileTypeInfo) => macFileTypeInfo.types)
				.reduce((previousValue, currentValue) => [...previousValue, ...currentValue]);
			return result;
		}
	}

	getByType(typeString: string): FileTypeEnum | undefined {
		const matching: FileTypeInfo = FILE_TYPES.find((fileType: FileTypeInfo) => fileType.types.indexOf(typeString) !== -1);
		return isDefined(matching) ? matching.key : undefined;
	}

	getTypesFor(macFileTypeEnum: FileTypeEnum): string[] | undefined {
		const matching: FileTypeInfo = this.getFileTypeInfoFor(macFileTypeEnum);
		return isDefined(matching) ? matching.types : undefined;
	}

	getExtensionsFor(macFileTypeEnum: FileTypeEnum): string[] | undefined {
		const matching: FileTypeInfo = this.getFileTypeInfoFor(macFileTypeEnum);
		return isDefined(matching) ? matching.extensions : undefined;
	}

	getFileTypeInfoFor(macFileTypeEnum: FileTypeEnum): FileTypeInfo | undefined {
		return FILE_TYPES.find((fileType: FileTypeInfo) => fileType.key === macFileTypeEnum);
	}
}
