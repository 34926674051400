/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllMailAddressTypes } from '../fn/mail-address-type-controller/get-all-mail-address-types';
import { GetAllMailAddressTypes$Params } from '../fn/mail-address-type-controller/get-all-mail-address-types';
import { list } from '../fn/mail-address-type-controller/list';
import { List$Params } from '../fn/mail-address-type-controller/list';
import { listNotOwned } from '../fn/mail-address-type-controller/list-not-owned';
import { ListNotOwned$Params } from '../fn/mail-address-type-controller/list-not-owned';
import { MailAddressTypeDto } from '../models/mail-address-type-dto';
import { MailAddressTypeFullDto } from '../models/mail-address-type-full-dto';

@Injectable({ providedIn: 'root' })
export class MailAddressTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `list()` */
  static readonly ListPath = '/mailaddresses/{communicationEntity}';

  /**
   * retrieve all mail address types for the given communicationEntity (11 = ContactPerson, 73 = Customer)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list()` instead.
   *
   * This method doesn't expect any request body.
   */
  list$Response(params: List$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MailAddressTypeDto>>> {
    return list(this.http, this.rootUrl, params, context);
  }

  /**
   * retrieve all mail address types for the given communicationEntity (11 = ContactPerson, 73 = Customer)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list(params: List$Params, context?: HttpContext): Observable<Array<MailAddressTypeDto>> {
    return this.list$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MailAddressTypeDto>>): Array<MailAddressTypeDto> => r.body)
    );
  }

  /** Path part for operation `getAllMailAddressTypes()` */
  static readonly GetAllMailAddressTypesPath = '/mailaddresses/types';

  /**
   * retrieve all mail address types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMailAddressTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMailAddressTypes$Response(params?: GetAllMailAddressTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MailAddressTypeDto>>> {
    return getAllMailAddressTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * retrieve all mail address types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMailAddressTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMailAddressTypes(params?: GetAllMailAddressTypes$Params, context?: HttpContext): Observable<Array<MailAddressTypeDto>> {
    return this.getAllMailAddressTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MailAddressTypeDto>>): Array<MailAddressTypeDto> => r.body)
    );
  }

  /** Path part for operation `listNotOwned()` */
  static readonly ListNotOwnedPath = '/mailaddresses/notowned/{customerId}';

  /**
   * retrieve mail address types not owned by the given customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listNotOwned()` instead.
   *
   * This method doesn't expect any request body.
   */
  listNotOwned$Response(params: ListNotOwned$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MailAddressTypeFullDto>>> {
    return listNotOwned(this.http, this.rootUrl, params, context);
  }

  /**
   * retrieve mail address types not owned by the given customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listNotOwned$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listNotOwned(params: ListNotOwned$Params, context?: HttpContext): Observable<Array<MailAddressTypeFullDto>> {
    return this.listNotOwned$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MailAddressTypeFullDto>>): Array<MailAddressTypeFullDto> => r.body)
    );
  }

}
