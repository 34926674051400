import { Pipe, PipeTransform } from '@angular/core';

import { isDefined } from '../utils/general.utils';

@Pipe({
	name: 'trackingNumberToTrackingUrl',
})
export class TrackingNumberToTrackingUrlPipe implements PipeTransform {
	transform(trackingNumber: string): string {
		return isDefined(trackingNumber) ? `http://nolp.dhl.de/nextt-online-public/set_identcodes.do?lang=de&idc=${trackingNumber}` : null;
	}
}
