import { Pipe, PipeTransform } from '@angular/core';
import * as IBAN from 'ibantools';

@Pipe({ name: 'iban' })
export class IbanPipe implements PipeTransform {
	private defaceCharsCount = 4;
	private defaceChar = '*';
	private ibanSeparator = ' ';

	transform(
		value: string,
		defaceIt: boolean = true,
		defaceChar: string = this.defaceChar,
		defaceCharsCount: number = this.defaceCharsCount,
	): string {
		if (value) {
			const formattedIban: string = IBAN.isValidIBAN(value) ? IBAN.friendlyFormatIBAN(value) : value;
			return !defaceIt ? formattedIban : this.defaceIban(formattedIban, defaceChar, defaceCharsCount);
		} else {
			return value;
		}
	}

	private defaceIban(formattedIban: string, defaceChar: string, defaceCharsCount: number): string {
		const ibanNoWhitespaces = formattedIban.replace(/\s/, '');

		if (ibanNoWhitespaces.length > defaceCharsCount) {
			return this.deface(formattedIban, defaceChar, defaceCharsCount);
		} else {
			return formattedIban;
		}
	}

	/**
	 * converts txt to array, reverses it, maps some chars to defacedChar, re-reverses array, and joins back to string
	 */
	private deface(txt: string, defaceChar: string, defaceCharsCount: number): string {
		const reversedArray = txt.split('').reverse();
		const mappedArray = this.mapToDefacedChar(reversedArray, defaceChar, defaceCharsCount);
		const correctOrderedArray = mappedArray.reverse();
		return correctOrderedArray.join('');
	}

	/**
	 * takes reversed array of chars and the count of chars to deface
	 */
	private mapToDefacedChar(arr: string[], defaceChar: string, defaceCharsCount: number): string[] {
		let charsCount = defaceCharsCount; // increase count for every whitespace
		return arr.map((char: string, index: number) => {
			const isSeparator = char === this.ibanSeparator;
			const shouldDeface = index >= charsCount && !isSeparator;

			if (isSeparator) {
				charsCount++;
			}

			return shouldDeface ? defaceChar : char;
		});
	}
}
