/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createCustomerResurrection } from '../fn/resurrection-controller/create-customer-resurrection';
import { CreateCustomerResurrection$Params } from '../fn/resurrection-controller/create-customer-resurrection';
import { getResurrectableCustomers } from '../fn/resurrection-controller/get-resurrectable-customers';
import { GetResurrectableCustomers$Params } from '../fn/resurrection-controller/get-resurrectable-customers';
import { ResponseResurrectCustomerDto } from '../models/response-resurrect-customer-dto';

@Injectable({ providedIn: 'root' })
export class ResurrectionControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createCustomerResurrection()` */
  static readonly CreateCustomerResurrectionPath = '/customer/resurrection/{customerBaseId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomerResurrection()` instead.
   *
   * This method doesn't expect any request body.
   */
  createCustomerResurrection$Response(params: CreateCustomerResurrection$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createCustomerResurrection(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCustomerResurrection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createCustomerResurrection(params: CreateCustomerResurrection$Params, context?: HttpContext): Observable<void> {
    return this.createCustomerResurrection$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getResurrectableCustomers()` */
  static readonly GetResurrectableCustomersPath = '/customer/resurrection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResurrectableCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResurrectableCustomers$Response(params?: GetResurrectableCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseResurrectCustomerDto>> {
    return getResurrectableCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getResurrectableCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResurrectableCustomers(params?: GetResurrectableCustomers$Params, context?: HttpContext): Observable<ResponseResurrectCustomerDto> {
    return this.getResurrectableCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseResurrectCustomerDto>): ResponseResurrectCustomerDto => r.body)
    );
  }

}
