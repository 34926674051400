import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe is required to match the accountConditionType 'P' to % and 'A' to €.
 * Required only for accountConditions.
 */
@Pipe({ name: 'accountConditionUnit' })
export class AccountConditionUnitPipe implements PipeTransform {
	transform(value: string): string {
		return value === 'P' ? '%' : '€';
	}
}
