/**
 * This is the only place where clr-variables from src/app/utils/enums/clr-colors.enum.ts should be referenced directly.
 */
import { ClrColors } from './clr-colors.enum';

export enum Colors {
	// Yellow
	ACCOUNT = ClrColors.YELLOW_6,
	DKV = ClrColors.YELLOW_2,
	DOCUMENTS = ClrColors.YELLOW_8,
	E_INVOICING = ClrColors.YELLOW_4,
	EUROSHELL = ClrColors.YELLOW_0,

	// Blue
	ASFINAGMAUT = ClrColors.BLUE_1,
	REGISTRATION = ClrColors.BLUE_2,
	VALIDATE = ClrColors.BLUE_6,

	// Purple
	ASI = ClrColors.PURPLE_1,
	CONDITIONS = ClrColors.PURPLE_4,
	UPLOAD_INQUIRY = ClrColors.PURPLE_6,
	VIALTIS = ClrColors.PURPLE_6,

	// Red-Orange
	ASSURANCE = ClrColors.RED_ORANGE_1,
	TOTAL = ClrColors.RED_ORANGE_6,
	VEHICLE = ClrColors.RED_ORANGE_4,

	// Cyan
	BELGIENMAUT = ClrColors.CYAN_8,
	ESSO = ClrColors.CYAN_6,

	// Orange
	BGHW = ClrColors.ORANGE_8,
	EVIGNETTE = ClrColors.ORANGE_1,
	SVGBOX = ClrColors.ORANGE_4,

	// Pink
	CONNECT_BOXES_TO_VEHICLES = ClrColors.PINK_4,
	CREDIT = ClrColors.PINK_1,
	CUST_DOWNLOAD_EETS_VEHICLE_DOCUMENT = ClrColors.PINK_6,
	EMP_UPLOAD_EETS_VEHICLE_DOCUMENT = ClrColors.PINK_8,

	// Green
	CUSTOMER = ClrColors.GREEN_6,
	DHL_ADDRESS = ClrColors.GREEN_1,
	PRIVACY = ClrColors.GREEN_3,
	TELEPASS = ClrColors.GREEN_8,
	TISPL = ClrColors.GREEN_10,

	// Teal
	DMAUT = ClrColors.TEAL_4,
	ESSODE = ClrColors.TEAL_6,
	UPLOAD_SEPA_MANDAT = ClrColors.TEAL_1,

	// Ultramarine
	DRIVERDB = ClrColors.ULTRAMARINE_1,
	NOMINATE = ClrColors.ULTRAMARINE_8,
	UPDATE_SEPA_MANDAT = ClrColors.ULTRAMARINE_4,

	// Red
	EETS = ClrColors.RED_1,
	EMAUT = ClrColors.RED_5,
	MAINTENANCE = ClrColors.RED_0,

	// Cool-Grey
	FINALIZE_SEPA_MANDAT = ClrColors.COOL_GREY_4,
	RV = ClrColors.COOL_GREY_8,
	SUBSIDIES = ClrColors.COOL_GREY_2,
}
