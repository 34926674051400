import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AuthControllerService, BusinessSectorPermission } from '@mysvg/api/auth';

import { ContextState, SvgContext, SvgContextService } from '@svg-frontends/session';

import { RepositoryStoreService } from './repository-store.service';

const UNIQUE_FLEXBOX_BUSINESS_SECTOR_NAME = 'flexBox';

// TODO move this into business sectors library
@Injectable({ providedIn: 'root' })
export class BusinessSectorsStoreService extends RepositoryStoreService<BusinessSectorPermission[]> {
	constructor(private authControllerService: AuthControllerService, private svgContextService: SvgContextService) {
		super();
	}

	/**
	 * load business sectors from customer by state
	 * reset business sectors in staff state
	 */
	private static loadBusinessSectorsBy(
		authControllerService: AuthControllerService,
		svgContext: SvgContext,
	): Observable<BusinessSectorPermission[] | null> {
		if (svgContext.state === ContextState.STAFF_CUSTOMER || svgContext.state === ContextState.CUSTOMER) {
			return authControllerService.getBusinessSectorsForCustomer({ portalUid: svgContext.customerInfo.id });
		} else {
			return of(null);
		}
	}

	fetchData(): Observable<BusinessSectorPermission[]> {
		return this.loadBusinessSectors(this.authControllerService, this.svgContextService);
	}

	hasFlexBoxAccount(): Observable<boolean> {
		return this.getFirst().pipe(
			map((businessSectorPermissions: BusinessSectorPermission[]) => this.hasFlexBoxBusinessSector(businessSectorPermissions)),
		);
	}

	private loadBusinessSectors(
		authControllerService: AuthControllerService,
		svgContextService: SvgContextService,
	): Observable<BusinessSectorPermission[]> {
		return svgContextService.getSvgContext().pipe(
			mergeMap((svgContext: SvgContext) => BusinessSectorsStoreService.loadBusinessSectorsBy(authControllerService, svgContext)),
			map((businessSectorPermissions: BusinessSectorPermission[]) => (businessSectorPermissions ? businessSectorPermissions : [])),
		);
	}

	private hasFlexBoxBusinessSector(businessSectorPermissions: BusinessSectorPermission[]): boolean {
		return businessSectorPermissions.some(
			(permission: BusinessSectorPermission) => permission.businessSector === UNIQUE_FLEXBOX_BUSINESS_SECTOR_NAME && !permission.readonly,
		);
	}
}
