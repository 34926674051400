import { Pipe, PipeTransform } from '@angular/core';

import { DocumentService } from '../services/document.service';

@Pipe({ name: 'zipDownloadLinkFromHash' })
export class ZipDownloadLinkFromHashPipe implements PipeTransform {
	constructor(private documentService: DocumentService) {}

	transform(downloadHash: string): string {
		return this.documentService.getZipDocumentUrlFromHash(downloadHash);
	}
}
