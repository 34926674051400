import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SvgSafetyContractControllerService, SvgSafetyContractDto } from '@mysvg/api/financial-guarantees';

import { DataWrapper } from '../../models/data-wrapper.model';
import { RepositoryStoreService } from '../repository-store.service';

@Injectable({ providedIn: 'root' })
export class SvgContractNumbersStoreService extends RepositoryStoreService<SvgSafetyContractDto[]> {
	constructor(private svgSafetyContractControllerService: SvgSafetyContractControllerService) {
		super();
	}

	fetchData(): Observable<SvgSafetyContractDto[]> {
		return this.svgSafetyContractControllerService.getAll();
	}

	findSvgContractNumberBySvgId(svgNr: number): Observable<DataWrapper<SvgSafetyContractDto[] | undefined>> {
		return this.getData().pipe(
			map((wrapper: DataWrapper<SvgSafetyContractDto[]>) => ({
				...wrapper,
				value: wrapper?.value?.filter((svgSafetyContract: SvgSafetyContractDto) => svgSafetyContract.svgNumber === svgNr),
			})),
		);
	}
}
