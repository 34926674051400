import { NgModule } from '@angular/core';
import { UpdateAppWorkerApplicationAlertComponent } from './update-app-worker-application-alert/update-app-worker-application-alert.component';
import { ClarityWrapperModule } from '@svg-frontends/theming';

@NgModule({
	declarations: [UpdateAppWorkerApplicationAlertComponent],
	imports: [ClarityWrapperModule],
	exports: [UpdateAppWorkerApplicationAlertComponent],
})
export class UpdateAppWorkerModule {}
