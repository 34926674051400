/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { cancelExtendedMonitoring } from '../fn/crefo-monitoring-controller/cancel-extended-monitoring';
import { CancelExtendedMonitoring$Params } from '../fn/crefo-monitoring-controller/cancel-extended-monitoring';
import { cancelStandardMonitoring } from '../fn/crefo-monitoring-controller/cancel-standard-monitoring';
import { CancelStandardMonitoring$Params } from '../fn/crefo-monitoring-controller/cancel-standard-monitoring';
import { CrefoCancelStandardMonitoringResponseDto } from '../models/crefo-cancel-standard-monitoring-response-dto';
import { CrefoChangeExtendedMonitoringResponseDto } from '../models/crefo-change-extended-monitoring-response-dto';
import { CrefoMonitoringStatusDto } from '../models/crefo-monitoring-status-dto';
import { getMonitoringStatus } from '../fn/crefo-monitoring-controller/get-monitoring-status';
import { GetMonitoringStatus$Params } from '../fn/crefo-monitoring-controller/get-monitoring-status';

@Injectable({ providedIn: 'root' })
export class CrefoMonitoringControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `cancelStandardMonitoring()` */
  static readonly CancelStandardMonitoringPath = '/crefo/monitoring/standard/cancel';

  /**
   * cancels standard-monitoring
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelStandardMonitoring()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelStandardMonitoring$Response(params: CancelStandardMonitoring$Params, context?: HttpContext): Observable<StrictHttpResponse<CrefoCancelStandardMonitoringResponseDto>> {
    return cancelStandardMonitoring(this.http, this.rootUrl, params, context);
  }

  /**
   * cancels standard-monitoring
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelStandardMonitoring$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelStandardMonitoring(params: CancelStandardMonitoring$Params, context?: HttpContext): Observable<CrefoCancelStandardMonitoringResponseDto> {
    return this.cancelStandardMonitoring$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrefoCancelStandardMonitoringResponseDto>): CrefoCancelStandardMonitoringResponseDto => r.body)
    );
  }

  /** Path part for operation `cancelExtendedMonitoring()` */
  static readonly CancelExtendedMonitoringPath = '/crefo/monitoring/extended/cancel';

  /**
   * cancel extended-monitoring
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelExtendedMonitoring()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelExtendedMonitoring$Response(params: CancelExtendedMonitoring$Params, context?: HttpContext): Observable<StrictHttpResponse<CrefoChangeExtendedMonitoringResponseDto>> {
    return cancelExtendedMonitoring(this.http, this.rootUrl, params, context);
  }

  /**
   * cancel extended-monitoring
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelExtendedMonitoring$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelExtendedMonitoring(params: CancelExtendedMonitoring$Params, context?: HttpContext): Observable<CrefoChangeExtendedMonitoringResponseDto> {
    return this.cancelExtendedMonitoring$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrefoChangeExtendedMonitoringResponseDto>): CrefoChangeExtendedMonitoringResponseDto => r.body)
    );
  }

  /** Path part for operation `getMonitoringStatus()` */
  static readonly GetMonitoringStatusPath = '/crefo/monitoring/status';

  /**
   * get monitoring status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMonitoringStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonitoringStatus$Response(params: GetMonitoringStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<CrefoMonitoringStatusDto>> {
    return getMonitoringStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * get monitoring status
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMonitoringStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonitoringStatus(params: GetMonitoringStatus$Params, context?: HttpContext): Observable<CrefoMonitoringStatusDto> {
    return this.getMonitoringStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrefoMonitoringStatusDto>): CrefoMonitoringStatusDto => r.body)
    );
  }

}
