import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { ClarityModule, ClrCommonStrings, ClrCommonStringsService } from '@clr/angular';

/**
 * Translations of englis defaults. See:
 * https://github.com/vmware-clarity/ng-clarity/blob/main/projects/angular/src/utils/i18n/common-strings.default.ts
 */
const germanLocale: ClrCommonStrings = {
	// Not used at the moment it seems
	comboboxDelete: 'Ausgewählte Option löschen',
	comboboxSearching: 'Suche nach Übereinstimmungen für "{INPUT}"',
	comboboxSelection: 'Auswahl',
	comboboxSelected: 'Ausgewählte',
	comboboxNoResults: 'Keine Ergebnisse',
	comboboxOpen: 'Optionen anzeigen',

	// placeholder for filter fields within tables
	filterItems: 'Elemente filtern',

	// Only used for `clr-password-container` which we do not use atm
	passwordHide: 'Passwort verbergen',
	passwordShow: 'Passwort anzeigen',

	// used on the navigation menu as aria-title for burger menu btn etc.
	responsiveNavToggleClose: 'Navigationsmenü schließen',
	responsiveNavToggleOpen: 'Navigationsmenü öffnen',
	responsiveNavOverflowOpen: 'Navigations-Überlaufmenü öffnen',
	responsiveNavOverflowClose: 'Navigations-Überlaufmenü schließen',

	// Can be used to show success and error messages in wizards
	wizardStepError: 'Beendet',
	wizardStepSuccess: 'Fehler',

	alertCloseButtonAriaLabel: 'Hinweis schließen', // Alert: aria label for closing alert
	allColumnsSelected: 'Alle Spalten ausgewählt', // Datagrid: screen reader only confirmation that all columns were selected
	close: 'Schließen', // Close button text
	collapse: 'Einklappen', // Expandable components: collapse caret
	current: 'Zur Auswahl', // Pagination: go to current
	currentPage: 'Aktuelle Seite', // Datagrid: pagination current page button text
	danger: 'Fehler', // Alert levels: danger
	datepickerCurrentDecade: 'Dieses Jahrzehnt', // The button that navigates the yearpicker to current decade
	datepickerCurrentMonth: 'Dieser Monat', // The button that navigates a daypicker to current month
	datepickerNextDecade: 'Nächstes Jahrzehnt', // The button that navigates a yearpicker to next decade
	datepickerNextMonth: 'Nächster Monat', // The button that navigates a daypicker to the next month
	datepickerPreviousDecade: 'Vorheriges Jahrzehnt', // The button that navigates a yearpicker to previous decade
	datepickerPreviousMonth: 'Vorheriger Monat', // The button that navigates daypicker to a monthpicker
	datepickerSelectMonthText: 'Monat auswählen, der aktuelle Monat ist {CALENDAR_MONTH}', // Populates aria-label and title for monthpicker button. Is concatenated with the (localized) value for calendarMonth as well as this value
	datepickerSelectYearText: 'Jahr auswählen, das aktuelle Jahr ist {CALENDAR_MONTH}', // Populates aria-label and title for yearpicker button. Is concatenated with the (localized) value for calendarYear as well as this value
	datepickerToggle: 'Datumsauswahl öffnen/schließen', // The open/close button for a datepicker
	detailExpandableAriaLabel: 'Zus. Zeileninhalt öffnen/schließen', // Datagrid: aria label for expandable row toggle button
	detailPaneEnd: 'Ende der Detailansicht', // Datagrid: detail pane end content for screen reader
	detailPaneStart: 'Start der Detailansicht', // Datagrid: detail pane start content for screen reader
	expand: 'Ausklappen', // Expandable components: expand caret
	firstPage: 'Erste Seite', // Datagrid: pagination first page button text
	hide: 'Ausblenden', // Hide button text
	info: 'Info', // Alert levels: info
	lastPage: 'Letzte Seite', // Datagrid: pagination last page button text
	loading: 'Es lädt', // Display loading text (Default: Loading)
	maxValue: 'Maximaler Wert', // Datagrid: maximum value (numeric filters)
	minValue: 'Minimaler Wert', // Datagrid: minimum value (numeric filters)
	modalContentEnd: 'Ende des Dialog-Inhalts', // Modal end of content
	modalContentStart: 'Anfang des Dialog-Inhalts', // Modal start of content
	more: 'Mehr', // Overflow menus: ellipsis button
	next: 'Weiter', // Pagination: next button
	nextPage: 'Nächste Seite', // Datagrid: pagination next page button text
	open: 'Öffnen', // Open button text
	pickColumns: 'Spalten auswählen', // Datagrid: show and hide columns icon alt text
	previous: 'Zurück', // Pagination: previous button
	previousPage: 'Vorherige Seite', // Datagrid: pagination previous page button text
	rowActions: 'Verfügbare Aktionen', // Datagrid: row actions icon alt text
	select: 'Auswählen', // Selectable components: checkbox or radio
	selectAll: 'Alles auswählen', // Selectable components: checkbox to select all
	show: 'Anzeigen', // Show button text
	showColumns: 'Spalten anzeigen', // Datagrid: show columns title
	showColumnsMenuDescription: 'Spaltenauswahl öffnen/schließen', // Datagrid: screen reader only description of the Show/Hide columns menu
	signpostClose: 'Details schließen', // Signpost Close Button (used inside signpost content components)
	signpostToggle: 'Details öffnen/schließen', // Applies the aria-label value to the signpost trigger.
	singleActionableAriaLabel: 'Einzelaktion', // Datagrid: aria label for row action header column
	singleSelectionAriaLabel: 'Einzelselektion', // Datagrid: aria label for header single selection header column
	sortColumn: 'Spalte sortieren', // Datagrid: sort columns title
	stackViewChanged: '', // Stack View: describes a particular stack block has changed
	success: 'Erfolg', // Alert levels: success
	timelineStepCurrent: 'Aktueller Schritt', // Used in the aria-label for the current step icon
	timelineStepError: 'Fehler', // Used in the aria-label for the error step icon
	timelineStepNotStarted: 'Nicht gestartet', // Used in the aria-label for the not started step icon
	timelineStepProcessing: 'In Verarbeitung', // Used in the aria-label for the processing step icon
	timelineStepSuccess: 'Abgeschlossen', // Used in the aria-label for the success step icon
	totalPages: 'Seitenanzahl', // Datagrid: pagination total pages button text
	verticalNavGroupToggle: 'Navigationsgruppe öffnen/schließen', // Applies the expanded/collapsed state to an aria-expanded attribute for screen readers whenever vertical nav group buttons are expanded/collapsed
	verticalNavToggle: 'Navigation öffnen/schließen', // Applies expanded/collapsed state to an aria-expanded attribute for screen readers when vertical nav button expands/collapses the entire menu
	warning: 'Warnung', // Alert levels: warning

	datepickerDialogLabel: 'Datum auswählen',
	datepickerSelectedLabel: '{FULL_DATE} - Ausgewählt',
	datepickerToggleChooseDateLabel: 'Datum auswählen',
	datepickerToggleChangeDateLabel: 'Datum ändern, {SELECTED_DATE}',
	selectedRows: 'Ausgewählte Zeilen',
};

@Injectable({ providedIn: 'root' })
export class CustomCommonStringsService {
	constructor(private clrCommonStringsService: ClrCommonStringsService) {}

	init(): void {
		this.clrCommonStringsService.localize(germanLocale);
	}
}

@NgModule({
	exports: [CommonModule, ClarityModule],
	imports: [CommonModule, ClarityModule],
})
export class ClarityWrapperModule {}
