/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getTask } from '../fn/task-controller/get-task';
import { GetTask$Params } from '../fn/task-controller/get-task';
import { ProcessActionResponseDtoObject } from '../models/process-action-response-dto-object';
import { updateTask } from '../fn/task-controller/update-task';
import { UpdateTask$Params } from '../fn/task-controller/update-task';

@Injectable({ providedIn: 'root' })
export class TaskControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTask()` */
  static readonly GetTaskPath = '/processes/tasks/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTask$Response(params: GetTask$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessActionResponseDtoObject>> {
    return getTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTask(params: GetTask$Params, context?: HttpContext): Observable<ProcessActionResponseDtoObject> {
    return this.getTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessActionResponseDtoObject>): ProcessActionResponseDtoObject => r.body)
    );
  }

  /** Path part for operation `updateTask()` */
  static readonly UpdateTaskPath = '/processes/tasks/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTask$Response(params: UpdateTask$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessActionResponseDtoObject>> {
    return updateTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTask(params: UpdateTask$Params, context?: HttpContext): Observable<ProcessActionResponseDtoObject> {
    return this.updateTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessActionResponseDtoObject>): ProcessActionResponseDtoObject => r.body)
    );
  }

}
