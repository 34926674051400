import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'arrayJoin' })
export class ArrayJoinPipe implements PipeTransform {
	private static mapItemOfArrayToString(item: any): string {
		return item.toString ? item.toString() : item;
	}

	private static mapObjectOfArrayToChild(item: any, objectKey?: string): any {
		item = item ? item : '';
		return !objectKey || !item ? item : item[objectKey];
	}

	transform(input: any[], char: string = '', objectKey?: string): string | null {
		if (!input || input.length === 0) {
			return null;
		} else {
			return input
				.map((item) => ArrayJoinPipe.mapObjectOfArrayToChild(item, objectKey))
				.map(ArrayJoinPipe.mapItemOfArrayToString)
				.join(char);
		}
	}
}
