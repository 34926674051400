/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { VehicleBoxValidation } from '../../models/vehicle-box-validation';

export interface DeleteBoxValidation$Params {

/**
 * id of vehicle
 */
  vehicleId: number;

/**
 * id of the validation object
 */
  validationId: number;

/**
 * the portalUid of the customer
 */
  customers: string;
}

export function deleteBoxValidation(http: HttpClient, rootUrl: string, params: DeleteBoxValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<VehicleBoxValidation>> {
  const rb = new RequestBuilder(rootUrl, deleteBoxValidation.PATH, 'delete');
  if (params) {
    rb.path('vehicleId', params.vehicleId, {});
    rb.path('validationId', params.validationId, {});
    rb.query('customers', params.customers, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<VehicleBoxValidation>;
    })
  );
}

deleteBoxValidation.PATH = '/vehicles/{vehicleId}/boxvalidations/{validationId}';
