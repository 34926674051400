/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { TelematicBoxDto } from '../models/telematic-box-dto';
import { TelematicOrderDto } from '../models/telematic-order-dto';
import { updateBoxTelematicStatus } from '../fn/public-event-controller/update-box-telematic-status';
import { UpdateBoxTelematicStatus$Params } from '../fn/public-event-controller/update-box-telematic-status';
import { updateOrderStatusByKMaster } from '../fn/public-event-controller/update-order-status-by-k-master';
import { UpdateOrderStatusByKMaster$Params } from '../fn/public-event-controller/update-order-status-by-k-master';

@Injectable({ providedIn: 'root' })
export class PublicEventControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateOrderStatusByKMaster()` */
  static readonly UpdateOrderStatusByKMasterPath = '/public/event/kmaster/order-status';

  /**
   * Update the orders status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrderStatusByKMaster()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrderStatusByKMaster$Response(params: UpdateOrderStatusByKMaster$Params, context?: HttpContext): Observable<StrictHttpResponse<TelematicOrderDto>> {
    return updateOrderStatusByKMaster(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the orders status
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOrderStatusByKMaster$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrderStatusByKMaster(params: UpdateOrderStatusByKMaster$Params, context?: HttpContext): Observable<TelematicOrderDto> {
    return this.updateOrderStatusByKMaster$Response(params, context).pipe(
      map((r: StrictHttpResponse<TelematicOrderDto>): TelematicOrderDto => r.body)
    );
  }

  /** Path part for operation `updateBoxTelematicStatus()` */
  static readonly UpdateBoxTelematicStatusPath = '/public/event/kmaster/box-services';

  /**
   * Update the status of the telematic of a box
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBoxTelematicStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBoxTelematicStatus$Response(params: UpdateBoxTelematicStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<TelematicBoxDto>> {
    return updateBoxTelematicStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the status of the telematic of a box
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBoxTelematicStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBoxTelematicStatus(params: UpdateBoxTelematicStatus$Params, context?: HttpContext): Observable<TelematicBoxDto> {
    return this.updateBoxTelematicStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<TelematicBoxDto>): TelematicBoxDto => r.body)
    );
  }

}
