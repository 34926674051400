/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getSvgForZipCode } from '../fn/zip-code-2-svg-controller/get-svg-for-zip-code';
import { GetSvgForZipCode$Params } from '../fn/zip-code-2-svg-controller/get-svg-for-zip-code';
import { setSvgForZipCode } from '../fn/zip-code-2-svg-controller/set-svg-for-zip-code';
import { SetSvgForZipCode$Params } from '../fn/zip-code-2-svg-controller/set-svg-for-zip-code';
import { ZipCode2SvgDto } from '../models/zip-code-2-svg-dto';

@Injectable({ providedIn: 'root' })
export class ZipCode2SvgControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `setSvgForZipCode()` */
  static readonly SetSvgForZipCodePath = '/zipCode2Svg';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setSvgForZipCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSvgForZipCode$Response(params: SetSvgForZipCode$Params, context?: HttpContext): Observable<StrictHttpResponse<ZipCode2SvgDto>> {
    return setSvgForZipCode(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setSvgForZipCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSvgForZipCode(params: SetSvgForZipCode$Params, context?: HttpContext): Observable<ZipCode2SvgDto> {
    return this.setSvgForZipCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZipCode2SvgDto>): ZipCode2SvgDto => r.body)
    );
  }

  /** Path part for operation `getSvgForZipCode()` */
  static readonly GetSvgForZipCodePath = '/zipCode2Svg/{zipCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSvgForZipCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgForZipCode$Response(params: GetSvgForZipCode$Params, context?: HttpContext): Observable<StrictHttpResponse<ZipCode2SvgDto>> {
    return getSvgForZipCode(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSvgForZipCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgForZipCode(params: GetSvgForZipCode$Params, context?: HttpContext): Observable<ZipCode2SvgDto> {
    return this.getSvgForZipCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZipCode2SvgDto>): ZipCode2SvgDto => r.body)
    );
  }

}
