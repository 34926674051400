/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllPayrollCycles } from '../fn/payroll-cycle-controller/get-all-payroll-cycles';
import { GetAllPayrollCycles$Params } from '../fn/payroll-cycle-controller/get-all-payroll-cycles';
import { ResponsePayrollCycleDto } from '../models/response-payroll-cycle-dto';

@Injectable({ providedIn: 'root' })
export class PayrollCycleControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllPayrollCycles()` */
  static readonly GetAllPayrollCyclesPath = '/payrollcycles';

  /**
   * Get a list of payroll cycles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPayrollCycles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPayrollCycles$Response(params?: GetAllPayrollCycles$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponsePayrollCycleDto>> {
    return getAllPayrollCycles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of payroll cycles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPayrollCycles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPayrollCycles(params?: GetAllPayrollCycles$Params, context?: HttpContext): Observable<ResponsePayrollCycleDto> {
    return this.getAllPayrollCycles$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponsePayrollCycleDto>): ResponsePayrollCycleDto => r.body)
    );
  }

}
