import { AfterViewInit, Directive, ElementRef, Host, HostListener, Input, Renderer2 } from '@angular/core';
import { ClrDatagrid, ClrDatagridStateInterface } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LoadData } from '../services/table-view-http.service';
import { TableViewOptionsService } from '../services/table-view-options.service';
import { TableViewService } from '../services/table-view.service';

const DATAGRID_COMPACT_CLASS_NAME = 'datagrid-compact';

@UntilDestroy()
@Directive({ selector: '[svgFrontendsTableView]' })
export class TableViewDirective<Data, FilterData> implements AfterViewInit {
	@Input() loadData: LoadData<Data, FilterData>;
	@Input() loadDataDebounceTime: number;

	constructor(
		@Host() private hostInput: ClrDatagrid,
		private elementRef: ElementRef,
		private renderer2: Renderer2,
		private tableViewOptionsService: TableViewOptionsService,
		private tableViewService: TableViewService<Data, FilterData>,
	) {}

	@HostListener('clrDgRefresh', ['$event']) clrDgRefresh(state: ClrDatagridStateInterface): void {
		this.tableViewService.refresh(state);
	}

	ngAfterViewInit(): void {
		this.tableViewService.initialize(this.hostInput, this.loadData, this.loadDataDebounceTime);

		this.tableViewOptionsService.compactState$.pipe(untilDestroyed(this)).subscribe((compactState: boolean) => {
			if (compactState) {
				this.renderer2.addClass(this.elementRef.nativeElement, DATAGRID_COMPACT_CLASS_NAME);
			} else {
				this.renderer2.removeClass(this.elementRef.nativeElement, DATAGRID_COMPACT_CLASS_NAME);
			}
		});
	}
}
