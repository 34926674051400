import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, HostListener, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FileInputComponent), multi: true }],
	selector: 'svg-frontends-file-input',
	styleUrls: ['./file-input.component.scss'],
	templateUrl: './file-input.component.html',
})
export class FileInputComponent implements ControlValueAccessor {
	files: File[] = [];
	onChange: (_: any) => void;
	onTouch: () => void;

	@Input() accept: string;
	@Input() multiple: boolean;

	@ViewChild('input') input: ElementRef;

	@HostListener('change', ['$event.target.files']) emitFiles(fileList: FileList): void {
		this.handleInput(fileList);
	}

	@HostListener('drop', ['$event']) ondrop(event: any): void {
		event.preventDefault();
		event.stopPropagation();
		this.onTouch(); // on touch is triggered by click and drop files - both make this input be touched
		this.handleInput(event.dataTransfer.files);
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouch = fn;
	}

	writeValue(): void {
		if (this.input) {
			this.input.nativeElement.value = '';
		}
	}

	// TODO review if function is needed
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setDisabledState(): void {}

	private handleInput(fileList: FileList): void {
		const newSelectedFiles = fileList ? Array.from(fileList) : [];
		this.onChange(newSelectedFiles);
	}
}
