/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseSimpleProcessDto } from '../../models/response-simple-process-dto';

export interface GetProcessesList$Params {
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
  id?: string;
  name?: string;
  category?: string;
  subcategory?: string;
  createdAt?: string;

/**
 * filter for the overall process status (RUNNING, CANCELLED or FINISHED)
 */
  processStatus?: string;
  svgId?: number;
  customerPortalUid?: string;
  type?: number;
}

export function getProcessesList(http: HttpClient, rootUrl: string, params?: GetProcessesList$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSimpleProcessDto>> {
  const rb = new RequestBuilder(rootUrl, getProcessesList.PATH, 'get');
  if (params) {
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
    rb.query('id', params.id, {});
    rb.query('name', params.name, {});
    rb.query('category', params.category, {});
    rb.query('subcategory', params.subcategory, {});
    rb.query('createdAt', params.createdAt, {});
    rb.query('processStatus', params.processStatus, {});
    rb.query('svgId', params.svgId, {});
    rb.query('customerPortalUid', params.customerPortalUid, {});
    rb.query('type', params.type, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseSimpleProcessDto>;
    })
  );
}

getProcessesList.PATH = '/processes/list';
