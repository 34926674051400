/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SvgResponseIncidentDto } from '../../models/svg-response-incident-dto';

export interface GetIncidents$Params {
  incidentType?: string;
  createdAt?: string;
  expiresAt?: string;
  boniIndex?: string;
  until?: string;
  riskMaximum?: string;
  riskAverage?: string;
  guarantee?: string;
  customerName?: string;
  rvNumber?: string;
  crefoNumber?: string;
  customerNumber?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getIncidents(http: HttpClient, rootUrl: string, params?: GetIncidents$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgResponseIncidentDto>> {
  const rb = new RequestBuilder(rootUrl, getIncidents.PATH, 'get');
  if (params) {
    rb.query('incidentType', params.incidentType, {});
    rb.query('createdAt', params.createdAt, {});
    rb.query('expiresAt', params.expiresAt, {});
    rb.query('boniIndex', params.boniIndex, {});
    rb.query('until', params.until, {});
    rb.query('riskMaximum', params.riskMaximum, {});
    rb.query('riskAverage', params.riskAverage, {});
    rb.query('guarantee', params.guarantee, {});
    rb.query('customerName', params.customerName, {});
    rb.query('rvNumber', params.rvNumber, {});
    rb.query('crefoNumber', params.crefoNumber, {});
    rb.query('customerNumber', params.customerNumber, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SvgResponseIncidentDto>;
    })
  );
}

getIncidents.PATH = '/incidents';
