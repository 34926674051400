import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { BankDetails, BankDetailsControllerService, ResponseBankDetails } from '@mysvg/api/account-master-data';
import { EditModeService } from '@svg-frontends/edit-mode';
import { SvgContextService } from '@svg-frontends/session';

import { RepositoryStoreService } from '../repository-store.service';

@Injectable({ providedIn: 'root' })
export class BankDetailsStoreService extends RepositoryStoreService<BankDetails[]> {
	constructor(
		private bankDetailsControllerService: BankDetailsControllerService,
		private editModeService: EditModeService,
		private svgContextService: SvgContextService,
	) {
		super();
	}

	fetchData(): Observable<BankDetails[]> {
		return this.editModeService.isStaffAtCustomerEditing$.pipe(
			mergeMap((editModeActive: boolean) => forkJoin([of(editModeActive), this.svgContextService.getFirstPortalUid()])),
			mergeMap(([editModeActive, portalUid]: [boolean, string]) =>
				editModeActive
					? this.bankDetailsControllerService.getBankDetailsFilterable({ portalUid, showAll: true })
					: this.bankDetailsControllerService.getBankDetailsFilterable({ portalUid, showAll: false }),
			),
			map((response: ResponseBankDetails) => response.data),
			map((bankDetailsList: BankDetails[]) =>
				bankDetailsList
					.filter((bankDetails: BankDetails) => bankDetails.mandateStatus)
					.sort((a: BankDetails, b: BankDetails) => a.mandateStatus - b.mandateStatus),
			),
		);
	}
}
