/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllBillingTypes } from '../fn/billing-type-controller/get-all-billing-types';
import { GetAllBillingTypes$Params } from '../fn/billing-type-controller/get-all-billing-types';
import { ResponseBillingTypeDto } from '../models/response-billing-type-dto';

@Injectable({ providedIn: 'root' })
export class BillingTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllBillingTypes()` */
  static readonly GetAllBillingTypesPath = '/billing/types';

  /**
   * List all available billing types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBillingTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBillingTypes$Response(params?: GetAllBillingTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBillingTypeDto>> {
    return getAllBillingTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * List all available billing types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllBillingTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBillingTypes(params?: GetAllBillingTypes$Params, context?: HttpContext): Observable<ResponseBillingTypeDto> {
    return this.getAllBillingTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseBillingTypeDto>): ResponseBillingTypeDto => r.body)
    );
  }

}
