import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditModeDirective } from './directives/edit-mode.directive';

@NgModule({
	declarations: [EditModeDirective],
	exports: [EditModeDirective],
	imports: [CommonModule],
})
export class EditModeModule {}
