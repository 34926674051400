/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountTypeBoView } from '../models/account-type-bo-view';
import { getAccountTypesForCustomer } from '../fn/account-type/get-account-types-for-customer';
import { GetAccountTypesForCustomer$Params } from '../fn/account-type/get-account-types-for-customer';
import { getUnused } from '../fn/account-type/get-unused';
import { GetUnused$Params } from '../fn/account-type/get-unused';


/**
 * Account type operations
 */
@Injectable({ providedIn: 'root' })
export class AccountTypeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getUnused()` */
  static readonly GetUnusedPath = '/unused-account-types';

  /**
   * Returns a list of all account types that are not used by customer yet
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnused()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnused$Response(params: GetUnused$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountTypeBoView>>> {
    return getUnused(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a list of all account types that are not used by customer yet
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnused$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnused(params: GetUnused$Params, context?: HttpContext): Observable<Array<AccountTypeBoView>> {
    return this.getUnused$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountTypeBoView>>): Array<AccountTypeBoView> => r.body)
    );
  }

  /** Path part for operation `getAccountTypesForCustomer()` */
  static readonly GetAccountTypesForCustomerPath = '/account-types';

  /**
   * Returns a list of all account types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountTypesForCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountTypesForCustomer$Response(params?: GetAccountTypesForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountTypeBoView>>> {
    return getAccountTypesForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a list of all account types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountTypesForCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountTypesForCustomer(params?: GetAccountTypesForCustomer$Params, context?: HttpContext): Observable<Array<AccountTypeBoView>> {
    return this.getAccountTypesForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountTypeBoView>>): Array<AccountTypeBoView> => r.body)
    );
  }

}
