/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponsePassageListDto } from '../../models/response-passage-list-dto';

export interface GetPassageListResponse$Params {

/**
 * customers portal uid
 */
  customers: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
  id?: string;
  rlid?: string;
  baseCustomerId?: string;
  obuID?: string;
  plate?: string;
  entryTimestamp?: string;
  exitTimestamp?: string;
  toPlace?: string;
  fromPlace?: string;
  toHWay?: string;
  fromHWay?: string;
  operatorTo?: string;
  operatorFrom?: string;
  km?: string;
  gross?: string;
  vatRate?: string;
  netto?: string;
  tax?: string;
  transaktionsNumber?: string;
  billNumber?: string;
  routeDescription?: string;
  stationNrFrom?: string;
  stationNrTill?: string;
  tollClass?: string;
  documentNumber?: string;
  roadType?: string;
  country?: string;
  currency?: string;
  euroClass?: string;
}

export function getPassageListResponse(http: HttpClient, rootUrl: string, params: GetPassageListResponse$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponsePassageListDto>> {
  const rb = new RequestBuilder(rootUrl, getPassageListResponse.PATH, 'get');
  if (params) {
    rb.query('customers', params.customers, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
    rb.query('id', params.id, {});
    rb.query('rlid', params.rlid, {});
    rb.query('baseCustomerId', params.baseCustomerId, {});
    rb.query('obuID', params.obuID, {});
    rb.query('plate', params.plate, {});
    rb.query('entryTimestamp', params.entryTimestamp, {});
    rb.query('exitTimestamp', params.exitTimestamp, {});
    rb.query('toPlace', params.toPlace, {});
    rb.query('fromPlace', params.fromPlace, {});
    rb.query('toHWay', params.toHWay, {});
    rb.query('fromHWay', params.fromHWay, {});
    rb.query('operatorTo', params.operatorTo, {});
    rb.query('operatorFrom', params.operatorFrom, {});
    rb.query('km', params.km, {});
    rb.query('gross', params.gross, {});
    rb.query('vatRate', params.vatRate, {});
    rb.query('netto', params.netto, {});
    rb.query('tax', params.tax, {});
    rb.query('transaktionsNumber', params.transaktionsNumber, {});
    rb.query('billNumber', params.billNumber, {});
    rb.query('routeDescription', params.routeDescription, {});
    rb.query('stationNrFrom', params.stationNrFrom, {});
    rb.query('stationNrTill', params.stationNrTill, {});
    rb.query('tollClass', params.tollClass, {});
    rb.query('documentNumber', params.documentNumber, {});
    rb.query('roadType', params.roadType, {});
    rb.query('country', params.country, {});
    rb.query('currency', params.currency, {});
    rb.query('euroClass', params.euroClass, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponsePassageListDto>;
    })
  );
}

getPassageListResponse.PATH = '/passageList';
