/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteTmpDocumentByFilenameHash } from '../fn/documents-controller/delete-tmp-document-by-filename-hash';
import { DeleteTmpDocumentByFilenameHash$Params } from '../fn/documents-controller/delete-tmp-document-by-filename-hash';
import { getDocumentByHash } from '../fn/documents-controller/get-document-by-hash';
import { GetDocumentByHash$Params } from '../fn/documents-controller/get-document-by-hash';
import { getDocuments } from '../fn/documents-controller/get-documents';
import { GetDocuments$Params } from '../fn/documents-controller/get-documents';
import { getListpreis } from '../fn/documents-controller/get-listpreis';
import { GetListpreis$Params } from '../fn/documents-controller/get-listpreis';
import { getTmpDocumentByFilenameHash } from '../fn/documents-controller/get-tmp-document-by-filename-hash';
import { GetTmpDocumentByFilenameHash$Params } from '../fn/documents-controller/get-tmp-document-by-filename-hash';
import { getZipDocumentByHash } from '../fn/documents-controller/get-zip-document-by-hash';
import { GetZipDocumentByHash$Params } from '../fn/documents-controller/get-zip-document-by-hash';
import { ResponseDocument } from '../models/response-document';
import { uploadTempFile } from '../fn/documents-controller/upload-temp-file';
import { UploadTempFile$Params } from '../fn/documents-controller/upload-temp-file';

@Injectable({ providedIn: 'root' })
export class DocumentsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `uploadTempFile()` */
  static readonly UploadTempFilePath = '/documents/temp';

  /**
   * uploading a document in a tmp context. returning the encrypted path for download issues.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadTempFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadTempFile$Response(params?: UploadTempFile$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return uploadTempFile(this.http, this.rootUrl, params, context);
  }

  /**
   * uploading a document in a tmp context. returning the encrypted path for download issues.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadTempFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadTempFile(params?: UploadTempFile$Params, context?: HttpContext): Observable<string> {
    return this.uploadTempFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getListpreis()` */
  static readonly GetListpreisPath = '/listpreis';

  /**
   * a list of documents for listprices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getListpreis()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListpreis$Response(params?: GetListpreis$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocument>> {
    return getListpreis(this.http, this.rootUrl, params, context);
  }

  /**
   * a list of documents for listprices
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getListpreis$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListpreis(params?: GetListpreis$Params, context?: HttpContext): Observable<ResponseDocument> {
    return this.getListpreis$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseDocument>): ResponseDocument => r.body)
    );
  }

  /** Path part for operation `getDocuments()` */
  static readonly GetDocumentsPath = '/documents';

  /**
   * a list of documents for the customers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments$Response(params: GetDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocument>> {
    return getDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * a list of documents for the customers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments(params: GetDocuments$Params, context?: HttpContext): Observable<ResponseDocument> {
    return this.getDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseDocument>): ResponseDocument => r.body)
    );
  }

  /** Path part for operation `getDocumentByHash()` */
  static readonly GetDocumentByHashPath = '/documents/{documentHash}';

  /**
   * starts the download stream
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentByHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentByHash$Response(params: GetDocumentByHash$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getDocumentByHash(this.http, this.rootUrl, params, context);
  }

  /**
   * starts the download stream
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentByHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentByHash(params: GetDocumentByHash$Params, context?: HttpContext): Observable<Blob> {
    return this.getDocumentByHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `getZipDocumentByHash()` */
  static readonly GetZipDocumentByHashPath = '/documents/zip/{documentHash}';

  /**
   * starts the download stream
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getZipDocumentByHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  getZipDocumentByHash$Response(params: GetZipDocumentByHash$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getZipDocumentByHash(this.http, this.rootUrl, params, context);
  }

  /**
   * starts the download stream
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getZipDocumentByHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getZipDocumentByHash(params: GetZipDocumentByHash$Params, context?: HttpContext): Observable<Blob> {
    return this.getZipDocumentByHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `getTmpDocumentByFilenameHash()` */
  static readonly GetTmpDocumentByFilenameHashPath = '/documents/temp/{documentHash}';

  /**
   * starts the download stream
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTmpDocumentByFilenameHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTmpDocumentByFilenameHash$Response(params: GetTmpDocumentByFilenameHash$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getTmpDocumentByFilenameHash(this.http, this.rootUrl, params, context);
  }

  /**
   * starts the download stream
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTmpDocumentByFilenameHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTmpDocumentByFilenameHash(params: GetTmpDocumentByFilenameHash$Params, context?: HttpContext): Observable<Blob> {
    return this.getTmpDocumentByFilenameHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `deleteTmpDocumentByFilenameHash()` */
  static readonly DeleteTmpDocumentByFilenameHashPath = '/documents/temp/{documentHash}';

  /**
   * deleting the tmp document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTmpDocumentByFilenameHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTmpDocumentByFilenameHash$Response(params: DeleteTmpDocumentByFilenameHash$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTmpDocumentByFilenameHash(this.http, this.rootUrl, params, context);
  }

  /**
   * deleting the tmp document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTmpDocumentByFilenameHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTmpDocumentByFilenameHash(params: DeleteTmpDocumentByFilenameHash$Params, context?: HttpContext): Observable<void> {
    return this.deleteTmpDocumentByFilenameHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
