import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

import { isDefined } from '../../utils/general.utils';

@Pipe({ name: 'timeElapsedFromNow' })
export class TimeElapsedFromNowPipe implements PipeTransform {
	/**
	 * [NOTE] dayjs can convert a unix timestamp or a iso date string so both number and string are excepted
	 */
	transform(value: string | number | null | undefined): string {
		return isDefined(value) ? dayjs(value).fromNow() : '';
	}
}
