<div class="content-container">
	<div class="content-area mac-content-area">
		<div class="XCcontainer">
			<div class="XCcard card mt-0 clickable" (click)="navigateToDefault()">
				<div class="card-block text-center">
					<h1 class="XChighlight XCheadline">404</h1>
					<h2 class="mb-2">
						<span>Die Seite&nbsp;</span>
						<span class="XChighlight" *ngIf="routerUrl">{{ routerUrl }}&nbsp;</span>
						<span>konnte nicht gefunden werden.</span>
					</h2>
					<cds-icon shape="arrow" direction="right" class="XCarrow"></cds-icon>
				</div>
			</div>
		</div>
	</div>
</div>
