/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SecurityExpertsCustomerInformationDto } from '../../models/security-experts-customer-information-dto';

export interface GetSecurityExpertCustomerInfos$Params {

/**
 * id of security expert
 */
  id: number;
}

export function getSecurityExpertCustomerInfos(http: HttpClient, rootUrl: string, params: GetSecurityExpertCustomerInfos$Params, context?: HttpContext): Observable<StrictHttpResponse<SecurityExpertsCustomerInformationDto>> {
  const rb = new RequestBuilder(rootUrl, getSecurityExpertCustomerInfos.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SecurityExpertsCustomerInformationDto>;
    })
  );
}

getSecurityExpertCustomerInfos.PATH = '/security-experts/{id}/customer-infos';
