/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createNewExternalDocument } from '../fn/external-documents-controller/create-new-external-document';
import { CreateNewExternalDocument$Params } from '../fn/external-documents-controller/create-new-external-document';
import { deleteExternalDocument } from '../fn/external-documents-controller/delete-external-document';
import { DeleteExternalDocument$Params } from '../fn/external-documents-controller/delete-external-document';
import { ExternalDocumentDto } from '../models/external-document-dto';
import { getExternalDocumentContentById } from '../fn/external-documents-controller/get-external-document-content-by-id';
import { GetExternalDocumentContentById$Params } from '../fn/external-documents-controller/get-external-document-content-by-id';
import { getExternalDocumentsForCustomer } from '../fn/external-documents-controller/get-external-documents-for-customer';
import { GetExternalDocumentsForCustomer$Params } from '../fn/external-documents-controller/get-external-documents-for-customer';
import { updateExternalDocument } from '../fn/external-documents-controller/update-external-document';
import { UpdateExternalDocument$Params } from '../fn/external-documents-controller/update-external-document';

@Injectable({ providedIn: 'root' })
export class ExternalDocumentsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getExternalDocumentContentById()` */
  static readonly GetExternalDocumentContentByIdPath = '/external-documents/{id}/customers/{puid}';

  /**
   * starts the download stream
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExternalDocumentContentById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalDocumentContentById$Response(params: GetExternalDocumentContentById$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getExternalDocumentContentById(this.http, this.rootUrl, params, context);
  }

  /**
   * starts the download stream
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExternalDocumentContentById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalDocumentContentById(params: GetExternalDocumentContentById$Params, context?: HttpContext): Observable<Blob> {
    return this.getExternalDocumentContentById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `updateExternalDocument()` */
  static readonly UpdateExternalDocumentPath = '/external-documents/{id}/customers/{puid}';

  /**
   * Update an existing document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateExternalDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateExternalDocument$Response(params: UpdateExternalDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalDocumentDto>> {
    return updateExternalDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Update an existing document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateExternalDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateExternalDocument(params: UpdateExternalDocument$Params, context?: HttpContext): Observable<ExternalDocumentDto> {
    return this.updateExternalDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExternalDocumentDto>): ExternalDocumentDto => r.body)
    );
  }

  /** Path part for operation `deleteExternalDocument()` */
  static readonly DeleteExternalDocumentPath = '/external-documents/{id}/customers/{puid}';

  /**
   * delete the customer document information in the database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteExternalDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExternalDocument$Response(params: DeleteExternalDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteExternalDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * delete the customer document information in the database
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteExternalDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExternalDocument(params: DeleteExternalDocument$Params, context?: HttpContext): Observable<void> {
    return this.deleteExternalDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getExternalDocumentsForCustomer()` */
  static readonly GetExternalDocumentsForCustomerPath = '/external-documents/customers/{puid}';

  /**
   * Retrieve all client documents Information of a client
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExternalDocumentsForCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalDocumentsForCustomer$Response(params: GetExternalDocumentsForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExternalDocumentDto>>> {
    return getExternalDocumentsForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve all client documents Information of a client
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExternalDocumentsForCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalDocumentsForCustomer(params: GetExternalDocumentsForCustomer$Params, context?: HttpContext): Observable<Array<ExternalDocumentDto>> {
    return this.getExternalDocumentsForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExternalDocumentDto>>): Array<ExternalDocumentDto> => r.body)
    );
  }

  /** Path part for operation `createNewExternalDocument()` */
  static readonly CreateNewExternalDocumentPath = '/external-documents/customers/{puid}';

  /**
   * create a new customer document and upload the file to webdav
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNewExternalDocument()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createNewExternalDocument$Response(params: CreateNewExternalDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalDocumentDto>> {
    return createNewExternalDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * create a new customer document and upload the file to webdav
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createNewExternalDocument$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createNewExternalDocument(params: CreateNewExternalDocument$Params, context?: HttpContext): Observable<ExternalDocumentDto> {
    return this.createNewExternalDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExternalDocumentDto>): ExternalDocumentDto => r.body)
    );
  }

}
