import { LabeledEnumModel } from '../models/enum/labeledEnum.model';

export const VehicleType: LabeledEnumModel = {
	LKW: { label: 'LKW' },
	Bus: { label: 'Bus' },
	PKW: { label: 'PKW' },
	Anhänger: { label: 'Anhänger' },
	Sattelzugmaschine: { label: 'Sattelzugmaschine' },
	Sattelzugauflieger: { label: 'Sattelzugauflieger' },
	Sonstige: { label: 'Sonstige' },
};
