import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
	MedicalCenterControllerService,
	MedicalCenterDto,
	MedicalCenterFormDto,
	ResponseMedicalCenterDto,
} from '@myasi/api/customers/addresses';

import { AsiApiBaseUrls } from '@mysvg/utils';
import { TypeAheadSearchParams, TypeAheadSearchServiceInterface } from '@svg-frontends/type-ahead/core';

import { API_BASE_URLS_TOKEN } from '@svg/environment';

export interface getAllMedicalCentersParams {
	limit?: number;
	offset?: number;
	query?: string;
	sortBy?: string;
	sortOrder?: 'ASC' | 'DESC';
}

@Injectable({ providedIn: 'root' })
export class MedicalCenterTypeaheadRepositoryService implements TypeAheadSearchServiceInterface<MedicalCenterDto> {
	constructor(
		@Inject(API_BASE_URLS_TOKEN) private baseApiUrls: AsiApiBaseUrls,
		private medicalCenterControllerService: MedicalCenterControllerService,
		private httpClient: HttpClient,
	) {}

	get(params: getAllMedicalCentersParams): Observable<ResponseMedicalCenterDto> {
		return this.medicalCenterControllerService.getMedicalCenters(params);
	}

	/**
	 * TypeAheadSearchServiceInterface<MedicalCenterDto>
	 */
	getByReference(reference: string): Observable<ResponseMedicalCenterDto> {
		const url = `${this.baseApiUrls.customers}/customers${reference}`;
		return this.httpClient.get<any>(url);
	}

	/**
	 * TypeAheadSearchServiceInterface<MedicalCenterDto>
	 */
	getWithParams(params: TypeAheadSearchParams): Observable<ResponseMedicalCenterDto> {
		return this.get({ query: params.search, limit: params.limit, offset: params.offset });
	}

	getAll(): Observable<MedicalCenterDto[]> {
		return this.medicalCenterControllerService.getAllMedicalCenters();
	}

	delete(id: number): Observable<void> {
		return this.medicalCenterControllerService.deleteMedicalCenter({ id });
	}

	add(medicalCenterFormDto: MedicalCenterFormDto): Observable<MedicalCenterDto> {
		return this.medicalCenterControllerService.createMedicalCenter({ body: medicalCenterFormDto });
	}

	update(id: number, medicalCenterFormDto: MedicalCenterFormDto): Observable<MedicalCenterDto> {
		return this.medicalCenterControllerService.updateMedicalCenter({ id, body: medicalCenterFormDto });
	}

	assign(customerId: number, centerId: number): Observable<void> {
		return this.medicalCenterControllerService.patchCustomersMedicalCenter({ customerId, centerId });
	}
}
