/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface GetListOfListOfOperationFigures$Params {
  portalUid?: string;
  categories?: Array<string>;
  subcategories?: Array<string>;
  topic: string;
  granularity?: 'DAILY' | 'WEEKLY' | 'SEMIMONTHLY' | 'MONTHLY' | 'YEARLY';
  operation?: 'SUM' | 'MEAN';
  start: string;
  end?: string;
  customerIds?: Array<number>;
  group?: number;
  groupBy?: Array<'CATEGORIES' | 'SUBCATEGORIES' | 'TIME' | 'SVG'>;
}

export function getListOfListOfOperationFigures(http: HttpClient, rootUrl: string, params: GetListOfListOfOperationFigures$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<{
'value'?: Array<{
'value'?: number;
'key'?: string;
}>;
'key'?: string;
}>>> {
  const rb = new RequestBuilder(rootUrl, getListOfListOfOperationFigures.PATH, 'get');
  if (params) {
    rb.query('portalUid', params.portalUid, {});
    rb.query('categories', params.categories, {});
    rb.query('subcategories', params.subcategories, {});
    rb.query('topic', params.topic, {});
    rb.query('granularity', params.granularity, {});
    rb.query('operation', params.operation, {});
    rb.query('start', params.start, {});
    rb.query('end', params.end, {});
    rb.query('customerIds', params.customerIds, {});
    rb.query('group', params.group, {});
    rb.query('groupBy', params.groupBy, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<{
      'value'?: Array<{
      'value'?: number;
      'key'?: string;
      }>;
      'key'?: string;
      }>>;
    })
  );
}

getListOfListOfOperationFigures.PATH = '/opfigureType3';
