/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { EntityDocumentsControllerService } from './services/entity-documents-controller.service';
import { ExternalDocumentsControllerService } from './services/external-documents-controller.service';
import { ProcessDocumentsControllerService } from './services/process-documents-controller.service';
import { DocumentsControllerService } from './services/documents-controller.service';
import { DiscountProgramControllerService } from './services/discount-program-controller.service';
import { MySvgControllerService } from './services/my-svg-controller.service';
import { PublicDocumentsControllerService } from './services/public-documents-controller.service';
import { ExternalDocumentTypeControllerService } from './services/external-document-type-controller.service';
import { CountryInvoiceCertificateControllerService } from './services/country-invoice-certificate-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    EntityDocumentsControllerService,
    ExternalDocumentsControllerService,
    ProcessDocumentsControllerService,
    DocumentsControllerService,
    DiscountProgramControllerService,
    MySvgControllerService,
    PublicDocumentsControllerService,
    ExternalDocumentTypeControllerService,
    CountryInvoiceCertificateControllerService,
    ApiConfiguration
  ],
})
export class DocumentModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<DocumentModule> {
    return {
      ngModule: DocumentModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: DocumentModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('DocumentModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
