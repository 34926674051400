import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterArray' })
export class FilterArrayPipe implements PipeTransform {
	transform<T>(items: T[], query?: string, key?: string): T[] {
		if (!query) {
			return items;
		} else {
			return items
				.filter((item: T) => typeof item === 'string' || typeof item[key] === 'string')
				.filter((item: T) => {
					const searchIn: string = (key ? (item[key] as any) : (item as any)).toLowerCase();
					return searchIn.indexOf(query.toLowerCase()) >= 0;
				});
		}
	}
}
