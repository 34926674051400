/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BankDetails } from '../models/bank-details';
import { createBankDetails } from '../fn/bank-details-controller/create-bank-details';
import { CreateBankDetails$Params } from '../fn/bank-details-controller/create-bank-details';
import { deactivateMandateStatus } from '../fn/bank-details-controller/deactivate-mandate-status';
import { DeactivateMandateStatus$Params } from '../fn/bank-details-controller/deactivate-mandate-status';
import { deleteBankDetails } from '../fn/bank-details-controller/delete-bank-details';
import { DeleteBankDetails$Params } from '../fn/bank-details-controller/delete-bank-details';
import { getBankDetails } from '../fn/bank-details-controller/get-bank-details';
import { GetBankDetails$Params } from '../fn/bank-details-controller/get-bank-details';
import { getBankDetailsFilterable } from '../fn/bank-details-controller/get-bank-details-filterable';
import { GetBankDetailsFilterable$Params } from '../fn/bank-details-controller/get-bank-details-filterable';
import { reactivateMandateStatus } from '../fn/bank-details-controller/reactivate-mandate-status';
import { ReactivateMandateStatus$Params } from '../fn/bank-details-controller/reactivate-mandate-status';
import { ResponseBankDetails } from '../models/response-bank-details';
import { updateBankDetails } from '../fn/bank-details-controller/update-bank-details';
import { UpdateBankDetails$Params } from '../fn/bank-details-controller/update-bank-details';
import { updateMandateStatus } from '../fn/bank-details-controller/update-mandate-status';
import { UpdateMandateStatus$Params } from '../fn/bank-details-controller/update-mandate-status';

@Injectable({ providedIn: 'root' })
export class BankDetailsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateBankDetails()` */
  static readonly UpdateBankDetailsPath = '/bankdetails/{id}';

  /**
   * Update one account master data entry
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBankDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBankDetails$Response(params: UpdateBankDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<BankDetails>> {
    return updateBankDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Update one account master data entry
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBankDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBankDetails(params: UpdateBankDetails$Params, context?: HttpContext): Observable<BankDetails> {
    return this.updateBankDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankDetails>): BankDetails => r.body)
    );
  }

  /** Path part for operation `deleteBankDetails()` */
  static readonly DeleteBankDetailsPath = '/bankdetails/{id}';

  /**
   * Delete a bank connection
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBankDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBankDetails$Response(params: DeleteBankDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteBankDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a bank connection
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBankDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBankDetails(params: DeleteBankDetails$Params, context?: HttpContext): Observable<void> {
    return this.deleteBankDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateMandateStatus()` */
  static readonly UpdateMandateStatusPath = '/bankdetails/{id}/mandatestatus';

  /**
   * Update the mandate status of an account master data entry
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMandateStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMandateStatus$Response(params: UpdateMandateStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<BankDetails>> {
    return updateMandateStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the mandate status of an account master data entry
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMandateStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMandateStatus(params: UpdateMandateStatus$Params, context?: HttpContext): Observable<BankDetails> {
    return this.updateMandateStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankDetails>): BankDetails => r.body)
    );
  }

  /** Path part for operation `reactivateMandateStatus()` */
  static readonly ReactivateMandateStatusPath = '/bankdetails/{id}/mandatestatus/reactivate';

  /**
   * Update the mandate status of an account master data entry from 3 to 2 when signature exists, in every other case to 1 (+ create process)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reactivateMandateStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  reactivateMandateStatus$Response(params: ReactivateMandateStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<BankDetails>> {
    return reactivateMandateStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the mandate status of an account master data entry from 3 to 2 when signature exists, in every other case to 1 (+ create process)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reactivateMandateStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reactivateMandateStatus(params: ReactivateMandateStatus$Params, context?: HttpContext): Observable<BankDetails> {
    return this.reactivateMandateStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankDetails>): BankDetails => r.body)
    );
  }

  /** Path part for operation `deactivateMandateStatus()` */
  static readonly DeactivateMandateStatusPath = '/bankdetails/{id}/mandatestatus/deactivate';

  /**
   * Update the mandate status of an account master data entry to 3
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateMandateStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateMandateStatus$Response(params: DeactivateMandateStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<BankDetails>> {
    return deactivateMandateStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the mandate status of an account master data entry to 3
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deactivateMandateStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateMandateStatus(params: DeactivateMandateStatus$Params, context?: HttpContext): Observable<BankDetails> {
    return this.deactivateMandateStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankDetails>): BankDetails => r.body)
    );
  }

  /** Path part for operation `getBankDetails()` */
  static readonly GetBankDetailsPath = '/bankdetails';

  /**
   * Get a list of account master data entries
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBankDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBankDetails$Response(params: GetBankDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBankDetails>> {
    return getBankDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of account master data entries
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBankDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBankDetails(params: GetBankDetails$Params, context?: HttpContext): Observable<ResponseBankDetails> {
    return this.getBankDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseBankDetails>): ResponseBankDetails => r.body)
    );
  }

  /** Path part for operation `createBankDetails()` */
  static readonly CreateBankDetailsPath = '/bankdetails';

  /**
   * Create a account master data entry
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBankDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBankDetails$Response(params: CreateBankDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<BankDetails>> {
    return createBankDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a account master data entry
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBankDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBankDetails(params: CreateBankDetails$Params, context?: HttpContext): Observable<BankDetails> {
    return this.createBankDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankDetails>): BankDetails => r.body)
    );
  }

  /** Path part for operation `getBankDetailsFilterable()` */
  static readonly GetBankDetailsFilterablePath = '/bankdetails/list';

  /**
   * Get a list of account master data entries
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBankDetailsFilterable()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBankDetailsFilterable$Response(params: GetBankDetailsFilterable$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBankDetails>> {
    return getBankDetailsFilterable(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of account master data entries
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBankDetailsFilterable$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBankDetailsFilterable(params: GetBankDetailsFilterable$Params, context?: HttpContext): Observable<ResponseBankDetails> {
    return this.getBankDetailsFilterable$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseBankDetails>): ResponseBankDetails => r.body)
    );
  }

}
