/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface GetUsersActivitiesForCustomerGroup$Params {

/**
 * the group id we want to get all activities for
 */
  customerId: string;

/**
 * hint to ignore the restriction to business sectors
 */
  ignoreBusinessSectors?: boolean;
}

export function getUsersActivitiesForCustomerGroup(http: HttpClient, rootUrl: string, params: GetUsersActivitiesForCustomerGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
  const rb = new RequestBuilder(rootUrl, getUsersActivitiesForCustomerGroup.PATH, 'get');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.query('ignoreBusinessSectors', params.ignoreBusinessSectors, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<string>>;
    })
  );
}

getUsersActivitiesForCustomerGroup.PATH = '/auth/customers/{customerId}/activities';
