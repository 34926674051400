/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ProcessActionResponseDtoActionForm } from '../../models/process-action-response-dto-action-form';

export interface CreateNewProcess$Params {
  processName: string;
  entryPoint: string;
  
    /**
     * The payload for the entryPoint
     */
    body: {
}
}

export function createNewProcess(http: HttpClient, rootUrl: string, params: CreateNewProcess$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessActionResponseDtoActionForm>> {
  const rb = new RequestBuilder(rootUrl, createNewProcess.PATH, 'post');
  if (params) {
    rb.path('processName', params.processName, {});
    rb.query('entryPoint', params.entryPoint, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ProcessActionResponseDtoActionForm>;
    })
  );
}

createNewProcess.PATH = '/processes/{processName}';
