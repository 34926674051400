import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bool' })
export class BoolPipe implements PipeTransform {
	transform(value: any): any {
		if (value === undefined) {
			return '';
		} else if (value) {
			return 'Ja';
		} else {
			return 'Nein';
		}
	}
}
