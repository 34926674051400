<div [ngClass]="{ 'clr-form-control-disabled': formControl.disabled }" class="clr-form-control mac-form-control">
	<div [ngClass]="{ 'clr-error': formControl.invalid && formControl.touched }" class="clr-control-container">
		<div class="mac-drop-container">
			<svg-frontends-file-input
				[accept]="allowedFileTypes | allowedFileTypes"
				[formControl]="formControl"
				[id]="id"
				[svgFrontendsDisableControl]="disableControl"
				[multiple]="multiple"
				class="mac-input mac-drop-container__input"
			></svg-frontends-file-input>

			<fa-icon [icon]="faCloudUploadAlt" class="mac-upload-icon"></fa-icon>
			<br />
			<br />
			<button class="btn btn-link mac-drop-container__button" type="button">{{ uploadText }}</button>
			<br />
		</div>

		<div class="clr-subtext-wrapper mac-subtext-wrapper">
			<cds-icon class="clr-validate-icon" shape="exclamation-circle"></cds-icon>
			<span *ngIf="helperText" class="clr-subtext">
				<svg-frontends-string-or-template-ref [value]="helperText"></svg-frontends-string-or-template-ref>
			</span>
			<span *ngIf="formControl.invalid && formControl.touched" class="clr-subtext">
				{{ formControl.errors | firstControlError | controlErrorMessage }}
			</span>
		</div>

		<div *ngIf="formControl?.value?.length > 0" class="XCselected-files-wrapper">
			<div *ngIf="formControl.value.length > 1" class="mac-resetbuttonwrapper">
				<button (click)="resetFiles()" class="btn btn-sm" type="button">
					<cds-icon shape="undo"></cds-icon>
					Zurücksetzen
				</button>
			</div>
			<svg-frontends-file-form-control-selected-file
				(remove)="removeFileFromList(file)"
				*ngFor="let file of formControl.value"
				[file]="file"
				[hasRemoveOption]="multiple && !replaceFiles"
			></svg-frontends-file-form-control-selected-file>

			<hr class="mt-1 mb-2" />
		</div>
	</div>
</div>
