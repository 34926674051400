import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';

import { BillableServiceControllerService } from '@myasi/api/billable-service/core';
import { RegulationDto } from '@myasi/api/customers/riskassessment';
import { PiaControllerService } from '@mysvg/api/pia';

import { HttpStatus, isDefined, parseFloatFromString } from '@mysvg/utils';
import { SvgErrorMessageFactoryService, TRANSLATED_ERROR_MESSAGE_KEY } from '@svg-frontends/error';
import { RegulationRepositoryService } from '@svg-frontends/store';

export class CstmAsyncValidators {
	static validCustomerIdValidator(piaControllerService: PiaControllerService, svgId: number): AsyncValidatorFn {
		return (customerNo: AbstractControl): Observable<ValidationErrors> => {
			const customerNumber = customerNo.value;
			const isInvalid = { cstmCustomerNumberInvalid: true };
			const hasConflict = { cstmCustomerNumberConflict: true };

			if (!customerNumber) {
				return of(isInvalid);
			} else {
				return CstmAsyncValidators.validateCustomerId(piaControllerService, customerNumber, svgId).pipe(
					map((result: HttpStatus) => {
						switch (result) {
							case HttpStatus.OK:
								return null;
							case HttpStatus.CONFLICT:
								return hasConflict;
							case HttpStatus.ERROR:
								return isInvalid;
							default:
								return isInvalid;
						}
					}),
				);
			}
		};
	}

	static validFuelCardIdValidator(piaControllerService: PiaControllerService, svgId: number): AsyncValidatorFn {
		return (customerNo: AbstractControl): Observable<ValidationErrors> => {
			const customerNumber = customerNo.value;
			const isInvalid = { cstmFuelcardIdInvalid: true };
			const hasConflict = { cstmFuelcardIdConflict: true };

			if (!customerNumber) {
				return of(isInvalid);
			} else {
				return CstmAsyncValidators.validateFuelCardNumber(piaControllerService, customerNo.value, svgId).pipe(
					map((result: HttpStatus) => {
						switch (result) {
							case HttpStatus.OK:
								return null;
							case HttpStatus.CONFLICT:
								return hasConflict;
							default:
								return isInvalid;
						}
					}),
				);
			}
		};
	}

	private static validateCustomerId(
		piaControllerService: PiaControllerService,
		customerNumber: string,
		svgId: number,
	): Observable<HttpStatus> {
		return piaControllerService.validateCustomerNumber({ customerNumber, svgId }).pipe(
			mapTo(HttpStatus.OK),
			catchError((error: HttpErrorResponse) => of(CstmAsyncValidators.mapErrorToHttpStatus(error))),
		);
	}

	private static validateFuelCardNumber(
		piaControllerService: PiaControllerService,
		fuelCardNumber: string,
		svgId: number,
	): Observable<HttpStatus> {
		return piaControllerService.validateFuelcardCustomerNumber({ fuelcardNumber: fuelCardNumber, svgId }).pipe(
			mapTo(HttpStatus.OK),
			catchError((error: HttpErrorResponse) => of(CstmAsyncValidators.mapErrorToHttpStatus(error))),
		);
	}

	/**
	 * add more status codes if necessary
	 */
	private static mapErrorToHttpStatus(error: HttpErrorResponse): HttpStatus {
		if (error.status === 409) {
			return HttpStatus.CONFLICT;
		} else {
			return HttpStatus.ERROR;
		}
	}

	static regulationsNotYetAdded(
		hazardFactorId: number,
		errorMessageFactoryService: SvgErrorMessageFactoryService,
		regulationRepositoryService: RegulationRepositoryService,
	): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			let value = Array.isArray(control.value) ? control.value : [control.value];

			if (isDefined(value) && value.length) {
				value = value.map((reg: RegulationDto) => reg.id);
			}

			if (!value || value.length === 0 || control.value === null) {
				return of(null);
			} else {
				return regulationRepositoryService.regulationsCanBeAdded(hazardFactorId, value).pipe(
					map(() => null),
					catchError((httpErrorResponse: HttpErrorResponse) => {
						const errorMessage: string[] = errorMessageFactoryService.getMessageForError(httpErrorResponse);
						return of({ [TRANSLATED_ERROR_MESSAGE_KEY]: errorMessage.join(', ') });
					}),
				);
			}
		};
	}

	static billableServiceTimeValid(
		billableServiceControllerService: BillableServiceControllerService,
		errorMessageFactoryService: SvgErrorMessageFactoryService,
		customerId: number,
		billableServiceId: number,
		controlNames: { [key: string]: string },
	): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			const formGroup = control.parent;
			const value = control.value;

			if (!value) {
				return of(null);
			} else if (
				customerId &&
				// check for validity isn't possible here, in editMode this formControl is disabled, but value is still set
				isDefined(formGroup.get(controlNames['billableServiceCategoryId']).value) &&
				formGroup.get(controlNames['securityExpertId']).valid &&
				formGroup.get(controlNames['date']).valid &&
				formGroup.get(controlNames['billableServiceType']).valid
			) {
				const formGroupRawValue = formGroup.getRawValue();
				const time: number = parseFloatFromString(value);

				return billableServiceControllerService
					.validateServiceTime({
						billableServiceId,
						time,
						serviceCategoryId: formGroupRawValue.billableServiceCategoryId.id,
						customerId,
						securityExpertId: formGroupRawValue.securityExpertId,
						date: new Date(formGroupRawValue.date).toISOString(),
						serviceTypeId: formGroupRawValue.billableServiceTypeId?.serviceTypeNumber,
					})
					.pipe(
						map(() => null),
						catchError((httpErrorResponse: HttpErrorResponse) => {
							const errorMessage: string[] = errorMessageFactoryService.getMessageForError(httpErrorResponse);
							return of({ [TRANSLATED_ERROR_MESSAGE_KEY]: errorMessage.join(', ') });
						}),
					);
			} else {
				return of({ cstmBillableServiceTimeParamsMissing: value });
			}
		};
	}
}
