import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, timer } from 'rxjs';
import { distinctUntilChanged, switchMap, takeWhile } from 'rxjs/operators';

@UntilDestroy()
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-error-message',
	styleUrls: ['./error-message.component.scss'],
	templateUrl: './error-message.component.html',
})
export class ErrorMessageComponent implements OnChanges {
	@Input() errorDisplayTime = 1500;
	@Input() isSelfResettable = false;
	@Input() messages: string[] = [];
	private messagesForSelfReset$: Subject<string[]>;

	ngOnChanges(): void {
		if (this.isSelfResettable && !this.messagesForSelfReset$) {
			this.messagesForSelfReset$ = new Subject<string[]>();
			this.messageForSelfResetListener();
		}

		if (this.isSelfResettable) {
			this.messagesForSelfReset$.next(this.messages);
		}
	}

	/**
	 * [NOTE] when component destroyed or `this.isSelfResettable` input is changed to false, then complete this subject
	 * 				`switchMap` is used to restart timer when error message is set to a different message, while reset timer is running
	 * 				when message is set to null, the component does not show anything
	 */
	private messageForSelfResetListener(): void {
		this.messagesForSelfReset$
			.pipe(
				takeWhile(() => this.isSelfResettable),
				distinctUntilChanged(),
				switchMap(() => timer(0, this.errorDisplayTime)),
				untilDestroyed(this),
			)
			.subscribe(() => (this.messages = []));
	}
}
