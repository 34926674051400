import { LabeledEnumModel } from '../../models/enum/labeledEnum.model';
import { Colors } from '../colors.enum';

// TODO - make up a color scheme, for categories / subcategories etc.

export const MsgSubcategoriesEnum: LabeledEnumModel = {
	// general categories
	CUSTOMER: { label: 'Kunde', color: Colors.CUSTOMER },
	REGISTRATION: { label: 'Registrierung', color: Colors.REGISTRATION },

	// providers as subcategories
	DKV: { label: 'DKV', color: Colors.DKV },
	ESSO: { label: 'Esso', color: Colors.ESSO },
	ESSODE: { label: 'Esso DE', color: Colors.ESSODE },
	EUROSHELL: { label: 'EuroShell', color: Colors.EUROSHELL },
	TOTAL: { label: 'Total', color: Colors.TOTAL },

	ASFINAGMAUT: { label: 'EuroMaut | Österreich', color: Colors.ASFINAGMAUT },
	BELGIENMAUT: { label: 'EuroMaut | Belgien', color: Colors.BELGIENMAUT },
	DMAUT: { label: 'Deutsche Maut', color: Colors.DMAUT },
	EETS: { label: 'FleXbox', color: Colors.EETS },
	EMAUT: { label: 'EuroMaut', color: Colors.EMAUT },
	EVIGNETTE: { label: 'E-Vignette', color: Colors.EVIGNETTE },
	SVGBOX: { label: 'SVG-Box', color: Colors.SVGBOX },
	TELEPASS: { label: 'Telepass', color: Colors.TELEPASS },
	TISPL: { label: 'Tispl', color: Colors.TISPL },
	VIALTIS: { label: 'Vialtis', color: Colors.VIALTIS },

	ASI: { label: 'Arbeitssicherheit', color: Colors.ASI },
	ASSURANCE: { label: 'Versicherung', color: Colors.ASSURANCE },
	BGHW: { label: 'BGHW', color: Colors.BGHW },
	DOCUMENTS: { label: 'Dokumente', color: Colors.DOCUMENTS },
	DRIVERDB: { label: 'Fahrer DB', color: Colors.DRIVERDB },
	PRIVACY: { label: 'Datenschutz', color: Colors.PRIVACY },
	SUBSIDIES: { label: 'Fördermittel', color: Colors.SUBSIDIES },

	ACCOUNT: { label: 'Konto', color: Colors.ACCOUNT },
	CONDITIONS: { label: 'Konditionen', color: Colors.CONDITIONS },
	CONNECT_BOXES_TO_VEHICLES: { label: 'Fahrzeugdeklaration', color: Colors.CONNECT_BOXES_TO_VEHICLES },
	CREDIT: { label: 'Sicherheiten', color: Colors.CREDIT },
	CUST_DOWNLOAD_EETS_VEHICLE_DOCUMENT: { label: 'Dokument herunterladen', color: Colors.CUST_DOWNLOAD_EETS_VEHICLE_DOCUMENT },
	DHL_ADDRESS: { label: 'DHL Adresse', color: Colors.DHL_ADDRESS },
	EMP_UPLOAD_EETS_VEHICLE_DOCUMENT: { label: 'Dokument hochladen', color: Colors.EMP_UPLOAD_EETS_VEHICLE_DOCUMENT },
	FINALIZE_SEPA_MANDAT: { label: 'Bestätigung', color: Colors.FINALIZE_SEPA_MANDAT },
	UPDATE_E_INVOICING: { label: 'Zahlungsmodalitäten ändern', color: Colors.E_INVOICING },
	UPDATE_SEPA_MANDAT: { label: 'SEPA Mandat', color: Colors.UPDATE_SEPA_MANDAT },
	UPLOAD_E_INVOICING_DOCUMENT: { label: 'Dokument hochladen', color: Colors.E_INVOICING },
	UPLOAD_INQUIRY: { label: 'FleXbox Bestellformular', color: Colors.UPLOAD_INQUIRY },
	UPLOAD_SEPA_MANDAT: { label: 'Antrag hochladen', color: Colors.UPLOAD_SEPA_MANDAT },
	VEHICLE: { label: 'Fahrzeug', color: Colors.VEHICLE },

	RV: { label: 'R+V', color: Colors.RV },
	NOMINATE: { label: 'Benannter Bereich', color: Colors.NOMINATE },

	VALIDATE: { label: 'Validierung', color: Colors.VALIDATE },
	COMPLETE_VEHICLE_DATA: { label: 'Validierung', color: Colors.VALIDATE },
};
