import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { SvgContextService } from '../services/svg-context.service';

@UntilDestroy()
@Directive({ selector: '[svgFrontendsNoActivity]' })
export class NoActivityDirective implements OnChanges {
	private activity$ = new Subject<string>();

	@Input() svgFrontendsNoActivity: string;

	constructor(
		private svgContextService: SvgContextService,
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef,
	) {
		this.init();
	}

	ngOnChanges(): void {
		this.activity$.next(this.svgFrontendsNoActivity);
	}

	init(): void {
		this.activity$
			.pipe(
				mergeMap((activity: string) => (activity ? this.hasNoActivity(activity) : of(true))),
				// no changes if `isAccessGranted` === `isVisible`
				filter((isAccessGranted: boolean) => isAccessGranted !== this.viewContainerRef.length > 0),
				untilDestroyed(this),
			)
			.subscribe((isAccessGranted: boolean) => this.setVisibility(isAccessGranted));
	}

	private setVisibility(isVisible: boolean): void {
		if (isVisible) {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainerRef.clear();
		}
	}

	private hasNoActivity(activity: string): Observable<boolean> {
		return this.svgContextService.hasActivity(activity).pipe(map((hasActivity: boolean) => !hasActivity));
	}
}
