/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getVignettePriceLists } from '../fn/price-list-controller/get-vignette-price-lists';
import { GetVignettePriceLists$Params } from '../fn/price-list-controller/get-vignette-price-lists';
import { VignettePriceList } from '../models/vignette-price-list';

@Injectable({ providedIn: 'root' })
export class PriceListControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getVignettePriceLists()` */
  static readonly GetVignettePriceListsPath = '/price-list';

  /**
   * Get all vignette types for showing all of them for prize information
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVignettePriceLists()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVignettePriceLists$Response(params?: GetVignettePriceLists$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VignettePriceList>>> {
    return getVignettePriceLists(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all vignette types for showing all of them for prize information
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVignettePriceLists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVignettePriceLists(params?: GetVignettePriceLists$Params, context?: HttpContext): Observable<Array<VignettePriceList>> {
    return this.getVignettePriceLists$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VignettePriceList>>): Array<VignettePriceList> => r.body)
    );
  }

}
