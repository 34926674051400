/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface GetNumericOperationFigure$Params {
  portalUid?: string;
  category?: string;
  subcategory?: string;
  topic: string;
  granularity?: 'DAILY' | 'WEEKLY' | 'SEMIMONTHLY' | 'MONTHLY' | 'YEARLY';
  operation?: 'SUM' | 'MEAN';
  start: string;
  end?: string;
  customerIds?: Array<number>;
  group?: number;
}

export function getNumericOperationFigure(http: HttpClient, rootUrl: string, params: GetNumericOperationFigure$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
  const rb = new RequestBuilder(rootUrl, getNumericOperationFigure.PATH, 'get');
  if (params) {
    rb.query('portalUid', params.portalUid, {});
    rb.query('category', params.category, {});
    rb.query('subcategory', params.subcategory, {});
    rb.query('topic', params.topic, {});
    rb.query('granularity', params.granularity, {});
    rb.query('operation', params.operation, {});
    rb.query('start', params.start, {});
    rb.query('end', params.end, {});
    rb.query('customerIds', params.customerIds, {});
    rb.query('group', params.group, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
    })
  );
}

getNumericOperationFigure.PATH = '/opfigureType1';
