/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MailParamsEetsOrderOfferTemplateData } from '../../models/mail-params-eets-order-offer-template-data';
import { ResponseObject } from '../../models/response-object';

export interface SendEetsOrderOfferMail$Params {
      body: MailParamsEetsOrderOfferTemplateData
}

export function sendEetsOrderOfferMail(http: HttpClient, rootUrl: string, params: SendEetsOrderOfferMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
  const rb = new RequestBuilder(rootUrl, sendEetsOrderOfferMail.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseObject>;
    })
  );
}

sendEetsOrderOfferMail.PATH = '/sendMail/eets-order-offer';
