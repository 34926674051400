import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from '@nsalaun/ng-logger';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import jwt_decode from 'jwt-decode';
import { Observable, Subject, from, of, throwError } from 'rxjs';
import { catchError, filter, mapTo } from 'rxjs/operators';

import { UrlParamsService } from '@mysvg/utils';
import { StorageService } from '@svg-frontends/storage';
import { DefaultUserTokenClaims } from '../../context/models/user-token-claims.model';

import { ACCESS_TOKEN_STORAGE_KEY, DEFAULT_DISCOVERY_DOCUMENT, OAUTH_EVENTS } from '../configs/config';
import { DiscoveryDocument } from '../models/discovery-document.model';

const CLIENT_IDP_PARAM_KEY = 'idp';

export const DISCOVERY_DOCUMENT: InjectionToken<DiscoveryDocument> = new InjectionToken<DiscoveryDocument>('mac_discovery_document');

@Injectable({ providedIn: 'root' })
export class OauthWrapperService<U extends DefaultUserTokenClaims> {
	private readonly discoveryDocument: DiscoveryDocument;

	private isDoneLoadingSubject$ = new Subject<boolean>();
	public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

	constructor(
		@Inject(DISCOVERY_DOCUMENT) discoveryDocument: DiscoveryDocument,
		private oauthService: OAuthService,
		private router: Router,
		private storageService: StorageService,
		private urlParamsService: UrlParamsService,
		private logger: Logger,
	) {
		// App must provide `issuer` and `clientId`
		this.discoveryDocument = { ...DEFAULT_DISCOVERY_DOCUMENT, ...discoveryDocument };
	}

	/**
	 * [NOTE] this throws an error if login did not work
	 * [CAUTION] use this only from 'ContextService'
	 */
	init(): void {
		this.configureOAuthLib();
		this.oauthService
			.loadDiscoveryDocumentAndTryLogin()
			.then(() => {
				this.isDoneLoadingSubject$.next(true);

				// Check for the strings 'undefined' and 'null' just to be sure. Our current
				// login(...) should never have this, but in case someone ever calls
				// initImplicitFlow(undefined | null) this could happen.
				if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
					let stateUrl = this.oauthService.state;
					if (!stateUrl.startsWith('/')) {
						stateUrl = decodeURIComponent(stateUrl);
					}
					this.router.navigateByUrl(stateUrl).catch((error) => {
						this.logger.error('Navigation failed', error);
					});
				}
			})
			.catch(() => this.isDoneLoadingSubject$.next(false));
	}

	/**
	 * [CAUTION] use this only in `InitialAuthenticationGuard`
	 */
	initImplicitFlow(targetUrl: string): void {
		this.oauthService.initImplicitFlow(targetUrl);
	}

	/**
	 * [CAUTION] use this only from 'ContextService'
	 */
	logout(noRedirect: boolean = false): void {
		this.oauthService.logOut(noRedirect);
	}

	/**
	 * [CAUTION] only use this in `UnauthorizedInterceptorService`
	 */
	hasValidAccessToken(): boolean {
		return this.oauthService.hasValidAccessToken();
	}

	/**
	 * [CAUTION] use this only from 'ContextService'
	 */
	getAccessToken(): string {
		return this.oauthService.getAccessToken();
	}

	/**
	 * returns decoded token if valid token found, or throws error
	 *
	 * [CAUTION] use this only from 'ContextService'
	 */
	getDecodedToken(): Observable<U> {
		if (this.hasValidAccessToken()) {
			const token = this.getAccessToken();
			const decodedToken: U = jwt_decode<U>(token);
			return of(decodedToken);
		} else {
			return throwError(new Error('oauth_flow_invalid_token'));
		}
	}

	/**
	 * [CAUTION] use this only from 'ContextService'
	 */
	overwriteValidateAndDecode(token: string): Observable<U> {
		this.storageService.set(ACCESS_TOKEN_STORAGE_KEY, token);
		return this.getDecodedToken();
	}

	/**
	 * angular-oauth2-oidc lib has an option to do this automatically, which works with angular routing set to `urlUpdateStrategy: 'eager'`
	 * we do it manually because of optional url parameter `context` (open customer in new tab)
	 *
	 * TODO - remove this and use auto option by lib, when context is no longer url parameter append with `?`
	 *
	 * [CAUTION] use this only from 'ContextService'
	 */
	clearHashAfterLogin(): void {
		this.router.navigateByUrl(location.pathname, { replaceUrl: true }).catch((error) => {
			this.logger.error('Navigation failed', error);
		});
	}

	getSilentRefreshEvents(): Observable<OAuthEvent> {
		return this.oauthService.events.pipe(
			filter(
				(event: OAuthEvent) =>
					event.type === OAUTH_EVENTS.SILENT_REFRESH.ERROR ||
					event.type === OAUTH_EVENTS.SILENT_REFRESH.REFRESHED ||
					event.type === OAUTH_EVENTS.SILENT_REFRESH.TIMEOUT,
			),
		);
	}

	setupAutomaticSilentRefresh(): void {
		this.oauthService.setupAutomaticSilentRefresh();
	}

	manualSilentRefresh(): Observable<boolean> {
		return from(this.oauthService.silentRefresh()).pipe(
			catchError(() => of(false)),
			mapTo(true),
		);
	}

	private configureOAuthLib(): void {
		const oAuthLibConfig = this.updateStaticConfigByUrlParam();
		this.oauthService.configure(oAuthLibConfig);
		this.oauthService.tokenValidationHandler = new JwksValidationHandler();
	}

	/**
	 * [NOTE] idp single sing on flow: idp provider redirects to application with client param (idp id), forward ipd id to keycloak (via lib)
	 *
	 * [EXAMPLE] wedolo links to `mysvg.de#ipd=wedolo`, mysvg communicates via oauthlib with keycloak and forwards wedolo to keycloak
	 * 			keycloak does not show login page but redirect to wedolo, wedolo has token and directly redirects to keycloak, keycloak logs
	 * 			user in and redirects to mysvg.de
	 */
	private updateStaticConfigByUrlParam(): any {
		const idpParam = this.urlParamsService.getClientParam(CLIENT_IDP_PARAM_KEY);
		return !idpParam ? this.discoveryDocument : { ...this.discoveryDocument, customQueryParams: { kc_idp_hint: idpParam } };
	}
}
