/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActivityDto } from '../models/activity-dto';
import { ActivityRelation } from '../models/activity-relation';
import { createActivities } from '../fn/activities-controller/create-activities';
import { CreateActivities$Params } from '../fn/activities-controller/create-activities';
import { createActivity } from '../fn/activities-controller/create-activity';
import { CreateActivity$Params } from '../fn/activities-controller/create-activity';
import { deleteActivities } from '../fn/activities-controller/delete-activities';
import { DeleteActivities$Params } from '../fn/activities-controller/delete-activities';
import { deleteActivity } from '../fn/activities-controller/delete-activity';
import { DeleteActivity$Params } from '../fn/activities-controller/delete-activity';
import { getActivities } from '../fn/activities-controller/get-activities';
import { GetActivities$Params } from '../fn/activities-controller/get-activities';
import { getActivities1 } from '../fn/activities-controller/get-activities-1';
import { GetActivities1$Params } from '../fn/activities-controller/get-activities-1';
import { getActivity } from '../fn/activities-controller/get-activity';
import { GetActivity$Params } from '../fn/activities-controller/get-activity';
import { getActivityRelation } from '../fn/activities-controller/get-activity-relation';
import { GetActivityRelation$Params } from '../fn/activities-controller/get-activity-relation';
import { ResponseActivityDto } from '../models/response-activity-dto';
import { updateActivityDescription } from '../fn/activities-controller/update-activity-description';
import { UpdateActivityDescription$Params } from '../fn/activities-controller/update-activity-description';
import { updateActivityRelation } from '../fn/activities-controller/update-activity-relation';
import { UpdateActivityRelation$Params } from '../fn/activities-controller/update-activity-relation';
import { updateActivityRelationWithRoles } from '../fn/activities-controller/update-activity-relation-with-roles';
import { UpdateActivityRelationWithRoles$Params } from '../fn/activities-controller/update-activity-relation-with-roles';

@Injectable({ providedIn: 'root' })
export class ActivitiesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getActivityRelation()` */
  static readonly GetActivityRelationPath = '/activities/{id}/relations';

  /**
   * getting a list objects which are related to the activity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivityRelation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivityRelation$Response(params: GetActivityRelation$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityRelation>> {
    return getActivityRelation(this.http, this.rootUrl, params, context);
  }

  /**
   * getting a list objects which are related to the activity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActivityRelation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivityRelation(params: GetActivityRelation$Params, context?: HttpContext): Observable<ActivityRelation> {
    return this.getActivityRelation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityRelation>): ActivityRelation => r.body)
    );
  }

  /** Path part for operation `updateActivityRelation()` */
  static readonly UpdateActivityRelationPath = '/activities/{id}/relations';

  /**
   * set a list of related objects to this activity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateActivityRelation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActivityRelation$Response(params: UpdateActivityRelation$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityRelation>> {
    return updateActivityRelation(this.http, this.rootUrl, params, context);
  }

  /**
   * set a list of related objects to this activity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateActivityRelation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActivityRelation(params: UpdateActivityRelation$Params, context?: HttpContext): Observable<ActivityRelation> {
    return this.updateActivityRelation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityRelation>): ActivityRelation => r.body)
    );
  }

  /** Path part for operation `updateActivityRelationWithRoles()` */
  static readonly UpdateActivityRelationWithRolesPath = '/activities/{id}/activity-roles';

  /**
   * updating activity relation with roles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateActivityRelationWithRoles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActivityRelationWithRoles$Response(params: UpdateActivityRelationWithRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityRelation>> {
    return updateActivityRelationWithRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * updating activity relation with roles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateActivityRelationWithRoles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActivityRelationWithRoles(params: UpdateActivityRelationWithRoles$Params, context?: HttpContext): Observable<ActivityRelation> {
    return this.updateActivityRelationWithRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityRelation>): ActivityRelation => r.body)
    );
  }

  /** Path part for operation `getActivities()` */
  static readonly GetActivitiesPath = '/activities';

  /**
   * searches for activities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivities$Response(params?: GetActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseActivityDto>> {
    return getActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * searches for activities
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivities(params?: GetActivities$Params, context?: HttpContext): Observable<ResponseActivityDto> {
    return this.getActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseActivityDto>): ResponseActivityDto => r.body)
    );
  }

  /** Path part for operation `createActivity()` */
  static readonly CreateActivityPath = '/activities';

  /**
   * Creates a new Activity.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createActivity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createActivity$Response(params: CreateActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityDto>> {
    return createActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a new Activity.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createActivity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createActivity(params: CreateActivity$Params, context?: HttpContext): Observable<ActivityDto> {
    return this.createActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDto>): ActivityDto => r.body)
    );
  }

  /** Path part for operation `getActivities1()` */
  static readonly GetActivities1Path = '/activities/activities-list';

  /**
   * get all activities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivities1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivities1$Response(params?: GetActivities1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActivityDto>>> {
    return getActivities1(this.http, this.rootUrl, params, context);
  }

  /**
   * get all activities
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActivities1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivities1(params?: GetActivities1$Params, context?: HttpContext): Observable<Array<ActivityDto>> {
    return this.getActivities1$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityDto>>): Array<ActivityDto> => r.body)
    );
  }

  /** Path part for operation `createActivities()` */
  static readonly CreateActivitiesPath = '/activities/activities-list';

  /**
   * Creates a List new Activities.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createActivities()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createActivities$Response(params: CreateActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActivityDto>>> {
    return createActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a List new Activities.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createActivities$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createActivities(params: CreateActivities$Params, context?: HttpContext): Observable<Array<ActivityDto>> {
    return this.createActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityDto>>): Array<ActivityDto> => r.body)
    );
  }

  /** Path part for operation `deleteActivities()` */
  static readonly DeleteActivitiesPath = '/activities/activities-list';

  /**
   * Delete a list of activities by ids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteActivities()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteActivities$Response(params: DeleteActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a list of activities by ids
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteActivities$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteActivities(params: DeleteActivities$Params, context?: HttpContext): Observable<void> {
    return this.deleteActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getActivity()` */
  static readonly GetActivityPath = '/activities/{id}';

  /**
   * getting one activity identified by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivity$Response(params: GetActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityDto>> {
    return getActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * getting one activity identified by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActivity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivity(params: GetActivity$Params, context?: HttpContext): Observable<ActivityDto> {
    return this.getActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDto>): ActivityDto => r.body)
    );
  }

  /** Path part for operation `deleteActivity()` */
  static readonly DeleteActivityPath = '/activities/{id}';

  /**
   * Delete an activity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteActivity()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteActivity$Response(params: DeleteActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete an activity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteActivity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteActivity(params: DeleteActivity$Params, context?: HttpContext): Observable<void> {
    return this.deleteActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateActivityDescription()` */
  static readonly UpdateActivityDescriptionPath = '/activities/{id}';

  /**
   * updating the description activity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateActivityDescription()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActivityDescription$Response(params: UpdateActivityDescription$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityDto>> {
    return updateActivityDescription(this.http, this.rootUrl, params, context);
  }

  /**
   * updating the description activity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateActivityDescription$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActivityDescription(params: UpdateActivityDescription$Params, context?: HttpContext): Observable<ActivityDto> {
    return this.updateActivityDescription$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDto>): ActivityDto => r.body)
    );
  }

}
