import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'unique' })
export class UniquePipe implements PipeTransform {
	private static isUndefined(value: any): boolean {
		return typeof value === 'undefined';
	}

	private static isObject(value: any): boolean {
		return value !== null && typeof value === 'object';
	}

	private static customFilter(e: any, propertyName: string | undefined, uniques: boolean[]): boolean {
		let value = UniquePipe.extractDeepPropertyByMapKey(e, propertyName);
		value = UniquePipe.isObject(value) ? JSON.stringify(value) : value;

		if (UniquePipe.isUndefined(value) || uniques[value]) {
			return false;
		}

		uniques[value] = true;

		return true;
	}

	private static extractDeepPropertyByMapKey(obj: any, map: string): any {
		const keys = map.split('.');
		const head = keys.shift();

		return keys.reduce(
			(prop: any, key: string) => (!UniquePipe.isUndefined(prop) && !UniquePipe.isUndefined(prop[key]) ? prop[key] : undefined),
			obj[head || ''],
		);
	}

	transform(input: any[], args?: string | undefined): any[];
	transform<T>(input: T, args?: string | undefined): T;

	transform(input: any, propertyName?: string | undefined): any {
		const uniques: boolean[] = [];

		if (Array.isArray(input)) {
			return UniquePipe.isUndefined(propertyName)
				? input.filter((e, index: number) => input.indexOf(e) === index)
				: input.filter((e) => UniquePipe.customFilter(e, propertyName, uniques));
		} else {
			return input;
		}
	}
}
