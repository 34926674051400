/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TcRegistrationDto } from '../../models/tc-registration-dto';
import { TcRegistrationTcPropertiesForm } from '../../models/tc-registration-tc-properties-form';

export interface UpdateTcProperties$Params {

/**
 * UUID to identify the customer
 */
  customerId: string;

/**
 * UUID to identify the svg (tenancy id)
 */
  svgId: string;

/**
 * current numbers: 16 digits with check digit, prefix 7056 (containing the 7-digit customer number); legacy numbers consist of 10 digits (prefix + customer number) and are predictable; identifies the TCRegistration
 */
  tcPaymentNumber: string;
      body: TcRegistrationTcPropertiesForm
}

export function updateTcProperties(http: HttpClient, rootUrl: string, params: UpdateTcProperties$Params, context?: HttpContext): Observable<StrictHttpResponse<TcRegistrationDto>> {
  const rb = new RequestBuilder(rootUrl, updateTcProperties.PATH, 'patch');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.path('svgId', params.svgId, {});
    rb.path('tcPaymentNumber', params.tcPaymentNumber, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TcRegistrationDto>;
    })
  );
}

updateTcProperties.PATH = '/svgs/{svgId}/customers/{customerId}/registrations/{tcPaymentNumber}/tcprops';
