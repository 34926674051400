/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseDocument } from '../../models/response-document';

export interface GetSvgDocuments$Params {

/**
 * the id of the svg
 */
  id: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getSvgDocuments(http: HttpClient, rootUrl: string, params: GetSvgDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocument>> {
  const rb = new RequestBuilder(rootUrl, getSvgDocuments.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseDocument>;
    })
  );
}

getSvgDocuments.PATH = '/svgs/{id}/documents';
