import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SvgContextService } from '@svg-frontends/session';

import { DocumentService } from '../services/document.service';

@Pipe({
	name: 'customerDocumentDownloadPath',
})
export class CustomerDocumentDownloadPathPipe implements PipeTransform {
	constructor(private svgContextService: SvgContextService, private documentService: DocumentService) {}
	transform(documentId: number): Observable<string> {
		return this.svgContextService
			.getFirstPortalUid()
			.pipe(map((portalUid: string) => this.documentService.getCustomerDocumentDownloadPathFromDocId(documentId, portalUid)));
	}
}
