<ng-select
	#select
	(change)="handleSelection($event)"
	(keyup.enter)="submit()"
	(scrollToEnd)="fetchTrigger$.next()"
	[appendTo]="appendTo"
	[class.mac-input]="formGroupInputStyle"
	[clearable]="isClearable"
	[clearSearchOnAdd]="true"
	[closeOnSelect]="!multiple"
	[items]="items$ | async"
	[loading]="isLoading"
	[markFirst]="true"
	[multiple]="multiple"
	[ngModel]="preSelection"
	[typeahead]="typeAhead$"
	[virtualScroll]="true"
	id="select"
	placeholder="{{ itemTerm }} wählen"
	typeToSearchText="Beginne Eingabe..."
>
	<ng-template let-clear="clear" let-item="item" ng-label-tmp>
		<ng-template *ngTemplateOutlet="labelTemplate; context: { item: item }"></ng-template>
		<ng-container *ngIf="isClearable">
			<span (click)="clear(item)" aria-hidden="true" class="ng-value-icon right">×</span>
		</ng-container>
	</ng-template>

	<ng-template let-index="index" let-item="item" let-item$="item$" let-search="searchTerm" ng-option-tmp>
		<ng-template *ngTemplateOutlet="optionTemplate; context: { item: item, item$: item$, search: search }"></ng-template>
	</ng-template>

	<ng-template let-searchTerm="searchTerm" ng-notfound-tmp>
		<div *ngIf="!!searchTerm" class="ng-option disabled">Kein(e) {{ itemTerm }} gefunden für: {{ searchTerm }}</div>
		<div *ngIf="!searchTerm" class="ng-option disabled">Kein(e) {{ itemTerm }} gefunden</div>
	</ng-template>

	<ng-template let-searchTerm="searchTerm" ng-loadingtext-tmp>
		<div class="ng-option disabled">Suche {{ itemTerm }} beginnend mit: {{ searchTerm }}</div>
	</ng-template>
</ng-select>
