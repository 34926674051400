import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { PiaControllerService, SvgContact } from '@mysvg/api/pia';

import { SvgContext, SvgContextService } from '@svg-frontends/session';

import { RepositoryStoreService } from './repository-store.service';

export interface SvgContactWithSvgName {
	svgName: string;
	svgContact: SvgContact;
}

@Injectable({ providedIn: 'root' })
export class CommunicationSvgContactStoreService extends RepositoryStoreService<SvgContactWithSvgName> {
	constructor(private svgContextService: SvgContextService, private piaControllerService: PiaControllerService) {
		super();
	}

	fetchData(): Observable<SvgContactWithSvgName> {
		return this.svgContextService.getFirstSvgContext().pipe(
			mergeMap((svgContext: SvgContext) =>
				this.piaControllerService.getSvgContact({ id: svgContext.customerInfo.id }).pipe(
					map((svgContact: SvgContact) => ({
						svgName: svgContext.customerInfo.svgName,
						svgContact: svgContact,
					})),
				),
			),
		);
	}
}
