<clr-dg-action-bar class="hasResetFilterButton">
	<div>
		<ng-content></ng-content>
	</div>

	<clr-button-group class="btn-secondary btn-sm resetFilterButton">
		<ng-container *ngIf="showCompactButton">
			<ng-container *svgFrontendsLet="tableViewOptionsService.compactState$ | async as compactState">
				<clr-button (click)="tableViewOptionsService.changeCompactState(false)" *ngIf="compactState === true">
					<cds-icon shape="zoom-in"></cds-icon>
					Vergrößern
				</clr-button>
				<clr-button (click)="tableViewOptionsService.changeCompactState(true)" *ngIf="compactState === false">
					<cds-icon shape="zoom-out"></cds-icon>
					Verkleinern
				</clr-button>
			</ng-container>
		</ng-container>
		<clr-button (click)="onReload()" *ngIf="showRefreshButton">
			<cds-icon shape="refresh"></cds-icon>
			Neu laden
		</clr-button>
		<clr-button
			(click)="resetAllFilters()"
			*ngIf="showResetButton"
			[disabled]="(tableViewService.hasFilters$.asObservable() | async) === false"
		>
			<cds-icon shape="undo"></cds-icon>
			Zurücksetzen
		</clr-button>
	</clr-button-group>
</clr-dg-action-bar>
