<div *ngIf="file | isDefined" class="card">
	<div class="card-block">
		<div class="card-media-block svg-frontends-card-media-block">
			<ng-container *ngIf="file | filePreviewUrl | async as preview">
				<!-- if no file preview created then use icon or fallback to FA_FILE -->
				<img
					*ngIf="fileTypeInfo?.isImagePreviewAvailable && (preview.value | isDefined)"
					[alt]="file.name"
					[src]="preview.value"
					class="card-media-image mac-preview"
				/>
				<fa-icon
					*ngIf="!(fileTypeInfo?.isImagePreviewAvailable && (preview.value | isDefined))"
					[icon]="fileTypeInfo?.icon || FA_FILE"
					class="mac-upload-icon card-media-image mac-preview mac-preview--icon"
				></fa-icon>

				<div *ngIf="preview.isLoading" class="mac-spinner-wrapper">
					<svg-frontends-loading [isSmall]="true" [withBackdrop]="true"></svg-frontends-loading>
				</div>
			</ng-container>
			<div class="card-media-description">
				<span class="card-media-title">{{ file.name }}</span>
				<div class="card-media-text mt-0">
					<p class="mt-0">
						{{ file.size | fileSize }}
						<i *ngIf="file.type">({{ file.type | fileType }})</i>
					</p>
					<p class="mt-0">{{ file.lastModified | timeElapsedFromNow }}</p>
				</div>
			</div>
			<div *ngIf="hasRemoveOption" class="mac-closewrapper">
				<button (click)="removeFile()" class="close" type="button">
					<cds-icon shape="window-close"></cds-icon>
				</button>
			</div>
		</div>
	</div>
</div>
