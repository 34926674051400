import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ScopeOfCareDto } from '@myasi/api/customers/riskassessment';

import { isDefined } from '@mysvg/utils';
import { AsiContextService } from '@svg-frontends/session';

import { ScopeOfCareRepositoryService } from '../../../repositories/risk-assessment/scope-of-care-repository.service';
import { RepositoryStoreService } from '../../repository-store.service';

const ARCHIVED = false;

@Injectable({ providedIn: 'root' })
export class ScopesOfCareStoreService extends RepositoryStoreService<ScopeOfCareDto[]> {
	constructor(private asiContextService: AsiContextService, private scopeOfCareRepositoryService: ScopeOfCareRepositoryService) {
		super();
	}

	fetchData(): Observable<ScopeOfCareDto[]> {
		return this.asiContextService.getFirstCustomerId().pipe(
			mergeMap((customerId: number) => this.scopeOfCareRepositoryService.get(customerId, ARCHIVED)),
			// 404 not found, if no description in db - this is an expected error and we catch it, by just showing no description
			catchError((httpErrorResponse: HttpErrorResponse) => (httpErrorResponse.status === 404 ? of(null) : throwError(httpErrorResponse))),
		);
	}

	getWith(id: number): Observable<ScopeOfCareDto> {
		return this.getFirst().pipe(
			map((scopeOfCareDtos: ScopeOfCareDto[]) => scopeOfCareDtos.find((scopeOfCareDto: ScopeOfCareDto) => scopeOfCareDto.id === id)),
			mergeMap((scopeOfCareDto: ScopeOfCareDto) =>
				isDefined(scopeOfCareDto) ? of(scopeOfCareDto) : throwError(new Error(`Could not find active scope of care with id ${id}`)),
			),
		);
	}
}
