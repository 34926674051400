import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Context } from '../../../context/models/context.model';
import { KeycloakTokenClaims } from '../../../context/models/user-token-claims.model';
import { AuthRefreshService } from '../../services/auth-refresh.service';
import { OauthWrapperService } from '../../services/oauth-wrapper.service';

@Injectable()
export class ErrorResponseInterceptorService implements HttpInterceptor {
	constructor(
		private authRefreshService: AuthRefreshService<Context, KeycloakTokenClaims>,
		private oauthWrapperService: OauthWrapperService<KeycloakTokenClaims>,
	) {}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(req).pipe(
			tap(
				(): void => void 0,
				(error: HttpErrorResponse) => this.handleError(error),
			),
		);
	}

	private handleError(error: HttpErrorResponse): void {
		if (this.isUnauthorizedError(error)) {
			this.authRefreshService.triggerRefresh();
		}
	}

	private isUnauthorizedError(error: HttpErrorResponse): boolean {
		return !this.oauthWrapperService.hasValidAccessToken() && error.status === 401;
	}
}
