/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AttachmentDto } from '../models/attachment-dto';
import { uploadFilePrivate } from '../fn/document-controller/upload-file-private';
import { UploadFilePrivate$Params } from '../fn/document-controller/upload-file-private';
import { uploadFileToProcess } from '../fn/document-controller/upload-file-to-process';
import { UploadFileToProcess$Params } from '../fn/document-controller/upload-file-to-process';

@Injectable({ providedIn: 'root' })
export class DocumentControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `uploadFileToProcess()` */
  static readonly UploadFileToProcessPath = '/processes/{processId}/attachments/upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFileToProcess()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFileToProcess$Response(params: UploadFileToProcess$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachmentDto>> {
    return uploadFileToProcess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadFileToProcess$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFileToProcess(params: UploadFileToProcess$Params, context?: HttpContext): Observable<AttachmentDto> {
    return this.uploadFileToProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachmentDto>): AttachmentDto => r.body)
    );
  }

  /** Path part for operation `uploadFilePrivate()` */
  static readonly UploadFilePrivatePath = '/processes/documents/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFilePrivate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFilePrivate$Response(params: UploadFilePrivate$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachmentDto>> {
    return uploadFilePrivate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadFilePrivate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFilePrivate(params: UploadFilePrivate$Params, context?: HttpContext): Observable<AttachmentDto> {
    return this.uploadFilePrivate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachmentDto>): AttachmentDto => r.body)
    );
  }

}
