<ng-container *ngIf="isValid">
	<span class="mr-1 clr-hidden-sm-down">Summiert aus Tabelle:</span>

	<ng-container *ngIf="!!meta.totalGroupedQuantities?.L || !!meta.totalGroupedQuantities?.P">
		<span class="mr-1" *ngIf="!!meta.totalGroupedQuantities?.L">{{ meta.totalGroupedQuantities.L | number: '.2' }} Liter</span>
		<span class="mr-1" *ngIf="!!meta.totalGroupedQuantities?.P">{{ meta.totalGroupedQuantities.P | number: '.0-0' }} Stück</span>
	</ng-container>

	&nbsp;
	<span *ngIf="meta.totalNetAmount | isDefined" class="mr-1">Netto: {{ meta.totalNetAmount | currency: 'EUR':'symbol' }}</span>
	&nbsp;
	<span *ngIf="meta.totalGrossAmount | isDefined" class="mr-1">Brutto: {{ meta.totalGrossAmount | currency: 'EUR':'symbol' }}</span>
</ng-container>
