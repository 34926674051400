/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActivityTree } from '../models/activity-tree';
import { getActivitySet } from '../fn/activity-sets-controller/get-activity-set';
import { GetActivitySet$Params } from '../fn/activity-sets-controller/get-activity-set';
import { getActivitySets } from '../fn/activity-sets-controller/get-activity-sets';
import { GetActivitySets$Params } from '../fn/activity-sets-controller/get-activity-sets';
import { updateActivitySet } from '../fn/activity-sets-controller/update-activity-set';
import { UpdateActivitySet$Params } from '../fn/activity-sets-controller/update-activity-set';

@Injectable({ providedIn: 'root' })
export class ActivitySetsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getActivitySet()` */
  static readonly GetActivitySetPath = '/activitySets/{id}';

  /**
   * getting one set of activities by its activitySet-id in tree structure with all subsets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivitySet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivitySet$Response(params: GetActivitySet$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityTree>> {
    return getActivitySet(this.http, this.rootUrl, params, context);
  }

  /**
   * getting one set of activities by its activitySet-id in tree structure with all subsets
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActivitySet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivitySet(params: GetActivitySet$Params, context?: HttpContext): Observable<ActivityTree> {
    return this.getActivitySet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityTree>): ActivityTree => r.body)
    );
  }

  /** Path part for operation `updateActivitySet()` */
  static readonly UpdateActivitySetPath = '/activitySets/{id}';

  /**
   * updating the activitySet tree
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateActivitySet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActivitySet$Response(params: UpdateActivitySet$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityTree>> {
    return updateActivitySet(this.http, this.rootUrl, params, context);
  }

  /**
   * updating the activitySet tree
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateActivitySet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActivitySet(params: UpdateActivitySet$Params, context?: HttpContext): Observable<ActivityTree> {
    return this.updateActivitySet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityTree>): ActivityTree => r.body)
    );
  }

  /** Path part for operation `getActivitySets()` */
  static readonly GetActivitySetsPath = '/activitySets';

  /**
   * getting all sets of activities in tree structure
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivitySets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivitySets$Response(params?: GetActivitySets$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityTree>> {
    return getActivitySets(this.http, this.rootUrl, params, context);
  }

  /**
   * getting all sets of activities in tree structure
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActivitySets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivitySets(params?: GetActivitySets$Params, context?: HttpContext): Observable<ActivityTree> {
    return this.getActivitySets$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityTree>): ActivityTree => r.body)
    );
  }

}
