import { UserPermission } from '@mysvg/utils';

import { KeycloakTokenClaims } from '../../context/models/user-token-claims.model';

export class User {
	email: string;
	family_name: string;
	given_name: string;
	name: string;
	permissions: UserPermission[];
	preferred_username: string;
	roles: string[] = [];
	sub: string;

	constructor(userTokenClaims: KeycloakTokenClaims) {
		this.assignParams(userTokenClaims);
	}

	private assignParams(userTokenClaims: KeycloakTokenClaims): void {
		this.email = userTokenClaims.email;
		this.family_name = userTokenClaims.family_name;
		this.given_name = userTokenClaims.given_name;
		this.name = userTokenClaims.name;
		this.preferred_username = userTokenClaims.preferred_username;
		this.sub = userTokenClaims.sub;
	}
}
