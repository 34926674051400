/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllMandateStatus } from '../fn/mandate-status-controller/get-all-mandate-status';
import { GetAllMandateStatus$Params } from '../fn/mandate-status-controller/get-all-mandate-status';
import { ResponseMandateStatusDto } from '../models/response-mandate-status-dto';

@Injectable({ providedIn: 'root' })
export class MandateStatusControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllMandateStatus()` */
  static readonly GetAllMandateStatusPath = '/bankdetails/mandate/status';

  /**
   * Get a list of mandate status types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMandateStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMandateStatus$Response(params?: GetAllMandateStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseMandateStatusDto>> {
    return getAllMandateStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of mandate status types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMandateStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMandateStatus(params?: GetAllMandateStatus$Params, context?: HttpContext): Observable<ResponseMandateStatusDto> {
    return this.getAllMandateStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseMandateStatusDto>): ResponseMandateStatusDto => r.body)
    );
  }

}
