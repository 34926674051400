import { NgModule } from '@angular/core';
import { ClarityWrapperModule } from '@svg-frontends/theming';

import { NotFoundContentComponent } from './components/not-found-content/not-found-content.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

@NgModule({
	declarations: [NotFoundContentComponent, PageNotFoundComponent],
	exports: [NotFoundContentComponent, PageNotFoundComponent],
	imports: [ClarityWrapperModule],
})
export class PageNotFoundModule {}
