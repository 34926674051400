/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllGroupingTypes } from '../fn/grouping-type-controller/get-all-grouping-types';
import { GetAllGroupingTypes$Params } from '../fn/grouping-type-controller/get-all-grouping-types';
import { ResponseGroupingTypeDto } from '../models/response-grouping-type-dto';

@Injectable({ providedIn: 'root' })
export class GroupingTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllGroupingTypes()` */
  static readonly GetAllGroupingTypesPath = '/grouping/types';

  /**
   * List all available grouping types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllGroupingTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllGroupingTypes$Response(params?: GetAllGroupingTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseGroupingTypeDto>> {
    return getAllGroupingTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * List all available grouping types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllGroupingTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllGroupingTypes(params?: GetAllGroupingTypes$Params, context?: HttpContext): Observable<ResponseGroupingTypeDto> {
    return this.getAllGroupingTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseGroupingTypeDto>): ResponseGroupingTypeDto => r.body)
    );
  }

}
