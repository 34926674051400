/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getSubTypeNst } from '../fn/svg-type-controller/get-sub-type-nst';
import { GetSubTypeNst$Params } from '../fn/svg-type-controller/get-sub-type-nst';
import { getTypeNst } from '../fn/svg-type-controller/get-type-nst';
import { GetTypeNst$Params } from '../fn/svg-type-controller/get-type-nst';
import { ReportSubTypeBo } from '../models/report-sub-type-bo';
import { ReportTypeBo } from '../models/report-type-bo';

@Injectable({ providedIn: 'root' })
export class SvgTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTypeNst()` */
  static readonly GetTypeNstPath = '/svg/nst/type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTypeNst()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTypeNst$Response(params?: GetTypeNst$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReportTypeBo>>> {
    return getTypeNst(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTypeNst$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTypeNst(params?: GetTypeNst$Params, context?: HttpContext): Observable<Array<ReportTypeBo>> {
    return this.getTypeNst$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReportTypeBo>>): Array<ReportTypeBo> => r.body)
    );
  }

  /** Path part for operation `getSubTypeNst()` */
  static readonly GetSubTypeNstPath = '/svg/nst/subType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubTypeNst()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubTypeNst$Response(params?: GetSubTypeNst$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReportSubTypeBo>>> {
    return getSubTypeNst(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSubTypeNst$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubTypeNst(params?: GetSubTypeNst$Params, context?: HttpContext): Observable<Array<ReportSubTypeBo>> {
    return this.getSubTypeNst$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReportSubTypeBo>>): Array<ReportSubTypeBo> => r.body)
    );
  }

}
