/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { notifyForIncidents } from '../fn/incident-notification-controller/notify-for-incidents';
import { NotifyForIncidents$Params } from '../fn/incident-notification-controller/notify-for-incidents';

@Injectable({ providedIn: 'root' })
export class IncidentNotificationControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `notifyForIncidents()` */
  static readonly NotifyForIncidentsPath = '/incidents/notify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notifyForIncidents()` instead.
   *
   * This method doesn't expect any request body.
   */
  notifyForIncidents$Response(params?: NotifyForIncidents$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return notifyForIncidents(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notifyForIncidents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notifyForIncidents(params?: NotifyForIncidents$Params, context?: HttpContext): Observable<void> {
    return this.notifyForIncidents$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
