import { Injectable } from '@angular/core';
import { RiskParameterControllerService, RiskParameterDto } from '@mysvg/api/svgs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataWrapper } from '../../models/data-wrapper.model';
import { RepositoryStoreService } from '../repository-store.service';

@Injectable({ providedIn: 'root' })
export class RiskParametersStoreService extends RepositoryStoreService<RiskParameterDto[]> {
	constructor(private riskParameterControllerService: RiskParameterControllerService) {
		super();
	}

	fetchData(): Observable<RiskParameterDto[]> {
		return this.riskParameterControllerService.getRiskParameters();
	}

	findRiskParametersBySvgId(svgNr: number): Observable<DataWrapper<RiskParameterDto | undefined>> {
		return this.getData().pipe(
			map((wrapper: DataWrapper<RiskParameterDto[]>) => ({
				...wrapper,
				value: wrapper?.value?.find((riskParam: RiskParameterDto) => riskParam.svgNumber === svgNr?.toString()),
			})),
		);
	}
}
