/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseLockCancellationReasonBo } from '../../models/response-lock-cancellation-reason-bo';

export interface GetLockCancellationReasons$Params {

/**
 * search query
 */
  query?: string;

/**
 * limit for pagination
 */
  limit?: number;

/**
 * offset for pagination
 */
  offset?: number;
}

export function getLockCancellationReasons(http: HttpClient, rootUrl: string, params?: GetLockCancellationReasons$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseLockCancellationReasonBo>> {
  const rb = new RequestBuilder(rootUrl, getLockCancellationReasons.PATH, 'get');
  if (params) {
    rb.query('query', params.query, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseLockCancellationReasonBo>;
    })
  );
}

getLockCancellationReasons.PATH = '/customers/actions/reasons/lockCancellation';
