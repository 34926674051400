import { Injectable } from '@angular/core';
import { SalutationControllerService, SalutationDto } from '@mysvg/api/contact-data';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SalutationsStoreService extends RepositoryStoreService<SalutationDto[]> {
	constructor(private salutationControllerService: SalutationControllerService) {
		super();
	}

	fetchData(): Observable<SalutationDto[]> {
		return this.salutationControllerService.getAllSalutations();
	}
}
