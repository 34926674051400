import { CountryEnum } from '../models/enum/country.model';

export const Country: CountryEnum = {
	// countries
	AFG: { label: 'AFG', name: 'Afghanistan', abbrIso: 'AF' },
	ET: { label: 'ET', name: 'Ägypten', abbrIso: 'EG' },
	AL: { label: 'AL', name: 'Albanien', abbrIso: 'AL' },
	GBA: { label: 'GBA', name: 'Alderney', abbrIso: 'GG' },
	DZ: { label: 'DZ', name: 'Algerien', abbrIso: 'DZ' },
	AND: { label: 'AND', name: 'Andorra', abbrIso: 'AD' },
	ANG: { label: 'ANG', name: 'Angola', abbrIso: 'AO' },
	RA: { label: 'RA', name: 'Argentinien', abbrIso: 'AR' },
	AZ: { label: 'AZ', name: 'Aserbaidschan', abbrIso: 'AZ' },
	ETH: { label: 'ETH', name: 'Äthiopien', abbrIso: 'ET' },
	AUS: { label: 'AUS', name: 'Australien', abbrIso: 'AU' },
	BS: { label: 'BS', name: 'Bahamas', abbrIso: 'BS' },
	BRN: { label: 'BRN', name: 'Bahrain', abbrIso: 'BH' },
	BD: { label: 'BD', name: 'Bangladesch', abbrIso: 'BD' },
	BDS: { label: 'BDS', name: 'Barbados', abbrIso: 'BB' },
	BH: { label: 'BH', name: 'Belize', abbrIso: 'BZ' },
	BOL: { label: 'BOL', name: 'Bolivien', abbrIso: 'BO' },
	BIH: { label: 'BIH', name: 'Bosnien und Herzegowina', abbrIso: 'BA' },
	RB: { label: 'RB', name: 'Botswana', abbrIso: 'BW' },
	BR: { label: 'BR', name: 'Brasilien', abbrIso: 'BR' },
	BRU: { label: 'BRU', name: 'Brunei', abbrIso: 'BN' },
	BG: { label: 'BG', name: 'Bulgarien', abbrIso: 'BG' },
	BF: { label: 'BF', name: 'Burkina Faso', abbrIso: 'BF' },
	RCH: { label: 'RCH', name: 'Chile', abbrIso: 'CL' },
	CR: { label: 'CR', name: 'Costa Rica', abbrIso: 'CR' },
	DK: { label: 'DK', name: 'Dänemark', abbrIso: 'DK' },
	CD: { label: 'CD', name: 'Demokratische Republik Kongo', abbrIso: 'CD' },
	D: { label: 'D', name: 'Deutschland', abbrIso: 'DE' },
	WD: { label: 'WD', name: 'Dominica', abbrIso: 'DM' },
	DOM: { label: 'DOM', name: 'Dominikanische Republik', abbrIso: 'DO' },
	EC: { label: 'EC', name: 'Ecuador', abbrIso: 'EC' },
	CI: { label: 'CI', name: 'Elfenbeinküste', abbrIso: 'CI' },
	ES: { label: 'ES', name: 'El Salvador', abbrIso: 'SV' },
	ER: { label: 'ER', name: 'Eritrea', abbrIso: 'ER' },
	EST: { label: 'EST', name: 'Estland', abbrIso: 'EE' },
	FR: { label: 'FR', name: 'Färöer', abbrIso: 'FO' },
	FJI: { label: 'FJI', name: 'Fidschi', abbrIso: 'FJ' },
	FIN: { label: 'FIN', name: 'Finnland', abbrIso: 'FI' },
	F: { label: 'F', name: 'Frankreich', abbrIso: 'FR' },
	WAG: { label: 'WAG', name: 'Gambia', abbrIso: 'GM' },
	GE: { label: 'GE', name: 'Georgien', abbrIso: 'GE' },
	GH: { label: 'GH', name: 'Ghana', abbrIso: 'GH' },
	GBZ: { label: 'GBZ', name: 'Gibraltar', abbrIso: 'GI' },
	WG: { label: 'WG', name: 'Grenada', abbrIso: 'GD' },
	GR: { label: 'GR', name: 'Griechenland', abbrIso: 'GR' },
	GCA: { label: 'GCA', name: 'Guatemala', abbrIso: 'GT' },
	GBG: { label: 'GBG', name: 'Guernsey', abbrIso: 'GG' },
	GUY: { label: 'GUY', name: 'Guyana', abbrIso: 'GY' },
	RH: { label: 'RH', name: 'Haiti', abbrIso: 'HT' },
	HN: { label: 'HN', name: 'Honduras', abbrIso: 'HN' },
	HK: { label: 'HK', name: 'Hongkong', abbrIso: 'HK' },
	IND: { label: 'IND', name: 'Indien', abbrIso: 'IN' },
	RI: { label: 'RI', name: 'Indonesien', abbrIso: 'ID' },
	GBM: { label: 'GBM', name: 'Insel Man', abbrIso: 'IM' },
	IRQ: { label: 'IRQ', name: 'Irak', abbrIso: 'IQ' },
	IR: { label: 'IR', name: 'Iran', abbrIso: 'IR' },
	IRL: { label: 'IRL', name: 'Irland', abbrIso: 'IE' },
	IS: { label: 'IS', name: 'Island', abbrIso: 'IS' },
	IL: { label: 'IL', name: 'Israel', abbrIso: 'IL' },
	I: { label: 'I', name: 'Italien', abbrIso: 'IT' },
	JA: { label: 'JA', name: 'Jamaika', abbrIso: 'JM' },
	J: { label: 'J', name: 'Japan', abbrIso: 'JP' },
	GBJ: { label: 'GBJ', name: 'Jersey', abbrIso: 'JE' },
	JOR: { label: 'JOR', name: 'Jordanien', abbrIso: 'JO' },
	YU: { label: 'YU', name: 'Jugoslawien', abbrIso: 'YU' },
	K: { label: 'K', name: 'Kambodscha', abbrIso: 'KH' },
	CDN: { label: 'CDN', name: 'Kanada', abbrIso: 'CA' },
	KZ: { label: 'KZ', name: 'Kasachstan', abbrIso: 'KZ' },
	Q: { label: 'Q', name: 'Katar', abbrIso: 'QA' },
	EAK: { label: 'EAK', name: 'Kenia', abbrIso: 'KE' },
	KS: { label: 'KS', name: 'Kirgisistan', abbrIso: 'KG' },
	CO: { label: 'CO', name: 'Kolumbien', abbrIso: 'CO' },
	KSA: { label: 'KSA', name: 'Königreich Saudi-Arabien', abbrIso: 'SA' },
	HR: { label: 'HR', name: 'Kroatien', abbrIso: 'HR' },
	C: { label: 'C', name: 'Kuba', abbrIso: 'CU' },
	KWT: { label: 'KWT', name: 'Kuwait', abbrIso: 'KW' },
	LAO: { label: 'LAO', name: 'Laos', abbrIso: 'LA' },
	LS: { label: 'LS', name: 'Lesotho', abbrIso: 'LS' },
	LV: { label: 'LV', name: 'Lettland', abbrIso: 'LV' },
	RL: { label: 'RL', name: 'Libanon', abbrIso: 'LB' },
	FL: { label: 'FL', name: 'Liechtenstein', abbrIso: 'LI' },
	LT: { label: 'LT', name: 'Litauen', abbrIso: 'LT' },
	L: { label: 'L', name: 'Luxemburg', abbrIso: 'LU' },
	RM: { label: 'RM', name: 'Madagaskar', abbrIso: 'MG' },
	MW: { label: 'MW', name: 'Malawi', abbrIso: 'MW' },
	MAL: { label: 'MAL', name: 'Malaysia', abbrIso: 'MY' },
	RMM: { label: 'RMM', name: 'Mali', abbrIso: 'ML' },
	M: { label: 'M', name: 'Malta', abbrIso: 'MT' },
	MA: { label: 'MA', name: 'Marokko', abbrIso: 'MA' },
	RIM: { label: 'RIM', name: 'Mauretanien', abbrIso: 'MR' },
	MS: { label: 'MS', name: 'Mauritius', abbrIso: 'MU' },
	MK: { label: 'MK', name: 'Mazedonien', abbrIso: 'MK' },
	MEX: { label: 'MEX', name: 'Mexiko', abbrIso: 'MX' },
	MD: { label: 'MD', name: 'Moldawien', abbrIso: 'MD' },
	MC: { label: 'MC', name: 'Monaco', abbrIso: 'MC' },
	MGL: { label: 'MGL', name: 'Mongolei', abbrIso: 'MN' },
	MOC: { label: 'MOC', name: 'Mosambik', abbrIso: 'MZ' },
	MYA: { label: 'MYA', name: 'Myanmar', abbrIso: 'MM' },
	NAM: { label: 'NAM', name: 'Namibia', abbrIso: 'NA' },
	NZ: { label: 'NZ', name: 'Neuseeland', abbrIso: 'NZ' },
	NIC: { label: 'NIC', name: 'Nicaragua', abbrIso: 'NI' },
	NL: { label: 'NL', name: 'Niederlande', abbrIso: 'NL' },
	RN: { label: 'RN', name: 'Niger', abbrIso: 'NE' },
	WAN: { label: 'WAN', name: 'Nigeria', abbrIso: 'NG' },
	N: { label: 'N', name: 'Norwegen', abbrIso: 'NO' },
	OM: { label: 'OM', name: 'Oman', abbrIso: 'OM' },
	A: { label: 'A', name: 'Österreich', abbrIso: 'AT' },
	PK: { label: 'PK', name: 'Pakistan', abbrIso: 'PK' },
	PA: { label: 'PA', name: 'Panama', abbrIso: 'PA' },
	PY: { label: 'PY', name: 'Paraguay', abbrIso: 'PY' },
	PE: { label: 'PE', name: 'Peru', abbrIso: 'PE' },
	RP: { label: 'RP', name: 'Philippinen', abbrIso: 'PH' },
	PL: { label: 'PL', name: 'Polen', abbrIso: 'PL' },
	P: { label: 'P', name: 'Portugal', abbrIso: 'PT' },
	RC: { label: 'RC', name: 'Republik China (Taiwan)', abbrIso: 'TW' },
	RCB: { label: 'RCB', name: 'Republik Kongo', abbrIso: 'CG' },
	RWA: { label: 'RWA', name: 'Ruanda', abbrIso: 'RW' },
	RO: { label: 'RO', name: 'Rumänien', abbrIso: 'RO' },
	RUS: { label: 'RUS', name: 'Russland', abbrIso: 'RU' },
	Z: { label: 'Z', name: 'Sambia', abbrIso: 'ZM' },
	WS: { label: 'WS', name: 'Samoa', abbrIso: 'WS' },
	RSM: { label: 'RSM', name: 'San Marino', abbrIso: 'SM' },
	CH: { label: 'CH', name: 'Schweiz', abbrIso: 'CH' },
	SN: { label: 'SN', name: 'Senegal', abbrIso: 'SN' },
	SCG: { label: 'SCG', name: 'Serbien und Montenegro', abbrIso: 'CS' },
	SY: { label: 'SY', name: 'Seychellen', abbrIso: 'SC' },
	WAL: { label: 'WAL', name: 'Sierra Leone', abbrIso: 'SL' },
	ZW: { label: 'ZW', name: 'Simbabwe', abbrIso: 'ZW' },
	SGP: { label: 'SGP', name: 'Singapur', abbrIso: 'SG' },
	SK: { label: 'SK', name: 'Slowakei', abbrIso: 'SK' },
	SLO: { label: 'SLO', name: 'Slowenien', abbrIso: 'SI' },
	SP: { label: 'SP', name: 'Somalia', abbrIso: 'SO' },
	SU: { label: 'SU', name: 'Sowjetunion', abbrIso: '' },
	E: { label: 'E', name: 'Spanien', abbrIso: 'ES' },
	WL: { label: 'WL', name: 'St. Lucia', abbrIso: 'LC' },
	WV: { label: 'WV', name: 'St. Vincent und die Grenadinen', abbrIso: 'VC' },
	ZA: { label: 'ZA', name: 'Südafrika', abbrIso: 'ZA' },
	ROK: { label: 'ROK', name: 'Südkorea', abbrIso: 'KR' },
	SME: { label: 'SME', name: 'Suriname', abbrIso: 'SR' },
	SD: { label: 'SD', name: 'Swasiland', abbrIso: 'SZ' },
	SYR: { label: 'SYR', name: 'Syrien', abbrIso: 'SY' },
	TJ: { label: 'TJ', name: 'Tadschikistan', abbrIso: 'TJ' },
	EAT: { label: 'EAT', name: 'Tansania', abbrIso: 'TZ' },
	THA: { label: 'THA', name: 'Thailand', abbrIso: 'TH' },
	RT: { label: 'RT', name: 'Togo', abbrIso: 'TG' },
	TT: { label: 'TT', name: 'Trinidad und Tobago', abbrIso: 'TT' },
	CZ: { label: 'CZ', name: 'Tschechien', abbrIso: 'CZ' },
	TN: { label: 'TN', name: 'Tunesien', abbrIso: 'TN' },
	TR: { label: 'TR', name: 'Türkei', abbrIso: 'TR' },
	TM: { label: 'TM', name: 'Turkmenistan', abbrIso: 'TM' },
	EAU: { label: 'EAU', name: 'Uganda', abbrIso: 'UG' },
	UA: { label: 'UA', name: 'Ukraine', abbrIso: 'UA' },
	H: { label: 'H', name: 'Ungarn', abbrIso: 'HU' },
	ROU: { label: 'ROU', name: 'Uruguay', abbrIso: 'UY' },
	USA: { label: 'USA', name: 'USA', abbrIso: 'US' },
	UZ: { label: 'UZ', name: 'Usbekistan', abbrIso: 'UZ' },
	V: { label: 'V', name: 'Vatikanstaat', abbrIso: 'VA' },
	YV: { label: 'YV', name: 'Venezuela', abbrIso: 'VE' },
	UAE: { label: 'UAE', name: 'Vereinigte Arabische Emirate', abbrIso: 'AE' },
	GB: { label: 'GB', name: 'Vereinigtes Königreich', abbrIso: 'GB' },
	VN: { label: 'VN', name: 'Vietnam', abbrIso: 'VN' },
	BY: { label: 'BY', name: 'Weißrussland', abbrIso: 'BY' },
	RCA: { label: 'RCA', name: 'Zentralafrikanische Republik', abbrIso: 'CF' },
	CY: { label: 'CY', name: 'Zypern', abbrIso: 'CY' },
};
