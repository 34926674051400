import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { PageVisibilityState } from '../enums/page-visibility-state.enum';

const PAGE_VISIBILITY_KEYS = [
	{
		// DEFAULT
		hidden: 'hidden',
		visibilitychange: 'visibilitychange',
		visibilityState: 'visibilityState',
	},
	{
		// MS
		hidden: 'msHidden',
		visibilitychange: 'msvisibilitychange',
		visibilityState: 'msVisibilityState',
	},
	{
		// WEBKIT
		hidden: 'webkitHidden',
		visibilitychange: 'webkitvisibilitychange',
		visibilityState: 'webkitVisibilityState',
	},
];

@Injectable({ providedIn: 'root' })
export class BrowserPageVisibilityApiService {
	private eventAndStateKeys: any;
	private lastState: PageVisibilityState;
	private state$ = new BehaviorSubject<PageVisibilityState>(PageVisibilityState.visible);

	constructor() {
		this.init();
	}

	getReEnterState(): Observable<PageVisibilityState> {
		return this.state$
			.asObservable()
			.pipe(filter((state: PageVisibilityState) => state === PageVisibilityState.visible && this.lastState === PageVisibilityState.hidden));
	}

	private init(): void {
		this.eventAndStateKeys = this.setBrowserDependingKeys();
		if (!!this.eventAndStateKeys) {
			this.setEventListener();
		}
	}

	private setBrowserDependingKeys(): any {
		return PAGE_VISIBILITY_KEYS.find((keySet: any) => document[keySet.hidden] === false || document[keySet.hidden] === true);
	}

	private setEventListener(): void {
		document.addEventListener(this.eventAndStateKeys.visibilitychange, () => this.handleVisibilityChange(), false);
	}

	private handleVisibilityChange(): void {
		const visibilityState: PageVisibilityState = this.getVisibilityState();
		this.lastState = this.state$.value;
		this.state$.next(visibilityState);
	}

	private getVisibilityState(): PageVisibilityState {
		const apiState: string = document[this.eventAndStateKeys.visibilityState];
		return PageVisibilityState[apiState];
	}
}
