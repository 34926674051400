/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseDocument } from '../../models/response-document';

export interface GetDocuments$Params {
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';

/**
 * a list of the business segments the documents should  be selected for
 */
  providers?: Array<string>;
  customers: string;
  type?: string;
  filename?: string;
  name?: string;
  country?: string;
  provider?: string;
  category?: string;
  versionNumber?: string;
  validUntil?: string;
  valid?: string;
  approved?: string;
  approver?: string;
  amount?: string;
  documentNumber?: string;
  author?: string;
  shortText?: string;
  tempDownloadHash?: string;
  persistedDownloadHash?: string;
  secured?: string;
}

export function getDocuments(http: HttpClient, rootUrl: string, params: GetDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocument>> {
  const rb = new RequestBuilder(rootUrl, getDocuments.PATH, 'get');
  if (params) {
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
    rb.query('providers', params.providers, {});
    rb.query('customers', params.customers, {});
    rb.query('type', params.type, {});
    rb.query('filename', params.filename, {});
    rb.query('name', params.name, {});
    rb.query('country', params.country, {});
    rb.query('provider', params.provider, {});
    rb.query('category', params.category, {});
    rb.query('versionNumber', params.versionNumber, {});
    rb.query('validUntil', params.validUntil, {});
    rb.query('valid', params.valid, {});
    rb.query('approved', params.approved, {});
    rb.query('approver', params.approver, {});
    rb.query('amount', params.amount, {});
    rb.query('documentNumber', params.documentNumber, {});
    rb.query('author', params.author, {});
    rb.query('shortText', params.shortText, {});
    rb.query('tempDownloadHash', params.tempDownloadHash, {});
    rb.query('persistedDownloadHash', params.persistedDownloadHash, {});
    rb.query('secured', params.secured, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseDocument>;
    })
  );
}

getDocuments.PATH = '/documents';
