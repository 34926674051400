import { Directive, Input, OnChanges } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

@Directive({ selector: '[svgFrontendsDisableForm]' })
export class MacDisableFormDirective implements OnChanges {
	@Input() svgFrontendsDisableForm: boolean | undefined;

	constructor(private controlContainer: ControlContainer) {}

	/**
	 * [NOTE] svgFrontendsDisableForm condition is not enough to decide whether the control should be disabled or enabled.
	 * 				the third option `do nothing` is needed too.
	 */
	ngOnChanges(): void {
		const abstractControl: AbstractControl = this.controlContainer.control;

		if (this.svgFrontendsDisableForm !== undefined) {
			if (this.svgFrontendsDisableForm) {
				abstractControl.disable({ emitEvent: false });
			} else if (!this.svgFrontendsDisableForm) {
				abstractControl.enable({ emitEvent: false });
			}
		}
	}
}
