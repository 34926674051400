import { Injectable } from '@angular/core';
import { VehiclesControllerService } from '@mysvg/api/vehicle';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TruckVehicleTypesStoreService extends RepositoryStoreService<string[]> {
	constructor(private vehiclesControllerService: VehiclesControllerService) {
		super();
	}

	fetchData(): Observable<string[]> {
		return this.vehiclesControllerService.getVehicleTypes({ truckOnly: true });
	}
}
