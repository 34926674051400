/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAgbs } from '../fn/public-controller/get-agbs';
import { GetAgbs$Params } from '../fn/public-controller/get-agbs';
import { getDocumentsOfType } from '../fn/public-controller/get-documents-of-type';
import { GetDocumentsOfType$Params } from '../fn/public-controller/get-documents-of-type';
import { getDsgvos } from '../fn/public-controller/get-dsgvos';
import { GetDsgvos$Params } from '../fn/public-controller/get-dsgvos';
import { getFaqDocuments } from '../fn/public-controller/get-faq-documents';
import { GetFaqDocuments$Params } from '../fn/public-controller/get-faq-documents';
import { getManualInstructionDocuments } from '../fn/public-controller/get-manual-instruction-documents';
import { GetManualInstructionDocuments$Params } from '../fn/public-controller/get-manual-instruction-documents';
import { getSvgForZipCodePublic } from '../fn/public-controller/get-svg-for-zip-code-public';
import { GetSvgForZipCodePublic$Params } from '../fn/public-controller/get-svg-for-zip-code-public';
import { SvgDocument } from '../models/svg-document';
import { ZipCode2SvgDto } from '../models/zip-code-2-svg-dto';

@Injectable({ providedIn: 'root' })
export class PublicControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSvgForZipCodePublic()` */
  static readonly GetSvgForZipCodePublicPath = '/public/zipCode2Svg/{zipCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSvgForZipCodePublic()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgForZipCodePublic$Response(params: GetSvgForZipCodePublic$Params, context?: HttpContext): Observable<StrictHttpResponse<ZipCode2SvgDto>> {
    return getSvgForZipCodePublic(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSvgForZipCodePublic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgForZipCodePublic(params: GetSvgForZipCodePublic$Params, context?: HttpContext): Observable<ZipCode2SvgDto> {
    return this.getSvgForZipCodePublic$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZipCode2SvgDto>): ZipCode2SvgDto => r.body)
    );
  }

  /** Path part for operation `getDocumentsOfType()` */
  static readonly GetDocumentsOfTypePath = '/public/svgs/{svgId}/documents/{documentTypeId}';

  /**
   * get documents of documentType
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentsOfType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsOfType$Response(params: GetDocumentsOfType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SvgDocument>>> {
    return getDocumentsOfType(this.http, this.rootUrl, params, context);
  }

  /**
   * get documents of documentType
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentsOfType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsOfType(params: GetDocumentsOfType$Params, context?: HttpContext): Observable<Array<SvgDocument>> {
    return this.getDocumentsOfType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SvgDocument>>): Array<SvgDocument> => r.body)
    );
  }

  /** Path part for operation `getManualInstructionDocuments()` */
  static readonly GetManualInstructionDocumentsPath = '/public/svgs/{svgId}/documents/manuals';

  /**
   * get latest manual instruction documents of the svg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManualInstructionDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualInstructionDocuments$Response(params: GetManualInstructionDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SvgDocument>>> {
    return getManualInstructionDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * get latest manual instruction documents of the svg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getManualInstructionDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualInstructionDocuments(params: GetManualInstructionDocuments$Params, context?: HttpContext): Observable<Array<SvgDocument>> {
    return this.getManualInstructionDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SvgDocument>>): Array<SvgDocument> => r.body)
    );
  }

  /** Path part for operation `getFaqDocuments()` */
  static readonly GetFaqDocumentsPath = '/public/svgs/{svgId}/documents/faqs';

  /**
   * get the lastest f.a.q-documents of the svg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFaqDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqDocuments$Response(params: GetFaqDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SvgDocument>>> {
    return getFaqDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * get the lastest f.a.q-documents of the svg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFaqDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqDocuments(params: GetFaqDocuments$Params, context?: HttpContext): Observable<Array<SvgDocument>> {
    return this.getFaqDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SvgDocument>>): Array<SvgDocument> => r.body)
    );
  }

  /** Path part for operation `getDsgvos()` */
  static readonly GetDsgvosPath = '/public/svgs/{svgId}/documents/dsgvos';

  /**
   * get latest dsgvo-document of the svg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDsgvos()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDsgvos$Response(params: GetDsgvos$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SvgDocument>>> {
    return getDsgvos(this.http, this.rootUrl, params, context);
  }

  /**
   * get latest dsgvo-document of the svg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDsgvos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDsgvos(params: GetDsgvos$Params, context?: HttpContext): Observable<Array<SvgDocument>> {
    return this.getDsgvos$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SvgDocument>>): Array<SvgDocument> => r.body)
    );
  }

  /** Path part for operation `getAgbs()` */
  static readonly GetAgbsPath = '/public/svgs/{svgId}/documents/agbs';

  /**
   * get latest agb-document of the svg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgbs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgbs$Response(params: GetAgbs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SvgDocument>>> {
    return getAgbs(this.http, this.rootUrl, params, context);
  }

  /**
   * get latest agb-document of the svg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgbs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgbs(params: GetAgbs$Params, context?: HttpContext): Observable<Array<SvgDocument>> {
    return this.getAgbs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SvgDocument>>): Array<SvgDocument> => r.body)
    );
  }

}
