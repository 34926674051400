import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { BoolPipe, ColumnEnum, EnumPipe, ListMappedPropertyToStringPipe, StatePipe } from '@mysvg/utils';
import { VehicleDocumentsPipe } from '@svg-frontends/document';

@Pipe({ name: 'column' })
export class ColumnPipe implements PipeTransform {
	private columns = ColumnEnum;

	constructor(
		private boolPipe: BoolPipe,
		private currencyPipe: CurrencyPipe,
		private datePipe: DatePipe,
		private enumPipe: EnumPipe,
		private listPropertyPipe: ListMappedPropertyToStringPipe,
		private percentPipe: PercentPipe,
		private statePipe: StatePipe,
		private vehicleDocumentsPipe: VehicleDocumentsPipe,
	) {}

	transform(obj: any, columnName: string, property: string = null, isExport: boolean = false): any {
		const column = this.columns[columnName];

		const isNotDefined = !column || !column.pipe;
		const isUnknownPipe = !isNotDefined && this.getPipeFromKey(column.pipe) === null;

		if (!property) {
			property = columnName;
		}

		if (isNotDefined || isUnknownPipe || (isExport && column.pipe === 'currency')) {
			return obj[property];
		} else {
			if (column.pipeArgs) {
				return this.getPipeFromKey(column.pipe).transform(obj[property], ...column.pipeArgs);
			} else {
				return this.getPipeFromKey(column.pipe).transform(obj[property]);
			}
		}
	}

	private getPipeFromKey(key: string): any | null {
		switch (key) {
			case 'bool':
				return this.boolPipe;
			case 'currency':
				return this.currencyPipe;
			case 'date':
				return this.datePipe;
			case 'enum':
				return this.enumPipe;
			case 'percent':
				return this.percentPipe;
			case 'state':
				return this.statePipe;
			case 'vehicle-documents':
				return this.vehicleDocumentsPipe;
			case 'list-mapped-property-to-string':
				return this.listPropertyPipe;
			default:
				return null;
		}
	}
}
