import { Injectable } from '@angular/core';
import { ContactPersonDto, ContactPersonManagementControllerService } from '@mysvg/api/contact-data';
import { SvgContextService } from '@svg-frontends/session';
import { RepositoryStoreService } from '../repository-store.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ContactPersonStoreService extends RepositoryStoreService<ContactPersonDto[]> {
	constructor(
		private contactPersonManagementControllerService: ContactPersonManagementControllerService,
		private svgContextService: SvgContextService,
	) {
		super();
	}

	fetchData(): Observable<ContactPersonDto[]> {
		return this.svgContextService
			.getFirstPortalUid()
			.pipe(mergeMap((portalUid: string) => this.contactPersonManagementControllerService.getContactPersons({ portalUid })));
	}
}
