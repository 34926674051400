import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProcessControllerService, ProcessDto, PublicControllerService } from '@mysvg/api/bpm';
import { SvgContext, SvgContextService } from '@svg-frontends/session';
import { BehaviorSubject, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

/**
 * this is a store service for `CUSTOMER` components
 */
@Injectable({ providedIn: 'root' })
export class ProcessDetailsStoreService {
	currentProcess$: Observable<ProcessDto | null>;
	currentProcessStatus$: Observable<number | null>;

	private currentProcessSubject = new BehaviorSubject<ProcessDto | null>(null);
	private currentProcessStatusSubject = new BehaviorSubject<number | null>(null);

	constructor(
		private processControllerService: ProcessControllerService,
		private publicControllerService: PublicControllerService,
		private svgContextService: SvgContextService,
	) {
		this.currentProcess$ = this.currentProcessSubject.asObservable();
		this.currentProcessStatus$ = this.currentProcessStatusSubject.asObservable();
	}

	updateProcess(process: ProcessDto | null, status: number | null = null): void {
		this.currentProcessSubject.next(process);
		this.currentProcessStatusSubject.next(status);
	}

	reload(): void {
		const process = this.currentProcessSubject.getValue();

		if (process) {
			this.loadProcess(process.id);
		}
	}

	private loadProcess(processId: string): void {
		this.svgContextService
			.getFirstSvgContext()
			.pipe(
				mergeMap((svgContext: SvgContext) => {
					if (svgContext.isNotLoggedInState()) {
						return this.publicControllerService.getPublicProcess({ processId });
					} else {
						return this.processControllerService.getProcess({ processId });
					}
				}),
			)
			.subscribe(
				(process: ProcessDto) => this.updateProcess(process),
				(error: HttpErrorResponse) => this.updateProcess(null, error.status),
			);
	}
}
