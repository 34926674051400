/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ParseResult } from '../../models/parse-result';
import { ParserType } from '../../models/parser-type';

export interface ParseVehicles$Params {
  portalUid: string;
  parserType: ParserType;
      body?: {
'file': Blob;
}
}

export function parseVehicles(http: HttpClient, rootUrl: string, params: ParseVehicles$Params, context?: HttpContext): Observable<StrictHttpResponse<ParseResult>> {
  const rb = new RequestBuilder(rootUrl, parseVehicles.PATH, 'post');
  if (params) {
    rb.query('portalUid', params.portalUid, {});
    rb.path('parserType', params.parserType, {});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ParseResult>;
    })
  );
}

parseVehicles.PATH = '/csv-parser/{parserType}';
