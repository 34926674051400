import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AddressDto, AddressManagementControllerService } from '@mysvg/api/contact-data';

import { SvgContextService } from '@svg-frontends/session';

import { DataWrapper } from '../../models/data-wrapper.model';
import { RepositoryStoreService } from '../repository-store.service';

@Injectable({ providedIn: 'root' })
export class AddressesStoreService extends RepositoryStoreService<AddressDto[]> {
	constructor(
		private addressManagementControllerService: AddressManagementControllerService,
		private svgContextService: SvgContextService,
	) {
		super();
	}

	fetchData(): Observable<AddressDto[]> {
		return this.svgContextService
			.getFirstPortalUid()
			.pipe(mergeMap((portalUid: string) => this.addressManagementControllerService.getCustomerAddresses({ portalUid })));
	}

	/**
	 * filter for non-old addresses and sort by type
	 */
	getCurrentAddresses(): Observable<DataWrapper<AddressDto[]>> {
		return this.getData().pipe(
			map((addressesWrap: DataWrapper<AddressDto[]>) => ({
				...addressesWrap,
				value: addressesWrap?.value
					?.filter((address: AddressDto) => !address?.oldAddress)
					?.sort((addressA: AddressDto, addressB: AddressDto) => addressA.type - addressB.type),
			})),
		);
	}

	getHistoryAddresses(): Observable<DataWrapper<AddressDto[] | []>> {
		return this.getData().pipe(
			map((addressesWrap: DataWrapper<AddressDto[]>) => ({
				...addressesWrap,
				value: addressesWrap?.value?.filter((address: AddressDto) => address?.oldAddress),
			})),
		);
	}
}
