/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CommunicationDto } from '../models/communication-dto';
import { createCommunicationByEntity } from '../fn/communication-controller/create-communication-by-entity';
import { CreateCommunicationByEntity$Params } from '../fn/communication-controller/create-communication-by-entity';
import { getCommunicationsByEntity } from '../fn/communication-controller/get-communications-by-entity';
import { GetCommunicationsByEntity$Params } from '../fn/communication-controller/get-communications-by-entity';
import { updateCommunication } from '../fn/communication-controller/update-communication';
import { UpdateCommunication$Params } from '../fn/communication-controller/update-communication';

@Injectable({ providedIn: 'root' })
export class CommunicationControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateCommunication()` */
  static readonly UpdateCommunicationPath = '/communications/{communicationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCommunication()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCommunication$Response(params: UpdateCommunication$Params, context?: HttpContext): Observable<StrictHttpResponse<CommunicationDto>> {
    return updateCommunication(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCommunication$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCommunication(params: UpdateCommunication$Params, context?: HttpContext): Observable<CommunicationDto> {
    return this.updateCommunication$Response(params, context).pipe(
      map((r: StrictHttpResponse<CommunicationDto>): CommunicationDto => r.body)
    );
  }

  /** Path part for operation `getCommunicationsByEntity()` */
  static readonly GetCommunicationsByEntityPath = '/communications/{entityType}/{entityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCommunicationsByEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCommunicationsByEntity$Response(params: GetCommunicationsByEntity$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CommunicationDto>>> {
    return getCommunicationsByEntity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCommunicationsByEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCommunicationsByEntity(params: GetCommunicationsByEntity$Params, context?: HttpContext): Observable<Array<CommunicationDto>> {
    return this.getCommunicationsByEntity$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CommunicationDto>>): Array<CommunicationDto> => r.body)
    );
  }

  /** Path part for operation `createCommunicationByEntity()` */
  static readonly CreateCommunicationByEntityPath = '/communications/{entityType}/{entityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCommunicationByEntity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCommunicationByEntity$Response(params: CreateCommunicationByEntity$Params, context?: HttpContext): Observable<StrictHttpResponse<CommunicationDto>> {
    return createCommunicationByEntity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCommunicationByEntity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCommunicationByEntity(params: CreateCommunicationByEntity$Params, context?: HttpContext): Observable<CommunicationDto> {
    return this.createCommunicationByEntity$Response(params, context).pipe(
      map((r: StrictHttpResponse<CommunicationDto>): CommunicationDto => r.body)
    );
  }

}
