/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TcDailySalesPaginatedBody } from '../../models/tc-daily-sales-paginated-body';

export interface GetDailySales$Params {

/**
 * limit for pagination
 */
  limit?: number;

/**
 * offset for pagination
 */
  offset?: number;
  sortOrder?: 'ASC' | 'DESC';
  sortBy?: string;

/**
 * UUID to identify the customer
 */
  customerId: string;

/**
 * UUID to identify the svg (tenancy id)
 */
  svgId: string;

/**
 * filter with a list of tc payment numbers
 */
  tcPaymentNumbers?: Array<string> | null;

/**
 * show only data which was created starting with the given date (inclusive)
 */
  createdAtMin?: string;

/**
 * show only data which was created ending at the given date (inclusive)
 */
  createdAtMax?: string;

/**
 * show only data which was updated starting with the given date (inclusive)
 */
  updatedAtMin?: string;

/**
 * show only data which was updated ending at the given date (inclusive)
 */
  updatedAtMax?: string;

/**
 * show only data which serviceDate is starting with the given date (inclusive)
 */
  serviceDateMin?: string;

/**
 * show only data which serviceDate is ending at the given date (inclusive)
 */
  serviceDateMax?: string;

/**
 * minimum amount of cents (inclusive)
 */
  grossAmountCentsMin?: number;

/**
 * maximum amount of cents (inclusive)
 */
  grossAmountCentsMax?: number;

/**
 * search string for license plate, will find matches that start with the search string
 */
  licensePlate?: string;

/**
 * match exactly one daily sale by original id (provided by CA)
 */
  originId?: number;

/**
 * show only data which invoiceDate is starting with the given date (inclusive)
 */
  invoiceDateMin?: string;

/**
 * show only data which invoiceDate is ending at the given date (inclusive)
 */
  invoiceDateMax?: string;

/**
 * search string for invoice number, will find matches that start with the search string
 */
  invoiceNumber?: string;

/**
 * filter daily sales by invoice state
 */
  invoiceFilter?: 'unspecified' | 'false' | 'true';
}

export function getDailySales(http: HttpClient, rootUrl: string, params: GetDailySales$Params, context?: HttpContext): Observable<StrictHttpResponse<TcDailySalesPaginatedBody>> {
  const rb = new RequestBuilder(rootUrl, getDailySales.PATH, 'get');
  if (params) {
    rb.query('limit', params.limit, {"explode":false});
    rb.query('offset', params.offset, {"explode":false});
    rb.query('sortOrder', params.sortOrder, {"explode":false});
    rb.query('sortBy', params.sortBy, {"explode":false});
    rb.path('customerId', params.customerId, {});
    rb.path('svgId', params.svgId, {});
    rb.query('tcPaymentNumbers', params.tcPaymentNumbers, {"explode":false});
    rb.query('createdAtMin', params.createdAtMin, {"explode":false});
    rb.query('createdAtMax', params.createdAtMax, {"explode":false});
    rb.query('updatedAtMin', params.updatedAtMin, {"explode":false});
    rb.query('updatedAtMax', params.updatedAtMax, {"explode":false});
    rb.query('serviceDateMin', params.serviceDateMin, {"explode":false});
    rb.query('serviceDateMax', params.serviceDateMax, {"explode":false});
    rb.query('grossAmountCentsMin', params.grossAmountCentsMin, {"explode":false});
    rb.query('grossAmountCentsMax', params.grossAmountCentsMax, {"explode":false});
    rb.query('licensePlate', params.licensePlate, {"explode":false});
    rb.query('originId', params.originId, {"explode":false});
    rb.query('invoiceDateMin', params.invoiceDateMin, {"explode":false});
    rb.query('invoiceDateMax', params.invoiceDateMax, {"explode":false});
    rb.query('invoiceNumber', params.invoiceNumber, {"explode":false});
    rb.query('invoiceFilter', params.invoiceFilter, {"explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TcDailySalesPaginatedBody>;
    })
  );
}

getDailySales.PATH = '/svgs/{svgId}/customers/{customerId}/daily-sales';
