/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { newLimit } from '../fn/rv-controller/new-limit';
import { NewLimit$Params } from '../fn/rv-controller/new-limit';
import { persistMailboxEntries } from '../fn/rv-controller/persist-mailbox-entries';
import { PersistMailboxEntries$Params } from '../fn/rv-controller/persist-mailbox-entries';
import { processLimitRequestWithoutResponseAfter90Days } from '../fn/rv-controller/process-limit-request-without-response-after-90-days';
import { ProcessLimitRequestWithoutResponseAfter90Days$Params } from '../fn/rv-controller/process-limit-request-without-response-after-90-days';
import { processMailboxEntries } from '../fn/rv-controller/process-mailbox-entries';
import { ProcessMailboxEntries$Params } from '../fn/rv-controller/process-mailbox-entries';
import { rvSearch } from '../fn/rv-controller/rv-search';
import { RvSearch$Params } from '../fn/rv-controller/rv-search';
import { RvSearchDto } from '../models/rv-search-dto';
import { SafetyDto } from '../models/safety-dto';
import { terminateLimit } from '../fn/rv-controller/terminate-limit';
import { TerminateLimit$Params } from '../fn/rv-controller/terminate-limit';
import { updateLimit } from '../fn/rv-controller/update-limit';
import { UpdateLimit$Params } from '../fn/rv-controller/update-limit';

@Injectable({ providedIn: 'root' })
export class RvControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateLimit()` */
  static readonly UpdateLimitPath = '/rv/safeties/{id}/update-limit';

  /**
   * Make a new limit request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLimit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLimit$Response(params: UpdateLimit$Params, context?: HttpContext): Observable<StrictHttpResponse<SafetyDto>> {
    return updateLimit(this.http, this.rootUrl, params, context);
  }

  /**
   * Make a new limit request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateLimit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLimit(params: UpdateLimit$Params, context?: HttpContext): Observable<SafetyDto> {
    return this.updateLimit$Response(params, context).pipe(
      map((r: StrictHttpResponse<SafetyDto>): SafetyDto => r.body)
    );
  }

  /** Path part for operation `newLimit()` */
  static readonly NewLimitPath = '/rv/new-limit';

  /**
   * Make a new limit request and create new safety
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newLimit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newLimit$Response(params: NewLimit$Params, context?: HttpContext): Observable<StrictHttpResponse<SafetyDto>> {
    return newLimit(this.http, this.rootUrl, params, context);
  }

  /**
   * Make a new limit request and create new safety
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newLimit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newLimit(params: NewLimit$Params, context?: HttpContext): Observable<SafetyDto> {
    return this.newLimit$Response(params, context).pipe(
      map((r: StrictHttpResponse<SafetyDto>): SafetyDto => r.body)
    );
  }

  /** Path part for operation `rvSearch()` */
  static readonly RvSearchPath = '/rv/search';

  /**
   * returns search result
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rvSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  rvSearch$Response(params: RvSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RvSearchDto>>> {
    return rvSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * returns search result
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rvSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rvSearch(params: RvSearch$Params, context?: HttpContext): Observable<Array<RvSearchDto>> {
    return this.rvSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RvSearchDto>>): Array<RvSearchDto> => r.body)
    );
  }

  /** Path part for operation `persistMailboxEntries()` */
  static readonly PersistMailboxEntriesPath = '/rv/mailbox/read';

  /**
   * Read & persist safety requests from R+V Mailbox
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `persistMailboxEntries()` instead.
   *
   * This method doesn't expect any request body.
   */
  persistMailboxEntries$Response(params?: PersistMailboxEntries$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return persistMailboxEntries(this.http, this.rootUrl, params, context);
  }

  /**
   * Read & persist safety requests from R+V Mailbox
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `persistMailboxEntries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  persistMailboxEntries(params?: PersistMailboxEntries$Params, context?: HttpContext): Observable<void> {
    return this.persistMailboxEntries$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `processMailboxEntries()` */
  static readonly ProcessMailboxEntriesPath = '/rv/mailbox/process';

  /**
   * Process safety requests from R+V Mailbox
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processMailboxEntries()` instead.
   *
   * This method doesn't expect any request body.
   */
  processMailboxEntries$Response(params?: ProcessMailboxEntries$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return processMailboxEntries(this.http, this.rootUrl, params, context);
  }

  /**
   * Process safety requests from R+V Mailbox
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processMailboxEntries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  processMailboxEntries(params?: ProcessMailboxEntries$Params, context?: HttpContext): Observable<void> {
    return this.processMailboxEntries$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `processLimitRequestWithoutResponseAfter90Days()` */
  static readonly ProcessLimitRequestWithoutResponseAfter90DaysPath = '/rv/limits/expired';

  /**
   * Processes limit requests which have not received a response after 90 days
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processLimitRequestWithoutResponseAfter90Days()` instead.
   *
   * This method doesn't expect any request body.
   */
  processLimitRequestWithoutResponseAfter90Days$Response(params?: ProcessLimitRequestWithoutResponseAfter90Days$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return processLimitRequestWithoutResponseAfter90Days(this.http, this.rootUrl, params, context);
  }

  /**
   * Processes limit requests which have not received a response after 90 days
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processLimitRequestWithoutResponseAfter90Days$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  processLimitRequestWithoutResponseAfter90Days(params?: ProcessLimitRequestWithoutResponseAfter90Days$Params, context?: HttpContext): Observable<void> {
    return this.processLimitRequestWithoutResponseAfter90Days$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `terminateLimit()` */
  static readonly TerminateLimitPath = '/rv/safeties/{id}/termination-limit';

  /**
   * Terminate a limit
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `terminateLimit()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateLimit$Response(params: TerminateLimit$Params, context?: HttpContext): Observable<StrictHttpResponse<SafetyDto>> {
    return terminateLimit(this.http, this.rootUrl, params, context);
  }

  /**
   * Terminate a limit
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `terminateLimit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateLimit(params: TerminateLimit$Params, context?: HttpContext): Observable<SafetyDto> {
    return this.terminateLimit$Response(params, context).pipe(
      map((r: StrictHttpResponse<SafetyDto>): SafetyDto => r.body)
    );
  }

}
