/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DocumentDirectoryMetaDto } from '../models/document-directory-meta-dto';
import { getPublicDocumentDirectoryMeta } from '../fn/customer-document-directory-controller/get-public-document-directory-meta';
import { GetPublicDocumentDirectoryMeta$Params } from '../fn/customer-document-directory-controller/get-public-document-directory-meta';
import { getSafetyDocumentDirectoryMeta } from '../fn/customer-document-directory-controller/get-safety-document-directory-meta';
import { GetSafetyDocumentDirectoryMeta$Params } from '../fn/customer-document-directory-controller/get-safety-document-directory-meta';

@Injectable({ providedIn: 'root' })
export class CustomerDocumentDirectoryControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSafetyDocumentDirectoryMeta()` */
  static readonly GetSafetyDocumentDirectoryMetaPath = '/customer/documents/directory/safeties';

  /**
   * return document directory meta of safeties (crefo and r+v) documents for customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSafetyDocumentDirectoryMeta()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSafetyDocumentDirectoryMeta$Response(params: GetSafetyDocumentDirectoryMeta$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentDirectoryMetaDto>>> {
    return getSafetyDocumentDirectoryMeta(this.http, this.rootUrl, params, context);
  }

  /**
   * return document directory meta of safeties (crefo and r+v) documents for customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSafetyDocumentDirectoryMeta$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSafetyDocumentDirectoryMeta(params: GetSafetyDocumentDirectoryMeta$Params, context?: HttpContext): Observable<Array<DocumentDirectoryMetaDto>> {
    return this.getSafetyDocumentDirectoryMeta$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentDirectoryMetaDto>>): Array<DocumentDirectoryMetaDto> => r.body)
    );
  }

  /** Path part for operation `getPublicDocumentDirectoryMeta()` */
  static readonly GetPublicDocumentDirectoryMetaPath = '/customer/documents/directory/public';

  /**
   * return document directory meta of public documents for customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicDocumentDirectoryMeta()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicDocumentDirectoryMeta$Response(params: GetPublicDocumentDirectoryMeta$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentDirectoryMetaDto>>> {
    return getPublicDocumentDirectoryMeta(this.http, this.rootUrl, params, context);
  }

  /**
   * return document directory meta of public documents for customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicDocumentDirectoryMeta$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicDocumentDirectoryMeta(params: GetPublicDocumentDirectoryMeta$Params, context?: HttpContext): Observable<Array<DocumentDirectoryMetaDto>> {
    return this.getPublicDocumentDirectoryMeta$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentDirectoryMetaDto>>): Array<DocumentDirectoryMetaDto> => r.body)
    );
  }

}
