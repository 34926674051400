import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgSelectWrapperModule, UtilsModule } from '@mysvg/utils';
import { DialogModule } from '@svg-frontends/dialog';
import { FormModule } from '@svg-frontends/form';
import { ClarityWrapperModule, OwlWrapperModule } from '@svg-frontends/theming';

import { DocumentCellComponent } from './components/document-cell/document-cell.component';
import { TableFooterMetaComponent } from './components/table-footer-meta/table-footer-meta.component';
import { TableViewActionsBarComponent } from './components/table-view-actions-bar/table-view-actions-bar.component';
import { TableViewFooterComponent } from './components/table-view-footer/table-view-footer.component';
import { TableViewPlaceholderContentComponent } from './components/table-view-placeholder-content/table-view-placeholder-content.component';
import { TableViewColumnDirective } from './directives/hideable-column.directive';
import { PageSizeDirective } from './directives/page-size.directive';
import { TableViewDirective } from './directives/table-view.directive';
import { ColumnPipe } from './pipes/column.pipe';
import { TableViewPlaceholderPipe } from './pipes/table-view-placeholder/table-view-placeholder.pipe';
import { UniquePipe } from './pipes/unique.pipe';

const DECLARABLES = [
	DocumentCellComponent,
	TableFooterMetaComponent,
	UniquePipe,
	PageSizeDirective,
	TableViewActionsBarComponent,
	TableViewColumnDirective,
	TableViewDirective,
	TableViewFooterComponent,
	TableViewPlaceholderContentComponent,
	TableViewPlaceholderPipe,
	ColumnPipe,
];

/**
 * Core module of table view
 */
@NgModule({
	declarations: [DECLARABLES],
	exports: [DECLARABLES],
	imports: [ClarityWrapperModule, DialogModule, FormModule, FormsModule, NgSelectWrapperModule, OwlWrapperModule, UtilsModule],
})
export class TableViewCoreModule {}
