import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ErrorHandlingType } from '../enums/error-handling-type.enum';
import { EnrichedError } from '../models/enriched-error.model';
import { EnrichedErrorFactoryService } from './enriched-error-factory.service';

@Injectable({ providedIn: 'root' })
export class ErrorHandlingService {
	private error$: BehaviorSubject<EnrichedError> = new BehaviorSubject<EnrichedError>(undefined);

	constructor(private enrichedErrorFactoryService: EnrichedErrorFactoryService, router: Router) {
		this.getErrorObservable()
			.pipe(filter((enrichedError: EnrichedError) => enrichedError.handlingType === ErrorHandlingType.ROUTE_TO_ERROR_PAGE))
			.subscribe(() => router.navigate(['error']));
	}

	getErrorObservable(): Observable<EnrichedError> {
		return this.error$.asObservable().pipe(filter((enrichedError: EnrichedError) => !!enrichedError));
	}

	getErrorForDialogObservable(): Observable<EnrichedError> {
		return this.getErrorObservable().pipe(
			filter((enrichedError: EnrichedError) => enrichedError.handlingType === ErrorHandlingType.SHOW_ERROR_DIALOG),
		);
	}

	setNextError(enrichedError: EnrichedError): EnrichedError {
		this.error$.next(enrichedError);
		return enrichedError;
	}

	setNextErrorBy(
		error: Error,
		handlingType: ErrorHandlingType = ErrorHandlingType.SHOW_ERROR_DIALOG,
		reportSentry: boolean = true,
	): EnrichedError {
		const enrichedError = this.enrichedErrorFactoryService.by(error, handlingType, reportSentry);
		this.error$.next(enrichedError);

		return enrichedError;
	}

	setNextErrorByKey(
		errorKey: string,
		handlingType: ErrorHandlingType = ErrorHandlingType.SHOW_ERROR_DIALOG,
		reportSentry: boolean = true,
	): EnrichedError {
		const enrichedError = this.enrichedErrorFactoryService.byKey(errorKey, handlingType, reportSentry);
		this.error$.next(enrichedError);

		return enrichedError;
	}
}
