import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BusinessSectorPermission } from '@mysvg/api/auth';

import { UtilsService } from '@mysvg/utils';
import { ContextState, SvgContext } from '@svg-frontends/session';
import { BusinessSectorsStoreService } from '@svg-frontends/store';

@Injectable({ providedIn: 'root' })
export class TableViewHttpParamsService {
	constructor(private businessSectorsStoreService: BusinessSectorsStoreService) {}

	static addCustomersParam(params: HttpParams, svgContext: SvgContext): HttpParams {
		if (!(svgContext.state === ContextState.CUSTOMER || svgContext.state === ContextState.STAFF_CUSTOMER)) {
			return params;
		} else {
			return params.set('customers', svgContext.customerInfo.id);
		}
	}

	enrichParamsByBusinessSectors(params: HttpParams, category?: string): Observable<HttpParams> {
		if (category) {
			return this.businessSectorsStoreService.getFirst().pipe(
				map((businessSectors: BusinessSectorPermission[]) =>
					UtilsService.createProviderParameter(businessSectors, params.get('providers'), category),
				),
				map((providerParam: string) => params.set('providers', providerParam)),
			);
		} else {
			return of(params);
		}
	}
}
