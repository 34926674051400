/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ExternalDocumentDto } from '../../models/external-document-dto';
import { ExternalDocumentFormDto } from '../../models/external-document-form-dto';

export interface UpdateExternalDocument$Params {
  id: number;
  puid: string;
      body: ExternalDocumentFormDto
}

export function updateExternalDocument(http: HttpClient, rootUrl: string, params: UpdateExternalDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalDocumentDto>> {
  const rb = new RequestBuilder(rootUrl, updateExternalDocument.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {});
    rb.path('puid', params.puid, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ExternalDocumentDto>;
    })
  );
}

updateExternalDocument.PATH = '/external-documents/{id}/customers/{puid}';
