/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TcDailySaleDto } from '../../models/tc-daily-sale-dto';
import { TcDailySaleForm } from '../../models/tc-daily-sale-form';

export interface InsertDailySale$Params {

/**
 * UUID to identify the svg (tenancy id)
 */
  svgId: string;
  customerId: string;
      body: TcDailySaleForm
}

export function insertDailySale(http: HttpClient, rootUrl: string, params: InsertDailySale$Params, context?: HttpContext): Observable<StrictHttpResponse<TcDailySaleDto>> {
  const rb = new RequestBuilder(rootUrl, insertDailySale.PATH, 'post');
  if (params) {
    rb.path('svgId', params.svgId, {});
    rb.path('customerId', params.customerId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TcDailySaleDto>;
    })
  );
}

insertDailySale.PATH = '/svgs/{svgId}/customers/{customerId}/daily-sales';
