export enum ChartTypeEnum {
	BAR_CHART,
	STACKED_BAR_CHART,
	GROUPED_BAR_CHART,
	NORMALIZED_BAR_CHART,
	BAR_CHART__HORIZONTAL,
	STACKED_BAR_CHART__HORIZONTAL,
	GROUPED_BAR_CHART__HORIZONTAL,
	NORMALIZED_BAR_CHART__HORIZONTAL,
	PIE_GRID,
	PIE_CHART,
	PIE_ADVANCED,
}
