import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TableViewService } from '../../services/table-view.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-table-view-placeholder-content',
	styleUrls: ['./table-view-placeholder-content.component.scss'],
	templateUrl: './table-view-placeholder-content.component.html',
})
export class TableViewPlaceholderContentComponent {
	@Input() itemName: string;
	@Input() showResetButton = true;

	constructor(public tableViewService: TableViewService<any, any>) {}

	resetFilters(): void {
		this.tableViewService.resetAllFilters();
	}
}
