import { TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * function to be used in structural directives to show or hide content
 */
export const setVisibility = (isVisible: boolean, viewContainerRef: ViewContainerRef, templateRef: TemplateRef<any>): void => {
	if (isVisible) {
		viewContainerRef.createEmbeddedView(templateRef);
	} else {
		viewContainerRef.clear();
	}
};
