import { Injectable } from '@angular/core';
import { BusinessSector, BusinessSectorControllerService } from '@mysvg/api/svgs';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SvgsBusinessSectorsStoreService extends RepositoryStoreService<BusinessSector[]> {
	constructor(private businessSectorControllerService: BusinessSectorControllerService) {
		super();
	}

	fetchData(): Observable<BusinessSector[]> {
		return this.businessSectorControllerService.getBusinessSectors();
	}
}
