import { Injectable } from '@angular/core';
import { DebitingDetailsControllerService, DebitingDetailsDto, ResponseDebitingDetailsDto } from '@mysvg/api/payment';
import { SvgContextService } from '@svg-frontends/session';
import { RepositoryStoreService } from '../repository-store.service';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DebitingDetailsStoreService extends RepositoryStoreService<DebitingDetailsDto[]> {
	constructor(private debitingDetailsControllerService: DebitingDetailsControllerService, private svgContextService: SvgContextService) {
		super();
	}

	fetchData(): Observable<DebitingDetailsDto[]> {
		return this.svgContextService.getFirstPortalUid().pipe(
			mergeMap((portalUid: string) => this.debitingDetailsControllerService.getDebitingDetailsForCustomer({ portalUid })),
			map((detailsLists: ResponseDebitingDetailsDto) => detailsLists.data),
		);
	}
}
