import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ContextState } from '../enums/context-state.enum';
import { SvgContext } from '../models/svg-context.model';
import { SvgContextService } from '../services/svg-context.service';

@Injectable({ providedIn: 'root' })
export class StaffDashboardGuard implements CanActivate {
	constructor(private svgContextService: SvgContextService, private router: Router) {}

	// TODO - in context staff_customer '/staff/dashboard cannot be accessed'
	canActivate(): Observable<UrlTree | boolean> {
		return this.svgContextService.getFirstSvgContext().pipe(map((svgContext: SvgContext) => this.getCanActivateBasedOnContext(svgContext)));
	}

	private getCanActivateBasedOnContext(svgContext: SvgContext): UrlTree | boolean {
		if (svgContext.state === ContextState.CUSTOMER || svgContext.state === ContextState.STAFF_CUSTOMER) {
			return this.router.createUrlTree(['dashboard']);
		} else {
			return true;
		}
	}
}
