import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';

import { FormGroupService } from '@svg-frontends/form';
import { DialogSize } from '../../enums/dialog-size.enum';

export interface SpecifyReasonDialogData<T> {
	cancelBtnTxt: string;
	confirmBtnTxt: string;
	data$: Observable<T[]>;
	reasonLabel: string;
	title: string;

	allowMultiple?: boolean;
	bindLabel?: string;
	dateLabel?: string;
	infoText?: string;
	isAdditionalCommentMandatory?: boolean;
	isDateMandatory?: boolean;
	showAdditionalComment?: boolean;
	showDate?: boolean;
	size?: DialogSize;
}

export interface SpecifyReasonResult<T> {
	additionalComment?: string;
	date?: string;
	reason: T;
}

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [FormGroupService],
	selector: 'svg-frontends-specify-reason-dialog',
	styleUrls: ['./specify-reason-dialog.component.scss'],
	templateUrl: './specify-reason-dialog.component.html',
})
export class SpecifyReasonDialogComponent<T> implements OnInit {
	DIALOG_SIZE = DialogSize;

	additionalCommentControlName = 'additionalComment';
	dateControlName = 'date';
	formGroup: FormGroup;
	reasonControlName = 'reason';

	today: string = dayjs(new Date()).startOf('day').toISOString();

	constructor(
		@Inject(MAT_DIALOG_DATA) public inputArgs: SpecifyReasonDialogData<T>,
		private matDialogRef: MatDialogRef<SpecifyReasonDialogComponent<T>>,
	) {}

	ngOnInit(): void {
		this.formGroup = new FormGroup({
			[this.additionalCommentControlName]: new FormControl(null, {
				validators: this.inputArgs.isAdditionalCommentMandatory ? [Validators.required] : [],
			}),
			[this.dateControlName]: new FormControl(null, { validators: this.inputArgs.isDateMandatory ? [Validators.required] : [] }),
			[this.reasonControlName]: new FormControl(null, { validators: [Validators.required] }),
		});
	}

	confirmDialog(): void {
		const result: SpecifyReasonResult<any> = this.formGroup.value;
		this.matDialogRef.close(result);
	}

	closeDialog(): void {
		this.matDialogRef.close(null);
	}
}
