import { ProviderEnum } from '../models/enum/provider.model';

export const ProvidersEnum: ProviderEnum = {
	// fuelcards
	DKV: { label: 'DKV', shortcut: 'DKV', color: '#FAA026', active: true, category: 'fuelcard' },
	ESSO: { label: 'Esso', shortcut: 'E', color: '#55ACD2', active: true, category: 'fuelcard' },
	ESSODE: { label: 'Esso DE', shortcut: 'ED', color: '#29BB9C', active: true, category: 'fuelcard' },
	EUROSHELL: { label: 'EuroShell', shortcut: 'ES', color: '#FAC51D', active: true, category: 'fuelcard' },
	TOTAL: { label: 'Total', shortcut: 'TO', color: '#E96B56', active: true, category: 'fuelcard' },

	// toll
	ASFINAGMAUT: { label: 'EuroMaut | Österreich', shortcut: 'ASF', color: '#3065ab', active: true, category: 'toll' },
	BELGIENMAUT: { label: 'EuroMaut | Belgien', shortcut: 'BM', color: '#92E7E8', active: true, category: 'toll' },
	DMAUT: { label: 'Deutsche Maut', shortcut: 'DM', color: '#66BD6D', active: true, category: 'toll' },
	EMAUT: { label: 'EuroMaut', shortcut: 'EM', color: '#B7332F', active: false, category: 'toll' },
	EVIGNETTE: { label: 'E-Vignette', shortcut: 'EVG', color: '#FDD6E3', active: true, category: 'toll' },
	FLEXBOX: { label: 'SVG fleXbox', shortcut: 'FLEXBOX', color: '#FAC51D', active: true, category: 'toll' },
	SVGBOX: { label: 'SVG-Box', shortcut: 'SVG', color: '#733d00', active: true, category: 'toll' },
	TISPL: { label: 'Tispl', shortcut: 'TI', color: '#dbff9c', active: true, category: 'toll' },
	VIALTIS: { label: 'Vialtis', shortcut: 'VIA', color: '#9166B8', active: true, category: 'toll' },

	// asi
	ASI: { label: 'Arbeitssicherheit', shortcut: 'ASI', color: '#C28523', active: true, category: 'asi' },

	// privacy
	PRIVACY: { label: 'Datenschutz', shortcut: 'DS', color: '#1dc200', active: true, category: 'privacy' },

	// other (not visible yet)
	ASSURANCE: { label: 'Versicherung', shortcut: 'V', color: '#870C1C', active: false, category: 'other' },
	BGHW: { label: 'BGHW', shortcut: 'BGH', color: '#f58866', active: false, category: 'other' },
	DRIVERDB: { label: 'Fahrer DB', shortcut: 'FDB', color: '#F5A82C', active: false, category: 'other' },
	SUBSIDIES: { label: 'Fördermittel', shortcut: 'SUB', color: '#C28523', active: false, category: 'other' },
};
