/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { changePassword } from '../fn/crefo-controller/change-password';
import { ChangePassword$Params } from '../fn/crefo-controller/change-password';
import { CrefoProductAvailabilityDto } from '../models/crefo-product-availability-dto';
import { CrefoSearchDto } from '../models/crefo-search-dto';
import { getKeylist } from '../fn/crefo-controller/get-keylist';
import { GetKeylist$Params } from '../fn/crefo-controller/get-keylist';
import { getProductAvailability } from '../fn/crefo-controller/get-product-availability';
import { GetProductAvailability$Params } from '../fn/crefo-controller/get-product-availability';
import { logon } from '../fn/crefo-controller/logon';
import { Logon$Params } from '../fn/crefo-controller/logon';
import { search } from '../fn/crefo-controller/search';
import { Search$Params } from '../fn/crefo-controller/search';

@Injectable({ providedIn: 'root' })
export class CrefoControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `changePassword()` */
  static readonly ChangePasswordPath = '/crefo/change-password';

  /**
   * changes password
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword$Response(params: ChangePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * changes password
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword(params: ChangePassword$Params, context?: HttpContext): Observable<void> {
    return this.changePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `search()` */
  static readonly SearchPath = '/crefo/search';

  /**
   * returns search result
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method doesn't expect any request body.
   */
  search$Response(params: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<CrefoSearchDto>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * returns search result
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  search(params: Search$Params, context?: HttpContext): Observable<CrefoSearchDto> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrefoSearchDto>): CrefoSearchDto => r.body)
    );
  }

  /** Path part for operation `getProductAvailability()` */
  static readonly GetProductAvailabilityPath = '/crefo/product-availability';

  /**
   * returns product availability
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductAvailability()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductAvailability$Response(params: GetProductAvailability$Params, context?: HttpContext): Observable<StrictHttpResponse<CrefoProductAvailabilityDto>> {
    return getProductAvailability(this.http, this.rootUrl, params, context);
  }

  /**
   * returns product availability
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductAvailability$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductAvailability(params: GetProductAvailability$Params, context?: HttpContext): Observable<CrefoProductAvailabilityDto> {
    return this.getProductAvailability$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrefoProductAvailabilityDto>): CrefoProductAvailabilityDto => r.body)
    );
  }

  /** Path part for operation `logon()` */
  static readonly LogonPath = '/crefo/logon';

  /**
   * returns logon response
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logon()` instead.
   *
   * This method doesn't expect any request body.
   */
  logon$Response(params?: Logon$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return logon(this.http, this.rootUrl, params, context);
  }

  /**
   * returns logon response
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logon$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logon(params?: Logon$Params, context?: HttpContext): Observable<string> {
    return this.logon$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getKeylist()` */
  static readonly GetKeylistPath = '/crefo/keylist';

  /**
   * returns keylist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKeylist()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKeylist$Response(params?: GetKeylist$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getKeylist(this.http, this.rootUrl, params, context);
  }

  /**
   * returns keylist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getKeylist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKeylist(params?: GetKeylist$Params, context?: HttpContext): Observable<string> {
    return this.getKeylist$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
