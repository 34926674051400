import { Injectable } from '@angular/core';
import { CrefoAvailableProductDto, CrefoControllerService, CrefoProductAvailabilityDto } from '@mysvg/api/financial-guarantees';
import { SvgContextService } from '@svg-frontends/session';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { RepositoryStoreService } from '../repository-store.service';

@Injectable({ providedIn: 'root' })
export class AvailableProductStoreService extends RepositoryStoreService<CrefoAvailableProductDto[]> {
	constructor(private crefoControllerService: CrefoControllerService, private svgContextService: SvgContextService) {
		super();
	}

	fetchData(): Observable<CrefoAvailableProductDto[]> {
		return this.svgContextService.getFirstPortalUid().pipe(
			mergeMap((portalUid: string) => this.crefoControllerService.getProductAvailability({ portalUid })),
			map((productAvailability: CrefoProductAvailabilityDto) => productAvailability.availableProducts),
		);
	}
}
