import { Injectable } from '@angular/core';
import { CardStatusControllerService, CardStatusDto } from '@mysvg/api/toll-box';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CardStatusStoreService extends RepositoryStoreService<CardStatusDto[]> {
	constructor(private cardStatusControllerService: CardStatusControllerService) {
		super();
	}

	fetchData(): Observable<CardStatusDto[]> {
		return this.cardStatusControllerService.getCardStatuses();
	}
}
