/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ClientRepresentation } from '../models/client-representation';
import { createUser } from '../fn/users-controller/create-user';
import { CreateUser$Params } from '../fn/users-controller/create-user';
import { deleteUser } from '../fn/users-controller/delete-user';
import { DeleteUser$Params } from '../fn/users-controller/delete-user';
import { getGroupRelations } from '../fn/users-controller/get-group-relations';
import { GetGroupRelations$Params } from '../fn/users-controller/get-group-relations';
import { getServiceAccountClients } from '../fn/users-controller/get-service-account-clients';
import { GetServiceAccountClients$Params } from '../fn/users-controller/get-service-account-clients';
import { getServiceAccountForClient } from '../fn/users-controller/get-service-account-for-client';
import { GetServiceAccountForClient$Params } from '../fn/users-controller/get-service-account-for-client';
import { getUser } from '../fn/users-controller/get-user';
import { GetUser$Params } from '../fn/users-controller/get-user';
import { getUserLabel } from '../fn/users-controller/get-user-label';
import { GetUserLabel$Params } from '../fn/users-controller/get-user-label';
import { getUsers } from '../fn/users-controller/get-users';
import { GetUsers$Params } from '../fn/users-controller/get-users';
import { GroupRelation } from '../models/group-relation';
import { ResponseUserDto } from '../models/response-user-dto';
import { triggerPasswordReset } from '../fn/users-controller/trigger-password-reset';
import { TriggerPasswordReset$Params } from '../fn/users-controller/trigger-password-reset';
import { updateGroupRelations } from '../fn/users-controller/update-group-relations';
import { UpdateGroupRelations$Params } from '../fn/users-controller/update-group-relations';
import { updateUser } from '../fn/users-controller/update-user';
import { UpdateUser$Params } from '../fn/users-controller/update-user';
import { UserDto } from '../models/user-dto';
import { UserLabelDto } from '../models/user-label-dto';

@Injectable({ providedIn: 'root' })
export class UsersControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getGroupRelations()` */
  static readonly GetGroupRelationsPath = '/users/{id}/group-relations';

  /**
   * get the relations for the user with the given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroupRelations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupRelations$Response(params: GetGroupRelations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GroupRelation>>> {
    return getGroupRelations(this.http, this.rootUrl, params, context);
  }

  /**
   * get the relations for the user with the given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGroupRelations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupRelations(params: GetGroupRelations$Params, context?: HttpContext): Observable<Array<GroupRelation>> {
    return this.getGroupRelations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GroupRelation>>): Array<GroupRelation> => r.body)
    );
  }

  /** Path part for operation `updateGroupRelations()` */
  static readonly UpdateGroupRelationsPath = '/users/{id}/group-relations';

  /**
   * get the relations for the user with the given keycloak uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateGroupRelations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroupRelations$Response(params: UpdateGroupRelations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GroupRelation>>> {
    return updateGroupRelations(this.http, this.rootUrl, params, context);
  }

  /**
   * get the relations for the user with the given keycloak uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateGroupRelations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroupRelations(params: UpdateGroupRelations$Params, context?: HttpContext): Observable<Array<GroupRelation>> {
    return this.updateGroupRelations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GroupRelation>>): Array<GroupRelation> => r.body)
    );
  }

  /** Path part for operation `updateUser()` */
  static readonly UpdateUserPath = '/customers/{portalUuid}/users/{userUuid}';

  /**
   * updates a keycloak user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params: UpdateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return updateUser(this.http, this.rootUrl, params, context);
  }

  /**
   * updates a keycloak user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params: UpdateUser$Params, context?: HttpContext): Observable<UserDto> {
    return this.updateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `getUsers()` */
  static readonly GetUsersPath = '/users';

  /**
   * searches for keycloak users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(params: GetUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseUserDto>> {
    return getUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * searches for keycloak users
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(params: GetUsers$Params, context?: HttpContext): Observable<ResponseUserDto> {
    return this.getUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseUserDto>): ResponseUserDto => r.body)
    );
  }

  /** Path part for operation `createUser()` */
  static readonly CreateUserPath = '/users';

  /**
   * creates a keycloak user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser$Response(params: CreateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return createUser(this.http, this.rootUrl, params, context);
  }

  /**
   * creates a keycloak user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser(params: CreateUser$Params, context?: HttpContext): Observable<UserDto> {
    return this.createUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `triggerPasswordReset()` */
  static readonly TriggerPasswordResetPath = '/users/{id}/passwordReset';

  /**
   * trigger password reset event for a keycloak user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `triggerPasswordReset()` instead.
   *
   * This method doesn't expect any request body.
   */
  triggerPasswordReset$Response(params: TriggerPasswordReset$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return triggerPasswordReset(this.http, this.rootUrl, params, context);
  }

  /**
   * trigger password reset event for a keycloak user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `triggerPasswordReset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  triggerPasswordReset(params: TriggerPasswordReset$Params, context?: HttpContext): Observable<void> {
    return this.triggerPasswordReset$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getUser()` */
  static readonly GetUserPath = '/users/{id}';

  /**
   * get the keycloak user with the given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: GetUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return getUser(this.http, this.rootUrl, params, context);
  }

  /**
   * get the keycloak user with the given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: GetUser$Params, context?: HttpContext): Observable<UserDto> {
    return this.getUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `deleteUser()` */
  static readonly DeleteUserPath = '/users/{id}';

  /**
   * deletes a keycloak user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Response(params: DeleteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * deletes a keycloak user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser(params: DeleteUser$Params, context?: HttpContext): Observable<void> {
    return this.deleteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getUserLabel()` */
  static readonly GetUserLabelPath = '/users/{id}/label';

  /**
   * get the user label for a given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserLabel()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserLabel$Response(params: GetUserLabel$Params, context?: HttpContext): Observable<StrictHttpResponse<UserLabelDto>> {
    return getUserLabel(this.http, this.rootUrl, params, context);
  }

  /**
   * get the user label for a given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserLabel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserLabel(params: GetUserLabel$Params, context?: HttpContext): Observable<UserLabelDto> {
    return this.getUserLabel$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserLabelDto>): UserLabelDto => r.body)
    );
  }

  /** Path part for operation `getServiceAccountClients()` */
  static readonly GetServiceAccountClientsPath = '/users/technical';

  /**
   * searches for keycloak users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getServiceAccountClients()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceAccountClients$Response(params?: GetServiceAccountClients$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ClientRepresentation>>> {
    return getServiceAccountClients(this.http, this.rootUrl, params, context);
  }

  /**
   * searches for keycloak users
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getServiceAccountClients$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceAccountClients(params?: GetServiceAccountClients$Params, context?: HttpContext): Observable<Array<ClientRepresentation>> {
    return this.getServiceAccountClients$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ClientRepresentation>>): Array<ClientRepresentation> => r.body)
    );
  }

  /** Path part for operation `getServiceAccountForClient()` */
  static readonly GetServiceAccountForClientPath = '/users/technical/{id}/service-account';

  /**
   * searches for keycloak users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getServiceAccountForClient()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceAccountForClient$Response(params: GetServiceAccountForClient$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return getServiceAccountForClient(this.http, this.rootUrl, params, context);
  }

  /**
   * searches for keycloak users
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getServiceAccountForClient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceAccountForClient(params: GetServiceAccountForClient$Params, context?: HttpContext): Observable<UserDto> {
    return this.getServiceAccountForClient$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

}
