/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createUserTask } from '../fn/user-task-controller/create-user-task';
import { CreateUserTask$Params } from '../fn/user-task-controller/create-user-task';
import { getMyTasks } from '../fn/user-task-controller/get-my-tasks';
import { GetMyTasks$Params } from '../fn/user-task-controller/get-my-tasks';
import { getTasksByProcessUid } from '../fn/user-task-controller/get-tasks-by-process-uid';
import { GetTasksByProcessUid$Params } from '../fn/user-task-controller/get-tasks-by-process-uid';
import { solveTask } from '../fn/user-task-controller/solve-task';
import { SolveTask$Params } from '../fn/user-task-controller/solve-task';
import { TaskDto } from '../models/task-dto';
import { updateUserTask } from '../fn/user-task-controller/update-user-task';
import { UpdateUserTask$Params } from '../fn/user-task-controller/update-user-task';

@Injectable({ providedIn: 'root' })
export class UserTaskControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateUserTask()` */
  static readonly UpdateUserTaskPath = '/tasks/{id}';

  /**
   * Update task entry in database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserTask$Response(params: UpdateUserTask$Params, context?: HttpContext): Observable<StrictHttpResponse<TaskDto>> {
    return updateUserTask(this.http, this.rootUrl, params, context);
  }

  /**
   * Update task entry in database
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserTask(params: UpdateUserTask$Params, context?: HttpContext): Observable<TaskDto> {
    return this.updateUserTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaskDto>): TaskDto => r.body)
    );
  }

  /** Path part for operation `getTasksByProcessUid()` */
  static readonly GetTasksByProcessUidPath = '/tasks';

  /**
   * Get all tasks for a given process uid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTasksByProcessUid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTasksByProcessUid$Response(params: GetTasksByProcessUid$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaskDto>>> {
    return getTasksByProcessUid(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all tasks for a given process uid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTasksByProcessUid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTasksByProcessUid(params: GetTasksByProcessUid$Params, context?: HttpContext): Observable<Array<TaskDto>> {
    return this.getTasksByProcessUid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaskDto>>): Array<TaskDto> => r.body)
    );
  }

  /** Path part for operation `createUserTask()` */
  static readonly CreateUserTaskPath = '/tasks';

  /**
   * Create task entry in database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUserTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserTask$Response(params: CreateUserTask$Params, context?: HttpContext): Observable<StrictHttpResponse<TaskDto>> {
    return createUserTask(this.http, this.rootUrl, params, context);
  }

  /**
   * Create task entry in database
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUserTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserTask(params: CreateUserTask$Params, context?: HttpContext): Observable<TaskDto> {
    return this.createUserTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaskDto>): TaskDto => r.body)
    );
  }

  /** Path part for operation `solveTask()` */
  static readonly SolveTaskPath = '/tasks/{id}/solve';

  /**
   * Solve an existing and open task
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `solveTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  solveTask$Response(params: SolveTask$Params, context?: HttpContext): Observable<StrictHttpResponse<TaskDto>> {
    return solveTask(this.http, this.rootUrl, params, context);
  }

  /**
   * Solve an existing and open task
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `solveTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  solveTask(params: SolveTask$Params, context?: HttpContext): Observable<TaskDto> {
    return this.solveTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaskDto>): TaskDto => r.body)
    );
  }

  /** Path part for operation `getMyTasks()` */
  static readonly GetMyTasksPath = '/tasks/my';

  /**
   * Get all tasks where the authenticated user is the editor
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMyTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyTasks$Response(params?: GetMyTasks$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaskDto>>> {
    return getMyTasks(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all tasks where the authenticated user is the editor
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMyTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyTasks(params?: GetMyTasks$Params, context?: HttpContext): Observable<Array<TaskDto>> {
    return this.getMyTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaskDto>>): Array<TaskDto> => r.body)
    );
  }

}
