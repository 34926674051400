/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createMessage } from '../fn/message-controller/create-message';
import { CreateMessage$Params } from '../fn/message-controller/create-message';
import { createMessageStaff } from '../fn/message-controller/create-message-staff';
import { CreateMessageStaff$Params } from '../fn/message-controller/create-message-staff';
import { getLatestPublicMessage } from '../fn/message-controller/get-latest-public-message';
import { GetLatestPublicMessage$Params } from '../fn/message-controller/get-latest-public-message';
import { getMessages } from '../fn/message-controller/get-messages';
import { GetMessages$Params } from '../fn/message-controller/get-messages';
import { getMessagesStaff } from '../fn/message-controller/get-messages-staff';
import { GetMessagesStaff$Params } from '../fn/message-controller/get-messages-staff';
import { Message } from '../models/message';
import { ResponseMessage } from '../models/response-message';
import { solveLatestPublicMessage } from '../fn/message-controller/solve-latest-public-message';
import { SolveLatestPublicMessage$Params } from '../fn/message-controller/solve-latest-public-message';
import { updateMessage } from '../fn/message-controller/update-message';
import { UpdateMessage$Params } from '../fn/message-controller/update-message';
import { updateMessageStaff } from '../fn/message-controller/update-message-staff';
import { UpdateMessageStaff$Params } from '../fn/message-controller/update-message-staff';

@Injectable({ providedIn: 'root' })
export class MessageControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateMessageStaff()` */
  static readonly UpdateMessageStaffPath = '/messagesStaff/{id}';

  /**
   * Updates staff message entries in database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMessageStaff()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMessageStaff$Response(params: UpdateMessageStaff$Params, context?: HttpContext): Observable<StrictHttpResponse<Message>> {
    return updateMessageStaff(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates staff message entries in database
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMessageStaff$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMessageStaff(params: UpdateMessageStaff$Params, context?: HttpContext): Observable<Message> {
    return this.updateMessageStaff$Response(params, context).pipe(
      map((r: StrictHttpResponse<Message>): Message => r.body)
    );
  }

  /** Path part for operation `updateMessage()` */
  static readonly UpdateMessagePath = '/messages/{id}';

  /**
   * Updates message entries in database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMessage$Response(params: UpdateMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<Message>> {
    return updateMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates message entries in database
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMessage(params: UpdateMessage$Params, context?: HttpContext): Observable<Message> {
    return this.updateMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Message>): Message => r.body)
    );
  }

  /** Path part for operation `getMessages()` */
  static readonly GetMessagesPath = '/messages';

  /**
   * Gets message entries in database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessages$Response(params?: GetMessages$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseMessage>> {
    return getMessages(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets message entries in database
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessages(params?: GetMessages$Params, context?: HttpContext): Observable<ResponseMessage> {
    return this.getMessages$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseMessage>): ResponseMessage => r.body)
    );
  }

  /** Path part for operation `createMessage()` */
  static readonly CreateMessagePath = '/messages';

  /**
   * Create message entry in database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMessage$Response(params: CreateMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<Message>> {
    return createMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Create message entry in database
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMessage(params: CreateMessage$Params, context?: HttpContext): Observable<Message> {
    return this.createMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Message>): Message => r.body)
    );
  }

  /** Path part for operation `getMessagesStaff()` */
  static readonly GetMessagesStaffPath = '/messagesStaff';

  /**
   * Get staff message entries in database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMessagesStaff()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessagesStaff$Response(params?: GetMessagesStaff$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseMessage>> {
    return getMessagesStaff(this.http, this.rootUrl, params, context);
  }

  /**
   * Get staff message entries in database
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMessagesStaff$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessagesStaff(params?: GetMessagesStaff$Params, context?: HttpContext): Observable<ResponseMessage> {
    return this.getMessagesStaff$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseMessage>): ResponseMessage => r.body)
    );
  }

  /** Path part for operation `createMessageStaff()` */
  static readonly CreateMessageStaffPath = '/messagesStaff';

  /**
   * Create staff message entry in database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMessageStaff()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMessageStaff$Response(params: CreateMessageStaff$Params, context?: HttpContext): Observable<StrictHttpResponse<Message>> {
    return createMessageStaff(this.http, this.rootUrl, params, context);
  }

  /**
   * Create staff message entry in database
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMessageStaff$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMessageStaff(params: CreateMessageStaff$Params, context?: HttpContext): Observable<Message> {
    return this.createMessageStaff$Response(params, context).pipe(
      map((r: StrictHttpResponse<Message>): Message => r.body)
    );
  }

  /** Path part for operation `solveLatestPublicMessage()` */
  static readonly SolveLatestPublicMessagePath = '/latestPublicMessage/solve';

  /**
   * Solve the latest public message
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `solveLatestPublicMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  solveLatestPublicMessage$Response(params?: SolveLatestPublicMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<Message>> {
    return solveLatestPublicMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Solve the latest public message
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `solveLatestPublicMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  solveLatestPublicMessage(params?: SolveLatestPublicMessage$Params, context?: HttpContext): Observable<Message> {
    return this.solveLatestPublicMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Message>): Message => r.body)
    );
  }

  /** Path part for operation `getLatestPublicMessage()` */
  static readonly GetLatestPublicMessagePath = '/public/latestPublicMessage';

  /**
   * Get public message entry in database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestPublicMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestPublicMessage$Response(params?: GetLatestPublicMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<Message>> {
    return getLatestPublicMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Get public message entry in database
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestPublicMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestPublicMessage(params?: GetLatestPublicMessage$Params, context?: HttpContext): Observable<Message> {
    return this.getLatestPublicMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Message>): Message => r.body)
    );
  }

}
