/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteProcessDocumentByFilenameHash } from '../fn/process-documents-controller/delete-process-document-by-filename-hash';
import { DeleteProcessDocumentByFilenameHash$Params } from '../fn/process-documents-controller/delete-process-document-by-filename-hash';
import { getProcessDocumentByFilenameHash } from '../fn/process-documents-controller/get-process-document-by-filename-hash';
import { GetProcessDocumentByFilenameHash$Params } from '../fn/process-documents-controller/get-process-document-by-filename-hash';
import { getProcessDocuments } from '../fn/process-documents-controller/get-process-documents';
import { GetProcessDocuments$Params } from '../fn/process-documents-controller/get-process-documents';
import { uploadFileToProcess } from '../fn/process-documents-controller/upload-file-to-process';
import { UploadFileToProcess$Params } from '../fn/process-documents-controller/upload-file-to-process';

@Injectable({ providedIn: 'root' })
export class ProcessDocumentsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getProcessDocuments()` */
  static readonly GetProcessDocumentsPath = '/processes/{processid}/documents';

  /**
   * get all documents related to a process, returning the filename and the download hash.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProcessDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessDocuments$Response(params: GetProcessDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {
    return getProcessDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * get all documents related to a process, returning the filename and the download hash.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProcessDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessDocuments(params: GetProcessDocuments$Params, context?: HttpContext): Observable<{
[key: string]: string;
}> {
    return this.getProcessDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>): {
[key: string]: string;
} => r.body)
    );
  }

  /** Path part for operation `uploadFileToProcess()` */
  static readonly UploadFileToProcessPath = '/processes/{processid}/documents';

  /**
   * uploading a document in the context of a process.  returning the encrypted path for download issues.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFileToProcess()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFileToProcess$Response(params: UploadFileToProcess$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return uploadFileToProcess(this.http, this.rootUrl, params, context);
  }

  /**
   * uploading a document in the context of a process.  returning the encrypted path for download issues.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadFileToProcess$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFileToProcess(params: UploadFileToProcess$Params, context?: HttpContext): Observable<string> {
    return this.uploadFileToProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getProcessDocumentByFilenameHash()` */
  static readonly GetProcessDocumentByFilenameHashPath = '/processes/documents/{documentHash}';

  /**
   * starts the download stream for a process related document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProcessDocumentByFilenameHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessDocumentByFilenameHash$Response(params: GetProcessDocumentByFilenameHash$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getProcessDocumentByFilenameHash(this.http, this.rootUrl, params, context);
  }

  /**
   * starts the download stream for a process related document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProcessDocumentByFilenameHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessDocumentByFilenameHash(params: GetProcessDocumentByFilenameHash$Params, context?: HttpContext): Observable<Blob> {
    return this.getProcessDocumentByFilenameHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `deleteProcessDocumentByFilenameHash()` */
  static readonly DeleteProcessDocumentByFilenameHashPath = '/processes/documents/{documentHash}';

  /**
   * deleting the process related document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteProcessDocumentByFilenameHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProcessDocumentByFilenameHash$Response(params: DeleteProcessDocumentByFilenameHash$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteProcessDocumentByFilenameHash(this.http, this.rootUrl, params, context);
  }

  /**
   * deleting the process related document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteProcessDocumentByFilenameHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProcessDocumentByFilenameHash(params: DeleteProcessDocumentByFilenameHash$Params, context?: HttpContext): Observable<void> {
    return this.deleteProcessDocumentByFilenameHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
