/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getSafetyDocuments } from '../fn/crefo-documents-controller/get-safety-documents';
import { GetSafetyDocuments$Params } from '../fn/crefo-documents-controller/get-safety-documents';
import { SafetyDocumentDto } from '../models/safety-document-dto';

@Injectable({ providedIn: 'root' })
export class CrefoDocumentsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSafetyDocuments()` */
  static readonly GetSafetyDocumentsPath = '/safeties/documents/{productType}';

  /**
   * return all safeties (crefo and r+v) documents for customer of the given product type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSafetyDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSafetyDocuments$Response(params: GetSafetyDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SafetyDocumentDto>>> {
    return getSafetyDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * return all safeties (crefo and r+v) documents for customer of the given product type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSafetyDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSafetyDocuments(params: GetSafetyDocuments$Params, context?: HttpContext): Observable<Array<SafetyDocumentDto>> {
    return this.getSafetyDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SafetyDocumentDto>>): Array<SafetyDocumentDto> => r.body)
    );
  }

}
