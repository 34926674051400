<div *svgFrontendsLet="formControl.statusChanges | async as status" class="mac-wrapper">
	<svg-frontends-loading *ngIf="status === FORM_STATUS.PENDING" [isSmall]="true" [withBackdrop]="true"></svg-frontends-loading>

	<div [ngClass]="{ 'clr-form-control-disabled': disableControl }" class="clr-form-control mac-form-control">
		<label *ngIf="label" [for]="id" class="clr-control-label">
			<svg-frontends-string-or-template-ref [value]="label"></svg-frontends-string-or-template-ref>
			<svg-frontends-required-marker *ngIf="formControl | isFormControlRequired"></svg-frontends-required-marker>
		</label>

		<div [ngClass]="{ 'clr-error': formControl.invalid && formControl.touched }" class="clr-control-container">
			<div class="clr-input-wrapper">
				<input
					[formControl]="formControl"
					[id]="id"
					[svgFrontendsDisableControl]="disableControl"
					[max]="max"
					[min]="min"
					[name]="id"
					[owlDateTimeTrigger]="dt"
					[owlDateTime]="dt"
					[placeholder]="placeholder"
					[rangeSeparator]="' - '"
					[selectMode]="range ? 'range' : 'single'"
					class="clr-input mac-input"
				/>
				<owl-date-time #dt [firstDayOfWeek]="1" [pickerType]="DATE_PICKER_TYPE.DATE_AND_TIME"></owl-date-time>
				<cds-icon *ngIf="formControl.invalid && formControl.touched" class="clr-validate-icon" shape="exclamation-circle"></cds-icon>
			</div>

			<div class="clr-subtext-wrapper mac-subtext-wrapper">
				<span *ngIf="helperText && !(formControl.invalid && formControl.touched)" class="clr-subtext">
					<svg-frontends-string-or-template-ref [value]="helperText"></svg-frontends-string-or-template-ref>
				</span>
				<span *ngIf="formControl.invalid && formControl.touched" class="clr-subtext">
					{{ formControl.errors | firstControlError | controlErrorMessage }}
				</span>
			</div>
		</div>
	</div>
</div>
