/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { changeRoleActivities } from '../fn/activity-roles-controller/change-role-activities';
import { ChangeRoleActivities$Params } from '../fn/activity-roles-controller/change-role-activities';
import { copyActivitiesFromOtherRole } from '../fn/activity-roles-controller/copy-activities-from-other-role';
import { CopyActivitiesFromOtherRole$Params } from '../fn/activity-roles-controller/copy-activities-from-other-role';
import { createRole } from '../fn/activity-roles-controller/create-role';
import { CreateRole$Params } from '../fn/activity-roles-controller/create-role';
import { deleteRole } from '../fn/activity-roles-controller/delete-role';
import { DeleteRole$Params } from '../fn/activity-roles-controller/delete-role';
import { exportRoles } from '../fn/activity-roles-controller/export-roles';
import { ExportRoles$Params } from '../fn/activity-roles-controller/export-roles';
import { exportRolesAsFile } from '../fn/activity-roles-controller/export-roles-as-file';
import { ExportRolesAsFile$Params } from '../fn/activity-roles-controller/export-roles-as-file';
import { getPaginatedRoles } from '../fn/activity-roles-controller/get-paginated-roles';
import { GetPaginatedRoles$Params } from '../fn/activity-roles-controller/get-paginated-roles';
import { getRole } from '../fn/activity-roles-controller/get-role';
import { GetRole$Params } from '../fn/activity-roles-controller/get-role';
import { getRoles } from '../fn/activity-roles-controller/get-roles';
import { GetRoles$Params } from '../fn/activity-roles-controller/get-roles';
import { getRoleWithActivityTree } from '../fn/activity-roles-controller/get-role-with-activity-tree';
import { GetRoleWithActivityTree$Params } from '../fn/activity-roles-controller/get-role-with-activity-tree';
import { importRoles } from '../fn/activity-roles-controller/import-roles';
import { ImportRoles$Params } from '../fn/activity-roles-controller/import-roles';
import { importRolesAsFile } from '../fn/activity-roles-controller/import-roles-as-file';
import { ImportRolesAsFile$Params } from '../fn/activity-roles-controller/import-roles-as-file';
import { ResponseRoleDto } from '../models/response-role-dto';
import { RoleDto } from '../models/role-dto';
import { RoleImportExportDto } from '../models/role-import-export-dto';
import { updateRole } from '../fn/activity-roles-controller/update-role';
import { UpdateRole$Params } from '../fn/activity-roles-controller/update-role';

@Injectable({ providedIn: 'root' })
export class ActivityRolesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRole()` */
  static readonly GetRolePath = '/activity-roles/{id}';

  /**
   * getting a role by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRole$Response(params: GetRole$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleDto>> {
    return getRole(this.http, this.rootUrl, params, context);
  }

  /**
   * getting a role by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRole(params: GetRole$Params, context?: HttpContext): Observable<RoleDto> {
    return this.getRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleDto>): RoleDto => r.body)
    );
  }

  /** Path part for operation `updateRole()` */
  static readonly UpdateRolePath = '/activity-roles/{id}';

  /**
   * updating the role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRole$Response(params: UpdateRole$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleDto>> {
    return updateRole(this.http, this.rootUrl, params, context);
  }

  /**
   * updating the role
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRole(params: UpdateRole$Params, context?: HttpContext): Observable<RoleDto> {
    return this.updateRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleDto>): RoleDto => r.body)
    );
  }

  /** Path part for operation `deleteRole()` */
  static readonly DeleteRolePath = '/activity-roles/{id}';

  /**
   * deleting a role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRole$Response(params: DeleteRole$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteRole(this.http, this.rootUrl, params, context);
  }

  /**
   * deleting a role
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRole(params: DeleteRole$Params, context?: HttpContext): Observable<void> {
    return this.deleteRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `changeRoleActivities()` */
  static readonly ChangeRoleActivitiesPath = '/activity-roles/{id}';

  /**
   * changing activities of role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeRoleActivities()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeRoleActivities$Response(params: ChangeRoleActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleDto>> {
    return changeRoleActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * changing activities of role
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeRoleActivities$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeRoleActivities(params: ChangeRoleActivities$Params, context?: HttpContext): Observable<RoleDto> {
    return this.changeRoleActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleDto>): RoleDto => r.body)
    );
  }

  /** Path part for operation `copyActivitiesFromOtherRole()` */
  static readonly CopyActivitiesFromOtherRolePath = '/activity-roles/{id}/copy/{otherId}';

  /**
   * copy and replace all activities from another role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyActivitiesFromOtherRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyActivitiesFromOtherRole$Response(params: CopyActivitiesFromOtherRole$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleDto>> {
    return copyActivitiesFromOtherRole(this.http, this.rootUrl, params, context);
  }

  /**
   * copy and replace all activities from another role
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `copyActivitiesFromOtherRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyActivitiesFromOtherRole(params: CopyActivitiesFromOtherRole$Params, context?: HttpContext): Observable<RoleDto> {
    return this.copyActivitiesFromOtherRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleDto>): RoleDto => r.body)
    );
  }

  /** Path part for operation `getPaginatedRoles()` */
  static readonly GetPaginatedRolesPath = '/activity-roles';

  /**
   * getting all roles with pagination
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaginatedRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaginatedRoles$Response(params?: GetPaginatedRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseRoleDto>> {
    return getPaginatedRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * getting all roles with pagination
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaginatedRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaginatedRoles(params?: GetPaginatedRoles$Params, context?: HttpContext): Observable<ResponseRoleDto> {
    return this.getPaginatedRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseRoleDto>): ResponseRoleDto => r.body)
    );
  }

  /** Path part for operation `createRole()` */
  static readonly CreateRolePath = '/activity-roles';

  /**
   * creating a new role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRole$Response(params: CreateRole$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleDto>> {
    return createRole(this.http, this.rootUrl, params, context);
  }

  /**
   * creating a new role
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRole(params: CreateRole$Params, context?: HttpContext): Observable<RoleDto> {
    return this.createRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleDto>): RoleDto => r.body)
    );
  }

  /** Path part for operation `importRoles()` */
  static readonly ImportRolesPath = '/activity-roles/import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importRoles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importRoles$Response(params: ImportRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RoleImportExportDto>>> {
    return importRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importRoles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importRoles(params: ImportRoles$Params, context?: HttpContext): Observable<Array<RoleImportExportDto>> {
    return this.importRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RoleImportExportDto>>): Array<RoleImportExportDto> => r.body)
    );
  }

  /** Path part for operation `importRolesAsFile()` */
  static readonly ImportRolesAsFilePath = '/activity-roles/importFile';

  /**
   * Import roles from a given json file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importRolesAsFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importRolesAsFile$Response(params?: ImportRolesAsFile$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RoleImportExportDto>>> {
    return importRolesAsFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Import roles from a given json file
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importRolesAsFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importRolesAsFile(params?: ImportRolesAsFile$Params, context?: HttpContext): Observable<Array<RoleImportExportDto>> {
    return this.importRolesAsFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RoleImportExportDto>>): Array<RoleImportExportDto> => r.body)
    );
  }

  /** Path part for operation `getRoleWithActivityTree()` */
  static readonly GetRoleWithActivityTreePath = '/activity-roles/{id}/withActivityTree';

  /**
   * getting a role by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoleWithActivityTree()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoleWithActivityTree$Response(params: GetRoleWithActivityTree$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleDto>> {
    return getRoleWithActivityTree(this.http, this.rootUrl, params, context);
  }

  /**
   * getting a role by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoleWithActivityTree$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoleWithActivityTree(params: GetRoleWithActivityTree$Params, context?: HttpContext): Observable<RoleDto> {
    return this.getRoleWithActivityTree$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleDto>): RoleDto => r.body)
    );
  }

  /** Path part for operation `exportRoles()` */
  static readonly ExportRolesPath = '/activity-roles/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportRoles$Response(params?: ExportRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RoleImportExportDto>>> {
    return exportRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportRoles(params?: ExportRoles$Params, context?: HttpContext): Observable<Array<RoleImportExportDto>> {
    return this.exportRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RoleImportExportDto>>): Array<RoleImportExportDto> => r.body)
    );
  }

  /** Path part for operation `exportRolesAsFile()` */
  static readonly ExportRolesAsFilePath = '/activity-roles/exportFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportRolesAsFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportRolesAsFile$Response(params?: ExportRolesAsFile$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportRolesAsFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportRolesAsFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportRolesAsFile(params?: ExportRolesAsFile$Params, context?: HttpContext): Observable<Blob> {
    return this.exportRolesAsFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `getRoles()` */
  static readonly GetRolesPath = '/activity-roles/as-list';

  /**
   * getting all roles as plain list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles$Response(params?: GetRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RoleDto>>> {
    return getRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * getting all roles as plain list
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles(params?: GetRoles$Params, context?: HttpContext): Observable<Array<RoleDto>> {
    return this.getRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RoleDto>>): Array<RoleDto> => r.body)
    );
  }

}
