import { Injectable } from '@angular/core';
import dayjs from 'dayjs';

import { ProcessActionResponseDtoObject, SimpleProcessDto } from '@mysvg/api/bpm';
import { UtilsService } from '@mysvg/utils';
import { GtagEventParams } from '../models/gtag-event-params.model';

import { GtagPurchaseItem } from '../models/gtag-purchase-items.model';
import { GoogleAnalyticsService } from './google-analytics.service';
import { Vignette } from '@mysvg/api/vignette';

@Injectable({ providedIn: 'root' })
export class CustomGoogleAnalyticsEventsHelperService {
	constructor(private googleAnalyticsService: GoogleAnalyticsService) {}

	triggerEVignetteBooked(vignette: Vignette): void {
		const from = dayjs(vignette.validFrom).format('DD.MM.YYYY');
		const to = dayjs(vignette.validTo).format('DD.MM.YYYY');

		const item: GtagPurchaseItem = {
			category: 'E-Vignette',
			id: vignette.licensePlateNumber,
			name: `${vignette.licensePlateNumber} (${vignette.axleClassId}, ${vignette.emissionClassId})`,
			price: vignette.price?.toString(),
			variant: from === to ? from : `${from} - ${to}`,
		};

		this.googleAnalyticsService.sendPurchaseEvent(vignette.price, [item], vignette.bookingRequestUuid, 'E-Vignette');
	}

	triggerEVignetteFailed(vignette: Vignette): void {
		const googleTagManagerParams: GtagEventParams = {
			event_category: 'E-Vignette',
			event_label: 'E-Vignette failed: ' + UtilsService.stringifyVignette(vignette),
		};

		this.googleAnalyticsService.sendEvent('booking failed', googleTagManagerParams);
	}

	triggerTaskFinishedEvent(action: string, processActionResponseDtoObject: ProcessActionResponseDtoObject): void {
		if (processActionResponseDtoObject.actionResponse.status === 'OK') {
			this.googleAnalyticsService.sendEvent(action, {
				event_category: `${processActionResponseDtoObject.process.category} (${processActionResponseDtoObject.process.subcategory})`,
				event_label: processActionResponseDtoObject.process.name
					? processActionResponseDtoObject.process.name
					: processActionResponseDtoObject.process.category,
			});
		}
	}

	triggerFinishedWizardWithOptionalProcess(simpleProcessDto: SimpleProcessDto): void {
		this.googleAnalyticsService.sendEvent('startedProcess', {
			event_category: `${simpleProcessDto.category} (${simpleProcessDto.subcategory})`,
			event_label: simpleProcessDto.name ? simpleProcessDto.name : simpleProcessDto.category,
		});
	}
}
