/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { booking } from '../fn/vignette-controller/booking';
import { Booking$Params } from '../fn/vignette-controller/booking';
import { BookingResponse } from '../models/booking-response';
import { canceling } from '../fn/vignette-controller/canceling';
import { Canceling$Params } from '../fn/vignette-controller/canceling';
import { count } from '../fn/vignette-controller/count';
import { Count$Params } from '../fn/vignette-controller/count';
import { getBookingResponse } from '../fn/vignette-controller/get-booking-response';
import { GetBookingResponse$Params } from '../fn/vignette-controller/get-booking-response';
import { getLicensePlateCountryCodes } from '../fn/vignette-controller/get-license-plate-country-codes';
import { GetLicensePlateCountryCodes$Params } from '../fn/vignette-controller/get-license-plate-country-codes';
import { getVignetteDetail } from '../fn/vignette-controller/get-vignette-detail';
import { GetVignetteDetail$Params } from '../fn/vignette-controller/get-vignette-detail';
import { orders } from '../fn/vignette-controller/orders';
import { Orders$Params } from '../fn/vignette-controller/orders';
import { ResponseOrderResult } from '../models/response-order-result';
import { ResponseResponseType } from '../models/response-response-type';
import { ResponseVignette } from '../models/response-vignette';
import { ResponseVignetteDetailView } from '../models/response-vignette-detail-view';
import { resubmission } from '../fn/vignette-controller/resubmission';
import { Resubmission$Params } from '../fn/vignette-controller/resubmission';
import { Vignette } from '../models/vignette';
import { vignettes } from '../fn/vignette-controller/vignettes';
import { Vignettes$Params } from '../fn/vignette-controller/vignettes';
import { vignettesforperiod } from '../fn/vignette-controller/vignettesforperiod';
import { Vignettesforperiod$Params } from '../fn/vignette-controller/vignettesforperiod';
import { vignetteUpdate } from '../fn/vignette-controller/vignette-update';
import { VignetteUpdate$Params } from '../fn/vignette-controller/vignette-update';

@Injectable({ providedIn: 'root' })
export class VignetteControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getVignetteDetail()` */
  static readonly GetVignetteDetailPath = '/vignette/{id}';

  /**
   * Get a single vignette with the given id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVignetteDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVignetteDetail$Response(params: GetVignetteDetail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseVignetteDetailView>> {
    return getVignetteDetail(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a single vignette with the given id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVignetteDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVignetteDetail(params: GetVignetteDetail$Params, context?: HttpContext): Observable<ResponseVignetteDetailView> {
    return this.getVignetteDetail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseVignetteDetailView>): ResponseVignetteDetailView => r.body)
    );
  }

  /** Path part for operation `vignetteUpdate()` */
  static readonly VignetteUpdatePath = '/vignette/{id}';

  /**
   * Update a single vignette with the given id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vignetteUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vignetteUpdate$Response(params: VignetteUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return vignetteUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a single vignette with the given id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vignetteUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vignetteUpdate(params: VignetteUpdate$Params, context?: HttpContext): Observable<number> {
    return this.vignetteUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `vignettesforperiod()` */
  static readonly VignettesforperiodPath = '/vignettesforperiod';

  /**
   * Get best choice of vignettes for a given period.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vignettesforperiod()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vignettesforperiod$Response(params: Vignettesforperiod$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: Vignette;
}>> {
    return vignettesforperiod(this.http, this.rootUrl, params, context);
  }

  /**
   * Get best choice of vignettes for a given period.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vignettesforperiod$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vignettesforperiod(params: Vignettesforperiod$Params, context?: HttpContext): Observable<{
[key: string]: Vignette;
}> {
    return this.vignettesforperiod$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: Vignette;
}>): {
[key: string]: Vignette;
} => r.body)
    );
  }

  /** Path part for operation `vignettes()` */
  static readonly VignettesPath = '/vignetteforlicenseplatenumbers';

  /**
   * Get a list of all vignettes.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vignettes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vignettes$Response(params: Vignettes$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseVignette>> {
    return vignettes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of all vignettes.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vignettes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vignettes(params: Vignettes$Params, context?: HttpContext): Observable<ResponseVignette> {
    return this.vignettes$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseVignette>): ResponseVignette => r.body)
    );
  }

  /** Path part for operation `canceling()` */
  static readonly CancelingPath = '/canceling';

  /**
   * Make service call to cancel an ordered vignette
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `canceling()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  canceling$Response(params: Canceling$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseResponseType>> {
    return canceling(this.http, this.rootUrl, params, context);
  }

  /**
   * Make service call to cancel an ordered vignette
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `canceling$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  canceling(params: Canceling$Params, context?: HttpContext): Observable<ResponseResponseType> {
    return this.canceling$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseResponseType>): ResponseResponseType => r.body)
    );
  }

  /** Path part for operation `booking()` */
  static readonly BookingPath = '/booking';

  /**
   * Make service call to book a vignette
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `booking()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  booking$Response(params: Booking$Params, context?: HttpContext): Observable<StrictHttpResponse<BookingResponse>> {
    return booking(this.http, this.rootUrl, params, context);
  }

  /**
   * Make service call to book a vignette
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `booking$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  booking(params: Booking$Params, context?: HttpContext): Observable<BookingResponse> {
    return this.booking$Response(params, context).pipe(
      map((r: StrictHttpResponse<BookingResponse>): BookingResponse => r.body)
    );
  }

  /** Path part for operation `getBookingResponse()` */
  static readonly GetBookingResponsePath = '/booking/status';

  /**
   * Get the booking status for the given uuids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingResponse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBookingResponse$Response(params: GetBookingResponse$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseVignette>> {
    return getBookingResponse(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the booking status for the given uuids
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBookingResponse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBookingResponse(params: GetBookingResponse$Params, context?: HttpContext): Observable<ResponseVignette> {
    return this.getBookingResponse$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseVignette>): ResponseVignette => r.body)
    );
  }

  /** Path part for operation `resubmission()` */
  static readonly ResubmissionPath = '/resubmission';

  /**
   * Do resubmission process for all vignettes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resubmission()` instead.
   *
   * This method doesn't expect any request body.
   */
  resubmission$Response(params?: Resubmission$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resubmission(this.http, this.rootUrl, params, context);
  }

  /**
   * Do resubmission process for all vignettes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resubmission$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resubmission(params?: Resubmission$Params, context?: HttpContext): Observable<void> {
    return this.resubmission$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `orders()` */
  static readonly OrdersPath = '/orders';

  /**
   * Get all orders vignettes from a given client.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orders()` instead.
   *
   * This method doesn't expect any request body.
   */
  orders$Response(params: Orders$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseOrderResult>> {
    return orders(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all orders vignettes from a given client.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orders(params: Orders$Params, context?: HttpContext): Observable<ResponseOrderResult> {
    return this.orders$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseOrderResult>): ResponseOrderResult => r.body)
    );
  }

  /** Path part for operation `getLicensePlateCountryCodes()` */
  static readonly GetLicensePlateCountryCodesPath = '/licenseplatecodes';

  /**
   * Returns the list of all mappable license plate country codes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLicensePlateCountryCodes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicensePlateCountryCodes$Response(params?: GetLicensePlateCountryCodes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<'A' | 'B' | 'BG' | 'BY' | 'CH' | 'CZ' | 'D' | 'DK' | 'E' | 'F' | 'GB' | 'HR' | 'H' | 'IRL' | 'I' | 'KZ' | 'FL' | 'LT' | 'L' | 'LV' | 'NL' | 'PL' | 'P' | 'RO' | 'RUS' | 'SLO' | 'SK' | 'TR' | 'UA'>>> {
    return getLicensePlateCountryCodes(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the list of all mappable license plate country codes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLicensePlateCountryCodes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicensePlateCountryCodes(params?: GetLicensePlateCountryCodes$Params, context?: HttpContext): Observable<Array<'A' | 'B' | 'BG' | 'BY' | 'CH' | 'CZ' | 'D' | 'DK' | 'E' | 'F' | 'GB' | 'HR' | 'H' | 'IRL' | 'I' | 'KZ' | 'FL' | 'LT' | 'L' | 'LV' | 'NL' | 'PL' | 'P' | 'RO' | 'RUS' | 'SLO' | 'SK' | 'TR' | 'UA'>> {
    return this.getLicensePlateCountryCodes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<'A' | 'B' | 'BG' | 'BY' | 'CH' | 'CZ' | 'D' | 'DK' | 'E' | 'F' | 'GB' | 'HR' | 'H' | 'IRL' | 'I' | 'KZ' | 'FL' | 'LT' | 'L' | 'LV' | 'NL' | 'PL' | 'P' | 'RO' | 'RUS' | 'SLO' | 'SK' | 'TR' | 'UA'>>): Array<'A' | 'B' | 'BG' | 'BY' | 'CH' | 'CZ' | 'D' | 'DK' | 'E' | 'F' | 'GB' | 'HR' | 'H' | 'IRL' | 'I' | 'KZ' | 'FL' | 'LT' | 'L' | 'LV' | 'NL' | 'PL' | 'P' | 'RO' | 'RUS' | 'SLO' | 'SK' | 'TR' | 'UA'> => r.body)
    );
  }

  /** Path part for operation `count()` */
  static readonly CountPath = '/count';

  /**
   * Get all active vignettes for a given customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params: Count$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return count(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all active vignettes for a given customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params: Count$Params, context?: HttpContext): Observable<string> {
    return this.count$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
