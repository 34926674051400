import { LabeledEnumModel } from '../../models/enum/labeledEnum.model';
import { Colors } from '../colors.enum';

export const MsgCategoriesEnum: LabeledEnumModel = {
	ACTIVATE: { label: 'Aktivieren' },
	ALL: { label: 'Alle' },
	ASI: { label: 'Arbeitssicherheit' },
	CREATE: { label: 'Erstellen' },
	CUSTOMER: { label: 'Kunde' },
	EETS_ORDER: { label: 'Hochladen' },
	FUELCARD: { label: 'Tankkarte' },
	GUARANTEES: { label: 'Sicherheiten' },
	MAINTENANCE: { label: 'Wartungsarbeiten', color: Colors.MAINTENANCE },
	MASTERDATA: { label: 'Stammdaten' },
	REGISTRATION: { label: 'Registrierung' },
	RV: { label: 'R+V' },
	TOLL: { label: 'Maut' },
	TOLLBOX: { label: 'Mautbox' },
	UPDATE_E_INVOICING: { label: 'Zahlungsmodalitäten ändern' },
	UPDATE_SEPA_MANDAT: { label: 'SEPA Mandat' },
	UPDATE_VEHICLE: { label: 'Fahrzeug' },
	VALIDATE: { label: 'Validieren' },
	VEHICLE: { label: 'Fahrzeug' },
};
