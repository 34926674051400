import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({ name: 'backendDateFormat' })
export class BackendDateFormatPipe implements PipeTransform {
	/**
	 * transform 'YYYY-MM-DD' string date to German 'DD.MM.YYYY' format
	 */
	transform(value: string): string {
		return value ? dayjs(value).format('DD.MM.YYYY') : value;
	}
}
