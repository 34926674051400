/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllReleaseNotes } from '../fn/release-notes-controller/get-all-release-notes';
import { GetAllReleaseNotes$Params } from '../fn/release-notes-controller/get-all-release-notes';
import { getAllReleaseNotesByApplication } from '../fn/release-notes-controller/get-all-release-notes-by-application';
import { GetAllReleaseNotesByApplication$Params } from '../fn/release-notes-controller/get-all-release-notes-by-application';
import { ReleaseNotesDto } from '../models/release-notes-dto';


/**
 * Manages the release notes
 */
@Injectable({ providedIn: 'root' })
export class ReleaseNotesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllReleaseNotes()` */
  static readonly GetAllReleaseNotesPath = '/release-notes';

  /**
   * Get all release notes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllReleaseNotes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllReleaseNotes$Response(params?: GetAllReleaseNotes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReleaseNotesDto>>> {
    return getAllReleaseNotes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all release notes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllReleaseNotes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllReleaseNotes(params?: GetAllReleaseNotes$Params, context?: HttpContext): Observable<Array<ReleaseNotesDto>> {
    return this.getAllReleaseNotes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReleaseNotesDto>>): Array<ReleaseNotesDto> => r.body)
    );
  }

  /** Path part for operation `getAllReleaseNotesByApplication()` */
  static readonly GetAllReleaseNotesByApplicationPath = '/release-notes/{application}';

  /**
   * Get all release notes by application
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllReleaseNotesByApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllReleaseNotesByApplication$Response(params: GetAllReleaseNotesByApplication$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReleaseNotesDto>>> {
    return getAllReleaseNotesByApplication(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all release notes by application
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllReleaseNotesByApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllReleaseNotesByApplication(params: GetAllReleaseNotesByApplication$Params, context?: HttpContext): Observable<Array<ReleaseNotesDto>> {
    return this.getAllReleaseNotesByApplication$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReleaseNotesDto>>): Array<ReleaseNotesDto> => r.body)
    );
  }

}
