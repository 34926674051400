/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { PaymentModalitiesControllerService } from './services/payment-modalities-controller.service';
import { PartialPaymentControllerService } from './services/partial-payment-controller.service';
import { DebitingDetailsControllerService } from './services/debiting-details-controller.service';
import { MySvgControllerService } from './services/my-svg-controller.service';
import { ProcessingGroupControllerService } from './services/processing-group-controller.service';
import { PayrollCycleControllerService } from './services/payroll-cycle-controller.service';
import { InvoiceShippingTypeControllerService } from './services/invoice-shipping-type-controller.service';
import { GroupingTypeControllerService } from './services/grouping-type-controller.service';
import { FibuControllerService } from './services/fibu-controller.service';
import { EInvoicingStatusControllerService } from './services/e-invoicing-status-controller.service';
import { DkvEInvoircingControllerService } from './services/dkv-e-invoircing-controller.service';
import { CustomerTypeControllerService } from './services/customer-type-controller.service';
import { BillingTypeControllerService } from './services/billing-type-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    PaymentModalitiesControllerService,
    PartialPaymentControllerService,
    DebitingDetailsControllerService,
    MySvgControllerService,
    ProcessingGroupControllerService,
    PayrollCycleControllerService,
    InvoiceShippingTypeControllerService,
    GroupingTypeControllerService,
    FibuControllerService,
    EInvoicingStatusControllerService,
    DkvEInvoircingControllerService,
    CustomerTypeControllerService,
    BillingTypeControllerService,
    ApiConfiguration
  ],
})
export class PaymentModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<PaymentModule> {
    return {
      ngModule: PaymentModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: PaymentModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('PaymentModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
