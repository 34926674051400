import { Injectable } from '@angular/core';
import { ContactPersonRoleDto, RoleControllerService } from '@mysvg/api/contact-data';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InternalRolesStoreService extends RepositoryStoreService<ContactPersonRoleDto[]> {
	constructor(private roleControllerService: RoleControllerService) {
		super();
	}

	fetchData(): Observable<ContactPersonRoleDto[]> {
		return this.roleControllerService.listRolesInternal();
	}
}
