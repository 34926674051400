import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClrDatagrid, ClrDatagridColumn, ClrDatagridStateInterface } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, tap } from 'rxjs/operators';

import { ResponseMeta } from '@mysvg/api/auth';

import { LoadDataConfiguration, Resp, isDefined } from '@mysvg/utils';
import { ErrorHandlingService } from '@svg-frontends/error';

import { CustomFilter } from '../interfaces/custom-filter.interface';
import { LoadData, TableViewHttpService } from './table-view-http.service';
import { TableViewOptionsService } from './table-view-options.service';

@UntilDestroy()
@Injectable()
export class TableViewService<Data, FilterData> {
	data$: BehaviorSubject<Data[]> = new BehaviorSubject<Data[]>(null);
	meta$: BehaviorSubject<ResponseMeta> = new BehaviorSubject<ResponseMeta>({});

	hasFilters$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	refresh$: BehaviorSubject<ClrDatagridStateInterface> = new BehaviorSubject<ClrDatagridStateInterface>(undefined);

	loadingFromServerTrigger$: Subject<null> = new Subject<null>();

	private customFilters: CustomFilter<any>[] = [];
	private dataGrid: ClrDatagrid;
	private loadData: LoadData<Data, FilterData>;
	private loadDataDebounceTime: number;

	constructor(
		private errorHandlingService: ErrorHandlingService,
		private tableViewHttpParamsService: TableViewHttpService<FilterData>,
		private tableViewOptionsService: TableViewOptionsService,
	) {}

	initialize(dataGrid: ClrDatagrid, loadData: LoadData<Data, FilterData>, loadDataDebounceTime: number = 250): void {
		this.dataGrid = dataGrid;
		this.loadData = loadData;
		this.loadDataDebounceTime = loadDataDebounceTime;
		this.listenOnRefreshUsingSwitchMap();

		// check on every table initialization
		// if localstorage contains old table columns in storage for hidden state of columns
		this.tableViewOptionsService.removeUnusedColumnsFromHiddenStorage(this.getColumnFieldNames());
	}

	getLoadingFromServerTrigger(): Observable<any> {
		return this.loadingFromServerTrigger$.asObservable();
	}

	// Only registered custom filters are reset, when all-reset is triggered. This is enforced, because there is no other way to get a handle
	// of all custom filter instances.
	registerCustomFilter(customFilter: CustomFilter<any>): void {
		this.deRegisterCustomFilter(customFilter);
		this.customFilters = this.customFilters.concat(customFilter);
	}

	deRegisterCustomFilter(deRegisteredCustomFilter: CustomFilter<any>): void {
		this.customFilters = this.customFilters.filter(
			(customFilter: CustomFilter<any>) => customFilter.property !== deRegisteredCustomFilter.property,
		);
	}

	refresh(state: ClrDatagridStateInterface): void {
		this.refresh$.next(state);
	}

	simpleRefresh(): void {
		this.refresh$.next(this.refresh$.getValue());
	}

	resetAllFilters(): void {
		// Reset all custom filters
		this.customFilters.forEach((customFilter: CustomFilter<any>) => customFilter.resetGlobal());

		// Reset all built-in filters
		this.dataGrid.columns
			.filter((column: ClrDatagridColumn) => !column.customFilter)
			.forEach((column: ClrDatagridColumn) => {
				column.filterValue = null;
				// access private changeDetectorRef var to force onPush re render
				(column as any).changeDetectorRef.markForCheck();
			});

		// Trigger refresh to fetch data
		this.simpleRefresh();
	}

	private getColumnFieldNames(): string[] {
		return this.dataGrid.columns.toArray().map((i) => i.field);
	}

	private listenOnRefreshUsingSwitchMap(): void {
		this.refresh$
			.asObservable()
			.pipe(
				filter((state: ClrDatagridStateInterface) => !!state),
				debounceTime(this.loadDataDebounceTime),
				switchMap((state: ClrDatagridStateInterface) => this.loadFromServer(state)),
				untilDestroyed(this),
			)
			.subscribe({
				next: (resp: Resp<Data>) => this.setData(resp),
				error: () => this.setData(null),
			});
	}

	private loadFromServer(state: ClrDatagridStateInterface): Observable<Resp<Data>> {
		this.loadingFromServerTrigger$.next(null);
		this.loading$.next(true);

		return this.tableViewHttpParamsService.getLoadDataConfiguration(state).pipe(
			tap((configuration: LoadDataConfiguration<FilterData>) => this.updateHasFilters(configuration)),
			switchMap((configuration: LoadDataConfiguration<FilterData>) => this.loadData(configuration)),
			catchError((error: HttpErrorResponse) => {
				this.errorHandlingService.setNextErrorBy(error);
				return of({});
			}),
		);
	}

	private updateHasFilters(configuration: LoadDataConfiguration<FilterData>): void {
		this.hasFilters$.next(isDefined(configuration.filters) && Object.entries(configuration.filters).length > 0);
	}

	private setData(data: Resp<Data> | null): void {
		this.data$.next(data ? data.data : []);
		this.meta$.next(data ? data.meta : {});
		this.loading$.next(false);

		this.dataGrid.dataChanged();
	}
}
