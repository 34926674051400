/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BoxOverviewViewResponse } from '../models/box-overview-view-response';
import { getBoxes } from '../fn/box-overview/get-boxes';
import { GetBoxes$Params } from '../fn/box-overview/get-boxes';
import { getBoxesForCustomer } from '../fn/box-overview/get-boxes-for-customer';
import { GetBoxesForCustomer$Params } from '../fn/box-overview/get-boxes-for-customer';


/**
 * Boxes owerview
 */
@Injectable({ providedIn: 'root' })
export class BoxOverviewService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getBoxes()` */
  static readonly GetBoxesPath = '/staff/boxes/overview';

  /**
   * Returns a list of all boxes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBoxes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoxes$Response(params?: GetBoxes$Params, context?: HttpContext): Observable<StrictHttpResponse<BoxOverviewViewResponse>> {
    return getBoxes(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a list of all boxes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBoxes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoxes(params?: GetBoxes$Params, context?: HttpContext): Observable<BoxOverviewViewResponse> {
    return this.getBoxes$Response(params, context).pipe(
      map((r: StrictHttpResponse<BoxOverviewViewResponse>): BoxOverviewViewResponse => r.body)
    );
  }

  /** Path part for operation `getBoxesForCustomer()` */
  static readonly GetBoxesForCustomerPath = '/boxes/overview';

  /**
   * Returns a list of all boxes for one customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBoxesForCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoxesForCustomer$Response(params: GetBoxesForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<BoxOverviewViewResponse>> {
    return getBoxesForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a list of all boxes for one customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBoxesForCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoxesForCustomer(params: GetBoxesForCustomer$Params, context?: HttpContext): Observable<BoxOverviewViewResponse> {
    return this.getBoxesForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<BoxOverviewViewResponse>): BoxOverviewViewResponse => r.body)
    );
  }

}
