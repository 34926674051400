import { Injectable } from '@angular/core';
import { TaskDto } from '@mysvg/api/bpm';
import { MessageControllerService } from '@mysvg/api/messaging';
import { RepositoryStoreService } from '../repository-store.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LatestPublicMessageStoreService extends RepositoryStoreService<TaskDto | null> {
	constructor(private messageControllerService: MessageControllerService) {
		super();
	}

	fetchData(): Observable<TaskDto | null> {
		return this.messageControllerService.getLatestPublicMessage().pipe(catchError(() => of(null)));
	}
}
