import { Injectable } from '@angular/core';
import { InvoiceShippingTypeControllerService, InvoiceShippingTypeDto, ResponseInvoiceShippingTypeDto } from '@mysvg/api/payment';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InvoiceShippingTypesStoreService extends RepositoryStoreService<InvoiceShippingTypeDto[]> {
	constructor(private invoiceShippingTypesService: InvoiceShippingTypeControllerService) {
		super();
	}

	fetchData(): Observable<InvoiceShippingTypeDto[]> {
		return this.invoiceShippingTypesService
			.getAllInvoiceShippingTypes()
			.pipe(map((response: ResponseInvoiceShippingTypeDto) => response.data));
	}
}
