/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllProcessDefinitions } from '../fn/process-definition-controller/get-all-process-definitions';
import { GetAllProcessDefinitions$Params } from '../fn/process-definition-controller/get-all-process-definitions';
import { getProcessDefinition } from '../fn/process-definition-controller/get-process-definition';
import { GetProcessDefinition$Params } from '../fn/process-definition-controller/get-process-definition';
import { ProcessDefinition } from '../models/process-definition';

@Injectable({ providedIn: 'root' })
export class ProcessDefinitionControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllProcessDefinitions()` */
  static readonly GetAllProcessDefinitionsPath = '/processes/definitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllProcessDefinitions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllProcessDefinitions$Response(params?: GetAllProcessDefinitions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getAllProcessDefinitions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllProcessDefinitions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllProcessDefinitions(params?: GetAllProcessDefinitions$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getAllProcessDefinitions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getProcessDefinition()` */
  static readonly GetProcessDefinitionPath = '/processes/definitions/{definitionName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProcessDefinition()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessDefinition$Response(params: GetProcessDefinition$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessDefinition>> {
    return getProcessDefinition(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProcessDefinition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessDefinition(params: GetProcessDefinition$Params, context?: HttpContext): Observable<ProcessDefinition> {
    return this.getProcessDefinition$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessDefinition>): ProcessDefinition => r.body)
    );
  }

}
