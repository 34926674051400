import { Injectable } from '@angular/core';
import { GuaranteesControllerService, GuaranteesSummaryDto } from '@mysvg/api/financial-guarantees';
import { SvgContextService } from '@svg-frontends/session';
import { RepositoryStoreService } from '../repository-store.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GuaranteeSummaryStoreService extends RepositoryStoreService<GuaranteesSummaryDto> {
	constructor(private guaranteesControllerService: GuaranteesControllerService, private svgContextService: SvgContextService) {
		super();
	}

	fetchData(): Observable<GuaranteesSummaryDto> {
		return this.svgContextService
			.getFirstPortalUid()
			.pipe(mergeMap((portalUid: string) => this.guaranteesControllerService.getGuaranteesSummary({ portalUid })));
	}
}
