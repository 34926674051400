import { Injectable } from '@angular/core';
import { BankDetailsType, BankDetailsTypeControllerService, ResponseBankDetailsType } from '@mysvg/api/account-master-data';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BankDetailsTypesStoreService extends RepositoryStoreService<BankDetailsType[]> {
	constructor(private bankDetailsTypeControllerService: BankDetailsTypeControllerService) {
		super();
	}

	fetchData(): Observable<BankDetailsType[]> {
		return this.bankDetailsTypeControllerService.getAllBankDetailsTypes().pipe(map((response: ResponseBankDetailsType) => response.data));
	}
}
