/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseMinimalVehicle } from '../../models/response-minimal-vehicle';

export interface GetMinimalVehicles$Params {

/**
 * customers portalUid.
 */
  customers: string;

/**
 * the search string. searches license plates and description fields
 */
  search?: string;

/**
 * restrict results to vehicle type 'truck'? - default: false
 */
  truckUnitsOnly: boolean;

/**
 * Box type id that is disallowed
 */
  boxTypeId?: number;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getMinimalVehicles(http: HttpClient, rootUrl: string, params: GetMinimalVehicles$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseMinimalVehicle>> {
  const rb = new RequestBuilder(rootUrl, getMinimalVehicles.PATH, 'get');
  if (params) {
    rb.query('customers', params.customers, {});
    rb.query('search', params.search, {});
    rb.query('truckUnitsOnly', params.truckUnitsOnly, {});
    rb.query('boxTypeId', params.boxTypeId, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseMinimalVehicle>;
    })
  );
}

getMinimalVehicles.PATH = '/vehicles/minimal';
