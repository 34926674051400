/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface SendCommercialPrivateOrder$Params {
  portalUid: string;
  firstName: string;
  lastName: string;
  country: string;
  postCode: string;
  city: string;
  street?: string;
  houseNumber?: number;
  houseNumberAffix?: string;
  orderSpecifyingText?: string;
}

export function sendCommercialPrivateOrder(http: HttpClient, rootUrl: string, params: SendCommercialPrivateOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
  const rb = new RequestBuilder(rootUrl, sendCommercialPrivateOrder.PATH, 'post');
  if (params) {
    rb.query('portalUid', params.portalUid, {});
    rb.query('firstName', params.firstName, {});
    rb.query('lastName', params.lastName, {});
    rb.query('country', params.country, {});
    rb.query('postCode', params.postCode, {});
    rb.query('city', params.city, {});
    rb.query('street', params.street, {});
    rb.query('houseNumber', params.houseNumber, {});
    rb.query('houseNumberAffix', params.houseNumberAffix, {});
    rb.query('orderSpecifyingText', params.orderSpecifyingText, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
    })
  );
}

sendCommercialPrivateOrder.PATH = '/crefo/order/commercial/private';
