/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteSvgInvoiceParams } from '../fn/tc-invoice-params/delete-svg-invoice-params';
import { DeleteSvgInvoiceParams$Params } from '../fn/tc-invoice-params/delete-svg-invoice-params';
import { getAllInvoiceParams } from '../fn/tc-invoice-params/get-all-invoice-params';
import { GetAllInvoiceParams$Params } from '../fn/tc-invoice-params/get-all-invoice-params';
import { getInvoiceParamsById } from '../fn/tc-invoice-params/get-invoice-params-by-id';
import { GetInvoiceParamsById$Params } from '../fn/tc-invoice-params/get-invoice-params-by-id';
import { TcInvoiceParamsDto } from '../models/tc-invoice-params-dto';
import { upsertSvgInvoiceParams } from '../fn/tc-invoice-params/upsert-svg-invoice-params';
import { UpsertSvgInvoiceParams$Params } from '../fn/tc-invoice-params/upsert-svg-invoice-params';

@Injectable({ providedIn: 'root' })
export class TcInvoiceParamsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllInvoiceParams()` */
  static readonly GetAllInvoiceParamsPath = '/invoice-params';

  /**
   * Get business sector related invoice params of all or queried SVGs.
   *
   * Get parameters for given SVG to be used/printed on invoice for Toll Collect Business Sector.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllInvoiceParams()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllInvoiceParams$Response(params?: GetAllInvoiceParams$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TcInvoiceParamsDto>>> {
    return getAllInvoiceParams(this.http, this.rootUrl, params, context);
  }

  /**
   * Get business sector related invoice params of all or queried SVGs.
   *
   * Get parameters for given SVG to be used/printed on invoice for Toll Collect Business Sector.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllInvoiceParams$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllInvoiceParams(params?: GetAllInvoiceParams$Params, context?: HttpContext): Observable<Array<TcInvoiceParamsDto>> {
    return this.getAllInvoiceParams$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TcInvoiceParamsDto>>): Array<TcInvoiceParamsDto> => r.body)
    );
  }

  /** Path part for operation `getInvoiceParamsById()` */
  static readonly GetInvoiceParamsByIdPath = '/svgs/{svgId}/invoice-params';

  /**
   * Get parameters for given SVG to be used/printed on invoice.
   *
   * Get parameters for given SVG to be used/printed on invoice for Toll Collect Business Sector.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInvoiceParamsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInvoiceParamsById$Response(params: GetInvoiceParamsById$Params, context?: HttpContext): Observable<StrictHttpResponse<TcInvoiceParamsDto>> {
    return getInvoiceParamsById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get parameters for given SVG to be used/printed on invoice.
   *
   * Get parameters for given SVG to be used/printed on invoice for Toll Collect Business Sector.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInvoiceParamsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInvoiceParamsById(params: GetInvoiceParamsById$Params, context?: HttpContext): Observable<TcInvoiceParamsDto> {
    return this.getInvoiceParamsById$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcInvoiceParamsDto>): TcInvoiceParamsDto => r.body)
    );
  }

  /** Path part for operation `upsertSvgInvoiceParams()` */
  static readonly UpsertSvgInvoiceParamsPath = '/svgs/{svgId}/invoice-params';

  /**
   * Upsert invoice params.
   *
   * Upsert business sector related invoice params per SVG, which are printed on invoices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertSvgInvoiceParams()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upsertSvgInvoiceParams$Response(params: UpsertSvgInvoiceParams$Params, context?: HttpContext): Observable<StrictHttpResponse<TcInvoiceParamsDto>> {
    return upsertSvgInvoiceParams(this.http, this.rootUrl, params, context);
  }

  /**
   * Upsert invoice params.
   *
   * Upsert business sector related invoice params per SVG, which are printed on invoices
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertSvgInvoiceParams$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upsertSvgInvoiceParams(params: UpsertSvgInvoiceParams$Params, context?: HttpContext): Observable<TcInvoiceParamsDto> {
    return this.upsertSvgInvoiceParams$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcInvoiceParamsDto>): TcInvoiceParamsDto => r.body)
    );
  }

  /** Path part for operation `deleteSvgInvoiceParams()` */
  static readonly DeleteSvgInvoiceParamsPath = '/svgs/{svgId}/invoice-params';

  /**
   * Delete svg params written on invoice.
   *
   * Delete business sector related data per SVG that is written on invoice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSvgInvoiceParams()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSvgInvoiceParams$Response(params: DeleteSvgInvoiceParams$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteSvgInvoiceParams(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete svg params written on invoice.
   *
   * Delete business sector related data per SVG that is written on invoice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSvgInvoiceParams$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSvgInvoiceParams(params: DeleteSvgInvoiceParams$Params, context?: HttpContext): Observable<void> {
    return this.deleteSvgInvoiceParams$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
