export let nextId = 0;

export abstract class AbstractControlComponent {
	id: string;

	protected constructor() {
		nextId++;
		this.id = nextId?.toString(10);
	}
}
