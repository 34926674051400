import { Injectable } from '@angular/core';
import { VehicleField, VehiclesControllerService } from '@mysvg/api/vehicle';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VehicleFieldsStoreService extends RepositoryStoreService<VehicleField[]> {
	constructor(private vehiclesControllerService: VehiclesControllerService) {
		super();
	}

	fetchData(): Observable<VehicleField[]> {
		return this.vehiclesControllerService.getVehicleFields();
	}
}
