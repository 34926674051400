import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '@mysvg/utils';

@Pipe({
	name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
	transform(fileSize: number): string {
		if (isDefined(fileSize)) {
			return this.transformToFileSizeString(fileSize);
		} else {
			return '';
		}
	}

	private transformToFileSizeString(fileSize: number): string {
		if (fileSize >= 1000 * 1000) {
			const sizeInMb = this.divideAndRound(fileSize, 1000 * 1000, 1);
			return this.transformToString(sizeInMb, 'MB');
		} else if (fileSize >= 1000) {
			const sizeInKb = this.divideAndRound(fileSize, 1000);
			return this.transformToString(sizeInKb, 'KB');
		} else {
			return this.transformToString(fileSize, 'Byte');
		}
	}

	/**
	 * to round up use `Math.ceil` and for one decimal multiply and divide afterwards by ten
	 */
	private divideAndRound(size: number, divisor: number, decimals: number = 0): number {
		const divisorMultiplierForDecimals = Math.pow(10, decimals);
		const converted = size / divisor;
		return Math.ceil(converted * divisorMultiplierForDecimals) / divisorMultiplierForDecimals;
	}

	private transformToString(size: number, postfix: string): string {
		return `${size} ${postfix}`.replace('.', ',');
	}
}
