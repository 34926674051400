/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { cancelRebookings } from '../fn/rebooking-controller/cancel-rebookings';
import { CancelRebookings$Params } from '../fn/rebooking-controller/cancel-rebookings';
import { RebookingDto } from '../models/rebooking-dto';
import { rebookOpenPositions } from '../fn/rebooking-controller/rebook-open-positions';
import { RebookOpenPositions$Params } from '../fn/rebooking-controller/rebook-open-positions';

@Injectable({ providedIn: 'root' })
export class RebookingControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `rebookOpenPositions()` */
  static readonly RebookOpenPositionsPath = '/rebookings';

  /**
   * Rebooks selected open positions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rebookOpenPositions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rebookOpenPositions$Response(params: RebookOpenPositions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RebookingDto>>> {
    return rebookOpenPositions(this.http, this.rootUrl, params, context);
  }

  /**
   * Rebooks selected open positions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rebookOpenPositions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rebookOpenPositions(params: RebookOpenPositions$Params, context?: HttpContext): Observable<Array<RebookingDto>> {
    return this.rebookOpenPositions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RebookingDto>>): Array<RebookingDto> => r.body)
    );
  }

  /** Path part for operation `cancelRebookings()` */
  static readonly CancelRebookingsPath = '/rebookings/cancel';

  /**
   * Cancels the rebookings of open positions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelRebookings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelRebookings$Response(params: CancelRebookings$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return cancelRebookings(this.http, this.rootUrl, params, context);
  }

  /**
   * Cancels the rebookings of open positions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelRebookings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelRebookings(params: CancelRebookings$Params, context?: HttpContext): Observable<void> {
    return this.cancelRebookings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
