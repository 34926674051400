/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseCustomersCardsBoxesDto } from '../../models/response-customers-cards-boxes-dto';

export interface GetCustomersCardsBoxesResponse$Params {
  companyName?: string;
  svgCustomerId?: string;
  customerId?: string;
  fleXBoxes?: string;
  svgBoxes?: string;
  activeVignettes?: string;
  essoDessoCards?: string;
  euroShellCards?: string;
  totalCards?: string;
  dkvCards?: string;
  telematicBoxes?: string;
  hasTelematicPlus?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getCustomersCardsBoxesResponse(http: HttpClient, rootUrl: string, params?: GetCustomersCardsBoxesResponse$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseCustomersCardsBoxesDto>> {
  const rb = new RequestBuilder(rootUrl, getCustomersCardsBoxesResponse.PATH, 'get');
  if (params) {
    rb.query('companyName', params.companyName, {});
    rb.query('svgCustomerId', params.svgCustomerId, {});
    rb.query('customerId', params.customerId, {});
    rb.query('fleXBoxes', params.fleXBoxes, {});
    rb.query('svgBoxes', params.svgBoxes, {});
    rb.query('activeVignettes', params.activeVignettes, {});
    rb.query('essoDessoCards', params.essoDessoCards, {});
    rb.query('euroShellCards', params.euroShellCards, {});
    rb.query('totalCards', params.totalCards, {});
    rb.query('dkvCards', params.dkvCards, {});
    rb.query('telematicBoxes', params.telematicBoxes, {});
    rb.query('hasTelematicPlus', params.hasTelematicPlus, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseCustomersCardsBoxesDto>;
    })
  );
}

getCustomersCardsBoxesResponse.PATH = '/customersCardsBoxes';
