/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { bulkUpdate } from '../fn/standard-condition/bulk-update';
import { BulkUpdate$Params } from '../fn/standard-condition/bulk-update';
import { updateCondition } from '../fn/standard-condition/update-condition';
import { UpdateCondition$Params } from '../fn/standard-condition/update-condition';


/**
 * Standard conditions crud
 */
@Injectable({ providedIn: 'root' })
export class StandardConditionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `bulkUpdate()` */
  static readonly BulkUpdatePath = '/svgs/{svgId}/standard-conditions';

  /**
   * Standard conditions bulk update
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdate$Response(params: BulkUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return bulkUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Standard conditions bulk update
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdate(params: BulkUpdate$Params, context?: HttpContext): Observable<void> {
    return this.bulkUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateCondition()` */
  static readonly UpdateConditionPath = '/svgs/{svgId}/standard-conditions/{id}';

  /**
   * Updates the the given standard condition.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCondition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCondition$Response(params: UpdateCondition$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateCondition(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the the given standard condition.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCondition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCondition(params: UpdateCondition$Params, context?: HttpContext): Observable<void> {
    return this.updateCondition$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
