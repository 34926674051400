/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createMedicalCenter } from '../fn/medical-center-controller/create-medical-center';
import { CreateMedicalCenter$Params } from '../fn/medical-center-controller/create-medical-center';
import { deleteMedicalCenter } from '../fn/medical-center-controller/delete-medical-center';
import { DeleteMedicalCenter$Params } from '../fn/medical-center-controller/delete-medical-center';
import { getAllMedicalCenters } from '../fn/medical-center-controller/get-all-medical-centers';
import { GetAllMedicalCenters$Params } from '../fn/medical-center-controller/get-all-medical-centers';
import { getMedicalCenterByCustomerId } from '../fn/medical-center-controller/get-medical-center-by-customer-id';
import { GetMedicalCenterByCustomerId$Params } from '../fn/medical-center-controller/get-medical-center-by-customer-id';
import { getMedicalCenters } from '../fn/medical-center-controller/get-medical-centers';
import { GetMedicalCenters$Params } from '../fn/medical-center-controller/get-medical-centers';
import { MedicalCenterDto } from '../models/medical-center-dto';
import { patchCustomersMedicalCenter } from '../fn/medical-center-controller/patch-customers-medical-center';
import { PatchCustomersMedicalCenter$Params } from '../fn/medical-center-controller/patch-customers-medical-center';
import { ResponseMedicalCenterDto } from '../models/response-medical-center-dto';
import { unassignCustomersMedicalCenter } from '../fn/medical-center-controller/unassign-customers-medical-center';
import { UnassignCustomersMedicalCenter$Params } from '../fn/medical-center-controller/unassign-customers-medical-center';
import { updateMedicalCenter } from '../fn/medical-center-controller/update-medical-center';
import { UpdateMedicalCenter$Params } from '../fn/medical-center-controller/update-medical-center';


/**
 * Manages the customer medical center address
 */
@Injectable({ providedIn: 'root' })
export class MedicalCenterControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateMedicalCenter()` */
  static readonly UpdateMedicalCenterPath = '/customers/medical-center/{id}';

  /**
   * Update medical center.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMedicalCenter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMedicalCenter$Response(params: UpdateMedicalCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<MedicalCenterDto>> {
    return updateMedicalCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * Update medical center.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMedicalCenter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMedicalCenter(params: UpdateMedicalCenter$Params, context?: HttpContext): Observable<MedicalCenterDto> {
    return this.updateMedicalCenter$Response(params, context).pipe(
      map((r: StrictHttpResponse<MedicalCenterDto>): MedicalCenterDto => r.body)
    );
  }

  /** Path part for operation `deleteMedicalCenter()` */
  static readonly DeleteMedicalCenterPath = '/customers/medical-center/{id}';

  /**
   * Delete medical center of a customer by customerId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMedicalCenter()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMedicalCenter$Response(params: DeleteMedicalCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteMedicalCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete medical center of a customer by customerId
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMedicalCenter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMedicalCenter(params: DeleteMedicalCenter$Params, context?: HttpContext): Observable<void> {
    return this.deleteMedicalCenter$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createMedicalCenter()` */
  static readonly CreateMedicalCenterPath = '/customers/medical-center';

  /**
   * Create medical center to a customer.
   *
   * returns an MedicalCenterDto with the newly created medical center.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMedicalCenter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMedicalCenter$Response(params: CreateMedicalCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<MedicalCenterDto>> {
    return createMedicalCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * Create medical center to a customer.
   *
   * returns an MedicalCenterDto with the newly created medical center.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMedicalCenter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMedicalCenter(params: CreateMedicalCenter$Params, context?: HttpContext): Observable<MedicalCenterDto> {
    return this.createMedicalCenter$Response(params, context).pipe(
      map((r: StrictHttpResponse<MedicalCenterDto>): MedicalCenterDto => r.body)
    );
  }

  /** Path part for operation `getMedicalCenterByCustomerId()` */
  static readonly GetMedicalCenterByCustomerIdPath = '/customers/{customerId}/medical-center';

  /**
   * Get medical center of a customer by customer id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMedicalCenterByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedicalCenterByCustomerId$Response(params: GetMedicalCenterByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<MedicalCenterDto>> {
    return getMedicalCenterByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * Get medical center of a customer by customer id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMedicalCenterByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedicalCenterByCustomerId(params: GetMedicalCenterByCustomerId$Params, context?: HttpContext): Observable<MedicalCenterDto> {
    return this.getMedicalCenterByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<MedicalCenterDto>): MedicalCenterDto => r.body)
    );
  }

  /** Path part for operation `unassignCustomersMedicalCenter()` */
  static readonly UnassignCustomersMedicalCenterPath = '/customers/{customerId}/medical-center';

  /**
   * unassign customers medical center.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unassignCustomersMedicalCenter()` instead.
   *
   * This method doesn't expect any request body.
   */
  unassignCustomersMedicalCenter$Response(params: UnassignCustomersMedicalCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return unassignCustomersMedicalCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * unassign customers medical center.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unassignCustomersMedicalCenter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unassignCustomersMedicalCenter(params: UnassignCustomersMedicalCenter$Params, context?: HttpContext): Observable<void> {
    return this.unassignCustomersMedicalCenter$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchCustomersMedicalCenter()` */
  static readonly PatchCustomersMedicalCenterPath = '/customers/{customerId}/medical-center/{centerId}';

  /**
   * patch customers medical center assignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCustomersMedicalCenter()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchCustomersMedicalCenter$Response(params: PatchCustomersMedicalCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return patchCustomersMedicalCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * patch customers medical center assignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCustomersMedicalCenter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchCustomersMedicalCenter(params: PatchCustomersMedicalCenter$Params, context?: HttpContext): Observable<void> {
    return this.patchCustomersMedicalCenter$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getMedicalCenters()` */
  static readonly GetMedicalCentersPath = '/customers/medical-centers';

  /**
   * Returns paginated medical centers applicable to free / individual customers (not asd customers).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMedicalCenters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedicalCenters$Response(params?: GetMedicalCenters$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseMedicalCenterDto>> {
    return getMedicalCenters(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns paginated medical centers applicable to free / individual customers (not asd customers).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMedicalCenters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedicalCenters(params?: GetMedicalCenters$Params, context?: HttpContext): Observable<ResponseMedicalCenterDto> {
    return this.getMedicalCenters$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseMedicalCenterDto>): ResponseMedicalCenterDto => r.body)
    );
  }

  /** Path part for operation `getAllMedicalCenters()` */
  static readonly GetAllMedicalCentersPath = '/customers/medical-centers/all';

  /**
   * Returns all medical centers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMedicalCenters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMedicalCenters$Response(params?: GetAllMedicalCenters$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MedicalCenterDto>>> {
    return getAllMedicalCenters(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all medical centers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMedicalCenters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMedicalCenters(params?: GetAllMedicalCenters$Params, context?: HttpContext): Observable<Array<MedicalCenterDto>> {
    return this.getAllMedicalCenters$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MedicalCenterDto>>): Array<MedicalCenterDto> => r.body)
    );
  }

}
