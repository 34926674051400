/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseBodyTcInvoiceDto } from '../../models/response-body-tc-invoice-dto';

export interface GetInvoices$Params {

/**
 * limit for pagination
 */
  limit?: number;

/**
 * offset for pagination
 */
  offset?: number;
  sortOrder?: 'ASC' | 'DESC';
  sortBy?: string;

/**
 * UUID to identify the customer
 */
  customerId: string;

/**
 * UUID to identify the svg (tenancy id)
 */
  svgId: string;

/**
 * Minimum gross amount of invoice in cents (no decimal or floating point). Example: 123450 Cents are 1234.50 Euro
 */
  grossAmountInCentsMin?: number;

/**
 * Maximum gross amount of invoice in cents (no decimal or floating point). Example: 678900 Cents are 6789.00 Euro
 */
  grossAmountInCentsMax?: number;

/**
 * show only invoices, which were created from the given date (inclusive)
 */
  invoiceDateMin?: string;

/**
 * show only invoices, which were created til the given date (inclusive)
 */
  invoiceDateMax?: string;

/**
 * search string for invoice number, will find matches that start with the search string
 */
  invoiceNumber?: string;

/**
 * show only invoices, which have a due date from the given one (inclusive)
 */
  paymentTermDateMin?: string;

/**
 * show only invoices, which have a due date til the given one (inclusive)
 */
  paymentTermDateMax?: string;
}

export function getInvoices(http: HttpClient, rootUrl: string, params: GetInvoices$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBodyTcInvoiceDto>> {
  const rb = new RequestBuilder(rootUrl, getInvoices.PATH, 'get');
  if (params) {
    rb.query('limit', params.limit, {"explode":false});
    rb.query('offset', params.offset, {"explode":false});
    rb.query('sortOrder', params.sortOrder, {"explode":false});
    rb.query('sortBy', params.sortBy, {"explode":false});
    rb.path('customerId', params.customerId, {});
    rb.path('svgId', params.svgId, {});
    rb.query('grossAmountInCentsMin', params.grossAmountInCentsMin, {"explode":false});
    rb.query('grossAmountInCentsMax', params.grossAmountInCentsMax, {"explode":false});
    rb.query('invoiceDateMin', params.invoiceDateMin, {"explode":false});
    rb.query('invoiceDateMax', params.invoiceDateMax, {"explode":false});
    rb.query('invoiceNumber', params.invoiceNumber, {"explode":false});
    rb.query('paymentTermDateMin', params.paymentTermDateMin, {"explode":false});
    rb.query('paymentTermDateMax', params.paymentTermDateMax, {"explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseBodyTcInvoiceDto>;
    })
  );
}

getInvoices.PATH = '/svgs/{svgId}/customers/{customerId}/invoices';
