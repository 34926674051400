/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { endRv } from '../fn/incident-controller/end-rv';
import { EndRv$Params } from '../fn/incident-controller/end-rv';
import { getBriefReportWithProductAvailability } from '../fn/incident-controller/get-brief-report-with-product-availability';
import { GetBriefReportWithProductAvailability$Params } from '../fn/incident-controller/get-brief-report-with-product-availability';
import { getIncidentMeta } from '../fn/incident-controller/get-incident-meta';
import { GetIncidentMeta$Params } from '../fn/incident-controller/get-incident-meta';
import { getIncidentReasons } from '../fn/incident-controller/get-incident-reasons';
import { GetIncidentReasons$Params } from '../fn/incident-controller/get-incident-reasons';
import { getIncidents } from '../fn/incident-controller/get-incidents';
import { GetIncidents$Params } from '../fn/incident-controller/get-incidents';
import { getIncidentTypes } from '../fn/incident-controller/get-incident-types';
import { GetIncidentTypes$Params } from '../fn/incident-controller/get-incident-types';
import { IncidentMetaDto } from '../models/incident-meta-dto';
import { IncidentReasonDto } from '../models/incident-reason-dto';
import { IncidentTypeDto } from '../models/incident-type-dto';
import { ReportDto } from '../models/report-dto';
import { SafetyDto } from '../models/safety-dto';
import { SvgResponseIncidentDto } from '../models/svg-response-incident-dto';
import { transitionToSelfEval } from '../fn/incident-controller/transition-to-self-eval';
import { TransitionToSelfEval$Params } from '../fn/incident-controller/transition-to-self-eval';

@Injectable({ providedIn: 'root' })
export class IncidentControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `transitionToSelfEval()` */
  static readonly TransitionToSelfEvalPath = '/incidents/transitionToSelfEval';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transitionToSelfEval()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transitionToSelfEval$Response(params: TransitionToSelfEval$Params, context?: HttpContext): Observable<StrictHttpResponse<SafetyDto>> {
    return transitionToSelfEval(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transitionToSelfEval$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transitionToSelfEval(params: TransitionToSelfEval$Params, context?: HttpContext): Observable<SafetyDto> {
    return this.transitionToSelfEval$Response(params, context).pipe(
      map((r: StrictHttpResponse<SafetyDto>): SafetyDto => r.body)
    );
  }

  /** Path part for operation `endRv()` */
  static readonly EndRvPath = '/incidents/endRV';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `endRv()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  endRv$Response(params: EndRv$Params, context?: HttpContext): Observable<StrictHttpResponse<SafetyDto>> {
    return endRv(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `endRv$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  endRv(params: EndRv$Params, context?: HttpContext): Observable<SafetyDto> {
    return this.endRv$Response(params, context).pipe(
      map((r: StrictHttpResponse<SafetyDto>): SafetyDto => r.body)
    );
  }

  /** Path part for operation `getIncidents()` */
  static readonly GetIncidentsPath = '/incidents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIncidents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidents$Response(params?: GetIncidents$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgResponseIncidentDto>> {
    return getIncidents(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIncidents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidents(params?: GetIncidents$Params, context?: HttpContext): Observable<SvgResponseIncidentDto> {
    return this.getIncidents$Response(params, context).pipe(
      map((r: StrictHttpResponse<SvgResponseIncidentDto>): SvgResponseIncidentDto => r.body)
    );
  }

  /** Path part for operation `getIncidentTypes()` */
  static readonly GetIncidentTypesPath = '/incidents/types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIncidentTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidentTypes$Response(params?: GetIncidentTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<IncidentTypeDto>>> {
    return getIncidentTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIncidentTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidentTypes(params?: GetIncidentTypes$Params, context?: HttpContext): Observable<Array<IncidentTypeDto>> {
    return this.getIncidentTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IncidentTypeDto>>): Array<IncidentTypeDto> => r.body)
    );
  }

  /** Path part for operation `getIncidentReasons()` */
  static readonly GetIncidentReasonsPath = '/incidents/reasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIncidentReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidentReasons$Response(params?: GetIncidentReasons$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<IncidentReasonDto>>> {
    return getIncidentReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIncidentReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidentReasons(params?: GetIncidentReasons$Params, context?: HttpContext): Observable<Array<IncidentReasonDto>> {
    return this.getIncidentReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IncidentReasonDto>>): Array<IncidentReasonDto> => r.body)
    );
  }

  /** Path part for operation `getIncidentMeta()` */
  static readonly GetIncidentMetaPath = '/incidents/meta';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIncidentMeta()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidentMeta$Response(params?: GetIncidentMeta$Params, context?: HttpContext): Observable<StrictHttpResponse<IncidentMetaDto>> {
    return getIncidentMeta(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIncidentMeta$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidentMeta(params?: GetIncidentMeta$Params, context?: HttpContext): Observable<IncidentMetaDto> {
    return this.getIncidentMeta$Response(params, context).pipe(
      map((r: StrictHttpResponse<IncidentMetaDto>): IncidentMetaDto => r.body)
    );
  }

  /** Path part for operation `getBriefReportWithProductAvailability()` */
  static readonly GetBriefReportWithProductAvailabilityPath = '/incidents/brief/available';

  /**
   * returns brief report with product availability
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBriefReportWithProductAvailability()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBriefReportWithProductAvailability$Response(params: GetBriefReportWithProductAvailability$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportDto>> {
    return getBriefReportWithProductAvailability(this.http, this.rootUrl, params, context);
  }

  /**
   * returns brief report with product availability
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBriefReportWithProductAvailability$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBriefReportWithProductAvailability(params: GetBriefReportWithProductAvailability$Params, context?: HttpContext): Observable<ReportDto> {
    return this.getBriefReportWithProductAvailability$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportDto>): ReportDto => r.body)
    );
  }

}
