import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, startWith } from 'rxjs/operators';
import { OAuthEvent } from 'angular-oauth2-oidc';

import { OAUTH_EVENTS, OauthWrapperService, SvgContextService } from '@svg-frontends/session';

import { DocumentService } from '../services/document.service';

@Pipe({ name: 'customersDownloadLinkFromHash' })
export class CustomersDownloadLinkFromHashPipe implements PipeTransform {
	constructor(
		private documentService: DocumentService,
		private svgContextService: SvgContextService,
		private oauthWrapperService: OauthWrapperService<any>,
	) {}

	transform(downloadHash: string): Observable<string> {
		return this.oauthWrapperService.getSilentRefreshEvents().pipe(
			startWith({ type: OAUTH_EVENTS.SILENT_REFRESH.REFRESHED } as OAuthEvent),
			filter((event: OAuthEvent) => event.type === OAUTH_EVENTS.SILENT_REFRESH.REFRESHED),
			mergeMap(() => this.svgContextService.getFirstPortalUid()),
			map((portalUid: string) => this.documentService.getPrivateEntityDocumentUrlFromHash('customers', portalUid, downloadHash)),
		);
	}
}
