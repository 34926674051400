/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getGuarantees } from '../fn/guarantees-controller/get-guarantees';
import { GetGuarantees$Params } from '../fn/guarantees-controller/get-guarantees';
import { getGuaranteesSummary } from '../fn/guarantees-controller/get-guarantees-summary';
import { GetGuaranteesSummary$Params } from '../fn/guarantees-controller/get-guarantees-summary';
import { GuaranteesSummaryDto } from '../models/guarantees-summary-dto';
import { ignoreCustomers } from '../fn/guarantees-controller/ignore-customers';
import { IgnoreCustomers$Params } from '../fn/guarantees-controller/ignore-customers';
import { isCustomerIgnored } from '../fn/guarantees-controller/is-customer-ignored';
import { IsCustomerIgnored$Params } from '../fn/guarantees-controller/is-customer-ignored';
import { ResponseCustomerGuaranteeDto } from '../models/response-customer-guarantee-dto';
import { unignoreCustomers } from '../fn/guarantees-controller/unignore-customers';
import { UnignoreCustomers$Params } from '../fn/guarantees-controller/unignore-customers';

@Injectable({ providedIn: 'root' })
export class GuaranteesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `ignoreCustomers()` */
  static readonly IgnoreCustomersPath = '/guarantees/ignore';

  /**
   * Ignore customers from guarantees
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ignoreCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  ignoreCustomers$Response(params: IgnoreCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ignoreCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * Ignore customers from guarantees
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ignoreCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ignoreCustomers(params: IgnoreCustomers$Params, context?: HttpContext): Observable<void> {
    return this.ignoreCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getGuarantees()` */
  static readonly GetGuaranteesPath = '/guarantees';

  /**
   * Returns customer guarantees, supports IFilter
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGuarantees()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGuarantees$Response(params: GetGuarantees$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseCustomerGuaranteeDto>> {
    return getGuarantees(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns customer guarantees, supports IFilter
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGuarantees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGuarantees(params: GetGuarantees$Params, context?: HttpContext): Observable<ResponseCustomerGuaranteeDto> {
    return this.getGuarantees$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseCustomerGuaranteeDto>): ResponseCustomerGuaranteeDto => r.body)
    );
  }

  /** Path part for operation `getGuaranteesSummary()` */
  static readonly GetGuaranteesSummaryPath = '/guarantees/summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGuaranteesSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGuaranteesSummary$Response(params: GetGuaranteesSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<GuaranteesSummaryDto>> {
    return getGuaranteesSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGuaranteesSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGuaranteesSummary(params: GetGuaranteesSummary$Params, context?: HttpContext): Observable<GuaranteesSummaryDto> {
    return this.getGuaranteesSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuaranteesSummaryDto>): GuaranteesSummaryDto => r.body)
    );
  }

  /** Path part for operation `isCustomerIgnored()` */
  static readonly IsCustomerIgnoredPath = '/guarantees/ignored/{customerBaseId}';

  /**
   * Returns if given customer is ignored
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isCustomerIgnored()` instead.
   *
   * This method doesn't expect any request body.
   */
  isCustomerIgnored$Response(params: IsCustomerIgnored$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isCustomerIgnored(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns if given customer is ignored
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isCustomerIgnored$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isCustomerIgnored(params: IsCustomerIgnored$Params, context?: HttpContext): Observable<boolean> {
    return this.isCustomerIgnored$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `unignoreCustomers()` */
  static readonly UnignoreCustomersPath = '/guarantees/unignore';

  /**
   * Unignore customers from guarantees
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unignoreCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  unignoreCustomers$Response(params: UnignoreCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return unignoreCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * Unignore customers from guarantees
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unignoreCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unignoreCustomers(params: UnignoreCustomers$Params, context?: HttpContext): Observable<void> {
    return this.unignoreCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
