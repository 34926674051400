import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationInterceptorService implements HttpInterceptor {
	constructor(private oAuthService: OAuthService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const authHeader = this.oAuthService.authorizationHeader();
		// Clone the request to add the Authorization header.
		return next.handle(req.clone({ headers: req.headers.set('Authorization', authHeader) }));
	}
}
