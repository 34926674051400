/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllBookings1 } from '../fn/bookings-controller/get-all-bookings-1';
import { GetAllBookings1$Params } from '../fn/bookings-controller/get-all-bookings-1';
import { getChargeBacks } from '../fn/bookings-controller/get-charge-backs';
import { GetChargeBacks$Params } from '../fn/bookings-controller/get-charge-backs';
import { getOpenPositions } from '../fn/bookings-controller/get-open-positions';
import { GetOpenPositions$Params } from '../fn/bookings-controller/get-open-positions';
import { ResponseBookingDto } from '../models/response-booking-dto';
import { ResponseChargeBackDto } from '../models/response-charge-back-dto';

@Injectable({ providedIn: 'root' })
export class BookingsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOpenPositions()` */
  static readonly GetOpenPositionsPath = '/bookings/open';

  /**
   * Returns all open positions for customers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpenPositions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenPositions$Response(params: GetOpenPositions$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBookingDto>> {
    return getOpenPositions(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all open positions for customers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpenPositions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenPositions(params: GetOpenPositions$Params, context?: HttpContext): Observable<ResponseBookingDto> {
    return this.getOpenPositions$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseBookingDto>): ResponseBookingDto => r.body)
    );
  }

  /** Path part for operation `getChargeBacks()` */
  static readonly GetChargeBacksPath = '/bookings/chargebacks';

  /**
   * Returns all charge backs for customers. Support IFilter
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChargeBacks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargeBacks$Response(params?: GetChargeBacks$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseChargeBackDto>> {
    return getChargeBacks(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all charge backs for customers. Support IFilter
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChargeBacks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargeBacks(params?: GetChargeBacks$Params, context?: HttpContext): Observable<ResponseChargeBackDto> {
    return this.getChargeBacks$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseChargeBackDto>): ResponseChargeBackDto => r.body)
    );
  }

  /** Path part for operation `getAllBookings1()` */
  static readonly GetAllBookings1Path = '/bookings/all';

  /**
   * Returns all bookings for customers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBookings1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBookings1$Response(params: GetAllBookings1$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBookingDto>> {
    return getAllBookings1(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all bookings for customers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllBookings1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBookings1(params: GetAllBookings1$Params, context?: HttpContext): Observable<ResponseBookingDto> {
    return this.getAllBookings1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseBookingDto>): ResponseBookingDto => r.body)
    );
  }

}
