/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getBriefReport } from '../fn/crefo-report-controller/get-brief-report';
import { GetBriefReport$Params } from '../fn/crefo-report-controller/get-brief-report';
import { getCommercialReport } from '../fn/crefo-report-controller/get-commercial-report';
import { GetCommercialReport$Params } from '../fn/crefo-report-controller/get-commercial-report';
import { getCompactReport } from '../fn/crefo-report-controller/get-compact-report';
import { GetCompactReport$Params } from '../fn/crefo-report-controller/get-compact-report';
import { getSignalSolvencyReport } from '../fn/crefo-report-controller/get-signal-solvency-report';
import { GetSignalSolvencyReport$Params } from '../fn/crefo-report-controller/get-signal-solvency-report';
import { getTrafficLightReport } from '../fn/crefo-report-controller/get-traffic-light-report';
import { GetTrafficLightReport$Params } from '../fn/crefo-report-controller/get-traffic-light-report';
import { ReportDto } from '../models/report-dto';

@Injectable({ providedIn: 'root' })
export class CrefoReportControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTrafficLightReport()` */
  static readonly GetTrafficLightReportPath = '/crefo/report/traffic-light';

  /**
   * returns traffic-light report
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrafficLightReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrafficLightReport$Response(params: GetTrafficLightReport$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportDto>> {
    return getTrafficLightReport(this.http, this.rootUrl, params, context);
  }

  /**
   * returns traffic-light report
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTrafficLightReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrafficLightReport(params: GetTrafficLightReport$Params, context?: HttpContext): Observable<ReportDto> {
    return this.getTrafficLightReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportDto>): ReportDto => r.body)
    );
  }

  /** Path part for operation `getSignalSolvencyReport()` */
  static readonly GetSignalSolvencyReportPath = '/crefo/report/signal-solvency';

  /**
   * returns signal-solvency report
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSignalSolvencyReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSignalSolvencyReport$Response(params: GetSignalSolvencyReport$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportDto>> {
    return getSignalSolvencyReport(this.http, this.rootUrl, params, context);
  }

  /**
   * returns signal-solvency report
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSignalSolvencyReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSignalSolvencyReport(params: GetSignalSolvencyReport$Params, context?: HttpContext): Observable<ReportDto> {
    return this.getSignalSolvencyReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportDto>): ReportDto => r.body)
    );
  }

  /** Path part for operation `getCompactReport()` */
  static readonly GetCompactReportPath = '/crefo/report/compact';

  /**
   * returns compact report
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompactReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompactReport$Response(params: GetCompactReport$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportDto>> {
    return getCompactReport(this.http, this.rootUrl, params, context);
  }

  /**
   * returns compact report
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompactReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompactReport(params: GetCompactReport$Params, context?: HttpContext): Observable<ReportDto> {
    return this.getCompactReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportDto>): ReportDto => r.body)
    );
  }

  /** Path part for operation `getCommercialReport()` */
  static readonly GetCommercialReportPath = '/crefo/report/commercial';

  /**
   * returns commercial report
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCommercialReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCommercialReport$Response(params: GetCommercialReport$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportDto>> {
    return getCommercialReport(this.http, this.rootUrl, params, context);
  }

  /**
   * returns commercial report
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCommercialReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCommercialReport(params: GetCommercialReport$Params, context?: HttpContext): Observable<ReportDto> {
    return this.getCommercialReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportDto>): ReportDto => r.body)
    );
  }

  /** Path part for operation `getBriefReport()` */
  static readonly GetBriefReportPath = '/crefo/report/brief';

  /**
   * returns brief report
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBriefReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBriefReport$Response(params: GetBriefReport$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportDto>> {
    return getBriefReport(this.http, this.rootUrl, params, context);
  }

  /**
   * returns brief report
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBriefReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBriefReport(params: GetBriefReport$Params, context?: HttpContext): Observable<ReportDto> {
    return this.getBriefReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportDto>): ReportDto => r.body)
    );
  }

}
