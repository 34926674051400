import { Injectable } from '@angular/core';
import { SwUpdate, UnrecoverableStateEvent, VersionEvent } from '@angular/service-worker';
import { Level, Logger } from '@nsalaun/ng-logger';
import { Observable, Subject, from, timer } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { ErrorHandlingService, ErrorHandlingType } from '@svg-frontends/error';

@Injectable({
	providedIn: 'root',
})
export class UpdateAppWorkerService {
	private reloadTrigger$: Subject<boolean> = new Subject();

	constructor(private swUpdate: SwUpdate, private errorHandlingService: ErrorHandlingService, private logger: Logger) {}

	init(pollingInterval: number = 20000): void {
		if (this.swUpdate.isEnabled) {
			this.initDebugLogger();

			// `VERSION_READY` indicates a reload should be done (angular docu)
			this.swUpdate.versionUpdates
				.pipe(filter((versionEvent: VersionEvent) => versionEvent.type === 'VERSION_READY'))
				.subscribe(() => this.reloadTrigger$.next(true));

			this.checkForUpdatePolling(pollingInterval);
			this.handleUnrecoverableState();
		} else {
			const supportsServiceWorker = 'serviceWorker' in navigator;
			if (!supportsServiceWorker) {
				// "In Firefox, Service Worker APIs are hidden and cannot be used when the user is in private browsing mode, or when history is disabled, or if cookies are cleared when Firefox is closed."
				// https://developer.mozilla.org/en-US/docs/Web/API/Service_Worker_API/Using_Service_Workers
				const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
				// People search for MySVG within the Google Search App (GSA) on iOS devices and then open MySVG in a webview there.
				// This does not support PWAs so ignore this here and don't notify Sentry.
				const isGoogleWebView = navigator.userAgent.includes('GSA/');
				if (isFirefox || isGoogleWebView) {
					this.logger.warn('Service worker can not be registred with this Browser/App configuration');
				} else {
					// report to sentry to identify other browsers/configurations that do not register update service worker
					this.reportToSentryAsError(`[UpdateAppWorkerService] says, PWA ServiceWorker is disabled.`);
				}
			}
		}
	}

	private initDebugLogger(): void {
		this.swUpdate.versionUpdates.subscribe((versionEvent: VersionEvent) => {
			if (this.logger.level >= Level.DEBUG) {
				if (versionEvent.type !== 'NO_NEW_VERSION_DETECTED') {
					this.logger.groupCollapsed('Version Event:');
					this.logger.debug(versionEvent);
				}

				switch (versionEvent.type) {
					case 'VERSION_DETECTED':
						this.logger.debug(`1. '${versionEvent}' received: Downloading new app version: ${versionEvent.version.hash}`);
						break;
					case 'VERSION_READY':
						this.logger.debug(`2. '${versionEvent}' received.`);
						this.logger.debug(`2. Current app version: ${versionEvent.currentVersion.hash}`);
						this.logger.debug(`2. New app version ready for use: ${versionEvent.latestVersion.hash}`);
						this.logger.debug('TRIGGERS RELOAD');
						break;
					case 'VERSION_INSTALLATION_FAILED':
						this.logger.debug(
							`'${versionEvent}' received: Failed to install app version '${versionEvent.version.hash}': ${versionEvent.error}`,
						);
						break;
				}
				this.logger.groupEnd();
			}
		});
	}

	getVersionReadyForReload(): Observable<boolean> {
		return this.reloadTrigger$.asObservable();
	}

	reloadAndReset(): void {
		this.reloadTrigger$.next(false);
		window.location.href = './';
	}

	private checkForUpdatePolling(pollingInterval: number): void {
		timer(0, pollingInterval)
			.pipe(mergeMap(() => from(this.swUpdate.checkForUpdate())))
			.subscribe(
				(foundUpdate: boolean) => {
					if (foundUpdate) {
						this.logger.debug(`[UpdateAppWorkerService] says, found application update ${foundUpdate}.`);
					}
				},
				(error: Error) => {
					this.errorHandlingService.setNextErrorBy(error, ErrorHandlingType.IGNORE, true);
				},
			);
	}

	private handleUnrecoverableState(): void {
		this.swUpdate.unrecoverable.subscribe((event: UnrecoverableStateEvent) => {
			this.reloadAndReset();
			this.reportToSentryAsError(
				`[UpdateAppWorkerService] says, PWA reported unrecoverable state. Automatic reload triggered. ${event.type}: ${event.reason}`,
			);
		});
	}

	private reportToSentryAsError(message: string): void {
		const error = new Error(message);
		this.logger.debug(message);
		this.errorHandlingService.setNextErrorBy(error, ErrorHandlingType.IGNORE, true);
	}
}
