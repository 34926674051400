import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FileTypeEnum } from '@svg-frontends/file-upload';

import { AbstractControlComponent } from '../../classes/AbstractControlComponent';
import { FormGroupService } from '../../services/form-group.service';

@UntilDestroy()
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-file-form-control',
	styleUrls: ['./file-form-control.component.scss'],
	templateUrl: './file-form-control.component.html',
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FileFormControlComponent extends AbstractControlComponent implements OnInit {
	faCloudUploadAlt = faCloudUploadAlt;
	formControl: FormControl;

	@Input() controlName: string;
	@Input() label: string;

	// optionals
	@Input() allowedFileTypes?: FileTypeEnum[] = [];
	@Input() disableControl?: boolean;
	@Input() helperText?: string | TemplateRef<any>;
	@Input() multiple? = false;
	@Input() replaceFiles? = false;
	@Input() uploadText? = 'Wähle eine Datei';

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private formGroupDirective: FormGroupDirective,
		private macFormGroupService: FormGroupService,
	) {
		super();
	}

	ngOnInit(): void {
		this.formControl = this.formGroupDirective.control.get(this.controlName) as FormControl;

		this.macFormGroupService.submit$.pipe(untilDestroyed(this)).subscribe(() => {
			FormGroupService.touchControl(this.formControl);
			this.changeDetectorRef.markForCheck();
		});
	}

	removeFileFromList(file: File): void {
		const value = [...this.formControl.value];
		const arrayWithoutGivenFile = value.filter((fileFromArray: File) => fileFromArray !== file);
		this.formControl.patchValue(arrayWithoutGivenFile);
	}

	resetFiles(): void {
		this.formControl.patchValue([]);
	}
}
