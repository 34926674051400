/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createVehicleCategory } from '../fn/vehicle-categories-controller/create-vehicle-category';
import { CreateVehicleCategory$Params } from '../fn/vehicle-categories-controller/create-vehicle-category';
import { deleteVehicleCategory } from '../fn/vehicle-categories-controller/delete-vehicle-category';
import { DeleteVehicleCategory$Params } from '../fn/vehicle-categories-controller/delete-vehicle-category';
import { getFilterOptionsForVehicleCategories } from '../fn/vehicle-categories-controller/get-filter-options-for-vehicle-categories';
import { GetFilterOptionsForVehicleCategories$Params } from '../fn/vehicle-categories-controller/get-filter-options-for-vehicle-categories';
import { getVehicleCategories } from '../fn/vehicle-categories-controller/get-vehicle-categories';
import { GetVehicleCategories$Params } from '../fn/vehicle-categories-controller/get-vehicle-categories';
import { ResponseVehicleCategory } from '../models/response-vehicle-category';
import { updateVehicleCategory } from '../fn/vehicle-categories-controller/update-vehicle-category';
import { UpdateVehicleCategory$Params } from '../fn/vehicle-categories-controller/update-vehicle-category';
import { VehicleCategory } from '../models/vehicle-category';

@Injectable({ providedIn: 'root' })
export class VehicleCategoriesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateVehicleCategory()` */
  static readonly UpdateVehicleCategoryPath = '/vehicleCategories/{id}';

  /**
   * updating the vehicle category.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVehicleCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVehicleCategory$Response(params: UpdateVehicleCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<VehicleCategory>> {
    return updateVehicleCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * updating the vehicle category.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateVehicleCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVehicleCategory(params: UpdateVehicleCategory$Params, context?: HttpContext): Observable<VehicleCategory> {
    return this.updateVehicleCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleCategory>): VehicleCategory => r.body)
    );
  }

  /** Path part for operation `deleteVehicleCategory()` */
  static readonly DeleteVehicleCategoryPath = '/vehicleCategories/{id}';

  /**
   * deleting the vehicle category.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteVehicleCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVehicleCategory$Response(params: DeleteVehicleCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return deleteVehicleCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * deleting the vehicle category.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteVehicleCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVehicleCategory(params: DeleteVehicleCategory$Params, context?: HttpContext): Observable<Array<string>> {
    return this.deleteVehicleCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getVehicleCategories()` */
  static readonly GetVehicleCategoriesPath = '/vehicleCategories';

  /**
   * Get a list of all vehicle categories.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleCategories$Response(params: GetVehicleCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseVehicleCategory>> {
    return getVehicleCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of all vehicle categories.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicleCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleCategories(params: GetVehicleCategories$Params, context?: HttpContext): Observable<ResponseVehicleCategory> {
    return this.getVehicleCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseVehicleCategory>): ResponseVehicleCategory => r.body)
    );
  }

  /** Path part for operation `createVehicleCategory()` */
  static readonly CreateVehicleCategoryPath = '/vehicleCategories';

  /**
   * creating a new vehicle category.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createVehicleCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVehicleCategory$Response(params: CreateVehicleCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<VehicleCategory>> {
    return createVehicleCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * creating a new vehicle category.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createVehicleCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVehicleCategory(params: CreateVehicleCategory$Params, context?: HttpContext): Observable<VehicleCategory> {
    return this.createVehicleCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleCategory>): VehicleCategory => r.body)
    );
  }

  /** Path part for operation `getFilterOptionsForVehicleCategories()` */
  static readonly GetFilterOptionsForVehicleCategoriesPath = '/filter/vehicleCategories/{name}';

  /**
   * get the filterOptions for any column of VehicleCategory
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterOptionsForVehicleCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterOptionsForVehicleCategories$Response(params: GetFilterOptionsForVehicleCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getFilterOptionsForVehicleCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * get the filterOptions for any column of VehicleCategory
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFilterOptionsForVehicleCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterOptionsForVehicleCategories(params: GetFilterOptionsForVehicleCategories$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getFilterOptionsForVehicleCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
