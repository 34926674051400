/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MedicalCenterDto } from '../../models/medical-center-dto';
import { MedicalCenterFormDto } from '../../models/medical-center-form-dto';

export interface UpdateMedicalCenter$Params {

/**
 * resource id.
 */
  id: number;
      body: MedicalCenterFormDto
}

export function updateMedicalCenter(http: HttpClient, rootUrl: string, params: UpdateMedicalCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<MedicalCenterDto>> {
  const rb = new RequestBuilder(rootUrl, updateMedicalCenter.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MedicalCenterDto>;
    })
  );
}

updateMedicalCenter.PATH = '/customers/medical-center/{id}';
