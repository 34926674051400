import { Directive, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';

export class LetContext<T> {
	$implicit: T | null = null;
	svgFrontendsLet: T | null = null;
}

@Directive({ selector: '[svgFrontendsLet]' })
export class LetDirective<T> implements OnInit, OnChanges {
	@Input() svgFrontendsLet: T;
	private context = new LetContext<T>();

	constructor(private templateRef: TemplateRef<LetContext<T>>, private viewContainerRef: ViewContainerRef) {}

	/**
	 * Asserts the correct type of the context for the template that `MacLet` will render.
	 * See https://angular.io/guide/structural-directives#typing-the-directives-context
	 *
	 * The presence of this method is a signal to the Ivy template type-check compiler that the
	 * `NgIf` structural directive renders its template with a specific context type.
	 */
	static ngTemplateContextGuard<T>(_dir: LetDirective<T>, _ctx: unknown): _ctx is LetContext<T> {
		return true;
	}

	ngOnInit(): void {
		this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('svgFrontendsLet' in changes) {
			this.context.$implicit = this.context.svgFrontendsLet = changes['svgFrontendsLet'].currentValue as T;
		}
	}
}
