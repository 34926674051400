/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { BoxValidationControllerService } from './services/box-validation-controller.service';
import { VehiclesControllerService } from './services/vehicles-controller.service';
import { VehicleCategoriesControllerService } from './services/vehicle-categories-controller.service';
import { VehicleDocumentsControllerService } from './services/vehicle-documents-controller.service';
import { CsvParserService } from './services/csv-parser.service';
import { MySvgControllerService } from './services/my-svg-controller.service';
import { PublicVehiclesControllerService } from './services/public-vehicles-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    BoxValidationControllerService,
    VehiclesControllerService,
    VehicleCategoriesControllerService,
    VehicleDocumentsControllerService,
    CsvParserService,
    MySvgControllerService,
    PublicVehiclesControllerService,
    ApiConfiguration
  ],
})
export class VehicleModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<VehicleModule> {
    return {
      ngModule: VehicleModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: VehicleModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('VehicleModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
