import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { EditModeService } from '../services/edit-mode.service';

@UntilDestroy()
@Directive({ selector: '[svgFrontendsVisibleInEditMode]' })
export class EditModeDirective implements OnInit {
	@Input() svgFrontendsVisibleInEditMode: boolean;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private editModeService: EditModeService,
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef,
	) {}

	ngOnInit(): void {
		this.editModeService.isStaffAtCustomerEditing$
			.pipe(untilDestroyed(this))
			.subscribe((isInEditMode: boolean) => this.setVisibility(isInEditMode));
	}

	/**
	 * Show content only if:
	 * 1. EditMode is enabled and content should be visible in editMode
	 * 2. EditMode is disabled and content should be hidden in editMode
	 */
	private setVisibility(isInEditMode: boolean): void {
		if ((isInEditMode && this.svgFrontendsVisibleInEditMode) || (!isInEditMode && !this.svgFrontendsVisibleInEditMode)) {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainerRef.clear();
		}

		this.changeDetectorRef.markForCheck();
	}
}
