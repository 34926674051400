/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { VehicleBoxValidation } from '../../models/vehicle-box-validation';

export interface GetBoxValidations$Params {

/**
 * id of vehicle
 */
  vehicleId: number;

/**
 * the portalUid of the customer
 */
  customers: string;

/**
 * the boxTypeId (optional filter)
 */
  boxTypeId?: number;

/**
 * show all validations ignoring time (optional)
 */
  showAll?: boolean;
}

export function getBoxValidations(http: HttpClient, rootUrl: string, params: GetBoxValidations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VehicleBoxValidation>>> {
  const rb = new RequestBuilder(rootUrl, getBoxValidations.PATH, 'get');
  if (params) {
    rb.path('vehicleId', params.vehicleId, {});
    rb.query('customers', params.customers, {});
    rb.query('boxTypeId', params.boxTypeId, {});
    rb.query('showAll', params.showAll, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<VehicleBoxValidation>>;
    })
  );
}

getBoxValidations.PATH = '/vehicles/{vehicleId}/boxvalidations';
