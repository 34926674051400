/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { insertBulk } from '../fn/csv-parser/insert-bulk';
import { InsertBulk$Params } from '../fn/csv-parser/insert-bulk';
import { ParseResult } from '../models/parse-result';
import { parseVehicles } from '../fn/csv-parser/parse-vehicles';
import { ParseVehicles$Params } from '../fn/csv-parser/parse-vehicles';
import { Vehicle } from '../models/vehicle';


/**
 * Parse csv exports of vehicle lists
 */
@Injectable({ providedIn: 'root' })
export class CsvParserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `parseVehicles()` */
  static readonly ParseVehiclesPath = '/csv-parser/{parserType}';

  /**
   * Get vehicles from a given csv
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parseVehicles()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  parseVehicles$Response(params: ParseVehicles$Params, context?: HttpContext): Observable<StrictHttpResponse<ParseResult>> {
    return parseVehicles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get vehicles from a given csv
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parseVehicles$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  parseVehicles(params: ParseVehicles$Params, context?: HttpContext): Observable<ParseResult> {
    return this.parseVehicles$Response(params, context).pipe(
      map((r: StrictHttpResponse<ParseResult>): ParseResult => r.body)
    );
  }

  /** Path part for operation `insertBulk()` */
  static readonly InsertBulkPath = '/csv-parser/insert-bulk';

  /**
   * Inserts vehicles in bulk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertBulk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insertBulk$Response(params: InsertBulk$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Vehicle>>> {
    return insertBulk(this.http, this.rootUrl, params, context);
  }

  /**
   * Inserts vehicles in bulk
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insertBulk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insertBulk(params: InsertBulk$Params, context?: HttpContext): Observable<Array<Vehicle>> {
    return this.insertBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Vehicle>>): Array<Vehicle> => r.body)
    );
  }

}
