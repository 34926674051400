/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { activateCustomerAccess } from '../fn/pia-controller/activate-customer-access';
import { ActivateCustomerAccess$Params } from '../fn/pia-controller/activate-customer-access';
import { createCustomer } from '../fn/pia-controller/create-customer';
import { CreateCustomer$Params } from '../fn/pia-controller/create-customer';
import { createCustomerFromPiaCustomer } from '../fn/pia-controller/create-customer-from-pia-customer';
import { CreateCustomerFromPiaCustomer$Params } from '../fn/pia-controller/create-customer-from-pia-customer';
import { Customer } from '../models/customer';
import { deactivateCustomerAccess } from '../fn/pia-controller/deactivate-customer-access';
import { DeactivateCustomerAccess$Params } from '../fn/pia-controller/deactivate-customer-access';
import { deleteCustomer } from '../fn/pia-controller/delete-customer';
import { DeleteCustomer$Params } from '../fn/pia-controller/delete-customer';
import { getCustomerByBaseId } from '../fn/pia-controller/get-customer-by-base-id';
import { GetCustomerByBaseId$Params } from '../fn/pia-controller/get-customer-by-base-id';
import { getCustomerByNumber } from '../fn/pia-controller/get-customer-by-number';
import { GetCustomerByNumber$Params } from '../fn/pia-controller/get-customer-by-number';
import { getCustomerByPiaSvgCustomerIdTypeAhead } from '../fn/pia-controller/get-customer-by-pia-svg-customer-id-type-ahead';
import { GetCustomerByPiaSvgCustomerIdTypeAhead$Params } from '../fn/pia-controller/get-customer-by-pia-svg-customer-id-type-ahead';
import { getCustomerByPortalId } from '../fn/pia-controller/get-customer-by-portal-id';
import { GetCustomerByPortalId$Params } from '../fn/pia-controller/get-customer-by-portal-id';
import { getCustomers } from '../fn/pia-controller/get-customers';
import { GetCustomers$Params } from '../fn/pia-controller/get-customers';
import { getDefaultPhoneNumber } from '../fn/pia-controller/get-default-phone-number';
import { GetDefaultPhoneNumber$Params } from '../fn/pia-controller/get-default-phone-number';
import { getKeyFigures } from '../fn/pia-controller/get-key-figures';
import { GetKeyFigures$Params } from '../fn/pia-controller/get-key-figures';
import { getSvgContact } from '../fn/pia-controller/get-svg-contact';
import { GetSvgContact$Params } from '../fn/pia-controller/get-svg-contact';
import { getSvgCustomerNumberAllocation } from '../fn/pia-controller/get-svg-customer-number-allocation';
import { GetSvgCustomerNumberAllocation$Params } from '../fn/pia-controller/get-svg-customer-number-allocation';
import { KeyFigures } from '../models/key-figures';
import { ResponseCustomer } from '../models/response-customer';
import { ResponsePiaCustomerFieldsProjection } from '../models/response-pia-customer-fields-projection';
import { SvgContact } from '../models/svg-contact';
import { SvgCustomerNumberAllocation } from '../models/svg-customer-number-allocation';
import { updateCustomerBaseData } from '../fn/pia-controller/update-customer-base-data';
import { UpdateCustomerBaseData$Params } from '../fn/pia-controller/update-customer-base-data';
import { updateLegalRepresentative } from '../fn/pia-controller/update-legal-representative';
import { UpdateLegalRepresentative$Params } from '../fn/pia-controller/update-legal-representative';
import { updateTaxNumber } from '../fn/pia-controller/update-tax-number';
import { UpdateTaxNumber$Params } from '../fn/pia-controller/update-tax-number';
import { updateVatNumber } from '../fn/pia-controller/update-vat-number';
import { UpdateVatNumber$Params } from '../fn/pia-controller/update-vat-number';
import { validateCustomerNumber } from '../fn/pia-controller/validate-customer-number';
import { ValidateCustomerNumber$Params } from '../fn/pia-controller/validate-customer-number';
import { validateFuelcardCustomerNumber } from '../fn/pia-controller/validate-fuelcard-customer-number';
import { ValidateFuelcardCustomerNumber$Params } from '../fn/pia-controller/validate-fuelcard-customer-number';

@Injectable({ providedIn: 'root' })
export class PiaControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateVatNumber()` */
  static readonly UpdateVatNumberPath = '/customers/{id}/vatNumber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVatNumber()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVatNumber$Response(params: UpdateVatNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<Customer>> {
    return updateVatNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateVatNumber$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVatNumber(params: UpdateVatNumber$Params, context?: HttpContext): Observable<Customer> {
    return this.updateVatNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<Customer>): Customer => r.body)
    );
  }

  /** Path part for operation `updateTaxNumber()` */
  static readonly UpdateTaxNumberPath = '/customers/{id}/taxNumber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTaxNumber()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTaxNumber$Response(params: UpdateTaxNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<Customer>> {
    return updateTaxNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTaxNumber$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTaxNumber(params: UpdateTaxNumber$Params, context?: HttpContext): Observable<Customer> {
    return this.updateTaxNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<Customer>): Customer => r.body)
    );
  }

  /** Path part for operation `updateCustomerBaseData()` */
  static readonly UpdateCustomerBaseDataPath = '/customers/{id}/base-data';

  /**
   * Update the customer base data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerBaseData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerBaseData$Response(params: UpdateCustomerBaseData$Params, context?: HttpContext): Observable<StrictHttpResponse<Customer>> {
    return updateCustomerBaseData(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the customer base data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCustomerBaseData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerBaseData(params: UpdateCustomerBaseData$Params, context?: HttpContext): Observable<Customer> {
    return this.updateCustomerBaseData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Customer>): Customer => r.body)
    );
  }

  /** Path part for operation `getCustomers()` */
  static readonly GetCustomersPath = '/customers';

  /**
   * selecting all customers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers$Response(params?: GetCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseCustomer>> {
    return getCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * selecting all customers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers(params?: GetCustomers$Params, context?: HttpContext): Observable<ResponseCustomer> {
    return this.getCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseCustomer>): ResponseCustomer => r.body)
    );
  }

  /** Path part for operation `createCustomer()` */
  static readonly CreateCustomerPath = '/customers';

  /**
   * creating a new customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomer$Response(params: CreateCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Customer>> {
    return createCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * creating a new customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomer(params: CreateCustomer$Params, context?: HttpContext): Observable<Customer> {
    return this.createCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Customer>): Customer => r.body)
    );
  }

  /** Path part for operation `createCustomerFromPiaCustomer()` */
  static readonly CreateCustomerFromPiaCustomerPath = '/customersFromPia/{svgCustomerId}';

  /**
   * Creates a portal customer for a PIA customer. If the customer exists already in KundenStamm, this object gets updated
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomerFromPiaCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomerFromPiaCustomer$Response(params: CreateCustomerFromPiaCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Customer>> {
    return createCustomerFromPiaCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a portal customer for a PIA customer. If the customer exists already in KundenStamm, this object gets updated
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCustomerFromPiaCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomerFromPiaCustomer(params: CreateCustomerFromPiaCustomer$Params, context?: HttpContext): Observable<Customer> {
    return this.createCustomerFromPiaCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Customer>): Customer => r.body)
    );
  }

  /** Path part for operation `deactivateCustomerAccess()` */
  static readonly DeactivateCustomerAccessPath = '/customers/{id}/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateCustomerAccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateCustomerAccess$Response(params: DeactivateCustomerAccess$Params, context?: HttpContext): Observable<StrictHttpResponse<Customer>> {
    return deactivateCustomerAccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deactivateCustomerAccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateCustomerAccess(params: DeactivateCustomerAccess$Params, context?: HttpContext): Observable<Customer> {
    return this.deactivateCustomerAccess$Response(params, context).pipe(
      map((r: StrictHttpResponse<Customer>): Customer => r.body)
    );
  }

  /** Path part for operation `activateCustomerAccess()` */
  static readonly ActivateCustomerAccessPath = '/customers/{id}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateCustomerAccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateCustomerAccess$Response(params: ActivateCustomerAccess$Params, context?: HttpContext): Observable<StrictHttpResponse<Customer>> {
    return activateCustomerAccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activateCustomerAccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateCustomerAccess(params: ActivateCustomerAccess$Params, context?: HttpContext): Observable<Customer> {
    return this.activateCustomerAccess$Response(params, context).pipe(
      map((r: StrictHttpResponse<Customer>): Customer => r.body)
    );
  }

  /** Path part for operation `updateLegalRepresentative()` */
  static readonly UpdateLegalRepresentativePath = '/customers/{id}/legal-representative';

  /**
   * Update the legal representative
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLegalRepresentative()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateLegalRepresentative$Response(params: UpdateLegalRepresentative$Params, context?: HttpContext): Observable<StrictHttpResponse<Customer>> {
    return updateLegalRepresentative(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the legal representative
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateLegalRepresentative$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateLegalRepresentative(params: UpdateLegalRepresentative$Params, context?: HttpContext): Observable<Customer> {
    return this.updateLegalRepresentative$Response(params, context).pipe(
      map((r: StrictHttpResponse<Customer>): Customer => r.body)
    );
  }

  /** Path part for operation `getCustomerByPortalId()` */
  static readonly GetCustomerByPortalIdPath = '/customers/{id}';

  /**
   * selecting the customer by its portal uid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerByPortalId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerByPortalId$Response(params: GetCustomerByPortalId$Params, context?: HttpContext): Observable<StrictHttpResponse<Customer>> {
    return getCustomerByPortalId(this.http, this.rootUrl, params, context);
  }

  /**
   * selecting the customer by its portal uid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerByPortalId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerByPortalId(params: GetCustomerByPortalId$Params, context?: HttpContext): Observable<Customer> {
    return this.getCustomerByPortalId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Customer>): Customer => r.body)
    );
  }

  /** Path part for operation `deleteCustomer()` */
  static readonly DeleteCustomerPath = '/customers/{id}';

  /**
   * deleting a customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomer$Response(params: DeleteCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * deleting a customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomer(params: DeleteCustomer$Params, context?: HttpContext): Observable<void> {
    return this.deleteCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getSvgContact()` */
  static readonly GetSvgContactPath = '/customers/{id}/svgcontact';

  /**
   * selecting the contact information of responsible svg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSvgContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgContact$Response(params: GetSvgContact$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgContact>> {
    return getSvgContact(this.http, this.rootUrl, params, context);
  }

  /**
   * selecting the contact information of responsible svg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSvgContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgContact(params: GetSvgContact$Params, context?: HttpContext): Observable<SvgContact> {
    return this.getSvgContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<SvgContact>): SvgContact => r.body)
    );
  }

  /** Path part for operation `getDefaultPhoneNumber()` */
  static readonly GetDefaultPhoneNumberPath = '/customers/{id}/phone';

  /**
   * getting the default phone number of the customer number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultPhoneNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultPhoneNumber$Response(params: GetDefaultPhoneNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getDefaultPhoneNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * getting the default phone number of the customer number
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDefaultPhoneNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultPhoneNumber(params: GetDefaultPhoneNumber$Params, context?: HttpContext): Observable<string> {
    return this.getDefaultPhoneNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getKeyFigures()` */
  static readonly GetKeyFiguresPath = '/customers/{id}/keyfigures';

  /**
   * selecting keyfigures of customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKeyFigures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKeyFigures$Response(params: GetKeyFigures$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KeyFigures>>> {
    return getKeyFigures(this.http, this.rootUrl, params, context);
  }

  /**
   * selecting keyfigures of customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getKeyFigures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKeyFigures(params: GetKeyFigures$Params, context?: HttpContext): Observable<Array<KeyFigures>> {
    return this.getKeyFigures$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KeyFigures>>): Array<KeyFigures> => r.body)
    );
  }

  /** Path part for operation `validateFuelcardCustomerNumber()` */
  static readonly ValidateFuelcardCustomerNumberPath = '/customers/fuelcardNumber/{fuelcardNumber}/validate';

  /**
   * validating the fuelcard customer number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateFuelcardCustomerNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateFuelcardCustomerNumber$Response(params: ValidateFuelcardCustomerNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return validateFuelcardCustomerNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * validating the fuelcard customer number
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateFuelcardCustomerNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateFuelcardCustomerNumber(params: ValidateFuelcardCustomerNumber$Params, context?: HttpContext): Observable<void> {
    return this.validateFuelcardCustomerNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getSvgCustomerNumberAllocation()` */
  static readonly GetSvgCustomerNumberAllocationPath = '/customers/customerNumberAllocation';

  /**
   * get the allocation strategy for customer numbers for each SVG
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSvgCustomerNumberAllocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgCustomerNumberAllocation$Response(params?: GetSvgCustomerNumberAllocation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SvgCustomerNumberAllocation>>> {
    return getSvgCustomerNumberAllocation(this.http, this.rootUrl, params, context);
  }

  /**
   * get the allocation strategy for customer numbers for each SVG
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSvgCustomerNumberAllocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgCustomerNumberAllocation(params?: GetSvgCustomerNumberAllocation$Params, context?: HttpContext): Observable<Array<SvgCustomerNumberAllocation>> {
    return this.getSvgCustomerNumberAllocation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SvgCustomerNumberAllocation>>): Array<SvgCustomerNumberAllocation> => r.body)
    );
  }

  /** Path part for operation `validateCustomerNumber()` */
  static readonly ValidateCustomerNumberPath = '/customers/customerNumber/{customerNumber}/validate';

  /**
   * validating the customer number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateCustomerNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateCustomerNumber$Response(params: ValidateCustomerNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return validateCustomerNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * validating the customer number
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateCustomerNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateCustomerNumber(params: ValidateCustomerNumber$Params, context?: HttpContext): Observable<void> {
    return this.validateCustomerNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCustomerByPiaSvgCustomerIdTypeAhead()` */
  static readonly GetCustomerByPiaSvgCustomerIdTypeAheadPath = '/customerByPiaId';

  /**
   * searching customers by their pia svgCustomerId starting with.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerByPiaSvgCustomerIdTypeAhead()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerByPiaSvgCustomerIdTypeAhead$Response(params: GetCustomerByPiaSvgCustomerIdTypeAhead$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponsePiaCustomerFieldsProjection>> {
    return getCustomerByPiaSvgCustomerIdTypeAhead(this.http, this.rootUrl, params, context);
  }

  /**
   * searching customers by their pia svgCustomerId starting with.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerByPiaSvgCustomerIdTypeAhead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerByPiaSvgCustomerIdTypeAhead(params: GetCustomerByPiaSvgCustomerIdTypeAhead$Params, context?: HttpContext): Observable<ResponsePiaCustomerFieldsProjection> {
    return this.getCustomerByPiaSvgCustomerIdTypeAhead$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponsePiaCustomerFieldsProjection>): ResponsePiaCustomerFieldsProjection => r.body)
    );
  }

  /** Path part for operation `getCustomerByNumber()` */
  static readonly GetCustomerByNumberPath = '/customerByNumber/{id}';

  /**
   * selecting the customer by its customerNumber
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerByNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerByNumber$Response(params: GetCustomerByNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<Customer>> {
    return getCustomerByNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * selecting the customer by its customerNumber
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerByNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerByNumber(params: GetCustomerByNumber$Params, context?: HttpContext): Observable<Customer> {
    return this.getCustomerByNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<Customer>): Customer => r.body)
    );
  }

  /** Path part for operation `getCustomerByBaseId()` */
  static readonly GetCustomerByBaseIdPath = '/customerByBaseId/{id}';

  /**
   * selecting the customer by its customerBaseId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerByBaseId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerByBaseId$Response(params: GetCustomerByBaseId$Params, context?: HttpContext): Observable<StrictHttpResponse<Customer>> {
    return getCustomerByBaseId(this.http, this.rootUrl, params, context);
  }

  /**
   * selecting the customer by its customerBaseId
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerByBaseId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerByBaseId(params: GetCustomerByBaseId$Params, context?: HttpContext): Observable<Customer> {
    return this.getCustomerByBaseId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Customer>): Customer => r.body)
    );
  }

}
