import { LabeledEnumModel } from '../models/enum/labeledEnum.model';

export const RegistrationStatus: LabeledEnumModel = {
	CREATED: { label: 'Angelegt' },
	SUCCESS: { label: 'Erfolgreich' },
	FAILED: { label: 'Fehlgeschlagen' },
	MODIFIED: { label: 'Angepasst' },
	DECLINED: { label: 'Abgelehnt' },
	FAILED_TECHNICALLY: { label: 'Fehlgeschlagen (technisch)' },
};
