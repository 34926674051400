/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CountryInvoiceCertificateDto } from '../../models/country-invoice-certificate-dto';

export interface GetAllCountryInvoiceCertificatesForCustomerAndCountry$Params {
  puid: string;
  country: string;
}

export function getAllCountryInvoiceCertificatesForCustomerAndCountry(http: HttpClient, rootUrl: string, params: GetAllCountryInvoiceCertificatesForCustomerAndCountry$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CountryInvoiceCertificateDto>>> {
  const rb = new RequestBuilder(rootUrl, getAllCountryInvoiceCertificatesForCustomerAndCountry.PATH, 'get');
  if (params) {
    rb.path('puid', params.puid, {});
    rb.path('country', params.country, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<CountryInvoiceCertificateDto>>;
    })
  );
}

getAllCountryInvoiceCertificatesForCustomerAndCountry.PATH = '/documents/customers/{puid}/{country}';
