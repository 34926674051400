import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { RepositoryStoreService } from '../services/repository-store.service';
import { isDefined } from '@mysvg/utils';

@Pipe({ name: 'store' })
export class StorePipe implements PipeTransform {
	transform<T>(value: string | number, storeService: RepositoryStoreService<T[]>, propertyName: string): Observable<T | null> {
		if (isDefined(value) && isDefined(storeService) && isDefined(propertyName)) {
			return storeService.getFirst().pipe(
				map((data: T[]) => data.find((d: any) => d[propertyName] === value)),
				map((data: T | undefined) => (data ? data : null)),
			);
		} else {
			return of(null);
		}
	}
}
