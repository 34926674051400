import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ResponseSecurityExpertDto, SecurityExpertDto, SecurityExpertTeamControllerService } from '@myasi/api/customers/security_experts';

import { AsiApiBaseUrls } from '@mysvg/utils';
import { TypeAheadSearchParams, TypeAheadSearchServiceInterface } from '@svg-frontends/type-ahead/core';

import { API_BASE_URLS_TOKEN } from '@svg/environment';

export interface GetAllSecurityExpertsForTeamleaderSelectionParams {
	limit?: number;
	offset?: number;
	sortBy?: string;
	sortOrder?: 'ASC' | 'DESC';
	query?: string;
}

@Injectable({ providedIn: 'root' })
export class TeamleadSecurityExpertsRepositoryService implements TypeAheadSearchServiceInterface<SecurityExpertDto> {
	constructor(
		@Inject(API_BASE_URLS_TOKEN) private baseApiUrls: AsiApiBaseUrls,
		private httpClient: HttpClient,
		private securityExpertTeamControllerService: SecurityExpertTeamControllerService,
	) {}

	getMinimal(params: GetAllSecurityExpertsForTeamleaderSelectionParams): Observable<ResponseSecurityExpertDto> {
		return this.securityExpertTeamControllerService.getAllSecurityExpertsForTeamleaderSelection(params);
	}

	/**
	 * TypeAheadSearchServiceInterface<MinimalCustomer>
	 */
	getByReference(reference: string): Observable<ResponseSecurityExpertDto> {
		return this.httpClient.get<ResponseSecurityExpertDto>(`${this.baseApiUrls.customers}/security-experts/team${reference}`);
	}

	/**
	 * TypeAheadSearchServiceInterface<MinimalCustomer>
	 */
	getWithParams(params: TypeAheadSearchParams): Observable<ResponseSecurityExpertDto> {
		return this.getMinimal({ query: params.search, limit: params.limit, offset: params.offset });
	}
}
