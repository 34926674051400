export const FIGURE_PROVIDER: FigureProvider[] = [
	{ key: 'ALL', label: 'Gesamt' },
	{ key: 'ASFINAG', label: 'EuroMaut | Österreich' },
	{ key: 'DKV', label: 'DKV' },
	{ key: 'DMAUT', label: 'Deutsche Maut' },
	{ key: 'ESSO', label: 'Esso' },
	{ key: 'ESSODE', label: 'Esso DE' },
	{ key: 'EUROSHELL', label: 'EuroShell' },
	{ key: 'EVIGNETTE', label: 'E-Vignette' },
	{ key: 'FLEXBOX', label: 'Telepass' },
	{ key: 'SATELLIC', label: 'EuroMaut | Belgien' },
	{ key: 'SVGBOX', label: 'SVG-Box' },
	{ key: 'TISPL', label: 'Tispl' },
	{ key: 'TOTAL', label: 'Total' },
	{ key: 'VIALTISFDE', label: 'Vialtis ViaCard/Telepass' },
	{ key: 'VIALTISSATB', label: 'Vialtis DSRC/TelepassEU' },
];

export interface FigureProvider {
	key: string;
	label: string;
	color?: string;
}
