<svg-frontends-dialog-container
	(closeDialog)="closeDialog()"
	[closable]="true"
	[size]="inputArgs.size || DIALOG_SIZE.LARGE"
	[title]="inputArgs.title"
>
	<svg-frontends-dialog-body-container>
		<p [innerHTML]="inputArgs.body"></p>
	</svg-frontends-dialog-body-container>

	<svg-frontends-dialog-footer-container>
		<button
			(click)="closeDialog()"
			[ngClass]="{
				'btn-primary': inputArgs?.level === DIALOG_LEVEL.INFO || !(inputArgs?.level | isDefined),
				'btn-success': inputArgs?.level === DIALOG_LEVEL.SUCCESS,
				'btn-warning': inputArgs?.level === DIALOG_LEVEL.WARNING,
				'btn-danger': inputArgs?.level === DIALOG_LEVEL.DANGER
			}"
			class="btn"
			type="button"
		>
			{{ inputArgs.submitButton || 'Ok' }}
		</button>
	</svg-frontends-dialog-footer-container>
</svg-frontends-dialog-container>
