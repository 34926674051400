/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getFilterOptions } from '../fn/fuelcard-controller/get-filter-options';
import { GetFilterOptions$Params } from '../fn/fuelcard-controller/get-filter-options';
import { getFuelcards } from '../fn/fuelcard-controller/get-fuelcards';
import { GetFuelcards$Params } from '../fn/fuelcard-controller/get-fuelcards';
import { getFuelcardStaticsPerVehicle } from '../fn/fuelcard-controller/get-fuelcard-statics-per-vehicle';
import { GetFuelcardStaticsPerVehicle$Params } from '../fn/fuelcard-controller/get-fuelcard-statics-per-vehicle';
import { getServiceEndpoints } from '../fn/fuelcard-controller/get-service-endpoints';
import { GetServiceEndpoints$Params } from '../fn/fuelcard-controller/get-service-endpoints';
import { getTransactions } from '../fn/fuelcard-controller/get-transactions';
import { GetTransactions$Params } from '../fn/fuelcard-controller/get-transactions';
import { getTurnoverFigures } from '../fn/fuelcard-controller/get-turnover-figures';
import { GetTurnoverFigures$Params } from '../fn/fuelcard-controller/get-turnover-figures';
import { ResponseFuelcard } from '../models/response-fuelcard';
import { ResponseFuelcardTransaction } from '../models/response-fuelcard-transaction';
import { ResponseObject } from '../models/response-object';
import { ResponseTurnoverFigures } from '../models/response-turnover-figures';
import { ResponseVehicleFuelcardStat } from '../models/response-vehicle-fuelcard-stat';
import { sendFuelcardOrder } from '../fn/fuelcard-controller/send-fuelcard-order';
import { SendFuelcardOrder$Params } from '../fn/fuelcard-controller/send-fuelcard-order';

@Injectable({ providedIn: 'root' })
export class FuelcardControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendFuelcardOrder()` */
  static readonly SendFuelcardOrderPath = '/fuelcardOrder';

  /**
   * Send a fuelcard order by mail and by message.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendFuelcardOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendFuelcardOrder$Response(params: SendFuelcardOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendFuelcardOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * Send a fuelcard order by mail and by message.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendFuelcardOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendFuelcardOrder(params: SendFuelcardOrder$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendFuelcardOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `getTransactions()` */
  static readonly GetTransactionsPath = '/transactions';

  /**
   * View a list of fuelcard transactions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactions$Response(params: GetTransactions$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseFuelcardTransaction>> {
    return getTransactions(this.http, this.rootUrl, params, context);
  }

  /**
   * View a list of fuelcard transactions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactions(params: GetTransactions$Params, context?: HttpContext): Observable<ResponseFuelcardTransaction> {
    return this.getTransactions$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseFuelcardTransaction>): ResponseFuelcardTransaction => r.body)
    );
  }

  /** Path part for operation `getFuelcards()` */
  static readonly GetFuelcardsPath = '/fuelcards';

  /**
   * View a list of fuelcards
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFuelcards()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFuelcards$Response(params: GetFuelcards$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseFuelcard>> {
    return getFuelcards(this.http, this.rootUrl, params, context);
  }

  /**
   * View a list of fuelcards
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFuelcards$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFuelcards(params: GetFuelcards$Params, context?: HttpContext): Observable<ResponseFuelcard> {
    return this.getFuelcards$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseFuelcard>): ResponseFuelcard => r.body)
    );
  }

  /** Path part for operation `getTurnoverFigures()` */
  static readonly GetTurnoverFiguresPath = '/fuelcards/turnoverFigures';

  /**
   * a list of turnover figures for the customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTurnoverFigures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTurnoverFigures$Response(params: GetTurnoverFigures$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseTurnoverFigures>> {
    return getTurnoverFigures(this.http, this.rootUrl, params, context);
  }

  /**
   * a list of turnover figures for the customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTurnoverFigures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTurnoverFigures(params: GetTurnoverFigures$Params, context?: HttpContext): Observable<ResponseTurnoverFigures> {
    return this.getTurnoverFigures$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseTurnoverFigures>): ResponseTurnoverFigures => r.body)
    );
  }

  /** Path part for operation `getFuelcardStaticsPerVehicle()` */
  static readonly GetFuelcardStaticsPerVehiclePath = '/fuelcards/statisticsPerVehicle';

  /**
   * a list of documents for the customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFuelcardStaticsPerVehicle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFuelcardStaticsPerVehicle$Response(params: GetFuelcardStaticsPerVehicle$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseVehicleFuelcardStat>> {
    return getFuelcardStaticsPerVehicle(this.http, this.rootUrl, params, context);
  }

  /**
   * a list of documents for the customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFuelcardStaticsPerVehicle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFuelcardStaticsPerVehicle(params: GetFuelcardStaticsPerVehicle$Params, context?: HttpContext): Observable<ResponseVehicleFuelcardStat> {
    return this.getFuelcardStaticsPerVehicle$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseVehicleFuelcardStat>): ResponseVehicleFuelcardStat => r.body)
    );
  }

  /** Path part for operation `getFilterOptions()` */
  static readonly GetFilterOptionsPath = '/filter/{model}/{name}';

  /**
   * a list of filter options for the customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterOptions$Response(params: GetFilterOptions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getFilterOptions(this.http, this.rootUrl, params, context);
  }

  /**
   * a list of filter options for the customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFilterOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterOptions(params: GetFilterOptions$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getFilterOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getServiceEndpoints()` */
  static readonly GetServiceEndpointsPath = '/endpoints';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getServiceEndpoints()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceEndpoints$Response(params?: GetServiceEndpoints$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getServiceEndpoints(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getServiceEndpoints$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceEndpoints(params?: GetServiceEndpoints$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getServiceEndpoints$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
