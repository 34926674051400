import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogConfig, MatDialogModule } from '@angular/material/dialog';

import { UtilsModule } from '@mysvg/utils';
import { FormModule } from '@svg-frontends/form';
import { ClarityWrapperModule } from '@svg-frontends/theming';

import { ActionDialogComponent } from './components/action-dialog/action-dialog.component';
import { DialogBodyContainerComponent } from './components/dialog-body-container/dialog-body-container.component';
import { DialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { DialogFooterContainerComponent } from './components/dialog-footer-container/dialog-footer-container.component';
import { DialogWizardContainerComponent } from './components/dialog-wizard-container/dialog-wizard-container.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { SpecifyReasonDialogComponent } from './components/specify-reason-dialog/specify-reason-dialog.component';
import { StandardDialogService } from './services/standard-dialog.service';

export const MAC_MAT_DIALOG_CONFIG: MatDialogConfig = {
	...new MatDialogConfig(),
	autoFocus: false,
	backdropClass: 'mac-custom-modal-backdrop',
	closeOnNavigation: true,
	disableClose: true,
	panelClass: 'mac-custom-mat-dialog-container',
	restoreFocus: true,
};

@NgModule({
	declarations: [
		ActionDialogComponent,
		DialogBodyContainerComponent,
		DialogContainerComponent,
		DialogFooterContainerComponent,
		DialogWizardContainerComponent,
		InfoDialogComponent,
		SpecifyReasonDialogComponent,
	],
	exports: [DialogBodyContainerComponent, DialogContainerComponent, DialogFooterContainerComponent, DialogWizardContainerComponent],
	imports: [CommonModule, ClarityWrapperModule, MatDialogModule, FormModule, UtilsModule],
	providers: [StandardDialogService],
})
export class DialogModule {}
