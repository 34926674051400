import { Injectable } from '@angular/core';
import { PayrollCycleControllerService, PayrollCycleDto, ResponsePayrollCycleDto } from '@mysvg/api/payment';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PayrollCycleTypesStoreService extends RepositoryStoreService<PayrollCycleDto[]> {
	constructor(private payrollCycleControllerService: PayrollCycleControllerService) {
		super();
	}

	fetchData(): Observable<PayrollCycleDto[]> {
		return this.payrollCycleControllerService.getAllPayrollCycles().pipe(map((response: ResponsePayrollCycleDto) => response.data));
	}
}
