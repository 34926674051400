/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AddressDto } from '../../models/address-dto';

export interface GetCustomerAddresses$Params {

/**
 * the portal uid of the customer
 */
  portalUid: string;
  country?: string;
  customerId?: string;
  id?: string;
  type?: string;
  city?: string;
  countryCode?: string;
  countryName?: string;
  name?: string;
  name1?: string;
  name2?: string;
  name3?: string;
  street?: string;
  zip?: string;
  validFrom?: string;
  isOldAddress?: string;
}

export function getCustomerAddresses(http: HttpClient, rootUrl: string, params: GetCustomerAddresses$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AddressDto>>> {
  const rb = new RequestBuilder(rootUrl, getCustomerAddresses.PATH, 'get');
  if (params) {
    rb.path('portalUid', params.portalUid, {});
    rb.query('country', params.country, {});
    rb.query('customerId', params.customerId, {});
    rb.query('id', params.id, {});
    rb.query('type', params.type, {});
    rb.query('city', params.city, {});
    rb.query('countryCode', params.countryCode, {});
    rb.query('countryName', params.countryName, {});
    rb.query('name', params.name, {});
    rb.query('name1', params.name1, {});
    rb.query('name2', params.name2, {});
    rb.query('name3', params.name3, {});
    rb.query('street', params.street, {});
    rb.query('zip', params.zip, {});
    rb.query('validFrom', params.validFrom, {});
    rb.query('isOldAddress', params.isOldAddress, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<AddressDto>>;
    })
  );
}

getCustomerAddresses.PATH = '/customers/{portalUid}/addresses';
