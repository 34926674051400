import { Injectable } from '@angular/core';
import { TelematicControllerService, TelematicStatusDto } from '@mysvg/api/kmaster-box-management';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TelematicStatusStoreService extends RepositoryStoreService<TelematicStatusDto[]> {
	constructor(private telematicControllerService: TelematicControllerService) {
		super();
	}

	fetchData(): Observable<TelematicStatusDto[]> {
		return this.telematicControllerService.getTelematicStatuses();
	}
}
