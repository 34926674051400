import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { EditModeService } from '../services/edit-mode.service';

@Injectable({ providedIn: 'root' })
export class EnterEditModeGuard implements CanActivate {
	constructor(private editModeService: EditModeService) {}

	canActivate(): boolean {
		this.editModeService.enterEditMode();
		return true;
	}
}
