/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AttachmentDto } from '../models/attachment-dto';
import { createNewPublicProcess } from '../fn/public-controller/create-new-public-process';
import { CreateNewPublicProcess$Params } from '../fn/public-controller/create-new-public-process';
import { getPublicProcess } from '../fn/public-controller/get-public-process';
import { GetPublicProcess$Params } from '../fn/public-controller/get-public-process';
import { getPublicTask } from '../fn/public-controller/get-public-task';
import { GetPublicTask$Params } from '../fn/public-controller/get-public-task';
import { ProcessActionResponseDtoActionForm } from '../models/process-action-response-dto-action-form';
import { ProcessActionResponseDtoObject } from '../models/process-action-response-dto-object';
import { ProcessDto } from '../models/process-dto';
import { updatePublicTask } from '../fn/public-controller/update-public-task';
import { UpdatePublicTask$Params } from '../fn/public-controller/update-public-task';
import { uploadPublicFile } from '../fn/public-controller/upload-public-file';
import { UploadPublicFile$Params } from '../fn/public-controller/upload-public-file';

@Injectable({ providedIn: 'root' })
export class PublicControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPublicTask()` */
  static readonly GetPublicTaskPath = '/public/processes/tasks/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicTask$Response(params: GetPublicTask$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessActionResponseDtoObject>> {
    return getPublicTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicTask(params: GetPublicTask$Params, context?: HttpContext): Observable<ProcessActionResponseDtoObject> {
    return this.getPublicTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessActionResponseDtoObject>): ProcessActionResponseDtoObject => r.body)
    );
  }

  /** Path part for operation `updatePublicTask()` */
  static readonly UpdatePublicTaskPath = '/public/processes/tasks/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePublicTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePublicTask$Response(params: UpdatePublicTask$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessActionResponseDtoObject>> {
    return updatePublicTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePublicTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePublicTask(params: UpdatePublicTask$Params, context?: HttpContext): Observable<ProcessActionResponseDtoObject> {
    return this.updatePublicTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessActionResponseDtoObject>): ProcessActionResponseDtoObject => r.body)
    );
  }

  /** Path part for operation `createNewPublicProcess()` */
  static readonly CreateNewPublicProcessPath = '/public/processes/{processName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNewPublicProcess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNewPublicProcess$Response(params: CreateNewPublicProcess$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessActionResponseDtoActionForm>> {
    return createNewPublicProcess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createNewPublicProcess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNewPublicProcess(params: CreateNewPublicProcess$Params, context?: HttpContext): Observable<ProcessActionResponseDtoActionForm> {
    return this.createNewPublicProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessActionResponseDtoActionForm>): ProcessActionResponseDtoActionForm => r.body)
    );
  }

  /** Path part for operation `uploadPublicFile()` */
  static readonly UploadPublicFilePath = '/public/processes/documents/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadPublicFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadPublicFile$Response(params: UploadPublicFile$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachmentDto>> {
    return uploadPublicFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadPublicFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadPublicFile(params: UploadPublicFile$Params, context?: HttpContext): Observable<AttachmentDto> {
    return this.uploadPublicFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachmentDto>): AttachmentDto => r.body)
    );
  }

  /** Path part for operation `getPublicProcess()` */
  static readonly GetPublicProcessPath = '/public/processes/{processId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicProcess$Response(params: GetPublicProcess$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessDto>> {
    return getPublicProcess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicProcess(params: GetPublicProcess$Params, context?: HttpContext): Observable<ProcessDto> {
    return this.getPublicProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessDto>): ProcessDto => r.body)
    );
  }

}
