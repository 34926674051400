import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { SecurityExpertDto } from '@myasi/api/customers/security_experts';

import { AsiContext } from '../models/asi-context.model';
import { KeycloakTokenClaims } from '../models/user-token-claims.model';
import { ContextService } from './context.service';

@Injectable({ providedIn: 'root' })
export class AsiContextService {
	constructor(private contextService: ContextService<AsiContext, KeycloakTokenClaims>) {}

	init(): void {
		this.setPageTitleBasedOnContext();
		this.contextService.init();
	}

	hasActivity(activity: string | string[]): Observable<boolean> {
		return this.contextService.hasActivity(activity);
	}

	hasAllActivities(activities: string[]): Observable<boolean> {
		return this.contextService.hasAllActivities(activities);
	}

	getAccessToken(): string | null {
		return this.contextService.getAccessToken();
	}

	getAsiContext(): Observable<AsiContext> {
		return this.contextService.getContext();
	}

	getFirstAsiContext(): Observable<AsiContext> {
		return this.contextService.getFirstContext().pipe(first());
	}

	getFirstCustomerId(): Observable<number> {
		return this.getFirstAsiContext().pipe(
			filter((asiContext: AsiContext) => asiContext.isCustomerState()),
			map((asiContext: AsiContext) => asiContext.customerInfo.id),
		);
	}

	getFirstCustomerTypeIsASDType(): Observable<boolean> {
		return this.getFirstAsiContext().pipe(
			filter((asiContext: AsiContext) => asiContext.isCustomerState()),
			map((asiContext: AsiContext) => asiContext.customerInfo.customerType.id === 1),
		);
	}

	logout(): void {
		this.contextService.logout();
	}

	createSecurityExpertFromLoggedInUser(): Observable<SecurityExpertDto> {
		return this.getFirstAsiContext().pipe(
			map((context: AsiContext) => ({
				firstname: context.firstName,
				id: context.id,
				lastname: context.lastName,
				serviceProvider: context.svgInfo,
				username: context.userName,
			})),
		);
	}

	private setPageTitleBasedOnContext(): void {
		this.getAsiContext().subscribe((asiContext: AsiContext) => {
			if (asiContext.isCustomerState()) {
				window.document.title = `(${asiContext.customerInfo.customerNumber}) ${asiContext.customerInfo.displayName}`;
			} else if (asiContext.isStaffState()) {
				window.document.title = 'Mitarbeiter';
			} else {
				window.document.title = 'myASI Portal';
			}
		});
	}
}
