/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DocumentTypeDto } from '../models/document-type-dto';
import { getDocumentTypes } from '../fn/public-controller/get-document-types';
import { GetDocumentTypes$Params } from '../fn/public-controller/get-document-types';
import { getDocumentTypesSafeties } from '../fn/public-controller/get-document-types-safeties';
import { GetDocumentTypesSafeties$Params } from '../fn/public-controller/get-document-types-safeties';
import { matchPortalUidByParam } from '../fn/public-controller/match-portal-uid-by-param';
import { MatchPortalUidByParam$Params } from '../fn/public-controller/match-portal-uid-by-param';
import { registerNew } from '../fn/public-controller/register-new';
import { RegisterNew$Params } from '../fn/public-controller/register-new';
import { Registration } from '../models/registration';

@Injectable({ providedIn: 'root' })
export class PublicControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `registerNew()` */
  static readonly RegisterNewPath = '/public/register';

  /**
   * Register a new customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerNew()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerNew$Response(params: RegisterNew$Params, context?: HttpContext): Observable<StrictHttpResponse<Registration>> {
    return registerNew(this.http, this.rootUrl, params, context);
  }

  /**
   * Register a new customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerNew$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerNew(params: RegisterNew$Params, context?: HttpContext): Observable<Registration> {
    return this.registerNew$Response(params, context).pipe(
      map((r: StrictHttpResponse<Registration>): Registration => r.body)
    );
  }

  /** Path part for operation `matchPortalUidByParam()` */
  static readonly MatchPortalUidByParamPath = '/public/login-with-customer-param/{paramType}/{paramValue}';

  /**
   * Convert customer param to portalUid and redirect to login
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `matchPortalUidByParam()` instead.
   *
   * This method doesn't expect any request body.
   */
  matchPortalUidByParam$Response(params: MatchPortalUidByParam$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return matchPortalUidByParam(this.http, this.rootUrl, params, context);
  }

  /**
   * Convert customer param to portalUid and redirect to login
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `matchPortalUidByParam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  matchPortalUidByParam(params: MatchPortalUidByParam$Params, context?: HttpContext): Observable<void> {
    return this.matchPortalUidByParam$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getDocumentTypes()` */
  static readonly GetDocumentTypesPath = '/public/documents/types';

  /**
   * Get a list of all document types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes$Response(params?: GetDocumentTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentTypeDto>>> {
    return getDocumentTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of all document types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes(params?: GetDocumentTypes$Params, context?: HttpContext): Observable<Array<DocumentTypeDto>> {
    return this.getDocumentTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentTypeDto>>): Array<DocumentTypeDto> => r.body)
    );
  }

  /** Path part for operation `getDocumentTypesSafeties()` */
  static readonly GetDocumentTypesSafetiesPath = '/public/documents/types/safeties';

  /**
   * Get a list of all document types safeties (crefo and rv)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentTypesSafeties()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypesSafeties$Response(params?: GetDocumentTypesSafeties$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentTypeDto>>> {
    return getDocumentTypesSafeties(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of all document types safeties (crefo and rv)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentTypesSafeties$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypesSafeties(params?: GetDocumentTypesSafeties$Params, context?: HttpContext): Observable<Array<DocumentTypeDto>> {
    return this.getDocumentTypesSafeties$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentTypeDto>>): Array<DocumentTypeDto> => r.body)
    );
  }

}
