export interface Column {
	label: string;
	tooltip?: string;
	pipe?: any;
	pipeArgs?: any[];
	exportFormat?: string;
}

const XLSX_CURRENCY_FORMAT = '#,##0.00\\ "€"';

export const ColumnEnum: { [key: string]: Column } = {
	abatementDEsso: { label: 'DEsso Abschläge in €' },
	abatementDKV: { label: 'DKV Abschläge in €' },
	abatementEM: { label: 'EM Abschläge in €' },
	abatementEsso: { label: 'Esso Abschläge in €' },
	abatementEuroShell: { label: 'EuroShell Abschläge in €' },
	abatementFleXbox: { label: 'fleXbox Abschläge in €' },
	abatementTC: { label: 'TC Transaktionen in €' },
	abatementTotal: { label: 'Total Abschläge in €' },
	actual: { label: 'Aktuelles Risiko in €' },
	agesEmissionClass: { label: 'Vignetten Schadstoffklasse' },
	asi: { label: 'Arbeitssicherheit', pipe: 'bool' },
	availableDocTypes: { label: 'Dokumenten-Kategorien', pipe: 'vehicle-documents' },
	axleClassVignette: { label: 'Achsklasse' },
	axles: { label: 'Achszahl Zugmaschine' },
	blockReason: { label: 'Sperrgrund' },
	boxNumber: { label: 'Nummer' },
	boxStatus: { label: 'Status' },
	boxType: { label: 'Typ' },
	BUS: { label: 'Bus' },
	CAR: { label: 'PKW' },
	cabinType: { label: 'Kabinentyp' },
	category: { label: 'Kategorie' },
	city: { label: 'Stadt' },
	claim: { label: 'Forderung in €' },
	co2ExhaustEmission: { label: 'CO2 Emission' },
	company: { label: 'Firma' },
	contractNumber: { label: 'Vertragsnummer' },
	costCenter: { label: 'Kostenstelle' },
	countries: { label: 'Länder', pipe: 'list-mapped-property-to-string', pipeArgs: ['countryAbbreviation'] },
	country: { label: 'Land' },
	countryIsoCode: { label: 'Land' },
	createdAt: { label: 'Anlagedatum', pipe: 'date', pipeArgs: ['dd.MM.YYYY'] },
	createdDate: { label: 'Bestelldatum', pipe: 'date', pipeArgs: ['dd.MM.YYYY HH:mm'] },
	currencyISO: { label: 'Währung' },
	customerName: { label: 'Kundenname' },
	customerNumber: { label: 'Kunden-Nr.' },
	customerSvgNumber: { label: 'SVG-ID' },
	customerSvgId: { label: 'Kunden-ID' },
	deregistrationDate: { label: 'Abgemeldet am', pipe: 'date', pipeArgs: ['dd.MM.YYYY'] },
	description: { label: 'Beschreibung' },
	DIESEL: { label: 'Diesel' },
	diff: { label: 'Polster in €' },
	displayOptionVignette: { label: 'Für E-Vignetten benutzbar', pipe: 'bool' },
	dkvOps: { label: 'Offene Posten Dkv in €' },
	dkvCustomerNumber: { label: 'DKV-Kundennr.' },
	documentNumber: { label: 'Belegnummer' },
	downPayment: { label: 'Anzahlung in €' },
	education: { label: 'Weiterbildung', pipe: 'bool' },
	email: { label: 'E-Mail' },
	end: { label: 'Ende', pipe: 'date', pipeArgs: ['dd.MM.YYYY'] },
	enginePower: { label: 'Nennleistung' },
	errorMessage: { label: 'Fehler' },
	euroEmissionClass: { label: 'Schadstoffklasse' },
	eurovignetteNumber: { label: 'Vignette' },
	evignette: { label: 'E-Vignetten', pipe: 'bool' },
	expirationDate: { label: 'Ablaufdatum', pipe: 'date', pipeArgs: ['dd.MM.YYYY HH:mm'] },
	expiresAt: { label: 'Ablaufdatum', pipe: 'date', pipeArgs: ['dd.MM.yyyy'] },
	FINANCING: { label: 'Finanzierung' },
	firstRegistration: { label: 'Erstzulassung', pipe: 'date', pipeArgs: ['dd.MM.YYYY'] },
	fuelcard: { label: 'Tankkarten', pipe: 'bool' },
	fuelType: { label: 'Kraftstoffart' },
	GAS: { label: 'Benzin' },
	grossAmountLW: { label: 'Rabatte' },
	guarantee: { label: 'Sicherheit in €' },
	height: { label: 'Höhe' },
	homepage: { label: 'Homepage' },
	id: { label: 'Id' },
	info: { label: 'Info' },
	invoiceDate: { label: 'Rechnungsdatum' },
	invoiceNumber: { label: 'Belegnummer' },
	invoiceNumberCountry: { label: 'Rechnungsnummer-Land' },
	keyNumber: { label: 'Schlüsselnummer' },
	kravag: { label: 'Kravag Versicherungen', pipe: 'bool' },
	lastUsedAt: { label: 'Zuletzt Benutzt am', pipe: 'date', pipeArgs: ['dd.MM.YYYY HH:mm'] },
	LEASING: { label: 'Leasing' },
	lessor: { label: 'Finanzierungsgeber' },
	licensePlate: { label: 'Kennzeichen' },
	licensePlateCountry: { label: 'Kfz Land' },
	licenseplateNation: { label: 'Kfz Land' },
	licensePlateNation: { label: 'Kfz Land' },
	licensePlateNumber: { label: 'Kfz Kennzeichen' },
	licenseplateNumber: { label: 'Kfz Kennzeichen' },
	LPG: { label: 'LPG' },
	material: { label: 'Material' },
	materialDescription: { label: 'Material Beschreibung' },
	materialGroup: { label: 'Material Gruppe' },
	maxLoad: { label: 'max. Ladung' },
	messageCategory: { label: 'Kategorie' },
	messageCreated: { label: 'Erstellt am' },
	messageCreatorName: { label: 'Erstellt von' },
	messageCustomer: { label: 'Kunde' },
	messageEditor: { label: 'Bearbeiter' },
	messageMessage: { label: 'Nachrichtentext' },
	messagePriority: { label: 'Priorität' },
	messageSolved: { label: 'Abgearbeitet seit' },
	messageStatus: { label: 'Status' },
	messageSubcategory: { label: 'Unterkategorie' },
	messageSvg: { label: 'Svg' },
	mileage: { label: 'Kilometerstand' },
	mileageDate: { label: 'Datum Kilometerstand', pipe: 'date', pipeArgs: ['dd.MM.YYYY'] },
	modificationDate: { label: 'Änderungsdatum' },
	monthlyRate: { label: 'Monatsrate in €' },
	newCustomer: { label: 'Neukunde', pipe: 'bool' },
	numberOfEetsBoxes: { label: 'Anzahl EETS Boxen' },
	numberOfSvgBoxes: { label: 'Anzahl SVG Boxen' },
	numberOfEVignettes: { label: 'Anzahl E-Vignetten' },
	numberOfEssoFuelCards: { label: 'Anzahl Esso Tankkarten' },
	numberOfTotalFuelCards: { label: 'Anzahl Total Tankkarten' },
	numberOfShellFuelCards: { label: 'Anzahl Shell Tankkarten' },
	numberOfDkvFuelCards: { label: 'Anzahl DKV Tankkarten' },
	open: { label: 'Offener Rechnungsbetrag in €' },
	OTHER: { label: 'Sonstige' },
	overwriteEmail: { label: 'E-Mail überschreiben', pipe: 'bool' },
	pan: { label: 'PAN' },
	percentage: { label: 'Prozentsatz', pipe: 'percent', pipeArgs: ['0.0-2'] },
	phone: { label: 'Telefon' },
	price: { label: 'Preis', pipe: 'currency', pipeArgs: ['EUR', 'symbol'], exportFormat: XLSX_CURRENCY_FORMAT },
	provider: { label: 'Anbieter' },
	providers: { label: 'Anbieter' },
	purchasePrice: { label: 'Kaufpreis in €' },
	quantity: { label: 'Menge' },
	quantityUnit: { label: 'Einheit' },
	rebateGrossAmount: { label: 'Rabatt Brutto', pipe: 'currency', pipeArgs: ['EUR', 'symbol'], exportFormat: XLSX_CURRENCY_FORMAT },
	rebateNetAmount: { label: 'Rabatt Netto', pipe: 'currency', pipeArgs: ['EUR', 'symbol'], exportFormat: XLSX_CURRENCY_FORMAT },
	registrationCreatedAt: { label: 'Erstelldatum', pipe: 'date', pipeArgs: ['dd.MM.YYYY HH:mm'] },
	registrationLastModifiedAt: { label: 'Zuletzt geändert am', pipe: 'date', pipeArgs: ['dd.MM.YYYY HH:mm'] },
	registrationLastModifiedBy: { label: 'Zuletzt geändert von' },
	registrationStatus: { label: 'Status', pipe: 'enum', pipeArgs: ['registrationStatus', 'label'] },
	removable: { label: 'Löschbar' },
	residualAmount: { label: 'Restwert in €' },
	scope: { label: 'Leistungen' },
	SEMITRAILER: { label: 'Sattelzugaufleger' },
	serviceCardNumber: { label: 'Servicekartennummer' },
	serviceFeeGrossAmount: {
		label: 'Servicegebühr Brutto',
		pipe: 'currency',
		pipeArgs: ['EUR', 'symbol'],
		exportFormat: XLSX_CURRENCY_FORMAT,
	},
	serviceFeeNetAmount: {
		label: 'Servicegebühr Netto',
		pipe: 'currency',
		pipeArgs: ['EUR', 'symbol'],
		exportFormat: XLSX_CURRENCY_FORMAT,
	},
	subGroup: { label: 'Fahrzeug-Untergruppe' },
	stationNrTill: { label: 'Zielstation' },
	TRACTOR_UNIT: { label: 'Sattelzugmaschine' },
	TRAILER: { label: 'Anhänger' },
	TRUCK: { label: 'LKW' },
	activeGuaranteesSum: { label: 'Aktive Sicherheit' },
	billNumber: { label: 'Rechnungsnummer' },
	chargeableDiscounts: { label: 'kostenpflichtige Rabatte' },
	crefoNumber: { label: 'Crefonummer' },
	currency: { label: 'Währung' },
	entryTimestamp: { label: 'Einfahrtsuhrzeit', pipe: 'date', pipeArgs: ['dd.MM.yyyy HH:mm'] },
	exitTimestamp: { label: 'Ausfahrtsuhrzeit', pipe: 'date', pipeArgs: ['dd.MM.yyyy HH:mm'] },
	fromHWay: { label: 'Startautobahn' },
	fromPlace: { label: 'Startort' },
	fuelcardMaxMonth: { label: 'Monat des Maximums TK', pipe: 'date', pipeArgs: ['dd.MM.yyyy'] },
	fuelcardRiskAverage: { label: 'Durchschnittsrisiko TK' },
	fuelcardRiskMax: { label: 'Maximales Risiko TK' },
	gross: { label: 'Brutto' },
	hasTelematicPlus: { label: 'Telematik +' },
	km: { label: 'Kilometer' },
	netto: { label: 'Netto' },
	obuID: { label: 'Mautbox Id' },
	operatorFrom: { label: 'Zielbetreiber' },
	operatorTo: { label: 'Startbetreiber' },
	overallMaxMonth: { label: 'Monat des Maximums', pipe: 'date', pipeArgs: ['dd.MM.yyyy'] },
	until: { label: 'Monat des Maximums', pipe: 'date', pipeArgs: ['dd.MM.yyyy'] },
	overallRiskAverage: { label: 'Durchschnittsrisiko' },
	overallRiskMax: { label: 'Maximales Risiko' },
	plate: { label: 'Kennzeichen' },
	remainingGuaranteesSum: { label: 'Verbleibende Sicherheit' },
	rlid: { label: 'rLid' },
	roadType: { label: 'Straßentyp' },
	routeDescription: { label: 'Strecke' },
	rvInsured: { label: 'R+V versichert' },
	rvNumber: { label: 'R+V Nummer' },
	servicePoint: { label: 'Station' },
	servicePointMake: { label: 'Stationsbetreiber' },
	servicePointName: { label: 'Stationsname' },
	settled: { label: 'Fakturiert' },
	settlementDate: { label: 'Abrechnungsdatum', pipe: 'date', pipeArgs: ['dd.MM.YYYY'] },
	start: { label: 'Beginn', pipe: 'date', pipeArgs: ['dd.MM.YYYY'] },
	stationNrFrom: { label: 'Startstation' },
	status: { label: 'Status', pipe: 'state' },
	street: { label: 'Straße' },
	subCostCenter: { label: 'Subkostenstelle' },
	svgCustomerId: { label: 'SVG-Kundennummer' },
	svgId: { label: 'SVG-Nr' },
	targetMileage: { label: 'Soll km' },
	tax: { label: 'Steuer' },
	technicalTotalWeight: { label: 'techn. zul. Gesamtmasse' },
	telematicBoxes: { label: 'Telematik' },
	telematicProduct: { label: 'Telematik Produkt' },
	telematicStatus: { label: 'Telematik Status' },
	term: { label: 'Laufzeit in Monaten' },
	timestamp: { label: 'Bestelldatum', pipe: 'date', pipeArgs: ['dd.MM.YYYY HH:mm'] },
	toHWay: { label: 'Zielautobahn' },
	toPlace: { label: 'Zielort' },
	toll: { label: 'Maut', pipe: 'bool' },
	tollClass: { label: 'Mautart' },
	tollMaxMonth: { label: 'Monat des Maximums Maut', pipe: 'date', pipeArgs: ['dd.MM.yyyy'] },
	tollProvider: { label: 'Vertreiber' },
	tollRiskAverage: { label: 'Durchschnittsrisiko Maut' },
	tollRiskMax: { label: 'Maximales Risiko Maut' },
	totalGrossAmount: { label: 'Gesamt Brutto', pipe: 'currency', pipeArgs: ['EUR', 'symbol'], exportFormat: XLSX_CURRENCY_FORMAT },
	totalNetAmount: { label: 'Gesamt Netto', pipe: 'currency', pipeArgs: ['EUR', 'symbol'], exportFormat: XLSX_CURRENCY_FORMAT },
	totalWeight: { label: 'zul. Gesamtgewicht' },
	totalWeightofCombination: { label: 'zul. Gesamtzuggewicht' },
	trackingNumber: { label: 'Sendungsnummer' },
	transactionDate: { label: 'Lieferdatum', pipe: 'date', pipeArgs: ['dd.MM.YYYY'] },
	transactionDateTime: { label: 'Lieferzeitpunkt', pipe: 'date', pipeArgs: ['dd.MM.yyyy HH:mm'] },
	transactionTime: { label: 'Lieferzeit' },
	transaktionsNumber: { label: 'Transaktionsnummer' },
	type: { label: 'Fahrzeugtyp' },
	unitPrice: { label: 'Einzelpreis', pipe: 'currency', pipeArgs: ['EUR', 'symbol'], exportFormat: XLSX_CURRENCY_FORMAT },
	user: { label: 'Benutzer' },
	valid: { label: 'Aktiv', pipe: 'bool' },
	validFrom: { label: 'von', pipe: 'date', pipeArgs: ['dd.MM.YYYY'] },
	validTo: { label: 'bis', pipe: 'date', pipeArgs: ['dd.MM.YYYY'] },
	validated: { label: 'Validiert', pipe: 'bool' },
	vat: { label: 'Ust.-IdentNr.' },
	vatAmount: { label: 'MwSt. Anteil', pipe: 'currency', pipeArgs: ['EUR', 'symbol'], exportFormat: XLSX_CURRENCY_FORMAT },
	vatRate: { label: 'MwSt. Satz', pipe: 'percent', pipeArgs: ['0.0-2'] },
	vehicleCategory: { label: 'Fahrzeugkategorie' },
	co2EmissionClass: { label: 'CO2 Emissionsklasse' },
	vehicleFinancing: { label: 'Finanzierung' },
	vehicleFinancingType: { label: 'Finanzierungsart' },
	vignetteAxles: { label: 'Achszahl E-Vignette' },
	vignettes: { label: 'Vignetten' },
	vin: { label: 'Fahrzeug-Identifizierungsnummer' },
	width: { label: 'Breite' },
	zip: { label: 'PLZ' },
	zipCode: { label: 'PLZ' },
};
