/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { StandardConditionService } from './services/standard-condition.service';
import { ConditionService } from './services/condition.service';
import { BoxService } from './services/box.service';
import { AccountService } from './services/account.service';
import { DiscountService } from './services/discount.service';
import { BoxOrderService } from './services/box-order.service';
import { MySvgControllerService } from './services/my-svg-controller.service';
import { AccountTypeService } from './services/account-type.service';
import { SubAccountService } from './services/sub-account.service';
import { BoxOverviewService } from './services/box-overview.service';
import { CountryService } from './services/country.service';
import { PassageListBoControllerService } from './services/passage-list-bo-controller.service';
import { ConditionReportControllerService } from './services/condition-report-controller.service';
import { CardStatusControllerService } from './services/card-status-controller.service';
import { BoxStatusBoControllerService } from './services/box-status-bo-controller.service';
import { BoxHistoryChangeControllerService } from './services/box-history-change-controller.service';
import { BoxTypeService } from './services/box-type.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    StandardConditionService,
    ConditionService,
    BoxService,
    AccountService,
    DiscountService,
    BoxOrderService,
    MySvgControllerService,
    AccountTypeService,
    SubAccountService,
    BoxOverviewService,
    CountryService,
    PassageListBoControllerService,
    ConditionReportControllerService,
    CardStatusControllerService,
    BoxStatusBoControllerService,
    BoxHistoryChangeControllerService,
    BoxTypeService,
    ApiConfiguration
  ],
})
export class TollBoxModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<TollBoxModule> {
    return {
      ngModule: TollBoxModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: TollBoxModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('TollBoxModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
