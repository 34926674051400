import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-document-cell',
	styleUrls: ['./document-cell.component.scss'],
	templateUrl: './document-cell.component.html',
})
export class DocumentCellComponent {
	@Input() downloadLink: string;
	@Input() documentName: string;
}
