/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseVehicle } from '../../models/response-vehicle';

export interface GetVehicles$Params {

/**
 * the portalUid of the customer
 */
  customers: string;
  'vehicleFinancing.info'?: string;
  'vehicleFinancing.residualAmount'?: string;
  'vehicleFinancing.downPayment'?: string;
  'vehicleFinancing.monthlyRate'?: string;
  'vehicleFinancing.lessor'?: string;
  'vehicleFinancing.contractNumber'?: string;
  'vehicleFinancing.targetMileage'?: string;
  'vehicleFinancing.term'?: string;
  'vehicleFinancing.end'?: string;
  'vehicleFinancing.start'?: string;
  'vehicleFinancing.vehicleFinancingType'?: string;
  purchasePrice?: string;
  mileageDate?: string;
  mileage?: string;
  deregistrationDate?: string;
  fuelType?: string;
  maxLoad?: string;
  width?: string;
  height?: string;
  category?: string;
  type?: string;
  vin?: string;
  firstRegistration?: string;
  valid?: string;
  validFlexboxTelepass?: string;
  displayOptionVignette?: string;
  description?: string;
  totalWeightofCombination?: string;
  totalWeight?: string;
  technicalTotalWeight?: string;
  vignetteAxles?: string;
  axles?: string;
  agesEmissionClass?: string;
  euroEmissionClass?: string;
  licensePlate?: string;
  licensePlateNation?: string;
  co2ExhaustEmission?: string;
  enginePower?: string;
  cabinType?: string;
  subGroup?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getVehicles(http: HttpClient, rootUrl: string, params: GetVehicles$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseVehicle>> {
  const rb = new RequestBuilder(rootUrl, getVehicles.PATH, 'get');
  if (params) {
    rb.query('customers', params.customers, {});
    rb.query('vehicleFinancing.info', params['vehicleFinancing.info'], {});
    rb.query('vehicleFinancing.residualAmount', params['vehicleFinancing.residualAmount'], {});
    rb.query('vehicleFinancing.downPayment', params['vehicleFinancing.downPayment'], {});
    rb.query('vehicleFinancing.monthlyRate', params['vehicleFinancing.monthlyRate'], {});
    rb.query('vehicleFinancing.lessor', params['vehicleFinancing.lessor'], {});
    rb.query('vehicleFinancing.contractNumber', params['vehicleFinancing.contractNumber'], {});
    rb.query('vehicleFinancing.targetMileage', params['vehicleFinancing.targetMileage'], {});
    rb.query('vehicleFinancing.term', params['vehicleFinancing.term'], {});
    rb.query('vehicleFinancing.end', params['vehicleFinancing.end'], {});
    rb.query('vehicleFinancing.start', params['vehicleFinancing.start'], {});
    rb.query('vehicleFinancing.vehicleFinancingType', params['vehicleFinancing.vehicleFinancingType'], {});
    rb.query('purchasePrice', params.purchasePrice, {});
    rb.query('mileageDate', params.mileageDate, {});
    rb.query('mileage', params.mileage, {});
    rb.query('deregistrationDate', params.deregistrationDate, {});
    rb.query('fuelType', params.fuelType, {});
    rb.query('maxLoad', params.maxLoad, {});
    rb.query('width', params.width, {});
    rb.query('height', params.height, {});
    rb.query('category', params.category, {});
    rb.query('type', params.type, {});
    rb.query('vin', params.vin, {});
    rb.query('firstRegistration', params.firstRegistration, {});
    rb.query('valid', params.valid, {});
    rb.query('validFlexboxTelepass', params.validFlexboxTelepass, {});
    rb.query('displayOptionVignette', params.displayOptionVignette, {});
    rb.query('description', params.description, {});
    rb.query('totalWeightofCombination', params.totalWeightofCombination, {});
    rb.query('totalWeight', params.totalWeight, {});
    rb.query('technicalTotalWeight', params.technicalTotalWeight, {});
    rb.query('vignetteAxles', params.vignetteAxles, {});
    rb.query('axles', params.axles, {});
    rb.query('agesEmissionClass', params.agesEmissionClass, {});
    rb.query('euroEmissionClass', params.euroEmissionClass, {});
    rb.query('licensePlate', params.licensePlate, {});
    rb.query('licensePlateNation', params.licensePlateNation, {});
    rb.query('co2ExhaustEmission', params.co2ExhaustEmission, {});
    rb.query('enginePower', params.enginePower, {});
    rb.query('cabinType', params.cabinType, {});
    rb.query('subGroup', params.subGroup, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseVehicle>;
    })
  );
}

getVehicles.PATH = '/vehicles';
