import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AbstractControlComponent } from '../../classes/AbstractControlComponent';
import { FormStatus } from '../../enums/form-status.enum';
import { FormGroupService } from '../../services/form-group.service';

@UntilDestroy()
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-radio-group',
	styleUrls: ['./radio-group.component.scss'],
	templateUrl: './radio-group.component.html',
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class RadioGroupComponent extends AbstractControlComponent implements OnInit {
	FORM_STATUS = FormStatus;

	formControl: FormControl;

	@Input() controlName: string;
	@Input() disableControl: boolean = undefined;
	@Input() groupLabel?: string | TemplateRef<any>;
	@Input() helperText?: string | TemplateRef<any>;
	@Input() labels: string[];
	@Input() values: any[];

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private formGroupDirective: FormGroupDirective,
		private macFormGroupService: FormGroupService,
	) {
		super();
	}

	ngOnInit(): void {
		this.formControl = this.formGroupDirective.control.get(this.controlName) as FormControl;

		this.macFormGroupService.submit$.pipe(untilDestroyed(this)).subscribe(() => {
			FormGroupService.touchControl(this.formControl);
			this.changeDetectorRef.markForCheck();
		});
	}

	onBlur(): void {
		FormGroupService.touchControl(this.formControl);
	}
}
