<clr-dg-footer class="mac-datagrid-footer">
	<clr-dg-column-toggle>
		<clr-dg-column-toggle-title>Spalten auswählen</clr-dg-column-toggle-title>
		<clr-dg-column-toggle-button>Alle auswählen</clr-dg-column-toggle-button>
	</clr-dg-column-toggle>

	<ng-content></ng-content>

	<clr-dg-pagination
		#pagination
		*svgFrontendsLet="tableViewService.meta$ | async as meta"
		[clrDgPageSize]="tableViewOptionsService.pageSize$ | async"
		[clrDgTotalItems]="meta ? meta.totalCount : 0"
	>
		<clr-dg-page-size [clrPageSizeOptions]="pageSizeArray" svgFrontendsPageSize>{{ itemName }} pro Seite</clr-dg-page-size>
		<span *ngIf="pagination.totalItems > 0">{{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} von</span>
		{{ pagination.totalItems }} {{ itemName }}
	</clr-dg-pagination>
</clr-dg-footer>
