/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { VehicleControllerService } from './services/vehicle-controller.service';
import { TollConditionsControllerService } from './services/toll-conditions-controller.service';
import { UserTaskControllerService } from './services/user-task-controller.service';
import { PublicControllerService } from './services/public-controller.service';
import { ProcessControllerService } from './services/process-controller.service';
import { TaskControllerService } from './services/task-controller.service';
import { DebitingControllerService } from './services/debiting-controller.service';
import { TollBoxControllerService } from './services/toll-box-controller.service';
import { DocumentControllerService } from './services/document-controller.service';
import { BankDetailsControllerService } from './services/bank-details-controller.service';
import { IncidentControllerService } from './services/incident-controller.service';
import { MySvgControllerService } from './services/my-svg-controller.service';
import { ProcessDefinitionControllerService } from './services/process-definition-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    VehicleControllerService,
    TollConditionsControllerService,
    UserTaskControllerService,
    PublicControllerService,
    ProcessControllerService,
    TaskControllerService,
    DebitingControllerService,
    TollBoxControllerService,
    DocumentControllerService,
    BankDetailsControllerService,
    IncidentControllerService,
    MySvgControllerService,
    ProcessDefinitionControllerService,
    ApiConfiguration
  ],
})
export class BpmModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<BpmModule> {
    return {
      ngModule: BpmModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: BpmModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('BpmModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
