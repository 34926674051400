import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '@mysvg/utils';

import { CONTROL_ERROR_KEYS, TRANSLATED_ERROR_MESSAGE_KEY } from '../enums/control-error-keys.enum';
import { ControlError } from '../models/control-error';
import { ControlErrorFactoryService } from '../services/control-error-factory.service';

@Pipe({ name: 'controlErrorMessage' })
export class ControlErrorMessagePipe implements PipeTransform {
	constructor(private controlErrorFactoryService: ControlErrorFactoryService) {}

	transform(controlError: ControlError): string {
		if (isDefined(controlError)) {
			return this.mapToErrorMessage(controlError);
		} else {
			return null;
		}
	}

	private mapToErrorMessage(controlError: ControlError): string {
		if (controlError.key === TRANSLATED_ERROR_MESSAGE_KEY) {
			return controlError.value;
		} else {
			// includes fallback string
			return this.controlErrorFactoryService.mapToErrorMessage(controlError.key as CONTROL_ERROR_KEYS, controlError.value);
		}
	}
}
