/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CountryDto } from '../models/country-dto';
import { deleteEntityAndWebdavFile } from '../fn/discount-program-controller/delete-entity-and-webdav-file';
import { DeleteEntityAndWebdavFile$Params } from '../fn/discount-program-controller/delete-entity-and-webdav-file';
import { DiscountProgramDto } from '../models/discount-program-dto';
import { getAllCountriesForDiscountProgram } from '../fn/discount-program-controller/get-all-countries-for-discount-program';
import { GetAllCountriesForDiscountProgram$Params } from '../fn/discount-program-controller/get-all-countries-for-discount-program';
import { getAllDiscountPrograms } from '../fn/discount-program-controller/get-all-discount-programs';
import { GetAllDiscountPrograms$Params } from '../fn/discount-program-controller/get-all-discount-programs';
import { getDiscountProgramDocumentByCountryIsoCode } from '../fn/discount-program-controller/get-discount-program-document-by-country-iso-code';
import { GetDiscountProgramDocumentByCountryIsoCode$Params } from '../fn/discount-program-controller/get-discount-program-document-by-country-iso-code';
import { getDiscountProgramDocumentByHash } from '../fn/discount-program-controller/get-discount-program-document-by-hash';
import { GetDiscountProgramDocumentByHash$Params } from '../fn/discount-program-controller/get-discount-program-document-by-hash';
import { getDiscountProgramDtoByCountyIsoCode } from '../fn/discount-program-controller/get-discount-program-dto-by-county-iso-code';
import { GetDiscountProgramDtoByCountyIsoCode$Params } from '../fn/discount-program-controller/get-discount-program-dto-by-county-iso-code';
import { saveFileAndRemoveOldFile } from '../fn/discount-program-controller/save-file-and-remove-old-file';
import { SaveFileAndRemoveOldFile$Params } from '../fn/discount-program-controller/save-file-and-remove-old-file';

@Injectable({ providedIn: 'root' })
export class DiscountProgramControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `saveFileAndRemoveOldFile()` */
  static readonly SaveFileAndRemoveOldFilePath = '/discount-program/{isoCode}';

  /**
   * uploading a discount program document with overwriting.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveFileAndRemoveOldFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  saveFileAndRemoveOldFile$Response(params: SaveFileAndRemoveOldFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveFileAndRemoveOldFile(this.http, this.rootUrl, params, context);
  }

  /**
   * uploading a discount program document with overwriting.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveFileAndRemoveOldFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  saveFileAndRemoveOldFile(params: SaveFileAndRemoveOldFile$Params, context?: HttpContext): Observable<void> {
    return this.saveFileAndRemoveOldFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteEntityAndWebdavFile()` */
  static readonly DeleteEntityAndWebdavFilePath = '/discount-program/{isoCode}';

  /**
   * removing a discount program.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEntityAndWebdavFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEntityAndWebdavFile$Response(params: DeleteEntityAndWebdavFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteEntityAndWebdavFile(this.http, this.rootUrl, params, context);
  }

  /**
   * removing a discount program.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEntityAndWebdavFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEntityAndWebdavFile(params: DeleteEntityAndWebdavFile$Params, context?: HttpContext): Observable<void> {
    return this.deleteEntityAndWebdavFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getDiscountProgramDtoByCountyIsoCode()` */
  static readonly GetDiscountProgramDtoByCountyIsoCodePath = '/discount-program/{isoCode}/info';

  /**
   * get discount program document info for country.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscountProgramDtoByCountyIsoCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountProgramDtoByCountyIsoCode$Response(params: GetDiscountProgramDtoByCountyIsoCode$Params, context?: HttpContext): Observable<StrictHttpResponse<DiscountProgramDto>> {
    return getDiscountProgramDtoByCountyIsoCode(this.http, this.rootUrl, params, context);
  }

  /**
   * get discount program document info for country.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscountProgramDtoByCountyIsoCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountProgramDtoByCountyIsoCode(params: GetDiscountProgramDtoByCountyIsoCode$Params, context?: HttpContext): Observable<DiscountProgramDto> {
    return this.getDiscountProgramDtoByCountyIsoCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiscountProgramDto>): DiscountProgramDto => r.body)
    );
  }

  /** Path part for operation `getDiscountProgramDocumentByCountryIsoCode()` */
  static readonly GetDiscountProgramDocumentByCountryIsoCodePath = '/discount-program/{isoCode}/document';

  /**
   * get discount program document for country via country id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscountProgramDocumentByCountryIsoCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountProgramDocumentByCountryIsoCode$Response(params: GetDiscountProgramDocumentByCountryIsoCode$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getDiscountProgramDocumentByCountryIsoCode(this.http, this.rootUrl, params, context);
  }

  /**
   * get discount program document for country via country id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscountProgramDocumentByCountryIsoCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountProgramDocumentByCountryIsoCode(params: GetDiscountProgramDocumentByCountryIsoCode$Params, context?: HttpContext): Observable<Blob> {
    return this.getDiscountProgramDocumentByCountryIsoCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `getAllDiscountPrograms()` */
  static readonly GetAllDiscountProgramsPath = '/discount-program/documents';

  /**
   * get all discount program.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDiscountPrograms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDiscountPrograms$Response(params?: GetAllDiscountPrograms$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DiscountProgramDto>>> {
    return getAllDiscountPrograms(this.http, this.rootUrl, params, context);
  }

  /**
   * get all discount program.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllDiscountPrograms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDiscountPrograms(params?: GetAllDiscountPrograms$Params, context?: HttpContext): Observable<Array<DiscountProgramDto>> {
    return this.getAllDiscountPrograms$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DiscountProgramDto>>): Array<DiscountProgramDto> => r.body)
    );
  }

  /** Path part for operation `getDiscountProgramDocumentByHash()` */
  static readonly GetDiscountProgramDocumentByHashPath = '/discount-program/document/{documentHash}';

  /**
   * get discount program document for country via document hash.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscountProgramDocumentByHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountProgramDocumentByHash$Response(params: GetDiscountProgramDocumentByHash$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getDiscountProgramDocumentByHash(this.http, this.rootUrl, params, context);
  }

  /**
   * get discount program document for country via document hash.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscountProgramDocumentByHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountProgramDocumentByHash(params: GetDiscountProgramDocumentByHash$Params, context?: HttpContext): Observable<Blob> {
    return this.getDiscountProgramDocumentByHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `getAllCountriesForDiscountProgram()` */
  static readonly GetAllCountriesForDiscountProgramPath = '/discount-program/countries';

  /**
   * get all countries for discount program.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCountriesForDiscountProgram()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCountriesForDiscountProgram$Response(params?: GetAllCountriesForDiscountProgram$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CountryDto>>> {
    return getAllCountriesForDiscountProgram(this.http, this.rootUrl, params, context);
  }

  /**
   * get all countries for discount program.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllCountriesForDiscountProgram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCountriesForDiscountProgram(params?: GetAllCountriesForDiscountProgram$Params, context?: HttpContext): Observable<Array<CountryDto>> {
    return this.getAllCountriesForDiscountProgram$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CountryDto>>): Array<CountryDto> => r.body)
    );
  }

}
