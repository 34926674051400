export enum ErrorMessageKey {
	ANNUAL_BUDGET_NOT_NAVIGABLE = 'annualBudgetNotNavigable',
	AUTH_CUSTOMER_FORBIDDEN = 'authCustomerForbidden',
	AUTH_CUSTOMER_NOT_FOUND = 'authCustomerNotFound',
	AUTH_NO_ACTIVITIES = 'AUTH_NO_ACTIVITIES',
	CSTM_BUYER_REFERENCE_FORMAT = 'cstmBuyerReference',
	CSTM_REQUIRED_CITY = 'cstmRequiredCity',
	CSTM_REQUIRED_COMPANY = 'cstmRequiredCompany',
	CSTM_REQUIRED_EMAIL = 'cstmRequiredEmail',
	CSTM_REQUIRED_STREET = 'cstmRequiredStreet',
	CSTM_REQUIRED_ZIP = 'cstmRequiredZip',
	MISSING_RISK_ASSESSMENT_ID_FOR_DEFINING_DEFAULT = 'missingRiskAssessmentIdForDefiningDefault',
	MISSING_VEHICLE_ON_FLEX_BOX = 'missingVehicleOnFlexBox',
	NO_ACCESSIBLE_SVGS = 'noAccessibleSvgs',
	NO_PROCESS_WIZARD_MATCH = 'noProcessWizardMatch',
	SCOPE_OF_CARE_NOT_NAVIGABLE = 'scopeOfCareNotNavigable',
	TEAM_ADMIN_NOT_NAVIGABLE = 'teamAdminNotNavigable',
	USER_ADMIN_NOT_NAVIGABLE = 'userAdminNotNavigable',
	MISSING_PORTAL_UID = 'missingPortalUid',
}
