/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DocumentLinkDto } from '../models/document-link-dto';
import { DocumentMetadataDto } from '../models/document-metadata-dto';
import { generateTemplatePreviewByDocId } from '../fn/template-controller/generate-template-preview-by-doc-id';
import { GenerateTemplatePreviewByDocId$Params } from '../fn/template-controller/generate-template-preview-by-doc-id';
import { generateTemplatePreviewByUuid } from '../fn/template-controller/generate-template-preview-by-uuid';
import { GenerateTemplatePreviewByUuid$Params } from '../fn/template-controller/generate-template-preview-by-uuid';
import { getAllChecklists } from '../fn/template-controller/get-all-checklists';
import { GetAllChecklists$Params } from '../fn/template-controller/get-all-checklists';
import { getCustomerTemplates } from '../fn/template-controller/get-customer-templates';
import { GetCustomerTemplates$Params } from '../fn/template-controller/get-customer-templates';
import { getStaffTemplates } from '../fn/template-controller/get-staff-templates';
import { GetStaffTemplates$Params } from '../fn/template-controller/get-staff-templates';
import { ResponseDocumentMetadataDto } from '../models/response-document-metadata-dto';
import { TemplatePreviewDto } from '../models/template-preview-dto';
import { uploadAsdTemplate } from '../fn/template-controller/upload-asd-template';
import { UploadAsdTemplate$Params } from '../fn/template-controller/upload-asd-template';
import { uploadIvTemplate } from '../fn/template-controller/upload-iv-template';
import { UploadIvTemplate$Params } from '../fn/template-controller/upload-iv-template';
import { uploadNationwideTemplate } from '../fn/template-controller/upload-nationwide-template';
import { UploadNationwideTemplate$Params } from '../fn/template-controller/upload-nationwide-template';


/**
 * Provides operations to manage document templates.
 */
@Injectable({ providedIn: 'root' })
export class TemplateControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `uploadNationwideTemplate()` */
  static readonly UploadNationwideTemplatePath = '/documents/templates/nationwide';

  /**
   * Upload nationwide template document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadNationwideTemplate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadNationwideTemplate$Response(params?: UploadNationwideTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentMetadataDto>> {
    return uploadNationwideTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload nationwide template document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadNationwideTemplate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadNationwideTemplate(params?: UploadNationwideTemplate$Params, context?: HttpContext): Observable<DocumentMetadataDto> {
    return this.uploadNationwideTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentMetadataDto>): DocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `uploadIvTemplate()` */
  static readonly UploadIvTemplatePath = '/documents/templates/iv';

  /**
   * Upload an IV template document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadIvTemplate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadIvTemplate$Response(params?: UploadIvTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentMetadataDto>> {
    return uploadIvTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload an IV template document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadIvTemplate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadIvTemplate(params?: UploadIvTemplate$Params, context?: HttpContext): Observable<DocumentMetadataDto> {
    return this.uploadIvTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentMetadataDto>): DocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `uploadAsdTemplate()` */
  static readonly UploadAsdTemplatePath = '/documents/templates/asd';

  /**
   * Upload an ASD template document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadAsdTemplate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAsdTemplate$Response(params?: UploadAsdTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentMetadataDto>> {
    return uploadAsdTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload an ASD template document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadAsdTemplate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAsdTemplate(params?: UploadAsdTemplate$Params, context?: HttpContext): Observable<DocumentMetadataDto> {
    return this.uploadAsdTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentMetadataDto>): DocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `generateTemplatePreviewByUuid()` */
  static readonly GenerateTemplatePreviewByUuidPath = '/customers/{customerId}/complete-template-uuid/{templateUUID}';

  /**
   * Create a preview document using a template referenced by uuid and using customer data to fill the template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateTemplatePreviewByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateTemplatePreviewByUuid$Response(params: GenerateTemplatePreviewByUuid$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplatePreviewDto>> {
    return generateTemplatePreviewByUuid(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a preview document using a template referenced by uuid and using customer data to fill the template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateTemplatePreviewByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateTemplatePreviewByUuid(params: GenerateTemplatePreviewByUuid$Params, context?: HttpContext): Observable<TemplatePreviewDto> {
    return this.generateTemplatePreviewByUuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplatePreviewDto>): TemplatePreviewDto => r.body)
    );
  }

  /** Path part for operation `generateTemplatePreviewByDocId()` */
  static readonly GenerateTemplatePreviewByDocIdPath = '/customers/{customerId}/complete-template-docid/{templateDocumentId}';

  /**
   * Create a preview document using a template referenced by a template document id and using customer data to fill the template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateTemplatePreviewByDocId()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateTemplatePreviewByDocId$Response(params: GenerateTemplatePreviewByDocId$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplatePreviewDto>> {
    return generateTemplatePreviewByDocId(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a preview document using a template referenced by a template document id and using customer data to fill the template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateTemplatePreviewByDocId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateTemplatePreviewByDocId(params: GenerateTemplatePreviewByDocId$Params, context?: HttpContext): Observable<TemplatePreviewDto> {
    return this.generateTemplatePreviewByDocId$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplatePreviewDto>): TemplatePreviewDto => r.body)
    );
  }

  /** Path part for operation `getStaffTemplates()` */
  static readonly GetStaffTemplatesPath = '/documents/templates';

  /**
   * Get document templates in the staff context.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStaffTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStaffTemplates$Response(params?: GetStaffTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocumentMetadataDto>> {
    return getStaffTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get document templates in the staff context.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStaffTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStaffTemplates(params?: GetStaffTemplates$Params, context?: HttpContext): Observable<ResponseDocumentMetadataDto> {
    return this.getStaffTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseDocumentMetadataDto>): ResponseDocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `getCustomerTemplates()` */
  static readonly GetCustomerTemplatesPath = '/documents/templates/{customerId}';

  /**
   * Get templates applicable for a customer (in customer context)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerTemplates$Response(params: GetCustomerTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocumentMetadataDto>> {
    return getCustomerTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get templates applicable for a customer (in customer context)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerTemplates(params: GetCustomerTemplates$Params, context?: HttpContext): Observable<ResponseDocumentMetadataDto> {
    return this.getCustomerTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseDocumentMetadataDto>): ResponseDocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `getAllChecklists()` */
  static readonly GetAllChecklistsPath = '/documents/checklists';

  /**
   * Get all checklists as zip file.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllChecklists()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllChecklists$Response(params?: GetAllChecklists$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
    return getAllChecklists(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all checklists as zip file.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllChecklists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllChecklists(params?: GetAllChecklists$Params, context?: HttpContext): Observable<DocumentLinkDto> {
    return this.getAllChecklists$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentLinkDto>): DocumentLinkDto => r.body)
    );
  }

}
