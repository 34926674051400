import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClarityModule } from '@clr/angular';
import { ClarityWrapperModule } from './clarity-wrapper.module';
import { OwlWrapperModule } from './owl-wrapper.module';

@NgModule({
	imports: [CommonModule, ClarityModule, OwlWrapperModule],
	exports: [ClarityWrapperModule, OwlWrapperModule],
})
export class ThemingModule {}
