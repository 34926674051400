/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { sendExcelFile } from '../fn/dkv-e-invoircing-controller/send-excel-file';
import { SendExcelFile$Params } from '../fn/dkv-e-invoircing-controller/send-excel-file';

@Injectable({ providedIn: 'root' })
export class DkvEInvoircingControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendExcelFile()` */
  static readonly SendExcelFilePath = '/dkveinvoircing/sendexcelfile';

  /**
   * This will be used to send excel file for dkv
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendExcelFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendExcelFile$Response(params?: SendExcelFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendExcelFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This will be used to send excel file for dkv
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendExcelFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendExcelFile(params?: SendExcelFile$Params, context?: HttpContext): Observable<void> {
    return this.sendExcelFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
