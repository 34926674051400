/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CustomerInfo } from '../../models/customer-info';

export interface GetCustomerInfo$Params {

/**
 * the svgCustomerNumber of the desired customer
 */
  svgCustomerNumber?: string;

/**
 * the alias purpose of the desired customer
 */
  aliasPurpose?: string;

/**
 * the alias type of the desired customer
 */
  aliasType?: string;

/**
 * the alias value of the desired customer
 */
  alias?: string;
}

export function getCustomerInfo(http: HttpClient, rootUrl: string, params?: GetCustomerInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerInfo>> {
  const rb = new RequestBuilder(rootUrl, getCustomerInfo.PATH, 'get');
  if (params) {
    rb.query('svgCustomerNumber', params.svgCustomerNumber, {});
    rb.query('aliasPurpose', params.aliasPurpose, {});
    rb.query('aliasType', params.aliasType, {});
    rb.query('alias', params.alias, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CustomerInfo>;
    })
  );
}

getCustomerInfo.PATH = '/auth/customerInfo';
