import { Injectable } from '@angular/core';
import { AccountOverviewView, AccountService } from '@mysvg/api/toll-box';
import { SvgContextService } from '@svg-frontends/session';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AccountStoreService extends RepositoryStoreService<AccountOverviewView[]> {
	constructor(private accountService: AccountService, private svgContextService: SvgContextService) {
		super();
	}

	fetchData(): Observable<AccountOverviewView[]> {
		return this.svgContextService
			.getFirstPortalUid()
			.pipe(mergeMap((portalUid: string) => this.accountService.getAccountOverviewViewsForCustomer({ customers: portalUid })));
	}

	getAccountById(accountId: number): Observable<AccountOverviewView | undefined> {
		return this.getFirst().pipe(
			map((accounts: AccountOverviewView[]) => accounts.find((account: AccountOverviewView) => account.account.id === accountId)),
		);
	}
}
