/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BoxTypeView } from '../../models/box-type-view';

export interface GetBoxTypes$Params {

/**
 * Account id
 */
  accountTypeId?: number;

/**
 * Subaccount id
 */
  subAccountTypeId?: number;

/**
 * Box ids to be excluded
 */
  excludeId?: Array<number>;
}

export function getBoxTypes(http: HttpClient, rootUrl: string, params?: GetBoxTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BoxTypeView>>> {
  const rb = new RequestBuilder(rootUrl, getBoxTypes.PATH, 'get');
  if (params) {
    rb.query('accountTypeId', params.accountTypeId, {});
    rb.query('subAccountTypeId', params.subAccountTypeId, {});
    rb.query('excludeId', params.excludeId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<BoxTypeView>>;
    })
  );
}

getBoxTypes.PATH = '/box-types';
