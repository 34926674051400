import { Injectable } from '@angular/core';

import { CONTEXT_KEY } from '../../config/context-key';
import { UrlParamsService } from '../url-params.service';
import { WindowTabManagmentService } from '../window-tab-managment.service';

@Injectable({ providedIn: 'root' })
export class CustomerSelectService {
	private newWindowCallback: () => void;

	constructor(private urlParamsService: UrlParamsService, private windowTabManagmentService: WindowTabManagmentService) {}

	openNewTabWithCustomerContext(customerPortalUid: string | number | undefined, url: string = '/'): void {
		// gets url with query param context. Is relative if within pwa, absolute otherwise
		const enrichedUrl: string = this.urlParamsService.append(
			url,
			CONTEXT_KEY,
			customerPortalUid?.toString(),
			true,
			this.windowTabManagmentService.isPWA(),
		);

		this.windowTabManagmentService.createNewTab(enrichedUrl);

		this.callNewWindowCallback();
	}

	private callNewWindowCallback(): void {
		if (this.newWindowCallback) {
			this.newWindowCallback();
		}
	}
}
