/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BankDetailsResponse } from '../models/bank-details-response';
import { updateBankDetails } from '../fn/bank-details-controller/update-bank-details';
import { UpdateBankDetails$Params } from '../fn/bank-details-controller/update-bank-details';

@Injectable({ providedIn: 'root' })
export class BankDetailsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateBankDetails()` */
  static readonly UpdateBankDetailsPath = '/bankdetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBankDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBankDetails$Response(params: UpdateBankDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<BankDetailsResponse>> {
    return updateBankDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBankDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBankDetails(params: UpdateBankDetails$Params, context?: HttpContext): Observable<BankDetailsResponse> {
    return this.updateBankDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankDetailsResponse>): BankDetailsResponse => r.body)
    );
  }

}
