/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAuditUpdates } from '../fn/audit-controller/get-audit-updates';
import { GetAuditUpdates$Params } from '../fn/audit-controller/get-audit-updates';
import { ResponseAuditUpdate } from '../models/response-audit-update';

@Injectable({ providedIn: 'root' })
export class AuditControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAuditUpdates()` */
  static readonly GetAuditUpdatesPath = '/auditing/{customerBaseId}/updates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuditUpdates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuditUpdates$Response(params: GetAuditUpdates$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseAuditUpdate>> {
    return getAuditUpdates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuditUpdates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuditUpdates(params: GetAuditUpdates$Params, context?: HttpContext): Observable<ResponseAuditUpdate> {
    return this.getAuditUpdates$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseAuditUpdate>): ResponseAuditUpdate => r.body)
    );
  }

}
