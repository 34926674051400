import dayjs from 'dayjs';

export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';

export const convertLocalDateToDate = (date: string | null): string | null =>
	date !== null ? dayjs(date, BACKEND_DATE_FORMAT).toISOString() : null;

export const convertDateToLocalDate = (date: string | null): string | null =>
	date !== null ? dayjs(date).format(BACKEND_DATE_FORMAT) : null;

export const convertToIsoDateString = (date: string | null): string | null => (date !== null ? new Date(date).toISOString() : null);
