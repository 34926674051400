/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { lockCustomer } from '../fn/actions-controller/lock-customer';
import { LockCustomer$Params } from '../fn/actions-controller/lock-customer';
import { removeCustomerLock } from '../fn/actions-controller/remove-customer-lock';
import { RemoveCustomerLock$Params } from '../fn/actions-controller/remove-customer-lock';

@Injectable({ providedIn: 'root' })
export class ActionsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `lockCustomer()` */
  static readonly LockCustomerPath = '/customers/{id}/lock';

  /**
   * create lock order for customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lockCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lockCustomer$Response(params: LockCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return lockCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * create lock order for customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lockCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lockCustomer(params: LockCustomer$Params, context?: HttpContext): Observable<void> {
    return this.lockCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removeCustomerLock()` */
  static readonly RemoveCustomerLockPath = '/customers/{id}/lock';

  /**
   * remove lock order for customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeCustomerLock()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCustomerLock$Response(params: RemoveCustomerLock$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeCustomerLock(this.http, this.rootUrl, params, context);
  }

  /**
   * remove lock order for customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeCustomerLock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCustomerLock(params: RemoveCustomerLock$Params, context?: HttpContext): Observable<void> {
    return this.removeCustomerLock$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
