import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UtilsModule } from '@mysvg/utils';
import { ClarityWrapperModule } from '@svg-frontends/theming';

import { ErrorContentComponent } from './components/error-content/error-content.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { NewReleaseChunkErrorPageComponent } from './components/new-release-chunk-error-page/new-release-chunk-error-page.component';
import { ErrorMessagesPipe } from './error-message-pipe/error-messages.pipe';
import { ControlErrorMessagePipe } from './pipes/control-error-message.pipe';

@NgModule({
	declarations: [
		ErrorMessagesPipe,
		ErrorContentComponent,
		ErrorPageComponent,
		ControlErrorMessagePipe,
		ErrorMessageComponent,
		NewReleaseChunkErrorPageComponent,
	],
	exports: [
		ErrorMessagesPipe,
		ErrorContentComponent,
		ErrorPageComponent,
		ControlErrorMessagePipe,
		ErrorMessageComponent,
		NewReleaseChunkErrorPageComponent,
	],
	imports: [CommonModule, ClarityWrapperModule, UtilsModule],
})
export class ErrorModule {}
