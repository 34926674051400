import { Pipe, PipeTransform } from '@angular/core';
import { fromEvent, merge, Observable, of } from 'rxjs';
import { first, map, mapTo, startWith } from 'rxjs/operators';

import { FileTypeInfo } from '../../utils/file-type-info.model';
import { FileTypeInfoService } from '../../utils/file-type-info/file-type-info.service';
import { FileTypeEnum } from '../../utils/file-type.enum';

interface FilePreview {
	isLoading: boolean;
	value: string | ArrayBuffer | null;
}

const EMPTY_RESULT: FilePreview = { isLoading: false, value: null };
const INITIAL_VALUE: FilePreview = { isLoading: true, value: null };

@Pipe({
	name: 'filePreviewUrl',
})
export class FilePreviewUrlPipe implements PipeTransform {
	constructor(private fileTypeInfoService: FileTypeInfoService) {}

	transform(file: File): Observable<null | FilePreview> {
		const fileTypeInfo: FileTypeInfo = this.getFileTypeInfoBy(file);

		if (!fileTypeInfo || fileTypeInfo.isImagePreviewAvailable) {
			return this.createFilePreview(file);
		} else {
			return of(EMPTY_RESULT);
		}
	}

	private createFilePreview(file: File): Observable<null | FilePreview> {
		const fileReader: FileReader = new FileReader();
		fileReader.readAsDataURL(file);

		// fileReader triggers an error or load
		return merge(
			fromEvent(fileReader, 'error').pipe(mapTo(EMPTY_RESULT)),
			fromEvent(fileReader, 'load').pipe(
				map((event: Event) => event as ProgressEvent),
				map((event: ProgressEvent) => ({ isLoading: false, value: (event.target as FileReader).result })),
			),
		).pipe(first(), startWith(INITIAL_VALUE));
	}

	private getFileTypeInfoBy(file: File): FileTypeInfo {
		const fileTypeKey: FileTypeEnum = this.fileTypeInfoService.getByType(file.type);
		return this.fileTypeInfoService.getFileTypeInfoFor(fileTypeKey);
	}
}
