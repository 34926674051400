export enum VignetteOrderErrorEnum {
	'Kein Fehler' = 0,
	'Keine Berechtigung' = 1,
	'"Von"-Datum außerhalb des Tarifs' = 2,
	'"Bis"-Datum außerhalb des Tarifs' = 3,
	'Ungültiger Zeitraum (bspw. größer als 1 Jahr)' = 4,
	'Semantischer Fehler; Ein Attribut befindet sich außerhalb des definierten Bereichs' = 5,
	'Stornierung nicht möglich' = 6,
	'Kein freies Terminal verfügbar' = 7,
	'Nichtübereinstimmende UUID; Die überreichte UUID stimmt nicht mit der erwarteten überein.' = 8,
	'IP aufgrund von mehrfach ungültigen Anmeldungen gesperrt' = 9,
	'Falscher Username oder falsches Passwort' = 10,
	'Technischer Fehler' = 99,
	'Unbekannter Fehler' = -1,
	'Vignette ist bereits gebucht' = -2,
}
