import { Injectable } from '@angular/core';
import {
	administratorIcon,
	angleDoubleIcon,
	angleIcon,
	assignUserIcon,
	axisChartIcon,
	backupRestoreIcon,
	banIcon,
	bankIcon,
	barChartIcon,
	boltIcon,
	bookIcon,
	calculatorIcon,
	calendarIcon,
	carIcon,
	certificateIcon,
	chatBubbleIcon,
	checkIcon,
	checkboxListIcon,
	clockIcon,
	codeIcon,
	coinBagIcon,
	containerIcon,
	copyToClipboardIcon,
	creditCardIcon,
	dashboardIcon,
	dataClusterIcon,
	detailsIcon,
	dotCircleIcon,
	downloadIcon,
	eCheckIcon,
	ellipsisVerticalIcon,
	employeeIcon,
	envelopeIcon,
	errorStandardIcon,
	exclamationCircleIcon,
	exclamationTriangleIcon,
	exportIcon,
	eyeHideIcon,
	eyeIcon,
	fastForwardIcon,
	fileGroupIcon,
	fileIcon,
	fileSettingsIcon,
	folderOpenIcon,
	formIcon,
	helpIcon,
	helpInfoIcon,
	historyIcon,
	homeIcon,
	hourglassIcon,
	imageIcon,
	importIcon,
	infoCircleIcon,
	infoStandardIcon,
	keyIcon,
	layersIcon,
	lightbulbIcon,
	lineChartIcon,
	linkIcon,
	listIcon,
	lockIcon,
	logoutIcon,
	mapIcon,
	minusIcon,
	noteIcon,
	organizationIcon,
	pencilIcon,
	pluginIcon,
	plusCircleIcon,
	plusIcon,
	popOutIcon,
	rackServerIcon,
	recycleIcon,
	redoIcon,
	rewindIcon,
	searchIcon,
	shieldIcon,
	shrinkIcon,
	stepForward2Icon,
	stopIcon,
	successStandardIcon,
	syncIcon,
	tableIcon,
	timesCircleIcon,
	toolsIcon,
	trashIcon,
	treeViewIcon,
	truckIcon,
	undoIcon,
	unlockIcon,
	uploadCloudIcon,
	userIcon,
	usersIcon,
	warningStandardIcon,
	windowCloseIcon,
	wrenchIcon,
} from '@cds/core/icon';
import { IconShapeTuple } from '@cds/core/icon/interfaces/icon.interfaces';

import { ClarityInitializationService } from '@svg-frontends/theming';

@Injectable({ providedIn: 'root' })
export class ClarityInitService extends ClarityInitializationService {
	protected getIcons(): IconShapeTuple[] {
		const preset = super.getIcons();
		return [
			...preset,

			administratorIcon,
			angleDoubleIcon,
			angleIcon,
			assignUserIcon,
			axisChartIcon,
			backupRestoreIcon,
			banIcon,
			bankIcon,
			barChartIcon,
			boltIcon,
			bookIcon,
			calculatorIcon,
			calendarIcon,
			carIcon,
			certificateIcon,
			chatBubbleIcon,
			checkIcon,
			checkboxListIcon,
			clockIcon,
			codeIcon,
			coinBagIcon,
			containerIcon,
			copyToClipboardIcon,
			creditCardIcon,
			dashboardIcon,
			dataClusterIcon,
			detailsIcon,
			dotCircleIcon,
			downloadIcon,
			eCheckIcon,
			ellipsisVerticalIcon,
			employeeIcon,
			envelopeIcon,
			errorStandardIcon,
			exclamationCircleIcon,
			exclamationTriangleIcon,
			exportIcon,
			eyeHideIcon,
			eyeIcon,
			fastForwardIcon,
			fileGroupIcon,
			fileIcon,
			fileSettingsIcon,
			folderOpenIcon,
			formIcon,
			helpIcon,
			helpInfoIcon,
			historyIcon,
			homeIcon,
			hourglassIcon,
			imageIcon,
			importIcon,
			infoCircleIcon,
			infoStandardIcon,
			keyIcon,
			layersIcon,
			lightbulbIcon,
			lineChartIcon,
			linkIcon,
			listIcon,
			lockIcon,
			logoutIcon,
			mapIcon,
			minusIcon,
			noteIcon,
			organizationIcon,
			pencilIcon,
			pluginIcon,
			plusCircleIcon,
			plusIcon,
			popOutIcon,
			rackServerIcon,
			recycleIcon,
			redoIcon,
			rewindIcon,
			searchIcon,
			shieldIcon,
			shrinkIcon,
			stepForward2Icon,
			stopIcon,
			successStandardIcon,
			syncIcon,
			tableIcon,
			timesCircleIcon,
			toolsIcon,
			trashIcon,
			treeViewIcon,
			truckIcon,
			undoIcon,
			unlockIcon,
			uploadCloudIcon,
			userIcon,
			usersIcon,
			warningStandardIcon,
			windowCloseIcon,
			wrenchIcon,
		];
	}
}
