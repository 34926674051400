/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseFuelCardOrderDto } from '../../models/response-fuel-card-order-dto';

export interface GetFuelCardOrders$Params {
  businessSectorName?: string;
  cardNumber?: string;
  cardText?: string;
  companyName?: string;
  createdDate?: string;
  customerBaseId?: string;
  customerNumber?: string;
  id?: string;
  orderDate?: string;
  statusText?: string;
  svgId?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getFuelCardOrders(http: HttpClient, rootUrl: string, params?: GetFuelCardOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseFuelCardOrderDto>> {
  const rb = new RequestBuilder(rootUrl, getFuelCardOrders.PATH, 'get');
  if (params) {
    rb.query('businessSectorName', params.businessSectorName, {});
    rb.query('cardNumber', params.cardNumber, {});
    rb.query('cardText', params.cardText, {});
    rb.query('companyName', params.companyName, {});
    rb.query('createdDate', params.createdDate, {});
    rb.query('customerBaseId', params.customerBaseId, {});
    rb.query('customerNumber', params.customerNumber, {});
    rb.query('id', params.id, {});
    rb.query('orderDate', params.orderDate, {});
    rb.query('statusText', params.statusText, {});
    rb.query('svgId', params.svgId, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseFuelCardOrderDto>;
    })
  );
}

getFuelCardOrders.PATH = '/fuel-card/order';
