/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { UsersControllerService } from './services/users-controller.service';
import { EmployeeManagementControllerService } from './services/employee-management-controller.service';
import { ActivitySetsControllerService } from './services/activity-sets-controller.service';
import { ActivityRolesControllerService } from './services/activity-roles-controller.service';
import { ActivitiesControllerService } from './services/activities-controller.service';
import { GroupsControllerService } from './services/groups-controller.service';
import { MySvgControllerService } from './services/my-svg-controller.service';
import { SvgManagementControllerService } from './services/svg-management-controller.service';
import { PermissionsControllerService } from './services/permissions-controller.service';
import { MinimalEmployeeControllerService } from './services/minimal-employee-controller.service';
import { BusinessSectorsControllerService } from './services/business-sectors-controller.service';
import { AuthControllerService } from './services/auth-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    UsersControllerService,
    EmployeeManagementControllerService,
    ActivitySetsControllerService,
    ActivityRolesControllerService,
    ActivitiesControllerService,
    GroupsControllerService,
    MySvgControllerService,
    SvgManagementControllerService,
    PermissionsControllerService,
    MinimalEmployeeControllerService,
    BusinessSectorsControllerService,
    AuthControllerService,
    ApiConfiguration
  ],
})
export class AuthModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: AuthModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
