/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddressTypeDto } from '../models/address-type-dto';
import { getAllAddressTypes } from '../fn/address-type-controller/get-all-address-types';
import { GetAllAddressTypes$Params } from '../fn/address-type-controller/get-all-address-types';

@Injectable({ providedIn: 'root' })
export class AddressTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllAddressTypes()` */
  static readonly GetAllAddressTypesPath = '/addresses/types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAddressTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAddressTypes$Response(params?: GetAllAddressTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AddressTypeDto>>> {
    return getAllAddressTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAddressTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAddressTypes(params?: GetAllAddressTypes$Params, context?: HttpContext): Observable<Array<AddressTypeDto>> {
    return this.getAllAddressTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AddressTypeDto>>): Array<AddressTypeDto> => r.body)
    );
  }

}
