import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
	ApiConfiguration,
	CountryInvoiceCertificateControllerService,
	DiscountProgramControllerService,
	Document,
	DocumentsControllerService,
	EntityDocumentsControllerService,
	ExternalDocumentsControllerService,
	PublicDocumentsControllerService,
} from '@mysvg/api/document';

import { ACCESS_TOKEN_STORAGE_KEY, UrlParamsService } from '@mysvg/utils';
import { SvgContextService } from '@svg-frontends/session';

@Injectable({ providedIn: 'root' })
export class DocumentService {
	constructor(
		private apiConfiguration: ApiConfiguration,
		private publicDocumentsControllerService: PublicDocumentsControllerService,
		private svgContextService: SvgContextService,
		private urlParamsService: UrlParamsService,
	) {}

	getDocumentsPath(): string {
		return `${this.apiConfiguration.rootUrl}${DocumentsControllerService.GetDocumentsPath}`;
	}

	getPublicDocumentByHashPath(documentHash: string): string {
		return `${this.apiConfiguration.rootUrl}${PublicDocumentsControllerService.GetPublicDocumentForEntityByHashPath}`.replace(
			'{downloadHash}',
			documentHash,
		);
	}

	getPrivateDocumentByHashPath(documentHash: string): string {
		return `${this.apiConfiguration.rootUrl}${DocumentsControllerService.GetDocumentByHashPath}`.replace('{documentHash}', documentHash);
	}

	getPrivateEntityDocumentByHashPath(entityType: 'vehicles' | 'customers' | 'svgs', id: string, downloadHash: string): string {
		return `${this.apiConfiguration.rootUrl}${EntityDocumentsControllerService.GetDocumentForEntityByHashPath}`
			.replace('{entityType}', entityType)
			.replace('{id}', id)
			.replace('{downloadHash}', downloadHash);
	}

	getTmpDocumentByFilenameHashPath(documentHash: string): string {
		return `${this.apiConfiguration.rootUrl}${DocumentsControllerService.GetTmpDocumentByFilenameHashPath}`.replace(
			'{documentHash}',
			documentHash,
		);
	}

	getZipDocumentByHashPath(documentHash: string): string {
		return `${this.apiConfiguration.rootUrl}${DocumentsControllerService.GetZipDocumentByHashPath}`.replace('{documentHash}', documentHash);
	}

	getDiscountProgramDocumentByDocumentHash(documentHash: string): string {
		return `${this.apiConfiguration.rootUrl}${DiscountProgramControllerService.GetDiscountProgramDocumentByHashPath}`.replace(
			'{documentHash}',
			documentHash,
		);
	}

	getDiscountProgramDocumentByCountryId(isoCode: string): string {
		return `${this.apiConfiguration.rootUrl}${DiscountProgramControllerService.GetDiscountProgramDocumentByCountryIsoCodePath}`.replace(
			'{isoCode}',
			isoCode,
		);
	}

	getCustomerDocumentDownloadPathFromDocId(documentId: number, portalUid: string): string {
		const url = `${this.apiConfiguration.rootUrl}${ExternalDocumentsControllerService.GetExternalDocumentContentByIdPath}`
			.replace('{id}', documentId?.toString())
			.replace('{puid}', portalUid);

		return this.appendToken(url);
	}

	getEetsCountryReferencesDownloadPath(docId: number, portalUid: string): string {
		const url =
			`${this.apiConfiguration.rootUrl}${CountryInvoiceCertificateControllerService.GetOneCountryInvoiceCertificateFileForCustomerPath}`
				.replace('{id}', docId?.toString())
				.replace('{puid}', portalUid);

		return this.appendToken(url);
	}

	uploadFilePath(): string {
		return `${this.apiConfiguration.rootUrl}${DocumentsControllerService.UploadTempFilePath}`;
	}

	getPrivacyStatementUrl(svgId: number): Observable<string | null> {
		return this.publicDocumentsControllerService.getDsgvoDocuments({ svgId }).pipe(
			// TODO: Use correct category and provider filter
			map((documents: Document[]) => (documents.length > 0 ? documents[0].downloadHash : null)),
			map((downloadHash: string | null) => (downloadHash !== null ? this.getPublicDocumentByHashPath(downloadHash) : null)),
		);
	}

	getPrivateDocumentUrlFromHash(documentHash: string): string {
		const url = this.getPrivateDocumentByHashPath(documentHash);
		return this.appendToken(url);
	}

	getPrivateEntityDocumentUrlFromHash(entityType: 'vehicles' | 'customers' | 'svgs', id: string, documentHash: string): string {
		const url = this.getPrivateEntityDocumentByHashPath(entityType, id, documentHash);
		return this.appendToken(url);
	}

	getPublicDocumentUrlFromHash(documentHash: string): string {
		const url = this.getPublicDocumentByHashPath(documentHash);
		return this.appendToken(url);
	}

	getTempDocumentUrlFromHash(documentHash: string): string {
		const url = this.getTmpDocumentByFilenameHashPath(documentHash);
		return this.appendToken(url);
	}

	getZipDocumentUrlFromHash(documentHash: string): string {
		const url = this.getZipDocumentByHashPath(documentHash);
		return this.appendToken(url);
	}

	getDiscountProgramDocumentUrlViaDocumentHash(documentHash: string): string {
		const url = this.getDiscountProgramDocumentByDocumentHash(documentHash);
		return this.appendToken(url);
	}

	getDiscountProgramDocumentUrlViaCountryId(isoCode: string): string {
		const url = this.getDiscountProgramDocumentByCountryId(isoCode);
		return this.appendToken(url);
	}

	private appendToken(url: string): string {
		const accessToken = this.svgContextService.getAccessToken();
		return this.urlParamsService.append(url, ACCESS_TOKEN_STORAGE_KEY, accessToken, false);
	}
}
