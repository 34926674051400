import { LabeledEnumModel } from '../models/enum/labeledEnum.model';

export const AgesEmissionClass: LabeledEnumModel = {
	// Ages emission classes
	'CO2 Klasse 1 mit EURO 0': { label: 'CO₂ Klasse 1 mit EURO 0' },
	'CO2 Klasse 1 mit EURO 1': { label: 'CO₂ Klasse 1 mit EURO 1' },
	'CO2 Klasse 1 mit EURO 2': { label: 'CO₂ Klasse 1 mit EURO 2' },
	'CO2 Klasse 1 mit EURO 3': { label: 'CO₂ Klasse 1 mit EURO 3' },
	'CO2 Klasse 1 mit EURO 4': { label: 'CO₂ Klasse 1 mit EURO 4' },
	'CO2 Klasse 1 mit EURO 5': { label: 'CO₂ Klasse 1 mit EURO 5' },
	'CO2 Klasse 1 mit EURO 6': { label: 'CO₂ Klasse 1 mit EURO 6' },
};
