import { Injectable } from '@angular/core';
import { PaymentModalitiesControllerService, PaymentModalitiesDto, ResponsePaymentModalitiesDto } from '@mysvg/api/payment';
import { SvgContextService } from '@svg-frontends/session';
import { RepositoryStoreService } from '../repository-store.service';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentModalitiesStoreService extends RepositoryStoreService<PaymentModalitiesDto[]> {
	constructor(
		private paymentModalitiesControllerService: PaymentModalitiesControllerService,
		private svgContextService: SvgContextService,
	) {
		super();
	}

	fetchData(): Observable<PaymentModalitiesDto[]> {
		return this.svgContextService.getFirstPortalUid().pipe(
			mergeMap((portalUid: string) => this.paymentModalitiesControllerService.getPaymentModalities({ portalUid })),
			map((modalities: ResponsePaymentModalitiesDto) => modalities.data),
		);
	}
}
