/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deletePaymentTerms } from '../fn/tc-payment-terms/delete-payment-terms';
import { DeletePaymentTerms$Params } from '../fn/tc-payment-terms/delete-payment-terms';
import { getPaymentTerms } from '../fn/tc-payment-terms/get-payment-terms';
import { GetPaymentTerms$Params } from '../fn/tc-payment-terms/get-payment-terms';
import { savePaymentTerms } from '../fn/tc-payment-terms/save-payment-terms';
import { SavePaymentTerms$Params } from '../fn/tc-payment-terms/save-payment-terms';
import { TcPaymentTermsDto } from '../models/tc-payment-terms-dto';

@Injectable({ providedIn: 'root' })
export class TcPaymentTermsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPaymentTerms()` */
  static readonly GetPaymentTermsPath = '/svgs/{svgId}/customers/{customerId}/payment-terms';

  /**
   * Get payment-terms of one customer.
   *
   * Payment Terms is an Object that can only exist once per customer. This endpoint retrieves it for a given customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentTerms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentTerms$Response(params: GetPaymentTerms$Params, context?: HttpContext): Observable<StrictHttpResponse<TcPaymentTermsDto>> {
    return getPaymentTerms(this.http, this.rootUrl, params, context);
  }

  /**
   * Get payment-terms of one customer.
   *
   * Payment Terms is an Object that can only exist once per customer. This endpoint retrieves it for a given customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaymentTerms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentTerms(params: GetPaymentTerms$Params, context?: HttpContext): Observable<TcPaymentTermsDto> {
    return this.getPaymentTerms$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcPaymentTermsDto>): TcPaymentTermsDto => r.body)
    );
  }

  /** Path part for operation `savePaymentTerms()` */
  static readonly SavePaymentTermsPath = '/svgs/{svgId}/customers/{customerId}/payment-terms';

  /**
   * Save payment-terms.
   *
   * Payment Terms is an Object that can only exist once per customer. This endpoint creates it once for a given customer or updates it to the given values if its already existing.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePaymentTerms()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePaymentTerms$Response(params: SavePaymentTerms$Params, context?: HttpContext): Observable<StrictHttpResponse<TcPaymentTermsDto>> {
    return savePaymentTerms(this.http, this.rootUrl, params, context);
  }

  /**
   * Save payment-terms.
   *
   * Payment Terms is an Object that can only exist once per customer. This endpoint creates it once for a given customer or updates it to the given values if its already existing.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `savePaymentTerms$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePaymentTerms(params: SavePaymentTerms$Params, context?: HttpContext): Observable<TcPaymentTermsDto> {
    return this.savePaymentTerms$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcPaymentTermsDto>): TcPaymentTermsDto => r.body)
    );
  }

  /** Path part for operation `deletePaymentTerms()` */
  static readonly DeletePaymentTermsPath = '/svgs/{svgId}/customers/{customerId}/payment-terms';

  /**
   * Delete the payment-terms.
   *
   * Delete the payment-terms of a customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePaymentTerms()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePaymentTerms$Response(params: DeletePaymentTerms$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deletePaymentTerms(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete the payment-terms.
   *
   * Delete the payment-terms of a customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePaymentTerms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePaymentTerms(params: DeletePaymentTerms$Params, context?: HttpContext): Observable<void> {
    return this.deletePaymentTerms$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
