/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseMinimalEmployee } from '../../models/response-minimal-employee';

export interface GetMinimalEmployees$Params {

/**
 * the desired svgId
 */
  svgId?: number;

/**
 * search query
 */
  search?: string;

/**
 * Zero-based page index (0..N)
 */
  page?: number;

/**
 * The size of the page to be returned
 */
  size?: number;

/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
  sort?: Array<string>;
}

export function getMinimalEmployees(http: HttpClient, rootUrl: string, params?: GetMinimalEmployees$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseMinimalEmployee>> {
  const rb = new RequestBuilder(rootUrl, getMinimalEmployees.PATH, 'get');
  if (params) {
    rb.query('svgId', params.svgId, {});
    rb.query('search', params.search, {});
    rb.query('page', params.page, {});
    rb.query('size', params.size, {});
    rb.query('sort', params.sort, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseMinimalEmployee>;
    })
  );
}

getMinimalEmployees.PATH = '/employees/minimal/list';
