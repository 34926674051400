import { Injectable } from '@angular/core';
import { TelematicControllerService, TelematicProductServiceHardwareDto } from '@mysvg/api/kmaster-box-management';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TelematicServicesStoreService extends RepositoryStoreService<TelematicProductServiceHardwareDto[]> {
	constructor(private telematicControllerService: TelematicControllerService) {
		super();
	}

	fetchData(): Observable<TelematicProductServiceHardwareDto[]> {
		return this.telematicControllerService.getAllTelematicServices();
	}
}
