import { Injectable } from '@angular/core';
import { DocumentTypeDto, SvgDocumentControllerService } from '@mysvg/api/svgs';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SvgDocumentTypesStoreService extends RepositoryStoreService<DocumentTypeDto[]> {
	constructor(private svgDocumentControllerService: SvgDocumentControllerService) {
		super();
	}

	fetchData(): Observable<DocumentTypeDto[]> {
		return this.svgDocumentControllerService.getDocumentTypes();
	}
}
