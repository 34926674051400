/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CountryInvoiceCertificateDirectory } from '../models/country-invoice-certificate-directory';
import { CountryInvoiceCertificateDto } from '../models/country-invoice-certificate-dto';
import { getAllCountryInvoiceCertificatesForCustomerAndCountry } from '../fn/country-invoice-certificate-controller/get-all-country-invoice-certificates-for-customer-and-country';
import { GetAllCountryInvoiceCertificatesForCustomerAndCountry$Params } from '../fn/country-invoice-certificate-controller/get-all-country-invoice-certificates-for-customer-and-country';
import { getCountryInvoiceCertificateDirectoriesForCustomer } from '../fn/country-invoice-certificate-controller/get-country-invoice-certificate-directories-for-customer';
import { GetCountryInvoiceCertificateDirectoriesForCustomer$Params } from '../fn/country-invoice-certificate-controller/get-country-invoice-certificate-directories-for-customer';
import { getOneCountryInvoiceCertificateFileForCustomer } from '../fn/country-invoice-certificate-controller/get-one-country-invoice-certificate-file-for-customer';
import { GetOneCountryInvoiceCertificateFileForCustomer$Params } from '../fn/country-invoice-certificate-controller/get-one-country-invoice-certificate-file-for-customer';

@Injectable({ providedIn: 'root' })
export class CountryInvoiceCertificateControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOneCountryInvoiceCertificateFileForCustomer()` */
  static readonly GetOneCountryInvoiceCertificateFileForCustomerPath = '/documents/{id}/documents/{puid}';

  /**
   * starts the download stream by eets country invoice certificate by its id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneCountryInvoiceCertificateFileForCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneCountryInvoiceCertificateFileForCustomer$Response(params: GetOneCountryInvoiceCertificateFileForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getOneCountryInvoiceCertificateFileForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * starts the download stream by eets country invoice certificate by its id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOneCountryInvoiceCertificateFileForCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneCountryInvoiceCertificateFileForCustomer(params: GetOneCountryInvoiceCertificateFileForCustomer$Params, context?: HttpContext): Observable<Blob> {
    return this.getOneCountryInvoiceCertificateFileForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `getAllCountryInvoiceCertificatesForCustomerAndCountry()` */
  static readonly GetAllCountryInvoiceCertificatesForCustomerAndCountryPath = '/documents/customers/{puid}/{country}';

  /**
   * get all eets country invoice certificates for a specific country by customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCountryInvoiceCertificatesForCustomerAndCountry()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCountryInvoiceCertificatesForCustomerAndCountry$Response(params: GetAllCountryInvoiceCertificatesForCustomerAndCountry$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CountryInvoiceCertificateDto>>> {
    return getAllCountryInvoiceCertificatesForCustomerAndCountry(this.http, this.rootUrl, params, context);
  }

  /**
   * get all eets country invoice certificates for a specific country by customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllCountryInvoiceCertificatesForCustomerAndCountry$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCountryInvoiceCertificatesForCustomerAndCountry(params: GetAllCountryInvoiceCertificatesForCustomerAndCountry$Params, context?: HttpContext): Observable<Array<CountryInvoiceCertificateDto>> {
    return this.getAllCountryInvoiceCertificatesForCustomerAndCountry$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CountryInvoiceCertificateDto>>): Array<CountryInvoiceCertificateDto> => r.body)
    );
  }

  /** Path part for operation `getCountryInvoiceCertificateDirectoriesForCustomer()` */
  static readonly GetCountryInvoiceCertificateDirectoriesForCustomerPath = '/directory/customers/{puid}';

  /**
   * get all eets country invoices certificate directories by customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountryInvoiceCertificateDirectoriesForCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountryInvoiceCertificateDirectoriesForCustomer$Response(params: GetCountryInvoiceCertificateDirectoriesForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CountryInvoiceCertificateDirectory>>> {
    return getCountryInvoiceCertificateDirectoriesForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * get all eets country invoices certificate directories by customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCountryInvoiceCertificateDirectoriesForCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountryInvoiceCertificateDirectoriesForCustomer(params: GetCountryInvoiceCertificateDirectoriesForCustomer$Params, context?: HttpContext): Observable<Array<CountryInvoiceCertificateDirectory>> {
    return this.getCountryInvoiceCertificateDirectoriesForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CountryInvoiceCertificateDirectory>>): Array<CountryInvoiceCertificateDirectory> => r.body)
    );
  }

}
