import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { ApplicationLevelAlertLevel } from '@mysvg/utils';
import { UpdateAppWorkerService } from '../update-app-worker.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-update-app-worker-application-alert',
	styleUrls: ['./update-app-worker-application-alert.component.scss'],
	templateUrl: './update-app-worker-application-alert.component.html',
})
export class UpdateAppWorkerApplicationAlertComponent implements OnInit {
	ALERT_LEVEL = ApplicationLevelAlertLevel.INFO;
	isNewVersionReady$: Observable<boolean>;

	constructor(private updateAppWorkerService: UpdateAppWorkerService) {}

	ngOnInit(): void {
		this.isNewVersionReady$ = this.updateAppWorkerService.getVersionReadyForReload();
	}

	reload() {
		this.updateAppWorkerService.reloadAndReset();
	}
}
