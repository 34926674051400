/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Vehicle } from '../../models/vehicle';

export interface IsBoxValid$Params {

/**
 * validate for a specific boxType (optional)
 */
  boxTypeIds?: Array<number>;

/**
 * the portalUid of the customer
 */
  customers: string;
      body: Vehicle
}

export function isBoxValid(http: HttpClient, rootUrl: string, params: IsBoxValid$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
  const rb = new RequestBuilder(rootUrl, isBoxValid.PATH, 'post');
  if (params) {
    rb.query('boxTypeIds', params.boxTypeIds, {});
    rb.query('customers', params.customers, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
    })
  );
}

isBoxValid.PATH = '/vehicles/validate';
