/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getListpreisAusland } from '../fn/listenpreise-controller/get-listpreis-ausland';
import { GetListpreisAusland$Params } from '../fn/listenpreise-controller/get-listpreis-ausland';
import { getListpreisDeutschland } from '../fn/listenpreise-controller/get-listpreis-deutschland';
import { GetListpreisDeutschland$Params } from '../fn/listenpreise-controller/get-listpreis-deutschland';
import { getProfipreis } from '../fn/listenpreise-controller/get-profipreis';
import { GetProfipreis$Params } from '../fn/listenpreise-controller/get-profipreis';
import { ResponseListpreisAuslandPos } from '../models/response-listpreis-ausland-pos';
import { ResponseListpreisDeutschlandPos } from '../models/response-listpreis-deutschland-pos';
import { ResponseProfipreisPosDto } from '../models/response-profipreis-pos-dto';

@Injectable({ providedIn: 'root' })
export class ListenpreiseControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getProfipreis()` */
  static readonly GetProfipreisPath = '/profipreis';

  /**
   * View a list of transactions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfipreis()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfipreis$Response(params?: GetProfipreis$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseProfipreisPosDto>> {
    return getProfipreis(this.http, this.rootUrl, params, context);
  }

  /**
   * View a list of transactions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfipreis$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfipreis(params?: GetProfipreis$Params, context?: HttpContext): Observable<ResponseProfipreisPosDto> {
    return this.getProfipreis$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseProfipreisPosDto>): ResponseProfipreisPosDto => r.body)
    );
  }

  /** Path part for operation `getListpreisDeutschland()` */
  static readonly GetListpreisDeutschlandPath = '/listpreisDeutschland';

  /**
   * View a list of transactions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getListpreisDeutschland()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListpreisDeutschland$Response(params?: GetListpreisDeutschland$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseListpreisDeutschlandPos>> {
    return getListpreisDeutschland(this.http, this.rootUrl, params, context);
  }

  /**
   * View a list of transactions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getListpreisDeutschland$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListpreisDeutschland(params?: GetListpreisDeutschland$Params, context?: HttpContext): Observable<ResponseListpreisDeutschlandPos> {
    return this.getListpreisDeutschland$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseListpreisDeutschlandPos>): ResponseListpreisDeutschlandPos => r.body)
    );
  }

  /** Path part for operation `getListpreisAusland()` */
  static readonly GetListpreisAuslandPath = '/listpreisAusland';

  /**
   * View a list of transactions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getListpreisAusland()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListpreisAusland$Response(params?: GetListpreisAusland$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseListpreisAuslandPos>> {
    return getListpreisAusland(this.http, this.rootUrl, params, context);
  }

  /**
   * View a list of transactions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getListpreisAusland$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListpreisAusland(params?: GetListpreisAusland$Params, context?: HttpContext): Observable<ResponseListpreisAuslandPos> {
    return this.getListpreisAusland$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseListpreisAuslandPos>): ResponseListpreisAuslandPos => r.body)
    );
  }

}
