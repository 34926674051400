/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { cancelProcess } from '../fn/process-controller/cancel-process';
import { CancelProcess$Params } from '../fn/process-controller/cancel-process';
import { cancelProcesses } from '../fn/process-controller/cancel-processes';
import { CancelProcesses$Params } from '../fn/process-controller/cancel-processes';
import { createNewProcess } from '../fn/process-controller/create-new-process';
import { CreateNewProcess$Params } from '../fn/process-controller/create-new-process';
import { executeAction } from '../fn/process-controller/execute-action';
import { ExecuteAction$Params } from '../fn/process-controller/execute-action';
import { getActionData } from '../fn/process-controller/get-action-data';
import { GetActionData$Params } from '../fn/process-controller/get-action-data';
import { getProcess } from '../fn/process-controller/get-process';
import { GetProcess$Params } from '../fn/process-controller/get-process';
import { getProcesses } from '../fn/process-controller/get-processes';
import { GetProcesses$Params } from '../fn/process-controller/get-processes';
import { getProcessesList } from '../fn/process-controller/get-processes-list';
import { GetProcessesList$Params } from '../fn/process-controller/get-processes-list';
import { getProcessTypes } from '../fn/process-controller/get-process-types';
import { GetProcessTypes$Params } from '../fn/process-controller/get-process-types';
import { nextProcess } from '../fn/process-controller/next-process';
import { NextProcess$Params } from '../fn/process-controller/next-process';
import { ProcessActionResponseDtoActionForm } from '../models/process-action-response-dto-action-form';
import { ProcessActionResponseDtoObject } from '../models/process-action-response-dto-object';
import { ProcessDto } from '../models/process-dto';
import { ProcessTypeDto } from '../models/process-type-dto';
import { ResponseSimpleProcessDto } from '../models/response-simple-process-dto';
import { setProcessEditor } from '../fn/process-controller/set-process-editor';
import { SetProcessEditor$Params } from '../fn/process-controller/set-process-editor';

@Injectable({ providedIn: 'root' })
export class ProcessControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `setProcessEditor()` */
  static readonly SetProcessEditorPath = '/processes/{processId}/editor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setProcessEditor()` instead.
   *
   * This method doesn't expect any request body.
   */
  setProcessEditor$Response(params: SetProcessEditor$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessDto>> {
    return setProcessEditor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setProcessEditor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setProcessEditor(params: SetProcessEditor$Params, context?: HttpContext): Observable<ProcessDto> {
    return this.setProcessEditor$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessDto>): ProcessDto => r.body)
    );
  }

  /** Path part for operation `createNewProcess()` */
  static readonly CreateNewProcessPath = '/processes/{processName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNewProcess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNewProcess$Response(params: CreateNewProcess$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessActionResponseDtoActionForm>> {
    return createNewProcess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createNewProcess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNewProcess(params: CreateNewProcess$Params, context?: HttpContext): Observable<ProcessActionResponseDtoActionForm> {
    return this.createNewProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessActionResponseDtoActionForm>): ProcessActionResponseDtoActionForm => r.body)
    );
  }

  /** Path part for operation `nextProcess()` */
  static readonly NextProcessPath = '/processes/{processId}/next';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `nextProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  nextProcess$Response(params: NextProcess$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessDto>> {
    return nextProcess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `nextProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  nextProcess(params: NextProcess$Params, context?: HttpContext): Observable<ProcessDto> {
    return this.nextProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessDto>): ProcessDto => r.body)
    );
  }

  /** Path part for operation `executeAction()` */
  static readonly ExecuteActionPath = '/processes/{processId}/execute/{actionName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `executeAction()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  executeAction$Response(params: ExecuteAction$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessActionResponseDtoObject>> {
    return executeAction(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `executeAction$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  executeAction(params: ExecuteAction$Params, context?: HttpContext): Observable<ProcessActionResponseDtoObject> {
    return this.executeAction$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessActionResponseDtoObject>): ProcessActionResponseDtoObject => r.body)
    );
  }

  /** Path part for operation `cancelProcess()` */
  static readonly CancelProcessPath = '/processes/{processId}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelProcess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelProcess$Response(params: CancelProcess$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessDto>> {
    return cancelProcess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelProcess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelProcess(params: CancelProcess$Params, context?: HttpContext): Observable<ProcessDto> {
    return this.cancelProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessDto>): ProcessDto => r.body)
    );
  }

  /** Path part for operation `cancelProcesses()` */
  static readonly CancelProcessesPath = '/processes/cancel/bulk';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelProcesses()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelProcesses$Response(params: CancelProcesses$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProcessDto>>> {
    return cancelProcesses(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelProcesses$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelProcesses(params: CancelProcesses$Params, context?: HttpContext): Observable<Array<ProcessDto>> {
    return this.cancelProcesses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProcessDto>>): Array<ProcessDto> => r.body)
    );
  }

  /** Path part for operation `getProcesses()` */
  static readonly GetProcessesPath = '/processes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProcesses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcesses$Response(params?: GetProcesses$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSimpleProcessDto>> {
    return getProcesses(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProcesses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcesses(params?: GetProcesses$Params, context?: HttpContext): Observable<ResponseSimpleProcessDto> {
    return this.getProcesses$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSimpleProcessDto>): ResponseSimpleProcessDto => r.body)
    );
  }

  /** Path part for operation `getProcess()` */
  static readonly GetProcessPath = '/processes/{processId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcess$Response(params: GetProcess$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessDto>> {
    return getProcess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcess(params: GetProcess$Params, context?: HttpContext): Observable<ProcessDto> {
    return this.getProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessDto>): ProcessDto => r.body)
    );
  }

  /** Path part for operation `getActionData()` */
  static readonly GetActionDataPath = '/processes/{processId}/data/{actionName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActionData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActionData$Response(params: GetActionData$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessActionResponseDtoObject>> {
    return getActionData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActionData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActionData(params: GetActionData$Params, context?: HttpContext): Observable<ProcessActionResponseDtoObject> {
    return this.getActionData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessActionResponseDtoObject>): ProcessActionResponseDtoObject => r.body)
    );
  }

  /** Path part for operation `getProcessTypes()` */
  static readonly GetProcessTypesPath = '/processes/types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProcessTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessTypes$Response(params?: GetProcessTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProcessTypeDto>>> {
    return getProcessTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProcessTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessTypes(params?: GetProcessTypes$Params, context?: HttpContext): Observable<Array<ProcessTypeDto>> {
    return this.getProcessTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProcessTypeDto>>): Array<ProcessTypeDto> => r.body)
    );
  }

  /** Path part for operation `getProcessesList()` */
  static readonly GetProcessesListPath = '/processes/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProcessesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessesList$Response(params?: GetProcessesList$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSimpleProcessDto>> {
    return getProcessesList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProcessesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProcessesList(params?: GetProcessesList$Params, context?: HttpContext): Observable<ResponseSimpleProcessDto> {
    return this.getProcessesList$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSimpleProcessDto>): ResponseSimpleProcessDto => r.body)
    );
  }

}
