import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AuthenticationModule } from './authentication/authentication.module';
import { ContextModule } from './context/context.module';

@NgModule({
	imports: [CommonModule],
	exports: [AuthenticationModule, ContextModule],
})
export class SessionModule {}
