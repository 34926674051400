import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from '@nsalaun/ng-logger';

import { ErrorHandlingService } from '../../services/error-handling.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-error-content',
	styleUrls: ['./error-content.component.scss'],
	templateUrl: './error-content.component.html',
})
export class ErrorContentComponent {
	constructor(public errorHandlerService: ErrorHandlingService, private router: Router, private logger: Logger) {}

	/**
	 * route guard ensures that user is logged in or redirects to keycloak
	 */
	navigateToDefault(): void {
		this.router.navigateByUrl('').catch((error) => {
			this.logger.error('Navigation failed', error);
		});
	}
}
