import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-loading',
	styleUrls: ['./loading.component.scss'],
	templateUrl: './loading.component.html',
})
export class LoadingComponent {
	@Input() isSmall: boolean; // clr-wizard overrides spinner style, use always isSmall in wizards
	@Input() withBackdrop: boolean;
	@Input() additionalText: string;
	@Input() absolute = true;
	@Input() isInline = false;
}
