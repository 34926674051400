import { Directive, Host, Input, OnInit } from '@angular/core';
import { ClrDatagridHideableColumn } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TableViewOptionsService } from '../services/table-view-options.service';

@UntilDestroy()
@Directive({ selector: '[svgFrontendsHideableColumn]' })
export class TableViewColumnDirective implements OnInit {
	@Input() svgFrontendsHideableColumn: string;
	@Input() svgFrontendsHideableColumnFilterName: string;

	constructor(@Host() private host: ClrDatagridHideableColumn, private tableViewOptionsService: TableViewOptionsService) {}

	ngOnInit(): void {
		this.tableViewOptionsService.setInitialHiddenStateFromStorage(
			this.svgFrontendsHideableColumn,
			this.host,
			this.svgFrontendsHideableColumnFilterName,
		);

		this.host.hiddenChange
			.asObservable()
			.pipe(untilDestroyed(this))
			.subscribe((hidden: boolean) =>
				this.tableViewOptionsService.changeColumnHiddenState({
					column: this.svgFrontendsHideableColumn,
					filterName: this.svgFrontendsHideableColumnFilterName,
					hidden,
				}),
			);
	}
}
