/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TcPaymentNumberDto } from '../../models/tc-payment-number-dto';

export interface CreateTcPaymentNumber$Params {

/**
 * portal customer
 */
  portalUid: string;

/**
 * should a mail be sent to the customer
 */
  sendMail: boolean;
}

export function createTcPaymentNumber(http: HttpClient, rootUrl: string, params: CreateTcPaymentNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<TcPaymentNumberDto>> {
  const rb = new RequestBuilder(rootUrl, createTcPaymentNumber.PATH, 'post');
  if (params) {
    rb.path('portalUid', params.portalUid, {});
    rb.query('sendMail', params.sendMail, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TcPaymentNumberDto>;
    })
  );
}

createTcPaymentNumber.PATH = '/tc-payment-number/{portalUid}';
