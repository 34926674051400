import { Injectable } from '@angular/core';
import { BillingTypeControllerService, BillingTypeDto, ResponseBillingTypeDto } from '@mysvg/api/payment';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BillingTypesStoreService extends RepositoryStoreService<BillingTypeDto[]> {
	constructor(private billingTypeControllerService: BillingTypeControllerService) {
		super();
	}

	fetchData(): Observable<BillingTypeDto[]> {
		return this.billingTypeControllerService.getAllBillingTypes().pipe(map((response: ResponseBillingTypeDto) => response.data));
	}
}
