<div *svgFrontendsLet="formControl.statusChanges | async as status" class="mac-wrapper">
	<svg-frontends-loading *ngIf="status === FORM_STATUS.PENDING" [isSmall]="true" [withBackdrop]="true"></svg-frontends-loading>

	<div class="clr-form-control mac-form-control">
		<label *ngIf="groupLabel" class="clr-control-label">
			<svg-frontends-string-or-template-ref [value]="groupLabel"></svg-frontends-string-or-template-ref>
			<svg-frontends-required-marker *ngIf="formControl | isFormControlRequired"></svg-frontends-required-marker>
		</label>

		<div [ngClass]="{ 'clr-error': formControl.invalid && formControl.touched }" class="clr-control-container">
			<div *ngFor="let value of values; let i = index" class="clr-radio-wrapper">
				<input
					(blur)="onBlur()"
					[checked]="formControl.value === value"
					[formControl]="formControl"
					[id]="id + controlName + i"
					[svgFrontendsDisableControl]="disableControl"
					[name]="id + controlName + i"
					[value]="value"
					class="clr-radio"
					type="radio"
				/>
				<label [for]="id + controlName + i" [innerHTML]="labels[i]" class="clr-control-label"></label>
			</div>

			<div class="clr-subtext-wrapper mac-subtext-wrapper">
				<cds-icon class="clr-validate-icon" shape="exclamation-circle"></cds-icon>
				<span *ngIf="helperText && !(formControl.invalid && formControl.touched)" class="clr-subtext">
					<svg-frontends-string-or-template-ref [value]="helperText"></svg-frontends-string-or-template-ref>
				</span>
				<span *ngIf="formControl.invalid && formControl.touched" class="clr-subtext">
					{{ formControl.errors | firstControlError | controlErrorMessage }}
				</span>
			</div>
		</div>
	</div>
</div>
