/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ActivityCreateForm } from '../../models/activity-create-form';
import { ActivityDto } from '../../models/activity-dto';

export interface CreateActivity$Params {
      body: ActivityCreateForm
}

export function createActivity(http: HttpClient, rootUrl: string, params: CreateActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityDto>> {
  const rb = new RequestBuilder(rootUrl, createActivity.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ActivityDto>;
    })
  );
}

createActivity.PATH = '/activities';
