<div class="container">
	<div class="clr-row clr-justify-content-center">
		<div class="clr-col-10">
			<div class="card" *svgFrontendsLet="errorHandlerService.getErrorObservable() | async as enrichedError">
				<div class="card-header">{{ enrichedError ? 'Es ist ein Fehler aufgetreten.' : 'Fehler' }}</div>
				<div class="card-block">
					<div class="card-text">{{ enrichedError ? enrichedError.displayMessages : 'Es ist ein Fehler aufgetreten.' }}</div>
				</div>
				<div class="card-footer">
					<button class="btn btn-sm btn-link" (click)="navigateToDefault()">Zurück</button>
				</div>
			</div>
		</div>
	</div>
</div>
