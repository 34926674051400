<div class="d-flex flex-column">
	<span>{{ itemName | tableViewPlaceholder }}</span>

	<div class="d-flex justify-content-center">
		<button
			(click)="resetFilters()"
			*ngIf="showResetButton && (tableViewService.hasFilters$ | async) === true"
			class="btn btn-primary btn-sm"
		>
			Filter zurücksetzen
		</button>
	</div>
</div>
