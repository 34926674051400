/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface SetSecurityExpertsMultiple$Params {

/**
 * the first security expert id
 */
  securityExpertId1: number;

/**
 * the customer list the first security expert should be assigned to
 */
  customerIds1: Array<number>;

/**
 * the second security expert id
 */
  securityExpertId2: number;

/**
 * the customer list the second security expert should be assigned to
 */
  customerIds2: Array<number>;
}

export function setSecurityExpertsMultiple(http: HttpClient, rootUrl: string, params: SetSecurityExpertsMultiple$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, setSecurityExpertsMultiple.PATH, 'patch');
  if (params) {
    rb.query('securityExpertId1', params.securityExpertId1, {});
    rb.query('customerIds1', params.customerIds1, {});
    rb.query('securityExpertId2', params.securityExpertId2, {});
    rb.query('customerIds2', params.customerIds2, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

setSecurityExpertsMultiple.PATH = '/security-experts/assign-multiple';
