/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BoxStatusDto } from '../models/box-status-dto';
import { getBoxStatuses } from '../fn/box-status-bo-controller/get-box-statuses';
import { GetBoxStatuses$Params } from '../fn/box-status-bo-controller/get-box-statuses';

@Injectable({ providedIn: 'root' })
export class BoxStatusBoControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getBoxStatuses()` */
  static readonly GetBoxStatusesPath = '/boxes/statuses';

  /**
   * Returns a list of box statuses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBoxStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoxStatuses$Response(params?: GetBoxStatuses$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BoxStatusDto>>> {
    return getBoxStatuses(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a list of box statuses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBoxStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoxStatuses(params?: GetBoxStatuses$Params, context?: HttpContext): Observable<Array<BoxStatusDto>> {
    return this.getBoxStatuses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BoxStatusDto>>): Array<BoxStatusDto> => r.body)
    );
  }

}
