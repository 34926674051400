/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OrderStatus } from '../../models/order-status';
import { TelematicOrderDto } from '../../models/telematic-order-dto';

export interface UpdateOrderStatusByKMaster$Params {
      body: OrderStatus
}

export function updateOrderStatusByKMaster(http: HttpClient, rootUrl: string, params: UpdateOrderStatusByKMaster$Params, context?: HttpContext): Observable<StrictHttpResponse<TelematicOrderDto>> {
  const rb = new RequestBuilder(rootUrl, updateOrderStatusByKMaster.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TelematicOrderDto>;
    })
  );
}

updateOrderStatusByKMaster.PATH = '/public/event/kmaster/order-status';
