/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CardStatusDto } from '../models/card-status-dto';
import { getCardStatuses } from '../fn/card-status-controller/get-card-statuses';
import { GetCardStatuses$Params } from '../fn/card-status-controller/get-card-statuses';

@Injectable({ providedIn: 'root' })
export class CardStatusControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCardStatuses()` */
  static readonly GetCardStatusesPath = '/card/statuses';

  /**
   * Returns a list of card statuses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCardStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardStatuses$Response(params?: GetCardStatuses$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CardStatusDto>>> {
    return getCardStatuses(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a list of card statuses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCardStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardStatuses(params?: GetCardStatuses$Params, context?: HttpContext): Observable<Array<CardStatusDto>> {
    return this.getCardStatuses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CardStatusDto>>): Array<CardStatusDto> => r.body)
    );
  }

}
