import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, first, map, mergeMap } from 'rxjs/operators';

import { isDefined } from '@mysvg/utils';

import { DataWrapper } from '../models/data-wrapper.model';

/**
 * @deprecated legacy store implementation: use 'parametrized-repository-store2.service.ts'
 * main difference is, that this impl only allows to update / reload one key at the same time
 * concurrent update calls to this store with different keys, are not handled, only one is going to be updated
 * [Note] in other words, this store works, for components, that either show data of one key or the other,
 * but if you have multiple components, that show data of different keys at the same time, its no longer working
 */
@Injectable({ providedIn: 'root' })
export abstract class ParametrizedRepositoryStoreService<T, U> {
	private valueMap: Map<string, T> = new Map<string, T>();
	private wrappedData$: BehaviorSubject<DataWrapper<Map<string, T>>> = new BehaviorSubject<DataWrapper<Map<string, T>>>({
		loading: false,
	});

	abstract fetchData(key: U): Observable<T>;

	abstract keyToString(key: U): string;

	getFirst(key: U): Observable<T> {
		if (this.checkIfUpdateIsNecessary(key)) {
			this.update(key);
		}

		return this.wrappedData$.asObservable().pipe(
			mergeMap((wrappedData: DataWrapper<Map<string, T>>) => {
				if (wrappedData.error) {
					return throwError(wrappedData.error);
				} else {
					return of(wrappedData.value);
				}
			}),
			filter((data: Map<string, T>) => isDefined(data) && this.hasValue(key, data)),
			map(() => this.valueMap.get(this.keyToString(key))),
			first(),
		);
	}

	getData(key: U): Observable<DataWrapper<T>> {
		if (this.checkIfUpdateIsNecessary(key)) {
			this.update(key);
		}

		return this.wrappedData$.asObservable().pipe(
			map((wrapper: DataWrapper<Map<string, T>>) => ({
				...wrapper,
				value: this.valueMap.get(this.keyToString(key)),
			})),
		);
	}

	update(key: U): void {
		this.wrappedData$.next({ loading: true });

		this.fetchData(key)
			.pipe(first())
			.subscribe({
				next: (value: T) => {
					this.valueMap = this.valueMap.set(this.keyToString(key), value);
					this.wrappedData$.next({ loading: false, value: this.valueMap });
				},
				error: (error: Error) => this.wrappedData$.next({ error, loading: false }),
			});
	}

	private checkIfUpdateIsNecessary(key: U): boolean {
		const currentValue: DataWrapper<Map<string, T>> = this.wrappedData$.getValue();
		return (!isDefined(currentValue.value) || !this.hasValue(key, currentValue.value)) && !currentValue.loading;
	}

	private hasValue(key: U, data: Map<string, T>): boolean {
		return isDefined(data) && data.has(this.keyToString(key));
	}
}
