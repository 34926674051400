/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { TollBoxResponse } from '../models/toll-box-response';
import { updateBoxCountriesConfiguration } from '../fn/toll-box-controller/update-box-countries-configuration';
import { UpdateBoxCountriesConfiguration$Params } from '../fn/toll-box-controller/update-box-countries-configuration';
import { updateBoxesAndVehiclesConnection } from '../fn/toll-box-controller/update-boxes-and-vehicles-connection';
import { UpdateBoxesAndVehiclesConnection$Params } from '../fn/toll-box-controller/update-boxes-and-vehicles-connection';

@Injectable({ providedIn: 'root' })
export class TollBoxControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateBoxesAndVehiclesConnection()` */
  static readonly UpdateBoxesAndVehiclesConnectionPath = '/toll/boxes/vehicle-box-connections';

  /**
   * Updating the coupling between boxes and vehicles of a customer and if necessary start new processes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBoxesAndVehiclesConnection()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBoxesAndVehiclesConnection$Response(params: UpdateBoxesAndVehiclesConnection$Params, context?: HttpContext): Observable<StrictHttpResponse<TollBoxResponse>> {
    return updateBoxesAndVehiclesConnection(this.http, this.rootUrl, params, context);
  }

  /**
   * Updating the coupling between boxes and vehicles of a customer and if necessary start new processes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBoxesAndVehiclesConnection$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBoxesAndVehiclesConnection(params: UpdateBoxesAndVehiclesConnection$Params, context?: HttpContext): Observable<TollBoxResponse> {
    return this.updateBoxesAndVehiclesConnection$Response(params, context).pipe(
      map((r: StrictHttpResponse<TollBoxResponse>): TollBoxResponse => r.body)
    );
  }

  /** Path part for operation `updateBoxCountriesConfiguration()` */
  static readonly UpdateBoxCountriesConfigurationPath = '/toll/boxes/countries-configuration';

  /**
   * Updating countries where a customer could drive with its box and if necessary start a new process
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBoxCountriesConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBoxCountriesConfiguration$Response(params: UpdateBoxCountriesConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<TollBoxResponse>> {
    return updateBoxCountriesConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Updating countries where a customer could drive with its box and if necessary start a new process
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBoxCountriesConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBoxCountriesConfiguration(params: UpdateBoxCountriesConfiguration$Params, context?: HttpContext): Observable<TollBoxResponse> {
    return this.updateBoxCountriesConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<TollBoxResponse>): TollBoxResponse => r.body)
    );
  }

}
