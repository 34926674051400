import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, first, mergeMap } from 'rxjs/operators';
import { DataWrapper } from '../models/data-wrapper.model';

export abstract class RepositoryStoreService<T> {
	private wrappedData$: BehaviorSubject<DataWrapper<T>> = new BehaviorSubject<DataWrapper<T>>({ loading: false });

	abstract fetchData(): Observable<T>;

	getFirst(): Observable<T> {
		if (this.checkIfUpdateIsNecessary()) {
			this.update();
		}

		return this.wrappedData$.asObservable().pipe(
			mergeMap((wrappedData: DataWrapper<T>) => {
				if (wrappedData.error) {
					return throwError(wrappedData.error);
				} else {
					return of(wrappedData.value);
				}
			}),
			filter((data: T) => data !== undefined),
			first(),
		);
	}

	getData(): Observable<DataWrapper<T>> {
		if (this.checkIfUpdateIsNecessary()) {
			this.update();
		}

		return this.wrappedData$.asObservable();
	}

	set(value: T): void {
		this.wrappedData$.next({ loading: false, value });
	}

	update(): void {
		this.wrappedData$.next({ loading: true });

		this.fetchData()
			.pipe(first())
			.subscribe({
				next: (value: T) => this.set(value),
				error: (error: Error) => this.wrappedData$.next({ error, loading: false }),
			});
	}

	private checkIfUpdateIsNecessary(): boolean {
		const currentValue: DataWrapper<T> = this.wrappedData$.getValue();
		return !currentValue.value && !currentValue.loading;
	}
}
