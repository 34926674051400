/**
 * [NOTE] transforms `string or string[]` to string[] and filters empty values in array
 */
export const toArray = <T = string>(input: T | T[]): T[] => {
	if (!input) {
		return [];
	} else if (Array.isArray(input)) {
		return input.filter((value: T) => !!value);
	} else {
		return [input];
	}
};
