import { Employee, SvgPermission } from '@mysvg/api/auth';

import { WithPermissions } from './permission.model';

export enum BulkUpdateMethod {
	Add,
	Remove,
	Replace,
}

export interface BulkUpdateData extends WithPermissions {
	employees: Employee[];
	permissions: SvgPermission[];
	method: BulkUpdateMethod;
}
