import { Injectable } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { FilterParams, LoadDataConfiguration, ResponseMeta, isDefined } from '@mysvg/utils';

import { CustomFilter } from '../interfaces/custom-filter.interface';
import { TableViewOptionsService } from './table-view-options.service';

interface PageParams {
	pageNumber: number;
	pageSize: number;
}

interface SortParams {
	sortOrder?: 'ASC' | 'DESC';
	sortProperty?: string;
}

// [CAUTION] `Resp` and `ResponseDirectory` are defined here, because type of self has changed in new go services
interface Resp<T> {
	data?: T[];
	directory?: ResponseDirectory;
	meta?: ResponseMeta;
}

export interface ResponseDirectory {
	next?: string;
	prev?: string;
	self?: any; // from Self{id: number, href: string} to string, both should be supported here
}

export type LoadData<Data, FilterData> = (configuration: LoadDataConfiguration<FilterData>) => Observable<Resp<Data>>;

@Injectable()
export class TableViewHttpService<FilterData> {
	constructor(private tableViewOptionsService: TableViewOptionsService) {}

	getLoadDataConfiguration(state: ClrDatagridStateInterface): Observable<LoadDataConfiguration<FilterData>> {
		return this.getPageData(state).pipe(
			map(
				(pageParams: PageParams) =>
					[this.getFilterParams(state), pageParams, this.getSortParams(state)] as [FilterParams<FilterData>, PageParams, SortParams],
			),
			map(([filterParams, pageParams, sortParams]: [FilterParams<FilterData>, PageParams, SortParams]) => ({
				filters: filterParams,
				pageNumber: pageParams.pageNumber,
				pageSize: pageParams.pageSize,
				sortOrder: sortParams.sortOrder,
				sortProperty: sortParams.sortProperty,
			})),
		);
	}

	private getSortParams(state: ClrDatagridStateInterface): SortParams {
		if (state.sort) {
			return { sortOrder: state.sort.reverse ? 'DESC' : 'ASC', sortProperty: state.sort.by.toString() };
		} else {
			return {};
		}
	}

	private getFilterParams(state: ClrDatagridStateInterface): FilterParams<FilterData> {
		if (state.filters) {
			return state.filters
				.map((paramFilter) =>
					isDefined(paramFilter.getSelection) ? this.getCustomFilterParam(paramFilter.property, paramFilter) : paramFilter,
				)
				.reduceRight<FilterParams<FilterData>>(
					(filterParams: FilterParams<FilterData>, filterParam) => ({ ...filterParams, [filterParam.property]: filterParam.value }),
					{},
				);
		} else {
			return {};
		}
	}

	private getCustomFilterParam<K extends keyof FilterData>(
		property: K,
		customFilter: CustomFilter<FilterData[K]>,
	): { property: string; value: FilterData[K] } {
		return { property: property.toString(), value: customFilter.getSelection() };
	}

	private getPageData(state: ClrDatagridStateInterface): Observable<PageParams> {
		if (state.page) {
			return of({ pageSize: state.page.size, pageNumber: state.page.current, state });
		} else {
			return this.tableViewOptionsService.pageSize$.pipe(
				first(),
				map((pageSize: number) => ({ pageSize, pageNumber: 0, state })),
			);
		}
	}
}
