/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BusinessSector } from '../models/business-sector';
import { getBusinessSectors } from '../fn/business-sector-controller/get-business-sectors';
import { GetBusinessSectors$Params } from '../fn/business-sector-controller/get-business-sectors';

@Injectable({ providedIn: 'root' })
export class BusinessSectorControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getBusinessSectors()` */
  static readonly GetBusinessSectorsPath = '/businessSectors';

  /**
   * get a list of all business sectors
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBusinessSectors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBusinessSectors$Response(params?: GetBusinessSectors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BusinessSector>>> {
    return getBusinessSectors(this.http, this.rootUrl, params, context);
  }

  /**
   * get a list of all business sectors
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBusinessSectors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBusinessSectors(params?: GetBusinessSectors$Params, context?: HttpContext): Observable<Array<BusinessSector>> {
    return this.getBusinessSectors$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BusinessSector>>): Array<BusinessSector> => r.body)
    );
  }

}
