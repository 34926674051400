import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectWrapperModule, UtilsModule } from '@mysvg/utils';
import { ErrorModule } from '@svg-frontends/error';
import { FileUploadModule } from '@svg-frontends/file-upload';
import { ProgressSpinnerModule } from '@svg-frontends/progress-spinner';
import { ClarityWrapperModule, OwlWrapperModule } from '@svg-frontends/theming';

import { CheckboxFormControlComponent } from './components/checkbox-form-control/checkbox-form-control.component';
import { ControlWrapperComponent } from './components/control-wrapper/control-wrapper.component';
import { DatePickerFormControlComponent } from './components/date-picker-form-control/date-picker-form-control.component';
import { DateTimePickerFormControlComponent } from './components/date-time-picker-form-control/date-time-picker-form-control.component';
import { FileFormControlSelectedFileComponent } from './components/file-form-control/file-form-control-selected-file/file-form-control-selected-file.component';
import { FileFormControlComponent } from './components/file-form-control/file-form-control.component';
import { FormBlockComponent } from './components/form-block/form-block.component';
import { FormGroupComponent } from './components/form-group/form-group.component';
import { FormWrapperComponent } from './components/form-wrapper/form-wrapper.component';
import { OptionContainerComponent } from './components/option-container/option-container.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { RequiredMarkerComponent } from './components/required-marker/required-marker.component';
import { SelectFormControlComponent } from './components/select-form-control/select-form-control.component';
import { StringOrTemplateRefComponent } from './components/string-or-template-ref/string-or-template-ref.component';
import { TextAreaFormControlComponent } from './components/text-area-form-control/text-area-form-control.component';
import { TextFormControlComponent } from './components/text-form-control/text-form-control.component';
import { TimePickerFormControlComponent } from './components/time-picker-form-control/time-picker-form-control.component';
import { DatePickerAccountingDateFilterDirective } from './directives/date-picker-accounting-date-filter.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { MacDisableFormDirective } from './directives/disable-form.directive';
import { FirstControlErrorPipe } from './pipes/first-control-error/first-control-error.pipe';
import { IsFormControlRequiredPipe } from './pipes/is-form-control-required/is-form-control-required.pipe';

@NgModule({
	declarations: [
		CheckboxFormControlComponent,
		ControlWrapperComponent,
		DatePickerFormControlComponent,
		DateTimePickerFormControlComponent,
		DisableControlDirective,
		MacDisableFormDirective,
		DatePickerAccountingDateFilterDirective,
		FileFormControlComponent,
		FileFormControlSelectedFileComponent,
		FirstControlErrorPipe,
		FormBlockComponent,
		FormGroupComponent,
		FormWrapperComponent,
		IsFormControlRequiredPipe,
		OptionContainerComponent,
		RadioGroupComponent,
		RequiredMarkerComponent,
		SelectFormControlComponent,
		StringOrTemplateRefComponent,
		TextAreaFormControlComponent,
		TextFormControlComponent,
		TimePickerFormControlComponent,
	],
	exports: [
		CheckboxFormControlComponent,
		ControlWrapperComponent,
		DatePickerFormControlComponent,
		DateTimePickerFormControlComponent,
		DisableControlDirective,
		MacDisableFormDirective,
		DatePickerAccountingDateFilterDirective,
		FileFormControlComponent,
		FormBlockComponent,
		FormGroupComponent,
		FormWrapperComponent,
		OptionContainerComponent,
		RadioGroupComponent,
		SelectFormControlComponent,
		TextAreaFormControlComponent,
		TextFormControlComponent,
		TimePickerFormControlComponent,
		NgSelectWrapperModule,
		FormsModule,
		ReactiveFormsModule,
	],
	imports: [
		CommonModule,
		ClarityWrapperModule,
		ErrorModule,
		FileUploadModule,
		FormsModule,
		FontAwesomeModule,
		ReactiveFormsModule,
		NgSelectWrapperModule,
		OwlWrapperModule,
		ProgressSpinnerModule,
		UtilsModule,
	],
})
export class FormModule {}
