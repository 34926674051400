import { Injectable } from '@angular/core';
import { SafetyControllerService, SafetySubTypeDto } from '@mysvg/api/financial-guarantees';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SafetySubTypesStoreService extends RepositoryStoreService<SafetySubTypeDto[]> {
	constructor(private safetyControllerService: SafetyControllerService) {
		super();
	}

	fetchData(): Observable<SafetySubTypeDto[]> {
		return this.safetyControllerService.getSubTypes();
	}
}
