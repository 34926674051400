/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ProcessDto } from '../../models/process-dto';

export interface SetProcessEditor$Params {
  processId: string;
  overwriteChildTasks?: boolean;
  editor: string;
}

export function setProcessEditor(http: HttpClient, rootUrl: string, params: SetProcessEditor$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessDto>> {
  const rb = new RequestBuilder(rootUrl, setProcessEditor.PATH, 'put');
  if (params) {
    rb.path('processId', params.processId, {});
    rb.query('overwriteChildTasks', params.overwriteChildTasks, {});
    rb.query('editor', params.editor, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ProcessDto>;
    })
  );
}

setProcessEditor.PATH = '/processes/{processId}/editor';
