/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Employee } from '../models/employee';
import { getEmployee } from '../fn/employee-management-controller/get-employee';
import { GetEmployee$Params } from '../fn/employee-management-controller/get-employee';
import { getEmployees } from '../fn/employee-management-controller/get-employees';
import { GetEmployees$Params } from '../fn/employee-management-controller/get-employees';
import { patchEmployeesWithPermissionMap } from '../fn/employee-management-controller/patch-employees-with-permission-map';
import { PatchEmployeesWithPermissionMap$Params } from '../fn/employee-management-controller/patch-employees-with-permission-map';
import { ResponseEmployee } from '../models/response-employee';
import { updateEmployee } from '../fn/employee-management-controller/update-employee';
import { UpdateEmployee$Params } from '../fn/employee-management-controller/update-employee';
import { updateEmployeesWithPermissionMap } from '../fn/employee-management-controller/update-employees-with-permission-map';
import { UpdateEmployeesWithPermissionMap$Params } from '../fn/employee-management-controller/update-employees-with-permission-map';

@Injectable({ providedIn: 'root' })
export class EmployeeManagementControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getEmployees()` */
  static readonly GetEmployeesPath = '/employees';

  /**
   * getting all employess. returning a permisssion overview only.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployees()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployees$Response(params?: GetEmployees$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseEmployee>> {
    return getEmployees(this.http, this.rootUrl, params, context);
  }

  /**
   * getting all employess. returning a permisssion overview only.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEmployees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployees(params?: GetEmployees$Params, context?: HttpContext): Observable<ResponseEmployee> {
    return this.getEmployees$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseEmployee>): ResponseEmployee => r.body)
    );
  }

  /** Path part for operation `updateEmployeesWithPermissionMap()` */
  static readonly UpdateEmployeesWithPermissionMapPath = '/employees';

  /**
   * mass update of employees
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEmployeesWithPermissionMap()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEmployeesWithPermissionMap$Response(params: UpdateEmployeesWithPermissionMap$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Employee>>> {
    return updateEmployeesWithPermissionMap(this.http, this.rootUrl, params, context);
  }

  /**
   * mass update of employees
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateEmployeesWithPermissionMap$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEmployeesWithPermissionMap(params: UpdateEmployeesWithPermissionMap$Params, context?: HttpContext): Observable<Array<Employee>> {
    return this.updateEmployeesWithPermissionMap$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Employee>>): Array<Employee> => r.body)
    );
  }

  /** Path part for operation `patchEmployeesWithPermissionMap()` */
  static readonly PatchEmployeesWithPermissionMapPath = '/employees';

  /**
   * mass patch of employees
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEmployeesWithPermissionMap()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchEmployeesWithPermissionMap$Response(params: PatchEmployeesWithPermissionMap$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Employee>>> {
    return patchEmployeesWithPermissionMap(this.http, this.rootUrl, params, context);
  }

  /**
   * mass patch of employees
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchEmployeesWithPermissionMap$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchEmployeesWithPermissionMap(params: PatchEmployeesWithPermissionMap$Params, context?: HttpContext): Observable<Array<Employee>> {
    return this.patchEmployeesWithPermissionMap$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Employee>>): Array<Employee> => r.body)
    );
  }

  /** Path part for operation `getEmployee()` */
  static readonly GetEmployeePath = '/employees/{id}';

  /**
   * getting one employee with full permission map.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployee$Response(params: GetEmployee$Params, context?: HttpContext): Observable<StrictHttpResponse<Employee>> {
    return getEmployee(this.http, this.rootUrl, params, context);
  }

  /**
   * getting one employee with full permission map.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEmployee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployee(params: GetEmployee$Params, context?: HttpContext): Observable<Employee> {
    return this.getEmployee$Response(params, context).pipe(
      map((r: StrictHttpResponse<Employee>): Employee => r.body)
    );
  }

  /** Path part for operation `updateEmployee()` */
  static readonly UpdateEmployeePath = '/employees/{id}';

  /**
   * updating the employee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEmployee()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEmployee$Response(params: UpdateEmployee$Params, context?: HttpContext): Observable<StrictHttpResponse<Employee>> {
    return updateEmployee(this.http, this.rootUrl, params, context);
  }

  /**
   * updating the employee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateEmployee$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEmployee(params: UpdateEmployee$Params, context?: HttpContext): Observable<Employee> {
    return this.updateEmployee$Response(params, context).pipe(
      map((r: StrictHttpResponse<Employee>): Employee => r.body)
    );
  }

}
