import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '@mysvg/utils';

import { FileTypeInfoService } from '../../utils/file-type-info/file-type-info.service';
import { FileTypeEnum } from '../../utils/file-type.enum';

@Pipe({ name: 'allowedFileTypes' })
export class AllowedFileTypesPipe implements PipeTransform {
	constructor(private fileTypeInfoService: FileTypeInfoService) {}

	transform(allowedFileTypes: FileTypeEnum[]): string {
		if (isDefined(allowedFileTypes) && allowedFileTypes.length > 0) {
			return this.transformToFileExtensions(allowedFileTypes);
		} else {
			return '';
		}
	}

	/**
	 * [NOTE] map `allowedFileTypes` to their corresponding extension strings, which will result in a two dimensional string[][]
	 * 				flatten the array using reduce, then add dots and join to comma seperated string
	 */
	private transformToFileExtensions(allowedFileTypes: FileTypeEnum[]): string {
		return allowedFileTypes
			.map((macFileTypeEnum: FileTypeEnum) => this.fileTypeInfoService.getExtensionsFor(macFileTypeEnum))
			.reduce((previousValue: string[], currentValue: string[]) => [...previousValue, ...currentValue])
			.map((allowedFileType: string) => `.${allowedFileType}`)
			.join(',');
	}
}
