/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseBookingDto } from '../../models/response-booking-dto';

export interface GetAllBookings1$Params {

/**
 * 7digit customerNumber
 */
  customerNumber: string;
  rebookingsStatus?: string;
  origin?: string;
  dueDate?: string;
  text?: string;
  credit?: string;
  debit?: string;
  recordNumber?: string;
  recordType?: string;
  journalNumber?: string;
  recordDate?: string;
  bookingDate?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getAllBookings1(http: HttpClient, rootUrl: string, params: GetAllBookings1$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBookingDto>> {
  const rb = new RequestBuilder(rootUrl, getAllBookings1.PATH, 'get');
  if (params) {
    rb.query('customerNumber', params.customerNumber, {});
    rb.query('rebookingsStatus', params.rebookingsStatus, {});
    rb.query('origin', params.origin, {});
    rb.query('dueDate', params.dueDate, {});
    rb.query('text', params.text, {});
    rb.query('credit', params.credit, {});
    rb.query('debit', params.debit, {});
    rb.query('recordNumber', params.recordNumber, {});
    rb.query('recordType', params.recordType, {});
    rb.query('journalNumber', params.journalNumber, {});
    rb.query('recordDate', params.recordDate, {});
    rb.query('bookingDate', params.bookingDate, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseBookingDto>;
    })
  );
}

getAllBookings1.PATH = '/bookings/all';
