import { HttpErrorResponse } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { SvgErrorMessageFactoryService } from '../services/error-message-factory/svg-error-message-factory.service';

@Pipe({ name: 'errorMessages' })
export class ErrorMessagesPipe implements PipeTransform {
	constructor(private errorMessageFactoryService: SvgErrorMessageFactoryService) {}

	transform(errorResponse: Error | HttpErrorResponse): string[] {
		return this.errorMessageFactoryService.getMessageForError(errorResponse);
	}
}
