import { Pipe, PipeTransform } from '@angular/core';

import { DocumentService } from '../services/document.service';

@Pipe({ name: 'privateDownloadLinkFromHash' })
export class PrivateDownloadLinkFromHashPipe implements PipeTransform {
	constructor(private documentService: DocumentService) {}

	transform(downloadHash: string): string {
		return this.documentService.getPrivateDocumentUrlFromHash(downloadHash);
	}
}
