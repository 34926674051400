/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getGroupedListofOperationFigures } from '../fn/operation-figure-controller/get-grouped-listof-operation-figures';
import { GetGroupedListofOperationFigures$Params } from '../fn/operation-figure-controller/get-grouped-listof-operation-figures';
import { getListOfListOfOperationFigures } from '../fn/operation-figure-controller/get-list-of-list-of-operation-figures';
import { GetListOfListOfOperationFigures$Params } from '../fn/operation-figure-controller/get-list-of-list-of-operation-figures';
import { getNumericOperationFigure } from '../fn/operation-figure-controller/get-numeric-operation-figure';
import { GetNumericOperationFigure$Params } from '../fn/operation-figure-controller/get-numeric-operation-figure';

@Injectable({ providedIn: 'root' })
export class OperationFigureControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getListOfListOfOperationFigures()` */
  static readonly GetListOfListOfOperationFiguresPath = '/opfigureType3';

  /**
   * Get a double grouped operation figure as List<Map.Entry<String, List<Map.Entry<String, BigDecimal>>>>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getListOfListOfOperationFigures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListOfListOfOperationFigures$Response(params: GetListOfListOfOperationFigures$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<{
'value'?: Array<{
'value'?: number;
'key'?: string;
}>;
'key'?: string;
}>>> {
    return getListOfListOfOperationFigures(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a double grouped operation figure as List<Map.Entry<String, List<Map.Entry<String, BigDecimal>>>>
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getListOfListOfOperationFigures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListOfListOfOperationFigures(params: GetListOfListOfOperationFigures$Params, context?: HttpContext): Observable<Array<{
'value'?: Array<{
'value'?: number;
'key'?: string;
}>;
'key'?: string;
}>> {
    return this.getListOfListOfOperationFigures$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{
'value'?: Array<{
'value'?: number;
'key'?: string;
}>;
'key'?: string;
}>>): Array<{
'value'?: Array<{
'value'?: number;
'key'?: string;
}>;
'key'?: string;
}> => r.body)
    );
  }

  /** Path part for operation `getGroupedListofOperationFigures()` */
  static readonly GetGroupedListofOperationFiguresPath = '/opfigureType2';

  /**
   * Get a grouped operation figure as List<Map.Entry<String, BigDecimal>>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroupedListofOperationFigures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupedListofOperationFigures$Response(params: GetGroupedListofOperationFigures$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<{
'value'?: number;
'key'?: string;
}>>> {
    return getGroupedListofOperationFigures(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a grouped operation figure as List<Map.Entry<String, BigDecimal>>
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGroupedListofOperationFigures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupedListofOperationFigures(params: GetGroupedListofOperationFigures$Params, context?: HttpContext): Observable<Array<{
'value'?: number;
'key'?: string;
}>> {
    return this.getGroupedListofOperationFigures$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{
'value'?: number;
'key'?: string;
}>>): Array<{
'value'?: number;
'key'?: string;
}> => r.body)
    );
  }

  /** Path part for operation `getNumericOperationFigure()` */
  static readonly GetNumericOperationFigurePath = '/opfigureType1';

  /**
   * Get a single operation figure as a number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNumericOperationFigure()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumericOperationFigure$Response(params: GetNumericOperationFigure$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getNumericOperationFigure(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a single operation figure as a number
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNumericOperationFigure$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumericOperationFigure(params: GetNumericOperationFigure$Params, context?: HttpContext): Observable<number> {
    return this.getNumericOperationFigure$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
