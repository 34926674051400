/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createCustomerGroup } from '../fn/groups-controller/create-customer-group';
import { CreateCustomerGroup$Params } from '../fn/groups-controller/create-customer-group';
import { deleteGroupMembers } from '../fn/groups-controller/delete-group-members';
import { DeleteGroupMembers$Params } from '../fn/groups-controller/delete-group-members';
import { getGroup } from '../fn/groups-controller/get-group';
import { GetGroup$Params } from '../fn/groups-controller/get-group';
import { getGroupMembers } from '../fn/groups-controller/get-group-members';
import { GetGroupMembers$Params } from '../fn/groups-controller/get-group-members';
import { getGroups } from '../fn/groups-controller/get-groups';
import { GetGroups$Params } from '../fn/groups-controller/get-groups';
import { GroupDto } from '../models/group-dto';
import { ResponseGroupDto } from '../models/response-group-dto';
import { UserDto } from '../models/user-dto';

@Injectable({ providedIn: 'root' })
export class GroupsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createCustomerGroup()` */
  static readonly CreateCustomerGroupPath = '/groups/{svgCustomerNumber}';

  /**
   * inserts or updates a keycloak group for a customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomerGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomerGroup$Response(params: CreateCustomerGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupDto>> {
    return createCustomerGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * inserts or updates a keycloak group for a customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCustomerGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomerGroup(params: CreateCustomerGroup$Params, context?: HttpContext): Observable<GroupDto> {
    return this.createCustomerGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupDto>): GroupDto => r.body)
    );
  }

  /** Path part for operation `getGroups()` */
  static readonly GetGroupsPath = '/groups';

  /**
   * searches for keycloak groups
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroups$Response(params?: GetGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseGroupDto>> {
    return getGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * searches for keycloak groups
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroups(params?: GetGroups$Params, context?: HttpContext): Observable<ResponseGroupDto> {
    return this.getGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseGroupDto>): ResponseGroupDto => r.body)
    );
  }

  /** Path part for operation `getGroup()` */
  static readonly GetGroupPath = '/groups/{id}';

  /**
   * returns the keycloak group for the given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroup$Response(params: GetGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupDto>> {
    return getGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * returns the keycloak group for the given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroup(params: GetGroup$Params, context?: HttpContext): Observable<GroupDto> {
    return this.getGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupDto>): GroupDto => r.body)
    );
  }

  /** Path part for operation `getGroupMembers()` */
  static readonly GetGroupMembersPath = '/groups/{id}/members';

  /**
   * returns the members of the keycloak group with the given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroupMembers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupMembers$Response(params: GetGroupMembers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserDto>>> {
    return getGroupMembers(this.http, this.rootUrl, params, context);
  }

  /**
   * returns the members of the keycloak group with the given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGroupMembers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupMembers(params: GetGroupMembers$Params, context?: HttpContext): Observable<Array<UserDto>> {
    return this.getGroupMembers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>): Array<UserDto> => r.body)
    );
  }

  /** Path part for operation `deleteGroupMembers()` */
  static readonly DeleteGroupMembersPath = '/groups/{id}/members';

  /**
   * deletes the members of the keycloak group with the given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteGroupMembers()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGroupMembers$Response(params: DeleteGroupMembers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteGroupMembers(this.http, this.rootUrl, params, context);
  }

  /**
   * deletes the members of the keycloak group with the given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteGroupMembers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGroupMembers(params: DeleteGroupMembers$Params, context?: HttpContext): Observable<void> {
    return this.deleteGroupMembers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
