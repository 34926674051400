export interface QuantityUnit {
	label: string;
	pipe?: any;
	pipeArgs?: any[];
}

export const QuantityUnitEnum: { [key: string]: QuantityUnit } = {
	L: { label: 'Liter' },
	P: { label: 'Stück' },
	'€': { label: '€' },
	Db: { label: 'Db' },
	FR: { label: 'FR' },
	kg: { label: 'kg' },
	KM: { label: 'KM' },
	LT: { label: 'LT' },
	LU: { label: 'LU' },
	NL: { label: 'NL' },
	PZ: { label: 'PZ' },
};
