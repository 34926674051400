/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { archiveDocument } from '../fn/documents-archive-controller/archive-document';
import { ArchiveDocument$Params } from '../fn/documents-archive-controller/archive-document';
import { DocumentDto } from '../models/document-dto';


/**
 * Provides operations to archive documents.
 */
@Injectable({ providedIn: 'root' })
export class DocumentsArchiveControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `archiveDocument()` */
  static readonly ArchiveDocumentPath = '/documents/{id}/archive';

  /**
   * Archive a document. All parents and childs will be archived in the process.Returns info on all the documents archived in the process.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `archiveDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  archiveDocument$Response(params: ArchiveDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentDto>>> {
    return archiveDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Archive a document. All parents and childs will be archived in the process.Returns info on all the documents archived in the process.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `archiveDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  archiveDocument(params: ArchiveDocument$Params, context?: HttpContext): Observable<Array<DocumentDto>> {
    return this.archiveDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>): Array<DocumentDto> => r.body)
    );
  }

}
