<clr-main-container>
	<clr-header>
		<div class="branding">
			<a class="nav-link" routerLink="/">
				<img src="assets/img/headerlogo.png" height="100%" alt="myASI" />
			</a>
		</div>
	</clr-header>

	<svg-frontends-error-content></svg-frontends-error-content>
</clr-main-container>
