/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountBoView } from '../models/account-bo-view';
import { AccountOverviewView } from '../models/account-overview-view';
import { BlockConfigView } from '../models/block-config-view';
import { BoxTypeView } from '../models/box-type-view';
import { createAccount } from '../fn/account/create-account';
import { CreateAccount$Params } from '../fn/account/create-account';
import { getAccount } from '../fn/account/get-account';
import { GetAccount$Params } from '../fn/account/get-account';
import { getAccountOverviewViewsForCustomer } from '../fn/account/get-account-overview-views-for-customer';
import { GetAccountOverviewViewsForCustomer$Params } from '../fn/account/get-account-overview-views-for-customer';
import { getBoxTypesForAccountHavingRequiredFields } from '../fn/account/get-box-types-for-account-having-required-fields';
import { GetBoxTypesForAccountHavingRequiredFields$Params } from '../fn/account/get-box-types-for-account-having-required-fields';
import { getConfig } from '../fn/account/get-config';
import { GetConfig$Params } from '../fn/account/get-config';
import { unlockAccount } from '../fn/account/unlock-account';
import { UnlockAccount$Params } from '../fn/account/unlock-account';
import { updateAccount } from '../fn/account/update-account';
import { UpdateAccount$Params } from '../fn/account/update-account';


/**
 * Account crud operations
 */
@Injectable({ providedIn: 'root' })
export class AccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAccount()` */
  static readonly GetAccountPath = '/accounts/{id}';

  /**
   * Returns a single account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccount$Response(params: GetAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountBoView>> {
    return getAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a single account
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccount(params: GetAccount$Params, context?: HttpContext): Observable<AccountBoView> {
    return this.getAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountBoView>): AccountBoView => r.body)
    );
  }

  /** Path part for operation `updateAccount()` */
  static readonly UpdateAccountPath = '/accounts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAccount$Response(params: UpdateAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountBoView>> {
    return updateAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAccount(params: UpdateAccount$Params, context?: HttpContext): Observable<AccountBoView> {
    return this.updateAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountBoView>): AccountBoView => r.body)
    );
  }

  /** Path part for operation `unlockAccount()` */
  static readonly UnlockAccountPath = '/accounts/{id}/unlock';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unlockAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockAccount$Response(params: UnlockAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountBoView>> {
    return unlockAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unlockAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockAccount(params: UnlockAccount$Params, context?: HttpContext): Observable<AccountBoView> {
    return this.unlockAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountBoView>): AccountBoView => r.body)
    );
  }

  /** Path part for operation `createAccount()` */
  static readonly CreateAccountPath = '/accounts';

  /**
   * Create account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAccount$Response(params: CreateAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountBoView>> {
    return createAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * Create account
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAccount(params: CreateAccount$Params, context?: HttpContext): Observable<AccountBoView> {
    return this.createAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountBoView>): AccountBoView => r.body)
    );
  }

  /** Path part for operation `getBoxTypesForAccountHavingRequiredFields()` */
  static readonly GetBoxTypesForAccountHavingRequiredFieldsPath = '/accounts/{accountId}/validatable-boxtypes';

  /**
   * Returns all boxes where validation can be applied for the given customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBoxTypesForAccountHavingRequiredFields()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoxTypesForAccountHavingRequiredFields$Response(params: GetBoxTypesForAccountHavingRequiredFields$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BoxTypeView>>> {
    return getBoxTypesForAccountHavingRequiredFields(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all boxes where validation can be applied for the given customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBoxTypesForAccountHavingRequiredFields$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoxTypesForAccountHavingRequiredFields(params: GetBoxTypesForAccountHavingRequiredFields$Params, context?: HttpContext): Observable<Array<BoxTypeView>> {
    return this.getBoxTypesForAccountHavingRequiredFields$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BoxTypeView>>): Array<BoxTypeView> => r.body)
    );
  }

  /** Path part for operation `getConfig()` */
  static readonly GetConfigPath = '/accounts/{accountId}/block-config';

  /**
   * Returns account lock config.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfig$Response(params: GetConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<BlockConfigView>> {
    return getConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns account lock config.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfig(params: GetConfig$Params, context?: HttpContext): Observable<BlockConfigView> {
    return this.getConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<BlockConfigView>): BlockConfigView => r.body)
    );
  }

  /** Path part for operation `getAccountOverviewViewsForCustomer()` */
  static readonly GetAccountOverviewViewsForCustomerPath = '/accounts/overview';

  /**
   * Returns a list of all account types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountOverviewViewsForCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountOverviewViewsForCustomer$Response(params: GetAccountOverviewViewsForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountOverviewView>>> {
    return getAccountOverviewViewsForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a list of all account types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountOverviewViewsForCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountOverviewViewsForCustomer(params: GetAccountOverviewViewsForCustomer$Params, context?: HttpContext): Observable<Array<AccountOverviewView>> {
    return this.getAccountOverviewViewsForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountOverviewView>>): Array<AccountOverviewView> => r.body)
    );
  }

}
