/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addNewDocument } from '../fn/vehicle-documents-controller/add-new-document';
import { AddNewDocument$Params } from '../fn/vehicle-documents-controller/add-new-document';
import { deleteVehicleDocumentByDownloadHash } from '../fn/vehicle-documents-controller/delete-vehicle-document-by-download-hash';
import { DeleteVehicleDocumentByDownloadHash$Params } from '../fn/vehicle-documents-controller/delete-vehicle-document-by-download-hash';
import { Document } from '../models/document';
import { FileDownloadInfoDto } from '../models/file-download-info-dto';
import { getDocumentByDownloadHash } from '../fn/vehicle-documents-controller/get-document-by-download-hash';
import { GetDocumentByDownloadHash$Params } from '../fn/vehicle-documents-controller/get-document-by-download-hash';
import { getDocuments } from '../fn/vehicle-documents-controller/get-documents';
import { GetDocuments$Params } from '../fn/vehicle-documents-controller/get-documents';
import { getDocumentsOfVehicle } from '../fn/vehicle-documents-controller/get-documents-of-vehicle';
import { GetDocumentsOfVehicle$Params } from '../fn/vehicle-documents-controller/get-documents-of-vehicle';
import { getDocumentTypes } from '../fn/vehicle-documents-controller/get-document-types';
import { GetDocumentTypes$Params } from '../fn/vehicle-documents-controller/get-document-types';
import { getRegistrationCertificate } from '../fn/vehicle-documents-controller/get-registration-certificate';
import { GetRegistrationCertificate$Params } from '../fn/vehicle-documents-controller/get-registration-certificate';
import { getVehicleDocumentByDownloadHash } from '../fn/vehicle-documents-controller/get-vehicle-document-by-download-hash';
import { GetVehicleDocumentByDownloadHash$Params } from '../fn/vehicle-documents-controller/get-vehicle-document-by-download-hash';
import { ResponseSvgDocument } from '../models/response-svg-document';
import { SvgDocument } from '../models/svg-document';
import { updateDocument } from '../fn/vehicle-documents-controller/update-document';
import { UpdateDocument$Params } from '../fn/vehicle-documents-controller/update-document';

@Injectable({ providedIn: 'root' })
export class VehicleDocumentsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDocumentByDownloadHash()` */
  static readonly GetDocumentByDownloadHashPath = '/documents/{downloadHash}';

  /**
   * getting one document by download hash.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentByDownloadHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentByDownloadHash$Response(params: GetDocumentByDownloadHash$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgDocument>> {
    return getDocumentByDownloadHash(this.http, this.rootUrl, params, context);
  }

  /**
   * getting one document by download hash.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentByDownloadHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentByDownloadHash(params: GetDocumentByDownloadHash$Params, context?: HttpContext): Observable<SvgDocument> {
    return this.getDocumentByDownloadHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<SvgDocument>): SvgDocument => r.body)
    );
  }

  /** Path part for operation `updateDocument()` */
  static readonly UpdateDocumentPath = '/documents/{downloadHash}';

  /**
   * update the document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDocument$Response(params: UpdateDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgDocument>> {
    return updateDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * update the document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDocument(params: UpdateDocument$Params, context?: HttpContext): Observable<SvgDocument> {
    return this.updateDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<SvgDocument>): SvgDocument => r.body)
    );
  }

  /** Path part for operation `getDocumentsOfVehicle()` */
  static readonly GetDocumentsOfVehiclePath = '/vehicles/{id}/documents';

  /**
   * get all documents of the vehicle
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentsOfVehicle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsOfVehicle$Response(params: GetDocumentsOfVehicle$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSvgDocument>> {
    return getDocumentsOfVehicle(this.http, this.rootUrl, params, context);
  }

  /**
   * get all documents of the vehicle
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentsOfVehicle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsOfVehicle(params: GetDocumentsOfVehicle$Params, context?: HttpContext): Observable<ResponseSvgDocument> {
    return this.getDocumentsOfVehicle$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSvgDocument>): ResponseSvgDocument => r.body)
    );
  }

  /** Path part for operation `addNewDocument()` */
  static readonly AddNewDocumentPath = '/vehicles/{id}/documents';

  /**
   * uploading a document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewDocument$Response(params: AddNewDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<Document>> {
    return addNewDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * uploading a document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addNewDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewDocument(params: AddNewDocument$Params, context?: HttpContext): Observable<Document> {
    return this.addNewDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<Document>): Document => r.body)
    );
  }

  /** Path part for operation `getRegistrationCertificate()` */
  static readonly GetRegistrationCertificatePath = '/vehicles/{id}/registration-certificate';

  /**
   * get registration certificate of the vehicle
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegistrationCertificate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegistrationCertificate$Response(params: GetRegistrationCertificate$Params, context?: HttpContext): Observable<StrictHttpResponse<FileDownloadInfoDto>> {
    return getRegistrationCertificate(this.http, this.rootUrl, params, context);
  }

  /**
   * get registration certificate of the vehicle
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRegistrationCertificate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegistrationCertificate(params: GetRegistrationCertificate$Params, context?: HttpContext): Observable<FileDownloadInfoDto> {
    return this.getRegistrationCertificate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileDownloadInfoDto>): FileDownloadInfoDto => r.body)
    );
  }

  /** Path part for operation `getVehicleDocumentByDownloadHash()` */
  static readonly GetVehicleDocumentByDownloadHashPath = '/vehicles/{id}/documents/{downloadHash}';

  /**
   * getting one document by vehicle id and download hash.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleDocumentByDownloadHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleDocumentByDownloadHash$Response(params: GetVehicleDocumentByDownloadHash$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSvgDocument>> {
    return getVehicleDocumentByDownloadHash(this.http, this.rootUrl, params, context);
  }

  /**
   * getting one document by vehicle id and download hash.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicleDocumentByDownloadHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleDocumentByDownloadHash(params: GetVehicleDocumentByDownloadHash$Params, context?: HttpContext): Observable<ResponseSvgDocument> {
    return this.getVehicleDocumentByDownloadHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSvgDocument>): ResponseSvgDocument => r.body)
    );
  }

  /** Path part for operation `deleteVehicleDocumentByDownloadHash()` */
  static readonly DeleteVehicleDocumentByDownloadHashPath = '/vehicles/{id}/documents/{downloadHash}';

  /**
   * delete vehicle document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteVehicleDocumentByDownloadHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVehicleDocumentByDownloadHash$Response(params: DeleteVehicleDocumentByDownloadHash$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteVehicleDocumentByDownloadHash(this.http, this.rootUrl, params, context);
  }

  /**
   * delete vehicle document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteVehicleDocumentByDownloadHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVehicleDocumentByDownloadHash(params: DeleteVehicleDocumentByDownloadHash$Params, context?: HttpContext): Observable<void> {
    return this.deleteVehicleDocumentByDownloadHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getDocuments()` */
  static readonly GetDocumentsPath = '/vehicles/documents';

  /**
   * a list of vehicle documents of the customers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments$Response(params: GetDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSvgDocument>> {
    return getDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * a list of vehicle documents of the customers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments(params: GetDocuments$Params, context?: HttpContext): Observable<ResponseSvgDocument> {
    return this.getDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSvgDocument>): ResponseSvgDocument => r.body)
    );
  }

  /** Path part for operation `getDocumentTypes()` */
  static readonly GetDocumentTypesPath = '/documents/types';

  /**
   * get a list of all document types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes$Response(params?: GetDocumentTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getDocumentTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * get a list of all document types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes(params?: GetDocumentTypes$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getDocumentTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
