/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Document } from '../models/document';
import { getAgbDocuments } from '../fn/public-documents-controller/get-agb-documents';
import { GetAgbDocuments$Params } from '../fn/public-documents-controller/get-agb-documents';
import { getDsgvoDocuments } from '../fn/public-documents-controller/get-dsgvo-documents';
import { GetDsgvoDocuments$Params } from '../fn/public-documents-controller/get-dsgvo-documents';
import { getFaqDocuments } from '../fn/public-documents-controller/get-faq-documents';
import { GetFaqDocuments$Params } from '../fn/public-documents-controller/get-faq-documents';
import { getManualInstructionDocuments } from '../fn/public-documents-controller/get-manual-instruction-documents';
import { GetManualInstructionDocuments$Params } from '../fn/public-documents-controller/get-manual-instruction-documents';
import { getPublicDocumentForEntityByHash } from '../fn/public-documents-controller/get-public-document-for-entity-by-hash';
import { GetPublicDocumentForEntityByHash$Params } from '../fn/public-documents-controller/get-public-document-for-entity-by-hash';
import { getPublicTmpDocumentByFilenameHash } from '../fn/public-documents-controller/get-public-tmp-document-by-filename-hash';
import { GetPublicTmpDocumentByFilenameHash$Params } from '../fn/public-documents-controller/get-public-tmp-document-by-filename-hash';

@Injectable({ providedIn: 'root' })
export class PublicDocumentsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPublicDocumentForEntityByHash()` */
  static readonly GetPublicDocumentForEntityByHashPath = '/public/documents/{downloadHash}';

  /**
   * starts the download stream of a document in the specific entity context
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicDocumentForEntityByHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicDocumentForEntityByHash$Response(params: GetPublicDocumentForEntityByHash$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getPublicDocumentForEntityByHash(this.http, this.rootUrl, params, context);
  }

  /**
   * starts the download stream of a document in the specific entity context
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicDocumentForEntityByHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicDocumentForEntityByHash(params: GetPublicDocumentForEntityByHash$Params, context?: HttpContext): Observable<Blob> {
    return this.getPublicDocumentForEntityByHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `getPublicTmpDocumentByFilenameHash()` */
  static readonly GetPublicTmpDocumentByFilenameHashPath = '/public/documents/temp/{documentHash}';

  /**
   * starts the download stream
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicTmpDocumentByFilenameHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicTmpDocumentByFilenameHash$Response(params: GetPublicTmpDocumentByFilenameHash$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getPublicTmpDocumentByFilenameHash(this.http, this.rootUrl, params, context);
  }

  /**
   * starts the download stream
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicTmpDocumentByFilenameHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicTmpDocumentByFilenameHash(params: GetPublicTmpDocumentByFilenameHash$Params, context?: HttpContext): Observable<Blob> {
    return this.getPublicTmpDocumentByFilenameHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `getManualInstructionDocuments()` */
  static readonly GetManualInstructionDocumentsPath = '/public/documents/manuals';

  /**
   * get the manual instructions documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManualInstructionDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualInstructionDocuments$Response(params: GetManualInstructionDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Document>>> {
    return getManualInstructionDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * get the manual instructions documents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getManualInstructionDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualInstructionDocuments(params: GetManualInstructionDocuments$Params, context?: HttpContext): Observable<Array<Document>> {
    return this.getManualInstructionDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Document>>): Array<Document> => r.body)
    );
  }

  /** Path part for operation `getFaqDocuments()` */
  static readonly GetFaqDocumentsPath = '/public/documents/faqs';

  /**
   * get the FAQ-documents (the lastest version only)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFaqDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqDocuments$Response(params: GetFaqDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Document>>> {
    return getFaqDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * get the FAQ-documents (the lastest version only)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFaqDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqDocuments(params: GetFaqDocuments$Params, context?: HttpContext): Observable<Array<Document>> {
    return this.getFaqDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Document>>): Array<Document> => r.body)
    );
  }

  /** Path part for operation `getDsgvoDocuments()` */
  static readonly GetDsgvoDocumentsPath = '/public/documents/dsgvos';

  /**
   * get the DSGVO-documents (latest version only)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDsgvoDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDsgvoDocuments$Response(params: GetDsgvoDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Document>>> {
    return getDsgvoDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * get the DSGVO-documents (latest version only)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDsgvoDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDsgvoDocuments(params: GetDsgvoDocuments$Params, context?: HttpContext): Observable<Array<Document>> {
    return this.getDsgvoDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Document>>): Array<Document> => r.body)
    );
  }

  /** Path part for operation `getAgbDocuments()` */
  static readonly GetAgbDocumentsPath = '/public/documents/agbs';

  /**
   * get the AGB-documents (latest version only)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgbDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgbDocuments$Response(params: GetAgbDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Document>>> {
    return getAgbDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * get the AGB-documents (latest version only)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgbDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgbDocuments(params: GetAgbDocuments$Params, context?: HttpContext): Observable<Array<Document>> {
    return this.getAgbDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Document>>): Array<Document> => r.body)
    );
  }

}
