/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getDocuments } from '../fn/d-maut-controller/get-documents';
import { GetDocuments$Params } from '../fn/d-maut-controller/get-documents';
import { getFilterOptions } from '../fn/d-maut-controller/get-filter-options';
import { GetFilterOptions$Params } from '../fn/d-maut-controller/get-filter-options';
import { getGroupedTransactions } from '../fn/d-maut-controller/get-grouped-transactions';
import { GetGroupedTransactions$Params } from '../fn/d-maut-controller/get-grouped-transactions';
import { getTransactions } from '../fn/d-maut-controller/get-transactions';
import { GetTransactions$Params } from '../fn/d-maut-controller/get-transactions';
import { ResponseSvgDocument } from '../models/response-svg-document';
import { ResponseTollTransaction } from '../models/response-toll-transaction';

@Injectable({ providedIn: 'root' })
export class DMautControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTransactions()` */
  static readonly GetTransactionsPath = '/transactions';

  /**
   * View a list of transactions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactions$Response(params: GetTransactions$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseTollTransaction>> {
    return getTransactions(this.http, this.rootUrl, params, context);
  }

  /**
   * View a list of transactions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactions(params: GetTransactions$Params, context?: HttpContext): Observable<ResponseTollTransaction> {
    return this.getTransactions$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseTollTransaction>): ResponseTollTransaction => r.body)
    );
  }

  /** Path part for operation `getGroupedTransactions()` */
  static readonly GetGroupedTransactionsPath = '/transactions/grouped';

  /**
   * View a list of all transactions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroupedTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupedTransactions$Response(params: GetGroupedTransactions$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseTollTransaction>> {
    return getGroupedTransactions(this.http, this.rootUrl, params, context);
  }

  /**
   * View a list of all transactions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGroupedTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupedTransactions(params: GetGroupedTransactions$Params, context?: HttpContext): Observable<ResponseTollTransaction> {
    return this.getGroupedTransactions$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseTollTransaction>): ResponseTollTransaction => r.body)
    );
  }

  /** Path part for operation `getFilterOptions()` */
  static readonly GetFilterOptionsPath = '/filter/{model}/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterOptions$Response(params: GetFilterOptions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getFilterOptions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFilterOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterOptions(params: GetFilterOptions$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getFilterOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getDocuments()` */
  static readonly GetDocumentsPath = '/documents';

  /**
   * a list of documents for the customers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments$Response(params: GetDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSvgDocument>> {
    return getDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * a list of documents for the customers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments(params: GetDocuments$Params, context?: HttpContext): Observable<ResponseSvgDocument> {
    return this.getDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSvgDocument>): ResponseSvgDocument => r.body)
    );
  }

}
