import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '@mysvg/utils';

import { FileTypeInfoService } from '../../utils/file-type-info/file-type-info.service';

@Pipe({
	name: 'fileType',
})
export class FileTypePipe implements PipeTransform {
	constructor(private fileTypeInfoService: FileTypeInfoService) {}

	transform(fileType: string): string {
		if (isDefined(fileType) && typeof fileType === 'string') {
			return this.transformToFileTypeString(fileType);
		} else {
			return '';
		}
	}

	private transformToFileTypeString(fileType: string): string {
		const fileKey = this.fileTypeInfoService.getByType(fileType);

		if (isDefined(fileKey)) {
			return fileKey.toUpperCase();
		} else {
			return this.parseTypeString(fileType).toUpperCase();
		}
	}

	private parseTypeString(fileType: string): string {
		try {
			return fileType.split('/')[1];
		} catch (e) {
			return fileType;
		}
	}
}
