/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { IncidentDto } from '../../models/incident-dto';
import { RejectionDto } from '../../models/rejection-dto';

export interface RejectSuggestion$Params {
      body: IncidentDto
}

export function rejectSuggestion(http: HttpClient, rootUrl: string, params: RejectSuggestion$Params, context?: HttpContext): Observable<StrictHttpResponse<RejectionDto>> {
  const rb = new RequestBuilder(rootUrl, rejectSuggestion.PATH, 'patch');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<RejectionDto>;
    })
  );
}

rejectSuggestion.PATH = '/suggestions/reject';
