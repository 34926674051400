/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createMailAddress } from '../fn/mail-address-controller/create-mail-address';
import { CreateMailAddress$Params } from '../fn/mail-address-controller/create-mail-address';
import { deleteMailAddressById } from '../fn/mail-address-controller/delete-mail-address-by-id';
import { DeleteMailAddressById$Params } from '../fn/mail-address-controller/delete-mail-address-by-id';
import { getMailAddressesByEntity } from '../fn/mail-address-controller/get-mail-addresses-by-entity';
import { GetMailAddressesByEntity$Params } from '../fn/mail-address-controller/get-mail-addresses-by-entity';
import { MailAddressDto } from '../models/mail-address-dto';
import { updateMailAddress } from '../fn/mail-address-controller/update-mail-address';
import { UpdateMailAddress$Params } from '../fn/mail-address-controller/update-mail-address';

@Injectable({ providedIn: 'root' })
export class MailAddressControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateMailAddress()` */
  static readonly UpdateMailAddressPath = '/mailaddresses/{mailAddressId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMailAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMailAddress$Response(params: UpdateMailAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<MailAddressDto>> {
    return updateMailAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMailAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMailAddress(params: UpdateMailAddress$Params, context?: HttpContext): Observable<MailAddressDto> {
    return this.updateMailAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<MailAddressDto>): MailAddressDto => r.body)
    );
  }

  /** Path part for operation `deleteMailAddressById()` */
  static readonly DeleteMailAddressByIdPath = '/mailaddresses/{mailAddressId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMailAddressById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMailAddressById$Response(params: DeleteMailAddressById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteMailAddressById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMailAddressById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMailAddressById(params: DeleteMailAddressById$Params, context?: HttpContext): Observable<void> {
    return this.deleteMailAddressById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createMailAddress()` */
  static readonly CreateMailAddressPath = '/mailaddresses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMailAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMailAddress$Response(params: CreateMailAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<MailAddressDto>> {
    return createMailAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMailAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMailAddress(params: CreateMailAddress$Params, context?: HttpContext): Observable<MailAddressDto> {
    return this.createMailAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<MailAddressDto>): MailAddressDto => r.body)
    );
  }

  /** Path part for operation `getMailAddressesByEntity()` */
  static readonly GetMailAddressesByEntityPath = '/mailaddresses/{entityType}/{entityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMailAddressesByEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMailAddressesByEntity$Response(params: GetMailAddressesByEntity$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MailAddressDto>>> {
    return getMailAddressesByEntity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMailAddressesByEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMailAddressesByEntity(params: GetMailAddressesByEntity$Params, context?: HttpContext): Observable<Array<MailAddressDto>> {
    return this.getMailAddressesByEntity$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MailAddressDto>>): Array<MailAddressDto> => r.body)
    );
  }

}
