/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseCustomer } from '../../models/response-customer';

export interface GetCustomers$Params {

/**
 * filter for address countryCode
 */
  'address.countryCode'?: string;

/**
 * filter for address city
 */
  'address.city'?: string;

/**
 * filter for address zip
 */
  'address.zip'?: string;

/**
 * filter for address street
 */
  'address.street'?: string;

/**
 * filter for company name
 */
  companyName?: string;

/**
 * filter for the customers number
 */
  customerNumber?: string;

/**
 * filter for the customers svg id
 */
  customerSvgId?: string;

/**
 * filter for the customers svg number
 */
  customerSvgNumber?: string;

/**
 * filter for the customer access
 */
  customerAccess?: string;

/**
 * filter for the default mail
 */
  defaultMail?: string;

/**
 * filter for lock
 */
  lock?: 'LOCKED' | 'ORDERED';

/**
 * filter for lock date
 */
  'lock.toLockAt'?: string;

/**
 * filter for business sectors
 */
  providers?: Array<string>;

/**
 * filter for svg id
 */
  svgId?: string;

/**
 * filter for vat number
 */
  vatNumber?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getCustomers(http: HttpClient, rootUrl: string, params?: GetCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseCustomer>> {
  const rb = new RequestBuilder(rootUrl, getCustomers.PATH, 'get');
  if (params) {
    rb.query('address.countryCode', params['address.countryCode'], {});
    rb.query('address.city', params['address.city'], {});
    rb.query('address.zip', params['address.zip'], {});
    rb.query('address.street', params['address.street'], {});
    rb.query('companyName', params.companyName, {});
    rb.query('customerNumber', params.customerNumber, {});
    rb.query('customerSvgId', params.customerSvgId, {});
    rb.query('customerSvgNumber', params.customerSvgNumber, {});
    rb.query('customerAccess', params.customerAccess, {});
    rb.query('defaultMail', params.defaultMail, {});
    rb.query('lock', params.lock, {});
    rb.query('lock.toLockAt', params['lock.toLockAt'], {});
    rb.query('providers', params.providers, {});
    rb.query('svgId', params.svgId, {});
    rb.query('vatNumber', params.vatNumber, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseCustomer>;
    })
  );
}

getCustomers.PATH = '/customers';
