/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseDocument } from '../../models/response-document';

export interface GetEntityDocuments$Params {

/**
 * type of the entity
 */
  entityType: 'customers' | 'svgs' | 'vehicles';

/**
 * the id of the entity
 */
  id: string;

/**
 * the portalUid of the customer
 */
  customers: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getEntityDocuments(http: HttpClient, rootUrl: string, params: GetEntityDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocument>> {
  const rb = new RequestBuilder(rootUrl, getEntityDocuments.PATH, 'get');
  if (params) {
    rb.path('entityType', params.entityType, {});
    rb.path('id', params.id, {});
    rb.query('customers', params.customers, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseDocument>;
    })
  );
}

getEntityDocuments.PATH = '/{entityType}/{id}/documents';
