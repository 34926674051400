<svg-frontends-dialog-container
	(closeDialog)="closeDialog()"
	[closable]="true"
	[size]="inputArgs.size || DIALOG_SIZE.LARGE"
	[title]="inputArgs.title"
>
	<svg-frontends-dialog-body-container>
		<p *ngIf="!!inputArgs.infoText">{{ inputArgs.infoText }}</p>

		<svg-frontends-form-group [formGroup]="formGroup">
			<svg-frontends-select-form-control
				[bindLabel]="inputArgs.bindLabel"
				[controlName]="reasonControlName"
				[label]="inputArgs.reasonLabel"
				[multiple]="inputArgs.allowMultiple"
				[notFoundText]="'Keinen Grund gefunden'"
				[placeholder]="'Bitte wählen Sie einen Grund'"
				[possibleValues]="inputArgs.data$ | async"
				svgFrontendsAppendToCdkOverlayPane
			></svg-frontends-select-form-control>
			<svg-frontends-date-picker-form-control
				*ngIf="inputArgs.showDate"
				[controlName]="dateControlName"
				[label]="inputArgs.dateLabel || 'Datum'"
				[min]="today"
			></svg-frontends-date-picker-form-control>
			<svg-frontends-text-area-form-control
				*ngIf="inputArgs.showAdditionalComment"
				[controlName]="additionalCommentControlName"
				[label]="'Anmerkungen'"
			></svg-frontends-text-area-form-control>
		</svg-frontends-form-group>
	</svg-frontends-dialog-body-container>

	<svg-frontends-dialog-footer-container>
		<button (click)="closeDialog()" class="btn btn-primary-outline" type="button">{{ inputArgs.cancelBtnTxt }}</button>
		<button (click)="confirmDialog()" [disabled]="formGroup.invalid" class="btn btn-primary" type="button">
			{{ inputArgs.confirmBtnTxt }}
		</button>
	</svg-frontends-dialog-footer-container>
</svg-frontends-dialog-container>
