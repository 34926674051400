import { Observable } from 'rxjs';

export interface FileUploaderMetadataDefinition {
	type: FileUploaderMetadataType;
	modelName: string;
	placeholder: string;
	isRequired: boolean;

	// those settings are marked optional because the file uploader can handle it and it makes code more readable
	// in most cases default value = null and ignoreOnUpload = false, no need to always define those in field definitions
	defaultValue?: any;
	ignoreOnUpload?: boolean;

	// from here properties will only be used if type == CHOICE, therefore optional
	choices?: Observable<any[]>;
	bindChoiceLabel?: string;
	bindChoiceValue?: string;
	onChoiceChange?: any;
}

export enum FileUploaderMetadataType {
	TEXT,
	MULTILINE,
	CHOICE,
	HIDDEN,
	READONLY,
}
