import { Injectable } from '@angular/core';
import { CustomerTypeControllerService, CustomerTypeDto, ResponseCustomerTypeDto } from '@mysvg/api/payment';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SvgCustomerTypesStoreService extends RepositoryStoreService<CustomerTypeDto[]> {
	constructor(private customerTypeControllerService: CustomerTypeControllerService) {
		super();
	}

	fetchData(): Observable<CustomerTypeDto[]> {
		return this.customerTypeControllerService.getAllCustomerTypes().pipe(map((response: ResponseCustomerTypeDto) => response.data));
	}
}
