import { Injectable } from '@angular/core';
import { CrefoMonitoringControllerService, CrefoMonitoringStatusDto } from '@mysvg/api/financial-guarantees';
import { SvgContextService } from '@svg-frontends/session';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { RepositoryStoreService } from '../repository-store.service';

// Since we dont want to implement pagination and  only about 5 entries are realistic, we set this by default to 100 to be safe
const PAGE_SIZE = 100;

@Injectable({ providedIn: 'root' })
export class CrefoActiveMonitoringStoreService extends RepositoryStoreService<CrefoMonitoringStatusDto> {
	constructor(private crefoMonitoringControllerService: CrefoMonitoringControllerService, private svgContextService: SvgContextService) {
		super();
	}

	fetchData(): Observable<CrefoMonitoringStatusDto> {
		return this.svgContextService
			.getFirstPortalUid()
			.pipe(mergeMap((portalUid: string) => this.crefoMonitoringControllerService.getMonitoringStatus({ portalUid, pageSize: PAGE_SIZE })));
	}
}
