import { Injectable } from '@angular/core';
import { IncidentControllerService, IncidentReasonDto } from '@mysvg/api/financial-guarantees';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IncidentReasonsStoreService extends RepositoryStoreService<IncidentReasonDto[]> {
	constructor(private incidentControllerService: IncidentControllerService) {
		super();
	}

	fetchData(): Observable<IncidentReasonDto[]> {
		return this.incidentControllerService.getIncidentReasons();
	}
}
