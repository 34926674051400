/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAccessibleSvgs } from '../fn/svg-controller/get-accessible-svgs';
import { GetAccessibleSvgs$Params } from '../fn/svg-controller/get-accessible-svgs';
import { getAllSvgs } from '../fn/svg-controller/get-all-svgs';
import { GetAllSvgs$Params } from '../fn/svg-controller/get-all-svgs';
import { getSvgById } from '../fn/svg-controller/get-svg-by-id';
import { GetSvgById$Params } from '../fn/svg-controller/get-svg-by-id';
import { Svg } from '../models/svg';

@Injectable({ providedIn: 'root' })
export class SvgControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllSvgs()` */
  static readonly GetAllSvgsPath = '/svgs';

  /**
   * getting all SVGs. Returning all available SVGs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSvgs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSvgs$Response(params?: GetAllSvgs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Svg>>> {
    return getAllSvgs(this.http, this.rootUrl, params, context);
  }

  /**
   * getting all SVGs. Returning all available SVGs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSvgs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSvgs(params?: GetAllSvgs$Params, context?: HttpContext): Observable<Array<Svg>> {
    return this.getAllSvgs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Svg>>): Array<Svg> => r.body)
    );
  }

  /** Path part for operation `getSvgById()` */
  static readonly GetSvgByIdPath = '/svgs/{id}';

  /**
   * getting the SVG identified by its number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSvgById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgById$Response(params: GetSvgById$Params, context?: HttpContext): Observable<StrictHttpResponse<Svg>> {
    return getSvgById(this.http, this.rootUrl, params, context);
  }

  /**
   * getting the SVG identified by its number
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSvgById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgById(params: GetSvgById$Params, context?: HttpContext): Observable<Svg> {
    return this.getSvgById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Svg>): Svg => r.body)
    );
  }

  /** Path part for operation `getAccessibleSvgs()` */
  static readonly GetAccessibleSvgsPath = '/svgs/accessible';

  /**
   * get a list of all svgs accessible for the requested activity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessibleSvgs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessibleSvgs$Response(params: GetAccessibleSvgs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Svg>>> {
    return getAccessibleSvgs(this.http, this.rootUrl, params, context);
  }

  /**
   * get a list of all svgs accessible for the requested activity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccessibleSvgs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessibleSvgs(params: GetAccessibleSvgs$Params, context?: HttpContext): Observable<Array<Svg>> {
    return this.getAccessibleSvgs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Svg>>): Array<Svg> => r.body)
    );
  }

}
