/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseDocumentMetadataDto } from '../../models/response-document-metadata-dto';

export interface GetDocuments$Params {

/**
 * search for customer id
 */
  customerId?: number;

/**
 * search for the given name of a document
 */
  name?: string;

/**
 * search for the document type
 */
  documentTypeIds?: Array<number>;

/**
 * search for any number of field of care ids
 */
  fieldOfCareIds?: Array<number>;

/**
 * search for an author of the documents
 */
  author?: string;

/**
 * range filter for the upload date [from/to]
 */
  uploadDateFromTo?: string;

/**
 * search for a specific version number
 */
  version?: number;

/**
 * search for documents that are released to mysvg portal [true/false]
 */
  isPortalReleased?: boolean;

/**
 * search for service provider number
 */
  serviceProviderId?: number;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getDocuments(http: HttpClient, rootUrl: string, params?: GetDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocumentMetadataDto>> {
  const rb = new RequestBuilder(rootUrl, getDocuments.PATH, 'get');
  if (params) {
    rb.query('customerId', params.customerId, {});
    rb.query('name', params.name, {});
    rb.query('documentTypeIds', params.documentTypeIds, {});
    rb.query('fieldOfCareIds', params.fieldOfCareIds, {});
    rb.query('author', params.author, {});
    rb.query('uploadDateFromTo', params.uploadDateFromTo, {});
    rb.query('version', params.version, {});
    rb.query('isPortalReleased', params.isPortalReleased, {});
    rb.query('serviceProviderId', params.serviceProviderId, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseDocumentMetadataDto>;
    })
  );
}

getDocuments.PATH = '/documents';
