/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BookingUuidList } from '../../models/booking-uuid-list';
import { ResponseVignette } from '../../models/response-vignette';

export interface GetBookingResponse$Params {

/**
 * the portalUid of the customer
 */
  customers: string;
      body: BookingUuidList
}

export function getBookingResponse(http: HttpClient, rootUrl: string, params: GetBookingResponse$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseVignette>> {
  const rb = new RequestBuilder(rootUrl, getBookingResponse.PATH, 'post');
  if (params) {
    rb.query('customers', params.customers, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseVignette>;
    })
  );
}

getBookingResponse.PATH = '/booking/status';
