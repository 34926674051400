import { Injectable } from '@angular/core';
import { CountryInvoiceCertificateControllerService, CountryInvoiceCertificateDirectory } from '@mysvg/api/document';
import { SvgContextService } from '@svg-frontends/session';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class CountryInvoiceCertificateDirectoryStoreService extends RepositoryStoreService<CountryInvoiceCertificateDirectory[]> {
	constructor(
		private svgContextService: SvgContextService,
		private countryInvoiceCertificateControllerService: CountryInvoiceCertificateControllerService,
	) {
		super();
	}

	fetchData(): Observable<CountryInvoiceCertificateDirectory[]> {
		return this.svgContextService
			.getFirstPortalUid()
			.pipe(
				mergeMap((portalUid) =>
					this.countryInvoiceCertificateControllerService.getCountryInvoiceCertificateDirectoriesForCustomer({ puid: portalUid }),
				),
			);
	}
}
