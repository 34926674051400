import { Injectable, NgModule } from '@angular/core';
import {
	OWL_DATE_TIME_FORMATS,
	OWL_DATE_TIME_LOCALE,
	OwlDateTimeFormats,
	OwlDateTimeIntl,
	OwlDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { OwlDayJsDateTimeModule } from '@danielmoncada/angular-datetime-picker-dayjs-adapter';

// learn more about this formatting from
// https://day.js.org/docs/en/display/format#list-of-localized-formats
export const MY_DAYJS_FORMATS: OwlDateTimeFormats = {
	dateA11yLabel: 'LL',
	datePickerInput: 'L',
	fullPickerInput: 'L LT',
	monthYearA11yLabel: 'MMMM YYYY',
	monthYearLabel: 'MMM YYYY',
	parseInput: 'L',
	timePickerInput: 'LT',
};

@Injectable()
class CustomIntl extends OwlDateTimeIntl {
	override cancelBtnLabel = 'Abbrechen';
	override rangeFromLabel = 'Von';
	override rangeToLabel = 'Bis';
	override setBtnLabel = 'Auswählen';
}

@NgModule({
	exports: [OwlDateTimeModule, OwlDayJsDateTimeModule],
	imports: [OwlDateTimeModule, OwlDayJsDateTimeModule],
	providers: [
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_DAYJS_FORMATS },
		{ provide: OWL_DATE_TIME_LOCALE, useValue: 'de-DE' },
		{ provide: OwlDateTimeIntl, useClass: CustomIntl },
	],
})
export class OwlWrapperModule {}
