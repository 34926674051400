import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AbstractControlComponent } from '../../classes/AbstractControlComponent';
import { FormStatus } from '../../enums/form-status.enum';
import { FormGroupService } from '../../services/form-group.service';

@UntilDestroy()
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-text-form-control',
	styleUrls: ['./text-form-control.component.scss'],
	templateUrl: './text-form-control.component.html',
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class TextFormControlComponent extends AbstractControlComponent implements OnInit {
	FORM_STATUS = FormStatus;

	formControl: FormControl;

	@Input() controlName: string;
	@Input() disableControl: boolean = undefined;
	@Input() helperText?: string | TemplateRef<any>;
	@Input() label?: string | TemplateRef<any>;
	@Input() placeholder = '';
	@Input() type = 'text';

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private formGroupDirective: ControlContainer,
		private macFormGroupService: FormGroupService,
	) {
		super();
	}

	ngOnInit(): void {
		this.formControl = this.formGroupDirective.control.get(this.controlName) as FormControl;

		this.macFormGroupService.submit$.pipe(untilDestroyed(this)).subscribe(() => {
			FormGroupService.touchControl(this.formControl);
			this.changeDetectorRef.markForCheck();
		});
	}
}
