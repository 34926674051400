import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export enum UploadProcessStepState {
	Done,
	Error,
	NotStarted,
	Processing,
}

export class UploadProcessStep {
	message: string;
	state: UploadProcessStepState;
	displayRetryButton: boolean;

	private httpCall$: Observable<any>;

	constructor(msg: string, displayRetryButton = true) {
		this.message = msg;
		this.state = UploadProcessStepState.NotStarted;
		this.displayRetryButton = displayRetryButton;
	}

	retry(): Observable<any> {
		return this.start(this.httpCall$);
	}

	start<T>(httpCall$: Observable<T>): Observable<T> {
		this.state = UploadProcessStepState.Processing;
		this.httpCall$ = httpCall$;

		return httpCall$.pipe(
			map((res: T) => {
				this.state = UploadProcessStepState.Done;
				return res;
			}),
			catchError(() => {
				this.state = UploadProcessStepState.Error;
				return of(null);
			}),
		);
	}
}
