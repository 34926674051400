import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '../../utils/general.utils';

@Pipe({
	name: 'isNotDefinedHandling',
})
export class IsNotDefinedHandlingPipe implements PipeTransform {
	transform(value: any): any {
		if (isDefined(value)) {
			return value;
		} else {
			return '-';
		}
	}
}
