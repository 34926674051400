import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { isDefined, UtilsService } from '@mysvg/utils';
import { ErrorHandlingService } from '@svg-frontends/error';

@Injectable({ providedIn: 'root' })
export class StorageService {
	private isAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

	constructor(private errorHandlingService: ErrorHandlingService) {}

	/**
	 * Determines if a key exists in either session or localStorage
	 */
	has(key: string): boolean {
		return Object.prototype.hasOwnProperty.call(sessionStorage, key) || Object.prototype.hasOwnProperty.call(localStorage, key);
	}

	get<T = string>(key: string): T {
		const value = this.getFromStorage(key);
		return this.tryParseJSON(value) as T; // returned parsed json or string
	}

	/**
	 * @param key Key for which object should be saved
	 * @param urlDecode Flag to URLdecode whole object. Needed to support special characters saving.
	 * See https://stackoverflow.com/a/63387912/7692491 for solution and for otherwise supported chars https://kb.iu.edu/d/aepu
	 * @returns Saved oject
	 */
	getDecoded<T = string>(key: string, urlDecode = false): T {
		let value = this.getFromStorage(key);
		if (isDefined(value)) {
			value = UtilsService.decode(value); // eventually decrypt
			if (urlDecode) {
				value = decodeURIComponent(value);
			}
		}
		return this.tryParseJSON(value) as T; // returned parsed json or string
	}

	set(key: string, object: unknown, persist: boolean = false): void {
		const string = UtilsService.stringify(object);
		const storage: Storage = persist ? localStorage : sessionStorage;

		try {
			storage.setItem(key, string);
		} catch (e) {
			const notSafed = { key, value: object };
			this.handleStorageError(e, notSafed, storage, persist);
		}
	}

	/**
	 *
	 * @param key Key to it with
	 * @param object Object to save
	 * @param persist If setting should persist in localstorage or should be bound to session(-storage)
	 * @param urlEncode Flag to URLencode whole object. Needed to support special characters saving.
	 * See https://stackoverflow.com/a/63387912/7692491 for solution and for otherwise supported chars https://kb.iu.edu/d/aepu.
	 * If this flag is set also use it for getDecoded.
	 */
	setEncoded(key: string, object: unknown, persist: boolean = false, urlEncode = false): void {
		let value = UtilsService.stringify(object);
		if (urlEncode) {
			value = encodeURIComponent(value);
		}
		value = UtilsService.encode(value);
		this.set(key, value, persist);
	}

	tryParseJSON(json: string): unknown {
		try {
			const o = JSON.parse(json);
			if (o && typeof o === 'object') {
				return o;
			}
		} catch (e) {
			return json;
		}
		return json;
	}

	clear(): void {
		sessionStorage.clear();
		localStorage.clear();
	}

	clearSession(): void {
		sessionStorage.clear();
	}

	unsetSession(key: string): void {
		if (this.has(key)) {
			try {
				sessionStorage.removeItem(key);
			} catch (ex) {
				this.errorHandlingService.setNextErrorBy(ex as Error);
			}
		}
	}

	unsetPersistent(key: string): void {
		if (this.has(key)) {
			try {
				localStorage.removeItem(key);
			} catch (ex) {
				this.errorHandlingService.setNextErrorBy(ex as Error);
			}
		}
	}

	handleStorageError(error: any, notSafed: any, storage: Storage, persist: boolean): void {
		if (!this.storagesAvailable()) {
			this.errorHandlingService.setNextErrorBy(error);
		} else {
			this.checkExceedingStorage(error, notSafed, storage, persist);
		}
	}

	checkExceedingStorage(error: any, notSaved: any, storage: Storage, persist: boolean): void {
		if (this.isQuotaException(error)) {
			storage.clear();
			this.set(notSaved.key, notSaved.value, persist);
		} else {
			this.errorHandlingService.setNextErrorBy(error);
		}
	}

	/**
	 * this function is proposed by develop.mozilla.org to check availability of storages
	 * true if save and delete works or exception is QuotaExceededException but there is data in storage
	 * false if save and delete doesn't work or QuotaException but not data in storage or some other Exception
	 */
	storageAvailable(type: string): boolean {
		const storage = window[type];
		const testKey = '__storage_test__';

		try {
			storage.setItem(testKey, testKey);
			storage.removeItem(testKey);
		} catch (e) {
			return this.isQuotaException(e) && storage.length !== 0;
		}
		return true;
	}

	isQuotaException(e: any): boolean {
		return (
			e instanceof DOMException &&
			(e.code === 22 || e.code === 1014 || e.name === 'QuotaExceededError' || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
		);
	}

	/**
	 * check if local and session storage are available
	 */
	storagesAvailable(): boolean {
		if (this.isAvailable$.getValue() === undefined) {
			this.isAvailable$.next(this.storageAvailable('localStorage') && this.storageAvailable('sessionStorage'));
		}

		return this.isAvailable$.getValue();
	}

	private getFromStorage(key: string): string | null | undefined {
		return sessionStorage.getItem(key) || localStorage.getItem(key) || undefined;
	}
}
