/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getPermissions } from '../fn/permissions-controller/get-permissions';
import { GetPermissions$Params } from '../fn/permissions-controller/get-permissions';
import { ResponseBusinessAreaPermission } from '../models/response-business-area-permission';

@Injectable({ providedIn: 'root' })
export class PermissionsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPermissions()` */
  static readonly GetPermissionsPath = '/permissions';

  /**
   * getting all permissions. Returning all available permissions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissions$Response(params?: GetPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBusinessAreaPermission>> {
    return getPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * getting all permissions. Returning all available permissions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissions(params?: GetPermissions$Params, context?: HttpContext): Observable<ResponseBusinessAreaPermission> {
    return this.getPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseBusinessAreaPermission>): ResponseBusinessAreaPermission => r.body)
    );
  }

}
