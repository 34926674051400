/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CommunicationTypeDto } from '../models/communication-type-dto';
import { getAllCommunicationTypes } from '../fn/communication-type-controller/get-all-communication-types';
import { GetAllCommunicationTypes$Params } from '../fn/communication-type-controller/get-all-communication-types';

@Injectable({ providedIn: 'root' })
export class CommunicationTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllCommunicationTypes()` */
  static readonly GetAllCommunicationTypesPath = '/communications/types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCommunicationTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCommunicationTypes$Response(params?: GetAllCommunicationTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CommunicationTypeDto>>> {
    return getAllCommunicationTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllCommunicationTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCommunicationTypes(params?: GetAllCommunicationTypes$Params, context?: HttpContext): Observable<Array<CommunicationTypeDto>> {
    return this.getAllCommunicationTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CommunicationTypeDto>>): Array<CommunicationTypeDto> => r.body)
    );
  }

}
