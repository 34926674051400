export enum SafetyProductType {
	UNCATEGORIZED_PRODUCT_TYPE = 'PRTY-0',
	COMMERCIAL_REPORT_PRODUCT_TYPE = 'PRTY-2',
	COMPACT_REPORT_PRODUCT_TYPE = 'PRTY-3',
	TRAFFIC_LIGHT_REPORT_PRODUCT_TYPE = 'PRTY-4',
	BRIEF_REPORT_PRODUCT_TYPE = 'PRTY-5',
	SIGNAL_SOLVENCY_PRODUCT_TYPE = 'PRTY-1101',
	RV_KREDITMITTEILUNG = 'RV-K',
	RV_BENACHRICHTIGUNG = 'RV-B',
}
