import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

import { BACKEND_DATE_FORMAT } from '../../utils/date.utils';

@Pipe({ name: 'dateInPast' })
export class DateInPastPipe implements PipeTransform {
	transform(date: string): boolean {
		return dayjs(date, BACKEND_DATE_FORMAT).isBefore(dayjs().startOf('date'));
	}
}
