import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { isDefined } from '@mysvg/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge } from 'rxjs';

@UntilDestroy()
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-form-group',
	styleUrls: ['./form-group.component.scss'],
	templateUrl: './form-group.component.html',
	viewProviders: [{ provide: NgForm, useExisting: FormGroupDirective }],
})
export class FormGroupComponent implements OnInit {
	@Input() disabled: boolean = undefined;
	@Input() formGroup: FormGroup;

	constructor(private changeDetectorRef: ChangeDetectorRef, private ngForm: NgForm) {}

	ngOnInit(): void {
		// Changes the default value for `updateOn` to `blur`, unless specified otherwise.
		if (!isDefined(this.ngForm.options) || !isDefined(this.ngForm.options.updateOn)) {
			this.ngForm.options = { updateOn: 'blur' };
		}

		merge(this.formGroup.statusChanges, this.formGroup.valueChanges)
			.pipe(untilDestroyed(this))
			.subscribe(() => this.changeDetectorRef.markForCheck());
	}
}
