import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { WithId } from '@mysvg/utils';
import { ConfirmDialogData, DialogLevel, StandardDialogService } from '@svg-frontends/dialog';
import { SvgErrorMessageFactoryService } from '@svg-frontends/error';

const DELETE_DIALOG: ConfirmDialogData = {
	cancelButton: 'Abbrechen',
	submitButton: 'Löschen',
	body: '',
	level: DialogLevel.DANGER,
	title: 'Wirklich Löschen?',
};

@Injectable({ providedIn: 'root' })
export class TableViewCrudService<T extends WithId> {
	constructor(private errorMessageFactoryService: SvgErrorMessageFactoryService, private standardDialogService: StandardDialogService) {}

	deleteAll(items: T | T[], requestCall: (item: T) => Observable<void>, typeOfT: string): Observable<void> {
		const temporarySelected: T[] = items instanceof Array ? items : [items];
		const dialog: ConfirmDialogData = { ...DELETE_DIALOG, body: this.getDeletionAlertMessage(temporarySelected, typeOfT) };

		return this.standardDialogService.showConfirmationDialog(dialog).pipe(
			filter((hasConfirmed: boolean) => hasConfirmed),
			mergeMap(() => this.delete(temporarySelected, requestCall)),
			map(() => void 0),
		);
	}

	private getDeletionAlertMessage(items: T[], typeOfT: string): string {
		const count = items.length;

		if (count > 1) {
			return this.errorMessageFactoryService.getEnrichedMessage('deleteMultiple' + typeOfT, count);
		} else {
			return this.errorMessageFactoryService.getEnrichedMessage('delete' + typeOfT, items[0]);
		}
	}

	private delete(temporarySelected: T[], requestCall: (item: T) => Observable<void>): Observable<void[]> {
		return forkJoin(temporarySelected.map((item: T) => requestCall(item)));
	}
}
