import { Injectable } from '@angular/core';
import { Logger } from '@nsalaun/ng-logger';

import { isDefined } from '@mysvg/utils';
import { GtagConfigParams } from '../models/gtag-config-params.model';
import { GtagCustomDimensions } from '../models/gtag-custom-dimensions.model';

import { GtagEventParams } from '../models/gtag-event-params.model';
import { GtagPurchaseItem } from '../models/gtag-purchase-items.model';

// declare and type ga function
declare let gtag: (method: string, actionOrGaId: string, param?: any) => void; // Declare ga as a function

enum GTAG_CONSTS {
	CONFIG = 'config',
	EVENT = 'event',
	PURCHASE = 'purchase',
	PAGE = 'page',
}

@Injectable({
	providedIn: 'root',
})
export class GtagService {
	private affiliationConst: string;
	private currencyConst: string;
	private googleAnalyticsMeasurementId: string;
	private custom_map: object;

	constructor(private logger: Logger) {}

	init(googleAnalyticsMeasurementId: string, affiliationConst: string, currencyConst: string, custom_map: object): void {
		this.affiliationConst = affiliationConst;
		this.currencyConst = currencyConst;
		this.googleAnalyticsMeasurementId = googleAnalyticsMeasurementId;
		this.custom_map = custom_map;
	}

	event(action: string, params?: GtagEventParams): void {
		this.logger.info('[GtagService] says `event` with: ', params);

		if (isDefined(params)) {
			gtag(GTAG_CONSTS.EVENT, action, params);
		} else {
			gtag(GTAG_CONSTS.EVENT, action);
		}
	}

	pageView(page_path: string, page_title?: string): void {
		this.config({ page_title, page_path });
	}

	purchase(
		value: number,
		items: GtagPurchaseItem[],
		gtagCustomDimensions: GtagCustomDimensions,
		transaction_id: string,
		event_label: string,
	): void {
		const param = {
			event_label,
			transaction_id,
			affiliation: this.affiliationConst,
			value,
			currency: this.currencyConst,
			tax: 0,
			shipping: 0,
			items,
			...gtagCustomDimensions,
		};
		this.logger.info('[GtagService] says `purchase` with: ', param);
		gtag(GTAG_CONSTS.EVENT, GTAG_CONSTS.PURCHASE, param);
	}

	private config(params: GtagConfigParams): void {
		const mergedParams = { ...params, custom_map: this.custom_map };
		gtag(GTAG_CONSTS.CONFIG, this.googleAnalyticsMeasurementId, mergedParams);
	}
}
