import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { FileInputComponent } from './components/file-input/file-input.component';
import { MultiFileHandlerComponent } from './components/multi-file-handler/multi-file-handler.component';
import { AllowedFileTypesPipe } from './pipes/allowed-file-types/allowed-file-types.pipe';
import { FilePreviewUrlPipe } from './pipes/file-preview-url/file-preview-url.pipe';
import { FileSizePipe } from './pipes/file-size/file-size.pipe';
import { FileTypePipe } from './pipes/file-type/file-type.pipe';

const DECLARABLES = [AllowedFileTypesPipe, FileInputComponent, FilePreviewUrlPipe, FileSizePipe, FileTypePipe, MultiFileHandlerComponent];

@NgModule({
	declarations: [...DECLARABLES],
	exports: [...DECLARABLES],
	imports: [FontAwesomeModule, FormsModule],
})
export class FileUploadModule {}
