<div *svgFrontendsLet="formControl.statusChanges | async as status" class="mac-wrapper">
	<svg-frontends-loading *ngIf="status === FORM_STATUS.PENDING" [isSmall]="true" [withBackdrop]="true"></svg-frontends-loading>

	<!--Both checks are needed to also cover the case, that the FormGroup is disabled-->
	<div [ngClass]="{ 'clr-form-control-disabled': disableControl || formControl.disabled }" class="clr-form-control mac-form-control">
		<label *ngIf="label" [for]="id" class="clr-control-label mac-control-label">
			<svg-frontends-string-or-template-ref [value]="label"></svg-frontends-string-or-template-ref>
			<svg-frontends-required-marker *ngIf="formControl | isFormControlRequired"></svg-frontends-required-marker>
		</label>

		<div
			[ngClass]="{ 'clr-error': formControl.invalid && (inputTouched || formControl.touched) }"
			class="clr-control-container XGsvg-frontends-control-wrapper-unlimited-width"
		>
			<div
				*svgFrontendsLet="
					labelTemplate !== undefined ||
					optionTemplate !== undefined ||
					optionGroupTemplate !== undefined ||
					loadingTextTemplate !== undefined ||
					notFoundTemplate !== undefined as useLabels
				"
				class="clr-input-wrapper mac-input-wrapper"
			>
				<!--This is needed because of this https://github.com/ng-select/ng-select/issues/1255-->
				<ng-select
					(blur)="onBlur()"
					(change)="changeSelection.emit($event)"
					*ngIf="!useLabels"
					[appendTo]="appendTo"
					[bindLabel]="bindLabel"
					[bindValue]="bindValue"
					[closeOnSelect]="!multiple"
					[formControl]="formControl"
					[groupBy]="groupBy"
					[id]="id"
					[items]="possibleValues | sortStringProperty: (bindLabel && sortPossibleValues ? [bindLabel] : [])"
					[svgFrontendsDisableControl]="disableControl"
					[multiple]="multiple"
					[notFoundText]="notFoundText"
					[placeholder]="placeholder"
					[searchFn]="searchFn"
					[selectableGroupAsModel]="selectableGroupAsModel"
					[selectableGroup]="selectableGroup"
					class="mac-input pb-0"
				></ng-select>

				<ng-select
					(blur)="onBlur()"
					(change)="changeSelection.emit($event)"
					*ngIf="useLabels"
					[appendTo]="appendTo"
					[bindLabel]="bindLabel"
					[bindValue]="bindValue"
					[closeOnSelect]="!multiple"
					[formControl]="formControl"
					[groupBy]="groupBy"
					[id]="id"
					[items]="possibleValues | sortStringProperty: (bindLabel && sortPossibleValues ? [bindLabel] : [])"
					[svgFrontendsDisableControl]="disableControl"
					[multiple]="multiple"
					[notFoundText]="notFoundText"
					[placeholder]="placeholder"
					[searchFn]="searchFn"
					[selectableGroupAsModel]="selectableGroupAsModel"
					[selectableGroup]="selectableGroup"
					class="mac-input pb-0"
				>
					<ng-template let-clear="clear" let-item="item" ng-label-tmp>
						<ng-template *ngTemplateOutlet="labelTemplate; context: { item: item }"></ng-template>
						<span (click)="clear(item)" aria-hidden="true" class="ng-value-icon right">×</span>
					</ng-template>

					<ng-template let-index="index" let-item="item" let-item$="item$" let-searchTerm="searchTerm" ng-option-tmp>
						<ng-template
							*ngTemplateOutlet="optionTemplate; context: { item: item, item$: item$, index: index, searchTerm: searchTerm }"
						></ng-template>
					</ng-template>

					<ng-template let-index="index" let-item="item" let-item$="item$" let-searchTerm="searchTerm" ng-optgroup-tmp>
						<ng-template
							*ngTemplateOutlet="optionGroupTemplate; context: { item: item, item$: item$, index: index, searchTerm: searchTerm }"
						></ng-template>
					</ng-template>

					<ng-template let-searchTerm="searchTerm" ng-loadingtext-tmp>
						<ng-template *ngTemplateOutlet="loadingTextTemplate; context: { searchTerm: searchTerm }"></ng-template>
					</ng-template>

					<ng-template let-searchTerm="searchTerm" ng-notfound-tmp>
						<ng-template *ngTemplateOutlet="notFoundTemplate; context: { searchTerm: searchTerm }"></ng-template>
					</ng-template>
				</ng-select>
				<cds-icon
					*ngIf="formControl.invalid && (inputTouched || formControl.touched)"
					class="clr-validate-icon mac-validate-icon"
					shape="exclamation-circle"
				></cds-icon>
			</div>

			<div class="clr-subtext-wrapper mac-subtext-wrapper">
				<span *ngIf="helperText && !(formControl.invalid && (inputTouched || formControl.touched))" class="clr-subtext">
					<svg-frontends-string-or-template-ref [value]="helperText"></svg-frontends-string-or-template-ref>
				</span>
				<span *ngIf="formControl.invalid && (inputTouched || formControl.touched)" class="clr-subtext">
					{{ formControl.errors | firstControlError | controlErrorMessage }}
				</span>
			</div>
		</div>
	</div>
</div>
