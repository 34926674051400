import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { CountryInvoiceCertificateControllerService, CountryInvoiceCertificateDto } from '@mysvg/api/document';
import { SvgContextService } from '@svg-frontends/session';

import { ParametrizedRepositoryStoreService } from './parametrized-repository-store.service';

@Injectable({
	providedIn: 'root',
})
export class CountryInvoiceCertificateStoreService extends ParametrizedRepositoryStoreService<CountryInvoiceCertificateDto[], string> {
	constructor(
		private svgContextService: SvgContextService,
		private certificateControllerService: CountryInvoiceCertificateControllerService,
	) {
		super();
	}

	fetchData(selectedCountry: string): Observable<CountryInvoiceCertificateDto[]> {
		return this.svgContextService.getFirstPortalUid().pipe(
			mergeMap((portalUid) =>
				this.certificateControllerService.getAllCountryInvoiceCertificatesForCustomerAndCountry({
					puid: portalUid,
					country: selectedCountry,
				}),
			),
		);
	}

	keyToString(key: string): string {
		return key;
	}
}
