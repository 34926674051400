import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ResponseVehicleCategory, VehicleCategoriesControllerService, VehicleCategory } from '@mysvg/api/vehicle';
import { MysvgBaseUrls } from '@mysvg/utils';
import { SvgContextService } from '@svg-frontends/session';
import { TypeAheadSearchParams, TypeAheadSearchServiceInterface } from '@svg-frontends/type-ahead/core';
import { API_BASE_URLS_TOKEN } from '@svg/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VehicleCategoryRepositoryService implements TypeAheadSearchServiceInterface<VehicleCategory> {
	private portalUid: string;

	constructor(
		@Inject(API_BASE_URLS_TOKEN) private baseApiUrls: MysvgBaseUrls,
		private vehicleCategoriesControllerService: VehicleCategoriesControllerService,
		private svgContextService: SvgContextService,
		private httpClient: HttpClient,
	) {
		this.svgContextService.getFirstPortalUid().subscribe((portalUid: string) => (this.portalUid = portalUid));
	}

	/**
	 * Get first batch/page of vehicle categories
	 * @returns A batch of vehicle categories to start with
	 */
	get(): Observable<VehicleCategory[]> {
		return this.vehicleCategoriesControllerService
			.getVehicleCategories({ customers: this.portalUid })
			.pipe(map((responseVehicle: ResponseVehicleCategory) => responseVehicle.data));
	}

	/**
	 * For loading more items in typeahead select components
	 * @param reference `next` return parameter from backend referencing next url path to retrieve more items
	 * @returns Reponse object for more vehicle categories loaded
	 */
	getByReference(reference: string): Observable<ResponseVehicleCategory> {
		return this.httpClient.get<ResponseVehicleCategory>(`${this.baseApiUrls.vehicle}${reference}`);
	}

	/**
	 * Default interface defined method to search for categories by search parama
	 * @param params Search query, limit and offset parameters
	 * @returns Fitting vehicle categories returned by backend
	 */
	getWithParams(params: TypeAheadSearchParams): Observable<ResponseVehicleCategory> {
		return this.vehicleCategoriesControllerService.getVehicleCategories({
			customers: this.portalUid,
			description: params.search,
			limit: params.limit,
			offset: params.offset,
			// possible additional parameters for search
			// sortBy: sortBy,
			// sortOrder: sortOrder,
		});
	}
}
