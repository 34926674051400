import { Injectable } from '@angular/core';

import { UtilsService } from './utils/utils.service';

const URL_PARAMS_AND = '&';
const URL_PARAMS_KEY_VALUE_EQUALS = '=';

@Injectable({ providedIn: 'root' })
export class UrlParamsService {
	/**
	 * first check with `this.has` if key is present
	 *
	 * [NOTE] always use this.has before getting actual param, to ensure `UrlSearchParams` are available
	 */
	getBy(key: string): string | null {
		return this.has(key) ? this.getFromUrl(key) : null;
	}

	/**
	 * [CAUTION] encode everything which is used in portal - just do not encode stuff for backend calls (like document links)
	 */
	append(relativeOrAbsoluteUrl: string | null, key: string, param: any, doEncode = true, relative = false): string {
		const urlObj = this.toURL(relativeOrAbsoluteUrl);
		const paramString = !doEncode ? param : this.toEncodedString(param);
		urlObj.searchParams.set(key, paramString);

		if (relative) {
			return urlObj.toString().substring(urlObj.origin.length);
		} else {
			return urlObj.toString();
		}
	}

	/**
	 * [CAUTION] not every browser (some earlier edge browser versions) support UrlSearchParams
	 */
	has(key: string): boolean {
		if (!!window.URLSearchParams) {
			const url: URL = new URL(location.href);
			const params: URLSearchParams = new URLSearchParams(url.search);
			return params.has(key);
		} else {
			return false;
		}
	}

	/**
	 * client params should be attached by `#`
	 * [EXAMPLE] `https://mysvg.de#ipd=wedolo`
	 */
	getClientParam(key: string): string | null {
		const url = new URL(location.href);
		const matchingParamPair = url.hash
			.substr(1) // remove `#` at first
			.split(URL_PARAMS_AND) // multiple client params
			.map((paramsString: string) => paramsString.split(URL_PARAMS_KEY_VALUE_EQUALS)) // split key-value-string to tuple
			.find((paramsPair: string[]) => paramsPair[0] === key);

		return !!matchingParamPair ? matchingParamPair[1] : null;
	}

	private getFromUrl(key: string): string | null {
		const url = new URL(location.href);
		const params: URLSearchParams = new URLSearchParams(url.search);
		const param: string = params.get(key);
		return param ? UtilsService.decode(param) : null;
	}

	/**
	 * [NOTE] URL constructor takes only valid urls like `https://www.google.de` (`origin` must be set)
	 * 				relative url must be enriched by `origin`
	 */
	private toURL(url: string): URL {
		try {
			return new URL(url);
		} catch (_) {
			return new URL(window.location.origin + url);
		}
	}

	/**
	 * static functions of StorageService are used - those functions could be moved somewhere else
	 */
	private toEncodedString(param: any): string {
		const paramString = UtilsService.stringify(param); // strings are not modified with stringify
		return UtilsService.encode(paramString);
	}
}
