import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnInit,
	Optional,
	SimpleChanges,
	TemplateRef,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, merge } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { isDefined } from '@mysvg/utils';

import { FormGroupService } from '../../services/form-group.service';

// This should be used in cases where a part of the ui without a FormControl should be included visually in a FormGroup.

@UntilDestroy()
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-control-wrapper',
	styleUrls: ['./control-wrapper.component.scss'],
	templateUrl: './control-wrapper.component.html',
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class ControlWrapperComponent implements OnInit, OnChanges {
	formControl: FormControl | null = null;
	@Input() controlName: string;
	@Input() disableControl: boolean = undefined;
	@Input() forceRequired = false;
	@Input() fullWidth = false;
	@Input() helperText?: string | TemplateRef<any>;
	@Input() label?: string | TemplateRef<any>;
	@Input() value: any;
	/**
	 * Force overwrite display style of container around inserted ng-content.
	 * Defaults to false and only set for special cases of typeahead components inserted.
	 */
	@Input() forceBlockDisplay = false;

	private valueChanged$ = new Subject<void>();

	constructor(
		@Optional() private formGroupDirective: FormGroupDirective,
		@Optional() private macFormGroupService: FormGroupService,
		private changeDetectorRef: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		if (this.formGroupDirective !== null && this.macFormGroupService !== null && this.controlName) {
			this.formControl = this.formGroupDirective.control.get(this.controlName) as FormControl;
			// update control and this wrapping components html when either submit is triggered or projected content changes
			merge(this.macFormGroupService.submit$, this.valueChanged$)
				.pipe(untilDestroyed(this))
				.subscribe(() => {
					FormGroupService.touchControl(this.formControl);
					this.changeDetectorRef.markForCheck();
				});

			// handle async status changes in formControl (like async validators)
			// forces check if should re-render with markForCheck: E.g. to show error message produced by async validator
			this.formControl.statusChanges
				.pipe(distinctUntilChanged(), untilDestroyed(this))
				.subscribe(() => this.changeDetectorRef.markForCheck());
		}
	}

	/**
	 * set value to form control
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['value'] && isDefined(this.formControl)) {
			this.formControl.setValue(this.value);
			this.valueChanged$.next();
		} else if (isDefined(changes['disableControl']) && isDefined(this.formControl)) {
			if (this.disableControl) {
				this.formControl.disable();
				this.formControl.setValue(null);
			} else {
				this.formControl.enable();
				this.formControl.setValue(this.value || null);
			}
		}
	}
}
