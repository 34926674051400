import {
	AfterContentChecked,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { ClrDatagridPagination } from '@clr/angular';

import { isDefined } from '@mysvg/utils';

import { DEFAULT_PAGE_SIZE, TableViewOptionsService } from '../../services/table-view-options.service';
import { TableViewService } from '../../services/table-view.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-table-view-footer',
	styleUrls: ['./table-view-footer.component.scss'],
	templateUrl: './table-view-footer.component.html',
})
export class TableViewFooterComponent implements AfterViewInit, OnChanges, AfterContentChecked {
	pageSizeArray: number[] = [DEFAULT_PAGE_SIZE, 50];

	@Input() pageSizes: number[];
	@Input() itemName: string;

	@ViewChild('pagination') pagination: ClrDatagridPagination;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		public tableViewOptionsService: TableViewOptionsService,
		public tableViewService: TableViewService<any, any>,
	) {}

	ngAfterViewInit(): void {
		this.tableViewOptionsService.setPageObservable(this.pagination.currentChanged);
	}

	ngOnChanges(changes: SimpleChanges): void {
		// [CAUTION] first entry must always be DEFAULT_PAGE_SIZE
		if (changes.pageSizes && isDefined(this.pageSizes) && this.pageSizes.length > 0) {
			if (this.pageSizeArray[0] !== DEFAULT_PAGE_SIZE) {
				this.pageSizeArray = [DEFAULT_PAGE_SIZE, ...this.pageSizes];
			} else {
				this.pageSizeArray = this.pageSizes;
			}
		}
	}

	ngAfterContentChecked(): void {
		this.changeDetectorRef.markForCheck();
	}
}
