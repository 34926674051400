import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthControllerService, UserContext } from '@mysvg/api/auth';
import { KeycloakTokenClaims } from '../../context/models/user-token-claims.model';
import { ErrorMessageKey } from '@svg-frontends/error';
import { SvgContext } from '../../context/models/svg-context.model';

import { AuthenticationService } from './authentication.service';
import { OauthWrapperService } from './oauth-wrapper.service';
import { Logger } from '@nsalaun/ng-logger';

@Injectable({ providedIn: 'root' })
export class SvgAuthenticationService extends AuthenticationService<SvgContext> {
	constructor(
		private authControllerService: AuthControllerService,
		private oauthWrapperService: OauthWrapperService<KeycloakTokenClaims>,
		private logger: Logger,
	) {
		super();
	}

	notLoggedIn(): SvgContext {
		return SvgContext.notLoggedIn();
	}

	postLoginFlow(contextId: string | null): Observable<SvgContext> {
		this.logger.debug(`found groupId / contextId ${contextId}`);

		return forkJoin([
			this.oauthWrapperService.getDecodedToken(),
			contextId == null
				? this.authControllerService.getUserContext()
				: this.authControllerService.getUserContextByGroupId({ groupId: contextId }),
			this.getAndIgnoreErrorInterceptor(contextId),
		]).pipe(
			map(([userTokenClaims, user, activities]: [KeycloakTokenClaims, UserContext, string[]]) =>
				SvgContext.from(userTokenClaims, user, activities),
			),
		);
	}

	/**
	 * 401 (unauthorized) errors from activities should not result in an 401 error
	 * if so, login would say wrong credentials
	 * mark 401 errors on this as internal (500) error
	 */
	private getAndIgnoreErrorInterceptor(contextId: string | null): Observable<string[]> {
		let activitiesControllerCall: Observable<string[]>;
		if (contextId != null) {
			activitiesControllerCall = this.authControllerService.getUsersActivitiesForGroup({ groupId: contextId });
		} else {
			activitiesControllerCall = this.authControllerService.getUsersActivities();
		}

		return activitiesControllerCall.pipe(
			catchError((error: HttpErrorResponse) =>
				throwError(
					new HttpErrorResponse({
						...error,
						error: new Error(ErrorMessageKey.AUTH_NO_ACTIVITIES),
						status: error.status === 401 ? 500 : error.status,
					}),
				),
			),
		);
	}
}
