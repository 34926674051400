/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createPartnerNumber } from '../fn/partner-number-controller/create-partner-number';
import { CreatePartnerNumber$Params } from '../fn/partner-number-controller/create-partner-number';
import { deletePartnerNumber } from '../fn/partner-number-controller/delete-partner-number';
import { DeletePartnerNumber$Params } from '../fn/partner-number-controller/delete-partner-number';
import { PartnerNumberDto } from '../models/partner-number-dto';
import { updatePartnerNumber } from '../fn/partner-number-controller/update-partner-number';
import { UpdatePartnerNumber$Params } from '../fn/partner-number-controller/update-partner-number';

@Injectable({ providedIn: 'root' })
export class PartnerNumberControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updatePartnerNumber()` */
  static readonly UpdatePartnerNumberPath = '/partnerNumber/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePartnerNumber()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePartnerNumber$Response(params: UpdatePartnerNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<PartnerNumberDto>> {
    return updatePartnerNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePartnerNumber$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePartnerNumber(params: UpdatePartnerNumber$Params, context?: HttpContext): Observable<PartnerNumberDto> {
    return this.updatePartnerNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartnerNumberDto>): PartnerNumberDto => r.body)
    );
  }

  /** Path part for operation `createPartnerNumber()` */
  static readonly CreatePartnerNumberPath = '/partnerNumber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPartnerNumber()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPartnerNumber$Response(params: CreatePartnerNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<PartnerNumberDto>> {
    return createPartnerNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPartnerNumber$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPartnerNumber(params: CreatePartnerNumber$Params, context?: HttpContext): Observable<PartnerNumberDto> {
    return this.createPartnerNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartnerNumberDto>): PartnerNumberDto => r.body)
    );
  }

  /** Path part for operation `deletePartnerNumber()` */
  static readonly DeletePartnerNumberPath = '/partnerNumber/{partnerNumberId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePartnerNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerNumber$Response(params: DeletePartnerNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deletePartnerNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePartnerNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartnerNumber(params: DeletePartnerNumber$Params, context?: HttpContext): Observable<void> {
    return this.deletePartnerNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
