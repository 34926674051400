/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SubAccountTypeBoView } from '../../models/sub-account-type-bo-view';

export interface GetSubAccountTypes$Params {

/**
 * Account type id
 */
  accountType?: number;
}

export function getSubAccountTypes(http: HttpClient, rootUrl: string, params?: GetSubAccountTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SubAccountTypeBoView>>> {
  const rb = new RequestBuilder(rootUrl, getSubAccountTypes.PATH, 'get');
  if (params) {
    rb.query('accountType', params.accountType, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<SubAccountTypeBoView>>;
    })
  );
}

getSubAccountTypes.PATH = '/sub-account-types';
