import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from '@nsalaun/ng-logger';
import { Subject } from 'rxjs';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-not-found-content',
	styleUrls: ['./not-found-content.component.scss'],
	templateUrl: './not-found-content.component.html',
})
export class NotFoundContentComponent implements OnInit {
	navigate = new Subject<void>();
	routerUrl: string;

	constructor(private router: Router, private logger: Logger) {}

	ngOnInit(): void {
		this.routerUrl = this.router.url;
	}

	/**
	 * route guard ensures that user is logged in or redirects to keycloak
	 */
	navigateToDefault(): void {
		this.router.navigateByUrl('').catch((error) => {
			this.logger.error('Navigation failed', error);
		});
	}
}
