/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CabinTypeDto } from '../models/cabin-type-dto';
import { EmissionClassDto } from '../models/emission-class-dto';
import { FuelTypeDto } from '../models/fuel-type-dto';
import { getAvailableFuelTypes } from '../fn/public-vehicles-controller/get-available-fuel-types';
import { GetAvailableFuelTypes$Params } from '../fn/public-vehicles-controller/get-available-fuel-types';
import { getCabinTypesPublic } from '../fn/public-vehicles-controller/get-cabin-types-public';
import { GetCabinTypesPublic$Params } from '../fn/public-vehicles-controller/get-cabin-types-public';
import { getEmissionClasses } from '../fn/public-vehicles-controller/get-emission-classes';
import { GetEmissionClasses$Params } from '../fn/public-vehicles-controller/get-emission-classes';
import { getRequiredInputFields } from '../fn/public-vehicles-controller/get-required-input-fields';
import { GetRequiredInputFields$Params } from '../fn/public-vehicles-controller/get-required-input-fields';
import { getVehicleSubGroupsPublic } from '../fn/public-vehicles-controller/get-vehicle-sub-groups-public';
import { GetVehicleSubGroupsPublic$Params } from '../fn/public-vehicles-controller/get-vehicle-sub-groups-public';
import { getVehicleTypesPublic } from '../fn/public-vehicles-controller/get-vehicle-types-public';
import { GetVehicleTypesPublic$Params } from '../fn/public-vehicles-controller/get-vehicle-types-public';
import { isVinValid } from '../fn/public-vehicles-controller/is-vin-valid';
import { IsVinValid$Params } from '../fn/public-vehicles-controller/is-vin-valid';
import { VehicleField } from '../models/vehicle-field';
import { VehicleSubGroupDto } from '../models/vehicle-sub-group-dto';
import { VehicleTypeDto } from '../models/vehicle-type-dto';

@Injectable({ providedIn: 'root' })
export class PublicVehiclesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `isVinValid()` */
  static readonly IsVinValidPath = '/public/vin/{vin}/validation';

  /**
   * check vin against checkdigit
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isVinValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  isVinValid$Response(params: IsVinValid$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return isVinValid(this.http, this.rootUrl, params, context);
  }

  /**
   * check vin against checkdigit
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isVinValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isVinValid(params: IsVinValid$Params, context?: HttpContext): Observable<void> {
    return this.isVinValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getVehicleTypesPublic()` */
  static readonly GetVehicleTypesPublicPath = '/public/vehicles/types';

  /**
   * getting all vehicle types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleTypesPublic()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleTypesPublic$Response(params?: GetVehicleTypesPublic$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VehicleTypeDto>>> {
    return getVehicleTypesPublic(this.http, this.rootUrl, params, context);
  }

  /**
   * getting all vehicle types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicleTypesPublic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleTypesPublic(params?: GetVehicleTypesPublic$Params, context?: HttpContext): Observable<Array<VehicleTypeDto>> {
    return this.getVehicleTypesPublic$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VehicleTypeDto>>): Array<VehicleTypeDto> => r.body)
    );
  }

  /** Path part for operation `getVehicleSubGroupsPublic()` */
  static readonly GetVehicleSubGroupsPublicPath = '/public/vehicles/subgroups';

  /**
   * getting all vehicle sub groups
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleSubGroupsPublic()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleSubGroupsPublic$Response(params?: GetVehicleSubGroupsPublic$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VehicleSubGroupDto>>> {
    return getVehicleSubGroupsPublic(this.http, this.rootUrl, params, context);
  }

  /**
   * getting all vehicle sub groups
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicleSubGroupsPublic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleSubGroupsPublic(params?: GetVehicleSubGroupsPublic$Params, context?: HttpContext): Observable<Array<VehicleSubGroupDto>> {
    return this.getVehicleSubGroupsPublic$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VehicleSubGroupDto>>): Array<VehicleSubGroupDto> => r.body)
    );
  }

  /** Path part for operation `getAvailableFuelTypes()` */
  static readonly GetAvailableFuelTypesPath = '/public/vehicles/fuelTypes';

  /**
   * getting all fuel types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableFuelTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableFuelTypes$Response(params?: GetAvailableFuelTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FuelTypeDto>>> {
    return getAvailableFuelTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * getting all fuel types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailableFuelTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableFuelTypes(params?: GetAvailableFuelTypes$Params, context?: HttpContext): Observable<Array<FuelTypeDto>> {
    return this.getAvailableFuelTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FuelTypeDto>>): Array<FuelTypeDto> => r.body)
    );
  }

  /** Path part for operation `getEmissionClasses()` */
  static readonly GetEmissionClassesPath = '/public/vehicles/emissionClasses';

  /**
   * getting all emission classes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmissionClasses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmissionClasses$Response(params?: GetEmissionClasses$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EmissionClassDto>>> {
    return getEmissionClasses(this.http, this.rootUrl, params, context);
  }

  /**
   * getting all emission classes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEmissionClasses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmissionClasses(params?: GetEmissionClasses$Params, context?: HttpContext): Observable<Array<EmissionClassDto>> {
    return this.getEmissionClasses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmissionClassDto>>): Array<EmissionClassDto> => r.body)
    );
  }

  /** Path part for operation `getCabinTypesPublic()` */
  static readonly GetCabinTypesPublicPath = '/public/vehicles/cabintypes';

  /**
   * getting all vehicle cabin types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCabinTypesPublic()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCabinTypesPublic$Response(params?: GetCabinTypesPublic$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CabinTypeDto>>> {
    return getCabinTypesPublic(this.http, this.rootUrl, params, context);
  }

  /**
   * getting all vehicle cabin types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCabinTypesPublic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCabinTypesPublic(params?: GetCabinTypesPublic$Params, context?: HttpContext): Observable<Array<CabinTypeDto>> {
    return this.getCabinTypesPublic$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CabinTypeDto>>): Array<CabinTypeDto> => r.body)
    );
  }

  /** Path part for operation `getRequiredInputFields()` */
  static readonly GetRequiredInputFieldsPath = '/public/vehicles/boxtypes/{boxtypeId}/requiredFields';

  /**
   * getting the fields required to create or update a vehicle in relation to a boxtype.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequiredInputFields()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequiredInputFields$Response(params: GetRequiredInputFields$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VehicleField>>> {
    return getRequiredInputFields(this.http, this.rootUrl, params, context);
  }

  /**
   * getting the fields required to create or update a vehicle in relation to a boxtype.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRequiredInputFields$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequiredInputFields(params: GetRequiredInputFields$Params, context?: HttpContext): Observable<Array<VehicleField>> {
    return this.getRequiredInputFields$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VehicleField>>): Array<VehicleField> => r.body)
    );
  }

}
