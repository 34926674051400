import { Injectable } from '@angular/core';
import { DocumentTypeDto, PublicControllerService } from '@mysvg/api/pia';
import { RepositoryStoreService } from '../repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomerDocumentTypeSafetiesStoreService extends RepositoryStoreService<DocumentTypeDto[]> {
	constructor(private publicControllerService: PublicControllerService) {
		super();
	}

	fetchData(): Observable<DocumentTypeDto[]> {
		return this.publicControllerService.getDocumentTypesSafeties();
	}
}
