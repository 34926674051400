/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseResurrectCustomerDto } from '../../models/response-resurrect-customer-dto';

export interface GetResurrectableCustomers$Params {
  id?: string;
  svgId?: string;
  customerNumber?: string;
  svgCustomerId?: string;
  companyName?: string;
  billingAddress?: string;
  vatId?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getResurrectableCustomers(http: HttpClient, rootUrl: string, params?: GetResurrectableCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseResurrectCustomerDto>> {
  const rb = new RequestBuilder(rootUrl, getResurrectableCustomers.PATH, 'get');
  if (params) {
    rb.query('id', params.id, {});
    rb.query('svgId', params.svgId, {});
    rb.query('customerNumber', params.customerNumber, {});
    rb.query('svgCustomerId', params.svgCustomerId, {});
    rb.query('companyName', params.companyName, {});
    rb.query('billingAddress', params.billingAddress, {});
    rb.query('vatId', params.vatId, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseResurrectCustomerDto>;
    })
  );
}

getResurrectableCustomers.PATH = '/customer/resurrection';
