/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getLockCancellationReasons } from '../fn/action-reasons-controller/get-lock-cancellation-reasons';
import { GetLockCancellationReasons$Params } from '../fn/action-reasons-controller/get-lock-cancellation-reasons';
import { getLockReasons } from '../fn/action-reasons-controller/get-lock-reasons';
import { GetLockReasons$Params } from '../fn/action-reasons-controller/get-lock-reasons';
import { ResponseLockCancellationReasonBo } from '../models/response-lock-cancellation-reason-bo';
import { ResponseLockReasonBo } from '../models/response-lock-reason-bo';

@Injectable({ providedIn: 'root' })
export class ActionReasonsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getLockReasons()` */
  static readonly GetLockReasonsPath = '/customers/actions/reasons/lock';

  /**
   * Get customer lock reasons
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLockReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLockReasons$Response(params?: GetLockReasons$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseLockReasonBo>> {
    return getLockReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * Get customer lock reasons
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLockReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLockReasons(params?: GetLockReasons$Params, context?: HttpContext): Observable<ResponseLockReasonBo> {
    return this.getLockReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseLockReasonBo>): ResponseLockReasonBo => r.body)
    );
  }

  /** Path part for operation `getLockCancellationReasons()` */
  static readonly GetLockCancellationReasonsPath = '/customers/actions/reasons/lockCancellation';

  /**
   * Get customer lock reasons
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLockCancellationReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLockCancellationReasons$Response(params?: GetLockCancellationReasons$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseLockCancellationReasonBo>> {
    return getLockCancellationReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * Get customer lock reasons
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLockCancellationReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLockCancellationReasons(params?: GetLockCancellationReasons$Params, context?: HttpContext): Observable<ResponseLockCancellationReasonBo> {
    return this.getLockCancellationReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseLockCancellationReasonBo>): ResponseLockCancellationReasonBo => r.body)
    );
  }

}
