import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EnrichedError, ErrorHandlingService, ErrorHandlingType, SvgErrorMessageFactoryService } from '@svg-frontends/error';

import { Context } from '../../context/models/context.model';
import { DefaultUserTokenClaims } from '../../context/models/user-token-claims.model';
import { ContextService } from '../../context/services/context.service';

@Injectable({ providedIn: 'root' })
export class ActivitiesRoutingGuard<T extends Context, U extends DefaultUserTokenClaims> implements CanActivate {
	constructor(
		private contextService: ContextService<T, U>,
		private errorHandlingService: ErrorHandlingService,
		private errorMessageFactoryService: SvgErrorMessageFactoryService,
	) {}

	/**
	 * [NOTE] if route has no activity specified, then return true
	 * 				if activity is specified, then check if user has activity
	 */
	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		const activity = route.data['activity'];
		return !activity ? of(true) : this.handleActivity(activity);
	}

	canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
		return this.canActivate(route);
	}

	private handleActivity(activity: string): Observable<boolean> {
		return this.contextService.hasActivity(activity).pipe(tap((activated: boolean) => this.routeToErrorMessage(activated, activity)));
	}

	private routeToErrorMessage(activated: boolean, activity: string): void {
		if (!activated) {
			const errorMessage: string = this.errorMessageFactoryService.getEnrichedMessage('403', { activity });
			const enrichedError: EnrichedError = {
				displayMessages: [this.errorMessageFactoryService.getMessageForKey('403')],
				error: new Error(errorMessage),
				handlingType: ErrorHandlingType.ROUTE_TO_ERROR_PAGE,
				reportSentry: true,
			};

			this.errorHandlingService.setNextError(enrichedError);
		}
	}
}
