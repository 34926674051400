import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SvgContext } from '../models/svg-context.model';
import { SvgContextService } from '../services/svg-context.service';

@Injectable({ providedIn: 'root' })
export class SvgCustomerContextGuard implements CanActivate {
	constructor(private router: Router, private svgContextService: SvgContextService) {}

	canActivate(): Observable<UrlTree | boolean> {
		return this.svgContextService.getFirstSvgContext().pipe(
			map((svgContext: SvgContext) => {
				if (svgContext.isCustomerOrStaffAtCustomerState()) {
					return true;
				} else {
					return this.router.createUrlTree(['staff']);
				}
			}),
		);
	}
}
