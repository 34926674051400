import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { MysvgBaseUrls } from '@mysvg/utils';

import { API_BASE_URLS_TOKEN } from '@svg/environment';

import { SvgContextService } from '../services/svg-context.service';

const HEADER_NAME = 'Customer-Group';

@Injectable()
@UntilDestroy()
export class SvgCustomerGroupIdHeaderInterceptor implements HttpInterceptor {
	private customerGroupId: string | undefined;
	private mysvgApiUrls: Set<string>;

	constructor(@Inject(API_BASE_URLS_TOKEN) private baseApiUrls: MysvgBaseUrls, private svgContextService: SvgContextService) {
		this.mysvgApiUrls = this.getUniqueMysvgApiOrigins();
		// DEBUG_LOG (WEB-797): console.log('[SvgCustomerGroupIdHeaderInterceptor]: ', this.mysvgApiUrls);
		// get portal UID, as soon as available
		this.svgContextService
			.getFirstPortalUid()
			.pipe(untilDestroyed(this))
			.subscribe((portalUid: string) => (this.customerGroupId = portalUid));
	}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
		const requestOrigin = new URL(req.url).origin;
		// DEBUG_LOG (WEB-797): console.log('[SvgCustomerGroupIdHeaderInterceptor]: ', {url: req.url, urlMatch: this.mysvgApiUrls.has(requestOrigin), customerGroup: this.customerGroupId});
		// only for mySVG api requests and available customer group
		if (this.mysvgApiUrls.has(requestOrigin) && this.customerGroupId) {
			req = req.clone({
				headers: req.headers.set(HEADER_NAME, this.customerGroupId),
			});
			// DEBUG_LOG (WEB-797): console.log('MODIFIED HEADER');
		}
		return next.handle(req);
	}

	/**
	 * [NOTE] in regular usage there will be just one origin, but with local testing, there could potentially be multiple origins
	 */
	private getUniqueMysvgApiOrigins(): Set<string> {
		const origins = Object.values(this.baseApiUrls).map((urlString: string) => new URL(urlString).origin);
		// make unique using set,
		return new Set(origins);
	}
}
