import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

import { ErrorHandlingService, ErrorHandlingType } from '@svg-frontends/error';
import { SvgContext, SvgContextService } from '@svg-frontends/session';

import { GtagCustomDimensions } from '../models/gtag-custom-dimensions.model';
import { GtagEventParams } from '../models/gtag-event-params.model';
import { GtagPurchaseItem } from '../models/gtag-purchase-items.model';
import { GtagService } from './gtag.service';

const CONFIG = {
	MEASUREMENT_ID: 'UA-125913018-1', // this is the same as in `index.html`
	AFFILIATION: 'mySVG',
	CURRENCY: 'EUR',
};

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
	constructor(
		private errorHandlingService: ErrorHandlingService,
		private gtagService: GtagService,
		private router: Router,
		private svgContextService: SvgContextService,
	) {}

	init(): void {
		this.gtagService.init(CONFIG.MEASUREMENT_ID, CONFIG.AFFILIATION, CONFIG.CURRENCY, {
			dimension1: 'customer',
			dimension2: 'svg',
			dimension3: 'context',
			dimension4: 'customerName',
		});

		this.router.events
			.pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => this.gtagService.pageView(event.urlAfterRedirects, window.document.title));
	}

	sendEvent(action: string, gtagEventParams: GtagEventParams): void {
		this.svgContextService
			.getFirstSvgContext()
			.pipe(map((svgContext: SvgContext) => this.getGtagCustomDimensions(svgContext)))
			.subscribe(
				(gtagCustomDimensions: GtagCustomDimensions) => this.gtagService.event(action, { ...gtagEventParams, ...gtagCustomDimensions }),
				(error: Error) => this.errorHandlingService.setNextErrorBy(error, ErrorHandlingType.IGNORE, true),
			);
	}

	sendPurchaseEvent(value: number, items: GtagPurchaseItem[], id: string, label: string): void {
		this.svgContextService
			.getFirstSvgContext()
			.pipe(map((svgContext: SvgContext) => this.getGtagCustomDimensions(svgContext)))
			.subscribe(
				(gtagCustomDimensions: GtagCustomDimensions) => this.gtagService.purchase(value, items, gtagCustomDimensions, id, label),
				(error: Error) => this.errorHandlingService.setNextErrorBy(error, ErrorHandlingType.IGNORE, true),
			);
	}

	private getGtagCustomDimensions(svgContext: SvgContext): GtagCustomDimensions {
		return {
			context: svgContext.state,
			customer: svgContext?.customerInfo?.customerNumber,
			customerName: svgContext?.customerInfo?.companyName,
			svg: svgContext?.svgInfo?.svgNumber,
		};
	}
}
