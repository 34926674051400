import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class ExternalRedirectGuard implements CanActivate {
	canActivate(route: ActivatedRouteSnapshot): boolean {
		window.location.href = route.data['externalUrl'];
		return true;
	}
}
