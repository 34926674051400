import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { SvgDocument } from '@mysvg/api/document';
import { CustomersDocumentsControllerService } from '@mysvg/api/pia';
import { SvgContextService } from '@svg-frontends/session';
import { ParametrizedRepositoryStoreService } from '@svg-frontends/store';

import { CustomerDocumentType } from '../enums/customer-document-type.enum';

@Injectable({ providedIn: 'root' })
export class CustomerDocumentsStoreService extends ParametrizedRepositoryStoreService<SvgDocument[], CustomerDocumentType> {
	constructor(
		private customersDocumentsControllerService: CustomersDocumentsControllerService,
		private svgContextService: SvgContextService,
	) {
		super();
	}

	fetchData(key: CustomerDocumentType): Observable<SvgDocument[]> {
		return this.svgContextService
			.getFirstPortalUid()
			.pipe(
				mergeMap((portalUid: string) =>
					this.customersDocumentsControllerService.getPublicDocumentsOfType({ documentType: key, portalUid }),
				),
			);
	}

	keyToString(key: CustomerDocumentType): string {
		return key.toString();
	}
}
