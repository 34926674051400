import { Injectable } from '@angular/core';

/**
 * copy of `https://gist.github.com/bvandenbon/91d3e6d11363c08154b93f95eb4e9a0f`
 */
@Injectable({ providedIn: 'root' })
export class CookieService {
	set(key: string, value: string, expires: string): void {
		const expiresToUTC = new Date(expires).toUTCString();
		document.cookie = `${key}=${value};expires='${expiresToUTC}'`;
	}

	get(key: string): string | null {
		const decodedCookie: string = decodeURIComponent(document.cookie);
		const pairs: string[] = decodedCookie.split(/;\s*/);

		const prefix = `${key}=`;
		for (const pair of pairs) {
			if (pair.indexOf(prefix) === 0) {
				return pair.substring(prefix.length);
			}
		}
		return null;
	}
}
