import { Injectable } from '@angular/core';
import { ActivationEnd, Data, Event, Router } from '@angular/router';
import { Logger } from '@nsalaun/ng-logger';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { SvgContext, SvgContextService } from '@svg-frontends/session';

@Injectable({ providedIn: 'root' })
export class EditModeService {
	private currentData$: BehaviorSubject<Data> = new BehaviorSubject<Data>({});
	private isEditingMode$ = new BehaviorSubject(false);

	/**
	 * state can only be `true` in staff@customer - context
	 * else its always false
	 */
	isStaffAtCustomerEditing$: Observable<boolean>;

	constructor(private router: Router, private svgContextService: SvgContextService, private logger: Logger) {
		this.init();
	}

	private static getCorrectRoute(data: Data, isEditing: boolean): string {
		if (isEditing) {
			const enterEditModeRoute: string = data['enterEditModeRoute'];
			return enterEditModeRoute ? enterEditModeRoute : '/edit';
		} else {
			const leaveEditModeRoute: string = data['leaveEditModeRoute'];
			return leaveEditModeRoute ? leaveEditModeRoute : '/';
		}
	}

	toggle(): void {
		const isEditingMode = !this.isEditingMode$.getValue();
		this.routeToCorrectPage(isEditingMode);
	}

	enterEditMode(): void {
		this.isEditingMode$.next(true);
	}

	leaveEditMode(): void {
		this.isEditingMode$.next(false);
	}

	private init(): void {
		const isStaffAtCustomer$: Observable<boolean> = this.svgContextService
			.getSvgContext()
			.pipe(map((svgContext: SvgContext) => svgContext.isStaffAtCustomerState()));

		this.isStaffAtCustomerEditing$ = combineLatest([isStaffAtCustomer$, this.isEditingMode$]).pipe(
			map(([isStaffAtCustomer, isEditing]) => isStaffAtCustomer && isEditing),
			distinctUntilChanged(),
		);

		// We store the current data every time the routing changes in order to retrieve it for further processing.
		this.router.events
			.pipe(
				filter((event: Event): event is ActivationEnd => event instanceof ActivationEnd && event.snapshot.children.length === 0),
				map((event: ActivationEnd) => event.snapshot.data),
			)
			.subscribe((data: Data) => this.currentData$.next(data));
	}

	private routeToCorrectPage(isEditing: boolean): void {
		const data: Data = this.currentData$.getValue();
		const route: string = EditModeService.getCorrectRoute(data, isEditing);

		this.router
			.navigateByUrl(route)
			.then(() => (isEditing ? this.enterEditMode() : this.leaveEditMode()))
			.catch((error) => {
				this.logger.error('Navigation failed', error);
			});
	}
}
