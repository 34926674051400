/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ExternalDocumentSubTypeDirectory } from '../models/external-document-sub-type-directory';
import { ExternalDocumentSubTypeDto } from '../models/external-document-sub-type-dto';
import { ExternalDocumentTypeDirectory } from '../models/external-document-type-directory';
import { ExternalDocumentTypeDto } from '../models/external-document-type-dto';
import { getAllDocumentSubTypes } from '../fn/external-document-type-controller/get-all-document-sub-types';
import { GetAllDocumentSubTypes$Params } from '../fn/external-document-type-controller/get-all-document-sub-types';
import { getAllDocumentTypes } from '../fn/external-document-type-controller/get-all-document-types';
import { GetAllDocumentTypes$Params } from '../fn/external-document-type-controller/get-all-document-types';
import { getDocumentSubTypeDirectoryByCustomerAndType } from '../fn/external-document-type-controller/get-document-sub-type-directory-by-customer-and-type';
import { GetDocumentSubTypeDirectoryByCustomerAndType$Params } from '../fn/external-document-type-controller/get-document-sub-type-directory-by-customer-and-type';
import { getDocumentSubTypesByCustomerAndType } from '../fn/external-document-type-controller/get-document-sub-types-by-customer-and-type';
import { GetDocumentSubTypesByCustomerAndType$Params } from '../fn/external-document-type-controller/get-document-sub-types-by-customer-and-type';
import { getDocumentTypeDirectoryByCustomer } from '../fn/external-document-type-controller/get-document-type-directory-by-customer';
import { GetDocumentTypeDirectoryByCustomer$Params } from '../fn/external-document-type-controller/get-document-type-directory-by-customer';
import { getDocumentTypesForCustomer } from '../fn/external-document-type-controller/get-document-types-for-customer';
import { GetDocumentTypesForCustomer$Params } from '../fn/external-document-type-controller/get-document-types-for-customer';

@Injectable({ providedIn: 'root' })
export class ExternalDocumentTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDocumentSubTypesByCustomerAndType()` */
  static readonly GetDocumentSubTypesByCustomerAndTypePath = '/external-document/types/{typeId}/sub-type/customers/{puid}';

  /**
   * a list of document subtypes for the customers and by doc type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentSubTypesByCustomerAndType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentSubTypesByCustomerAndType$Response(params: GetDocumentSubTypesByCustomerAndType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExternalDocumentSubTypeDto>>> {
    return getDocumentSubTypesByCustomerAndType(this.http, this.rootUrl, params, context);
  }

  /**
   * a list of document subtypes for the customers and by doc type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentSubTypesByCustomerAndType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentSubTypesByCustomerAndType(params: GetDocumentSubTypesByCustomerAndType$Params, context?: HttpContext): Observable<Array<ExternalDocumentSubTypeDto>> {
    return this.getDocumentSubTypesByCustomerAndType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExternalDocumentSubTypeDto>>): Array<ExternalDocumentSubTypeDto> => r.body)
    );
  }

  /** Path part for operation `getDocumentTypesForCustomer()` */
  static readonly GetDocumentTypesForCustomerPath = '/external-document/types/customers/{puid}';

  /**
   * a list of document types for for a customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentTypesForCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypesForCustomer$Response(params: GetDocumentTypesForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExternalDocumentTypeDto>>> {
    return getDocumentTypesForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * a list of document types for for a customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentTypesForCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypesForCustomer(params: GetDocumentTypesForCustomer$Params, context?: HttpContext): Observable<Array<ExternalDocumentTypeDto>> {
    return this.getDocumentTypesForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExternalDocumentTypeDto>>): Array<ExternalDocumentTypeDto> => r.body)
    );
  }

  /** Path part for operation `getAllDocumentTypes()` */
  static readonly GetAllDocumentTypesPath = '/external-document/types/all';

  /**
   * a list of all document types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDocumentTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDocumentTypes$Response(params?: GetAllDocumentTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExternalDocumentTypeDto>>> {
    return getAllDocumentTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * a list of all document types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllDocumentTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDocumentTypes(params?: GetAllDocumentTypes$Params, context?: HttpContext): Observable<Array<ExternalDocumentTypeDto>> {
    return this.getAllDocumentTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExternalDocumentTypeDto>>): Array<ExternalDocumentTypeDto> => r.body)
    );
  }

  /** Path part for operation `getDocumentSubTypeDirectoryByCustomerAndType()` */
  static readonly GetDocumentSubTypeDirectoryByCustomerAndTypePath = '/external-document/type-directories/{typeId}/sub-type-directories/customers/{puid}';

  /**
   * a list of document subtype directories for the customers and by doc type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentSubTypeDirectoryByCustomerAndType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentSubTypeDirectoryByCustomerAndType$Response(params: GetDocumentSubTypeDirectoryByCustomerAndType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExternalDocumentSubTypeDirectory>>> {
    return getDocumentSubTypeDirectoryByCustomerAndType(this.http, this.rootUrl, params, context);
  }

  /**
   * a list of document subtype directories for the customers and by doc type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentSubTypeDirectoryByCustomerAndType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentSubTypeDirectoryByCustomerAndType(params: GetDocumentSubTypeDirectoryByCustomerAndType$Params, context?: HttpContext): Observable<Array<ExternalDocumentSubTypeDirectory>> {
    return this.getDocumentSubTypeDirectoryByCustomerAndType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExternalDocumentSubTypeDirectory>>): Array<ExternalDocumentSubTypeDirectory> => r.body)
    );
  }

  /** Path part for operation `getDocumentTypeDirectoryByCustomer()` */
  static readonly GetDocumentTypeDirectoryByCustomerPath = '/external-document/type-directories/customers/{puid}';

  /**
   * a list of document type directories for the customers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentTypeDirectoryByCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypeDirectoryByCustomer$Response(params: GetDocumentTypeDirectoryByCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExternalDocumentTypeDirectory>>> {
    return getDocumentTypeDirectoryByCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * a list of document type directories for the customers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentTypeDirectoryByCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypeDirectoryByCustomer(params: GetDocumentTypeDirectoryByCustomer$Params, context?: HttpContext): Observable<Array<ExternalDocumentTypeDirectory>> {
    return this.getDocumentTypeDirectoryByCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExternalDocumentTypeDirectory>>): Array<ExternalDocumentTypeDirectory> => r.body)
    );
  }

  /** Path part for operation `getAllDocumentSubTypes()` */
  static readonly GetAllDocumentSubTypesPath = '/external-document/sub-types/all';

  /**
   * a list of all document sub types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDocumentSubTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDocumentSubTypes$Response(params?: GetAllDocumentSubTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExternalDocumentSubTypeDto>>> {
    return getAllDocumentSubTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * a list of all document sub types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllDocumentSubTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDocumentSubTypes(params?: GetAllDocumentSubTypes$Params, context?: HttpContext): Observable<Array<ExternalDocumentSubTypeDto>> {
    return this.getAllDocumentSubTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExternalDocumentSubTypeDto>>): Array<ExternalDocumentSubTypeDto> => r.body)
    );
  }

}
