/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { activateTelematicProducts } from '../fn/telematic-controller/activate-telematic-products';
import { ActivateTelematicProducts$Params } from '../fn/telematic-controller/activate-telematic-products';
import { checkIfProductSelectionIsValid } from '../fn/telematic-controller/check-if-product-selection-is-valid';
import { CheckIfProductSelectionIsValid$Params } from '../fn/telematic-controller/check-if-product-selection-is-valid';
import { checkIfServiceSelectionIsValid } from '../fn/telematic-controller/check-if-service-selection-is-valid';
import { CheckIfServiceSelectionIsValid$Params } from '../fn/telematic-controller/check-if-service-selection-is-valid';
import { deactivateTelematicProducts } from '../fn/telematic-controller/deactivate-telematic-products';
import { DeactivateTelematicProducts$Params } from '../fn/telematic-controller/deactivate-telematic-products';
import { getAllTelematicProducts } from '../fn/telematic-controller/get-all-telematic-products';
import { GetAllTelematicProducts$Params } from '../fn/telematic-controller/get-all-telematic-products';
import { getAllTelematicServices } from '../fn/telematic-controller/get-all-telematic-services';
import { GetAllTelematicServices$Params } from '../fn/telematic-controller/get-all-telematic-services';
import { getOrderDataProcessingDocument } from '../fn/telematic-controller/get-order-data-processing-document';
import { GetOrderDataProcessingDocument$Params } from '../fn/telematic-controller/get-order-data-processing-document';
import { getOrderStatus } from '../fn/telematic-controller/get-order-status';
import { GetOrderStatus$Params } from '../fn/telematic-controller/get-order-status';
import { getSelectedTelematicProducts } from '../fn/telematic-controller/get-selected-telematic-products';
import { GetSelectedTelematicProducts$Params } from '../fn/telematic-controller/get-selected-telematic-products';
import { getSelectedTelematicServices } from '../fn/telematic-controller/get-selected-telematic-services';
import { GetSelectedTelematicServices$Params } from '../fn/telematic-controller/get-selected-telematic-services';
import { getServicesOfProducts } from '../fn/telematic-controller/get-services-of-products';
import { GetServicesOfProducts$Params } from '../fn/telematic-controller/get-services-of-products';
import { getTelematicAgbDocument } from '../fn/telematic-controller/get-telematic-agb-document';
import { GetTelematicAgbDocument$Params } from '../fn/telematic-controller/get-telematic-agb-document';
import { getTelematicBusinessServiceInformationDocument } from '../fn/telematic-controller/get-telematic-business-service-information-document';
import { GetTelematicBusinessServiceInformationDocument$Params } from '../fn/telematic-controller/get-telematic-business-service-information-document';
import { getTelematicOperatingInstructionsDocument } from '../fn/telematic-controller/get-telematic-operating-instructions-document';
import { GetTelematicOperatingInstructionsDocument$Params } from '../fn/telematic-controller/get-telematic-operating-instructions-document';
import { getTelematicStatus } from '../fn/telematic-controller/get-telematic-status';
import { GetTelematicStatus$Params } from '../fn/telematic-controller/get-telematic-status';
import { getTelematicStatuses } from '../fn/telematic-controller/get-telematic-statuses';
import { GetTelematicStatuses$Params } from '../fn/telematic-controller/get-telematic-statuses';
import { getTelematicTechnicalSpecificationsDocument } from '../fn/telematic-controller/get-telematic-technical-specifications-document';
import { GetTelematicTechnicalSpecificationsDocument$Params } from '../fn/telematic-controller/get-telematic-technical-specifications-document';
import { OrderStatus } from '../models/order-status';
import { TelematicDocument } from '../models/telematic-document';
import { TelematicProductServiceHardwareDto } from '../models/telematic-product-service-hardware-dto';
import { TelematicStatusDto } from '../models/telematic-status-dto';
import { VehicleStatus } from '../models/vehicle-status';

@Injectable({ providedIn: 'root' })
export class TelematicControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `deactivateTelematicProducts()` */
  static readonly DeactivateTelematicProductsPath = '/tollbox/telematic/deactivation';

  /**
   * deactivation of telematic products and/or services for one toll box.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateTelematicProducts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deactivateTelematicProducts$Response(params: DeactivateTelematicProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deactivateTelematicProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * deactivation of telematic products and/or services for one toll box.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deactivateTelematicProducts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deactivateTelematicProducts(params: DeactivateTelematicProducts$Params, context?: HttpContext): Observable<void> {
    return this.deactivateTelematicProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `activateTelematicProducts()` */
  static readonly ActivateTelematicProductsPath = '/tollbox/telematic/activation';

  /**
   * activation of telematic products and/or services for one toll box.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateTelematicProducts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateTelematicProducts$Response(params: ActivateTelematicProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return activateTelematicProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * activation of telematic products and/or services for one toll box.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activateTelematicProducts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateTelematicProducts(params: ActivateTelematicProducts$Params, context?: HttpContext): Observable<void> {
    return this.activateTelematicProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getTelematicStatus()` */
  static readonly GetTelematicStatusPath = '/tollbox/{obuId}/telematic';

  /**
   * Get the telematic status of one tollbox
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTelematicStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelematicStatus$Response(params: GetTelematicStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VehicleStatus>>> {
    return getTelematicStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the telematic status of one tollbox
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTelematicStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelematicStatus(params: GetTelematicStatus$Params, context?: HttpContext): Observable<Array<VehicleStatus>> {
    return this.getTelematicStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VehicleStatus>>): Array<VehicleStatus> => r.body)
    );
  }

  /** Path part for operation `getAllTelematicServices()` */
  static readonly GetAllTelematicServicesPath = '/telematic/services';

  /**
   * Get the list of telematic services
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTelematicServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTelematicServices$Response(params?: GetAllTelematicServices$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TelematicProductServiceHardwareDto>>> {
    return getAllTelematicServices(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the list of telematic services
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTelematicServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTelematicServices(params?: GetAllTelematicServices$Params, context?: HttpContext): Observable<Array<TelematicProductServiceHardwareDto>> {
    return this.getAllTelematicServices$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TelematicProductServiceHardwareDto>>): Array<TelematicProductServiceHardwareDto> => r.body)
    );
  }

  /** Path part for operation `getSelectedTelematicServices()` */
  static readonly GetSelectedTelematicServicesPath = '/telematic/services/{boxId}';

  /**
   * Get the list of selected telematic products for given box
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSelectedTelematicServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelectedTelematicServices$Response(params: GetSelectedTelematicServices$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TelematicProductServiceHardwareDto>>> {
    return getSelectedTelematicServices(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the list of selected telematic products for given box
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSelectedTelematicServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelectedTelematicServices(params: GetSelectedTelematicServices$Params, context?: HttpContext): Observable<Array<TelematicProductServiceHardwareDto>> {
    return this.getSelectedTelematicServices$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TelematicProductServiceHardwareDto>>): Array<TelematicProductServiceHardwareDto> => r.body)
    );
  }

  /** Path part for operation `checkIfServiceSelectionIsValid()` */
  static readonly CheckIfServiceSelectionIsValidPath = '/telematic/services/validate';

  /**
   * Check if given service selection is valid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkIfServiceSelectionIsValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfServiceSelectionIsValid$Response(params?: CheckIfServiceSelectionIsValid$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return checkIfServiceSelectionIsValid(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if given service selection is valid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkIfServiceSelectionIsValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfServiceSelectionIsValid(params?: CheckIfServiceSelectionIsValid$Params, context?: HttpContext): Observable<void> {
    return this.checkIfServiceSelectionIsValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAllTelematicProducts()` */
  static readonly GetAllTelematicProductsPath = '/telematic/products';

  /**
   * Get the list of telematic products
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTelematicProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTelematicProducts$Response(params?: GetAllTelematicProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TelematicProductServiceHardwareDto>>> {
    return getAllTelematicProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the list of telematic products
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTelematicProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTelematicProducts(params?: GetAllTelematicProducts$Params, context?: HttpContext): Observable<Array<TelematicProductServiceHardwareDto>> {
    return this.getAllTelematicProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TelematicProductServiceHardwareDto>>): Array<TelematicProductServiceHardwareDto> => r.body)
    );
  }

  /** Path part for operation `getSelectedTelematicProducts()` */
  static readonly GetSelectedTelematicProductsPath = '/telematic/products/{boxId}';

  /**
   * Get the list of selected telematic products for given box
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSelectedTelematicProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelectedTelematicProducts$Response(params: GetSelectedTelematicProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TelematicProductServiceHardwareDto>>> {
    return getSelectedTelematicProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the list of selected telematic products for given box
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSelectedTelematicProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelectedTelematicProducts(params: GetSelectedTelematicProducts$Params, context?: HttpContext): Observable<Array<TelematicProductServiceHardwareDto>> {
    return this.getSelectedTelematicProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TelematicProductServiceHardwareDto>>): Array<TelematicProductServiceHardwareDto> => r.body)
    );
  }

  /** Path part for operation `checkIfProductSelectionIsValid()` */
  static readonly CheckIfProductSelectionIsValidPath = '/telematic/products/validate';

  /**
   * Check if given product selection is valid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkIfProductSelectionIsValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfProductSelectionIsValid$Response(params?: CheckIfProductSelectionIsValid$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return checkIfProductSelectionIsValid(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if given product selection is valid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkIfProductSelectionIsValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfProductSelectionIsValid(params?: CheckIfProductSelectionIsValid$Params, context?: HttpContext): Observable<void> {
    return this.checkIfProductSelectionIsValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getServicesOfProducts()` */
  static readonly GetServicesOfProductsPath = '/telematic/products/services';

  /**
   * Get the list of telematic services for given products
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getServicesOfProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServicesOfProducts$Response(params: GetServicesOfProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TelematicProductServiceHardwareDto>>> {
    return getServicesOfProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the list of telematic services for given products
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getServicesOfProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServicesOfProducts(params: GetServicesOfProducts$Params, context?: HttpContext): Observable<Array<TelematicProductServiceHardwareDto>> {
    return this.getServicesOfProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TelematicProductServiceHardwareDto>>): Array<TelematicProductServiceHardwareDto> => r.body)
    );
  }

  /** Path part for operation `getOrderStatus()` */
  static readonly GetOrderStatusPath = '/telematic/orders/{orderId}/status';

  /**
   * Get the actual status of one telematic order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderStatus$Response(params: GetOrderStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderStatus>> {
    return getOrderStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the actual status of one telematic order
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderStatus(params: GetOrderStatus$Params, context?: HttpContext): Observable<OrderStatus> {
    return this.getOrderStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderStatus>): OrderStatus => r.body)
    );
  }

  /** Path part for operation `getTelematicBusinessServiceInformationDocument()` */
  static readonly GetTelematicBusinessServiceInformationDocumentPath = '/telematic/documents/{svgId}/telematic-business-service-information';

  /**
   * Get the business serviceinformation document for telematic
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTelematicBusinessServiceInformationDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelematicBusinessServiceInformationDocument$Response(params: GetTelematicBusinessServiceInformationDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<TelematicDocument>> {
    return getTelematicBusinessServiceInformationDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the business serviceinformation document for telematic
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTelematicBusinessServiceInformationDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelematicBusinessServiceInformationDocument(params: GetTelematicBusinessServiceInformationDocument$Params, context?: HttpContext): Observable<TelematicDocument> {
    return this.getTelematicBusinessServiceInformationDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<TelematicDocument>): TelematicDocument => r.body)
    );
  }

  /** Path part for operation `getTelematicTechnicalSpecificationsDocument()` */
  static readonly GetTelematicTechnicalSpecificationsDocumentPath = '/telematic/documents/{svgId}/technical-specifications';

  /**
   * Get the telematic technical specifications document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTelematicTechnicalSpecificationsDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelematicTechnicalSpecificationsDocument$Response(params: GetTelematicTechnicalSpecificationsDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<TelematicDocument>> {
    return getTelematicTechnicalSpecificationsDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the telematic technical specifications document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTelematicTechnicalSpecificationsDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelematicTechnicalSpecificationsDocument(params: GetTelematicTechnicalSpecificationsDocument$Params, context?: HttpContext): Observable<TelematicDocument> {
    return this.getTelematicTechnicalSpecificationsDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<TelematicDocument>): TelematicDocument => r.body)
    );
  }

  /** Path part for operation `getOrderDataProcessingDocument()` */
  static readonly GetOrderDataProcessingDocumentPath = '/telematic/documents/{svgId}/order-data-processing';

  /**
   * Get the order data processing document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderDataProcessingDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderDataProcessingDocument$Response(params: GetOrderDataProcessingDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<TelematicDocument>> {
    return getOrderDataProcessingDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the order data processing document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderDataProcessingDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderDataProcessingDocument(params: GetOrderDataProcessingDocument$Params, context?: HttpContext): Observable<TelematicDocument> {
    return this.getOrderDataProcessingDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<TelematicDocument>): TelematicDocument => r.body)
    );
  }

  /** Path part for operation `getTelematicOperatingInstructionsDocument()` */
  static readonly GetTelematicOperatingInstructionsDocumentPath = '/telematic/documents/operating-instructions/{svgId}';

  /**
   * Get the telematic operating Instructions document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTelematicOperatingInstructionsDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelematicOperatingInstructionsDocument$Response(params: GetTelematicOperatingInstructionsDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<TelematicDocument>> {
    return getTelematicOperatingInstructionsDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the telematic operating Instructions document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTelematicOperatingInstructionsDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelematicOperatingInstructionsDocument(params: GetTelematicOperatingInstructionsDocument$Params, context?: HttpContext): Observable<TelematicDocument> {
    return this.getTelematicOperatingInstructionsDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<TelematicDocument>): TelematicDocument => r.body)
    );
  }

  /** Path part for operation `getTelematicAgbDocument()` */
  static readonly GetTelematicAgbDocumentPath = '/telematic/documents/agb/{svgId}';

  /**
   * Get the telematic agb document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTelematicAgbDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelematicAgbDocument$Response(params: GetTelematicAgbDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<TelematicDocument>> {
    return getTelematicAgbDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the telematic agb document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTelematicAgbDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelematicAgbDocument(params: GetTelematicAgbDocument$Params, context?: HttpContext): Observable<TelematicDocument> {
    return this.getTelematicAgbDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<TelematicDocument>): TelematicDocument => r.body)
    );
  }

  /** Path part for operation `getTelematicStatuses()` */
  static readonly GetTelematicStatusesPath = '/boxes/telematic/statuses';

  /**
   * Returns a list of all telematic statuses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTelematicStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelematicStatuses$Response(params?: GetTelematicStatuses$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TelematicStatusDto>>> {
    return getTelematicStatuses(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a list of all telematic statuses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTelematicStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelematicStatuses(params?: GetTelematicStatuses$Params, context?: HttpContext): Observable<Array<TelematicStatusDto>> {
    return this.getTelematicStatuses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TelematicStatusDto>>): Array<TelematicStatusDto> => r.body)
    );
  }

}
