import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomerDocumentDownloadPathPipe } from './pipes/customer-document-download-path.pipe';
import { CustomersDownloadLinkFromHashPipe } from './pipes/customers-download-link-from-hash.pipe';
import { DiscountProgramDownloadLinkFromHashPipe } from './pipes/discount-program-download-link-from-hash.pipe';
import { DocumentTemplateCategoryPipe } from './pipes/document-template-category/document-template-category.pipe';
import { PrivateDownloadLinkFromHashPipe } from './pipes/private-download-link-from-hash.pipe';
import { PrivateEntityDownloadLinkFromHashPipe } from './pipes/private-entity-download-link-from-hash.pipe';
import { PublicDownloadLinkFromHashPipe } from './pipes/public-download-link-from-hash.pipe';
import { SvgsDownloadLinkFromHashPipe } from './pipes/svgs-download-link-from-hash.pipe';
import { TempDownloadLinkFromHashPipe } from './pipes/temp-download-link-from-hash.pipe';
import { VehicleDocumentsPipe } from './pipes/vehicle-documents.pipe';
import { ZipDownloadLinkFromHashPipe } from './pipes/zip-download-link-from-hash.pipe';

const DECLARABLES = [
	CustomerDocumentDownloadPathPipe,
	CustomersDownloadLinkFromHashPipe,
	DocumentTemplateCategoryPipe,
	PrivateDownloadLinkFromHashPipe,
	PrivateEntityDownloadLinkFromHashPipe,
	PublicDownloadLinkFromHashPipe,
	SvgsDownloadLinkFromHashPipe,
	TempDownloadLinkFromHashPipe,
	VehicleDocumentsPipe,
	ZipDownloadLinkFromHashPipe,
	DiscountProgramDownloadLinkFromHashPipe,
];

@NgModule({
	declarations: [DECLARABLES],
	exports: [DECLARABLES],
	imports: [CommonModule],
})
export class DocumentModule {}
