import { LabeledEnumModel } from '../models/enum/labeledEnum.model';

export const EuroEmissionClass: LabeledEnumModel = {
	// Euro emission classes
	keine: { label: 'keine' },
	'Euro 1': { label: 'Euro 1' },
	'Euro 2': { label: 'Euro 2' },
	'Euro 3': { label: 'Euro 3' },
	'Euro 4': { label: 'Euro 4' },
	'Euro 5': { label: 'Euro 5' },
	'EEV Klasse1': { label: 'EEV Klasse 1' },
	'Euro 6': { label: 'Euro 6' },
	'Euro 3 mit PMK 4': { label: 'Euro 3 mit PMK 4' },
	'Euro 3 mit PMK 3': { label: 'Euro 3 mit PMK 3' },
	'Euro 3 mit PMK 2': { label: 'Euro 3 mit PMK 2' },
	'Euro 2 mit PMK 4': { label: 'Euro 2 mit PMK 4' },
	'Euro 2 mit PMK 3': { label: 'Euro 2 mit PMK 3' },
	'Euro 2 mit PMK 2': { label: 'Euro 2 mit PMK 2' },
	'Euro 2 mit PMK 1': { label: 'Euro 2 mit PMK 1' },
};
