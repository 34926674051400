/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllActiveSecurityExperts } from '../fn/security-expert-controller/get-all-active-security-experts';
import { GetAllActiveSecurityExperts$Params } from '../fn/security-expert-controller/get-all-active-security-experts';
import { getAllSecurityExperts } from '../fn/security-expert-controller/get-all-security-experts';
import { GetAllSecurityExperts$Params } from '../fn/security-expert-controller/get-all-security-experts';
import { getSecurityExpertCustomerInfos } from '../fn/security-expert-controller/get-security-expert-customer-infos';
import { GetSecurityExpertCustomerInfos$Params } from '../fn/security-expert-controller/get-security-expert-customer-infos';
import { ResponseSecurityExpertDto } from '../models/response-security-expert-dto';
import { SecurityExpertsCustomerInformationDto } from '../models/security-experts-customer-information-dto';
import { setSecurityExperts } from '../fn/security-expert-controller/set-security-experts';
import { SetSecurityExperts$Params } from '../fn/security-expert-controller/set-security-experts';
import { setSecurityExpertsMultiple } from '../fn/security-expert-controller/set-security-experts-multiple';
import { SetSecurityExpertsMultiple$Params } from '../fn/security-expert-controller/set-security-experts-multiple';


/**
 * Provides operations for security experts that are managing the account of a customer.
 */
@Injectable({ providedIn: 'root' })
export class SecurityExpertControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `setSecurityExperts()` */
  static readonly SetSecurityExpertsPath = '/security-experts/{securityExpertId}/assign';

  /**
   * Assign an account managing security expert to a list of customers.
   *
   * This can only be done by sufficiently authorized users.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setSecurityExperts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSecurityExperts$Response(params: SetSecurityExperts$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setSecurityExperts(this.http, this.rootUrl, params, context);
  }

  /**
   * Assign an account managing security expert to a list of customers.
   *
   * This can only be done by sufficiently authorized users.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setSecurityExperts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSecurityExperts(params: SetSecurityExperts$Params, context?: HttpContext): Observable<void> {
    return this.setSecurityExperts$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `setSecurityExpertsMultiple()` */
  static readonly SetSecurityExpertsMultiplePath = '/security-experts/assign-multiple';

  /**
   * Assign two account managing security experts to a list of customers for each.
   *
   * This can only be done by sufficiently authorized users.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setSecurityExpertsMultiple()` instead.
   *
   * This method doesn't expect any request body.
   */
  setSecurityExpertsMultiple$Response(params: SetSecurityExpertsMultiple$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setSecurityExpertsMultiple(this.http, this.rootUrl, params, context);
  }

  /**
   * Assign two account managing security experts to a list of customers for each.
   *
   * This can only be done by sufficiently authorized users.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setSecurityExpertsMultiple$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setSecurityExpertsMultiple(params: SetSecurityExpertsMultiple$Params, context?: HttpContext): Observable<void> {
    return this.setSecurityExpertsMultiple$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAllSecurityExperts()` */
  static readonly GetAllSecurityExpertsPath = '/security-experts';

  /**
   * Get all security experts.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSecurityExperts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSecurityExperts$Response(params?: GetAllSecurityExperts$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSecurityExpertDto>> {
    return getAllSecurityExperts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all security experts.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSecurityExperts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSecurityExperts(params?: GetAllSecurityExperts$Params, context?: HttpContext): Observable<ResponseSecurityExpertDto> {
    return this.getAllSecurityExperts$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSecurityExpertDto>): ResponseSecurityExpertDto => r.body)
    );
  }

  /** Path part for operation `getSecurityExpertCustomerInfos()` */
  static readonly GetSecurityExpertCustomerInfosPath = '/security-experts/{id}/customer-infos';

  /**
   * Get additional information about customers supervised by a security expert.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSecurityExpertCustomerInfos()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSecurityExpertCustomerInfos$Response(params: GetSecurityExpertCustomerInfos$Params, context?: HttpContext): Observable<StrictHttpResponse<SecurityExpertsCustomerInformationDto>> {
    return getSecurityExpertCustomerInfos(this.http, this.rootUrl, params, context);
  }

  /**
   * Get additional information about customers supervised by a security expert.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSecurityExpertCustomerInfos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSecurityExpertCustomerInfos(params: GetSecurityExpertCustomerInfos$Params, context?: HttpContext): Observable<SecurityExpertsCustomerInformationDto> {
    return this.getSecurityExpertCustomerInfos$Response(params, context).pipe(
      map((r: StrictHttpResponse<SecurityExpertsCustomerInformationDto>): SecurityExpertsCustomerInformationDto => r.body)
    );
  }

  /** Path part for operation `getAllActiveSecurityExperts()` */
  static readonly GetAllActiveSecurityExpertsPath = '/public/security-experts';

  /**
   * Get all security experts. This endpoint is ignoring the auth logic and is able to return all security experts regardless who is requesting.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllActiveSecurityExperts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllActiveSecurityExperts$Response(params?: GetAllActiveSecurityExperts$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSecurityExpertDto>> {
    return getAllActiveSecurityExperts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all security experts. This endpoint is ignoring the auth logic and is able to return all security experts regardless who is requesting.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllActiveSecurityExperts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllActiveSecurityExperts(params?: GetAllActiveSecurityExperts$Params, context?: HttpContext): Observable<ResponseSecurityExpertDto> {
    return this.getAllActiveSecurityExperts$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSecurityExpertDto>): ResponseSecurityExpertDto => r.body)
    );
  }

}
