import { Injectable } from '@angular/core';
import { SafetyControllerService, SafetyTypeDto } from '@mysvg/api/financial-guarantees';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SafetyTypesStoreService extends RepositoryStoreService<SafetyTypeDto[]> {
	constructor(private safetyControllerService: SafetyControllerService) {
		super();
	}

	fetchData(): Observable<SafetyTypeDto[]> {
		return this.safetyControllerService.getSafetyTypes();
	}
}
