import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs/operators';

import { setVisibility } from '@mysvg/utils';
import { ContextState } from '../enums/context-state.enum';
import { AsiContext } from '../models/asi-context.model';
import { AsiContextService } from '../services/asi-context.service';

@UntilDestroy()
@Directive({ selector: '[svgFrontendsAsiForStates]' })
export class AsiContextStateDirective implements OnInit {
	@Input() svgFrontendsAsiForStates: ContextState[];

	constructor(
		private asiContextService: AsiContextService,
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef,
	) {}

	ngOnInit(): void {
		this.asiContextService
			.getAsiContext()
			.pipe(
				map((asiContext: AsiContext) => this.svgFrontendsAsiForStates.some((state: ContextState) => state === asiContext.state)),
				filter((isAccessGranted: boolean) => isAccessGranted !== this.viewContainerRef.length > 0),
				untilDestroyed(this),
			)
			.subscribe((isAccessGranted: boolean) => setVisibility(isAccessGranted, this.viewContainerRef, this.templateRef));
	}
}
