import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TaskDto } from '@mysvg/api/bpm';
import dayjs, { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MessagesWidgetConfig } from '../models/messages-widget-config.model';
import { Resp } from '../models/response/resp.model';

export const MESSAGE_PARAMS = {
	limit: { key: 'limit', value: 10 },
	sortBy: { key: 'sortBy', value: 'created' },
	sortOrder: { key: 'sortOrder', value: 'DESC' },
};

const created = { key: 'created' };
const msgType = { key: 'type', value: '0,1,4' };

@Injectable({ providedIn: 'root' })
export class MsgRepoService {
	private static getDefaultParams(httpParams?: HttpParams): HttpParams {
		let params: HttpParams = httpParams || new HttpParams();
		params = params.set(MESSAGE_PARAMS.limit.key, MESSAGE_PARAMS.limit.value.toString());
		params = params.set(MESSAGE_PARAMS.sortBy.key, MESSAGE_PARAMS.sortBy.value);
		params = params.set(MESSAGE_PARAMS.sortOrder.key, MESSAGE_PARAMS.sortOrder.value);
		return params;
	}

	private static setDateFilter(params: HttpParams, lastMessage: TaskDto): HttpParams {
		const dateString = MsgRepoService.getDateStringFrom(lastMessage);

		if (dateString && dateString.length) {
			const now = MsgRepoService.getDateString();
			const range = dateString + '/' + now;
			params = params.set(created.key, range);
		}

		return params;
	}

	private static getDateStringFrom(latestMessage: TaskDto): string {
		if (latestMessage && latestMessage.created) {
			const momentObj = dayjs(latestMessage.created);
			return MsgRepoService.getDateString(momentObj);
		} else {
			return null;
		}
	}

	private static getDateString(momentObj = dayjs()): string {
		momentObj = MsgRepoService.addMillisecond(momentObj);
		return momentObj.toISOString();
	}

	private static addMillisecond(momentObj: Dayjs, ms: number = 1): Dayjs {
		return momentObj.add(ms, 'ms');
	}

	constructor(private httpClient: HttpClient) {}

	// Cannot be moved to MessagesRepositoryService, because of undocumented HttpParams
	getLastMessages(config: MessagesWidgetConfig, url: string): Observable<TaskDto[]> {
		const params = this.createHttpParams(config);
		return this.requestMessages(params, url);
	}

	// Cannot be moved to MessagesRepositoryService, because of undocumented HttpParams
	getMessagesNewerThan(lastMessage: TaskDto, config: MessagesWidgetConfig, url: string): Observable<TaskDto[]> {
		let params = this.createHttpParams(config);
		params = MsgRepoService.setDateFilter(params, lastMessage);
		return this.requestMessages(params, url);
	}

	private createHttpParams(config: MessagesWidgetConfig): HttpParams {
		const defaultParams: HttpParams = MsgRepoService.getDefaultParams();
		const params: HttpParams = defaultParams.set(msgType.key, msgType.value);
		// partly overwrite default values
		return this.createHttpParamsFromConfig(config, params);
	}

	private createHttpParamsFromConfig(config: MessagesWidgetConfig, httpParams?: HttpParams): HttpParams {
		let params: HttpParams = httpParams || new HttpParams();
		Object.keys(config).forEach((key) => (params = params.set(key, (config as any)[key])));
		return params;
	}

	private requestMessages(params: HttpParams, url: string): Observable<TaskDto[]> {
		const opts = { params };
		return this.httpClient.get<Resp<TaskDto>>(url, opts).pipe(map((res) => res.data));
	}
}
