import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserNamePipe } from './pipes/user-name/user-name.pipe';

@NgModule({
	declarations: [UserNamePipe],
	exports: [UserNamePipe],
	imports: [CommonModule],
})
export class AuthenticationModule {}
