/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BoxDiscountView } from '../models/box-discount-view';
import { DiscountView } from '../models/discount-view';
import { getDiscounts } from '../fn/discount/get-discounts';
import { GetDiscounts$Params } from '../fn/discount/get-discounts';
import { getDiscountsForBox } from '../fn/discount/get-discounts-for-box';
import { GetDiscountsForBox$Params } from '../fn/discount/get-discounts-for-box';
import { postDiscountsForBox } from '../fn/discount/post-discounts-for-box';
import { PostDiscountsForBox$Params } from '../fn/discount/post-discounts-for-box';


/**
 * Discount operations
 */
@Injectable({ providedIn: 'root' })
export class DiscountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDiscountsForBox()` */
  static readonly GetDiscountsForBoxPath = '/boxes/{id}/discounts';

  /**
   * Returns a list of all discounts for one box and shows whether they are activated or not.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscountsForBox()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountsForBox$Response(params: GetDiscountsForBox$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BoxDiscountView>>> {
    return getDiscountsForBox(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a list of all discounts for one box and shows whether they are activated or not.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscountsForBox$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountsForBox(params: GetDiscountsForBox$Params, context?: HttpContext): Observable<Array<BoxDiscountView>> {
    return this.getDiscountsForBox$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BoxDiscountView>>): Array<BoxDiscountView> => r.body)
    );
  }

  /** Path part for operation `postDiscountsForBox()` */
  static readonly PostDiscountsForBoxPath = '/boxes/{id}/discounts';

  /**
   * creating the discounts for one box. adding the discount programs to the card.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDiscountsForBox()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDiscountsForBox$Response(params: PostDiscountsForBox$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BoxDiscountView>>> {
    return postDiscountsForBox(this.http, this.rootUrl, params, context);
  }

  /**
   * creating the discounts for one box. adding the discount programs to the card.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postDiscountsForBox$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDiscountsForBox(params: PostDiscountsForBox$Params, context?: HttpContext): Observable<Array<BoxDiscountView>> {
    return this.postDiscountsForBox$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BoxDiscountView>>): Array<BoxDiscountView> => r.body)
    );
  }

  /** Path part for operation `getDiscounts()` */
  static readonly GetDiscountsPath = '/discounts';

  /**
   * Returns a list of all discounts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscounts$Response(params?: GetDiscounts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DiscountView>>> {
    return getDiscounts(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a list of all discounts
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscounts(params?: GetDiscounts$Params, context?: HttpContext): Observable<Array<DiscountView>> {
    return this.getDiscounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DiscountView>>): Array<DiscountView> => r.body)
    );
  }

}
