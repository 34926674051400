import { Inject, Injectable } from '@angular/core';
import { Logger } from '@nsalaun/ng-logger';
import * as Sentry from '@sentry/angular-ivy';
import { filter, take } from 'rxjs/operators';

import { EnrichedError, ErrorHandlingService } from '@svg-frontends/error';
import { AsiContext, AsiContextService, ContextState, SvgContext, SvgContextService } from '@svg-frontends/session';

import { APPLICATION_NAME, APP_VERSION_TOKEN, ApplicationNamesEnum, STAGE_TOKEN, StagesEnum } from '@svg/environment';

@Injectable({ providedIn: 'root' })
export class LoggingService {
	constructor(
		@Inject(APPLICATION_NAME) private applicationName: string,
		@Inject(APP_VERSION_TOKEN) private appVersion: string,
		@Inject(STAGE_TOKEN) private stage: StagesEnum,
		private asiContextService: AsiContextService,
		private svgContextService: SvgContextService,
		private errorHandlerService: ErrorHandlingService,
		private logger: Logger,
	) {}

	/**
	 * Sentry's captureException function only captures an Error
	 * to capture an HttpErrorResponse, use captureMessage (HttpErrorResponse has no stack)
	 */
	static sendViaSentry(enrichedError: EnrichedError): void {
		Sentry.setExtra('displayMessage', enrichedError?.displayMessages?.join(', ') || undefined);
		Sentry.captureException(enrichedError.error);
	}

	init(): void {
		this.initContextChange();
		this.initErrorLogging();
	}

	private initContextChange(): void {
		if (this.applicationName === ApplicationNamesEnum.myASI) {
			this.asiContextService
				.getAsiContext()
				.pipe(
					// filter to catch cases on initial login where this is called before login
					// prevent initializing sentry with missing data
					filter((context) => context.state !== ContextState.NOT_LOGGED_IN),
					// take only first meaningful logged in context then kill subscription
					// sentry.configureScope() only calls parameter function once anyway
					take(1),
				)
				.subscribe((asiContext: AsiContext) => this.handleAsiContextChange(asiContext));
		} else {
			this.svgContextService
				.getSvgContext()
				.pipe(
					// see comment for myasi above
					filter((context) => context.state !== ContextState.NOT_LOGGED_IN),
					take(1),
				)
				.subscribe((svgContext: SvgContext) => this.handleSvgContextChange(svgContext));
		}
	}

	private handleAsiContextChange(asiContext: AsiContext): void {
		Sentry.configureScope((scope: Sentry.Scope) => {
			scope.setTags({
				build: this.appVersion.split('-')[1],
			});

			// user info and customer info must always be set - on logout for example it must be nulled
			scope.setUser({
				email: asiContext.email,
				family_name: asiContext.lastName,
				given_name: asiContext.firstName,
				name: asiContext.userName,
				preferred_username: asiContext.userName,
				sub: asiContext.id,
			});

			Sentry.setContext('company', {
				id: asiContext.customerInfo?.id,
				keycloakPath: asiContext.customerInfo?.customerNumber,
				name: asiContext.customerInfo?.displayName,
				svgId: asiContext.customerInfo?.asiSvgInfoDto.svg,
			});
		});
	}

	private handleSvgContextChange(svgContext: SvgContext): void {
		Sentry.configureScope((scope: Sentry.Scope) => {
			scope.setTags({
				build: this.appVersion.split('-')[1],
			});

			// user info and customer info must always be set - on logout for example it must be nulled
			scope.setUser({
				email: svgContext.user?.email,
				family_name: svgContext.user?.family_name,
				given_name: svgContext.user?.given_name,
				name: svgContext.user?.name,
				preferred_username: svgContext.user?.preferred_username,
				sub: svgContext.user?.sub,
			});

			Sentry.setContext('company', {
				id: svgContext.customerInfo?.id,
				keycloakPath: svgContext.customerInfo?.customerNumber,
				name: svgContext.customerInfo?.companyName,
				svgId: svgContext.customerInfo?.svgId,
			});
		});
	}

	private initErrorLogging(): void {
		this.errorHandlerService
			.getErrorObservable()
			.pipe(filter((enrichedError: EnrichedError) => enrichedError.reportSentry))
			.subscribe((enrichedError: EnrichedError) => this.logError(enrichedError));
	}

	/**
	 * log error to console and to sentry
	 */
	private logError(enrichedError: EnrichedError): void {
		LoggingService.sendViaSentry(enrichedError);

		const displayMessages = enrichedError.displayMessages.join(' ');
		if (this.stage === StagesEnum.TESTING || this.stage === StagesEnum.DEVOPS) {
			const message = `${displayMessages} (${enrichedError.error.message})`;
			this.logger.error(message);
			this.logger.error(enrichedError.error);
		} else {
			this.logger.error(displayMessages);
		}
	}
}
