import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ControlError } from '@svg-frontends/error';

@Pipe({ name: 'firstControlError' })
export class FirstControlErrorPipe implements PipeTransform {
	transform(validationErrors: ValidationErrors | null): ControlError | null {
		if (validationErrors) {
			const controlErrors: ControlError[] = Object.entries(validationErrors).map(([key, value]: [string, any]) => ({ key, value }));
			return controlErrors && controlErrors.length > 0 ? controlErrors[0] : { key: 'default' };
		} else {
			return null;
		}
	}
}
