import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, first, mergeMap } from 'rxjs/operators';

import { isDefined } from '@mysvg/utils';

import { DataWrapper } from '../models/data-wrapper.model';

@Injectable({ providedIn: 'root' })
export abstract class ParametrizedRepositoryStore2Service<T, U> {
	private valueMap = new Map<string, BehaviorSubject<DataWrapper<T>>>();

	abstract fetchData(key: U): Observable<T>;

	abstract keyToString(key: U): string;

	getFirst(key: U): Observable<T> {
		if (!this.isStored(key)) {
			this.update(key);
		}

		return this.get(key).pipe(
			filter((dataWrapper: DataWrapper<T>) => !dataWrapper?.loading),
			mergeMap((dataWrapper: DataWrapper<T>) => {
				if (dataWrapper.error) {
					return throwError(dataWrapper.error);
				} else {
					return of(dataWrapper.value);
				}
			}),
			first(),
		);
	}

	getData(key: U): Observable<DataWrapper<T>> {
		if (!this.isStored(key)) {
			this.update(key);
		}

		return this.get(key).asObservable();
	}

	update(key: U): void {
		const currentValueOfKeysObservable: DataWrapper<T> = this.get(key)?.value;
		if (!currentValueOfKeysObservable) {
			// before fetch create new observable with loading true
			this.valueMap.set(
				this.keyToString(key),
				new BehaviorSubject<DataWrapper<T>>({
					loading: true,
					value: null,
					error: null,
				}),
			);
		} else {
			// before fetch set loading true
			this.get(key).next({ loading: true, value: currentValueOfKeysObservable.value });
		}

		this.fetchData(key)
			.pipe(first())
			.subscribe({
				next: (value: T) => this.get(key).next({ loading: false, value }),
				error: (error: Error) => this.get(key).next({ error, loading: false }),
			});
	}

	/**
	 * a value is stored if the behavior subject is found
	 * no loading in progress
	 * and no error set
	 */
	private isStored(key: U): boolean {
		const currentValueOfKeysObservable: DataWrapper<T> = this.get(key)?.value;
		return isDefined(currentValueOfKeysObservable) && !currentValueOfKeysObservable.loading && !currentValueOfKeysObservable.error;
	}

	private get(key: U): BehaviorSubject<DataWrapper<T>> {
		return this.valueMap.get(this.keyToString(key));
	}
}
