/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CustomerTypeProcessingGroupDto } from '../models/customer-type-processing-group-dto';
import { getAllCustomerTypes } from '../fn/customer-type-controller/get-all-customer-types';
import { GetAllCustomerTypes$Params } from '../fn/customer-type-controller/get-all-customer-types';
import { getCustomersTypeProcessingGroupsMetaInformation } from '../fn/customer-type-controller/get-customers-type-processing-groups-meta-information';
import { GetCustomersTypeProcessingGroupsMetaInformation$Params } from '../fn/customer-type-controller/get-customers-type-processing-groups-meta-information';
import { ResponseCustomerTypeDto } from '../models/response-customer-type-dto';

@Injectable({ providedIn: 'root' })
export class CustomerTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCustomersTypeProcessingGroupsMetaInformation()` */
  static readonly GetCustomersTypeProcessingGroupsMetaInformationPath = '/customer/{portalUid}/processing-groups/{processingGroupId}';

  /**
   * Returns customer type processing group metadata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomersTypeProcessingGroupsMetaInformation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersTypeProcessingGroupsMetaInformation$Response(params: GetCustomersTypeProcessingGroupsMetaInformation$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerTypeProcessingGroupDto>> {
    return getCustomersTypeProcessingGroupsMetaInformation(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns customer type processing group metadata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomersTypeProcessingGroupsMetaInformation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersTypeProcessingGroupsMetaInformation(params: GetCustomersTypeProcessingGroupsMetaInformation$Params, context?: HttpContext): Observable<CustomerTypeProcessingGroupDto> {
    return this.getCustomersTypeProcessingGroupsMetaInformation$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerTypeProcessingGroupDto>): CustomerTypeProcessingGroupDto => r.body)
    );
  }

  /** Path part for operation `getAllCustomerTypes()` */
  static readonly GetAllCustomerTypesPath = '/customer/types';

  /**
   * List all available customer types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCustomerTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCustomerTypes$Response(params?: GetAllCustomerTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseCustomerTypeDto>> {
    return getAllCustomerTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * List all available customer types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllCustomerTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCustomerTypes(params?: GetAllCustomerTypes$Params, context?: HttpContext): Observable<ResponseCustomerTypeDto> {
    return this.getAllCustomerTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseCustomerTypeDto>): ResponseCustomerTypeDto => r.body)
    );
  }

}
