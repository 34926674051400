/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { SvgDocumentControllerService } from './services/svg-document-controller.service';
import { RiskParameterControllerService } from './services/risk-parameter-controller.service';
import { ZipCode2SvgControllerService } from './services/zip-code-2-svg-controller.service';
import { MySvgControllerService } from './services/my-svg-controller.service';
import { SvgControllerService } from './services/svg-controller.service';
import { PublicControllerService } from './services/public-controller.service';
import { BusinessSectorControllerService } from './services/business-sector-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    SvgDocumentControllerService,
    RiskParameterControllerService,
    ZipCode2SvgControllerService,
    MySvgControllerService,
    SvgControllerService,
    PublicControllerService,
    BusinessSectorControllerService,
    ApiConfiguration
  ],
})
export class SvgsModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<SvgsModule> {
    return {
      ngModule: SvgsModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: SvgsModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('SvgsModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
