/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getRiskTypes } from '../fn/risk-controller/get-risk-types';
import { GetRiskTypes$Params } from '../fn/risk-controller/get-risk-types';
import { RiskTypeDto } from '../models/risk-type-dto';

@Injectable({ providedIn: 'root' })
export class RiskControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRiskTypes()` */
  static readonly GetRiskTypesPath = '/risks/types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRiskTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRiskTypes$Response(params?: GetRiskTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RiskTypeDto>>> {
    return getRiskTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRiskTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRiskTypes(params?: GetRiskTypes$Params, context?: HttpContext): Observable<Array<RiskTypeDto>> {
    return this.getRiskTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RiskTypeDto>>): Array<RiskTypeDto> => r.body)
    );
  }

}
