/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getPassageListResponse } from '../fn/passage-list-bo-controller/get-passage-list-response';
import { GetPassageListResponse$Params } from '../fn/passage-list-bo-controller/get-passage-list-response';
import { ResponsePassageListDto } from '../models/response-passage-list-dto';

@Injectable({ providedIn: 'root' })
export class PassageListBoControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPassageListResponse()` */
  static readonly GetPassageListResponsePath = '/passageList';

  /**
   * Select passage list of customers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPassageListResponse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPassageListResponse$Response(params: GetPassageListResponse$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponsePassageListDto>> {
    return getPassageListResponse(this.http, this.rootUrl, params, context);
  }

  /**
   * Select passage list of customers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPassageListResponse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPassageListResponse(params: GetPassageListResponse$Params, context?: HttpContext): Observable<ResponsePassageListDto> {
    return this.getPassageListResponse$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponsePassageListDto>): ResponsePassageListDto => r.body)
    );
  }

}
