import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { StagesEnum } from '@svg/environment';
import { ENVIRONMENT } from './environments/environment';

if (ENVIRONMENT.name != StagesEnum.TESTING) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
