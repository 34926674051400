import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class WindowTabManagmentService {
	/**
	 * Creates a random unique number to be used as window name in PWA mode
	 * @returns A string containing 10 digits
	 */
	private createUniqueWindowName(): string {
		return window.crypto.getRandomValues(new Uint32Array(1)).toString();
	}

	/**
	 * Checks if is running in PWA mode
	 */
	public isPWA(): boolean {
		return window.matchMedia('(display-mode: minimal-ui)').matches;
	}

	/**
	 * Creates a new tab for browser based apps or new window if running as PWA
	 * @param url URL to open in new tab
	 * @returns newly opened tab
	 */
	public createNewTab(url: string = ''): Window {
		if (this.isPWA()) {
			const uniqueWindowName = this.createUniqueWindowName();
			// pwa needs explicit size (current size)
			return window.open(url, uniqueWindowName, `width=${window.innerWidth},height=${window.innerHeight}`);
		} else {
			return window.open(url, '_blank');
		}
	}
}
