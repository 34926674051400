/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { liveness } from '../fn/health/liveness';
import { Liveness$Params } from '../fn/health/liveness';
import { readiness } from '../fn/health/readiness';
import { Readiness$Params } from '../fn/health/readiness';
import { version } from '../fn/health/version';
import { Version$Params } from '../fn/health/version';

@Injectable({ providedIn: 'root' })
export class HealthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `liveness()` */
  static readonly LivenessPath = '/live';

  /**
   * liveness probe.
   *
   * liveness probe
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `liveness()` instead.
   *
   * This method doesn't expect any request body.
   */
  liveness$Response(params?: Liveness$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return liveness(this.http, this.rootUrl, params, context);
  }

  /**
   * liveness probe.
   *
   * liveness probe
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `liveness$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  liveness(params?: Liveness$Params, context?: HttpContext): Observable<void> {
    return this.liveness$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `readiness()` */
  static readonly ReadinessPath = '/ready';

  /**
   * readiness probe.
   *
   * readiness probe
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readiness()` instead.
   *
   * This method doesn't expect any request body.
   */
  readiness$Response(params?: Readiness$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return readiness(this.http, this.rootUrl, params, context);
  }

  /**
   * readiness probe.
   *
   * readiness probe
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readiness$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readiness(params?: Readiness$Params, context?: HttpContext): Observable<void> {
    return this.readiness$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `version()` */
  static readonly VersionPath = '/version';

  /**
   * returns version.
   *
   * returns the deployment version of this service
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `version()` instead.
   *
   * This method doesn't expect any request body.
   */
  version$Response(params?: Version$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return version(this.http, this.rootUrl, params, context);
  }

  /**
   * returns version.
   *
   * returns the deployment version of this service
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `version$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  version(params?: Version$Params, context?: HttpContext): Observable<any> {
    return this.version$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
