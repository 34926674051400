import { Pipe, PipeTransform } from '@angular/core';

import { getDeepPropertyValue } from '../utils/object.utils';

export enum SortDirection {
	ASCENDING,
	DESCENDING,
}

@Pipe({ name: 'sortStringProperty' })
export class SortStringPropertyPipe implements PipeTransform {
	transform<T>(values: T[], propertyNames: string[], sortDirection: SortDirection = SortDirection.ASCENDING): T[] {
		return (
			values?.sort((v1: T, v2: T) => {
				const propertyValue1: string = getDeepPropertyValue(v1, propertyNames)?.toString();
				const propertyValue2: string = getDeepPropertyValue(v2, propertyNames)?.toString();

				if (sortDirection === SortDirection.ASCENDING) {
					return propertyValue1?.localeCompare(propertyValue2) || 0;
				} else if (sortDirection === SortDirection.DESCENDING) {
					return propertyValue2?.localeCompare(propertyValue1) || 0;
				} else {
					return 0;
				}
			}) || values
		);
	}
}
