/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActualConditionSetDto } from '../models/actual-condition-set-dto';
import { createActualConditions } from '../fn/toll-conditions-controller/create-actual-conditions';
import { CreateActualConditions$Params } from '../fn/toll-conditions-controller/create-actual-conditions';
import { deleteActualConditions } from '../fn/toll-conditions-controller/delete-actual-conditions';
import { DeleteActualConditions$Params } from '../fn/toll-conditions-controller/delete-actual-conditions';
import { resetActualConditionsToStandardConditions } from '../fn/toll-conditions-controller/reset-actual-conditions-to-standard-conditions';
import { ResetActualConditionsToStandardConditions$Params } from '../fn/toll-conditions-controller/reset-actual-conditions-to-standard-conditions';
import { updateActualConditions } from '../fn/toll-conditions-controller/update-actual-conditions';
import { UpdateActualConditions$Params } from '../fn/toll-conditions-controller/update-actual-conditions';

@Injectable({ providedIn: 'root' })
export class TollConditionsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateActualConditions()` */
  static readonly UpdateActualConditionsPath = '/toll-conditions/condition-set/{runtimeGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateActualConditions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActualConditions$Response(params: UpdateActualConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<ActualConditionSetDto>> {
    return updateActualConditions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateActualConditions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActualConditions(params: UpdateActualConditions$Params, context?: HttpContext): Observable<ActualConditionSetDto> {
    return this.updateActualConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActualConditionSetDto>): ActualConditionSetDto => r.body)
    );
  }

  /** Path part for operation `deleteActualConditions()` */
  static readonly DeleteActualConditionsPath = '/toll-conditions/condition-set/{runtimeGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteActualConditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteActualConditions$Response(params: DeleteActualConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteActualConditions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteActualConditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteActualConditions(params: DeleteActualConditions$Params, context?: HttpContext): Observable<void> {
    return this.deleteActualConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createActualConditions()` */
  static readonly CreateActualConditionsPath = '/toll-conditions/condition-set';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createActualConditions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createActualConditions$Response(params: CreateActualConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<ActualConditionSetDto>> {
    return createActualConditions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createActualConditions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createActualConditions(params: CreateActualConditions$Params, context?: HttpContext): Observable<ActualConditionSetDto> {
    return this.createActualConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActualConditionSetDto>): ActualConditionSetDto => r.body)
    );
  }

  /** Path part for operation `resetActualConditionsToStandardConditions()` */
  static readonly ResetActualConditionsToStandardConditionsPath = '/toll-conditions/condition-set/{runtimeGroupId}/terminate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetActualConditionsToStandardConditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetActualConditionsToStandardConditions$Response(params: ResetActualConditionsToStandardConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resetActualConditionsToStandardConditions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetActualConditionsToStandardConditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetActualConditionsToStandardConditions(params: ResetActualConditionsToStandardConditions$Params, context?: HttpContext): Observable<void> {
    return this.resetActualConditionsToStandardConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
