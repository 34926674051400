import { ChangeDetectionStrategy, Component, Input, OnChanges, TemplateRef } from '@angular/core';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-string-or-template-ref',
	styleUrls: ['./string-or-template-ref.component.scss'],
	templateUrl: './string-or-template-ref.component.html',
})
export class StringOrTemplateRefComponent implements OnChanges {
	ref: TemplateRef<any> | undefined;
	str: string | undefined;

	@Input() value: string | TemplateRef<any>;

	ngOnChanges(): void {
		if (this.value) {
			if (typeof this.value === 'string') {
				this.str = this.value;
			} else {
				this.ref = this.value;
			}
		}
	}
}
