import { Injectable } from '@angular/core';
import { EInvoicingStatusControllerService, EInvoicingStatusDto } from '@mysvg/api/payment';
import { SvgContextService } from '@svg-frontends/session';
import { RepositoryStoreService } from '../repository-store.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EInvoicingStatusStoreService extends RepositoryStoreService<EInvoicingStatusDto> {
	constructor(private eInvoicingStatusControllerService: EInvoicingStatusControllerService, private svgContextService: SvgContextService) {
		super();
	}

	fetchData(): Observable<EInvoicingStatusDto> {
		return this.svgContextService
			.getFirstPortalUid()
			.pipe(mergeMap((portalUid: string) => this.eInvoicingStatusControllerService.getEInvoicingStatus({ portalUid })));
	}
}
