import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NetworkConnectionState } from '../enums/network-connection-state';

export const WINDOW_EVENTS = {
	OFFLINE: 'offline',
	ONLINE: 'online',
};

@Injectable({ providedIn: 'root' })
export class NetworkConnectionService {
	private networkStatus$ = new BehaviorSubject<NetworkConnectionState>(NetworkConnectionState.ONLINE);

	constructor() {
		fromEvent(window, WINDOW_EVENTS.ONLINE).subscribe(() => this.networkStatus$.next(NetworkConnectionState.ONLINE));
		fromEvent(window, WINDOW_EVENTS.OFFLINE).subscribe(() => this.networkStatus$.next(NetworkConnectionState.OFFLINE));
	}

	isOffline(): boolean {
		return this.networkStatus$.value === NetworkConnectionState.OFFLINE;
	}

	isOnline(): boolean {
		return this.networkStatus$.value === NetworkConnectionState.ONLINE;
	}

	isOnline$(): Observable<boolean> {
		return this.getNetworkState().pipe(map((state: NetworkConnectionState) => state === NetworkConnectionState.ONLINE));
	}

	isOffline$(): Observable<boolean> {
		return this.getNetworkState().pipe(map((state: NetworkConnectionState) => state === NetworkConnectionState.OFFLINE));
	}

	getNetworkState(): Observable<NetworkConnectionState> {
		return this.networkStatus$.asObservable();
	}
}
