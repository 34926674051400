import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { Meta } from '@mysvg/utils';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-table-footer-meta',
	styleUrls: ['./table-footer-meta.component.scss'],
	templateUrl: './table-footer-meta.component.html',
})
export class TableFooterMetaComponent implements OnChanges {
	@Input() meta: Meta;

	isValid: boolean;

	ngOnChanges(): void {
		this.isValid = this.meta && Object.keys(this.meta).length > 0;
	}
}
