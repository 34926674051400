import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
	ResponseSecurityExpertDto,
	SecurityExpertControllerService,
	SecurityExpertDto,
	SecurityExpertsAssignmentFormDto,
	SecurityExpertsCustomerInformationDto,
} from '@myasi/api/customers/security_experts';

import { AsiApiBaseUrls } from '@mysvg/utils';
import { TypeAheadSearchParams, TypeAheadSearchServiceInterface } from '@svg-frontends/type-ahead/core';

import { API_BASE_URLS_TOKEN } from '@svg/environment';

import { GetAllSecurityExpertsParams } from './all-security-experts-repository.service';

@Injectable({ providedIn: 'root' })
export class SecurityExpertsRepositoryService implements TypeAheadSearchServiceInterface<SecurityExpertDto> {
	constructor(
		@Inject(API_BASE_URLS_TOKEN) private baseApiUrls: AsiApiBaseUrls,
		private httpClient: HttpClient,
		private securityExpertControllerService: SecurityExpertControllerService,
	) {}

	getMinimal(params: GetAllSecurityExpertsParams): Observable<ResponseSecurityExpertDto> {
		// this endpoint returns all sifas filtered by users svg
		return this.securityExpertControllerService.getAllSecurityExperts(params);
	}

	/**
	 * TypeAheadSearchServiceInterface<MinimalCustomer>
	 */
	getByReference(reference: string): Observable<ResponseSecurityExpertDto> {
		return this.httpClient.get<ResponseSecurityExpertDto>(`${this.baseApiUrls.customers}${reference}`);
	}

	/**
	 * TypeAheadSearchServiceInterface<MinimalCustomer>
	 */
	getWithParams(params: TypeAheadSearchParams): Observable<ResponseSecurityExpertDto> {
		return this.getMinimal({ query: params.search, limit: params.limit, offset: params.offset });
	}

	assignSifaToCustomers(customerIds: number[], securityExpertId: number): Observable<void> {
		const securityExpertsAssignmentFormDto: SecurityExpertsAssignmentFormDto = { customerIds: customerIds };
		return this.securityExpertControllerService.setSecurityExperts({
			securityExpertId: securityExpertId,
			body: securityExpertsAssignmentFormDto,
		});
	}

	getCustomerInformation(id: number): Observable<SecurityExpertsCustomerInformationDto> {
		return this.securityExpertControllerService.getSecurityExpertCustomerInfos({ id });
	}
}
