export enum CustomerDocumentType {
	NOT_SPECIFIED = 0,
	CERTIFICATE_OF_REGISTRATION = 1,
	BUSINESS_REGISTRATION = 2,
	EETS_APPLICATION = 3,
	EETS_ORDER = 4,
	EETS_TERMS = 5,
	EETS_PRICE_LIST = 6,
	E_INVOICING_CONSENT = 7,
	SEPA_MANDATE = 8,
	TELEMATIC_ORDER = 22,
}

export const parseCustomerDocumentTypeFromNumber = (documentTypeId: number): CustomerDocumentType | null => {
	switch (documentTypeId) {
		case 0:
			return CustomerDocumentType.NOT_SPECIFIED;
		case 1:
			return CustomerDocumentType.CERTIFICATE_OF_REGISTRATION;
		case 2:
			return CustomerDocumentType.BUSINESS_REGISTRATION;
		case 3:
			return CustomerDocumentType.EETS_APPLICATION;
		case 4:
			return CustomerDocumentType.EETS_ORDER;
		case 5:
			return CustomerDocumentType.EETS_TERMS;
		case 6:
			return CustomerDocumentType.EETS_PRICE_LIST;
		case 7:
			return CustomerDocumentType.E_INVOICING_CONSENT;
		case 8:
			return CustomerDocumentType.SEPA_MANDATE;
		case 22:
			return CustomerDocumentType.TELEMATIC_ORDER;
		default:
			return null;
	}
};
