import { Injectable } from '@angular/core';
import { PriceListControllerService, VignettePriceList } from '@mysvg/api/vignette';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, finalize } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EVignettePriceListStoreService {
	private isLoading$ = new BehaviorSubject<boolean>(false);
	private vignettePriceLists$ = new BehaviorSubject<VignettePriceList[]>(undefined);

	constructor(private priceListControllerService: PriceListControllerService) {}

	/**
	 * loads processes, which are pushed to observable of store
	 */
	load(): void {
		this.isLoading$.next(true);

		this.priceListControllerService
			.getVignettePriceLists()
			.pipe(finalize(() => this.isLoading$.next(false)))
			.subscribe((vignettePriceLists: VignettePriceList[]) => this.vignettePriceLists$.next(vignettePriceLists));
	}

	/**
	 * loads messages, if not yet loaded
	 */
	get(): Observable<VignettePriceList[]> {
		if (!this.vignettePriceLists$.value) {
			this.load();
		}

		// skip first `undefined` of behaviour subject
		return this.vignettePriceLists$.asObservable().pipe(
			filter((vignettePriceLists: VignettePriceList[]) => !!vignettePriceLists),
			distinctUntilChanged(),
		);
	}

	getIsLoading(): Observable<boolean> {
		return this.isLoading$.asObservable();
	}
}
