/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createPaymentModalities } from '../fn/payment-modalities-controller/create-payment-modalities';
import { CreatePaymentModalities$Params } from '../fn/payment-modalities-controller/create-payment-modalities';
import { deletePaymentModalities } from '../fn/payment-modalities-controller/delete-payment-modalities';
import { DeletePaymentModalities$Params } from '../fn/payment-modalities-controller/delete-payment-modalities';
import { getPaymentModalities } from '../fn/payment-modalities-controller/get-payment-modalities';
import { GetPaymentModalities$Params } from '../fn/payment-modalities-controller/get-payment-modalities';
import { PaymentModalitiesDto } from '../models/payment-modalities-dto';
import { ResponsePaymentModalitiesDto } from '../models/response-payment-modalities-dto';
import { updatePaymentModalities } from '../fn/payment-modalities-controller/update-payment-modalities';
import { UpdatePaymentModalities$Params } from '../fn/payment-modalities-controller/update-payment-modalities';

@Injectable({ providedIn: 'root' })
export class PaymentModalitiesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updatePaymentModalities()` */
  static readonly UpdatePaymentModalitiesPath = '/paymentmodalities/{id}';

  /**
   * Update a payment modalities object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePaymentModalities()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePaymentModalities$Response(params: UpdatePaymentModalities$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentModalitiesDto>> {
    return updatePaymentModalities(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a payment modalities object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePaymentModalities$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePaymentModalities(params: UpdatePaymentModalities$Params, context?: HttpContext): Observable<PaymentModalitiesDto> {
    return this.updatePaymentModalities$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentModalitiesDto>): PaymentModalitiesDto => r.body)
    );
  }

  /** Path part for operation `deletePaymentModalities()` */
  static readonly DeletePaymentModalitiesPath = '/paymentmodalities/{id}';

  /**
   * Delete a payment modalities object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePaymentModalities()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePaymentModalities$Response(params: DeletePaymentModalities$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentModalitiesDto>> {
    return deletePaymentModalities(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a payment modalities object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePaymentModalities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePaymentModalities(params: DeletePaymentModalities$Params, context?: HttpContext): Observable<PaymentModalitiesDto> {
    return this.deletePaymentModalities$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentModalitiesDto>): PaymentModalitiesDto => r.body)
    );
  }

  /** Path part for operation `getPaymentModalities()` */
  static readonly GetPaymentModalitiesPath = '/paymentmodalities';

  /**
   * Get a list of payment modalities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentModalities()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentModalities$Response(params: GetPaymentModalities$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponsePaymentModalitiesDto>> {
    return getPaymentModalities(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of payment modalities
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaymentModalities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentModalities(params: GetPaymentModalities$Params, context?: HttpContext): Observable<ResponsePaymentModalitiesDto> {
    return this.getPaymentModalities$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponsePaymentModalitiesDto>): ResponsePaymentModalitiesDto => r.body)
    );
  }

  /** Path part for operation `createPaymentModalities()` */
  static readonly CreatePaymentModalitiesPath = '/paymentmodalities';

  /**
   * Create a payment modalities entry
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPaymentModalities()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPaymentModalities$Response(params: CreatePaymentModalities$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentModalitiesDto>> {
    return createPaymentModalities(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a payment modalities entry
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPaymentModalities$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPaymentModalities(params: CreatePaymentModalities$Params, context?: HttpContext): Observable<PaymentModalitiesDto> {
    return this.createPaymentModalities$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentModalitiesDto>): PaymentModalitiesDto => r.body)
    );
  }

}
