import { HttpErrorResponse } from '@angular/common/http';

import { isDefined } from '@mysvg/utils';
import { ProblemBody } from '../../models/problem-body.model';

export const ENRICHED_ERROR_PLACEHOLDER = '%value%';
export const ERROR_RESPONSE_CONTENT_TYPE_KEY = 'content-type';
export const ERROR_RESPONSE_CONTENT_TYPE_VALUE = 'application/problem+json';

export interface ErrorMessagesMapping {
	[key: string]: string;
}

export interface EnrichedErrorMessagesMapping {
	[key: string]: { txt: string; keys: string[] };
}

export abstract class ErrorMessageFactoryService {
	messages: ErrorMessagesMapping = {
		1014: 'Ihr Browser unterstützt nicht die Storage Api, bitte kontaktieren Sie Ihren Ansprechpartner',
		22: 'Ihr Browser unterstützt nicht die Storage Api, bitte kontaktieren Sie Ihren Ansprechpartner',
		400: 'Etwas ist schief gelaufen, bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Ansprechpartner!',
		401: 'Sie sind nicht berechtigt diese Aktion auszuführen.',
		403: 'Sie sind nicht berechtigt, diese Inhalte zu laden. Bitte kontaktieren Sie Ihren Ansprechpartner oder Administrator.',
		406: 'Die Eingabe wird vom Server nicht akzeptiert. Bitte kontaktieren Sie Ihren Ansprechpartner.',
		409: 'Es gab einen Konflikt. Eine Eingabe ist bereits vergeben.',
		500: 'Ein unerwarteter Fehler ist aufgetreten, bitte kontaktieren Sie Ihren Ansprechpartner',
		dashboardSaveError: 'Es ist ein Fehler aufgetreten. Die Änderungen konnten nicht gespeichert werden.',
		resetDashboard:
			'Möchten Sie dieses Dashboard auf Standardeinstellungen zurücksetzen?</br>Das Zurücksetzen wird nicht automatisch gespeichert und kann im Nachhinein abgebrochen werden.',
		saveDashboard: 'Möchten Sie die Änderungen an diesem Dashboard speichern?',
		softResetDashboard: 'Möchten Sie die Änderungen verwerfen?',
		unsupportedApplicationName: 'Anwendungsname wird nicht unterstützt.',
		deleteWidget: 'Möchten Sie dieses Widget wirklich löschen?',
		deleteWidgetDueSpaceLimitations:
			'Aufgrund von Platzmangel konnte das Widget Ihrem Dashboard leider nicht hinzugefügt werden.</br> Bitte löschen oder verschieben Sie vorhandene Widgets, um Platz für neue zu schaffen.', // This is more like an info
		offline: 'Offline: Es besteht keine Internetverbindung.',
		'Timeout has occurred': 'Es hat zu lange gedauert. Bitte versuchen Sie es erneut.',
		default: 'Ein unerwarteter Fehler ist aufgetreten, bitte kontaktieren Sie Ihren Ansprechpartner',
	};
	enrichedMessages: EnrichedErrorMessagesMapping = {
		403: { txt: `Fehlende Authorisierung (${ENRICHED_ERROR_PLACEHOLDER})`, keys: ['activity'] },
		409: { txt: `Diese Felder sind bereits vergeben: ${ENRICHED_ERROR_PLACEHOLDER}`, keys: [] },
		404: { txt: `${ENRICHED_ERROR_PLACEHOLDER} konnte nicht gefunden werden.`, keys: [] },
		default: { txt: 'Es ist ein Fehler aufgetreten - bitte prüfen Sie Ihre Eingaben.', keys: [] },
	};

	protected constructor(messages: ErrorMessagesMapping = {}, enrichedMessages: EnrichedErrorMessagesMapping = {}) {
		this.messages = { ...this.messages, ...messages };
		this.enrichedMessages = { ...this.enrichedMessages, ...enrichedMessages };
	}

	static hasProblemHeader(errorResponse: HttpErrorResponse): boolean {
		return (
			errorResponse.headers.has(ERROR_RESPONSE_CONTENT_TYPE_KEY) &&
			errorResponse.headers.get(ERROR_RESPONSE_CONTENT_TYPE_KEY) === ERROR_RESPONSE_CONTENT_TYPE_VALUE
		);
	}

	private static getKeyFrom(error: Error | string): string | null {
		if (typeof error === 'string') {
			return error;
		} else {
			// return string or null
			return error?.message?.toString() || error?.name?.toString() || null;
		}
	}

	getMessageForError(error: Error): string[] {
		if (error instanceof HttpErrorResponse && ErrorMessageFactoryService.hasProblemHeader(error)) {
			const problem: ProblemBody = typeof error?.error === 'string' ? JSON.parse(error.error) : error.error;
			return this.handleProblemBody(problem);
		} else if (error instanceof HttpErrorResponse) {
			return [this.getMessageForKey(error?.status?.toString())];
		} else {
			return [this.getMessageForKey(ErrorMessageFactoryService.getKeyFrom(error))];
		}
	}

	/**
	 * [NOTE] if no key provided use default, if a provided key does not match any result use default
	 */
	getMessageForKey(messageKey: string = 'default'): string {
		// return somehow does not allow || operator
		return this.messages[messageKey] || this.messages['default'];
	}

	getEnrichedMessage(messageKey: string, obj: any): string {
		const complexMsg = this.enrichedMessages[messageKey] || this.enrichedMessages['default'];
		const objectValues: string[] = complexMsg.keys
			.filter((keyOfObject: any) => Object.prototype.hasOwnProperty.call(obj, keyOfObject) && !!obj[keyOfObject])
			.map((keyOfObject: any) => `${obj[keyOfObject]}`);

		return complexMsg.txt.replace(ENRICHED_ERROR_PLACEHOLDER, objectValues.length > 0 ? objectValues.join(' ') : JSON.stringify(obj));
	}

	handleProblemBody(problem: ProblemBody): string[] {
		// optionally add cause messages recursively (feign client exceptions)
		const messages = this.collectCauseDisplayMessagesRecursively(problem);

		if (messages.length === 0) {
			return [this.getMessageForKey(problem.status?.toString())];
		} else {
			return messages;
		}
	}

	private collectCauseDisplayMessagesRecursively(problem: ProblemBody): string[] {
		const messages = [];
		do {
			if (problem?.displayMessage) {
				messages.push(problem.displayMessage);
			}
			if (problem?.violations) {
				problem.violations.forEach((v) => messages.push(v.message));
			}
			problem = problem.cause;
		} while (isDefined(problem));
		return messages;
	}
}
