import dayjs from 'dayjs';
import de from 'dayjs/locale/de';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

const LT = 'HH:mm';
const L = 'DD.MM.YYYY';
const locale = {
	...de,
	calendar: {
		lastDay: `[Gestern um] ${LT}`,
		lastWeek: `[Letzter] dddd [um] ${LT}`,
		nextDay: `[Morgen um] ${LT}`,
		nextWeek: `dddd [um] ${LT}`,
		sameDay: `[Heute um] ${LT}`,
		sameElse: L,
	},
};

dayjs.locale(locale);
dayjs.extend(calendar);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);
dayjs.extend(dayOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
