import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

import { CookieService, isDefined } from '@mysvg/utils';
import { StorageService } from './storage.service';

@Injectable()
export class ContentSchedulerHelperService {
	private clicksCount = new BehaviorSubject<number>(null);
	private expirationIsoDate: string;
	private isNotExpired = false;
	private maxClicks: number;
	private storageKey: string;

	constructor(private cookieService: CookieService, private storageService: StorageService) {}

	init(maxClicks: number, expirationIsoDate: string, storageKey: string): void {
		this.maxClicks = maxClicks;
		this.storageKey = storageKey;
		this.expirationIsoDate = expirationIsoDate;
		this.isNotExpired = expirationIsoDate ? dayjs(expirationIsoDate).isAfter(dayjs()) : true;

		this.getInitialClicks();
	}

	getTrigger(): Observable<boolean> {
		return this.getFilteredClicksCountObservable().pipe(
			tap((clicks: number) => this.updateClicksInStorage(clicks)),
			map((clicks: number) => clicks < this.maxClicks && this.isNotExpired),
			distinctUntilChanged(),
		);
	}

	/**
	 * [CAUTION] only triggers if some schedule content directive is initialized
	 */
	trigger(): void {
		const updatedClicks = this.clicksCount.value + 1;
		this.clicksCount.next(updatedClicks);
	}

	private getFilteredClicksCountObservable(): Observable<number> {
		return this.clicksCount.pipe(filter((clicks: number) => isDefined(clicks)));
	}

	private getInitialClicks(): void {
		try {
			const clicksCount = Math.max(this.getClicksFromStorage(), this.getClicksFromCookie());
			this.clicksCount.next(clicksCount);
		} catch (e) {
			this.clicksCount.next(0);
		}
	}

	private getClicksFromStorage(): number {
		try {
			const fromStorage = this.storageService.get<number>(this.storageKey);
			return this.parseToNumber(fromStorage);
		} catch (e) {
			return 0;
		}
	}

	private getClicksFromCookie(): number {
		try {
			const cookieValue = this.cookieService.get(this.storageKey);
			return this.parseToNumber(cookieValue);
		} catch (e) {
			return 0;
		}
	}

	private parseToNumber(value: any): number {
		const parsed = parseInt(value, 10);
		return isDefined(parsed) && !Number.isNaN(parsed) ? parsed : 0;
	}

	private updateClicksInStorage(clicksCount: number): void {
		this.storageService.set(this.storageKey, clicksCount, true);
		this.cookieService.set(this.storageKey, clicksCount.toString(), this.expirationIsoDate);
	}
}
