import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';

import { DialogLevel } from '../../enums/dialog-level.enum';
import { DialogSize } from '../../enums/dialog-size.enum';

export interface InfoDialogData {
	body: SafeHtml;
	title: string;

	level?: DialogLevel;
	size?: DialogSize;
	submitButton?: string;
}

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-info-dialog',
	styleUrls: ['./info-dialog.component.scss'],
	templateUrl: './info-dialog.component.html',
})
export class InfoDialogComponent {
	DIALOG_LEVEL = DialogLevel;
	DIALOG_SIZE = DialogSize;

	constructor(@Inject(MAT_DIALOG_DATA) public inputArgs: InfoDialogData, private matDialogRef: MatDialogRef<InfoDialogComponent>) {}

	closeDialog(): void {
		this.matDialogRef.close();
	}
}
