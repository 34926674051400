/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { SafetyControllerService } from './services/safety-controller.service';
import { RvControllerService } from './services/rv-controller.service';
import { PartnerNumberControllerService } from './services/partner-number-controller.service';
import { IncidentControllerService } from './services/incident-controller.service';
import { RebookingControllerService } from './services/rebooking-controller.service';
import { GuaranteesControllerService } from './services/guarantees-controller.service';
import { CrefoOrderControllerService } from './services/crefo-order-controller.service';
import { CrefoMonitoringControllerService } from './services/crefo-monitoring-controller.service';
import { CrefoControllerService } from './services/crefo-controller.service';
import { SuggestionControllerService } from './services/suggestion-controller.service';
import { MySvgControllerService } from './services/my-svg-controller.service';
import { SafetyRiskControllerService } from './services/safety-risk-controller.service';
import { SvgSafetyContractControllerService } from './services/svg-safety-contract-controller.service';
import { CrefoDocumentsControllerService } from './services/crefo-documents-controller.service';
import { RiskControllerService } from './services/risk-controller.service';
import { MailboxDirectoryControllerService } from './services/mailbox-directory-controller.service';
import { IncidentNotificationControllerService } from './services/incident-notification-controller.service';
import { CrefoVersionControllerService } from './services/crefo-version-controller.service';
import { CrefoReportControllerService } from './services/crefo-report-controller.service';
import { BookingsControllerService } from './services/bookings-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    SafetyControllerService,
    RvControllerService,
    PartnerNumberControllerService,
    IncidentControllerService,
    RebookingControllerService,
    GuaranteesControllerService,
    CrefoOrderControllerService,
    CrefoMonitoringControllerService,
    CrefoControllerService,
    SuggestionControllerService,
    MySvgControllerService,
    SafetyRiskControllerService,
    SvgSafetyContractControllerService,
    CrefoDocumentsControllerService,
    RiskControllerService,
    MailboxDirectoryControllerService,
    IncidentNotificationControllerService,
    CrefoVersionControllerService,
    CrefoReportControllerService,
    BookingsControllerService,
    ApiConfiguration
  ],
})
export class FinancialGuaranteesModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<FinancialGuaranteesModule> {
    return {
      ngModule: FinancialGuaranteesModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: FinancialGuaranteesModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('FinancialGuaranteesModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
