/**
 * [CAUTION] some actions are sent by backend (depending on selected box), those do have ids, all other actions will get custom ids over 100
 */
export enum BoxAction {
	// actions sent by backend with ids
	READY_TO_ORDER = 2,
	LOCK = 6,
	UNLOCK = 16,
	RETURN = 19,
	RESCIND = 20,
	REPLACE_WITHOUT_RETURN = 22,
	REPLACE_WITH_RETURN = 23,
	ACKNOWLEDGED = 32,
}
