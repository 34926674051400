import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { NgSelectWrapperModule, UtilsModule } from '@mysvg/utils';

import { TypeAheadSearchComponent } from './components/type-ahead-search/type-ahead-search.component';

const DECLARABLES = [TypeAheadSearchComponent];

@NgModule({
	declarations: [DECLARABLES],
	exports: [DECLARABLES],
	imports: [CommonModule, FormsModule, NgSelectModule, UtilsModule, NgSelectWrapperModule],
})
export class TypeAheadCoreModule {}
