import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-page-not-found',
	styleUrls: ['./page-not-found.component.scss'],
	templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent implements OnInit {
	routerUrl: string;

	constructor(private router: Router) {}

	ngOnInit(): void {
		this.routerUrl = this.router.url;
	}
}
