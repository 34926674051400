import { Pipe, PipeTransform } from '@angular/core';

import { isDefined } from '../../utils/general.utils';

@Pipe({ name: 'isDefined' })
export class IsDefinedPipe implements PipeTransform {
	transform(value: any): boolean {
		return isDefined(value);
	}
}
