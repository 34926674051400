/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteDashboard } from '../fn/dashboard-config-controller/delete-dashboard';
import { DeleteDashboard$Params } from '../fn/dashboard-config-controller/delete-dashboard';
import { getDashboard } from '../fn/dashboard-config-controller/get-dashboard';
import { GetDashboard$Params } from '../fn/dashboard-config-controller/get-dashboard';
import { saveDashboard } from '../fn/dashboard-config-controller/save-dashboard';
import { SaveDashboard$Params } from '../fn/dashboard-config-controller/save-dashboard';
import { SvgResponseTblDashboardDto } from '../models/svg-response-tbl-dashboard-dto';

@Injectable({ providedIn: 'root' })
export class DashboardConfigControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDashboard()` */
  static readonly GetDashboardPath = '/dashboard';

  /**
   * Get dashboard entities out of the database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDashboard()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDashboard$Response(params?: GetDashboard$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgResponseTblDashboardDto>> {
    return getDashboard(this.http, this.rootUrl, params, context);
  }

  /**
   * Get dashboard entities out of the database
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDashboard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDashboard(params?: GetDashboard$Params, context?: HttpContext): Observable<SvgResponseTblDashboardDto> {
    return this.getDashboard$Response(params, context).pipe(
      map((r: StrictHttpResponse<SvgResponseTblDashboardDto>): SvgResponseTblDashboardDto => r.body)
    );
  }

  /** Path part for operation `saveDashboard()` */
  static readonly SaveDashboardPath = '/dashboard';

  /**
   * Save a dashboard into the database.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveDashboard()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveDashboard$Response(params: SaveDashboard$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgResponseTblDashboardDto>> {
    return saveDashboard(this.http, this.rootUrl, params, context);
  }

  /**
   * Save a dashboard into the database.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveDashboard$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveDashboard(params: SaveDashboard$Params, context?: HttpContext): Observable<SvgResponseTblDashboardDto> {
    return this.saveDashboard$Response(params, context).pipe(
      map((r: StrictHttpResponse<SvgResponseTblDashboardDto>): SvgResponseTblDashboardDto => r.body)
    );
  }

  /** Path part for operation `deleteDashboard()` */
  static readonly DeleteDashboardPath = '/dashboard/{dashboardId}';

  /**
   * Delete dashboard entities in the database
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDashboard()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDashboard$Response(params: DeleteDashboard$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteDashboard(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete dashboard entities in the database
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDashboard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDashboard(params: DeleteDashboard$Params, context?: HttpContext): Observable<void> {
    return this.deleteDashboard$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
