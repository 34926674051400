import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ContextState } from '../enums/context-state.enum';
import { SvgContext } from '../models/svg-context.model';
import { SvgContextService } from '../services/svg-context.service';

@Injectable({ providedIn: 'root' })
export class StaffAtCustomerContextGuard implements CanActivate {
	constructor(private svgContextService: SvgContextService, private router: Router) {}

	canActivate(): Observable<UrlTree | boolean> {
		return this.svgContextService.getFirstSvgContext().pipe(
			map((svgContext: SvgContext) => {
				if (svgContext.state === ContextState.STAFF_CUSTOMER) {
					return true;
				} else {
					return this.router.createUrlTree(['dashboard']);
				}
			}),
		);
	}
}
