/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DebitingDetailsDto } from '../models/debiting-details-dto';
import { updateDebitingDetails } from '../fn/debiting-controller/update-debiting-details';
import { UpdateDebitingDetails$Params } from '../fn/debiting-controller/update-debiting-details';

@Injectable({ providedIn: 'root' })
export class DebitingControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateDebitingDetails()` */
  static readonly UpdateDebitingDetailsPath = '/debitingDetails/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDebitingDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDebitingDetails$Response(params: UpdateDebitingDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<DebitingDetailsDto>> {
    return updateDebitingDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDebitingDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDebitingDetails(params: UpdateDebitingDetails$Params, context?: HttpContext): Observable<DebitingDetailsDto> {
    return this.updateDebitingDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<DebitingDetailsDto>): DebitingDetailsDto => r.body)
    );
  }

}
