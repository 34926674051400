import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'tableViewPlaceholder' })
export class TableViewPlaceholderPipe implements PipeTransform {
	transform(itemName: string): string {
		if (itemName) {
			return `Es wurden keine ${itemName} gefunden!`;
		} else {
			return '-';
		}
	}
}
