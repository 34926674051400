/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getTemporaryConditionReporting } from '../fn/condition-report-controller/get-temporary-condition-reporting';
import { GetTemporaryConditionReporting$Params } from '../fn/condition-report-controller/get-temporary-condition-reporting';
import { ResponseCustomerConditionReportDto } from '../models/response-customer-condition-report-dto';

@Injectable({ providedIn: 'root' })
export class ConditionReportControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTemporaryConditionReporting()` */
  static readonly GetTemporaryConditionReportingPath = '/condition-report/temporary';

  /**
   * Get customer temporary condition reporting
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTemporaryConditionReporting()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemporaryConditionReporting$Response(params?: GetTemporaryConditionReporting$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseCustomerConditionReportDto>> {
    return getTemporaryConditionReporting(this.http, this.rootUrl, params, context);
  }

  /**
   * Get customer temporary condition reporting
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTemporaryConditionReporting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemporaryConditionReporting(params?: GetTemporaryConditionReporting$Params, context?: HttpContext): Observable<ResponseCustomerConditionReportDto> {
    return this.getTemporaryConditionReporting$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseCustomerConditionReportDto>): ResponseCustomerConditionReportDto => r.body)
    );
  }

}
