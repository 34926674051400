import { Injectable } from '@angular/core';
import { CustomerDocumentDirectoryControllerService, DocumentDirectoryMetaDto } from '@mysvg/api/pia';
import { SvgContextService } from '@svg-frontends/session';
import { RepositoryStoreService } from './repository-store.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SafetyDocumentDirectoryStoreService extends RepositoryStoreService<DocumentDirectoryMetaDto[]> {
	constructor(
		private customerDocumentDirectoryControllerService: CustomerDocumentDirectoryControllerService,
		private svgContextService: SvgContextService,
	) {
		super();
	}

	fetchData(): Observable<DocumentDirectoryMetaDto[]> {
		return this.svgContextService
			.getFirstPortalUid()
			.pipe(mergeMap((portalUid: string) => this.customerDocumentDirectoryControllerService.getSafetyDocumentDirectoryMeta({ portalUid })));
	}
}
