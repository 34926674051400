import { Directive, Host, OnInit, Optional } from '@angular/core';
import dayjs from 'dayjs';

import { isDefined } from '@mysvg/utils';
import { DatePickerFormControlComponent } from '../components/date-picker-form-control/date-picker-form-control.component';

// This filters the selectable dates of the DatePickerFormControlComponent

@Directive({ selector: '[svgFrontendsDatePickerAccountingDateFilter]' })
export class DatePickerAccountingDateFilterDirective implements OnInit {
	constructor(@Optional() @Host() private host: DatePickerFormControlComponent) {}

	ngOnInit(): void {
		if (isDefined(this.host)) {
			this.host.dateFilter = this.dateFilter;
		}
	}

	private dateFilter: (date: string | null) => boolean = (date: string | null) => {
		if (date === null) {
			return false;
		} else {
			const expirationData = dayjs(date);
			return expirationData.date() === 8 || expirationData.date() === 23;
		}
	};
}
