/**
 * The color variables need to be in sync with src/assets/styles/imports/clr-color-vars.scss
 * These variables should only be used to configure custom color variables in src/app/utils/enums/colors.enum.ts
 */
export enum ClrColors {
	RED_0 = '#f52f22',
	RED_1 = '#a32100',
	RED_2 = '#c92100',
	RED_3 = '#e12200',
	RED_4 = '#f52f22',
	RED_5 = '#f54f47',
	RED_6 = '#f76f6c',
	RED_7 = '#f89997',
	RED_8 = '#f8b7b6',
	RED_9 = '#f5dbd9',
	RED_10 = '#fff0ee',

	PINK_0 = '#f1428a',
	PINK_1 = '#9b0d54',
	PINK_2 = '#b0105b',
	PINK_3 = '#c41261',
	PINK_4 = '#d91468',
	PINK_5 = '#ed186f',
	PINK_6 = '#f1428a',
	PINK_7 = '#f46ca5',
	PINK_8 = '#f897bf',
	PINK_9 = '#fbc1da',
	PINK_10 = '#ffebf5',

	PURPLE_0 = '#781da0',
	PURPLE_1 = '#4d007a',
	PURPLE_2 = '#660092',
	PURPLE_3 = '#781da0',
	PURPLE_4 = '#8939ad',
	PURPLE_5 = '#9b56bb',
	PURPLE_6 = '#ad73c8',
	PURPLE_7 = '#be90d6',
	PURPLE_8 = '#d0ace4',
	PURPLE_9 = '#e1c9f1',
	PURPLE_10 = '#f3e6ff',

	ULTRAMARINE_0 = '#343dac',
	ULTRAMARINE_1 = '#0f1e82',
	ULTRAMARINE_2 = '#1a23a0',
	ULTRAMARINE_3 = '#343dac',
	ULTRAMARINE_4 = '#4e56b8',
	ULTRAMARINE_5 = '#6870c4',
	ULTRAMARINE_6 = '#838acf',
	ULTRAMARINE_7 = '#9da3db',
	ULTRAMARINE_8 = '#b7bde7',
	ULTRAMARINE_9 = '#d1d6f3',
	ULTRAMARINE_10 = '#ebf0ff',

	BLUE_0 = '#0065ab',
	BLUE_1 = '#003d79',
	BLUE_2 = '#004d8a',
	BLUE_3 = '#0065ab',
	BLUE_4 = '#1976d2',
	BLUE_4_OLD = '#0079b8',
	BLUE_5 = '#0095d3',
	BLUE_6 = '#49afd9',
	BLUE_7 = '#89cbdf',
	BLUE_8 = '#a6d8e7',
	BLUE_9 = '#c5e5ef',
	BLUE_10 = '#e1f1f6',

	CYAN_0 = '#00b7d6',
	CYAN_1 = '#004a70',
	CYAN_2 = '#005680',
	CYAN_3 = '#006690',
	CYAN_4 = '#0081a7',
	CYAN_5 = '#009cbf',
	CYAN_6 = '#00b7d6',
	CYAN_7 = '#36c9e1',
	CYAN_8 = '#6ddbeb',
	CYAN_9 = '#a3edf6',
	CYAN_10 = '#ccfbff',

	TEAL_0 = '#00968b',
	TEAL_1 = '#006668',
	TEAL_2 = '#007e7a',
	TEAL_3 = '#00968b',
	TEAL_4 = '#00ab9a',
	TEAL_5 = '#00bfa9',
	TEAL_6 = '#00d4b8',
	TEAL_7 = '#38dfc8',
	TEAL_8 = '#6fead9',
	TEAL_9 = '#a7f4e9',
	TEAL_10 = '#defff9',

	GREEN_0 = '#48960c',
	GREEN_1 = '#1d5100',
	GREEN_2 = '#266900',
	GREEN_3 = '#2f8400',
	GREEN_4 = '#48960c',
	GREEN_5 = '#62a420',
	GREEN_6 = '#60b515',
	GREEN_7 = '#85c81a',
	GREEN_8 = '#aadb1e',
	GREEN_9 = '#c7e59c',
	GREEN_10 = '#dff0d0',

	YELLOW_0 = '#fdcf08',
	YELLOW_1 = '#c47d00',
	YELLOW_2 = '#d28f00',
	YELLOW_3 = '#dfa100',
	YELLOW_4 = '#edb200',
	YELLOW_5 = '#fac400',
	YELLOW_6 = '#fdd006',
	YELLOW_7 = '#fdcf08',
	YELLOW_8 = '#ffe860',
	YELLOW_9 = '#fef3b5',
	YELLOW_10 = '#fffce8',

	ORANGE_0 = '#f57600',
	ORANGE_1 = '#aa4500',
	ORANGE_2 = '#c25400',
	ORANGE_3 = '#d36000',
	ORANGE_4 = '#e46c00',
	ORANGE_5 = '#f57600',
	ORANGE_6 = '#ff8400',
	ORANGE_7 = '#ff9c32',
	ORANGE_8 = '#ffb565',
	ORANGE_9 = '#ffcd97',
	ORANGE_10 = '#ffe5c9',

	RED_ORANGE_0 = '#ee4a08',
	RED_ORANGE_1 = '#cd3517',
	RED_ORANGE_2 = '#de400f',
	RED_ORANGE_3 = '#ee4a08',
	RED_ORANGE_4 = '#ff5500',
	RED_ORANGE_5 = '#ff681c',
	RED_ORANGE_6 = '#ff8142',
	RED_ORANGE_7 = '#ff9a69',
	RED_ORANGE_8 = '#ffb38f',
	RED_ORANGE_9 = '#ffccb5',
	RED_ORANGE_10 = '#ffe5dc',

	WARM_GREY_0 = '#80746d',
	WARM_GREY_1 = '#5b4d47',
	WARM_GREY_2 = '#6c5f59',
	WARM_GREY_3 = '#80746d',
	WARM_GREY_4 = '#948981',
	WARM_GREY_5 = '#a89e95',
	WARM_GREY_6 = '#bbb3a9',
	WARM_GREY_7 = '#cfc8bd',
	WARM_GREY_8 = '#e3ddd1',
	WARM_GREY_9 = '#f4f1e6',
	WARM_GREY_10 = '#faf9f5',

	NEUTRAL_GREY_0 = '#737373',
	NEUTRAL_GREY_1 = '#313131',
	NEUTRAL_GREY_2 = '#444444',
	NEUTRAL_GREY_3 = '#565656',
	NEUTRAL_GREY_4 = '#737373',
	NEUTRAL_GREY_5 = '#9a9a9a',
	NEUTRAL_GREY_6 = '#cccccc',
	NEUTRAL_GREY_7 = '#dddddd',
	NEUTRAL_GREY_8 = '#eeeeee',
	NEUTRAL_GREY_9 = '#f2f2f2',
	NEUTRAL_GREY_10 = '#fafafa',

	COOL_GREY_0 = '#61717d',
	COOL_GREY_1 = '#25333d',
	COOL_GREY_2 = '#314351',
	COOL_GREY_3 = '#495a67',
	COOL_GREY_4 = '#61717d',
	COOL_GREY_5 = '#798893',
	COOL_GREY_6 = '#919fa8',
	COOL_GREY_7 = '#a9b6be',
	COOL_GREY_8 = '#c1cdd4',
	COOL_GREY_9 = '#d9e4ea',
	COOL_GREY_10 = '#f3f6fa',

	BLACK = '#000000',
	WHITE = '#ffffff',
}
