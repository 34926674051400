/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ContactPersonRoleDto } from '../models/contact-person-role-dto';
import { listRolesInternal } from '../fn/role-controller/list-roles-internal';
import { ListRolesInternal$Params } from '../fn/role-controller/list-roles-internal';
import { listRolesOperating } from '../fn/role-controller/list-roles-operating';
import { ListRolesOperating$Params } from '../fn/role-controller/list-roles-operating';

@Injectable({ providedIn: 'root' })
export class RoleControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listRolesOperating()` */
  static readonly ListRolesOperatingPath = '/roles/operating';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listRolesOperating()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRolesOperating$Response(params?: ListRolesOperating$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContactPersonRoleDto>>> {
    return listRolesOperating(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listRolesOperating$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRolesOperating(params?: ListRolesOperating$Params, context?: HttpContext): Observable<Array<ContactPersonRoleDto>> {
    return this.listRolesOperating$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactPersonRoleDto>>): Array<ContactPersonRoleDto> => r.body)
    );
  }

  /** Path part for operation `listRolesInternal()` */
  static readonly ListRolesInternalPath = '/roles/internal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listRolesInternal()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRolesInternal$Response(params?: ListRolesInternal$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContactPersonRoleDto>>> {
    return listRolesInternal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listRolesInternal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRolesInternal(params?: ListRolesInternal$Params, context?: HttpContext): Observable<Array<ContactPersonRoleDto>> {
    return this.listRolesInternal$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactPersonRoleDto>>): Array<ContactPersonRoleDto> => r.body)
    );
  }

}
