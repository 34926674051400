import { Pipe, PipeTransform } from '@angular/core';

import { DocumentService } from '../services/document.service';

@Pipe({ name: 'svgsDownloadLinkFromHash' })
export class SvgsDownloadLinkFromHashPipe implements PipeTransform {
	constructor(private documentService: DocumentService) {}

	transform(downloadHash: string, svgId: string): string {
		return this.documentService.getPrivateEntityDocumentUrlFromHash('svgs', svgId, downloadHash);
	}
}
