/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseOrderResult } from '../../models/response-order-result';

export interface Orders$Params {

/**
 * the id of the customer
 */
  customers: string;

/**
 * the price of the orders
 */
  price?: string;

/**
 * the status of the orders
 */
  status?: string;

/**
 * the licensePlateNumber of the orders
 */
  licenseplateNumber?: string;

/**
 * the order date of the orders
 */
  timestamp?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function orders(http: HttpClient, rootUrl: string, params: Orders$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseOrderResult>> {
  const rb = new RequestBuilder(rootUrl, orders.PATH, 'get');
  if (params) {
    rb.query('customers', params.customers, {});
    rb.query('price', params.price, {});
    rb.query('status', params.status, {});
    rb.query('licenseplateNumber', params.licenseplateNumber, {});
    rb.query('timestamp', params.timestamp, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseOrderResult>;
    })
  );
}

orders.PATH = '/orders';
