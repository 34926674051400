/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getDocument } from '../fn/document-generator-controller/get-document';
import { GetDocument$Params } from '../fn/document-generator-controller/get-document';
import { mergeDocuments } from '../fn/document-generator-controller/merge-documents';
import { MergeDocuments$Params } from '../fn/document-generator-controller/merge-documents';

@Injectable({ providedIn: 'root' })
export class DocumentGeneratorControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `mergeDocuments()` */
  static readonly MergeDocumentsPath = '/merge';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mergeDocuments()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  mergeDocuments$Response(params?: MergeDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return mergeDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mergeDocuments$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  mergeDocuments(params?: MergeDocuments$Params, context?: HttpContext): Observable<Array<string>> {
    return this.mergeDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getDocument()` */
  static readonly GetDocumentPath = '/gendoc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocument()` instead.
   *
   * This method sends `application/json;charset=UTF-8` and handles request body of type `application/json;charset=UTF-8`.
   */
  getDocument$Response(params: GetDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocument$Response()` instead.
   *
   * This method sends `application/json;charset=UTF-8` and handles request body of type `application/json;charset=UTF-8`.
   */
  getDocument(params: GetDocument$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
