import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { AppendToOptions, MacAppendTo, Resp, ResponseDirectory } from '@mysvg/utils';

import { TypeAheadSearchServiceInterface } from '../../interfaces/type-ahead-search-service.interface';
import { TypeAheadSearchParams } from '../../models/type-ahead-search-params.model';

@UntilDestroy()
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-type-ahead-search',
	styleUrls: ['./type-ahead-search.component.scss'],
	templateUrl: './type-ahead-search.component.html',
})
export class TypeAheadSearchComponent<T> implements OnInit, AfterViewInit {
	isLoading = false;

	directory$: BehaviorSubject<ResponseDirectory | null> = new BehaviorSubject<ResponseDirectory | null>(null);
	fetchTrigger$: Subject<void> = new Subject<void>();
	items$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
	typeAhead$: BehaviorSubject<string> = new BehaviorSubject<string>('');

	// This can be used to specify additional params (f.e. svgId or portalUid) for the item request.
	@Input() additionalParams: any = {};
	@Input() appendTo: MacAppendTo = AppendToOptions.BODY;
	@Input() clearOnSelect = false;
	@Input() initialFocus = true;
	@Input() isClearable = true;
	@Input() itemTerm: string;
	@Input() labelTemplate: TemplateRef<any>;
	@Input() limit = 10;
	@Input() multiple = false;
	@Input() offset = 0;
	@Input() optionTemplate: TemplateRef<any>;
	@Input() preSelection?: T | T[];
	@Input() typeAheadSearchService: TypeAheadSearchServiceInterface<T>;
	@Input() formGroupInputStyle = false;
	/**
	 * Tell typeahead component to reload data it currently holds
	 */
	@Input() reloadTrigger$?: Observable<void>;

	@Output() multipleSelection: EventEmitter<T[]> = new EventEmitter<T[]>();
	@Output() selection: EventEmitter<T> = new EventEmitter<T>();
	@Output() submitSelection: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('select') select: NgSelectComponent;

	constructor(private changeDetectorRef: ChangeDetectorRef) {}

	// This needs to be here because of linting reasons
	// This input can be used to filter the returning items. This should used only in edge cases.
	@Input() filterOperator: (source: Observable<T[]>) => Observable<T[]> = (source: Observable<T[]>) => source;

	ngOnInit(): void {
		this.focusInput();
		this.setLoadingObservables();
		this.triggerInitialLoading();
	}

	ngAfterViewInit(): void {
		if (this.initialFocus) {
			this.focusInput();
		}
	}

	/**
	 * triggers selected$-output of this component
	 */
	submit(): void {
		this.submitSelection.emit();
	}

	handleSelection(selection: T | T[]): void {
		if (this.multiple) {
			this.multipleSelection.emit(selection as T[]);
		} else {
			this.selection.emit(selection as T);
		}

		if (!!selection && this.clearOnSelect) {
			this.select.clearModel();
		}
	}

	/**
	 * calls focus of ViewChild #select
	 * timeout is needed, because ng-select uses change detection strategy OnPush
	 * `ng-select.focus()` without timeout works for user interaction like click
	 */
	private focusInput(): void {
		if (!!this.select) {
			setTimeout(() => this.select.focus());
		}
	}

	private triggerInitialLoading(): void {
		this.typeAhead$.next('');
	}

	private setLoadingObservables(): void {
		// default loading observables (typing and fetchTrigger e.g. scrolling to bottom of list)
		const loadingObservables = [this.getTypeAheadObservable(), this.getFetchTriggerObservable()];

		// if reloadTrigger observable input exists then also listen for that
		if (this.reloadTrigger$) {
			loadingObservables.push(this.getReloadTriggerObservable());
		}

		merge(...loadingObservables)
			.pipe(
				this.filterOperator,
				tap(() => (this.isLoading = false)),
				tap(() => this.changeDetectorRef.markForCheck()),
				catchError(() => []),
				untilDestroyed(this),
			)
			.subscribe((items: T[]) => this.items$.next(items));
	}

	/**
	 * when fetchTrigger is triggered by scrollToEnd event of ng-select
	 * - set loading
	 * - map event to last set Directory
	 * - load users (as Resp-Obj with Directory)
	 * - update Directory, by new Directory form load users request
	 * - map response to T[]
	 * - map to concatenated T[] (all loaded Ts concatenated with new loaded Ts)
	 */
	private getFetchTriggerObservable(): Observable<T[]> {
		return this.fetchTrigger$.pipe(
			map(() => this.directory$.getValue()),
			filter((directory: ResponseDirectory | null) => !!directory && !!directory.next),
			tap(() => (this.isLoading = true)),
			tap(() => this.changeDetectorRef.markForCheck()),
			switchMap((dir: ResponseDirectory) => this.requestByReference(dir.next)),
			tap((response: Resp<T>) => this.directory$.next(response.directory)),
			map((response: Resp<T>) => response.data),
			map((items: T[]) => this.items$.getValue().concat(items)),
		);
	}

	private requestByReference(reference: string): Observable<Resp<T>> {
		return this.typeAheadSearchService.getByReference(reference);
	}

	/**
	 * when typeAhead is triggered by user input event of ng-select
	 * - debounce observable to not fire request while user is typing
	 * - only fire on distinct change
	 * - set loading
	 * - load users with typeAhead-value as filter / param
	 * - update Directory, by new Directory form load Ts request
	 * - map response to T[]
	 */
	private getTypeAheadObservable(): Observable<T[]> {
		return this.typeAhead$.pipe(
			debounceTime(500),
			distinctUntilChanged(),
			// `!!typeAhead` not working here, because `''` would be false
			filter((typeAhead: string) => typeAhead !== null && typeof typeAhead !== undefined),
			tap(() => (this.isLoading = true)),
			tap(() => this.changeDetectorRef.markForCheck()),
			map((typeAhead: string) => ({ limit: this.limit, offset: this.offset, search: typeAhead })),
			switchMap((params: TypeAheadSearchParams) => this.typeAheadSearchService.getWithParams(params, this.additionalParams)),
			tap((response: Resp<T>) => this.directory$.next(response.directory)),
			map((response: Resp<T>) => response.data),
		);
	}

	/**
	 * Listen for reload trigger observable
	 * (Is basically a copy of getTypeAheadObservable without debounce and distinctUntilChanged filtering)
	 */
	private getReloadTriggerObservable(): Observable<T[]> {
		return this.reloadTrigger$.pipe(
			mergeMap(() => this.typeAhead$),
			filter((typeAhead: string) => typeAhead !== null && typeof typeAhead !== undefined),
			tap(() => (this.isLoading = true)),
			tap(() => this.changeDetectorRef.markForCheck()),
			map((typeAhead: string) => ({ limit: this.limit, offset: this.offset, search: typeAhead })),
			switchMap((params: TypeAheadSearchParams) => this.typeAheadSearchService.getWithParams(params, this.additionalParams)),
			tap((response: Resp<T>) => this.directory$.next(response.directory)),
			map((response: Resp<T>) => response.data),
		);
	}
}
