import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FileTypeEnum, FileTypeInfo, FileTypeInfoService } from '@svg-frontends/file-upload';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-file-form-control-selected-file',
	styleUrls: ['./file-form-control-selected-file.component.scss'],
	templateUrl: './file-form-control-selected-file.component.html',
})
export class FileFormControlSelectedFileComponent implements OnChanges {
	FA_FILE = faFile;
	error: Error;
	fileTypeInfo: FileTypeInfo;

	@Input() file: File;
	@Input() hasRemoveOption: boolean;

	@Output() remove = new EventEmitter<void>();

	constructor(private fileTypeInfoService: FileTypeInfoService) {}

	ngOnChanges(): void {
		this.reset();
		const fileTypeKey: FileTypeEnum = this.fileTypeInfoService.getByType(this.file.type);
		this.fileTypeInfo = this.fileTypeInfoService.getFileTypeInfoFor(fileTypeKey);
	}

	reset(): void {
		this.error = undefined;
	}

	removeFile(): void {
		this.remove.emit();
	}
}
