<clr-main-container>
	<clr-header>
		<div class="branding">
			<a class="nav-link" routerLink="/">
				<img src="assets/img/headerlogo.png" height="100%" alt="logo" />
			</a>
		</div>
	</clr-header>

	<div class="container">
		<div class="clr-row clr-justify-content-center">
			<div class="clr-col-10">
				<div class="card">
					<div class="card-header">Applikation wurde mit einer neuen Version aktualisiert</div>
					<div class="card-block">
						<p>
							Um die App wieder fehlerfrei nutzen zu können, empfehlen wir Ihnen, die Seite neu zu laden. Tun Sie dies nicht können
							unerwartete Fehler auftreten und Seiten nicht verfügbar sein.
						</p>
					</div>
					<div class="card-footer">
						<button class="btn btn-sm btn-link" (click)="reload()">Neuladen</button>
						<button class="btn btn-sm btn-link" (click)="navigateToDefault()">Zurück</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</clr-main-container>
<div class="card-text"></div>
