import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TableViewOptionsService } from '../../services/table-view-options.service';
import { TableViewService } from '../../services/table-view.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-table-view-actions-bar',
	styleUrls: ['./table-view-actions-bar.component.scss'],
	templateUrl: './table-view-actions-bar.component.html',
})
export class TableViewActionsBarComponent {
	@Input() showCompactButton = true;
	@Input() showRefreshButton = true;
	@Input() showResetButton = true;

	@Output() resetFilters: EventEmitter<void> = new EventEmitter<void>();
	@Output() reload: EventEmitter<void> = new EventEmitter<void>();

	constructor(public tableViewOptionsService: TableViewOptionsService, public tableViewService: TableViewService<any, any>) {}

	resetAllFilters(): void {
		this.tableViewService.resetAllFilters();
		this.resetFilters.emit();
	}

	onReload(): void {
		this.tableViewService.simpleRefresh();
		this.reload.emit();
	}
}
