import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { EditModeService } from '../services/edit-mode.service';

@Injectable({ providedIn: 'root' })
export class LeaveEditModeGuard implements CanDeactivate<any> {
	constructor(private editModeService: EditModeService) {}

	canDeactivate(): boolean {
		this.editModeService.leaveEditMode();
		return true;
	}
}
