import { Injectable } from '@angular/core';
import { CrefoDocumentsControllerService, SafetyDocumentDto } from '@mysvg/api/financial-guarantees';
import { SafetyProductType } from '@mysvg/utils';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { SvgContextService } from '@svg-frontends/session';
import { ParametrizedRepositoryStoreService } from '../parametrized-repository-store.service';

@Injectable({ providedIn: 'root' })
export class CrefoDocumentsStoreService extends ParametrizedRepositoryStoreService<SafetyDocumentDto[], SafetyProductType> {
	constructor(private crefoDocumentsControllerService: CrefoDocumentsControllerService, private svgContextService: SvgContextService) {
		super();
	}

	fetchData(key: SafetyProductType): Observable<SafetyDocumentDto[]> {
		return this.svgContextService
			.getFirstPortalUid()
			.pipe(mergeMap((portalUid: string) => this.crefoDocumentsControllerService.getSafetyDocuments({ portalUid, productType: key })));
	}

	keyToString(key: SafetyProductType): string {
		return key;
	}
}
