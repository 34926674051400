/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createActivationCode } from '../fn/tc-registrations/create-activation-code';
import { CreateActivationCode$Params } from '../fn/tc-registrations/create-activation-code';
import { createRegistration } from '../fn/tc-registrations/create-registration';
import { CreateRegistration$Params } from '../fn/tc-registrations/create-registration';
import { getAllTcRegistrations } from '../fn/tc-registrations/get-all-tc-registrations';
import { GetAllTcRegistrations$Params } from '../fn/tc-registrations/get-all-tc-registrations';
import { getTcRegistrationsOfCustomer } from '../fn/tc-registrations/get-tc-registrations-of-customer';
import { GetTcRegistrationsOfCustomer$Params } from '../fn/tc-registrations/get-tc-registrations-of-customer';
import { lockRegistration } from '../fn/tc-registrations/lock-registration';
import { LockRegistration$Params } from '../fn/tc-registrations/lock-registration';
import { ResponseBodyTcRegistrationDto } from '../models/response-body-tc-registration-dto';
import { TcActivationCodeDto } from '../models/tc-activation-code-dto';
import { TcRegistrationDto } from '../models/tc-registration-dto';
import { terminateRegistration } from '../fn/tc-registrations/terminate-registration';
import { TerminateRegistration$Params } from '../fn/tc-registrations/terminate-registration';
import { updateTcProperties } from '../fn/tc-registrations/update-tc-properties';
import { UpdateTcProperties$Params } from '../fn/tc-registrations/update-tc-properties';

@Injectable({ providedIn: 'root' })
export class TcRegistrationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllTcRegistrations()` */
  static readonly GetAllTcRegistrationsPath = '/registrations';

  /**
   * Get paginated TCRegistrations.
   *
   * Get a list of all TCRegistrations that a user is authorized to see. Its a paginated endpoint, with filters on each property.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTcRegistrations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTcRegistrations$Response(params?: GetAllTcRegistrations$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBodyTcRegistrationDto>> {
    return getAllTcRegistrations(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated TCRegistrations.
   *
   * Get a list of all TCRegistrations that a user is authorized to see. Its a paginated endpoint, with filters on each property.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTcRegistrations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTcRegistrations(params?: GetAllTcRegistrations$Params, context?: HttpContext): Observable<ResponseBodyTcRegistrationDto> {
    return this.getAllTcRegistrations$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseBodyTcRegistrationDto>): ResponseBodyTcRegistrationDto => r.body)
    );
  }

  /** Path part for operation `getTcRegistrationsOfCustomer()` */
  static readonly GetTcRegistrationsOfCustomerPath = '/svgs/{svgId}/customers/{customerId}/registrations';

  /**
   * Get all TCRegistrations of one Customer.
   *
   * Get a list of TCRegistration for the given customer. Independent of their status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTcRegistrationsOfCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTcRegistrationsOfCustomer$Response(params: GetTcRegistrationsOfCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TcRegistrationDto>>> {
    return getTcRegistrationsOfCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all TCRegistrations of one Customer.
   *
   * Get a list of TCRegistration for the given customer. Independent of their status
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTcRegistrationsOfCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTcRegistrationsOfCustomer(params: GetTcRegistrationsOfCustomer$Params, context?: HttpContext): Observable<Array<TcRegistrationDto>> {
    return this.getTcRegistrationsOfCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TcRegistrationDto>>): Array<TcRegistrationDto> => r.body)
    );
  }

  /** Path part for operation `createRegistration()` */
  static readonly CreateRegistrationPath = '/svgs/{svgId}/customers/{customerId}/registrations';

  /**
   * Creates a Toll Collect registration.
   *
   * A TCRegistration describes the customers status regarding the Toll Collect Business Sector. It initially has only a TCPaymentNumber that is generated automatically.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRegistration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRegistration$Response(params: CreateRegistration$Params, context?: HttpContext): Observable<StrictHttpResponse<TcRegistrationDto>> {
    return createRegistration(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Toll Collect registration.
   *
   * A TCRegistration describes the customers status regarding the Toll Collect Business Sector. It initially has only a TCPaymentNumber that is generated automatically.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRegistration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRegistration(params: CreateRegistration$Params, context?: HttpContext): Observable<TcRegistrationDto> {
    return this.createRegistration$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcRegistrationDto>): TcRegistrationDto => r.body)
    );
  }

  /** Path part for operation `createActivationCode()` */
  static readonly CreateActivationCodePath = '/svgs/{svgId}/customers/{customerId}/registrations/{tcPaymentNumber}/activation-code';

  /**
   * Creates a fresh activation code for a tc payment number.
   *
   * Activation Code is a private/public key encrypted TCPaymentNumber. Its not saved and generated everytime a user needs this.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createActivationCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  createActivationCode$Response(params: CreateActivationCode$Params, context?: HttpContext): Observable<StrictHttpResponse<TcActivationCodeDto>> {
    return createActivationCode(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a fresh activation code for a tc payment number.
   *
   * Activation Code is a private/public key encrypted TCPaymentNumber. Its not saved and generated everytime a user needs this.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createActivationCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createActivationCode(params: CreateActivationCode$Params, context?: HttpContext): Observable<TcActivationCodeDto> {
    return this.createActivationCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcActivationCodeDto>): TcActivationCodeDto => r.body)
    );
  }

  /** Path part for operation `lockRegistration()` */
  static readonly LockRegistrationPath = '/svgs/{svgId}/customers/{customerId}/registrations/{tcPaymentNumber}/lock';

  /**
   * Locks a TC registration by SVG.
   *
   * A Toll Collect Registration can be locked with a cause, which will trigger a process to terminate the registration.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lockRegistration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lockRegistration$Response(params: LockRegistration$Params, context?: HttpContext): Observable<StrictHttpResponse<TcRegistrationDto>> {
    return lockRegistration(this.http, this.rootUrl, params, context);
  }

  /**
   * Locks a TC registration by SVG.
   *
   * A Toll Collect Registration can be locked with a cause, which will trigger a process to terminate the registration.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lockRegistration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lockRegistration(params: LockRegistration$Params, context?: HttpContext): Observable<TcRegistrationDto> {
    return this.lockRegistration$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcRegistrationDto>): TcRegistrationDto => r.body)
    );
  }

  /** Path part for operation `updateTcProperties()` */
  static readonly UpdateTcPropertiesPath = '/svgs/{svgId}/customers/{customerId}/registrations/{tcPaymentNumber}/tcprops';

  /**
   * Updates the Toll Collect Properties of the registration.
   *
   * A TCRegistration has multiple properties that are asynchronously set by Toll Collect.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTcProperties()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTcProperties$Response(params: UpdateTcProperties$Params, context?: HttpContext): Observable<StrictHttpResponse<TcRegistrationDto>> {
    return updateTcProperties(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the Toll Collect Properties of the registration.
   *
   * A TCRegistration has multiple properties that are asynchronously set by Toll Collect.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTcProperties$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTcProperties(params: UpdateTcProperties$Params, context?: HttpContext): Observable<TcRegistrationDto> {
    return this.updateTcProperties$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcRegistrationDto>): TcRegistrationDto => r.body)
    );
  }

  /** Path part for operation `terminateRegistration()` */
  static readonly TerminateRegistrationPath = '/svgs/{svgId}/customers/{customerId}/registrations/{tcPaymentNumber}/terminate';

  /**
   * Terminates a TCRegistration.
   *
   * A TCRegistration can be terminated by Toll Collect and reach it's final state of it's lifecycle.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `terminateRegistration()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateRegistration$Response(params: TerminateRegistration$Params, context?: HttpContext): Observable<StrictHttpResponse<TcRegistrationDto>> {
    return terminateRegistration(this.http, this.rootUrl, params, context);
  }

  /**
   * Terminates a TCRegistration.
   *
   * A TCRegistration can be terminated by Toll Collect and reach it's final state of it's lifecycle.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `terminateRegistration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateRegistration(params: TerminateRegistration$Params, context?: HttpContext): Observable<TcRegistrationDto> {
    return this.terminateRegistration$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcRegistrationDto>): TcRegistrationDto => r.body)
    );
  }

}
