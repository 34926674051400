<div *ngIf="messages && messages.length > 0" class="alert alert-danger">
	<div class="alert-items">
		<div *ngFor="let message of messages" class="alert-item static">
			<div class="alert-icon-wrapper">
				<cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
			</div>
			<span class="alert-text">{{ message }}</span>
		</div>
	</div>
</div>
