/**
 * Boxes are always in a certain status. This enum contains some of the possible statuses.
 */

export enum BoxStatus {
	REQUESTED = 1,
	LOCKED = 5,
	ACTIVE = 13,
	LOCK_INITIATED = 27,
	UNLOCK_INITIATED = 28,
	EXCHANGE_INITIATED = 29,
	EXCHANGE_WITHOUT_RETURN = 30,
	EXCHANGE_WITH_RETURN = 31,
}
