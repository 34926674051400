/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createInvoice } from '../fn/tc-invoices/create-invoice';
import { CreateInvoice$Params } from '../fn/tc-invoices/create-invoice';
import { createTemporaryInvoiceDocumentUrl } from '../fn/tc-invoices/create-temporary-invoice-document-url';
import { CreateTemporaryInvoiceDocumentUrl$Params } from '../fn/tc-invoices/create-temporary-invoice-document-url';
import { getInvoice } from '../fn/tc-invoices/get-invoice';
import { GetInvoice$Params } from '../fn/tc-invoices/get-invoice';
import { getInvoices } from '../fn/tc-invoices/get-invoices';
import { GetInvoices$Params } from '../fn/tc-invoices/get-invoices';
import { ResponseBodyTcInvoiceDto } from '../models/response-body-tc-invoice-dto';
import { TcInvoiceDownloadDto } from '../models/tc-invoice-download-dto';
import { TcInvoiceDto } from '../models/tc-invoice-dto';

@Injectable({ providedIn: 'root' })
export class TcInvoicesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getInvoices()` */
  static readonly GetInvoicesPath = '/svgs/{svgId}/customers/{customerId}/invoices';

  /**
   * Get all invoices of a given customer.
   *
   * Retrieving all invoice objects (meta data and link to document) for a given customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInvoices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInvoices$Response(params: GetInvoices$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBodyTcInvoiceDto>> {
    return getInvoices(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all invoices of a given customer.
   *
   * Retrieving all invoice objects (meta data and link to document) for a given customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInvoices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInvoices(params: GetInvoices$Params, context?: HttpContext): Observable<ResponseBodyTcInvoiceDto> {
    return this.getInvoices$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseBodyTcInvoiceDto>): ResponseBodyTcInvoiceDto => r.body)
    );
  }

  /** Path part for operation `createInvoice()` */
  static readonly CreateInvoicePath = '/svgs/{svgId}/customers/{customerId}/invoices';

  /**
   * Create an invoice.
   *
   * Create an invoice object (meta data and link to document)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createInvoice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createInvoice$Response(params: CreateInvoice$Params, context?: HttpContext): Observable<StrictHttpResponse<TcInvoiceDto>> {
    return createInvoice(this.http, this.rootUrl, params, context);
  }

  /**
   * Create an invoice.
   *
   * Create an invoice object (meta data and link to document)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createInvoice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createInvoice(params: CreateInvoice$Params, context?: HttpContext): Observable<TcInvoiceDto> {
    return this.createInvoice$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcInvoiceDto>): TcInvoiceDto => r.body)
    );
  }

  /** Path part for operation `getInvoice()` */
  static readonly GetInvoicePath = '/svgs/{svgId}/customers/{customerId}/invoices/{id}';

  /**
   * Get an invoice by id.
   *
   * Retrieving an invoice object (meta data and link to document) by id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInvoice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInvoice$Response(params: GetInvoice$Params, context?: HttpContext): Observable<StrictHttpResponse<TcInvoiceDto>> {
    return getInvoice(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an invoice by id.
   *
   * Retrieving an invoice object (meta data and link to document) by id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInvoice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInvoice(params: GetInvoice$Params, context?: HttpContext): Observable<TcInvoiceDto> {
    return this.getInvoice$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcInvoiceDto>): TcInvoiceDto => r.body)
    );
  }

  /** Path part for operation `createTemporaryInvoiceDocumentUrl()` */
  static readonly CreateTemporaryInvoiceDocumentUrlPath = '/svgs/{svgId}/customers/{customerId}/invoices/{id}/download';

  /**
   * Create a download urls for invoice.
   *
   * Create a download url by attaching a sas token to the given blob url. The generated Url is not persisted. The sas token has an expiration, so this url is only temporary usable.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTemporaryInvoiceDocumentUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTemporaryInvoiceDocumentUrl$Response(params: CreateTemporaryInvoiceDocumentUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<TcInvoiceDownloadDto>> {
    return createTemporaryInvoiceDocumentUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a download urls for invoice.
   *
   * Create a download url by attaching a sas token to the given blob url. The generated Url is not persisted. The sas token has an expiration, so this url is only temporary usable.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTemporaryInvoiceDocumentUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTemporaryInvoiceDocumentUrl(params: CreateTemporaryInvoiceDocumentUrl$Params, context?: HttpContext): Observable<TcInvoiceDownloadDto> {
    return this.createTemporaryInvoiceDocumentUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcInvoiceDownloadDto>): TcInvoiceDownloadDto => r.body)
    );
  }

}
