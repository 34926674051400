import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Context } from '../../context/models/context.model';
import { DefaultUserTokenClaims } from '../../context/models/user-token-claims.model';

import { ContextService } from '../../context/services/context.service';
import { OauthWrapperService } from '../services/oauth-wrapper.service';

@Injectable({ providedIn: 'root' })
export class InitialAuthenticationGuard<T extends Context, U extends DefaultUserTokenClaims> implements CanActivate, CanActivateChild {
	constructor(private contextService: ContextService<T, U>, private oauthWrapperService: OauthWrapperService<U>) {}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.canActivate(route, state);
	}

	canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.contextService.getFirstContext().pipe(
			map((context: T) => !context.isNotLoggedInState()),
			tap((canActivate: boolean) => {
				if (!canActivate) {
					this.oauthWrapperService.initImplicitFlow(state.url);
				}
			}),
		);
	}
}
