import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LayoutService {
	private contentResize$ = new Subject<void>();
	private windowResize$ = new Subject<void>();

	private renderer: Renderer2;

	constructor(private rendererFactory2: RendererFactory2) {
		// renderer2 can not be injected directly into services (components and directives only), see: https://github.com/angular/angular/pull/22248
		this.renderer = this.rendererFactory2.createRenderer(null, null);
		this.renderer.listen('window', 'resize', () => this.windowResize$.next());
	}

	getWindowResizeObservable(): Observable<void> {
		return this.windowResize$.asObservable();
	}

	triggerContentResized(): void {
		this.contentResize$.next();
	}

	getContentResized(): Observable<void> {
		return merge(this.contentResize$.asObservable(), this.windowResize$.asObservable());
	}
}
