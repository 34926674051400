import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AsiContextStateDirective } from './directives/asi-context-state.directive';
import { NoActivityDirective } from './directives/no-activity.directive';
import { SvgContextStateDirective } from './directives/svg-context-state.directive';
import { AccessTokenToUrlPipe } from './pipes/access-token-to-url/access-token-to-url.pipe';
import { PageTitleFromAsiContextPipe } from './pipes/page-title-from-asi-context-pipe';

const DECLARABLES = [
	AccessTokenToUrlPipe,
	SvgContextStateDirective,
	AsiContextStateDirective,
	NoActivityDirective,
	PageTitleFromAsiContextPipe,
];
@NgModule({
	declarations: [DECLARABLES],
	exports: [DECLARABLES],
	imports: [CommonModule],
})
export class ContextModule {}
