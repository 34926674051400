/**
 * [NOTE] checks null, undefined and empty string
 */
export const isDefined = (value: any): boolean => {
	if (value !== undefined && value !== null) {
		if (typeof value === 'string') {
			return value.trim().length > 0;
		} else {
			return true;
		}
	} else {
		return false;
	}
};
