import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ResponseTagDto, TagControllerService, TagDto } from '@myasi/api/customers/tags';

import { AsiApiBaseUrls } from '@mysvg/utils';
import { TypeAheadSearchParams, TypeAheadSearchServiceInterface } from '@svg-frontends/type-ahead/core';

import { API_BASE_URLS_TOKEN } from '@svg/environment';

@Injectable({ providedIn: 'root' })
export class TagsRepositoryService implements TypeAheadSearchServiceInterface<TagDto> {
	constructor(
		@Inject(API_BASE_URLS_TOKEN) private baseApiUrls: AsiApiBaseUrls,
		private tagControllerService: TagControllerService,
		private httpClient: HttpClient,
	) {}

	get(): Observable<TagDto[]> {
		return this.tagControllerService.getAllTags();
	}

	create(body: string): Observable<TagDto> {
		return this.tagControllerService.createTag({ body });
	}

	delete(id: number): Observable<void> {
		return this.tagControllerService.deleteTag({ id });
	}

	update(id: number, body: string): Observable<TagDto> {
		return this.tagControllerService.updateTag({ id, body });
	}

	search(query: string, limit: number, offset: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC'): Observable<ResponseTagDto> {
		return this.tagControllerService.searchTags({
			limit,
			offset,
			query,
			sortBy,
			sortOrder,
		});
	}

	getByReference(reference: string): Observable<ResponseTagDto> {
		return this.httpClient.get<ResponseTagDto>(`${this.baseApiUrls.customers}/tags${reference}`);
	}

	getWithParams(params: TypeAheadSearchParams): Observable<ResponseTagDto> {
		return this.search(params.search, params.limit, params.offset);
	}
}
