import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ErrorHandlingType } from '../enums/error-handling-type.enum';
import { EnrichedError } from '../models/enriched-error.model';
import { ErrorObject } from '../models/error-object.model';
import { ProblemBody } from '../models/problem-body.model';
import { SvgErrorMessageFactoryService } from './error-message-factory/svg-error-message-factory.service';

@Injectable({ providedIn: 'root' })
export class EnrichedErrorFactoryService {
	constructor(private errorMessageFactoryService: SvgErrorMessageFactoryService) {}

	private static mapErrorResponseToError(errorResponse: HttpErrorResponse): Error {
		if (SvgErrorMessageFactoryService.hasProblemHeader(errorResponse)) {
			const problem: ProblemBody = errorResponse.error;
			const error: Error = new Error(problem.detail || problem.displayMessage);
			error.name = errorResponse.name;
			return error;
		} else {
			const error: Error = new Error(errorResponse.message);
			error.name = errorResponse.name;
			return error;
		}
	}

	by(error: Error, handlingType: ErrorHandlingType, reportSentry: boolean): EnrichedError {
		if (error instanceof HttpErrorResponse) {
			if (error.status >= 500) {
				// sentry does not care about errors 5XX e.g. 504 Gateway-Timeout
				// backend should handle these. FE did nothing wrong, only pollutes sentry
				reportSentry = false;
			}

			const convertedError: Error = EnrichedErrorFactoryService.mapErrorResponseToError(error);
			const displayMessages: string[] = this.errorMessageFactoryService.getMessageForError(error);

			return { displayMessages, error: convertedError, handlingType, reportSentry };
		} else if (error instanceof Error) {
			const displayMessages: string[] = [this.errorMessageFactoryService.getMessageForKey(error.message)];
			return { displayMessages, error, handlingType, reportSentry };
		} else {
			return this.byObject(error, handlingType, reportSentry);
		}
	}

	byKey(errorKey: string, handlingType: ErrorHandlingType, reportSentry: boolean): EnrichedError {
		const displayMessages: string[] = [this.errorMessageFactoryService.getMessageForKey(errorKey)];
		return { displayMessages, error: new Error(displayMessages.join(' ')), handlingType, reportSentry };
	}

	private byObject(errorObject: ErrorObject, handlingType: ErrorHandlingType, reportSentry: boolean): EnrichedError {
		const error = new Error(errorObject.message);
		error.name = errorObject.name;

		if (errorObject.stack) {
			error.stack = errorObject.stack;
		}

		const displayMessages: string[] = [this.errorMessageFactoryService.getMessageForKey(error.message)];

		return { displayMessages, error, handlingType, reportSentry };
	}
}
