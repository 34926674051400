/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createPartialPayment } from '../fn/partial-payment-controller/create-partial-payment';
import { CreatePartialPayment$Params } from '../fn/partial-payment-controller/create-partial-payment';
import { deletePartialPayment } from '../fn/partial-payment-controller/delete-partial-payment';
import { DeletePartialPayment$Params } from '../fn/partial-payment-controller/delete-partial-payment';
import { getPartialPaymentForCustomer } from '../fn/partial-payment-controller/get-partial-payment-for-customer';
import { GetPartialPaymentForCustomer$Params } from '../fn/partial-payment-controller/get-partial-payment-for-customer';
import { PartialPaymentDto } from '../models/partial-payment-dto';
import { updatePartialPayment } from '../fn/partial-payment-controller/update-partial-payment';
import { UpdatePartialPayment$Params } from '../fn/partial-payment-controller/update-partial-payment';

@Injectable({ providedIn: 'root' })
export class PartialPaymentControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPartialPaymentForCustomer()` */
  static readonly GetPartialPaymentForCustomerPath = '/partialpayments';

  /**
   * Get the partial payment object for a given customer, if exists.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartialPaymentForCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartialPaymentForCustomer$Response(params: GetPartialPaymentForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<PartialPaymentDto>> {
    return getPartialPaymentForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the partial payment object for a given customer, if exists.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPartialPaymentForCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartialPaymentForCustomer(params: GetPartialPaymentForCustomer$Params, context?: HttpContext): Observable<PartialPaymentDto> {
    return this.getPartialPaymentForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartialPaymentDto>): PartialPaymentDto => r.body)
    );
  }

  /** Path part for operation `updatePartialPayment()` */
  static readonly UpdatePartialPaymentPath = '/partialpayments';

  /**
   * Update the partial payment object for a given customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePartialPayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePartialPayment$Response(params: UpdatePartialPayment$Params, context?: HttpContext): Observable<StrictHttpResponse<PartialPaymentDto>> {
    return updatePartialPayment(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the partial payment object for a given customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePartialPayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePartialPayment(params: UpdatePartialPayment$Params, context?: HttpContext): Observable<PartialPaymentDto> {
    return this.updatePartialPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartialPaymentDto>): PartialPaymentDto => r.body)
    );
  }

  /** Path part for operation `createPartialPayment()` */
  static readonly CreatePartialPaymentPath = '/partialpayments';

  /**
   * Create the partial payment object for a given customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPartialPayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPartialPayment$Response(params: CreatePartialPayment$Params, context?: HttpContext): Observable<StrictHttpResponse<PartialPaymentDto>> {
    return createPartialPayment(this.http, this.rootUrl, params, context);
  }

  /**
   * Create the partial payment object for a given customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPartialPayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPartialPayment(params: CreatePartialPayment$Params, context?: HttpContext): Observable<PartialPaymentDto> {
    return this.createPartialPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartialPaymentDto>): PartialPaymentDto => r.body)
    );
  }

  /** Path part for operation `deletePartialPayment()` */
  static readonly DeletePartialPaymentPath = '/partialpayments';

  /**
   * Delete the partial payment object for a given customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePartialPayment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartialPayment$Response(params: DeletePartialPayment$Params, context?: HttpContext): Observable<StrictHttpResponse<PartialPaymentDto>> {
    return deletePartialPayment(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete the partial payment object for a given customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePartialPayment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePartialPayment(params: DeletePartialPayment$Params, context?: HttpContext): Observable<PartialPaymentDto> {
    return this.deletePartialPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartialPaymentDto>): PartialPaymentDto => r.body)
    );
  }

}
