/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createBoxValidation } from '../fn/box-validation-controller/create-box-validation';
import { CreateBoxValidation$Params } from '../fn/box-validation-controller/create-box-validation';
import { deleteBoxValidation } from '../fn/box-validation-controller/delete-box-validation';
import { DeleteBoxValidation$Params } from '../fn/box-validation-controller/delete-box-validation';
import { getBoxValidations } from '../fn/box-validation-controller/get-box-validations';
import { GetBoxValidations$Params } from '../fn/box-validation-controller/get-box-validations';
import { updateBoxValidation } from '../fn/box-validation-controller/update-box-validation';
import { UpdateBoxValidation$Params } from '../fn/box-validation-controller/update-box-validation';
import { VehicleBoxValidation } from '../models/vehicle-box-validation';

@Injectable({ providedIn: 'root' })
export class BoxValidationControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateBoxValidation()` */
  static readonly UpdateBoxValidationPath = '/vehicles/{vehicleId}/boxvalidations/{validationId}';

  /**
   * update a validation status object for a boxtype.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBoxValidation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBoxValidation$Response(params: UpdateBoxValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<VehicleBoxValidation>> {
    return updateBoxValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * update a validation status object for a boxtype.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBoxValidation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBoxValidation(params: UpdateBoxValidation$Params, context?: HttpContext): Observable<VehicleBoxValidation> {
    return this.updateBoxValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleBoxValidation>): VehicleBoxValidation => r.body)
    );
  }

  /** Path part for operation `deleteBoxValidation()` */
  static readonly DeleteBoxValidationPath = '/vehicles/{vehicleId}/boxvalidations/{validationId}';

  /**
   * update a validation status object for a boxtype.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBoxValidation()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBoxValidation$Response(params: DeleteBoxValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<VehicleBoxValidation>> {
    return deleteBoxValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * update a validation status object for a boxtype.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBoxValidation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBoxValidation(params: DeleteBoxValidation$Params, context?: HttpContext): Observable<VehicleBoxValidation> {
    return this.deleteBoxValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleBoxValidation>): VehicleBoxValidation => r.body)
    );
  }

  /** Path part for operation `getBoxValidations()` */
  static readonly GetBoxValidationsPath = '/vehicles/{vehicleId}/boxvalidations';

  /**
   * getting the validation status object for a boxtype.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBoxValidations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoxValidations$Response(params: GetBoxValidations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VehicleBoxValidation>>> {
    return getBoxValidations(this.http, this.rootUrl, params, context);
  }

  /**
   * getting the validation status object for a boxtype.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBoxValidations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoxValidations(params: GetBoxValidations$Params, context?: HttpContext): Observable<Array<VehicleBoxValidation>> {
    return this.getBoxValidations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VehicleBoxValidation>>): Array<VehicleBoxValidation> => r.body)
    );
  }

  /** Path part for operation `createBoxValidation()` */
  static readonly CreateBoxValidationPath = '/vehicles/{vehicleId}/boxvalidations';

  /**
   * create a validation status object for a boxtype.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBoxValidation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBoxValidation$Response(params: CreateBoxValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<VehicleBoxValidation>> {
    return createBoxValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * create a validation status object for a boxtype.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBoxValidation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBoxValidation(params: CreateBoxValidation$Params, context?: HttpContext): Observable<VehicleBoxValidation> {
    return this.createBoxValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleBoxValidation>): VehicleBoxValidation => r.body)
    );
  }

}
