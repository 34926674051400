import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '../utils/general.utils';

@Pipe({ name: 'arrayContainsOneOf' })
export class ArrayContainsOneOfPipe implements PipeTransform {
	transform(arr: any[], propertyName: string, propertyValues: any[]): boolean {
		return (
			isDefined(arr) &&
			isDefined(propertyValues) &&
			arr
				.filter((item: any) => isDefined(item))
				.some((item: any) =>
					propertyValues
						.filter((propertyValue: any) => isDefined(propertyValue))
						.some((propertyValue: any) => item[propertyName] === propertyValue),
				)
		);
	}
}
