/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DiscountView } from '../../models/discount-view';

export interface GetDiscounts$Params {

/**
 * Country id
 */
  countryId?: number;

/**
 * Box type id
 */
  boxTypeId?: number;
}

export function getDiscounts(http: HttpClient, rootUrl: string, params?: GetDiscounts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DiscountView>>> {
  const rb = new RequestBuilder(rootUrl, getDiscounts.PATH, 'get');
  if (params) {
    rb.query('countryId', params.countryId, {});
    rb.query('boxTypeId', params.boxTypeId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<DiscountView>>;
    })
  );
}

getDiscounts.PATH = '/discounts';
