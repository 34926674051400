/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseListpreisDeutschlandPos } from '../../models/response-listpreis-deutschland-pos';

export interface GetListpreisDeutschland$Params {
  id?: string;
  netAmount?: string;
  creationDate?: string;
  validFrom?: string;
  validTo?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getListpreisDeutschland(http: HttpClient, rootUrl: string, params?: GetListpreisDeutschland$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseListpreisDeutschlandPos>> {
  const rb = new RequestBuilder(rootUrl, getListpreisDeutschland.PATH, 'get');
  if (params) {
    rb.query('id', params.id, {});
    rb.query('netAmount', params.netAmount, {});
    rb.query('creationDate', params.creationDate, {});
    rb.query('validFrom', params.validFrom, {});
    rb.query('validTo', params.validTo, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseListpreisDeutschlandPos>;
    })
  );
}

getListpreisDeutschland.PATH = '/listpreisDeutschland';
