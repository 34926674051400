import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs/operators';
import { ContextState } from '../enums/context-state.enum';
import { SvgContext } from '../models/svg-context.model';
import { SvgContextService } from '../services/svg-context.service';

@UntilDestroy()
@Directive({ selector: '[svgFrontendsSvgForStates]' })
export class SvgContextStateDirective implements OnInit {
	@Input() svgFrontendsSvgForStates: ContextState[];

	constructor(
		private svgContextService: SvgContextService,
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef,
	) {}

	/**
	 * [0] get SvgContext Observable
	 * [1] map to state
	 * [2] check if one of the states of this directive match the app state
	 * [3] check if `isAccessGranted` is not the same than `isVisible` - if both match, then it is already hidden or shown
	 *
	 * [Note] step 3 avoids displaying something twice
	 */
	ngOnInit(): void {
		this.svgContextService
			.getSvgContext()
			.pipe(
				map((svgContext: SvgContext) => svgContext.state),
				map((stateOfApp: ContextState) => this.svgFrontendsSvgForStates.some((state: ContextState) => state === stateOfApp)),
				filter((isAccessGranted: boolean) => isAccessGranted !== this.viewContainerRef.length > 0),
				untilDestroyed(this),
			)
			.subscribe((isAccessGranted: boolean) => this.setVisibility(isAccessGranted));
	}

	private setVisibility(isAccessGranted: boolean): void {
		if (isAccessGranted) {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainerRef.clear();
		}
	}
}
